import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Rules;
var getters = {
    GetRules: state => state.getdata,
    GetCallStatus: state => state.callstatus
};
const mutations = {
    [mutation_type.Rules]: (state, response) => {
        state.getdata = response;
    },
    [mutation_type.CallStatus]: (state, response) => {
        state.callstatus = response;
    },
    [mutation_type.CAMPAIGN]: (state, response) => {
        state.campaign = response;
    },
    [mutation_type.CAMPAIGN_LISTS]: (state, response) => {
        state.campaignList = response;
    }
};
const actions = {
    GetRules: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/rules/fetch`).then(response => {
                    const rules = response.data;
                    commit(mutation_type.Rules, rules);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    getCallStatus: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/rules/callstatus`)
                    .then(response => {
                        const call_status = response.data.data;
                        commit(mutation_type.CallStatus, call_status);
                        resolve(response.data);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    getruleCampaign: ({ commit }, dial_type) => {

        return new Promise((resolve, reject) => {

            try {
                api
                    .get(`/campaigns?dial_type=${dial_type}`)
                    .then(response => {
                        const campaign = response.data;
                        resolve(response.data);
                        commit(mutation_type.CAMPAIGN, campaign);

                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    getLists: ({ commit }, campaign) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/campaigns/list?campaign=${campaign}`)
                    .then(response => {
                        const campaign_list = response.data;
                        resolve(response.data);
                        commit(mutation_type.CAMPAIGN_LISTS, campaign_list);

                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertRules: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/rules/create`, payload)
                    .then(response => {
                        commit(mutation_type.Rules);
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteRules: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/rules/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.Rules);
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateRules: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/rules/update/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.Rules);
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};