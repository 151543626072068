<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" @addNew="AddTemplate" :addNewData="page.addnew"
      :exportOptions="page.exportOptions" @openFilter="filterClick" @refreshPage="refreshThisPage"
      @opensettings="settingclick" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="pausecodeData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align:center">
          <div style="text-align:center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.id }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.message }}</td>
          <td class="text-xs-center checkEmpty p0">

            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Template</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isAddConfig" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">SMS setting</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="sms">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <label style="font-size:17px;">Type</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="config" v-on:change="radioOption" id="API" value="API"
                      v-model="addConfig.payload.integration_type">&nbsp;&nbsp;
                    <label style="font-size:17px;" for="API">API</label>&nbsp;&nbsp;
                    <input type="radio" name="config" v-on:change="radioOption" id="Gateway" value="Gateway"
                      v-model="addConfig.payload.integration_type">&nbsp;&nbsp;
                    <label style="font-size:17px;" for="Gateway">Gateway</label>
                  </v-flex>
                  <v-flex xs12 v-if="isApi">
                    <v-select v-model="addConfig.payload.auth_type" :items="addConfig.options.auth_type"
                      label="Auth Type" item-text="name" v-on:change="authOption" item-value="value" return-string
                      persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs6 v-if="isUserPass">
                    <v-text-field v-model="addConfig.payload.user_name" required label="User Name" type="text"
                      :rules="[v => !!v || 'User Name is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isUserPass">
                    <v-text-field v-model="addConfig.payload.password" required label="Password" type="text"
                      :rules="[v => !!v || 'Password is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isApikey">
                    <v-text-field v-model="addConfig.payload.api_key" required label="Api Key" type="text"
                      :rules="[v => !!v || 'Api Key is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isToken">
                    <v-text-field v-model="addConfig.payload.token" required label="Token" type="text"
                      :rules="[v => !!v || 'Token is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isAuth">
                    <v-text-field v-model="addConfig.payload.url" required label="URL" type="text"
                      :rules="[v => !!v || 'URL is required' ,v => /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || 'URL Must Be Valid']">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isAuth">
                    <v-text-field v-model="addConfig.payload.phone_key" required label="Phone Key" type="text"
                      :rules="[v => !!v || 'Phone Key is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isAuth">
                    <v-text-field v-model="addConfig.payload.message_key" required label="Message Key" type="text"
                      :rules="[v => !!v || 'Message Key is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isAuth">
                    <v-text-field v-model="addConfig.payload.other_params" required label="Other Params"
                      placeholder="sender=TEVATL&service=T" type="textarea"
                      :rules="[v => !!v || 'Other Params is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isGateway">
                    <v-text-field v-model="addConfig.payload.gateway" required label="Gateway" type="text"
                      :rules="[v => !!v || 'Gateway is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isGateway">
                    <v-text-field v-model="addConfig.payload.from_address" required label="From Address" type="text"
                      :rules="[v => !!v || 'From Address is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isGateway">
                    <v-text-field v-model="addConfig.payload.number" required label="Number" type="text"
                      :rules="[v => !!v || 'Number is required']"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddConfig()">Close</v-btn>
            <v-btn color="primary" @click="saveAddConfig()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-dialog persistent :value="page.isAddPausecode" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Create Template</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="smsedit">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="addPausecode.payload.name" required label="Name" type="text"
                    :rules="[v => !!v || 'name is required']"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="addPausecode.payload.message" required label="Message" type="text"
                    :rules="[v => !!v || 'message is required']"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
          <v-btn color="primary" @click="saveAddPausecode()">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditPausecode" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Edit Template</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field v-model="editPausecode.name" required label="Name" type="text"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="editPausecode.message" required label="Message" type="text"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn color="primary" @click="saveEditRules()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color:white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select v-model="filter.payload.name" :items="filter.payload.options" label="Name" small></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi('reset')">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px"
      },
      page: {
        title: "Sms",
        icon: "fa-sms fa-3x",
        loading: false,
        isAddPausecode: false,
        isAddConfig: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, cog: true },
        addnew: [],
        isEditPausecode: false,
        temp: { key: "addastersms", label: "Add AsterSms" },
        candelete: false,
        canedit: false
      },
      pagecount: 0,
      headers: [
        {
          text: "Id",
          align: "left",
          class: "p0",
          sortable: false,
          value: "id"
        },
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name"
        },
        {
          text: "Message",
          align: "left",
          class: "p0",
          sortable: false,
          value: "message"
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%"
        }
      ],
      pausecodeData: [],
      pagination: {

        rowsPerPage: 10
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: ""
      },
      addConfig: {
        valid: false,
        payload: {
          integration_type: "",
          auth_type: "",
          user_name: "",
          password: "",
          url: "",
          phone_key: "",
          message_key: "",
          other_params: "",
          api_key: "",
          token: "",
          gateway: "",
          from_address: "",
          number: ""
        },
        demo: 0,
        options: {
          integration_type: ["API", "Gateway"],
          auth_type: ["Basic", "ApiKey", "Token"]
        }
      },
      isUserPass: false,
      isApi: false,
      isApikey: false,
      isToken: false,
      isAuth: false,
      isGateway: false,
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filter: {
        payload: {
          name: "",
          options: []
        },
        options: {
          status: ["Active", "Inactive"]
        }
      },

      isProcessing: false,
      call_status: [],
      editPausecode: {
        id: "",
        name: "",
        message: ""
      },
      addPausecode: {
        valid: false,
        payload: {
          name: "",
          message: ""
        },
        options: {
          status: ["Active", "Inactive"]
        }
      }
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.filter.payload.name = "";
      this.pagination.page=1
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    settingclick: function () {
      this.$store.dispatch("GetSmsConfig").then(response => {
        this.addConfig.payload.integration_type = response.data[0].integration_type;
        this.isApi = this.addConfig.payload.integration_type === 'API' ? true : false;
        this.isGateway = this.addConfig.payload.integration_type === 'Gateway' ? true : false;
        this.isUserPass = this.addConfig.payload.integration_type === 'Gateway' ? true : false;
        this.addConfig.payload.auth_type = response.data[0].auth_type;
        this.isUserPass = this.addConfig.payload.auth_type === 'Basic' ? true : false;
        this.isToken = this.addConfig.payload.auth_type === 'Token' ? true : false;
        this.isApikey = this.addConfig.payload.auth_type === 'ApiKey' ? true : false;
        this.isAuth = this.addConfig.payload.auth_type === 'ApiKey' || this.addConfig.payload.auth_type === 'Token' || this.addConfig.payload.auth_type === 'Basic' ? true : false;
        this.addConfig.payload.user_name = response.data[0].user_name;
        this.addConfig.payload.password = response.data[0].password;
        this.addConfig.payload.api_key = response.data[0].api_key;
        this.addConfig.payload.url = response.data[0].url;
        this.addConfig.payload.token = response.data[0].token;
        this.addConfig.payload.phone_key = response.data[0].phone_key;
        this.addConfig.payload.message_key = response.data[0].message_key;
        this.addConfig.payload.other_params = response.data[0].other_params;
        this.addConfig.payload.gateway = response.data[0].gateway;
        this.addConfig.payload.from_address = response.data[0].from_address;
        this.addConfig.payload.number = response.data[0].number;
      })
      this.page.isAddConfig = true;
    }, AddTemplate() {
      this.page.isAddPausecode = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      var data1 = {
        name: scope.filter.payload.name,
      };
      data1.skip = ""
      data1.take = ""

      this.$store.dispatch("FilterAsterSms", data1).then(response => {

        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.pausecodeData = []

        scope.pausecodeData = datas;
        scope.pagecount = datas.length
      })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    authOption() {
      var auth = this.addConfig.payload.auth_type;
      if (auth == "Basic") {
        this.isApikey = false;
        this.isToken = false;
        this.isGateway = false;
        this.isUserPass = true;
        this.isAuth = true;
      }
      if (auth == "ApiKey") {
        this.isToken = false;
        this.isGateway = false;
        this.isUserPass = false;
        this.isApikey = true;
        this.isAuth = true;
      }
      if (auth == "Token") {
        this.isGateway = false;
        this.isUserPass = false;
        this.isApikey = false;
        this.isToken = true;
        this.isAuth = true;
      }
    },
    radioOption() {
      var radio = this.addConfig.payload.integration_type;
      if (radio == "API") {
        this.isGateway = false;
        this.isUserPass = false;
        this.isApikey = false;
        this.isToken = false;
        this.isAuth = false;
        this.isApi = true;
      }
      if (radio == "Gateway") {
        this.isUserPass = true;
        this.isGateway = true;
        this.isApikey = false;
        this.isToken = false;
        this.isAuth = false;
        this.isApi = false;
      }
    },
    getDataFromApi(val) {
      var scope = this;
      scope.tableLoading = true;
      if ('reset' === val) {
        scope.filter.payload.name = "";

      }
      scope.filter.payload.options = [];
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage
      let skip
      if (this.pagination.page === 1) {
        skip = 0
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      }
      let query = {
        name: scope.filter.payload.name,
        skip: skip,
        take: take
      }
      this.skip=skip
      this.$store.dispatch("GetAsterSms", query).then(response => {
        scope.tableLoading = false;
        scope.page.loading = false;
        scope.pagecount = response.count;
        response.filtersms.forEach(element =>
          scope.filter.payload.options.push(element.name)
        );
        var datas = response.data;
        scope.pausecodeData = datas;
      })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },

    closeEditRules: function () {
      this.page.isEditPausecode = false;
    },
    closeAddPausecode: function () {
      this.page.isAddPausecode = false;
      this.$refs.smsedit.reset();
    },
    closeAddConfig: function () {
      this.page.isAddConfig = false;
      this.$refs.sms.reset();
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("updateAsterSms", scope.editPausecode)
        .then(() => {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditPausecode = false;
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
      this.getDataFromApi();
    },
    saveAddConfig() {
      var scope = this;
      alert(JSON.stringify(scope.addConfig.payload));
      scope.$store
        .dispatch("UpdateSmsConfig", scope.addConfig.payload)
        .then(() => {
          scope.page.isAddConfig = false;
          scope.showSnackBar("Updated Successfully");
          scope.addConfig.payload.integration_type = "";
          scope.addConfig.payload.auth_type = "";
          scope.addConfig.payload.user_name = "";
          scope.addConfig.payload.password = "";
          scope.addConfig.payload.api_key = "";
          scope.addConfig.payload.token = "";
          scope.addConfig.payload.url = "";
          scope.addConfig.payload.phone_key = "";
          scope.addConfig.payload.message_key = "";
          scope.addConfig.payload.other_params = "";
          scope.addConfig.payload.gateway = "";
          scope.addConfig.payload.from_address = "";
          scope.addConfig.payload.number = "";
        })
        .catch(function (error) {
          scope.page.isAddConfig = false;
          scope.showSnackBar("error" + error);
          scope.addConfig.payload.integration_type = "";
          scope.addConfig.payload.auth_type = "";
          scope.addConfig.payload.user_name = "";
          scope.addConfig.payload.password = "";
          scope.addConfig.payload.api_key = "";
          scope.addConfig.payload.token = "";
          scope.addConfig.payload.url = "";
          scope.addConfig.payload.phone_key = "";
          scope.addConfig.payload.message_key = "";
          scope.addConfig.payload.other_params = "";
          scope.addConfig.payload.gateway = "";
          scope.addConfig.payload.from_address = "";
          scope.addConfig.payload.number = "";
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;

      this.$refs.smsedit.validate();

      scope.page.isAddPausecode = !this.$refs.smsedit.validate();
      if (this.$refs.smsedit.validate()) {
        scope.$store
          .dispatch("insertAsterSms", scope.addPausecode.payload)
          .then(() => {
            scope.page.isAddPausecode = false;
            scope.showSnackBar("Created Successfully");
            scope.$refs.smsedit.reset();
            scope.getDataFromApi();
          })
          .catch(function (error) {
            scope.page.isAddPausecode = false;
            scope.showSnackBar("error" + error);

            scope.getDataFromApi();
          });
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },

    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditPausecode = true;
      this.editPausecode.id = item.id;
      this.editPausecode.name = item.name;
      this.editPausecode.message = item.message;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAsterSms", this.delete_id)
        .then(response => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Template Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete Template");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete Template");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    }
  },
  computed: {
    ...mapGetters(["CallFlow"])
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (scope.$store.getters.UserPermissions.indexOf('template:sms:create') !== -1) {
      // this.page.addnew = ["SMS setting", "Template"];
      this.page.addnew = [{ label: "Add SMS Template" }];
    }
    if (scope.$store.getters.UserPermissions.indexOf('template:sms:delete') !== -1) {
      scope.page.candelete = true;
    }
    if (scope.$store.getters.UserPermissions.indexOf('template:sms:edit') !== -1) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  }, watch: {
    pagination: {
      handler() {
        this.demo += 1
       if(this.pagination.page>1 || this.payload.skip>=10){
         this.getDataFromApi();
        }
      },
      deep: true,
    },
  }
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>