<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="ruleData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.call_status }}</td>
          <td class="checkEmpty p0">{{ props.item.dial_type }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td class="checkEmpty p0">{{ props.item.campaign }}</td>
          <td class="checkEmpty p1">{{ props.item.list_id }}</td>
          <td class="checkEmpty p0">{{ props.item.start_time }}</td>
          <td class="checkEmpty p0">{{ props.item.end_time }}</td>
          <td class="checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete list info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this list.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddRules" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">New Rule</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-form ref="addrules" v-model="valid">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="addRules.payload.name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addRules.payload.call_status"
                  :items="call_status"
                  label="Call Status"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addRules.payload.dial_type"
                  :items="dial_types"
                  label="Dial Type"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                  @change="Campaign()"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="addRules.payload.status"
                  :items="status"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  addRules.payload.dial_type == 'AUTO' &&
                  crmdomain == 'asterdialer'
                "
              >
                <v-select
                  v-model="addRules.payload.campaign"
                  :items="campaigns"
                  label="Campaign"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                  @change="getLists()"
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  addRules.payload.dial_type == 'AUTO' &&
                  crmdomain == 'asterdialer'
                "
              >
                <v-select
                  v-model="addRules.payload.list_id"
                  :items="lists"
                  label="List Id"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <h3>Time Slot</h3>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Start Time"
                      value="00:00:00"
                      type="time"
                      v-model="addRules.payload.start_time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="End Time"
                      value="00:00:00"
                      type="time"
                      v-model="addRules.payload.end_time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddRules()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" flat @click="saveAddRules()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditRules" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Edit Rule</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-form ref="editrule" v-model="valid">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="editRules.name"
                  required
                  label="Name"
                  type="text"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editRules.call_status"
                  :items="call_status"
                  label="Call Status"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editRules.dial_type"
                  :items="dial_types"
                  label="Dial Type"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                  @change="Campaign()"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editRules.status"
                  :items="status"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  crmdomain == 'asterdialer' && editRules.dial_type == 'AUTO'
                "
              >
                <v-select
                  v-model="editRules.campaign"
                  :items="campaigns"
                  label="Campaign"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                  @change="getLists()"
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  crmdomain == 'asterdialer' && editRules.dial_type == 'AUTO'
                "
              >
                <v-select
                  v-model="editRules.list_id"
                  :items="lists"
                  label="List Id"
                  item-text="name"
                  item-value="value"
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <h3>Time Slot</h3>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Start Time"
                      value="00:00:00"
                      type="time"
                      v-model="editRules.start_time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="End Time"
                      value="00:00:00"
                      type="time"
                      v-model="editRules.end_time"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn
            color="primary"
            :disabled="!valid"
            flat
            @click="saveEditRules()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field
              v-model="filter.payload.number"
              label="Phone Number"
              small
            ></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.status"
              :items="filter.options.status"
              label="Status"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="livaagents()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
// import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Rules",
        icon: "fa-clipboard",
        loading: false,
        isAddRules: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [],
        isEditRules: false,
        temp: { key: "addrules", label: "Add Rules" },
        candelete: false,
        canedit: false,
      },
      //   pagecount: 0,
      valid: true,

      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "name",
        },
        {
          text: "Callstatus",
          align: "left",
          class: "p0",
          sortable: true,
          value: "callstatus",
        },
        {
          text: "Dial Type",
          align: "left",
          class: "p0",
          sortable: false,
          value: "assign",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Campaign",
          align: "left",
          class: "p0",
          sortable: false,
          value: "campaign",
        },
        {
          text: "List Id",
          align: "left",
          class: "p0",
          sortable: false,
          value: "list_id",
        },
        {
          text: "Start Time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "starttime",
        },
        {
          text: "End Time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "endtime",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          class: "p0",
        },
      ],
      ruleData: [],
      pagecount: 0,
      //   pagination: {
      //     page: 0,
      //     rowsPerPage: 5
      //   },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filter: {
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      pagination: {
        rowsPerPage: 10,
      },
      isProcessing: false,
      call_status: [],
      dial_types: [
        { name: "AUTO", value: "AUTO" },
        { name: "VOICE BLAST", value: "VB" },
        { name: "PREVIEW", value: "PREVIEW" },
      ],
      status: [
        { name: "ACTIVE", value: "ACTIVE" },
        { name: "INACTIVE", value: "INACTIVE" },
      ],
      campaigns: [],
      lists: [],
      editRules: {
        id: "",
        name: "",
        call_status: "",
        dial_type: "",
        status: "",
        campaign: "",
        list_id: "",
        start_time: "",
        end_time: "",
      },
      addRules: {
        valid: false,
        payload: {
          name: "",
          call_status: "",
          dial_type: "",
          status: "",
          campaign: "",
          list_id: "",
          start_time: "",
          end_time: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store.dispatch("GetRules").then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.ruleData = datas;
      });
    },
    AddNewRule: function () {
      var scope = this;
      this.page.isAddRules = true;
      scope.$store.dispatch("getCallStatus").then((response) => {

        scope.call_status = response.data;
      });
    },
    closeEditRules: function () {
      this.page.isEditRules = false;
      this.$refs.editrule.reset();
    },
    closeAddRules: function () {
      this.page.isAddRules = false;
      this.$refs.addrules.reset();
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("updateRules", scope.editRules)
        .then(() => {
          this.closeEditRules();
          this.getDataFromApi();
          scope.showSnackBar("Updated Successfully");
          this.page.isEditRules = false;
        })
        .catch(function (error) {
          scope.page.isAddRules = false;
          scope.showSnackBar("error" + error);
          this.page.isEditRules = false;
        });
    },
    saveAddRules(item) {
      var scope = this;
      scope.$store
        .dispatch("insertRules", scope.addRules.payload)
        .then(() => {
          this.closeAddRules();
          this.getDataFromApi();
          scope.showSnackBar("Created Successfully");
        })
        .catch(function (error) {
          scope.page.isAddRules = false;
          scope.showSnackBar("error" + error);
        });
      this.addRules.payload.name = item.name;
      this.addRules.payload.call_status = item.call_status;
      this.addRules.payload.dial_type = item.dial_type;
      this.addRules.payload.status = item.status;
      this.addRules.payload.campaign = item.campaign;
      this.addRules.payload.list_id = item.list_id;
      this.addRules.payload.start_time = item.start_time;
      this.addRules.payload.end_time = item.end_time;
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    Campaign() {
      let scope = this;
      if (scope.crmdomain === "asterdialer") {
        let camp = "";
        if (scope.addRules.payload.dial_type != "") {
          camp = scope.addRules.payload.dial_type;
        } else {
          camp = scope.editRules.dial_type;
        }
        scope.$store
          .dispatch("getruleCampaign", camp)
          .then((response) => {
            scope.isProcessing = false;
            scope.campaigns = response.data;
          })
          .catch(() => {
            scope.isProcessing = false;
          });
      }
    },
    getLists() {
      let scope = this;
      scope.isProcessing = true;
      let list = "";
      if (scope.addRules.payload.campaign != "") {
        list = scope.addRules.payload.campaign;
      } else {
        list = scope.editRules.campaign;
      }
      scope.$store
        .dispatch("getLists", list)
        .then((response) => {
          scope.isProcessing = false;
          scope.lists = response.data;
        })
        .catch(() => {
          scope.isProcessing = false;
        });
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      var scope = this;
      this.page.isEditRules = true;
      this.editRules.id = item.id;
      this.editRules.name = item.name;
      this.editRules.call_status = item.call_status;
      this.editRules.dial_type = item.dial_type;
      this.editRules.status = item.status;
      this.editRules.campaign = item.campaign;
      this.editRules.list_id = item.list_id;
      this.editRules.start_time = item.start_time;
      this.editRules.end_time = item.end_time;
      scope.$store.dispatch("getCallStatus").then((response) => {
        scope.call_status = response.data;
      });
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteRules", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("List Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:rules:create"
          ) !== -1
        ) {
          this.page.addnew = [{ label: "Add Rule" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:rules:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:rules:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
