import axios from "axios";
var apiInfo = JSON.parse(localStorage.apiInfo || "{}");

let axiosInstance = axios.create({

  baseURL: apiInfo.portal_url,
  // baseURL: 'http://localhost:8888',
  //baseURL: 'http://localhost:4002',
  timeout: 300000,
  headers: {
    'Authorization': `Bearer ${apiInfo.token}`,
  },

});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) =>{
    return Promise.reject(error)
  }

);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) =>{
    return Promise.reject(error)
  }
);
export default axiosInstance;
/**
 * @return The adapter that axios uses for dispatching requests. This may be overwritten in tests.
 *
 * @see https://github.com/axios/axios/tree/master/lib/adapters
 * @see https://github.com/ctimmerm/axios-mock-adapter/blob/v1.12.0/src/index.js#L39
 */
export const getDefaultAdapter = () => axios.defaults.adapter;
