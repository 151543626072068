<template>
    <div class="queueContainer" ref="chunkParent">
        <v-carousel hide-controls hide-delimiters :interval="12000" height="100%" light>
            <v-carousel-item v-for="(items, index) in queueChunk" :key="index">
                <div style="display: flex; width: 100%; justify-content: space-around;">
                    <div class="queueDiv" v-for="item in items" :key="item.queue">
                        <v-card class="card">
                            <div class="titleRow">
                                <div class="empty"></div>
                                <div class="queuetitle">
                                    {{item.queue}}
                                    
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="agentRow">
                                <div class="agentTotal">
                                    <div><i class="fas fa-user"></i></div>
                                    <div>{{item.agents_total}}</div>
                                </div>
                                <div class="agentGraph">
                                    <AgentGraph :pauseAgent="item.pause" :onCallAgent="item.live" :availableAgent="item.idle">
                                    </AgentGraph>
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="callRow">
                                <div class="callTotal">
                                    <div><i class="fas fa-phone"></i></div>
                                    <div>{{item.call_total}}</div>
                                </div>
                                <div class="callGraph">
                                    <CallGraph :successCall="item.answer_count" :failureCall="item.abandon_count" :RingingCall="item.ringing_count" :waitingCall="item.waiting_count">
                                    </CallGraph>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<script>
import AgentGraph from './Components/AgentGraph';
import CallGraph from './Components/CallGraph';
import { mapGetters } from 'vuex';
export default {
    components: {
        AgentGraph,
        CallGraph
    },
    data() {
        return {

            windowWidth: 1100,
        }
    },
    computed: {
        ...mapGetters([
            "queuestatus"
        ]),

        queueChunk() {

            return this.queuestatus.chunk(3, this.windowWidth, 275);
        }
    },
    methods: {
        getWindowWidth() {
            this.windowWidth = this.$refs.chunkParent.clientWidth;
        }
    },
    mounted() {
        var that = this;
        that.$nextTick(function () {
            that.getWindowWidth()
        })
    }
};
</script>
<style scoped>
.queueContainer {
    display: flex;
    padding: 0px 30px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.queueDiv {
    padding: 5px;
    display: flex;
    width: 33%;
}

@media screen and (min-width: 100px) and (max-width: 700px) {
    .queueDiv {
        padding: 5px;
        display: flex;
        width: 100%;
    }
}

.card {
    width: 100%;
}

.titleRow {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-between;
}

.titleRow>.empty {
    width: 20%;
    height: 30px;
    border-right: 1px solid #ccc;
}

.titleRow>.queuetitle {
    width: 80%;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 4px;
}

.agentRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.agentRow>.agentTotal {
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 110px;
    padding: 5px;
    border-right: 1px solid #ccc;
}

.agentRow>.agentGraph {
    display: flex;
    width: 80%;
    height: 110px;
}

.callRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.callRow>.callTotal {
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60px;
    padding: 5px;
    border-right: 1px solid #ccc;
}

.callRow>.callGraph {
    display: flex;
    width: 80%;
    /* height: 60px; */
}

.v-carousel {
    box-shadow: 0px 0px;
}
</style>