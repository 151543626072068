import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const mutations = {
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    insertTeam: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/team/create`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetTeam: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/team/fetch?skip=${payload.skip}&take=${payload.take}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterTeam: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/team/fetch`,{ params: query }).then(response => {
                    
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteTeam: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/team/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateTeam: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/team/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    mutations,
    actions
};