import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterSms;
var getters = {
  Getastersms: (state) => state.AsterSms.getdata,
};
const mutations = {
  [mutation_type.AsterSms]: (state, response) => {
    state.AsterSms.getdata = response;
  },
};
const actions = {
  GetSmsConfig: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        api.get(`/sms/config`).then((response) => {
          resolve(response.data);
        });
      } catch (error) {
        commit(mutation_type.CommitNothing, {});
        reject(error);
      }
    });
  },
  UpdateSmsConfig: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .post(`/sms/config`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAsterSms: ({ commit }, query) => {
    return new Promise((resolve, reject) => {
      try {
        let skip ;
                let take;
                let name;
                if(query == undefined){
                    skip = 0;
                    take = 10;
                    name = ''
                }else{
                    skip = query.skip;
                    take = query.take;
                    name = query.name;
                }
        api
          .get(
            `/aster/sms?skip=${skip}&take=${take}&name=${name}`
          )
          .then((response) => {
            const astersms = response.data;
            resolve(response.data);
            commit(mutation_type.AsterSms, astersms);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  FilterAsterSms: ({ commit }, query) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .get(
            `/aster/sms?name=${query.name}&skip=${query.skip}&take=${query.take}`
          )
          .then((response) => {
            const astersms = response.data;
            resolve(response.data);
            commit(mutation_type.AsterSms, astersms);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  insertAsterSms: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .post(`/aster/sms`, payload)
          .then((response) => {
            const astersms = response.data;
            resolve(response);
            commit(mutation_type.AsterSms, astersms);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteAsterSms: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .delete(`/aster/sms/${payload}`)
          .then((response) => {
            const astersms = response.data;
            resolve(response);
            commit(mutation_type.AsterSms, astersms);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateAsterSms: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        const id = payload.id;
        delete payload.id;
        api
          .put(`/aster/sms/${id}`, payload)
          .then((response) => {
            const astersms = response.data;
            resolve(response);
            commit(mutation_type.AsterSms, astersms);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
