import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
// var getters = {
//     Music: state => state.AudioStore.Music,
// };
const mutations = {
    [mutation_type.Disposition]: (state, response) => {
        state.Disposition = response;
    },
    [mutation_type.CommitNothing]: () => { },
    setAudio: (state, response) => {
        state.Music = new Audio(response);
    }
};
const actions = {
    setAudio: ({ commit }, payload) => {
        console.log("setAudio", payload);
        commit("setAudio", payload);
    },
    GetAudio: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {

                let skip;
                let take;
                if (query != undefined) {

                    skip = query.skip;
                    take = query.take;
                } else {
                    skip = "";
                    take = "";
                }
                let filter = "";
                if(query.name){
                    filter +=`&name=${query.name}`
                }
                if(query.status){
                    filter +=`&status=${query.status}`
                }
                api.get(`/audio?skip=${skip}&take=${take}`+filter).then(response => {
                    resolve(response);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FindAudio: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/audio?status=${query}`).then(response => {
                    resolve(response);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    // FilterAudio: ({ commit }, query) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/audio?name=${query.name}&status=${query.status}`).then(response => {
    //                 resolve(response);
    //             });
    //         } catch (error) {
    //             commit(mutation_type.CommitNothing, {});
    //             reject(error);
    //         }
    //     });
    // },
    deleteAudio: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/audio/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAudio: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/audio/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    // getters,
    mutations,
    actions
};