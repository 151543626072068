<template>
  <div>
    <!-- <div v-if="loading" :style="{ width:filter_width,height: 327.7+'px', bottom: 5+'px' }
    
" class="box">
            <div  class="loader"></div>
        </div> -->
    <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series"></VueApexCharts>
    <div v-if="loading" :style="{ width:filter_width,height: 330.7+'px', bottom: 5+'px' }" :class="lds_ellipsis">
        <div class="lds-ellipsiss">
          <div></div><div></div><div></div><div></div>
        </div>
        </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts,

  },
  props: {

    alldata: {
      type: Array
    },
    toolbarTrigger: {
      type: Boolean,
      default: true
    },

    timer: {
      type: Number,
      default: 10000,
    },
    id: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: "",
      validator: function (val) {
        return typeof val === "string";
      },
    },
  },
 
  mounted() {

    if (localStorage.apply_pass) {
      let obj = JSON.parse(localStorage.apply_pass);
      if (obj.filter_type >= 0 && obj.filter_metrics.length>0) {
        this.watch_trigger = obj.filter_type;
      }

    }
  },
  watch: {
    watch_trigger() {

      let obj = JSON.parse(localStorage.apply_pass);
      if (obj.filter_metrics[0].widget_id === this.id) {
        this.filter_fun(obj)
      }
    }
  },
  created() {
    const scope = this;
    this.chartOptions.chart.toolbar.show = this.toolbarTrigger
    if (this.toolbarTrigger) {
      this.chartOptions.title.margin = 5
    }
    const payload = {
      id: scope.id,
      skip: 0,
      take: 20,
    };
    scope.loading = true;
    scope.$store
      .dispatch("rect/renderWidgetData", payload)
      .then((result) => {
        scope.dataPoints = JSON.parse(JSON.stringify(result));
        this.series = scope.dataPoints[0]
        scope.dataPoints[1].forEach((val) => {
          //let date = val.split('T')
          this.chartOptions.xaxis.categories.push(val)
        })

        scope.loading = false;
      })
      .catch((err) => {
        console.log("Error(Bar) ==>", err);
        scope.loading = false;
      });
    scope.fillData();
  },
  // updated() {
  //   const scope = this;
  //   if (scope.activePreview.update) {
  //     scope.dataPoints = [];
  //     const payload = {
  //       id: scope.id,
  //       skip: 0,
  //       take: 20,
  //     };
  //     scope.loading = true;
  //     scope.$store
  //       .dispatch("rect/renderWidgetData", payload)
  //       .then((result) => {
  //         scope.dataPoints = JSON.parse(JSON.stringify(result));
  //         this.series = scope.dataPoints[0]
  //         scope.dataPoints[1].forEach((val) => {
  //           // let date = val.split('T')
  //           this.chartOptions.xaxis.categories.push(val)
  //         })
  //         scope.loading = false;
  //       })
  //       .catch((err) => {
  //         console.log("Error(Bar) ==>", err);
  //         scope.loading = false;
  //       });
  //     scope.$store.dispatch("rect/updatePreview", scope.activePreview);
  //   }
  // },
  data() {
    return {
      lds_ellipsis:"lds-ellipsiss-inside",
      filter_width:`${this.alldata[0].Movable[0].width}px`,
      dataPoints: null,
      polling: null,
      loading: false,
      watch_trigger: null,
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            export: {
                            csv: {
                                filename: this.name,
                            },
                            svg:{
                                filename: this.name,
                            },png:{
                                filename: this.name,
                            }
                        },
            tools: {
             customIcons: [{
                                icon: '<img src="/static/filter-icon-14.png" width="17 ">',
                                //index: 4,
                                title: 'filter',
                                class: 'custom-icon',
                                click: () => {
                                    return this.triggers()
                                }
                            },
                            {
                                icon: '<img src="/static/reset.png" width="17 ">',
                                //index: 4,
                                title: 'reload',
                                class: 'custom-icon',
                                click: () => {
                                    return this.reset_triggers()
                                }
                            }]
            },
            show: true
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        title: {
          text: this.name,
          margin: 25
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },

    }
  },
  methods: {
    triggers() {this.loading = true;
      this.$emit("popup");
    },
      preview() {
this.chartOptions.chart.toolbar.tools.customIcons.length = 0;
        },
    reset_triggers() {
      let obj = {
        filter_metrics: this.alldata[0].filter_metrics,
        filter_type: 2,

      }
      this.filter_fun(obj)
      localStorage.setItem("apply_pass", JSON.stringify({}));
      this.$emit("reset");
    },
    filter_fun(obj) {
      this.$store.dispatch("rect/temp_filter_api", obj)
        .then(() => {
          this.updatefun();

        })
    },
    updatefun() {
      const scope = this;
      const payload = {
        id: scope.id,
        skip: 0,
        take: 20,
      };

      scope.$store
        .dispatch("rect/renderWidgetData", payload)
        .then((result) => {
          this.chartOptions.chart.toolbar.show = this.toolbarTrigger
          if (this.toolbarTrigger) {
            this.chartOptions.title.margin = 5
          }
          this.chartOptions.xaxis.categories = []
          scope.dataPoints = JSON.parse(JSON.stringify(result));
          this.series = scope.dataPoints[0]
          scope.dataPoints[1].forEach((val) => {
            //let date = val.split('T')
            this.chartOptions.xaxis.categories.push(val)

          })
        })
        .catch((err) => {
          console.log("Error(Bar) ==>", err);
        })
    },
    fillData() {
      const scope = this;

      scope.polling = setInterval(() => {

        const payload = {
          id: scope.id,
          skip: 0,
          take: 20,
        };
        scope.$store
          .dispatch("rect/renderWidgetData", payload)
          .then((result) => {
            this.chartOptions.chart.toolbar.show = this.toolbarTrigger
            if (this.toolbarTrigger) {
              this.chartOptions.title.margin = 5
            }
            this.chartOptions.xaxis.categories = []
            scope.dataPoints = JSON.parse(JSON.stringify(result));
            this.series = scope.dataPoints[0]
            scope.dataPoints[1].forEach((val) => {
              //let date = val.split('T')
              this.chartOptions.xaxis.categories.push(val)

            })
          })
          .catch((err) => {
            console.log("Error(Bar) ==>", err);
          });
      }, scope.timer);

    },
  },
  beforeDestroy() {

    clearInterval(this.polling);
  },

}
</script>
<style>
.box {
    background: #fffcfc;
    opacity: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    box-shadow: 4px 4px 20px rgba(255, 255, 255, 0.3);
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;

    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 6;
}
.custom-icon {
  margin-bottom: 2px;
  margin-left: 5px
}
.lds-ellipsiss-inside {
  position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff75;
    /* background-color: #f321e9; */
    display: flex;
    justify-content: space-evenly;
    z-index: 11;
    
}

.lds-ellipsiss {
  position: relative;
    top: 38%;
    right: 7%;
}

.lds-ellipsiss div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2196f3;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsiss div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsiss1 0.6s infinite;
}
.lds-ellipsiss div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsiss2 0.6s infinite;
}
.lds-ellipsiss div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsiss2 0.6s infinite;
}
.lds-ellipsiss div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsiss3 0.6s infinite;
}
@keyframes lds-ellipsiss1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsiss3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsiss2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>