<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :chatOptions="page.ChatOptions" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @ChatOptionData="chatSwitch" @exportData="exportCallDetailReport"
      :style="styleObject"></section-definition>

    <loader v-if="page.loading"> {{ "if" }}</loader>

    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers1" :items="walogreport" class="elevation-1" :pagination.sync="pagination"
        :total-items="pagecount" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:headers="props" id="table-scroll">
          <tr style="height: 30px">

            <th style="max-width: 150px; text-align: center" v-for="head in props.headers" :key="head.name">
              {{ head.text }}
            </th>
            <th style="text-align: center">Action </th>
          </tr>
        </template>


        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">

          <td class="checkEmpty p0">{{ props.item.session_id }}</td>
          <td class="checkEmpty p0">{{ props.item.created_at }}</td>
          <td class="checkEmpty p0">{{ props.item.did_number }}</td>
          <td class="checkEmpty p0">{{ props.item.campaign }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.msg_from }}</td>
          <td class="checkEmpty p0">{{ props.item.agent }}</td>
          <td class="checkEmpty p0">{{ props.item.modified_at }}</td>
          <td class="checkEmpty p0"> <v-icon v-show="page.canedit" @click="info(props.item.session_id)" small
              class="mr-4">info</v-icon></td>

          <!-- <td style="text-align: center">{{"Action"}}</td> -->
        </template>

      </v-data-table>
    </v-container>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <label>{{ "Created At" }}</label>
            <div class="datetimefilter">
              <v-container class="pa-1">
                <v-menu v-model="page.menu.fromdate" :close-on-content-click="false" label="Created At" full-width
                  max-width="290" small>
                  <template v-slot:activator="{ on }">
                    <label>From</label>
                    <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                      type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.fromdate"
                      :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"
                      @change="page.menu.fromdate = false"></datetime>
                  </template>


                </v-menu>
              </v-container>

              <v-container class="pa-1">
                <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width max-width="290" small>

                  <template v-slot:activator="{ on }">
                    <label>To</label>
                    <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                      type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.todate"
                      :value="appliedfilter.todate" label="To Date" readonly v-on="on"
                      @change="pagecontrol.menu.fromdate = false"></datetime>
                  </template>
                </v-menu>
              </v-container>
            </div>
          </div>

          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.didnumber" label="Didnumber"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.campaign" label="Campaign"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.callername" label="Callername"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.phonenumber" label="Phonenumber"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.agent" label="Agent"></v-text-field>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

  </div>
</template>
  
<script>
import moment from "moment";
export default {
  mounted() {

    let scope = this;
    scope.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:whatsappchatlog:export" //whatsappchatlog
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
        scope.page.ChatOptions = ["whatsapp", "website"];

      }.bind(scope),
      1
    );
    this.getDataFromApi();
  },
  watch: {
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      this.skip = 0;

    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.skip = 0;

    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      payload: {

        skip: 0,
        take: 0,

      },
      page: {
        title: "whatsapp Chat Log",
        default_title: 'whatsapp',
        icon: "fa fa-commenting fa-2x",
        loading: true,
        isFilterOpen: false,
        canedit: true,

        breadcrumbs: [],
        exportOptions: [],
        ChatOptions: [],
        options: { filter: true, refresh: true },
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      pagination: {
        rowsPerPage: 10,
      },
      headers1: [
        {
          text: "Session Id",
          align: "left",
          sortable: false,
          class: "p0",
          value: "SessionId",
        },
        {
          text: "Session StartTime",
          align: "left",
          sortable: false,
          class: "p0",
          value: "SessionStartTime",
        },
        {
          text: "Did Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "DidNumber",
        },
        {
          text: "Campaign",
          align: "left",
          sortable: false,
          class: "p0",
          value: "Campaign",
        },
        {
          text: "Caller Name",
          align: "left",
          sortable: false,
          class: "p0",
          value: "CallerName",
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "PhoneNumber",
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "Agent",
        },
        {
          text: "Session EndTime",
          align: "left",
          sortable: false,
          class: "p0",
          value: "SessionEndTime",
        },
        // {
        // text: "Action",
        // align: "left",
        // sortable: false,
        // class: "p0",
        // value: "SessionEndTime",
        // }
      ],
      skip: 0,
      take: 0,

      total: "",

      report: "",
      exports: [],
      walogreport: [],
      response: [],
      pagecount: 0,
      currentcount: 0,
      excel_data: [],
      txt_data: [],
      // pdf_data: [],
      from_date: "",
      to_date: "",
      dialog: false,
      appliedfilter: {
        fromdate: "",
        todate: "",
        didnumber: "",
        campaign: "",
        callername: "",
        phonenumber: "",
        agent: "",


      },
    };
  },

  methods: {
    datetimeformate(data) {
      let datetime = new Date(data)
      let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
      let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
      let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
      let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      let sec = datetime.getSeconds() < 10 ? `0${datetime.getSeconds()}` : datetime.getSeconds();
      return datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}:${sec}`
    },


    reset: function () {
      let scope = this;
      this.page.isFilterOpen = false;

      scope.appliedfilter.fromdate = "";
      scope.appliedfilter.todate = "";
      scope.appliedfilter.didnumber = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.callername = "";
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.agent = "";
      scope.getDataFromApi();

    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      let query = {
        skip: skip,
        take: take,
        from_date: this.appliedfilter.fromdate != "" ? this.datetimeformate(this.appliedfilter.fromdate) : "",
        to_date: this.appliedfilter.todate != "" ? this.datetimeformate(this.appliedfilter.todate) : "",
        didnumber: this.appliedfilter.didnumber,
        campaign: this.appliedfilter.campaign,
        callername: this.appliedfilter.callername,
        phonenumber: this.appliedfilter.phonenumber,
        agent: this.appliedfilter.agent,
        channel: this.page.default_title
      };
      this.skip = skip;
      this.$store
        .dispatch("Getchatlog", query)
        .then((response) => {

          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          scope.walogreport = datas;
          this.pagecount = response.data.total_count[0].count;
          this.currentcount = response.data.current_count;

        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });

    },
    refreshThisPage: function () {

      var scope = this;
      scope.pagination.page = 1;
      scope.page.loading = true;

      scope.appliedfilter.fromdate = "";
      scope.appliedfilter.todate = "";
      scope.appliedfilter.didnumber = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.callername = "";
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.agent = "";
      scope.pagination.page = 1;
      scope.getDataFromApi();
    },
    chatSwitch: function (option) {
      this.page.title = option + ' Chat Log '
      this.page.default_title = option
      this.getDataFromApi()
    },
    exportCallDetailReport: function (option) {
      console.log("optionoption", option)
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        // this.pdf_flag = true;
      }
    },
    export(option) {

      this.excel_data = [];
      this.txt_data = [];

      var export_date = {
        calldetail: "exportchatdetail",

      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response.data.data;

        for (var i = 0; i < this.exports.length; i++) {

          this.key = Object.keys(this.exports[i]);
          this.value = Object.values(this.exports[i]);
          var values = this.value;


          this.excel_data.push({
            header: values,
          });
          this.txt_data.push(Object.values(this.exports[i]));
        }


        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = this.key;
          let txt_tab = txt_key.join("\t");
          console.log("rowrowrowrowrow", txt_tab)
          txtContent += `${txt_tab} \r\n`; this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");

            txtContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "Wachatreport" + date1 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          let csvContent = '';
          csvContent += `${this.key} \r\n`;
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1[0].length; i++) {
              if (
                data1[0][i] != null &&
                data1[0][i] != undefined &&
                data1[0][i] != "" && typeof data1[0][i] == "string"
              ) {
                if (data1[0][i].includes(",")) {
                  data1[0][i] = data1[0][i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "Wachatreport" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      });
    },
    info(id) {

      let obj = {};
      obj.session_id = id
      localStorage.setItem("chatdetailview", JSON.stringify(obj));
      this.$router.push({
        name: "report/WapReportDetail",
      });
    }



  }

}
</script>