<template>
	<div>
		<highcharts class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
	</div>
</template>
<script>
	export default{
		props:{
			pauseAgent:{
				required: false,
				default:0
			},
			onCallAgent:{
				required: false,
				default:0
			},
			availableAgent:{
				required: false,
				default:0
			}
		},
		data(){
			return{
				updateArgs: [true, true, {duration: 1000}],
			}
		},
		computed:{
			chartOptions(){
				var options= {
					chart: {
						type: 'column',
						width:250,
						height:110
					},
					title: {
						text: ''
					},
					colors: ['#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'],
					legend: {
						enabled: true,
						align: 'right',
						verticalAlign: 'middle',
						itemStyle: {
							fontSize: '9px',
							fontWeight:'normal'
						}
					},
					xAxis: {
						categories: ['Queue'],
						labels:{
							enabled:false
						}
					},
					yAxis: {
						allowDecimals: false,
						min: 0,
						title: {
							text: ''
						}
					},
					tooltip: {
						formatter: function () {
							return '<b>' + this.x + '</b><br/>' +this.series.name + ': ' + this.y + '<br/>' +'Total: ' + this.point.stackTotal;
						}
					},
					plotOptions: {
						column: {

						}
					},
					series: [
						{
							name: 'Pause',
							data: [this.pauseAgent]
						},
						{
							name: 'Oncall',
							data: [this.onCallAgent]
						},
						{
							name: 'Available',
							data: [this.availableAgent]
						}
					]
				}
				return options;
			}
		}
	}
</script>