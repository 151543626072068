var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-img',{staticClass:"page-vimg",attrs:{"src":_vm.backgroundImg}},[_c('v-container',{attrs:{"fill-height":"","pa-0":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"text-xs-center margin-auto border-radius6 box-white-500-glow elevation-10 auth-box"},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","fill-height":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-center mt-3",attrs:{"xs12":""}},[(_vm.domain_name())?_c('img',{staticClass:"text-xs-center",staticStyle:{"width":"160px","height":"60px"},attrs:{"src":"/static/doocti.png","alt":"Vuse","height":"100"}}):_vm._e(),(_vm.domain_name() === false &&
                _vm.domail_namevalidate('console.gtawk')
                )?_c('img',{staticClass:"text-xs-center",staticStyle:{"width":"160px","height":"60px"},attrs:{"src":"/static/gtawk.png","alt":"Vuse","height":"100"}}):_vm._e(),(_vm.domain_name() === false &&
                _vm.domail_namevalidate('localhost')
                )?_c('img',{staticClass:"text-xs-center",staticStyle:{"width":"160px","height":"60px"},attrs:{"src":"/static/doocti.png","alt":"Vuse","height":"100"}}):_vm._e(),(_vm.domain_name() === false &&
                _vm.domail_namevalidate('console-v2.doocti.com'))?_c('img',{staticClass:"text-xs-center",staticStyle:{"width":"160px","height":"60px"},attrs:{"src":"/static/doocti.png","alt":"Vuse","height":"100"}}):_vm._e(),(_vm.domain_name() === false &&
                _vm.domail_namevalidate('cc.callcentersolutions.ae'))?_c('img',{staticClass:"text-xs-center",staticStyle:{"width":"160px","height":"60px"},attrs:{"src":"/static/ccsd.jpg","alt":"Vuse","height":"100"}}):_vm._e(),_c('h3',[_vm._v("Admin Login")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();_vm.$v.$invalid ? _vm.invalid() : _vm.submit(_vm.form)}}},[_c('v-layout',{attrs:{"wrap":"","pa-4":""}},[(true)?_c('v-flex',{attrs:{"xs12":"","pa-0":""}},[_c('v-text-field',{attrs:{"readonly":false,"disabled":false,"placeholder":`${'User Name'}`,"required":"","error-messages":_vm.fieldErrors('form.email'),"label":`${'UserName'}`},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{attrs:{"label":"Password","type":_vm.passwordFieldType,"required":"","error-messages":_vm.fieldErrors('form.password'),"append-icon":"mdi-eye"},on:{"click:append":_vm.togglePasswordVisibility},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","text-xs-center":""}},[_c('v-flex',{staticClass:"no-mrpd",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"act","type":"submit","block":"","disabled":_vm.form.password.length === 0 || _vm.form.email.length === 0}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"right":true,"bottom":true,"color":'red',"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"color":"black","flat":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v("Close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }