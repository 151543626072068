<template>
  <v-row>
    <div style="width: 250px">
      <div style="width: 250px">
        <v-dialog
          ref="dialog"
          v-model="startmodal"
          :return-value.sync="startdate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            style="width:230px"
              v-if="start === ''"
              v-model="display_start_date"
              label="Picker start date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="start"
              label="Picker start date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker color="primary" v-model="startdate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="startmodal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="startsavedate()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
      <div style="width: 250px">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-if="end === ''"
              style="width:230px"
              v-model="display_end_date"
              label="Picker end date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="end"
              label="Picker end date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker color="primary" v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="endsavedate()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </div>
  </v-row>
</template>
<script>
export default {
 
  props: {
    start: {
      type: String,
      default: "",
    },
    end: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    start_date: "",
    end_date: "",
    display_start_date: "",
    startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    startmodal: false,
    display_end_date: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
  }),
  methods: {
    startsavedate() {
      (this.startmodal = false), (this.display_start_date = this.startdate);
   
      if (this.display_end_date === "") {
        this.display_end_date = this.date;
      }
      this.passdata();
    },
    endsavedate() {
      (this.modal = false), (this.display_end_date = this.date);
      if (this.display_start_date === "") {
        this.display_start_date = this.startdate;
      }
      this.passdata();
    },
    passdata() {
      this.$emit("childdate", {
        dbdata: `${this.display_start_date},${this.display_end_date}`,
        display_start_date: this.display_start_date,
        display_end_date: this.display_end_date,
      });
    },
  },
};
</script>