import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
import config from "@/config/index";
const state = config.CustomForm;
// var getters = {
//     form: state => state.CustomForm.forms
   
// }
const mutations = {
    [mutation_type.CommitNothing]: () => {},
    [mutation_type.CustomForm]: (state, response) => {

        try {
            const form = response.fields;
            const conversion = form.map((value) => {
                for (var key in value) {
                    console.log(value.fieldType,value.values,"value[key]")
                    if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType === 'SelectList' || value.fieldType === 'RadioButton' || value.fieldType === 'Checkbox') ) {
                        let options = JSON.parse(value.values);
                        value.values = options;
                    } else if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType !== 'SelectList' && value.fieldType !== 'RadioButton' && value.fieldType !== 'Checkbox') && Array.isArray(value.values)) {
                        value.values = value.values[0];
                    }
                    if (value.hasOwnProperty(key) && (value[key] === 'true' || value[key] === 'false')) {
                        value[key] = (value[key] === 'true');
                    }
                    if (value.hasOwnProperty(key) && key == "id") {
                        delete value[key];
                    }
                    if (value.link_property !== '' && value.link_property !== undefined && value.link_property && value.fieldType === 'TextInput' && key === 'link_property') {
                        value[key] = value.link_property.map;
                    }
                }
                return value;
            });
           
            const data = [{ "fields": conversion }];
            if (response.template_name) {
                data[0].template_name = response.template_name
            }
            if (response.type) {
                data[0].type = response.type
            }
            if (response.active) {
                data[0].active = response.active
            }
            localStorage.setItem("forms", JSON.stringify(data));
            localStorage.setItem("originalForm", JSON.stringify(data));
            
            state.CustomForm.forms = data;
        } catch (err) {
            return err;
        }
    },
};

const actions = {
  GetAsterContacts: (_,query) => {
    return new Promise((resolve, reject) => {
      console.log("queryyy is", query);
      try {
        let url
        if (query.combinedSearch !== undefined && query.combinedSearch.name !== "" && query.combinedSearch.phone_number !== "") {

          url = `/contact/fetchsearch?name=${query.combinedSearch.name}&number=${query.combinedSearch.phone_number}&skip=${query.skip}&take=${query.take}`;
        } else if (query.value !== "" && query.value !== undefined) {
          url = `/contact/fetchsearch?value=${query.value}&skip=${query.skip}&take=${query.take}`
        } else {
          url = `/contact/fetchsearch?skip=${query.skip}&take=${query.take}`;
        }
        api
          .get(
            url
          )
          .then((response) => {
            const contact = response.data;
            
            resolve(contact);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  GetAsterContactsFields: ({commit}) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .get(
            `/contactfields/fetch`
          )
          .then((response) => {
            const contactfield = response.data;
            const obj={}
            obj.active='y'
            obj.fields=response.data.fields
            obj.template_name='ticket'
            obj.type='ticket'
           commit(mutation_type.CustomForm, obj);
            resolve(contactfield);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  
  insertAsterContact: ( _,payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .post(`/contact/create`, payload)

          .then((response) => {
            const contact = response.data;

            resolve(contact);
          })
          .catch((err) => {
            if (err.Error === " Request failed with status code 409") {
              reject("duplicate");
            } else {
              reject(err);
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteAsterContact: (_, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .delete(`/contact/delete/${payload.id}/${payload.modified_by}`)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  bulkdeleteAsterContact: (_,payload) => {
    return new Promise((resolve, reject) => {
      try {      
        api .post(`/contact/bulkdelete`,payload)
          .then((response) => {
            resolve(response);  
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateAsterContact: ( _,payload) => {
    return new Promise((resolve, reject) => {
      try {
        const id = payload.contact_id;
        console.log(payload,"yyyyyytyy")
        delete payload.contact_id;
        api
          .put(`/contact/update/${id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
};
export default {
  state,
  actions,
  mutations,
  
};
