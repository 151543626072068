// import config from "@/config/index";
import api from "../../utils/axios-util";
// import * as mutation_type from "../mutation-types";

const actions = {
    GetShift: (_, query) => {
        let condition = ""

    console.log('inside getshift api the query is ', query)
        if (query.name) {
            condition += `&name=${query.name}`

        }
        // if (query.status) {

        //     condition += `&status=${query.status}`

        // }

        return new Promise((resolve, reject) => {
            try {
                api.get(`/shift?skip=${query.skip}&take=${query.take}` + condition).then(response => {

                    // commit(mutation_type.CommitNothing, {});
                    //console.log(" LIST OF ALL DATA FROM MY DATABASE inside getshift api :",response)
                    resolve(response.data);
                });
            } catch (error) {
                //console.log("fetch api call to the url is failed")
                reject(error);
            }
        });
    },

    insertShift: (_, payload) => {
        //alert("create api triggered inside service")
        return new Promise((resolve, reject) => {
            console.log(payload," insert shift")
            try {
                api
                    .post(`/shift`, payload)
                    .then(response => {
                        resolve(response);
                        console.log("response of create api ", response)
                        // commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        console.log("your insert api rejected")
                        reject(error);
                    });
            } catch (error) {
                
                reject(error);
            }
        });
    },
    deleteShift: (_, payload) => {
        console.log("inside delete api")
        return new Promise((resolve, reject) => {
            console.log("this is inside delete",payload)
            try {
                api
                    .delete(`/shift/delete/${payload}`)
                    .then(response => {
                        console.log(response)
                        resolve(response);
                        // commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateShift: (_, payload) => {
        console.log("inside update")
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/shift/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        // commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    actions,
  };
  