<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :exportOptions="page.exportOptions"
      @openFilter="openCallTransferFilter"
      @refreshPage="refreshCallTransferPage"
      @exportData="exportCallDetailReport"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page id="table">
      <v-data-table
        :headers="headers"
        :items="calldetails_data1"
        class="elevation-1"
        :pagination.sync="pagination"
        :total-items="calldetailtotal"
        :rows-per-page-items="[5, 10, 25]"
      >
        <template v-slot:items="props">
          <td
            class="checkEmpty p0"
            v-for="(property, index) in Object.keys(props.item)"
            :key="index"
          >
            {{ props.item[property] }}
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-navigation-drawer
      fixed
      disable-resize-watcher
      right
      :value="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div id="callLogReportFilter">
    <div class="datetimefilter">
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>From</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_fromdate">
          </datetime>
        </div>
      </div>
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>To</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_todate">
          </datetime>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <v-autocomplete v-model="appliedfilter.agent" :items="filteragents" label="Agent" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-text-field  v-model="appliedfilter.uniqueid" :items="filters.uniqueid" label="Caller id" :allow-overflow="false">
    </v-text-field>
  </div>
        </v-container>

        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-bottom-sheet
      v-model="page.playingRecord"
      hide-overlay
      persistent
      lazy
      inset
      max-width="500"
      class="align-left"
      id="barging-div"
      transparent
    >
      <v-card tile class="player-div">
        <v-toolbar color="light" small>
          <audio controls autoplay v-if="page.playingRecord">
            <source
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <v-spacer></v-spacer>
          <div class="row justify-fe">
            <v-btn icon>
              <i class="fas fa-download"></i>
            </v-btn>
            <v-btn icon>
              <v-icon @click="togglePlayingRecordSheet('close', {})"
                >close</v-icon
              >
            </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    loader,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.dateformate();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:calldetail:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["calldetails_data", "queue"]),
 
    ...mapGetters(["filters", "filter"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },

      page: {
        title: "Call Transfer",
        icon: "fa-address-card fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      submit: "",
      filter_fromdate: "",
      filter_todate: "",
      filteragents: [],
      pagination: {
        rowsPerPage: 10,
      },
      headers: [],
      calldetailreport: [],
      calldetails_data1: [],
      calldetailtotal: 0,
      skip: 0,
      take: 0,
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        detail: "calldetails",
        descending: "",
        export: "exportcalldetails",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      from_date: "",
      to_date: "",
      dialog: false,
      page_flag: false,
      appliedfilter: {
        type: "",
        agent: "",
        todate: "",
        uniqueid: "",
        value: "",
      },
    };
  },
  watch: {
    appliedfilter: {
      handler: function () {
        if (
          this.appliedfilter.fromdate == this.filters.fromdate &&
          this.appliedfilter.todate == this.filters.todate
        ) {
          this.date_flag = false;
          this.filter_fromdate = this.appliedfilter.fromdate;
          this.filter_todate = this.appliedfilter.todate;
        }
      },
      deep: true,
    },
    filter_fromdate(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
    },
    filter_todate(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
    },
    "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
    },
    "appliedfilter.uniqueid"(newVal) {
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: newVal });
    },
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.payload.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },

  },
  methods: {
    openCallTransferFilter: function () {
      this.filter_fromdate = moment(this.filters.fromdate).format();
      this.filter_todate = moment(this.filters.todate).format();
      this.page.isFilterOpen = true;
      this.filteragents = JSON.parse(localStorage.getItem("group_users"));
    
    },
    closeModel: function () {
      this.dialog = false;
    },
    refreshCallTransferPage: function () {
      var scope = this;
      scope.dateformate();
      this.pagination.page = 1;
      this.skip = 1;
      scope.getDataFromApi();
      scope.page.loading = true;
      scope.refreshvalue();
      scope.appliedfilter.agent = "";
      scope.appliedfilter.uniqueid = "";
      scope.appliedfilter.value = "";
    },
    exportCallDetailReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
      }
    },
    togglePlayingRecordSheet: function (event, item) {
      if (event == "close") {
        this.page.playingRecord = false;
        this.playingItem = item;
      } else {
        this.page.playingRecord = true;
        this.playingItem = item;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 200;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFromApi() {
      let desiredOrder = [
        "uniqueid",
        "start_time",
        "phone_number",
        "type",
        "agent",
        "agent_to",
        "to1",
        "status",
        "duration",
        "start_time",
        "end_time",
      ];
      var scope = this;
      this.payload.to_date = this.to_date;
      this.payload.from_date = this.from_date;
      this.payload.value = this.appliedfilter.value;
      var { page, rowsPerPage } = this.pagination;
      let dataa = JSON.parse(localStorage.getItem("group_users"));
      console.log(dataa, "this i sda");
      scope.payload.agent = dataa;
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }

      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise((resolve) => {
        var { descending } = scope.pagination;
        this.payload.descending = descending;
        scope.$store
          .dispatch("callTransferDetails", this.payload)
          .then((response) => {
            var datas = response.data;
            scope.calldetails_data1 = datas;
            let values_ = scope.calldetails_data1;
            let values = [];

            for (let i = 0; i < values_.length; i++) {
              let originalObject = values_[i];
              const rearrangedObject = {};
              const unmatchedKeys = Object.keys(originalObject).filter(
                (key) => !desiredOrder.includes(key)
              );

              desiredOrder.forEach((key) => {
                if (originalObject.hasOwnProperty(key)) {
                  rearrangedObject[key] = originalObject[key];
                }
              });

              unmatchedKeys.forEach((key) => {
                rearrangedObject[key] = originalObject[key];
              });

              values.push(rearrangedObject);
            }

            scope.calldetails_data1 = values;
            if (values.length) {
              scope.headers = [];
              Object.keys(values[0]).forEach((element) => {
                scope.headers.push({
                  text: element
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")==="To1"? "To":element.split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
                  align: "left",
                  sortable: false,
                  class: "p0",
                  value: element.toLowerCase,
                });
              });
            }
            scope.page.loading = false;
            scope.total = response.total;
            if (scope.page_flag) {
              this.pagination.page = 1;
              scope.page_flag = false;
            }
            scope.calldetailtotal = scope.total;
            resolve(scope.total);
            if (response.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
          });
        scope.page.isFilterOpen = false;
      });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },
    resetvalue() {
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format();
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 200;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format();
      this.refreshvalue();
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.agent = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      this.appliedfilter.uniqueid = "";
      this.appliedfilter.value = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.getDataFromApi();
      this.page.isFilterOpen = false;
    },
    export(option) {
      this.excel_data = [];
      this.txt_data = [];
      let dataa = JSON.parse(localStorage.getItem("group_users"));
      var export_date = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        calldetail: "exportCallTransferDetails",
        team_user: dataa,
      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          this.key = Object.keys(response[i]);
          this.value = Object.values(response[i]);
          var values = this.value;
          this.excel_data.push({
            header: values,
          });
          this.txt_data.push(Object.values(response[i]));
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = this.key;
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "CallTransferDetails" + date1 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          let csvContent = "";
          csvContent += `${this.key} \r\n`;
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1[0].length; i++) {
              if (
                data1[0][i] != null &&
                data1[0][i] != undefined &&
                data1[0][i] != "" &&
                typeof data1[0][i] == "string"
              ) {
                if (data1[0][i].includes(",")) {
                  data1[0][i] = data1[0][i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "CallTransferDetails" + date + ".csv");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
      this.page_flag = true;
    },
  },

  beforeDestroy() {
    this.$store.commit("FILTER_AGENT", { agent: "" });
    this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
  },
};
</script>