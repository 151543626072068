<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="dispo_data" class="elevation-1 fixed-layout" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.dispo_id }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">{{ props.item.active }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Disposition</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>{{ deletemsg }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isAddDisposition" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create Disposition</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="DisportionMain" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addDisposition.payload.dispo_id" required label="Disposition" type="text"
                      :rules="[(v) => !!v || 'dispo_id is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addDisposition.payload.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="addDisposition.payload.action" required label="Action" :items="ActionData"
                      :rules="[(v) => !!v || 'Action is required']"></v-select>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-switch
                      v-model="addDisposition.payload.call_back"
                      color="primary"
                      flat
                      label="Call back"
                    ></v-switch>
                  </v-flex> -->
                  <!-- <v-flex xs12>
                    <v-select v-model="addDisposition.payload.active" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex> -->
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddPausecode()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog persistent :value="page.isEditDisposition" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Update Disposition</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="editDisposition.dispo_id" required label="Disposition" type="text"
                      :rules="[(v) => !!v || 'dispo_id is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editDisposition.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editDisposition.action" required label="Action" :items="ActionData"
                      :rules="[(v) => !!v || 'Action is required']"></v-select>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-switch
                      v-model="editDisposition.call_back"
                      flat
                      color="primary"
                      label="Call back"
                    ></v-switch>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select v-model="editDisposition.active" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <!-- <v-select v-model="filter.payload.dispo_id" :items="filtervalue" label="dispo_id" small></v-select> -->
            <v-autocomplete v-model="filter.payload.dispo_id" :items="filtervalue" :loading="filter.isLoading"
              :search-input.sync="filter.search" label="Disposition" hide-no-data hide-selected></v-autocomplete>
            <v-select v-model="filter.payload.active" label="Status" :items="status"></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Disposition",
        icon: "fa-clipboard fa-2x",
        loading: false,
        isAddDisposition: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditDisposition: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Disposition",
          align: "left",
          class: "p0",
          sortable: false,
          value: "dispo_id",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      dispo_data: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      deletemsg: "",
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      ActionData: ["No Action", "Reassign", "Meeting"],
      filter: {
        payload: {
          active: "",
          dispo_id: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      editDisposition: {
        id: "",
        dispo_id: "",
        description: "",
        active: "Active",
        call_back: "",
        action: "No Action",
      },

      addDisposition: {
        valid: false,
        payload: {
          dispo_id: "",
          description: "",
          active: "Active",
          call_back: false,
          action: "No Action",
        },

        options: {
          status: ["Active", "Inactive"],
        },
      },
      status: ["Active", "Inactive"],
      camp_data: [],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.active = "";
      scope.filter.payload.dispo_id = "";
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
    },

    reset() {
      var scope = this;
      scope.filter.payload.active = "";
      scope.filter.payload.dispo_id = "";
      this.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      let data = { model: "disposition", column: ["dispo_id"] };
      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.filtervalue = datas[0].dispo_id;
        })
        .catch((err) => {
          console.log(err);
        });
      scope.tableLoading = true;

      this.page.isFilterOpen = false;
      // scope.filtervalue = [];

      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      if (scope.filter.payload.active === "Active") {
        scope.filter.payload.active = "Y";
      }
      if (scope.filter.payload.active === "Inactive") {
        scope.filter.payload.active = "N";
      }
      let query = {
        skip: skip,
        take: take,
        dispo_status: scope.filter.payload.active,
        dispo_id: scope.filter.payload.dispo_id,
      };
      this.skip = skip;

      this.$store
        .dispatch("GetDisposition", query)
        .then((response) => {
          this.pagecount = response.data.count;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            for (let i = 0; i < datas.length; i++) {
              if (datas[i].active == "Y") {
                datas[i].active = "Active";
              } else {
                datas[i].active = "Inactive";
              }
            }
          }
          scope.dispo_data = datas;
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
    },
    AddNewRule: function () {
      this.page.isAddDisposition = true;
    },
    closeEditRules: function () {
      this.page.isEditDisposition = false;
    },
    closeAddPausecode: function () {
      this.page.isAddDisposition = false;
      this.$refs.DisportionMain.reset();
    },
    saveEditRules() {
      var scope = this;

      if (scope.editDisposition.active === "Active") {
        scope.editDisposition.active = "Y";
      }
      if (scope.editDisposition.active === "Inactive") {
        scope.editDisposition.active = "N";
      }
      // if (scope.editDisposition.active === "Y") {
      //   scope.editDisposition.active = "Y";
      // }
      // if (scope.editDisposition.active === "Inactive") {
      //   scope.editDisposition.active = "N";
      // }
      if (scope.editDisposition.call_back === true) {
        scope.editDisposition.call_back = "Y";
      }
      if (scope.editDisposition.call_back === false) {
        scope.editDisposition.call_back = "N";
      }

      scope.$store
        .dispatch("updateDisposition", scope.editDisposition)
        .then(() => {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditDisposition = false;
          if (scope.editDisposition.active == "N") {
            let field = {
              fields: [
                {
                  name: "dial_statuses",
                  value: this.editDisposition.dispo_id,
                },
                {
                  name: "dispo_statuses",
                  value: this.editDisposition.dispo_id,
                },
              ],
            };
            this.$store.dispatch("CampaignRemove", field);
          }
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
      // this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
      scope.addDisposition.payload.active = "Active";
      if (scope.addDisposition.payload.active === "Active") {
        scope.addDisposition.payload.active = "Y";
      }
      if (scope.addDisposition.payload.active === "Inactive") {
        scope.addDisposition.payload.active = "N";
      }

      if (scope.addDisposition.payload.call_back === true) {
        scope.addDisposition.payload.call_back = "Y";
      }
      if (scope.addDisposition.payload.call_back === false) {
        scope.addDisposition.payload.call_back = "N";
      }

      scope.$store
        .dispatch("insertDisposition", scope.addDisposition.payload)
        .then(() => {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("Created Successfully");
          this.$refs.DisportionMain.reset();
          scope.addDisposition.payload.call_back = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.showSnackBar(error.response.data.message);
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          scope.addDisposition.payload.dispo_id = "";
          scope.addDisposition.payload.description = "";
          scope.addDisposition.payload.active = "Active";
          scope.addDisposition.payload.call_back = false;
          this.getDataFromApi();
        });
        this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(data) {
      this.delete_id = data;
      this.DeleteRowdata = true;
      let scope = this;
      this.$store
        .dispatch("CheckDataCampaign", `dispo=${data.dispo_id}`)
        .then((res) => {
          if (res.status == 200) {
            scope.deletemsg =
              "This Disposition also affected Campaign to Remove?";
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              for (const key in element) {
                if (element[key] == null || element[key] == "") {
                  delete element[key];
                }
                if (key == "process") {
                  element["campaign_process"] = element[key];
                  delete element[key];
                } else if (key == "Domain") {
                  element["workflowvalues"] = element[key];
                  delete element[key];
                } else if (key == "industry") {
                  element["Industryvalue"] = element[key];
                  delete element[key];
                }
              }
            }
            this.camp_data = res.data.data;
          } else {
            scope.deletemsg = "Are your Sure to delete this disposition?";
          }
        })
        .catch(function (error) {
          this.showSnackBar("error" + error);
        });
    },
    editItem(item) {
      console.log("items", item);
      let scope = this;
      this.page.isEditDisposition = true;
      this.editDisposition.id = item.id;
      this.editDisposition.dispo_id = item.dispo_id;

      this.editDisposition.description = item.description;
      if (item.active === "Y" || item.active === "Active") {
        scope.editDisposition.active = "Active";
      }
      if (item.active === "N" || item.active === "Inactive") {
        scope.editDisposition.active = "Inactive";
      }

      if (item.call_back === "Y") {
        scope.editDisposition.call_back = true;
      }
      if (item.call_back === "N") {
        scope.editDisposition.call_back = false;
      }
      console.log(scope.editDisposition.active, item.active);
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteDisposition", this.delete_id.id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            for (let i = 0; i < scope.camp_data.length; i++) {
              const element = scope.camp_data[i];

              let index_dial_statuses = element.dial_statuses.indexOf(
                scope.delete_id.dispo_id
              );
              if (
                index_dial_statuses != -1 &&
                element.dial_statuses[index_dial_statuses] ==
                scope.delete_id.dispo_id
              ) {
                element.dial_statuses.splice(index_dial_statuses, 1);
              }
              let index_dispo_statuses = element.dispo_statuses.indexOf(
                scope.delete_id.dispo_id
              );
              if (
                index_dispo_statuses != -1 &&
                element.dispo_statuses[index_dispo_statuses] ==
                scope.delete_id.dispo_id
              ) {
                element.dispo_statuses.splice(index_dispo_statuses, 1);
              }
              scope.$store.dispatch("updateAsterCampaign", element);
            }
            this.delete_id = "";
            scope.getDataFromApi();
            scope.showSnackBar("Disposition Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Disposition" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:dispositions:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    // let data = { model: "disposition", column: ["dispo_id"] };

    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = { model: "disposition", column: ["dispo_id"] };

        data["search"] = val;

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].dispo_id;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
