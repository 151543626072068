<template>
  <div>

    <div style="padding:10px 10px 0px 10px">{{currentField.label}}</div>
    <div>
      <v-radio-group style="padding:0px 10px 0px 10px" v-model="radio" :readonly="true" :class="[currentField.required?required_class:currentField.clearable?read_only:others]">
       
       <v-radio  style="" v-for="(n, i) in currentField.values" :key="i" :value="n.value" color="primary" :label="`${n.value}`"
          :readonly="currentField.clearable" :disabled="currentField.disabled" @change="getChildProperties()">
          </v-radio>
        
      </v-radio-group>
    </div>

  </div>

</template>

<script>
import fetchData from "@/api/fetch-data";
export default {
  name: "RadioButton",
  props: ["currentField", "Allfields"],
  data() {
    return {
      radio: "1",
      required_class:"required",
            others:"others",
             read_only:"readonly"
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  methods: {
    getChildProperties() {
      const scope = this;
     
      if (scope.currentField.is_link === true && scope.currentField.link_type === 'Parent') {

        scope.Allfields.find(elm => {
          if ((elm.is_link && elm.fieldType === 'SelectList') || (elm.is_link && elm.fieldType === 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to) {
           
              let arr = [];
              let val = elm.link_property[scope.radio].split(',');

              val.forEach(res => {
                arr.push({ "name": res, "value": res })
              })

              elm.values = arr;
            }
          } else if ((scope.currentField.multiple === false || scope.currentField.multiple === '' || scope.currentField.multiple === undefined) && (elm.is_link && elm.fieldType != 'SelectList') || (elm.is_link && elm.fieldType != 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to) {
             
              let val = elm.link_property;
            
              if (val === scope.radio) {
                elm.visible = true;
              } else {
                elm.visible = false;
              }
            }
          }
        })
      }
    },
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
    setTimeout(() => {
      if (this.currentField.model !== "") {
        const containers = document.querySelectorAll(
          `#${this.currentField.model}`
        );
        for (let container of containers) {
          if (container.classList.value === "el-radio is-checked") {
            this.radio = container.querySelector(
              "span.el-radio__label"
            ).outerText;
          }
        }
      }
    }, 500);
  },
};
</script>


<style>
 
 .required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>