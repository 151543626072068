<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :addNewData="page.addnew" @openFilter="filterClick"
      @refreshPage="refreshThisPage" :style="styleObject">
    </section-definition>
  <loader v-if="page.loading"></loader>
  
<v-layout wrap>
  <v-flex xs2 class="topfield1">
    <label class="sheduleddate">Date</label>
    <datetime class="theme-orange" type="date" format="yyyy-MM-dd" v-model="date" label="test"></datetime>
  </v-flex>

   <v-flex xs2 class="topfield2" v-if="date.length !== 0">
      <v-select
        :items="shifts"
        required
        label="Shifts"  
        item-text="name"
        item-value="id"
        return-string
        v-model="addmeetings.payload.shifts"
        persistent-hint
      ></v-select>
    </v-flex>

     <v-flex xs2 class="topfield2" v-if="date.length !== 0">
      <v-select
        :items="shifts"
        required
        label="Category"
        item-text="name"
        item-value="id"
        return-string
        v-model="addmeetings.payload.shifts"
        persistent-hint
      ></v-select>
    </v-flex>   
</v-layout>

   <!--    --------  Start Calender View     -------------  -->

   <div class="table-view" v-for="(AgentData, index) in matchingUsers" :key="index">
      <div class="table-row">
        <div class="fixed-cell-header"> Agent </div>
          <div class="table-cell title-cell" v-for="i in calculateTimeRange()" :key="i">
            {{ formatTime(i) }} to {{ formatTime(i+1) }}
        </div>
      </div>

      <div class="table-row">
        <div class="fixed-cell">
          <div class="doctorname"> {{ AgentData.user }}</div> 
            <div class="category"> {{ AgentData.category }} </div> 
              <div class="shifttime">Shift Time: <div>{{durations}}</div> </div>  
        </div>

      <div class="table-cell" v-for="i in calculateTimeRange()" :key="i">
          <div class="button-container">

            <!-- <div v-if="bookedSlot.length > 0"> -->
              <button class="table-button" v-for="j in slot_count" :key="j" @click="openDialog(AgentData.user, AgentData.category, formatTime(i + j * 0.60 / slot_count))">
                  Slot {{ j }} - {{ formatTime(i + j * 0.60 / slot_count) }}
              </button>
          <!-- </div> -->
          <!-- <div v-else>
              <button class="table-button-red" v-for="j in slot_count" :key="j" @click="openDialog(AgentData.user, AgentData.category, formatTime(i + j * 0.60 / slot_count))">
                  Slot {{ j }} - {{ formatTime(i + j * 0.60 / slot_count) }}
              </button>
          </div> -->

                </div>
              </div>
            </div>
          </div>
<!--  --------    End Calender View      -----------------  -->

<!--    --------  Start Filter  dialog screenn   ---------->
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                <i class="fas fa-close"></i>
            </v-btn>
        </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <label class="sheduleddate">Date</label>
                <datetime class="theme-orange" type="date" format="yyyy-MM-dd" v-model="valid" label="test"></datetime>
            <v-spacer></v-spacer>
           <v-select
                v-model="filter.payload.title"
                label="Shift"
                :items="this.newArray"
              ></v-select>
             <v-select
                v-model="filter.payload.category"
                label="Category"
                :items="this.newArray"
              ></v-select>
              <v-select
                v-model="filter.payload.agent"
                label="agent"
                :items="this.newArray"
              ></v-select>    
          </div>
        </v-container>  
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
<!--    --------  End Filter dialog screenn   ---------->

    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>


   <v-dialog persistent :value="page.isaddmeetings" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create Appointment</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="DisportionMain" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                     <v-select v-model="addmeetings.payload.name " required label="Meeting title" :items="this.meeting_title" :rules="[(v) => !!v || 'Meeting title is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="addmeetings.payload.meeting_sub_title " required label="Meeting Sub title" :items="this.meeting_sub_title" :rules="[(v) => !!v || 'Meeting title is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-if="addmeetings.payload.name =='others'" v-model="addmeetings.payload.manual_title" required label="Title" type="text" :rules="[(v) => !!v || 'Title is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="addmeetings.payload.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field @change="phone_number" v-model="addmeetings.payload.phone_number" required
                      label="Phone Number" type="text" :rules="[(v) => !!v || 'Phone_number is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                      <v-text-field  v-model="addmeetings.payload.agent" required label="Agent" type="text"
                      :rules="[(v) => !!v || 'Agent is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                      <v-text-field  v-model="addmeetings.payload.category" required label="Category" type="text"
                      :rules="[(v) => !!v || 'Category is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                      <v-text-field  v-model="addmeetings.payload.shift" required label="Shift" type="text"
                      :rules="[(v) => !!v || 'Shift is required']"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
                      <v-text-field  v-model="addmeetings.payload.sheduleddate" required label="Meeting Time" type="text"
                      :rules="[(v) => !!v || 'Meeting time is required']"></v-text-field>
                  </v-flex> -->
                  <v-flex xs12>
                    <label class="sheduleddate">Meeting Date</label>
                    <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                      v-model="addmeetings.payload.sheduleddate" label="test">
                    </datetime>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Module is required']" :items="module" required label="Module"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.module"
                      persistent-hint></v-select>

                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.module === 'lead'">
                    <v-select :rules="[(v) => !!v || 'Template is required']" :items="template" required label="Template"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.template"
                      persistent-hint></v-select>

                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddAppointment()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAppointment()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 

  </div>
</template>

<script>

export default {

  data() {
    return {
      req_id: [],
      slot_time: "",
      bookedSlot:[],
      module: ['lead'],
      shifts: [],
      date:"",
      slotButton:"",
      meeting_data:"",
      template: JSON.parse(localStorage.getItem("group_template")),
      valid: true,
      showDialog: false,
      styleObject: {
        height: "80px",
        "margin-top": "-20px",
      },
      page: {
        title: "Calendar",
        icon: "fa fa-calendar fa-2x",
        loading: false,
        isFilterOpen: false,
        options: { filter: true, refresh: true, autoCompletePC: true },
        addnew: [],
        isaddmeetings:false,
      },
      TitleData: [],
      pagecount: 0,
      selected_slot: {
      user: "", 
      category: "",
      time: ""
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      Shiftfilter: {
        query: {
          name: "",
          status: "",
        },
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      newArray: [],

      filter: {
        payload: {
          title:"",
          is_active:""
        },
        isLoading: false,
      },
        addmeetings: {
        valid: false,
        menu2: false,
        menu1: false,
        payload: {
          name: "",
          phone_number: "",
          description: "",
          module: "",
          agent: "",
          category:"",
          shift:"",
          sheduleddate: "",
          meeting_sub_title:""
        },

        options: {
          status: ["Active", "Inactive"],
        },
      },
      filtermodule: ["lead", "contact", "ticket"],
      isProcessing: false,
      is_active: ["Active", "Inactive"],
      from_actual_time:[],
      to_actual_time:[],
      durations:"",
      slot_count:"",
      shiftBasedAgent:"",
      matchingUsers:"",
    };
  },
mounted() {
    this.page.loading = false;
    let currentDate = new Date();
    this.date= currentDate.toISOString().slice(0, 10);
    this.getDataFromApi();
    // this.getMeeting();
  },
methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.filter.payload.module = ""
      scope.filter.payload.user = ""
      scope.getDataFromApi();
    },
    DateFormate(date) {
        let datetime = new Date(date);
        let yy = datetime.getFullYear();
        let dd = datetime.getDate();
        let mm = datetime.getMonth() + 1;
        let hr = datetime.getHours();
        let min = datetime.getMinutes();
        let sec = datetime.getSeconds();
        dd = String(dd).padStart(2, "0");
        mm = String(mm).padStart(2, "0");
        hr = String(hr).padStart(2, "0");
        min = String(min).padStart(2, "0");
        sec = String(sec).padStart(2, "0");
        return `${yy}-${mm}-${dd} ${hr}:${min}:${sec}`;
    },
   openDialog(user, category, time) {

      this.addmeetings.payload.agent = user;
      this.addmeetings.payload.category = category;
      this.addmeetings.payload.shift = "9";
      this.selected_slot.user = user;
      this.selected_slot.category =  category;
      var timeArray = time.match(/(\d+).(\d+)([ap]m)/i);
      if (timeArray) {
          var hours = parseInt(timeArray[1], 10);
          var minutes = parseInt(timeArray[2], 10);
          var period = timeArray[3].toLowerCase();
              if (minutes === 60) {
                  minutes = 0;
                    hours += 1;
                }

              if (period === 'pm' && hours !== 12) {
                  hours += 12;
              }

              if (period === 'am' && hours === 12) {
                  hours = 12;
              }

            var result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
            this.selected_slot.time = result;
            const dateTimeString = `${this.date}T${this.selected_slot.time}`;
            const dateTimeValue = this.DateFormate(dateTimeString);
            this.addmeetings.payload.sheduleddate = new Date(dateTimeValue).toISOString();
                    
      } else {
            return "Invalid time format";
        }
      this.page.isaddmeetings = true;
      },
      closeAddAppointment(){
        this.page.isaddmeetings = false;
      },
      filterClick: function () {
        this.page.isFilterOpen = true;
      },

      reset() {
        var scope = this;
        
        scope.getDataFromApi();
      },

     saveAppointment() {
      var scope = this;
      if (scope.addmeetings.payload.module === "lead") {
        if (scope.addmeetings.payload.template !== "") {
          scope.$store
            .dispatch("findlead", { phone_number: scope.addmeetings.payload.phone_number, template: scope.addmeetings.payload.template })
            .then((res) => {
              if (res.status === 204) {
                scope.showSnackBar("please create leads");
              } else {
                this.req_id.push({ "lead": res.data.data[0].others.lead_id, module: "lead" })
                scope.create()
              }
            }).catch(function (error) {
              scope.showSnackBar(error);
            });
        } else {
          scope.showSnackBar("Please select template");
        }
      } else {
        scope.create()
      }
    },
      
    create() {
          var scope = this;
          let id = this.req_id.find((item) => {
              if (item.module === scope.addmeetings.payload.module) {
                return item
              }
            })

          id = id[`${scope.addmeetings.payload.module}`]
          if(scope.addmeetings.payload.name === "others"){
              scope.addmeetings.payload.name = scope.addmeetings.payload.manual_title
          }
      let data = {
                  "meeting_sub_title":scope.addmeetings.payload.meeting_sub_title,
                  "meetingName": scope.addmeetings.payload.name ,
                  "meetingTilte": scope.addmeetings.payload.description,
                  "meetingTime": scope.addmeetings.payload.sheduleddate,
                  "category": scope.addmeetings.payload.category,
                  "shift": scope.addmeetings.payload.shift,
                  "user_email": scope.addmeetings.payload.agent,
                  "leadID": id.toString(),
                  "phoneNumber": scope.addmeetings.payload.phone_number,
                  "campaign": "Admin Portal",
                  "module": scope.addmeetings.payload.module,
                  "callerName": scope.addmeetings.payload.phone_number
                  }          
          scope.$store
              .dispatch("insertAppointment", data)
              .then(() => {
                scope.page.isaddmeetings = false;
                scope.showSnackBar("Created Successfully");
                this.$refs.DisportionMain.reset();
                scope.addmeetings.payload.sheduleddate = "";
                scope.addmeetings.payload.call_back = false;
                this.getDataFromApi();
              })
              .catch(function (error) {
                scope.page.isaddmeetings = false;
                scope.showSnackBar("error" + error);
                scope.addmeetings.payload.name = "";
                scope.addmeetings.payload.phone_number = "";
                scope.addmeetings.payload.meeting_sub_title = "";
                scope.addmeetings.payload.description = "";
                scope.addmeetings.payload.module = "";
                scope.addmeetings.payload.agent = "";
                scope.addmeetings.payload.agent = "";
                scope.addmeetings.payload.sheduleddate = "";
              });
        },
    getDataFromApi(){

      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      scope.filter.payload["skip"] = "";
      scope.filter.payload["take"] = "";
      scope.filter.payload.user="";
      scope.filter.payload.shift="";
      scope.filter.payload.category="";
      scope.filter.payload.status="";
      scope.$store
        .dispatch("UserShiftFetch", scope.filter.payload)
        .then((response) => {
          this.pagecount = response.count;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.shiftBasedAgent = datas;  
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });

        let titleQuery = {
        skip: "",
        take: ""  
        }
        this.$store      
        .dispatch("FetchData",titleQuery)
        .then((response) => {
          var datas = response.data;
          scope.titledata=datas
           scope.meeting_title = datas.map((element) => element.title);
           scope.meeting_title.push("others")
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });

        scope.Shiftfilter.query["skip"] ="";
        scope.Shiftfilter.query["take"] = "";

        this.$store
          .dispatch("GetShift", scope.Shiftfilter.query)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas;
            if (response) {
              datas = response.data;
            } else {
              datas = [];
              this.pagecount = 0;
            }
            this.shifts = datas;
          })
          .catch(function (error) {
            scope.page.isAddShiftModule = false;
            scope.showSnackBar("error" + error);
            this.page.isEditShiftModule = false;
          });

           let query = {
            fromDate: this.date,
          };

        this.$store
          .dispatch("GetAppointments", query)
          .then((response) => {
            this.meeting_data = response.data.data;
          })
          .catch(function (error) {
            this.showSnackBar("error" + error);
          });
    },
    formatTime(hour) {

      const fromHour = parseFloat(this.from_actual_time[0]);
      let toHour = parseFloat(this.to_actual_time[0]);

      if (hour < fromHour || hour > toHour) {
        return '';
      }
      var formattedHour = hour % 12;
      const ampm = hour >= 12 ? 'pm' : 'am';

      if (formattedHour === 0) {
        formattedHour = 12;
      } else if (formattedHour.toFixed(2) === '0.15') {
        formattedHour = 12.15;
      } else if (formattedHour.toFixed(2) === '0.30') {
        formattedHour = 12.3;
      } else if (formattedHour.toFixed(2) === '0.45') {
        formattedHour = 12.45;
      } 

      const minutePart = formattedHour % 1;
      if (minutePart === 0.6) {
        formattedHour += 0.4; 
      }

      for(let i=0; i<this.meeting_data.length; i++){
        let dateTime = new Date(this.meeting_data[i].scheduledTime);
              let hours = dateTime.getHours();
              let minutes = dateTime.getMinutes();
              let BookedTimes = `${hours}:${minutes}`;
              
              console.log(BookedTimes,"uuuuuuuuuu");
              // if(timeings == BookedTimes){
              //    this.bookedSlot.push(BookedTimes)
              // // console.log(BookedTimes,  "Exact meeting booking time");
              // }
              // else{
              //   this.bookedSlot = [];
              // }

              //  this.bookedSlot = ["vel"];
              console.log("MeetingDAta111",formattedHour.toFixed(2));
              console.log(formattedHour.toFixed(2),"MeetingDAta");
      }
      console.log(formattedHour.toFixed(2),"MeetingDAta");
      return formattedHour.toFixed(2) + ampm;
    },
    calculateTimeRange() {

          const fromHour = parseFloat(this.from_actual_time[0]);
          let toHour = parseFloat(this.to_actual_time[0]);
          toHour -= 1;
          const timeRange = [];

          if(fromHour <= toHour){
          for (let hour = fromHour; hour <= toHour; hour++) {
            timeRange.push(hour);
          }
          }
          else{
            for (let hour = toHour; hour <= fromHour; hour++) {
            timeRange.push(hour);
            }
          }
          return timeRange;
      },
    duration() {
          let fromHour = parseFloat(this.from_actual_time[0]);
          let toHour = parseFloat(this.to_actual_time[0]);
          let fromAmPm = fromHour >= 12 ? 'pm' : 'am';
          let toAmPm = toHour >= 12 ? 'pm' : 'am';

          if (fromHour > 12) {
            fromHour -= 12;
          }
          if (toHour > 12) {
            toHour -= 12;
          }

          fromHour = fromHour.toFixed(2) + fromAmPm;
          toHour = toHour.toFixed(2) + toAmPm;

          this.durations = fromHour + " to " + toHour;
        },
      handleShiftsChange(id) {      // Shift Dropdown
        this.from_actual_time=[];
        this.to_actual_time=[];
        const selectedShift = this.shifts.find(shift => shift.id === id);

          if (selectedShift) {
              this.matchingUsers = this.shiftBasedAgent.filter(agent => agent.shift == id);
              this.from_actual_time.push(selectedShift.from_time.replace(':', '.'))
              this.to_actual_time.push(selectedShift.to_time.replace(':', '.'))
              this.slot_count = selectedShift.split_count;

              console.log(this.from_actual_time,this.to_actual_time,'Shifts changed to:', id,"____",selectedShift.from_time,"---------",selectedShift.to_time,"_______",selectedShift.split_count );

          } else {
              console.error('Shift not found for id:', id);
          }
           this.duration()
      },

          
          },
 computed: {
      },

  watch: {
    'addmeetings.payload.shifts': function (id) {
      this.handleShiftsChange(id);
      },
    'date': function (){
     this.getDataFromApi();
  },
    'addmeetings.payload.name': {
      handler() {
        let submeeting
        for (let i = 0; i < this.titledata.length; i++) {
          const element = this.titledata[i];
          if(element.title===this.addmeetings.payload.name){
            submeeting=element.sub_title.split(',')
          }
          
        }
        this.meeting_sub_title=submeeting
      }
    },
    'editmeetings.name': {
      
      handler() {
        let submeeting
        for (let i = 0; i < this.titledata.length; i++) {
          
          const element = this.titledata[i];
          if(element.title===this.editmeetings.name){
            submeeting=element.sub_title.split(',')
          }
          
        }
        this.meeting_sub_title=submeeting
      }
    },
    'addmeetings.payload.phone_number': {
      handler() {
        this.$store.dispatch("TicketPhoneSearch", { phone_number: Number(this.addmeetings.payload.phone_number) })
          .then((response) => {
            if (response.data.length > 0) {
              this.module.push("contact")
              this.req_id.push({ "contact": response.data[0].id, module: "contact" })
              } else {
              this.module = this.module.filter(item => item !== "contact")
              this.req_id = this.req_id.filter((item) => {
                if (item.module !== "contact") {
                  return item
                }
              })

            }
            this.$store.dispatch("Popup", { phone_number: this.addmeetings.payload.phone_number })
              .then((response) => {
                if (response.data.ids.length > 0) {
                  this.module.push("ticket")
                  this.req_id.push({ "ticket": response.data.ids[0].ticket_id, module: "ticket" })

                } else {
                  this.req_id = this.req_id.filter((item) => {

                    if (item.module !== "ticket") {
                      return item
                    }
                  })
                  this.module = this.module.filter(item => item !== "ticket")
                }

              })
          })
      },
      deep: true,
    },

    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = { model: "meeting", column: ["phone_number"] };

        data["search"] = val;

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].phone_number;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>

  .table-view {
    display: flex;
    flex-direction: column;
    /* border: 2px solid #cacaca; */
    border-radius: 5px;
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
    margin-top: 15px;
    padding: 15px;
  }

  .table-row {
    display: flex;
    position: relative;
    text-align: center;
    font-size: 12px;
  }

  .table-cell {

    flex: 1;
    padding: 8px;
    border: 1px solid #cacaca;
    /* border-radius: 5px; */
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
    transition: 0.3s ease-out;
    text-align: center;
    font-size: 95%; 
  }

  .title-cell {
    background-color: #d4e0f3;
    font-size: 11px;
    font-weight: 600 !important;
    font-family: "Roboto", sans-serif;
    color: rgb(5, 67, 92);
    position: relative;
  }
  .fixed-cell {
    width: 200px; 
    background-color: #edeef2;
    font-size: 8px;
    font-weight: 500 !important;
    font-family: "Roboto", sans-serif;
    color: rgb(5, 67, 92);;
    padding: 8px;
    border: 1px solid #cacaca;
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
    transition: 0.3s ease-out;
    text-align: center;
    /* border-radius: 5px; */
    /* font-size: 95%; */
    /* flex: 1; */
    /* height: 50px; */
    /* background-color: lightblue; */
  }
  .fixed-cell-header {
    width: 200px; 
    background-color: #d4e0f3;
    font-size: 16px;
    font-weight: 500 !important;
    font-family: "Roboto", sans-serif;
    color: rgb(5, 67, 92);;
    padding: 8px;
    border: 1px solid #cacaca;
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
    transition: 0.3s ease-out;
    text-align: center;
    /* font-size: 95%; */
    /* border-radius: 5px; */
    /* flex: 1; */
    /* position: relative; */
  }
  .title-cell:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .title-cell:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .table-button {
    margin: 0px 0; /* Add some vertical spacing between buttons */
    margin-left: 2px 0;
    /* position: relative; */
    padding: 3%;

    /* padding-left: 5%;
    padding-right: 5%; */
    height: 40%;
    width:50%;
    background-color: transparent;
    border: 1px solid #838586; /* Set the desired border color  0087ca */
    color: #0087ca; 
    border-radius: 5px;
    margin-top: 3px;
    font-size: 10px;

    /* font-weight: bold; */
    /* margin: 1px;  */
    cursor: pointer;
    transition: background-color 0.3s ease-out, color 0.3s ease-out;
  }
    .table-button-red {
    margin: 0px 0; /* Add some vertical spacing between buttons */
    margin-left: 2px 0;
    /* position: relative; */
    padding: 3%;

    /* padding-left: 5%;
    padding-right: 5%; */
    height: 40%;
    width:50%;
    background-color: transparent;
    border: 1px solid #838586; /* Set the desired border color  0087ca */
    color: #ca2f00; 
    border-radius: 5px;
    margin-top: 3px;
    font-size: 10px;

    /* font-weight: bold; */
    /* margin: 1px;  */
    cursor: pointer;
    transition: background-color 0.3s ease-out, color 0.3s ease-out;
  }

  .button-container {
    /* display: flex; */
    /* flex-direction: column; */
    /* margin: 30% ; */
    /* padding: 30%; */
    align-self: start;
    align-items: center; 
    padding: 4px;
  }


  .table-button:hover {
    background-color: #0087ca; /* Change the background color on hover */
    color: #fff; /* Change the text color on hover */
  }
  .doctorname{
    font-size: 14px;
      color: rgb(47, 1, 212);

    font-family: "Roboto", sans-serif;
  }
  .category{
    font-size: 12px;
      color: rgb(212, 96, 1);

    font-family: "Roboto", sans-serif;
  }
  .shifttime{
    font-size: 12px;
      color: rgb(35, 116, 3);

    font-family: "Roboto", sans-serif;
  }
  .topfield1{
    display: flex;
    flex-direction: column;
    /* border: 2px solid #cacaca; */
    border-radius: 0px;
    /* box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px; */
    margin-top: 15px;
    margin-left: 300px;
    margin-bottom: 0px;
    /* padding: 10px; */
  }
  .topfield2{
    display: flex;
    flex-direction: column;
    /* border: 2px solid #cacaca; */
    border-radius: 0px;
    /* box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px, rgb(33, 150, 243, 0.08) 0px 0px 0px 1px; */
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 0px;
    /* padding: 10px; */
  }
</style>