<template>
    <div style="height:auto;overflow:hidden">
        <div><Home /></div>
    </div>
</template>
<script>
import Home from './Home';

export default {
    data() {
        return {
            activeName: "Design"
        }
    },
    components: {
        Home,
    }
}
</script>
<style scoped>

.tabs {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 5 !important;
    padding: 15px !important;
}
</style>>