<template>
    <div>
        <div>

            <div class="header_custom">
                <div class="left_head">
                    <div class="form_icon">
                        <i v-if="methodeOf === 'Create'" class="fa fa-gg" aria-hidden="true"></i>
                        <i class="fa fa-pencil " aria-hidden="true" v-if="methodeOf === 'Update'"></i>
                    </div>

                    <div class="header_txt_form">
                        <span class="v-toolbar__title primary--text" v-if="methodeOf === 'Create'">Create Template
                            </span>
                        <span class="v-toolbar__title primary--text" v-if="methodeOf === 'Update'">Edit Template
                            </span>
                    </div>
                </div>
                <div class="name_input">
                    <div class="textfield">
                        <v-text-field v-model="forms[0].template_name"
                            :readonly="(methodeOf === 'Update') ? true : false" placeholder="Please Enter Form Title">
                        </v-text-field>
                    </div>
                    <div class="headerbtn">
                        <div>
                            <div>
                                <i style="color:rgb(33, 150, 243); cursor: pointer;" @click="backpage()"
                                    class="fa fa-arrow-left icon_cls_custom_form" aria-hidden="true"></i>

                            </div>
                        </div>
                        <div>
                            <i v-if="previews===false" @click="preview()" style="color:rgb(33, 150, 243); cursor: pointer;"
                                class="fa fa-eye icon_cls_custom_form" aria-hidden="true"></i>
                            <i v-if="previews===true" @click="preview()" style="color:rgb(33, 150, 243); cursor: pointer;"
                                class="icon_cls_custom_form fa fa-eye-slash" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i style="color:rgb(33, 150, 243); cursor: pointer;" class="fa fa-floppy-o icon_cls_custom_form"
                                aria-hidden="true" v-if="methodeOf === 'Create'" @click="CreateCustomForm_pop"></i>
                            <i style="color:rgb(33, 150, 243); cursor: pointer;" class="fa fa-floppy-o icon_cls_custom_form"
                                aria-hidden="true" v-if="methodeOf === 'Update'" @click="UpdateCustomForm_pop"></i>
                        </div>

                        <!-- <div v-if="methodeOf === 'Create'">
                            <i style="color:rgb(33, 150, 243)" @click="confirmReset()"
                                :class="['fa', 'fa-refresh','icon_cls_custom_form']"></i>
                        </div>
                        <div v-if="methodeOf === 'Update'">
                            <div class="icon_cls_custom_form"></div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div v-if="previews===false" class="main_view_custom">
                <div class="main_custom_container">
                    <div class="left_custom">
                        <div class="catagory">
                            <div style="padding:5px 10px 0px 10px;width:90%">
                                <v-select @change="change_catagory(catagory_value)" :items="catagory_arrs"
                                    label="Select Catagory" v-model="catagory_value"></v-select>

                            </div>

                            <template>
                               
                                    <v-dialog v-model="dialog" persistent max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div style="padding:15px 10px 0px 0px;width:10%"><i v-bind="attrs" v-on="on"
                                                    style="color:rgb(33, 150, 243);font-size:25px"
                                                    class="fa fa-plus-circle icon_cls_custom_form"
                                                    aria-hidden="true"></i></div>

                                        </template>

                                        <v-card>
                                            <v-toolbar dark color="primary">
                                                <v-toolbar-title>Create Catagory</v-toolbar-title>
                                            </v-toolbar>
                                            <v-container grid-list-md>
                                                <v-form ref="catagoryvalidation" lazy-validation>
                                                <v-layout wrap>
                                                    <v-text-field v-model="catagory_title" :rules="CatagoryRules" label="Catagory">
                                                    </v-text-field>
                                                </v-layout>
                                                </v-form>
                                            </v-container>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="light" flat @click="new_catagorycancel()">Cancel</v-btn>
                                                <v-btn color="primary"
                                                    @click="new_catagory(forms,catagory_title,catagory_value)">Yes,
                                                    Create !</v-btn>
                                            </v-card-actions>
                                        </v-card>


                                    </v-dialog>
                            </template>

                            <template>
                               
                                    <v-dialog v-model="edit_cat" persistent max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div style="padding:15px 10px 0px 0px;width:10%">
                                                <i v-bind="attrs" v-on="on"
                                                    style="color:rgb(33, 150, 243);font-size:25px"
                                                    class="fa fa-pencil icon_cls_custom_form" aria-hidden="true"></i>


                                            </div>

                                        </template>

                                        <v-card>
                                            <v-toolbar dark color="primary">
                                                <v-toolbar-title>Edit Catagory Name</v-toolbar-title>
                                            </v-toolbar>
                                            <v-container grid-list-md>
                                                <v-layout wrap>
                                                    <v-text-field v-model="catagory_value" label="Catagory">
                                                    </v-text-field>
                                                </v-layout>
                                            </v-container>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="light" flat @click="edit_cat = false">Cancel</v-btn>
                                                <v-btn color="primary" @click="edit_catagory_name(catagory_value)">Yes,
                                                    Edit !</v-btn>
                                            </v-card-actions>
                                        </v-card>


                                    </v-dialog>
                            </template>
                            <template>
                                    <v-dialog v-model="delete_cat" persistent max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-show="catagory_value != showed_cat.catagory_value" style="padding:15px 10px 0px 0px;width:10%">
                                                <i v-bind="attrs" v-on="on"
                                                    style="color:rgb(33, 150, 243);font-size:25px"
                                                    class="fa fa-trash icon_cls_custom_form" aria-hidden="true"></i>


                                            </div>

                                        </template>

                                        <v-card>
                                            <v-toolbar dark color="primary">
                                                <v-toolbar-title>Delete Catagory</v-toolbar-title>
                                            </v-toolbar>
                                            <v-container grid-list-md>
                                                <v-layout wrap>
                                                    <v-card-text>Are you sure to delete this Catagory.</v-card-text>
                                                </v-layout>
                                            </v-container>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="light" flat @click="delete_cat = false">Cancel</v-btn>
                                                <v-btn color="primary" @click="delete_catagory(catagory_value)">Yes,
                                                    Delete !</v-btn>
                                            </v-card-actions>
                                        </v-card>


                                    </v-dialog>
                            </template>

                        </div>
                    <div class="fields-dragarea">
                        <el-form style="overflow:hidden">
                            <el-row v-if="forms.length === 0">
                                <div class="empty-section">Please add Sections</div>
                            </el-row>
                            <template v-for="(eachFormObj, eachFormIndex) in forms">
                                <div :key="`div-${eachFormIndex}`" class="section-block">
                                    <div class="meta">
                                        <el-row>
                                            <draggable :list="catagory_fields" class="dragArea"
                                                :group="{ name: 'formbuilder', pull: false, put: true }" :sort="true"
                                                ghost-class="sortable__ghost"  @change="log">
                                                <el-col
                                                    v-for="(field, index) in catagory_fields"
                                                    :key="index" v-bind="field" :span="field.span" class="form__group"
                                                    :class="{ 'is--active': field === activeField }">

                                                    <div>
                                                        <span class="form__selectedlabel">
                                                        </span>
                                                        <div @click="editElementProperties(field)">
                                                            <div class="form__actiongroup" v-if="field.isdefaultfield===false">
                                                                <el-button circle size="mini" icon="el-icon-rank"
                                                                    class="form__actionitem--move"></el-button>
                                                                <div class="form__actionlist">

                                                                    <el-button style="background:none;border:none" size="mini" icon="el-icon-delete" @click="
                                                                    deleteElement(
                                                                    index,
                                                                    catagory_fields,
                                                                    eachFormIndex
                                                                    )
                                                                    "></el-button>
                                                                </div>
                                                            </div>
                                                            <component :is="field.fieldType" :currentField="field"
                                                                class="form__field" :Allfields="catagory_fields">
                                                            </component>
                                                        </div>



                                                    </div>
                                                </el-col>

                                            </draggable>
                                        </el-row>
                                    </div>
                                </div>
                            </template>
                        </el-form>
                    </div>
                    </div>

                    <div class="right_custom">

                        <div class="controll_panal_head">
                            <div :class="class_element" @click="tabchange('Elements')">Elements</div>
                            <div :class="class_properties" @click="tabchange('Properties')">Properties</div>
                        </div>
                        <div class="controll_panal_body">
                            <elements v-if="switch_prop==='Elements'"></elements>
                            <properties v-else :validation_arr="indicator_arr" v-show="Object.keys(activeField).length > 0"></properties>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="previews===true">
                <preview @closedpreview = "closepreview($event)"></preview>
            </div>
            <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
                {{ snackbar.text }}
                <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
            </v-snackbar>
            <v-dialog persistent v-model="reset_popup" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title v-if="back">Exit From Create Template</v-toolbar-title>
                        <v-toolbar-title v-else>Reset Template</v-toolbar-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text v-if="back">Are you sure you want to Exit ?</v-card-text>
                            <v-card-text v-else>Are you sure you want to Reset ?</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="reset_popup = false">Cancel</v-btn>
                        <v-btn color="primary" v-if="back" @click="deleteSection('exit')">Yes, Exit !</v-btn>
                        <v-btn color="primary" v-else @click="deleteSection()">Yes, Reset !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog persistent v-model="create_popup" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Create Template</v-toolbar-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure You Want To Create Template ?</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="create_popup = false">Cancel</v-btn>
                        <v-btn color="primary" @click="CreateCustomForm()">Yes, Create !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog persistent v-model="edit_popup" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Edit Template</v-toolbar-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure You Want To Edit Template ?</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="edit_popup = false">Cancel</v-btn>
                        <v-btn color="primary" @click="UpdateCustomForm()">Yes, Edit !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <template>
                <v-dialog persistent v-model="err_pop" max-width="500px" scrollable>
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-toolbar-title style="width:20%">Error!</v-toolbar-title>
                            <i style="text-align:right;width:90%" @click="err_pop = false" class="fa fa-times"
                                aria-hidden="true"></i>
                        </v-toolbar>
                        <v-card-text>

                            <v-container grid-list-md>
                                <v-layout wrap v-for="(data,i) in indicator_arr " :key="i">
                                    <v-card-text>{{i+1}}) {{data}}</v-card-text>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </div>

    </div>
</template>

<script>
import config from "@/config/index";
import preview from "../../CRM/custom-form/Preview.vue"
import {
    FormBuilder
} from "../../../components/customform_elements/formbuilder";
import {
    mapGetters
} from "vuex";
import Elements from "../../../components/customform_elements/Elements";
import Properties from "../../../components/customform_elements/properties/Properties";
import TextInput from "../../../components/customform_elements/FormElementTextInput";
import Checkbox from "../../../components/customform_elements/FormElementCheckbox";
import DatePicker from "../../../components/customform_elements/FormElementDatePicker";
import DatetimePicker from "../../../components/customform_elements/FormElementDatetimePicker";
import LongTextInput from "../../../components/customform_elements/FormElementLongTextInput";
import RadioButton from "../../../components/customform_elements/FormElementRadioButton";
import SelectList from "../../../components/customform_elements/FormElementSelectList";
import TimePicker from "../../../components/customform_elements/FormElementTimePicker";
import draggable from "vuedraggable";

export default {
    name: "Home",
    components: {
        Elements,
        Properties,
        draggable,
        TextInput,
        Checkbox,
        DatePicker,
        DatetimePicker,
        LongTextInput,
        RadioButton,
        SelectList,
        TimePicker,
        preview
    },
    data() {
        return {
            showed_cat:{},
            delete_cat: false,
            edit_cat: false,
            dialog: false,
            catagory_title: "",
            sortElementOptions: FormBuilder.$data.sortElementOptions,
            fields:FormBuilder.$data.fields,
            elements: [],
            methodeOf: "Save",
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            switch_prop: "Elements",
            class_element: 'bts_custom_form',
            class_properties: "bts_custom_form_change",
            reset_popup: false,
            create_popup: false,
            back: false,
            edit_popup: false,
            indicator_arr: [],
            err_pop: false,
            previews: false,
            catagory_pop: true,
            catagory_value: "",
            catagory_arrs: [],
            form_length: 0,
            CatagoryRules: [
            (v) => !!v || 'Catagory is required',
            (v) => (v && v !== " " ) || "Space not Allow",
            (v) => (v && v[0] !== " ") || "First letter Space not Allow",
            (v) => /^[A-Za-z0-9_][a-zA-Z0-9_ ]*$/.test(v) || "Only allow (A-Z , _ ,0-9)",
            (v) => this.catagory_arrs.filter((elm)=>{console.log(elm.toLowerCase()!==v.toLowerCase())
               return elm.toLowerCase() == v.toLowerCase()}).length == 0  || "Catagory is already exist",
            ],
            catagory_fields:[]
        };
    },
    computed: {
        ...mapGetters([
            "forms",
            "activeField",
            "activeTabForFields",
            "formType",
            "PageMethode",
            "CustomForm"
        ]),
    },

    mounted() {
        this.methodeOf = localStorage.getItem("PageMethode");
        if (this.methodeOf === 'Update') {
            const formData = JSON.parse(localStorage.getItem("forms"));
            this.$store.dispatch("restoreForm", formData);

            let catagory = []
            for (let i = 0; i < this.forms[0].fields.length; i++) {
                catagory.push(this.forms[0].fields[i].catagory_value)
                if (this.forms[0].fields[i].hasDefault) {
                    this.catagory_value = ""
                    this.catagory_value = this.forms[0].fields[i].catagory_value
                }
            }
            this.catagory_arrs = [...new Set(catagory)]


            

            // for (let j = 0; j < this.forms[0].fields.length; j++) {
            //     if (this.forms[0].fields[j].catagory_value === this.catagory_value) {
            //         this.forms[0].fields[j].catagory = false
            //     } else {
            //         this.forms[0].fields[j].catagory = true
            //     }
            // }
            localStorage.setItem("catagory_value", JSON.stringify({ "catagory_value": this.catagory_value }))
            this.change_catagory(this.catagory_value)
        } else {
            const formData = JSON.parse(localStorage.getItem("forms"));
            this.$store.dispatch("restoreForm", formData);
            for (let i = 0; i < this.forms[0].fields.length; i++) {
                const element = this.forms[0].fields[i];
                if (element.isdefaultfield) {
                    this.catagory_value = "Default Field"
                    element.catagory_value = "Default Field"
                    let arr = element['catagory_arr'] = []
                    arr.push('Default Field')
                    // element.catagory = false
                } 
                // else {
                    // element.catagory = true
                // }

            }
            localStorage.setItem("catagory_value", JSON.stringify({ "catagory_value": "Default Field" }))
            this.change_catagory("Default Field")
            this.catagory_arrs = this.forms[0].fields[0].catagory_arr
        }
        this.form_length = this.forms[0].fields.length
        this.log()

    },
    // updated() {
    //     const scope = this;
    //     this.form_length = scope.forms[0].fields.length
        
        // for (let i = 0; i < scope.forms[0].fields.length; i++) {
        //         scope.forms[0].fields[i].position = i +1;
        // }
    // },
    // watch: {
    //     form_length() {
            // for (let i = 0; i < this.forms[0].fields.length; i++) {
            //     const element = this.forms[0].fields[i];

            //     if (element.catagory === false) {
            //         element.catagory_value = this.catagory_value
            //         element['catagory_arr'] = this.catagory_arrs
            //     }

            // }
            // localStorage.setItem("originalForm", JSON.stringify(this.forms))
        // }
    // },
    methods: {
        edit_catagory_name(val) {
            this.edit_cat = false
            let obj = JSON.parse(localStorage.catagory_value);
            let catagory = []
            for (let i = 0; i < this.forms[0].fields.length; i++) {
                let element = this.forms[0].fields[i];


                if (element.catagory_value === obj.catagory_value) {
                    element.catagory_value = val

                    // let arr = []
                    // for (let j = 0; j < element.catagory_arr.length; j++) {
                    //     let catagory_arr = element.catagory_arr[j];
                    //     if (catagory_arr === obj.catagory_value) {
                    //         arr.push(val)
                    //     } else {
                    //         arr.push(catagory_arr)
                    //     }
                    // }
                    // element.catagory_arr = []
                    // element.catagory_arr = arr


                }
                catagory.push(this.forms[0].fields[i].catagory_value)
                //  else {
                //     let arr = []
                //     for (let j = 0; j < element.catagory_arr.length; j++) {
                //         let catagory_arr = element.catagory_arr[j];
                //         if (catagory_arr === obj.catagory_value) {
                //             arr.push(val)
                //         } else {
                //             arr.push(catagory_arr)
                //         }
                //     }
                //     element.catagory_arr = []
                //     element.catagory_arr = arr
                // }

            }
            this.catagory_arrs = [...new Set(catagory)]
            // this.catagory_arrs = this.forms[0].fields[0].catagory_arr
            localStorage.setItem("catagory_value", JSON.stringify({ "catagory_value": val }))
        },
        preview() {
            this.previews = !this.previews;
        },
        async closepreview(val) {
            this.indicator_arr = [];
            await this.indicator_arr.push(...val);
            this.previews = false;
            this.err_pop = true;
        },
        new_catagorycancel(){
            this.dialog = false
            this.catagory_title=''
            this.$refs.catagoryvalidation.resetValidation()
        },
      
        // assign_title(form, val) {

        // for (let i = 0; i < form[0].fields.length; i++) {
        // const element = form[0].fields[i];


        // if (element.catagory === false) {
        // element.catagory_value = val
        // }


        // }
        // },
        new_catagory(form, val, previous_value) {

            if (this.$refs.catagoryvalidation.validate()) {
            this.catagory_arrs.push(val)
            localStorage.setItem("catagory_value", JSON.stringify({ "catagory_value": val }))
            for (let i = 0; i < form[0].fields.length; i++) {


                let element = form[0].fields[i]
                if (form[0].fields[i].catagory_value === previous_value) {
                    element.catagory = true

                }

            }
            this.catagory_value = val
            this.dialog = false
            this.change_catagory(val)
            this.$refs.catagoryvalidation.resetValidation()
            // if (this.catagory_title === "") {
            //     this.showSnackBar("Catagory Title is not allowed to Empty");
            // } else {
            //     for (let i = 0; i < form[0].fields.length; i++) {
            //         var element = form[0].fields[i];
            //         if (element['catagory_arr']) {
            //             element['catagory_arr'].push(val)
            //         }
            //         if (element.catagory === false) {
            //             element.catagory = true
            //             element.catagory_value = val

            //         }

            //     }
            //     this.catagory_title = ""
            // }
            this.catagory_title=""
            }else{
                this.dialog = true
            }
        },
        change_catagory(val) {

            this.showed_cat = this.forms[0].fields.find(res=>{
return res.isdefaultfield == true;
            })
            localStorage.setItem("catagory_value", JSON.stringify({ "catagory_value": val }))
           
            // for (let i = 0; i < this.forms[0].fields.length; i++) {


            //     const element = this.forms[0].fields[i];
            //     if (val === element.catagory_value) {
            //         element.catagory = false
            //     } else {
            //         element.catagory = true
            //     }

            // }



           
            this.catagory_fields = this.forms[0].fields.filter((elm)=>{
                return elm.catagory_value == val
            })
        },
        confirmReset() {
            this.reset_popup = true
        },
        CreateCustomForm_pop() {
            this.create_popup = true
        },
        UpdateCustomForm_pop() {
            this.edit_popup = true
        },
        tabchange(obj) {
     
            const state = config.CustomForm;

            if (obj === "Elements") {
                state.CustomForm.activeTabForFields = "elements";
                this.switch_prop = "Elements"
                this.class_element = 'bts_custom_form',
                    this.class_properties = "bts_custom_form_change"

            } else {
                state.CustomForm.activeTabForFields = "properties";
                this.switch_prop = "Properties"
                this.class_element = 'bts_custom_form_change',
                    this.class_properties = "bts_custom_form"

            }
        },
        delete_catagory(val) {
  
            let scope = this
            let form = this.forms[0].fields
            let catagory = []

            if (this.methodeOf === 'Update') {
            for (let i = 0; i < form.length; i++) {

                if (form[i].catagory_value === val && form[i].isdefaultfield === false) {
                    scope.$store.dispatch("deleteCustomformField",{
                            template_name: scope.forms[0].template_name,
                            model: form[i].model,
                        })
                        .then((response) => {
                            if (response.statusCode === 200) {
                                scope.showSnackBar("Field was deleted successfully!");
                                FormBuilder.deleteElement(i, this.forms[0].fields);
                                scope.$store.dispatch("restoreForm", scope.forms);
                            } else if (response.statusCode === 204) {
                                FormBuilder.deleteElement(i, this.forms[0].fields);
                                scope.$store.dispatch("restoreForm", scope.forms);
                            } 
                            
                        })
                        .catch(() => {
                            scope.isProcessing = false;
                            scope.forms[0].fields = scope.forms[0].fields.filter(function (
                                obj
                            ) {
                                return obj.position !== scope.activeField.position;
                            });
                            this.deleteElement(i, this.forms[0].fields)
                            scope.showSnackBar("Field was deleted successfully!");
                        });
                } else {
                    catagory.push(form[i].catagory_value)
                }
            }

            scope.$store
                .dispatch("bulkdeleteCustomformField", {
                    template_name: scope.forms[0].template_name,
                    catagory_value: val,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        // this.back = false
                        // this.$router.push({
                        //     name: "crm/CustomFormList",
                        // });
                        scope.showSnackBar("Field was deleted successfully!");
                    }
                })

                catagory= this.catagory_arrs.filter(e=>e != val)
            }else{
                for (let i = 0; i < form.length; i++) {
                    if (form[i].catagory_value === val && form[i].isdefaultfield === false) {
                        FormBuilder.deleteElement(i, this.forms[0].fields);
                    } else {
                        catagory.push(form[i].catagory_value)
                    }
                }
                catagory= this.catagory_arrs.filter(e=>e != val)

            }
            this.delete_cat = false
            this.catagory_arrs = [...new Set(catagory)]
            this.catagory_value = this.catagory_arrs[0]
            this.change_catagory(this.catagory_arrs[0])
        },
        updated_cat(val) {
            this.$store
                .dispatch("UpdateFields", {
                    fields: val[0].fields,
                    template_name: val[0].template_name,
                })
                .then(() => {
                })
                .catch((err) => {
                    err.data.message = "Failure";
                });
        },
        deleteElement(index, form) {
            const formdata = JSON.parse(localStorage.getItem("forms"));
            var scope = this;
            if (this.methodeOf == "Create") {
            if (form[index].link_type !== "" && form[index].link_type == 'Parent') {
                for (let i = 0; i < form.length; i++) {
                    if (form[index].model == form[i].linked_to && form[i].link_type == "Child") {
                        FormBuilder.deleteElement(i, form);
                    }
                }
            }
                FormBuilder.deleteElement(index, form);
                scope.forms[0].fields = scope.forms[0].fields.filter(function (obj) {
                    return obj.position !== scope.activeField.position;
                });
                scope.showSnackBar("Field was deleted successfully!");
            }
            if (this.methodeOf == "Update") {
                let filterdata = formdata.find(
                    (elm) => elm.template_name === scope.forms[0].template_name
                );
                if (
                    !filterdata.fields.find(
                        (elm) => elm.model === scope.activeField.model
                    )
                ) {

                    if (form[index].link_type !== "" && form[index].link_type == 'Parent') {

                        for (let i = 0; i < form.length; i++) {
                            if (form[index].model == form[i].linked_to && form[i].link_type == "Child") {
                                FormBuilder.deleteElement(i, form);
                            }
                        }
                    }
                    FormBuilder.deleteElement(index, form);
                    scope.forms[0].fields = scope.forms[0].fields.filter(function (
                        obj
                    ) {
                        return obj.position !== scope.activeField.position;
                    });
                    scope.showSnackBar("Field was deleted successfully1!");
                } else {

                    if (form[index].link_type !== "" && form[index].link_type == 'Parent') {

                        let parent_data = form[index]
                        scope.$store
                        .dispatch("deleteCustomformField", {
                            template_name: scope.forms[0].template_name,
                            model: scope.activeField.model,
                        })
                        .then((response) => {
                            if (response.statusCode === 200) {
                                scope.showSnackBar("Field was deleted successfully2!");
                                FormBuilder.deleteElement(index, form);
                                scope.$store.dispatch("restoreForm", scope.forms);


                                for (let i = 0; i < form.length; i++) {
                                    if (parent_data.model == form[i].linked_to && form[i].link_type == "Child") {
                                        FormBuilder.deleteElement(i, form);
                                        scope.$store
                                            .dispatch("deleteCustomformField", {
                                                template_name: scope.forms[0].template_name,
                                                model: form[i].model,
                                            })
                                            .then((response) => {
                                                if (response.statusCode === 200) {
                                                    scope.showSnackBar("Field was deleted successfully2!");
                                                    FormBuilder.deleteElement(index, form);
                                                    scope.$store.dispatch("restoreForm", scope.forms);
                                                } else if (response.statusCode === 204) {
                                                    FormBuilder.deleteElement(index, form);
                                                    scope.$store.dispatch("restoreForm", scope.forms);
                                                }
                                            })
                                            .catch(() => {
                                                scope.isProcessing = false;
                                                scope.forms[0].fields = scope.forms[0].fields.filter(function (
                                                    obj
                                                ) {
                                                    return obj.position !== scope.activeField.position;
                                                });
                                                scope.showSnackBar("Field was deleted successfully3!");
                                            });
                                    }
                                }
                                



                            } else if (response.statusCode === 204) {
                                FormBuilder.deleteElement(index, form);
                                scope.$store.dispatch("restoreForm", scope.forms);
                            }
                        })
                        .catch(() => {
                            scope.isProcessing = false;
                            scope.forms[0].fields = scope.forms[0].fields.filter(function (
                                obj
                            ) {
                                return obj.position !== scope.activeField.position;
                            });
                            scope.showSnackBar("Field was deleted successfully3!");
                        });
                    } else{
                        scope.$store
                        .dispatch("deleteCustomformField", {
                            template_name: scope.forms[0].template_name,
                            model: scope.activeField.model,
                        })
                        .then((response) => {
                            if (response.statusCode === 200) {
                                
                                scope.forms[0].fields = scope.forms[0].fields.filter(function (obj) {
                                return obj.position !== scope.activeField.position;
                                });
                                scope.$store.dispatch("restoreForm", scope.forms);


                                FormBuilder.deleteElement(index, form);
                            } else if (response.statusCode === 204) {
                                
                                scope.forms[0].fields = scope.forms[0].fields.filter(function (obj) {
                                return obj.position !== scope.activeField.position;
                                });
                                scope.$store.dispatch("restoreForm", scope.forms);

                                FormBuilder.deleteElement(index, form);
                                scope.showSnackBar("Field was deleted successfully2!");
                            }
                        })
                        .catch(() => {
                            scope.isProcessing = false;
                            scope.forms[0].fields = scope.forms[0].fields.filter(function (
                                obj
                            ) {
                                return obj.position !== scope.activeField.position;
                            });
                            scope.showSnackBar("Field was deleted successfully3!");
                        });
                    }

                }
                // });
            }





        },
        CreateCustomForm() {
            const scope = this;
            var Form = scope.forms[0].fields;
            var i = 1;

            var conversion = Form.map((value) => {
                for (var key in value) {
                    if (
                        value.link_property !== "" &&
                        value.link_property !== undefined &&
                        value.link_property &&
                        (value.fieldType != "SelectList" && value.fieldType != "RadioButton") &&
                        key === "link_property"
                    ) {
                        value[key] = { map: value.link_property };
                    }
                    if (value[key] === 0 && key === "position") {
                        value[key] = i;
                    }
                    if (
                        value.hasOwnProperty(key) &&
                        key === "values" &&
                        !Array.isArray(value.values)
                    ) {
                        value[key] = [value[key]];
                    }
                }
                if(value.is_link == false){
                    value.link_type =""
                    value.linked_to =""
                    value.link_property =""
                }
                i = i + 1;
                
                return value;
            });
            let split = true;
            const payload = [{ fields: conversion }];
            if (scope.forms[0].template_name) {
                payload[0].template_name = scope.forms[0].template_name;
                const regex = /^[a-zA-Z0-9_]*$/
                if(!regex.test(scope.forms[0].template_name)){
                    this.create_popup = false
                    scope.showSnackBar("Title Special character/space/hypens  not allowed !");
                    split = false;
                }
            }else if(scope.forms[0].template_name == undefined){
                this.create_popup = false
                    scope.showSnackBar("Title is Empty !");
                    split = false;
            }
            if (scope.forms[0].type) {
                payload[0].type = scope.forms[0].type;
            }
            if (scope.forms[0].active) {
                payload[0].active = scope.forms[0].active;
            } else {
                payload[0].active = "Y";
            }
            this.validations(scope.forms[0].fields);
            payload[0].fields = this.sortfield(payload[0].fields)
            let indicator = []

                    for (let i = 0; i < payload[0].fields.length; i++) {
                        const element = payload[0].fields[i];
                        if (element.model === "" || element.model_type === "" || element.label === "") {
                            var lables
                            var req = ""
                            if (element.label === "") {
                                req += "Lable"
                            } if (element.model_type === "") {
                                req += "Model Type"
                            } if (element.model === "") {
                                req += "Model"
                            }
                            if (element.fieldType === "TextInput") {
                                lables = "Text"
                            } else if (element.fieldType === "SelectList") {
                                lables = "Select"
                            } else if (element.fieldType === "RadioButton") {
                                lables = "Radio"
                            } else if (element.fieldType === "DatePicker") {
                                lables = "Date"
                            } else if (element.fieldType === "LongTextInput") {
                                lables = "Text Area"
                            } else if (element.fieldType === "TimePicker") {
                                lables = "Time"
                            } else if (element.fieldType === "DatetimePicker") {
                                lables = "Date Time"
                            } else if (element.fieldType === "Checkbox") {
                                lables = "Checkbox"
                            }
                            console.log(element,i,"element")
                            indicator.push(`Element-${lables} & Required-${req}`)
                            
                        }else{
                           delete element.editable
                        }
                        

                    }
                    this.indicator_arr.push(...indicator)
       if(this.indicator_arr.length == 0) {
        if (split) {
                scope.$store
                    .dispatch("CreateCustomForm", payload)
                    .then((response) => {
                        if (response.statusCode === 201) {
                            scope.showSnackBar("Form was created successfully!");
                            scope.$router.push({
                                name: "builder/CustomFormList",
                            });
                        } else {
                            scope.showSnackBar(`Error: ${response.statusCode} ${response.message}`);
                        }
                    })
                    .catch((err) => {
                        this.create_popup = false
                        scope.isProcessing = false;
                        scope.showSnackBar(err);
                    });
            }
       }else{
        this.err_pop = true;
        this.create_popup = false
       }
        },
        sortfield(data){
            let fields =[]
            let scope = this
            let count = 0
            scope.catagory_arrs.forEach((elm)=>{
               let filteredata=  data.filter((elem)=>elem.catagory_value == elm)
               if(filteredata.length > 0){
                // filteredata = filteredata.sort((a,b)=>{ return a.position-b.position})
                for (let i = 0; i < filteredata.length; i++) {
                    const element = filteredata[i];
                    count++
                    element.position = count
                }
                fields = [...fields,...filteredata]
               }
            })
            return fields
        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        cloneElement(index, field, form) {
            FormBuilder.cloneElement(index, field, form);
        },
        editElementProperties(field) {
            FormBuilder.editElementProperties(field);
            this.tabchange('Properties');
        },
        UpdateCustomForm() {
            const scope = this;
            var originalForm = JSON.parse(localStorage.getItem("originalForm"));
            const originalFields = originalForm[0].fields.map((value) => {
                return value.model;
            });
            scope.forms[0].fields = scope.sortfield(scope.forms[0].fields)
            const edittedFields = scope.forms[0].fields.map((value) => {
                return value.model;
            });
            var difference = edittedFields.filter(
                (x) => originalFields.indexOf(x) === -1
            );
            const AddedFields = scope.forms[0].fields.filter(
                (x) => difference.indexOf(x.model) !== -1
            );
            this.validations(scope.forms[0].fields);
            if(this.indicator_arr.length == 0) {
                scope.updateModifiedFields().then((promiseResponse) => {
                if (AddedFields.length > 0 && promiseResponse.message === "Success") {
                    var conversion = AddedFields.map((value) => {
                        for (var key in value) {
            

                            let k = 0;
                            for (let i = 0; edittedFields.length > i; ++i) {
                                if (originalFields[i] !== edittedFields[i]) {
                                    k = i;
                                    if (value[key] === 0 && key === "position") {
                                        value[key] = k;
                                    }
                                }
                            }
                            if (
                                value.hasOwnProperty(key) &&
                                key === "values" &&
                                !Array.isArray(value.values)
                            ) {
                                value[key] = [value[key]];
                            }
                        }
                        
                        return value;
                    });

                    const payload = [{
                        fields: conversion
                    }];
                    if (scope.forms[0].template_name) {
                        payload[0].template_name = scope.forms[0].template_name;
                    }
                    let indicator = []
                    for (let i = 0; i < payload[0].fields.length; i++) {

                        const element = payload[0].fields[i];
                        if (element.model === "" || element.model_type === "" || element.label === "") {
                            var lables
                            var req = ""
                            if (element.label === "") {
                                req += "Lable"
                            } if (element.model_type === "") {
                                req += "Model Type"
                            } if (element.model === "") {
                                req += "Model"
                            }
                            if (element.fieldType === "TextInput") {
                                lables = "Text"
                            } else if (element.fieldType === "SelectList") {
                                lables = "Select"
                            } else if (element.fieldType === "RadioButton") {
                                lables = "Radio"
                            } else if (element.fieldType === "DatePicker") {
                                lables = "Date"
                            } else if (element.fieldType === "LongTextInput") {
                                lables = "Text Area"
                            } else if (element.fieldType === "TimePicker") {
                                lables = "Time"
                            } else if (element.fieldType === "DatetimePicker") {
                                lables = "Date Time"
                            } else if (element.fieldType === "Checkbox") {
                                lables = "Checkbox"
                            }
                            indicator.push(`Element-${lables} & Required-${req}`)
                        } else{
                            delete element.editable
                        }

                    }
                    this.indicator_arr.push(...indicator)
                    if(this.indicator_arr.length == 0) {
                    this.$store
                        .dispatch("CreateNewField", payload)
                        .then((response) => {
                            if (response.message === "Success") {
                                scope.showSnackBar("New Field was updated successfully!");
                                scope.$router.push({
                                    name: "builder/CustomFormList",
                                });
                            } else {
                                scope.showSnackBar("Field to Update");
                            }
                        })
                        .catch((err) => {
                            if (this.indicator_arr[0] === `no required`) {
                                scope.showSnackBar(`${err.data.error}`);
                            } else {
                                this.err_pop = true
                            }

                        });
                    }else{
                        this.err_pop = true;
                        this.edit_popup = false
                    }
                } else if (promiseResponse.message === "Failure") {
                    scope.showSnackBar(`${promiseResponse.error}`);
                } else if (promiseResponse.message === "Success") {
                    scope.$router.push({
                        name: "builder/CustomFormList",
                    });

                } else {
                    scope.showSnackBar(`Error: Please Add New Fields!`);
                }
            });
            this.edit_popup = false
            }else{
                this.err_pop = true;
                this.edit_popup = false
            }
          
        },
        validations(val) {
            this.indicator_arr=[];
            let validation_str = '';
                let validationclone = val.map((res) => {
                    if (res.is_link === true) {
                        if (res.link_type === 'Child') {
                            if (res.linked_to != '') {
                                if (res.fieldType != 'SelectList' && res.fieldType != 'RadioButton') {
                                    if (res.link_property === '') {
                                        return `${res.label} field select one option in Linked to property.`;
                                    } else if (res.link_property != '') {
                                        return true;
                                    }
                                } else if (res.fieldType == 'SelectList' || res.fieldType == 'RadioButton') {
                                    if (Object.keys(res.link_property).length == 0) {
                                        return `${res.label} field give some option values in Linked to property.`;
                                    } else if (Object.keys(res.link_property).length > 0) {
                                        if (Object.values(res.link_property).every(res => res != '')) {
                                            return true;
                                        } else {
                                            return `${res.label} field give some option values in Linked to property.`;
                                        }
                                    }
                                }
                            } else if (res.linked_to === '') {
                                return `${res.label} field Linked to property is Missing.`;
                            }
                        } else if (res.link_type === 'Parent') {
                            return true;
                        }
                    } else if (res.is_link === false) {
                        return true;
                    }
                })
                validation_str += `${validationclone.toString()},`;

            let validation = validation_str.slice(0, validation_str.length - 1).split(',');

            let validate = validation.filter(res => res != 'true');

                this.indicator_arr.push(...validate);
        },
        backpage() {
            if (this.reset_popup === false) {
                this.reset_popup = true
                this.back = true
            } else {

                this.back = false
                this.$router.push({
                    name: "builder/CustomFormList",
                });
            }
        },
        updateModifiedFields() {
            var scope = this;
            return new Promise((resolve, reject) => {
                var originalForm = JSON.parse(localStorage.getItem("originalForm"));
                const updatedObject = [];
                const insertedModels = [];

                scope.forms[0].fields.map((object) => {
                    originalForm[0].fields.map((object1) => {
                        Object.keys(object).forEach((key) => {
                            if (
                                ((object[key] !== object1[key] &&
                                    object["model"] === object1["model"]) ||
                                    (object["model"] === object1["model"] &&
                                        ((key === "values" &&
                                            (object["fieldType"] === "SelectList" ||
                                                object["fieldType"] === "Checkbox" ||
                                                object["fieldType"] === "RadioButton")) ||
                                            (key === "link_property" &&
                                                object["is_link"] &&
                                                object["link_type"] === "Child")) &&
                                        JSON.stringify(object[key]) !==
                                        JSON.stringify(object1[key]))) 
                            ) {
                                if (!insertedModels.includes(object["model"])) {
                                    if (!Array.isArray(object.values)) {
                                        object.values = [object.values];
                                    }
                                    delete object.editable
                                    updatedObject.push(object);
                                }
                                insertedModels.push(object["model"]);
                            }
                        });
                        return object1;
                    });
                    return object;
                });
                scope.forms[0].fields.map((object) => {
                    for (var key in object) {
                        if (
                            object.link_property !== "" &&
                            object.link_property !== undefined &&
                            object.link_property &&
                            (object.fieldType != "SelectList" && object.fieldType != "RadioButton") &&
                            key === "link_property"
                        ) {
                            object[key] = {
                                map: object.link_property
                            };
                        }
                    }
                    return object;
                });
                let isDefaultModified = false;
                // updatedObject.map((data) => {
                //     if (data.isdefaultfield) {
                //         this.showSnackBar("You cannot modify system field");
                //         isDefaultModified = true;
                //     }
                // });
                if (!isDefaultModified) {
                    if (updatedObject.length > 0) {
                        this.$store
                            .dispatch("UpdateFields", {
                                fields: updatedObject,
                                template_name: this.forms[0].template_name,
                            })
                            .then((response) => {
                                if (response.message === "Success") {
                                    scope.showSnackBar("Old Field Updated Succesfully");
                                    resolve(response);
                                }
                            })
                            .catch((err) => {
                                err.data.message = "Failure";
                                reject(err.data);
                            });
                    } else {
                        resolve({
                            message: "Success"
                        });
                    }
                }
            });
        },
        addSection() {
            var scope = this;
            const formObj = {
                template_name: "",
                active: "Y",
                type: scope.formType,
                fields: [],
            };
            this.forms.push(formObj);
        },
        // delete section in changed by abdul

        deleteSection(val) {
            var scope = this;

            if (val === 'exit') {
                this.backpage()
            } else {
                scope.$store
                    .dispatch("GetDefaultFields", scope.forms[0].type).then((res) => {
                        if (res.data.statusCode === 200) {
                            this.reset_popup = false
                            scope.showSnackBar("Section reset successfully!");
                        }
                    })
                    .catch(function (error) {
                        scope.showSnackBar("error" + error);
                    });
            }

        },
        log: function (e) {
            let scope = this
            let fields = []
            if (e != undefined) {
                if (e.hasOwnProperty('added')) {
                    e.added.element['catagory_value'] = this.catagory_value
                    e.added.element['catagory_arr'] = this.catagory_arrs
                    scope.forms[0].fields.push(e.added.element)
                } else if (e.hasOwnProperty('moved')) {
                    scope.catagory_arrs.forEach((elm) => {

                        if (this.catagory_value !== elm) {
                            let filteredata = this.forms[0].fields.filter((elem) => elem.catagory_value == elm)
                            if (filteredata.length > 0) {
                                fields = [...fields, ...filteredata]

                            }
                        } else {
                            fields = [...fields, ...this.catagory_fields]
                        }
                    })

                    this.forms[0].fields = fields

                }

            }

            scope.form_length = scope.forms[0].fields.length
            FormBuilder.$data.fields.forEach((elm) => {
                elm.position = this.form_length + 1
            })
        }
    },
    beforeDestroy() {
        let scope = this;
        this.$store
            .dispatch("clearForm")
            .then(() => {
            })
            .catch((err) => {
                scope.showSnackBar("err" + err);
            });
    },
};
</script>
<style lang="scss" scoped>
.empty-section {
    text-align: center;
    font-size: 40px;
    background: linear-gradient(to bottom, #fff, #409eff);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

// .dragArea {
//     margin-left: auto;
//     margin-right: auto;
//     position: relative;
//     min-height: 10px;
//     height: calc(30vh);
//     z-index: 2;
// }



.form__selectedlabel {
    display: none;
    background: #ecf5ff;
    padding: 3px 5px;
    color: black;
    font-size: 10px;
    position: absolute;
    top: -17px;
    right: 15px;
}

.form__actionitem--move {
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;

    &:active,
    &:focus,
    &:hover {
        border-color: #409eff;
        background: #ecf5ff;
    }
}

.form__actionlist {
    position: absolute;
    margin-top: 10px;
    visibility: hidden;
    z-index: 3;
    right: 0;
    border-radius: 10px;
}

.form__group {
    // margin-bottom: 25px;
    border: 1px solid transparent;
    position: relative;

    &:hover {
        border-color: #409eff;

        .form__actionitem--move {
            visibility: visible;
        }
    }

    &.is--active {
        border-color: #409eff;
        background: #ecf5ff;

        .form__actionlist {
            visibility: visible;
        }

        .form__selectedlabel {
            display: inline-block;
        }
    }
}

.section-block {
    background-color: #fafafa;
}

.section-block .source {
    padding: 10px;
}

.section-block .meta {
    // margin: 10px;
    background-color: #fafafa;
    padding: 15px 2px 2px 2px;
}

.form__field {
    background-color: #e9edf1;
    border-radius: 5px;
    margin: 3px;
}

.resetbtn {
    background-color: #969696;
    font-size: 14px;
    border-radius: 5px;
    height: 40px;
    width: 92px;
    margin-left: 26px;
    padding: 2px;
    text-align: center;
    /* margin-right: 21px; */
    color: white;
}

.cancelbtn {
    background-color: #f31000c5;
    font-size: 14px;
    border-radius: 5px;
    height: 40px;
    width: 92px;
    margin-left: 26px;
    padding: 2px;
    text-align: center;
    color: white;
}

.headerfield {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 10px 0px 10px;
    padding: 5px;
}

.headerbtn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 200px;
    padding-top: 10px;
    padding-left: 0px;

}

.textfield {
    width: 397px;
}

/*my logic*/
.left_head {
    display: flex;
    justify-content: space-between;
}

.form_icon {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
}

.icon_cls_custom_form {
    margin-top: 20px;
    font-size: 20px;
    padding: 0px 20px 0px 20px;
}

.header_custom {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0px 20px
}

.header_txt_form {
    padding: 10px;
}

.main_view_custom {
    padding: 0px 20px 20px 20px;

}

.name_input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main_custom_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 480px;
    border-radius: 5px;
}

.left_custom {
    width: 69%;
    overflow: auto;
    border: 1px solid #cacaca;
    padding: 5px;
    border-radius: 5px;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
}


.fields-dragarea{
overflow: auto;
}


.right_custom {
    width: 30%;
    border: 1px solid #cacaca;
    display: flex;
    flex-direction: column;

    border-radius: 5px;

}

.controll_panal_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    margin-bottom: 3px;
    height: 50px;

}

.controll_panal_body {
    max-height: 430px;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 5px;
}

.bts_custom_form {
    background-color: rgb(33, 150, 243);
    width: 50%;
    height: 40px;
    border-radius: 10px;
    text-align: center;
    padding: 4px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #cacaca;
    padding-top: 10px
}

.bts_custom_form_change {

    background-color: rgb(189, 189, 189);
    width: 50%;
    height: 40px;
    border-radius: 10px;
    text-align: center;
    padding: 4px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #cacaca;
    padding-top: 10px;
}

.catagory {
    display: flex;
    flex-direction: row;
    width: 100%;
}
</style>
