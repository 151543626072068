<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
    ></section-definition>
    <v-card style="margin: 20px">
      <v-container fluid="true">
        <div class="toplayer">
          <div>
            <div class="titlecls title">REPORT BUILDER WIDGET CREATION</div>
          </div>
          <div>
            <toplayer
              @layeroneDatePass="passdata($event)"
              @layeroneDate="getColumns($event)"
              ref="childref"
            ></toplayer>
          </div>
        </div>
        <div
          class="layertwo"
          v-if="
            (create.data_table && create.widget_type == 'Chart') ||
            (create.data_table &&
              create.widget_type == 'Table' &&
              create.chart_type)
          "
        >
          <div>
            <div class="titlecls title">{{ title_str }} CONFIGURATION</div>
          </div>
          <div class="flexboxs" style="margin-top: 25px">
            <div><layertwo
              ref="childlayertwo"
              @filter_button_validator="filter_button_validators($event)"
              @summarize_edited_save="summarize_edited_save"
              @sort_edited_save="sort_edited_save"
              @edit_filter="edit_filter"
              @filter_edited_save="filter_edited_save"
              @edit_summarize="edit_summarize"
              @function_group="function_group"
              @function_filter="function_filter"
              @function_summarize="function_summarize"
              @edit_sort="edit_sort"
              @function_sort="function_sort"
              :sorted="sorted"
              :filters="filters"
              :metrics="metrics"
              :sort_editable_icon="sort_editable_icon"
              :edit_icon="edit_icon"
              :Summarize_edit_icon="Summarize_edit_icon"
              :filter_edit="filter_edit"
              :sort_edit="sort_edit"
              :summarize_edit="summarize_edit"
              :widget_type="create.widget_type"
              :table_type="create.chart_type"
            ></layertwo>
            </div>
            <div class="textarea_cls">
              <viewLayer
                :sort_area="sort_area"
                :group_area="group_area"
                :filter_area="filter_area"
                :widgetType="create.widget_type"
                :table_type="create.chart_type"
                :function_area="function_area"
              ></viewLayer>
            </div>
          </div>
        </div>
        <div
          class="filters_class"
          v-if="
            summarize_function ||
            summarize_filter ||
            summarize_sort ||
            filter_edit ||
            sort_edit ||
            summarize_group ||
            summarize_edit
          "
        >
          <div>
            <div class="titlecls" v-if="summarize_function || summarize_edit">
              SUMMARIZE SETUP
            </div>
            <div class="titlecls" v-if="summarize_filter || filter_edit">
              FILTER SETUP
            </div>
            <div class="titlecls" v-if="summarize_sort">SORT SETUP</div>
            <div class="titlecls" v-if="summarize_group">GROUP SETUP</div>
          </div>
          <addSummarize
            @summarize_save="summarize_save"
            @function_cancel="function_cancel"
            @reseticon="reseticon"
            @summarize_data_pass="summarize_assign_function($event)"
            :xaxis_columns="xaxis_columns"
            :data_table="create.data_table"
            :function_types="function_types"
            :summarize_function="summarize_function"
          ></addSummarize>
          <v-space></v-space>
          <addfilter
          :filter_button_validator = "filter_button_validator"
            @multiselect_function="multiselect_function($event)"
            @dategetter="dategetter($event)"
            @rangedate_function="rangedate_function($event)"
            @filter_cancel="filter_cancel"
            @filter_save="filter_save"
            @filterValuePasser="filterValuePasser($event)"
            @operation_assign="operation_assign($event)"
            @datecreater="datecreater($event)"
            @type_assign="type_assign($event)"
            @reseticon="reseticon"
            :datetype="datetype"
            :type="filter_metrics.type"
            :date_shower="date_shower"
            :uniqueChars="uniqueChars"
            :operations="operations"
            :varechar_operations="varechar_operations"
            :data_table="create.data_table"
            :xaxis_columns="xaxis_columns"
            :filters="filters"
            :summarize_filter="summarize_filter"
            :options="options"
          ></addfilter>
          <addsort
            @sort_save="sort_save"
            @sort_cancel="sort_cancel"
            @sortDataPasser="sortDataPasser($event)"
            @reseticon="reseticon"
            :orders="orders"
            :data_table="create.data_table"
            :summarize_sort="summarize_sort"
            :xaxis_columns="xaxis_columns"
          ></addsort>
          <addGroup
            @group_save="group_save"
            @group_cancel="group_cancel"
            @groupDataPass="groupDataPass($event)"
            @reseticon="reseticon"
            :xaxis_columns="xaxis_columns"
            :data_table="create.data_table"
            :summarize_group="summarize_group"
          ></addGroup>
          <addSummarize
            v-if="summarize_edit"
            :function_types="function_types"
            @summarize_delete="summarize_delete($event)"
            :summarize_edit="summarize_edit"
            :metrics="metrics"
            :xaxis_columns="xaxis_columns"
            :data_table="create.data_table"
          ></addSummarize>
          <addfilter
            v-if="filter_edit"
            @filter_delete="filter_delete($event)"
            @type_assign_edit="type_assign_edit($event)"
            :filter_edit="filter_edit"
            @multiselect_function="multiselect_function($event)"
            @dategetter_edit="dategetter_edit($event)"
            @rangedate_function_edit="rangedate_function_edit($event)"
            @operation_assign="operation_assign($event)"
            @datecreater_edit="datecreater_edit($event)"
            :datetype="datetype"
            :type="filter_metrics.type"
            :date_shower="date_shower"
            :uniqueChars="uniqueChars"
            :operations="operations"
            :varechar_operations="varechar_operations"
            :data_table="create.data_table"
            :xaxis_columns="xaxis_columns"
            :filters="filters"
            :summarize_filter="summarize_filter"
            :options="options"
          ></addfilter>
          <addsort
            v-if="sort_edit"
            :sorted="sorted"
            :sort_edit="sort_edit"
            @reseticon="reseticon"
            @sort_delete="sort_delete($event)"
            :orders="orders"
            :data_table="create.data_table"
            :summarize_sort="summarize_sort"
            :xaxis_columns="xaxis_columns"
          ></addsort>
        </div>
      </v-container>
      <div style="display: flex;justify-content: space-between;padding: 0px 15px 15px 20px;">
        <div style="display: flex">
          <v-checkbox
            style="margin-top: 20px"
            color="primary"
            v-model="create.isShowOnDashboard"
            persistent-hint
          ></v-checkbox>
          <div style="margin-top: 28px">Show On Dashboard</div>
        </div>
        <div style="display: flex;margin-top: 15px;justify-content: space-between">
          <v-btn color="light" flat @click="goback">Cancel</v-btn>
          <v-btn color="primary"  @click="validation">Create</v-btn>
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import layertwo from "@/components/reportBuilders/layerTwo";
import toplayer from "@/components/reportBuilders/layerOne";
import viewLayer from "@/components/reportBuilders/view_layer";
import addSummarize from "@/components/reportBuilders/add-summarize.vue";
import addfilter from "@/components/reportBuilders/add-filter.vue";
import addsort from "@/components/reportBuilders/add-sort.vue";
import addGroup from "@/components/reportBuilders/add-group.vue";
export default {
  components: {
    viewLayer,
    toplayer,
    layertwo,
    addSummarize,
    addfilter,
    addsort,
    addGroup,
  },
  data() {
    return {
      filter_button_validator:false,
      title_str: "",
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      page: {
        title: "Create Widget",
        icon: "fa-plus fa-2x",
      },
      edit_icon: true,
      Summarize_edit_icon: true,
      sort_editable_icon: true,
      propsdata: [],
      isActive: false,
      datatype: "",
      uniqueChars1: [],
      uniqueChars: [],
      datetype: [
        "Today",
        "Yesterday",
        "This Week",
        "Last 7 days",
        "This Month",
        "This Year",
        "On",
        "After",
        "BETWEEN",
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      table_names: [],
      seconds: [],
      widget_types: ["Chart", "Table"],
      chart_types: [
        "Zoom Time",
        "polar Area",
        "pie",
        "Lable line",
        "Basic Line",
        "Basic Bar",
        "Group Bar",
        "Stacked Bar",
        "Column",
        "Column Group",
        "donut",
        "Gradient Radial",
        "Multiple Radial",
      ],
      xaxis_columns: [],
      yaxis_columns: [],
      create: {
        widget_name: "",
        data_table: "",
        widget_type: "",
        refresh_rate_sec: "",
        isShowOnDashboard: false,
        row_limit: "",
        xaxis_column: "",
        yaxis_column: "",
        chart_type: "",
        column: "",
      },
      summarize_function: false,
      summarize_edit: false,
      function_types: [
        "select",
        "sum_of",
        "count_of",
        "average_of",
        "min_of",
        "max_of"
      ],
      function_metrics: {
        function_type: "",
        column_name: "",
        alias_name: "",
      },
      metrics: [],
      function_area: "",
      summarize_filter: false,
      operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IN",
        "NOT IN",
        "IS NULL",
        "IS NOT NULL",
        "BETWEEN", //varchar
        "NOT BETWEEN", //varachar
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      varechar_operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "IN",
        "NOT IN",
        "Equal",
        // "Greater Than",
        // "Lesser Than",
        // "Greater Than Equal",
        // "Lesser Than Equal",
        "Not Equal",
      ],
      options: ["AND", "OR"],
      filter_metrics: {
        column_name: "",
        operation: "",
        option: "",
        column_value: "",
        type: "",
        date_picker: "",
        field_value_str: "",
        field_value: [],
        start: "",
        end: "",
        temp_operation: "",
      },
      filters: [],
      filter_area: "",
      filter_edit: false,
      summarize_sort: false,
      orders: ["ASC", "DESC"],
      sort: {
        column_name: "",
        order: "",
      },
      sorted: [],
      sort_area: "",
      sort_edit: false,
      summarize_group: false,
      group: "",
      group_area: "",
      rangedate: "",
      date_getters: "",
      date_shower: "",
      multiselect: "",
      operator_input: "",
      str: "",
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    const scope = this;
    var widgetTable = JSON.parse(localStorage.getItem("widgetTable"));
    scope.widget_types = widgetTable.widget_types;
    scope.chart_types = widgetTable.chart_types;
    widgetTable.tables.forEach((eml) => {
    
        scope.table_names.push(eml);
     
    });

    for (let i = 5; i <= 90; i++) {
      if (i % 5 == 0) {
        scope.seconds.push(i);
      }
    }
  },
  methods: {
    filter_button_validators(val) {
this.filter_button_validator = val
    },
    groupDataPass(val) {
      this.group = val;
    },
    sortDataPasser(val) {
      let split_value = val.split(",");
      this.sort[`${split_value[0]}`] = split_value[1];
    },
    filterValuePasser(val) {
      let split_value = val.split(",");
      this.filter_metrics[`${split_value[0]}`] = split_value[1];
    },
    summarize_assign_function(val) {
      let split_val = val.split(",");
      this.function_metrics[`${split_val[0]}`] = split_val[1];
    },
    operation_assign(val) {
      if (val === "Equal") {
        this.filter_metrics.temp_operation = "Equal";
        this.filter_metrics.operation = "=";
      } else if (val === "Greater Than") {
        this.filter_metrics.temp_operation = "Greater Than";
        this.filter_metrics.operation = ">";
      } else if (val === "Lesser Than") {
        this.filter_metrics.temp_operation = "Lesser Than";
        this.filter_metrics.operation = "<";
      } else if (val === "Greater Than And Equal") {
        this.filter_metrics.temp_operation = "Greater Than And Equal";
        this.filter_metrics.operation = ">=";
      } else if (val === "Lesser Than Equal") {
        this.filter_metrics.temp_operation = "Lesser Than Equal";
        this.filter_metrics.operation = "<=";
      } else if (val === "Not Equal") {
        this.filter_metrics.temp_operation = "Not Equal";
        this.filter_metrics.operation = "!=";
      } else if (val === "IS NOT NULL") {
        this.filter_metrics.temp_operation = "IS NOT NULL";
        this.filter_metrics.operation = "IS NOT NULL";
      } else if (val === "IS NULL") {
        this.filter_metrics.temp_operation = "IS NULL";
        this.filter_metrics.operation = "IS NULL";
      } else if (val === "NOT LIKE") {
        this.filter_metrics.temp_operation = "NOT LIKE";
        this.filter_metrics.operation = "NOT LIKE";
      } else if (val === "LIKE %...%") {
        this.filter_metrics.temp_operation = "LIKE %...%";
        this.filter_metrics.operation = "LIKE %...%";
      } else if (val === "LIKE") {
        this.filter_metrics.temp_operation = "LIKE";
        this.filter_metrics.operation = "LIKE";
      } else if (val === "IN") {
        this.filter_metrics.temp_operation = "IN";
        this.filter_metrics.operation = "IN";
      } else if (val === "NOT IN") {
        this.filter_metrics.temp_operation = "NOT IN";
        this.filter_metrics.operation = "NOT IN";
      } else if (val === "Today") {
        this.filter_metrics.temp_operation = "Today";
        this.filter_metrics.operation = "=";
      } else if (val === "Yesterday") {
        this.filter_metrics.temp_operation = "Yesterday";
        this.filter_metrics.operation = "=";
      } else if (val === "This Month") {
        this.filter_metrics.temp_operation = "This Month";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "Last 7 days") {
        this.filter_metrics.temp_operation = "Last 7 days";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "This Week") {
        this.filter_metrics.temp_operation = "This Week";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "This Year") {
        this.filter_metrics.temp_operation = "This Year";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "On") {
        this.filter_metrics.temp_operation = "On";
        this.filter_metrics.operation = "=";
      } else if (val === "After") {
        this.filter_metrics.temp_operation = "After";
        this.filter_metrics.operation = ">";
      } else if (val === "BETWEEN") {
        this.filter_metrics.temp_operation = "BETWEEN";
        this.filter_metrics.operation = "BETWEEN";
      }
    },
    multiselect_function(multiselect) {
      this.multiselect = multiselect;
      this.filter_metrics.column_value = multiselect;
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    formatDate(date) {
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      date = yyyy + "-" + mm + "-" + dd;
      return date;
    },
    datecreater_edit(val) {
      let splitdata = val.split(",");
      this.datecreater(splitdata[0], splitdata[1]);
    },
    datecreater(operater_val, index) {
      if (operater_val) {
        this.operation_assign(operater_val);
      }
      if (
        operater_val === "Today" ||
        operater_val === "Yesterday" ||
        operater_val === "This Week" ||
        operater_val === "This Month" ||
        operater_val === "Last 7 days" ||
        operater_val === "This Year"
      ) {
        if (operater_val === "Today") {
          let today = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = today;
          if (index >= 0) {
            this.filters[index].column_value = today;
          }

          this.filter_metrics.column_value = today;
        } else if (operater_val === "Yesterday") {
          let Yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 10000)
            .toISOString()
            .substr(0, 10);
          this.date_shower = Yesterday;
          if (index >= 0) {
            this.filters[index].column_value = Yesterday;
          }
          this.filter_metrics.column_value = Yesterday;
        } else if (operater_val === "This Week") {
          let monday = this.getMonday(new Date()).toISOString().substr(0, 10);
          let today = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${monday} - ${today}`;
          if (index >= 0) {
            this.filters[index].column_value = `${monday} , ${today}`;
          }
          this.filter_metrics.column_value = `${monday} , ${today}`;
        } else if (operater_val === "Last 7 days") {
          var today = new Date();
          let sixDaysAgo = new Date(today);
          sixDaysAgo.setDate(today.getDate() - 6);
          var Last_7_days = this.formatDate(sixDaysAgo);
          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);

          this.date_shower = `${Last_7_days} - ${today_}`;
          if (index >= 0) {
            this.filters[index].column_value = `${Last_7_days} , ${today_}`;
          }
          this.filter_metrics.column_value = `${Last_7_days} , ${today_}`;
        } else if (operater_val === "This Year") {
          var currentYear = new Date().getFullYear();

          let currentYear_start = `${currentYear}-01-01`;
          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${currentYear_start} - ${today_}`;
          if (index >= 0) {
            this.filters[
              index
            ].column_value = `${currentYear_start} , ${today_}`;
          }
          this.filter_metrics.column_value = `${currentYear_start} , ${today_}`;
        } else if (operater_val === "This Month") {
          var currentYear_ = new Date().getFullYear();
          var currentmonth = new Date().getMonth() + 1;
          let current_month_start;
          if (currentmonth < 10) {
            current_month_start = `${currentYear_}-0${currentmonth}-01`;
          } else {
            current_month_start = `${currentYear_}-${currentmonth}-01`;
          }

          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${current_month_start} - ${today_}`;
          if (index >= 0) {
            this.filters[
              index
            ].column_value = `${current_month_start} , ${today_}`;
          }
          this.filter_metrics.column_value = `${current_month_start} , ${today_}`;
        }
      }
    },
    dategetter_edit(val) {
      let splitdata = val.split(",");
      this.dategetter(splitdata[0], splitdata[1]);
    },
    dategetter(val, index) {
      this.filter_metrics.column_value = val;
      this.date_getters = val;
      if (index === 0 || index > 0) {
        this.filters[index].column_value = val;
      }
    },
    rangedate_function_edit(val) {
      let splitdata = val.split(",");
      this.rangedate_function(splitdata[0], splitdata[1]);
    },
    rangedate_function(val, index) {
      this.filter_metrics.column_value = val.dbdata;
      this.filter_metrics.start = val.display_start_date;
      this.filter_metrics.end = val.display_end_date;
      this.rangedate = val;
      if (index === 0 || index > 0) {
        this.filters[index].column_value = val.dbdata;
        this.filters[index].start = val.display_start_date;
        this.filters[index].end = val.display_end_date;
      }
    },

    type_assign_edit(value) {
      let splitvalue = value.split(".");
      let val = splitvalue[0];
      let index = splitvalue[1];
      this.filters[index].column_value = "";
      let obj = {
        field_name: val,
        table_name: this.create.data_table,
      };
      let arr = [];
      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        response.forEach((element) => {
          arr.push(element[`${val}`]);
        });
      });
      this.filters[index].field_value = arr;
      for (let i = 0; i < this.xaxis_columns.length; i++) {
        if (this.xaxis_columns[i].value === val) {
          this.filters[index].type = this.xaxis_columns[i].type;
          if (this.filters[index].type === "varchar") {
            this.datatype = "varchar";
          } else {
            this.datatype = "";
          }
        }
        if (
          this.xaxis_columns[i].type !== "datetime" ||
          this.xaxis_columns[i].type !== "date"
        ) {
          this.filter_metrics.date_picker = "";
        }
      }
    },
    type_assign(val) {
      for (let i = 0; i < this.xaxis_columns.length; i++) {
        if (this.xaxis_columns[i].value === val) {
          this.filter_metrics.type = this.xaxis_columns[i].type;
          if (this.filter_metrics.type === "varchar") {
            this.datatype = "varchar";
          } else {
            this.datatype = "";
          }
        }
        if (
          this.xaxis_columns[i].type !== "datetime" ||
          this.xaxis_columns[i].type !== "date"
        ) {
          this.filter_metrics.date_picker = "";
        }
      }

      if (this.datatype === "varchar") {
        this.varcharedata(val);
      }
    },
    varcharedata(val) {
      let obj = {
        field_name: val,
        table_name: this.create.data_table,
      };
      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        let arr = [];
        response.forEach((element) => {
          arr.push(element[`${val}`]);
        });
        this.uniqueChars = [...new Set(arr)];
        this.filter_metrics.field_value = [...new Set(arr)];
      });
    },
    passdata(data) {
      let splitArr = data.split(".");
      this.create[`${splitArr[0]}`] = splitArr[1];
      // delete
      if (
        splitArr[1] == "Consolidate_Report" ||
        splitArr[1] == "Detail_Report" ||
        (this.create.data_table !== "" && splitArr[1] == "Chart")
      ) {
        //  this.create.chart_type=""
        this.create.xaxis_column = "";
        this.create.yaxis_column = "";
        this.metrics = [];
        this.function_area = "";
        this.$refs.childref.getempy(`${splitArr[1]}`);
      } else if (this.create.data_table !== "" && splitArr[1] == "Table") {
        this.create.chart_type = "";
        this.create.xaxis_column = "";
        this.create.yaxis_column = "";
        this.$refs.childref.getempy(`${splitArr[1]}`);
      }
    },
    getColumns(table) {
      const scope = this;
      scope.title_str = table[1].replace(/_/g, " ").toUpperCase();
      scope.create.data_table = table[1];
      scope.yaxis_columns = table[0];
      scope.xaxis_columns = table[0];
      scope.filter_area = "";
      scope.function_area = "";
      scope.sort_area = "";
      scope.group_area = "";
      scope.metrics = [];
      scope.filters = [];
      scope.sorted = [];
      scope.summarize_edit = false;
      scope.filter_edit = false;
      scope.sort_edit = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.option = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.type = "";
      scope.filter_metrics.date_picker = "";
      scope.filter_metrics.field_value = [];
      (scope.filter_metrics.start = ""), (scope.filter_metrics.end = "");

      this.create.xaxis_column = "";
      this.create.yaxis_column = "";
      this.metrics = [];
      this.function_area = "";
      this.create.chart_type = "";
      this.$refs.childref.getempy("Chart");
    },
    goback() {
      const scope = this;
      scope.$router.push({
        name: "builder/Widgets",
      });
    },
    function_summarize() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_function = true;
    },
    function_filter() {
      var scope = this;
      scope.date_shower = "";
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_filter = true;
    },
    function_sort() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_sort = true;
    },
    function_group() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_edit = false;
      scope.summarize_group = true;
    },
    sort_cancel() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
    },
    sort_save() {
      var scope = this;
      scope.sorted.push(JSON.parse(JSON.stringify(scope.sort)));
      if (scope.sort_area !== "") scope.sort_area += ", ";
      scope.sort_area += scope.sort.column_name + " " + scope.sort.order;
      scope.summarize_sort = false;
      scope.sort_editable_icon = true;
      scope.sort.column_name = "";
      scope.sort.order = "";
    },
    group_cancel() {
      var scope = this;
      scope.group = "";
      scope.group_area = "";
      scope.summarize_group = false;
    },
    group_save() {
      var scope = this;
      scope.group_area = "";
      if (scope.group_area !== "") scope.group_area += ", ";
      scope.group_area += JSON.parse(JSON.stringify(scope.group));
      scope.summarize_group = false;
    },
    summarize_save() {
      var scope = this;

      scope.metrics.push(JSON.parse(JSON.stringify(scope.function_metrics)));
      if (scope.function_area !== "") scope.function_area += ", ";
      scope.function_area +=
        scope.metrics[scope.metrics.length - 1].function_type.replace(
          "_",
          " "
        ) +
        " " +
        scope.metrics[scope.metrics.length - 1].column_name;

      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_function = false;
    },
    function_cancel() {
      var scope = this;
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
    },
    filter_cancel() {
      var scope = this;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
    },
    filter_save() {
      this.propsdata = [];
      this.props_funstion();
      var scope = this;
      let filter_value = scope.filter_metrics;
      let data = {
        column_name: filter_value.column_name,
        operation: filter_value.operation,
        column_value: filter_value.column_value,
        option: filter_value.option,
        type: filter_value.type,
        date_picker: filter_value.date_picker,
        field_value: filter_value.field_value,
        start: filter_value.start,
        end: filter_value.end,
        temp_operation: filter_value.temp_operation,
      };

      scope.filters.push(JSON.parse(JSON.stringify(data)));
      if (scope.filter_area !== "") scope.filter_area += ", ";
      if (scope.filters[scope.filters.length - 1].option == "") {
        scope.filter_area +=
          scope.filters[scope.filters.length - 1].column_name +
          " " +
          scope.filters[scope.filters.length - 1].operation +
          " " +
          scope.filters[scope.filters.length - 1].column_value;
      } else {
        scope.filter_area +=
          scope.filters[scope.filters.length - 1].option +
          " " +
          scope.filters[scope.filters.length - 1].column_name +
          " " +
          scope.filters[scope.filters.length - 1].operation +
          " " +
          scope.filters[scope.filters.length - 1].column_value;
      }

      // scope.filter_area +=
      //   scope.filters[scope.filters.length - 1].option +
      //   " " +
      //   scope.filters[scope.filters.length - 1].column_name +
      //   " " +
      //   scope.filters[scope.filters.length - 1].operation +
      //   " " +
      //   scope.filters[scope.filters.length - 1].column_value;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.filter_metrics.date_picker = "";
      scope.filter_metrics.type = "";
      scope.filter_metrics.field_value = [];

      scope.summarize_filter = false;
      this.operator_input = "";
    },
    edit_summarize() {
      ///2
      var scope = this;
      scope.Summarize_edit_icon = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.filter_edit = false;
      scope.sort_edit = false;
      if (scope.function_area !== "" && scope.function_area) {
        scope.summarize_edit = true;
      }
    },
    summarize_edited_save() {
      var scope = this;
      scope.function_area = "";
      scope.Summarize_edit_icon = true;
      for (let i = 0; i < scope.metrics.length; i++) {
        scope.function_area +=
          scope.metrics[i].function_type.replace("_", " ") +
          " " +
          scope.metrics[i].column_name;
        if (i < scope.metrics.length - 1) {
          scope.function_area += ",";
        }
      }
      scope.summarize_edit = false;
    },
    summarize_delete(values) {
      var scope = this;
      let remain_elm = [];
      scope.function_area = "";
      for (let i = 0; i < scope.metrics.length; i++) {
        ///111
        if (scope.metrics.length === 1) {
          scope.Summarize_edit_icon = true;
        }
        let remove_elm = [];
        if (
          scope.metrics[i].column_name === values.column_name &&
          scope.metrics[i].function_type === values.function_type &&
          scope.metrics[i].alias_name === values.alias_name
        ) {
          remove_elm.push(scope.metrics[i]);
        } else {
          remain_elm.push(scope.metrics[i]);
          if (scope.function_area !== "") scope.function_area += ", ";
          scope.function_area +=
            scope.metrics[i].function_type.replace("_", " ") +
            " " +
            scope.metrics[i].column_name;
        }
      }
      scope.metrics = [];
      scope.metrics = [...remain_elm];
      if (scope.metrics.length <= 0) {
        this.$refs.childlayertwo.icon_changer("reset");
        scope.summarize_edit = false;
      }
    },
    reseticon() {
      this.$refs.childlayertwo.icon_changer("reset");
    },
    edit_filter() {
      ///2
      var scope = this;
      scope.edit_icon = false;
      scope.isActive = true;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.sort_edit = false;
      if (scope.filter_area !== "" && scope.filter_area) {
        scope.filter_edit = true;
      }
    },

    props_funstion() {
      for (let i = 0; i < this.filters.length; i++) {
        const element = this.filters[i];
        if (element.type === "datetime" || element.type === "date") {
          if (element.operation === "BETWEEN") {
            this.propsdata.push(element.column_value.split(","));
          }
        }
      }
    },
    filter_edited_save() {
      var scope = this;
      scope.edit_icon = true;
      scope.filter_area = "";
      for (let i = 0; i < scope.filters.length; i++) {
        let val = scope.filters[i].temp_operation;
        if (val === "Equal") {
          scope.filters[i].operation = "=";
        } else if (val === "Greater Than") {
          scope.filters[i].operation = ">";
        } else if (val === "Lesser Than") {
          scope.filters[i].operation = "<";
        } else if (val === "Greater Than And Equal") {
          scope.filters[i].operation = ">=";
        } else if (val === "Lesser Than Equal") {
          scope.filters[i].operation = "<=";
        } else if (val === "Not Equal") {
          scope.filters[i].operation = "!=";
        } else if (val === "IS NOT NULL") {
          scope.filters[i].operation = "IS NOT NULL";
        } else if (val === "IS NULL") {
          scope.filters[i].operation = "IS NULL";
        } else if (val === "NOT LIKE") {
          scope.filters[i].operation = "NOT LIKE";
        } else if (val === "LIKE %...%") {
          scope.filters[i].operation = "LIKE %...%";
        } else if (val === "LIKE") {
          scope.filters[i].operation = "LIKE";
        } else if (val === "IN") {
          scope.filters[i].operation = "IN";
        } else if (val === "NOT IN") {
          scope.filters[i].operation = "NOT IN";
        } else if (val === "Today") {
          scope.filters[i].operation = "=";
        } else if (val === "Yesterday") {
          scope.filters[i].operation = "=";
        } else if (val === "This Month") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "Last 7 days") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "This Week") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "This Year") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "On") {
          scope.filters[i].operation = "=";
        } else if (val === "After") {
          scope.filters[i].operation = ">";
        } else if (val === "BETWEEN") {
          scope.filters[i].operation = "BETWEEN";
        }
        if (
          scope.filters[i].operation === "IS NULL" ||
          scope.filters[i].operation === "IS NOT NULL"
        ) {
          scope.filters[i].column_value = "";
        }
        if (scope.filters[i].option == "") {
          scope.filter_area +=
            scope.filters[i].column_name +
            " " +
            scope.filters[i].operation +
            " " +
            scope.filters[i].column_value;
        } else {
          scope.filter_area +=
            scope.filters[i].option +
            " " +
            scope.filters[i].column_name +
            " " +
            scope.filters[i].operation +
            " " +
            scope.filters[i].column_value;
        }
        if (i < scope.filters.length - 1) {
          scope.filter_area += ", ";
        }
      }
      scope.filter_edit = false;
    },
    filter_delete(values) {
      var scope = this;
      let remain_elm = [];
      scope.filter_area = "";
      let count = 0;
      for (let i = 0; i < scope.filters.length; i++) {
        let remove_elm = [];
        if (scope.filters.length === 1) {
          scope.edit_icon = true;
        }
        if (
          scope.filters[i].column_name === values.column_name &&
          scope.filters[i].operation === values.operation &&
          scope.filters[i].column_value === values.column_value
        ) {
          remove_elm.push(scope.filters[i]);
        } else {
          remain_elm.push(scope.filters[i]);
          if (count > 0) {
            if (scope.filter_area !== "") scope.filter_area += ", ";
            scope.filter_area +=
              scope.filters[i].option != ""
                ? scope.filters[i].column_name +
                  " " +
                  scope.filters[i].operation +
                  " " +
                  scope.filters[i].column_value
                : scope.filters[i].option +
                  " " +
                  scope.filters[i].column_name +
                  " " +
                  scope.filters[i].operation +
                  " " +
                  scope.filters[i].column_value;
          } else {
            if (scope.filter_area !== "") scope.filter_area += ", ";
            scope.filter_area +=
              scope.filters[i].option != ""
                ? scope.filters[i].column_name +
                  " " +
                  scope.filters[i].operation +
                  " " +
                  scope.filters[i].column_value
                : scope.filters[i].option +
                  " " +
                  scope.filters[i].column_name +
                  " " +
                  scope.filters[i].operation +
                  " " +
                  scope.filters[i].column_value;
            count++;
          }
        }
      }
      scope.filters = [];
      scope.filters = [...remain_elm];
      if (scope.filters.length <= 0) {
        this.$refs.childlayertwo.icon_changer("reset");
        scope.filter_edit = false;
      }
    },
    edit_sort() {
      var scope = this;
      scope.sort_editable_icon = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.filter_edit = false;
      if (scope.sort_area !== "" && scope.sort_area) {
        scope.sort_edit = true;
      }
    },
    sort_edited_save() {
      var scope = this;
      scope.sort_area = "";
      scope.sort_editable_icon = true;
      for (let i = 0; i < scope.sorted.length; i++) {
        scope.sort_area +=
          scope.sorted[i].column_name + " " + scope.sorted[i].order;
        if (i < scope.sorted.length - 1) {
          scope.sort_area += ",";
        }
      }
      scope.sort_edit = false;
    },
    sort_delete(values) {
      var scope = this;
      let remain_elm = [];

      for (let i = 0; i < scope.sorted.length; i++) {
        let remove_elm = [];
        if (
          scope.sorted[i].column_name === values.column_name &&
          scope.sorted[i].order === values.order
        ) {
          remove_elm.push(scope.sorted[i]);
        } else {
          remain_elm.push(scope.sorted[i]);
        }
      }
      scope.sorted = [];
      scope.sorted = [...remain_elm];

      let del_val = `${values.column_name} ${values.order}`;
      let temp_str = scope.sort_area.split(", ");
      let removed_val = temp_str.filter((elm) => elm != del_val);

      scope.sort_area = "";
      for (let i = 0; i < removed_val.length; i++) {
        const elm = removed_val[i];
        if (i > 0) {
          scope.sort_area += `, ${elm}`;
        } else {
          scope.sort_area += `${elm}`;
        }
      }
      if (scope.sorted.length <= 0) {
        scope.sort_edit = false;
        this.$refs.childlayertwo.icon_changer("reset");
      }
      // let tempval = `${values.column_name} ${values.order}`;
    
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    validation() {
      let Isvalid = this.$refs.childref.validate();
      if (Isvalid == 4 || Isvalid == 7) {
        if (
          this.create.widget_type == "Table" &&
          this.create.chart_type == "Consolidate_Report" &&
          this.metrics.length == 0
        ) {
          this.showSnackBar("Summarize required");
        } else {
          this.createWidget();
        }
      }
    },
    createWidget() {
      const scope = this;
      var top = 10;
      var left = 10;
      var lostElement = {
        top: 10,
        left: 10,
        width: 400,
      };
      let filter_arr = [];
      if (this.filters.length > 0) {
        for (let i = 0; i < this.filters.length; i++) {
          
          for (let j = 0; j < this.filters[i].field_value.length; j++) {
            
            if (j === 0) {
              this.str += this.filters[i].field_value[j];
            } else {
              this.str += "," + this.filters[i].field_value[j];
            }
          }
         
          const element = this.filters[i];
          let obj = {
            column_name: element.column_name,
            operation: element.operation,
            option: element.option,
            column_value: element.column_value,
            type: element.type,
            date_picker: element.date_picker,
            field_value_str: this.str,
            start: element.start,
            end: element.end,
            temp_operation: element.temp_operation,
          };
          filter_arr.push(obj);
          this.str = "";
        }
      }
      scope.$store
        .dispatch("rect/fetchProperty")
        .then((response) => {
          if (response.length > 0) {
            lostElement.top = !isNaN(parseInt(response[0].top))
              ? parseInt(response[0].top)
              : 10;
            lostElement.left = !isNaN(parseInt(response[0].left))
              ? parseInt(response[0].left)
              : 10;
            lostElement.width = !isNaN(parseInt(response[0].width))
              ? parseInt(response[0].width)
              : 400;
            if (lostElement.left === 10 && lostElement.width <= 400) {
              top = lostElement.top;
              left = 420;
            } else {
              top = lostElement.top;
              top += 320;
              left = 10;
            }
          } else {
            top = 10;
            left = 420;
          }

          if (scope.metrics.length > 0) {
            for (let i = 0; i < scope.metrics.length; i++) {
              scope.metrics[i].column_order = i + 1;
            }
          }

          const widget = {
            name: scope.create.widget_name,
            data_table: scope.create.data_table,
            type: scope.create.widget_type,
            xaxis_column: scope.create.xaxis_column,
            yaxis_column: scope.create.yaxis_column,
            is_shown_on_dashboard: scope.create.isShowOnDashboard,
            chart_type:
              scope.create.widget_type === "Chart" ||
              scope.create.chart_type === "Consolidate_Report" ||
              scope.create.chart_type === "Detail_Report"
                ? scope.create.chart_type
                : "",
            row_limit: scope.create.row_limit,
            backgroun_colors: {},
            labels: {},
            width: scope.create.widget_type === "Table" ? 810 : 400,
            height: 300,
            position_top: top,
            position_left: left,
            is_draggable: false,
            is_resizeable: false,
            min_width: 10,
            min_height: 10,
            axis: "both",
            parent_limit: false,
            snap_to_grid: false,
            aspect_ratio: false,
            zindex: 1,
            color: "#FFF",
            active: false,
            component:
              scope.create.widget_type === "Chart"
                ? scope.create.chart_type
                : "Table",
            class: "box-shaddow",
            refresh_rate: parseInt(scope.create.refresh_rate_sec) * 1000,
            function_metrics: scope.metrics,
            filter_metrics: filter_arr,
            group_by: scope.group_area,
            order_by: scope.sort_area,
          };
          if (scope.metrics.length <= 0) {
            delete widget.function_metrics;
          }
          if (filter_arr.length <= 0) {
            delete widget.filter_metrics;
          }

         
          if (
            scope.widget_type == "Table" &&
            scope.chart_type == "Consolidate_Report"
          ) {
            scope.create.xaxis_column = "";
            scope.create.yaxis_column = "";
          } else if (
            scope.widget_type == "Table" &&
            scope.chart_type == "Detail_Report"
          ) {
            if (widget.function_metrics != undefined)
              delete widget.function_metrics;
            scope.create.yaxis_column = "";
          }


 

          scope.$store
            .dispatch("rect/createWidget", widget)
            .then((res) => {

      if (res.widget.is_shown_on_dashboard === "Y") {
        scope.$store.dispatch("rect/changeWidget", [res.widget.id])
      }

              scope.create.widget_name = "";
              scope.create.data_table = "";
              scope.create.widget_type = "";
              scope.create.xaxis_column = "";
              scope.create.yaxis_column = "";
              scope.create.isShowOnDashboard = "";
              scope.create.chart_type = "";
              scope.create.row_limit = 0;
              scope.create.refresh_rate_sec = 5;
              scope.showSnackBar("Widget Created Succesfully");
              scope.goback();
            })
            .catch((err) => {
              scope.showSnackBar("error :" + err);
            });
        })
        .catch(function (error) {
          console.log(error);
          scope.showSnackBar("error :" + error.message);
        });
    },
  },
};
</script>

<style>
.filters_class {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}

.Sub_containers:hover {
  box-shadow: rgba(243, 128, 33, 0.3) 0px 0px 0px 2px;
}
.layertwo {
  margin-top: 15px;
  padding: 15px;
  height: auto;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
  transition: 10s;
  transition-timing-function: ease;
}
.toplayer {
  padding-top: 15px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}
.mydiv {
  background-color: #edf2ef;
}
.titlecls {
  font-size: 20px;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.flexboxs {
  display: flex;
  flex-direction: column;
}

.titlecls {
  position: relative;
  color: #0087ca;
}

.titlecls:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* .container {
  max-width: 1205px;
} */
</style>
