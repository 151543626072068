<template>
  <div>
    <div>
      <div
        style="display: flex"
        v-for="(item, index) in sorted"
        :key="'B' + index"
      >
        <v-flex xs6 v-if="sort_edit" class="sort_cls">
          <v-select
            :items="xaxis_columns"
            label="Select Column To Sort"
            item-text="name"
            item-value="value"
            return-string
            v-model="item.column_name"
            :disabled="data_table == ''"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex xs5 v-if="sort_edit" class="sort_cls">
          <v-select
            :items="orders"
            label="Order By"
            item-text="name"
            item-value="value"
            return-string
            v-model="item.order"
          ></v-select>
        </v-flex>
        <i
          v-if="sort_edit"
          @click="sort_delete(item)"
          style="
            color: rgb(33, 150, 243);
            padding: 5px;
            padding-top: 30px;
            font-size: 25px;
          "
          class="fa fa-times-circle red--text"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div>
      <v-flex style="display: flex">
        <v-flex class="sort_cls" v-if="summarize_sort">
          <v-select
            :items="xaxis_columns"
            label="Select Column To Sort"
            item-text="name"
            item-value="value"
            return-string
            v-model="sort.column_name"
            @change="sortDataPasser(sort.column_name, 'column_name')"
            :disabled="data_table == ''"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex class="sort_cls" v-if="sort.column_name && summarize_sort">
          <v-select
            :items="orders"
            label="Order By"
            item-text="name"
            item-value="value"
            return-string
            v-model="sort.order"
            @change="sortDataPasser(sort.order, 'order')"
          ></v-select>
        </v-flex>

        <v-flex class="sort_cls">
          <i
            v-if="sort.order"
            @click="sort_save"
            style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 30px;
              font-size: 25px;
            "
            class="fa fa-check green--text"
            aria-hidden="true"
          ></i>
          <i
            v-if="summarize_sort"
            @click="sort_cancel"
            style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 30px;
              font-size: 25px;
            "
            class="fa fa-times-circle red--text"
            aria-hidden="true"
          ></i
        ></v-flex>

        <v-flex class="sort_cls"></v-flex>
      </v-flex>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    sorted: {
      type: Array,
    },
    orders: {
      type: Array,
    },
    data_table: {
      type: String,
      default: "",
    },
    xaxis_columns: {
      type: Array,
    },
    sort_edit: {
      type: Boolean,
      default: false,
    },
    summarize_sort: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sort: {
        column_name: "",
        order: "",
      },
    };
  },
  methods: {
    sort_delete(val) {
      this.$emit("sort_delete",val);
    },
    sort_save() {
      this.$emit("sort_save");
      this.$emit("reseticon");
      this.sort.column_name = "";
      this.sort.order = "";
    },
    sortDataPasser(val, field) {
      this.$emit("sortDataPasser", `${field},${val}`);
    },
    sort_cancel() {
      this.sort.column_name = "";
      this.sort.order = "";
      this.$emit("reseticon");
      this.$emit("sort_cancel");
    },
  },
};
</script>
<style>
.sort_cls {
  padding: 15px;
  width: 200px;
}
</style>
