<template>
  <div
    style="
      display: flex;
      justify-content: space-between;
      width:auto
     
     
    "
  >
    <v-flex class="layertwoflexbox" v-if="widget_type === 'Table' && table_type=='Consolidate_Report'">
      <div class="Sub_containers">
        <div style="margin-left: 10px">Summarize</div>
        <div style="display: flex">
          <div>
            <i 
              v-if="summarize_edit"
              @click="summarize_edited_save"
              style="
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
              "
              class="fa fa-check green--text"
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <v-icon
              style="color: red"
              small
              v-model="Summarize_edit_icon"
              v-if="metrics.length > 0 && Summarize_edit_icon"
              @click="edit_summarize"
              >fas fa-edit</v-icon
            >
          </div>
          <i
            v-if="config.summarize"
            @click="function_summarize"
            class="fa fa-plus-circle green--text"
            aria-hidden="true"
            style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 0px;
              font-size: 22px;
              margin-left: 5px;
            "
          ></i>
        </div>
      </div>
    </v-flex>
    <v-flex class="layertwoflexbox">
      <div class="Sub_containers">
        <div style="margin-left: 10px">Filter</div>
        <div style="display: flex">
          <div>
            <i
              @click="filter_edited_save"
              v-if="filter_edit"
              style="
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
              "
              class="fa fa-check green--text"
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <v-icon
              style="color: red"
              small
              @click="edit_filter"
              v-model="edit_icon"
              v-if="this.filters.length > 0 && edit_icon"
              >fas fa-edit</v-icon
            >
          </div>
          <div>
            <i
              v-if="config.filter"
              @click="function_filter"
              class="fa fa-plus-circle green--text"
              aria-hidden="true"
              style="
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
                margin-left: 5px;
              "
            ></i>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex class="layertwoflexbox">
      <div class="Sub_containers">
        <div style="margin-left: 10px">Group</div>
        <div style="display: flex">
          <div></div>
          <div>
            <i
              v-if="config.group"
              @click="function_group"
              class="fa fa-plus-circle green--text"
              aria-hidden="true"
              v-b-tooltip.focus
  title="Add/Modify"
              style="
              cursor: pointer;
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
                margin-left: 5px;
              "
            ></i>
          </div>
        </div>
      </div>
    </v-flex>
    <v-flex class="layertwoflexbox">
      <div class="Sub_containers">
        <div style="margin-left: 10px">Sort</div>
        <div style="display: flex">
          <div>
            <i
              v-if="sort_edit"
              @click="sort_edited_save"
              style="
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
              "
              class="fa fa-check green--text"
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <v-icon
              style="color: red"
              small
              @click="edit_sort"
              v-model="sort_editable_icon"
              v-if="sorted.length > 0 && sort_editable_icon"
              >fas fa-edit</v-icon
            >
          </div>
          <div>
            <i
              v-if="config.sort"
              @click="function_sort"
              class="fa fa-plus-circle green--text"
              aria-hidden="true"
              style="
                color: rgb(33, 150, 243);
                padding: 5px;
                padding-top: 0px;
                font-size: 22px;
                margin-left: 5px;
              "
            ></i>
          </div>
        </div>
      </div>
    </v-flex>
  </div>
</template>
<script>
// import View_layer from "./view_layer.vue";
export default {
  props: {
    sorted: {
      type: Array,
      // View_layer,
    },
    filters: {
      type: Array,
    },
    metrics: {
      type: Array,
    },
    sort_editable_icon: {
      type: Boolean,
      default: true,
    },
    edit_icon: {
      type: Boolean,
      default: true,
    },
    Summarize_edit_icon: {
      type: Boolean,
      default: true,
    },
    filter_edit: {
      type: Boolean,
      default: false,
    },
    sort_edit: {
      type: Boolean,
      default: false,
    },
    summarize_edit: {
      type: Boolean,
      default: false,
    },
    widget_type: {
      type: String,
      default: "",
    },
    table_type:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      sort_editable_icons: this.sort_editable_icon,
      Summarize_edit_icons: this.Summarize_edit_icon,
      edit_icons: this.edit_icon,
      config: {
        summarize: true,
        filter: true,
        group: true,
        sort: true,
      },
    };
  },
  methods: {
    function_sort() {
      this.$emit("function_sort");
      this.icon_changer('sort')
    },
    edit_sort() {
      this.edit_icons = false;
      this.Summarize_edit_icons = false;
      this.sort_editable_icons = false;
      this.icon_changer('sort_edit')
      this.$emit("edit_sort");
    },
    sort_edited_save() {
      if (this.sorted.length > 0) {
        this.sort_editable_icons = true;
      } else {
        this.sort_editable_icons = false;
      }
      if (this.filters.length > 0) {
        this.edit_icons = true;
      } else {
        this.edit_icons = false;
      }
      if (this.metrics.length > 0) {
        this.Summarize_edit_icons = true;
      } else {
        this.Summarize_edit_icons = false;
      }

      this.$emit("sort_edited_save");
      this.icon_changer('reset')
    },
    function_group() {
      this.$emit("function_group");
      this.icon_changer('group');
    },
    function_filter() {
      this.$emit("function_filter");
      this.icon_changer('filter')
    },
    edit_filter() {
      this.filter_update = true;
      this.edit_icons = false;
      this.Summarize_edit_icons = false;
      this.sort_editable_icons = false;
      this.icon_changer('filter_edit')
      this.$emit("edit_filter");
    },
    filter_edited_save() {
      if (this.filters.length > 0) {
        this.sort_editable_icons = true;
      } else {
        this.sort_editable_icons = false;
      }
      if (this.filters.length > 0) {
        this.edit_icons = true;
      } else {
        this.edit_icons = false;
      }
      if (this.metrics.length > 0) {
        this.Summarize_edit_icons = true;
      } else {
        this.Summarize_edit_icons = false;
      }
        this.icon_changer('reset')
      this.$emit("filter_edited_save");
    },
    function_summarize() {
      this.$emit("function_summarize");
      this.icon_changer("summarize");
    },
    icon_changer(data) {
      for (const property in this.config) {
        if(data.includes('edit')==true){
          this.config[property] = false;
        }
        else if (property == data || data=="reset") {
          this.config[property] = true;
        } else {
          this.config[property] = false;
        }
      }
      this.$emit("filter_button_validator",this.config.filter)
    },
    edit_summarize() {
      this.edit_icons = false;
      this.Summarize_edit_icons = false;
      this.sort_editable_icons = false;
      this.icon_changer('filter_edit')
      this.$emit("edit_summarize");
    },
    summarize_edited_save() {
      if (this.sorted.length > 0) {
        this.sort_editable_icons = true;
      } else {
        this.sort_editable_icons = false;
      }
      if (this.filters.length > 0) {
        this.edit_icons = true;
      } else {
        this.edit_icons = false;
      }
      if (this.metrics.length > 0) {
        this.Summarize_edit_icons = true;
      } else {
        this.Summarize_edit_icons = false;
      }
             this.icon_changer('reset')
      this.$emit("summarize_edited_save");
    },
  },
};
</script>
<style>
.Sub_containers {
  height:49px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}
.layertwoflexbox{
  max-width: 280px;
}
</style>