import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
var getters = {
    GetleadStatus: state => state.leadStatus
};
const mutations = {
    [mutation_type.leadStatus]: (state, response) => {
        state.leadStatus = response;
    },
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    GetleadStatus: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = payload.skip;
                let take = payload.take;
                let filter = ""
                if(payload.status){
                    filter +=`&status=${payload.status}`
                }
                if(payload.name){
                    filter +=`&name=${payload.name}`
                }
                
                api.get(`/fetch/lead?skip=${skip}&take=${take}`+filter).then(response => {
                    const disposition = response.data.data;
                    commit(mutation_type.Disposition, disposition);
                    if(disposition){
                        const lead_array = [];
                        disposition.forEach(lead => {
                            lead_array.push(lead.name);
                        });
                        localStorage.setItem("group_disposition", JSON.stringify(lead_array));
                    }
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
   
    InsertleadStatus: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/create/lead`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteLeadStatus: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/lead/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateLeadStatus: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/lead/update/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};