<template>
  <div class="titlecls_timeline_div">
    <div class=" titlecls_timeline_history" style="font-size: medium;color:#ffffff;text-align:center;">
      <div v-if="call" class="titlecls_timeline_history_lft" @click="activity" style="{call}">ACTIVITY</div>
      <div v-else class="titlecls_timeline_history_lft_" @click="activity" style="{call}">ACTIVITY</div>
      <div v-if="history_" class="titlecls_timeline_history_rht" @click="edithistory">HISTORY</div>
      <div v-else class="titlecls_timeline_history_rht_" @click="edithistory">HISTORY</div>
    </div>
    <div v-if="call" class="titlecls_timeline_history_bottom">
      <v-timeline v-if="callapi.length > 0" style="padding:10px 10px 10px 0px" dense clipped align-top>
        <v-timeline-item v-for="(data, i) in callapi" :key="i" small dark right="false">

          <div>
            <div v-if="data.channel === `whatsapp` || data.channel === `note`" :class="` headet_txt `">
              {{ data.channel.toUpperCase() }}

              <v-btn  v-if="data.channel === `whatsapp`" small fab flat color="info" @click="wachat(data)">
                <i class="fa fa-whatsapp fa-2x"></i>
              </v-btn>
            </div>
            <div v-else :class="` headet_txt `">

              {{ data.call_type.toUpperCase() }}
              <!-- <div class="row"> -->


              <!-- </div> -->
            </div>
            <div style="font-size:10px">{{ timeSince(data.created_at ) }}
            </div>
            <div class="content_cls">
              <div v-if="data.channel === `whatsapp`" :class="` font-weight-light `">
                {{ data.call_type.toUpperCase() }}
              </div>
              <div v-else-if="data.channel === `note`" :class="` font-weight-light `">
                {{ data.description.toUpperCase() }}
              </div>

              <div style=" word-wrap: break-word;min-height:10px;font-size:10px">
                {{ data.agent }}
              </div>
              <div style=" word-wrap: break-word;min-height:10px;font-size:10px">
                {{ data.status }}
              </div>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <div v-else style="padding:200px 10px 10px 70px" dense clipped align-top>
        No Activity
      </div>
    </div>
    <div v-else class="titlecls_timeline_history_bottom">
      <v-timeline v-if="edithistoryapi.length > 0" style="padding:10px 10px 10px 0px" dense clipped align-top>
        <v-timeline-item v-for="(data, i) in edithistoryapi" :key="i" small dark right="false">

          <div>

            <div :class="` headet_txt `">
              {{ data.header }}
            </div>
            <div style="font-size:10px">{{ timeSince(data.modified_at ) }}
            </div>
            <div class="content_cls">
              <div v-if="data.channel === `whatsapp`" :class="` font-weight-light `">
                {{ data.call_type.toUpperCase() }}
              </div>
              <div v-else-if="data.channel === `note`" :class="` font-weight-light `">
                {{ data.description }}
              </div>

              <div style=" word-wrap: break-word;min-height:10px;font-size:10px">
                {{ data.agent }}
              </div>
              <div style=" word-wrap: break-word;min-height:10px;font-size:10px">
                {{ data.status }}
              </div>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <div v-else style="padding:200px 10px 10px 70px" dense clipped align-top>
        No History
      </div>
    </div>
    <v-bottom-sheet v-model="page.playingRecord" hide-overlay persistent lazy inset max-width="500" class="align-left"
      id="barging-div" transparent>
      <v-card tile class="player-div">
        <v-toolbar color="light" small>
          <audio controls autoplay v-if="page.playingRecord">
            <source :src="recordingplay" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <v-spacer></v-spacer>
          <div class="row justify-fe">
            <a :href="recordingplay" download>
              <v-btn icon>
                <i class="fas fa-download"></i>
              </v-btn>
            </a>
            <v-btn icon>
              <v-icon @click="togglePlayingRecordSheet('close', {})">close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </v-bottom-sheet>
    <v-dialog persistent :value="isProcessing" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Whatsapp Conversation</span>
          </v-card-title>
          
        </v-toolbar>
        <v-container grid-list-md>
         
            <div v-for="(data, i) in whatsappchat" :key="i" class="chatdiv">
              <div v-if="msg_direction===`in`" class="chat-response">
                {{ data.msg_text }}
              </div>
              <div class="chat-request">
                {{ data.msg_text }}
              </div>
              
            </div>

         
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeWhatsapp()">Close</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>
// import moment from "moment";
export default {
  data: () => ({
    callapi: [],
    edithistoryapi: [],
    call: false,
    history_: false,
    recordingplay: "",
    isProcessing: false,
    page: {
      playingRecord: false,
    },
    whatsappchat: []
  }),
  props: {
    module: String,
    id: String,
    template_name: String,
    ticket_info:Object
  },
  mounted() {



    this.activity()


  },
  watch: {
    id() {
      this.activity()
    }
  },
  methods: {
    closeWhatsapp() {
      this.whatsappchat = []
      this.isProcessing = false

    },
    wachat(data) {
      let payload = {
        msg_from: data.phone_number,
        session_id: data.channel_id
      }
      this.$store.dispatch("getwahistory", payload).then((res) => {
        this.whatsappchat = res
      });
      this.isProcessing = true
    },
    togglePlayingRecordSheet: function (event, item) {
      if (event == "close") {
        this.page.playingRecord = false;
      } else {

        this.$store.dispatch("recurl", item.id).then((res) => {

          this.recordingplay = res[0].rec_url;
          this.page.playingRecord = true;

        });

      }
    },
    timeSince(date_) {
      let date = new Date(date_)
      var seconds = Math.floor((new Date() - date) / 1000)

      var interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' year ago'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' month ago '
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' day ago'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' hour ago'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' minute ago'
      }
      return Math.floor(seconds) + ' second ago'
    },
    activity() {
      this.call = true
      this.history_ = false
      if (this.id !== undefined) {
        let payload = {
          id: this.id,
          skip: 0,
          take: 0,
          module: this.module,
          phone_number:this.ticket_info.phone_number

        }
        this.edithistoryapi = []
        this.$store.dispatch("getcallhistory", payload).then((res) => {

          this.callapi = res

        });
      }

    },
    edithistory() {
      this.call = false
      this.history_ = true
      let payload = {
        id: this.id,
        skip: 0,
        take: 0,
        module: this.module,
        templateName: this.template_name
      }
      this.callapi = []
      this.$store.dispatch("getedithistory", payload).then((res) => {
        this.edithistoryapi = res

      });

    }
  }
}
</script>
<style>
.headet_txt {
  font-size: 15px;
  font-weight: 800;
  /* margin-top: 10px */
}

.content_cls {
  padding: 20px 20px 20px 0px;
}

.titlecls_timeline_div {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.titlecls_timeline_history {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  font-weight: 600;
  cursor: pointer;
}

.titlecls_timeline_history_lft {
  width: 50%;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  border-right: 1px solid #fff;
}

.titlecls_timeline_history_lft_ {
  width: 50%;
  color: rgb(33, 150, 243);
  padding: 10px;
  border-right: 1px solid #fff;
  background-color: #fff;
}

.titlecls_timeline_history_rht {
  width: 50%;
  padding: 10px;
  background-color: rgb(33, 150, 243);
}

.titlecls_timeline_history_rht_ {
  width: 50%;
  padding: 10px;
  color: rgb(33, 150, 243) !important;
  background-color: #fff;
}

.titlecls_timeline_history_bottom {
  height: calc(100% - 50px);
  overflow: auto;

}

.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 35px) !important;
}

.font-weight-light {
  overflow: hidden;
  text-overflow: ellipsis
}

.chat-response {
  background-color: rgb(11, 153, 241);
  padding: 10px;
  padding: 10px;
  max-width: 300px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: rgba(213, 215, 222, 0.27) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
  word-wrap: break-word;
  margin: 7px;
  font-size: 12px;

}

.chat-request {
  background-color: rgb(11, 153, 241);
  padding: 10px;
  padding: 10px;
  max-width: 300px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
  word-wrap: break-word;
  margin: 7px;
  color: #fff;
  font-size: 12px;

}
.chatdiv{
  display: flex;
  flex-direction: column;
}
</style>