<template>
  <div style="display: flex; width: 500px">
    <v-flex v-if="summarize_group">
      <v-select
        :items="xaxis_columns"
        label="Select Column To Group"
        item-text="name"
        item-value="value"
        return-string
        v-model="group"
        :error-messages="GroupErrors"
        @change="groupDataPass(group)"
        :disabled="data_table == ''"
        persistent-hint
        multiple
        :rules="[(v) => !!v || 'Group is required']"
      ></v-select>
    </v-flex>
    <div style="display: flex">
      <i
        v-if="summarize_group"
        @click="group_save"
        style="
          color: rgb(33, 150, 243);
          padding: 5px;
          padding-top: 5px;
          font-size: 25px;
        "
        class="fa fa-check green--text"
        aria-hidden="true"
      ></i>
      <i
        v-if="summarize_group"
        @click="group_cancel"
        style="
          color: rgb(33, 150, 243);
          padding: 5px;
          padding-top: 5px;
          font-size: 25px;
        "
        class="fa fa-times-circle red--text"
        aria-hidden="true"
      ></i>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
export default {
  mixins: [validationMixin],
  validations: {
    group: { required },
  },
  props: {
    data_table: {
      type: String,
      default: "",
    },
    summarize_group: {
      type: Boolean,
      default: false,
    },
    xaxis_columns: {
      type: Array,
    },
  },
  data() {
    return {
      group: "",
    };
  },
  computed: {
    GroupErrors() {
      const errors = [];
      if (!this.$v.group.$dirty) return errors;
      !this.$v.group.required && errors.push("Group is required");
      return errors;
    },
  },
  methods: {
    groupDataPass(val) {
      this.$emit("groupDataPass", val);
    },
    group_cancel() {
      this.$emit("group_cancel");
      this.$emit("reseticon");
    },
    group_save() {
      if (this.$v.group.$invalid == true) {
        this.$v.group.$touch();
      } else {
        this.$emit("group_save");
        this.group = "";
        this.$emit("reseticon");
      }
    },
  },
};
</script>