<template>
    <div>
        <section-definition :title="page.title" :icon="page.icon" :extraOptions="page.options" :canadd="page.canadd"
            @addNew="AddNewRule" @refreshPage="refreshThisPage" @openFilter="FilterOpen" @backpage="Backpage">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else-if="page.Notifiy_view" fluid grid-list-xl page>
            <!-- <v-data-table :headers="headers" 
            :items="pausecodeData" class="elevation-1 fixed-layout"
              :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]"
              > -->
            <v-data-table class="elevation-1 fixed-layout" :headers="headers" :items="Tabledata"
                :pagination.sync="pagination" :total-items="pagecount" :loading="page.tableLoading" :rows-per-page-items="[10]">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                  </template>
                <template v-slot:items="props">
                    <td class="checkEmpty p0">{{ props.item.name }}</td>
                    <td class="checkEmpty p0">{{ props.item.rule_module }}</td>
                    <td class="checkEmpty p0">{{ props.item.notify_type }}</td>
                    <td class="checkEmpty p0">
                        <template v-if="props.item.status === 0">Active</template>
                        <template v-else>Inactive</template>
                    </td>
                    <td class="text-xs-center checkEmpty p0">
                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete"
                            @click="deleteItem(props.item.id)">delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-card style="margin: 20px" v-else>
            <v-container fluid="true">
                <NotificationDetail :notify="Addpayload" @backpage="Backpage" @CreateNotifiy="CreateData"
                    @UpdateNotifiy="UpdateNotifiy" :Flag="flag">
                </NotificationDetail>
            </v-container>
        </v-card>

        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Notification</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you Sure to delete this Notification?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import NotificationDetail from "@/views/Configurations/notification/NotificationDetail.vue";
export default {
    components: {
        loader,
        NotificationDetail
    },
    data() {
        return {
            page: {
                title: "Notification",
                icon: "fa fa-comments fa-2x",
                loading: false,
                tableLoading:false,
                canedit: false,
                candelete: false,
                canadd: false,
                options: { filter: false, refresh: true, back: false },
                Notifiy_view: false
            },
            headers: [{
                text: "Name",
                align: "left",
                class: "p0",
                sortable: false,
                value: "name",
            },
            {
                text: "Rule Module",
                align: "left",
                class: "p0",
                sortable: false,
                value: "description",

            },
            {
                text: "Notify Type",
                align: "left",
                class: "p0",
                sortable: false,
                value: "description",

            },
            {
                text: "Status",
                align: "left",
                class: "p0",
                sortable: false,
                value: "status",
            },
            {
                text: "Action",
                align: "left",
                sortable: false,
                value: "btn",
                width: "20%",
            }],
            isAddPop: false,
            pagelimt: {
                skip: 0,
                take: 0
            },
            pagecount: 0,
            pagination: {
                page: 1,
                rowsPerPage: 10,
            },
            Tabledata: [],
            Addpayload: {
                name: "",
                rule_module: "",
                template: "",
                notify_type: "",
                notify_to: "",
                notify_to_value: "",
                message:"",
                status: false,
                params: [],
                api:{
                    method:"",
                    url:"",
                    payload:[],
                    auth_type:"",
                    auth_params:[]
                },
                wa_template:{}
            },
            flag: 0,
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            DeleteRowdata: false,
            DeleteId: "",
            isProcessing:false
        };
    },
    mounted() {
        var scope = this;
        scope.page.loading = true
        scope.page.Notifiy_view = true
        scope.Getdatafromapi()
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:notification:create"
                    ) !== -1
                ) {
                    scope.page.canadd = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:notification:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:notification:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
    },
    methods: {
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        Getdatafromapi() {
            var scope = this;
            var { page, rowsPerPage } = scope.pagination;
            if (rowsPerPage == -1) {
                scope.pagelimt.take = scope.pagecount;
            } else {
                scope.pagelimt.take = rowsPerPage;
            }
            if (page != "" && page != undefined && page != null) {
                scope.skip = page;
            } else {
                scope.skip = 1;
            }
            scope.pagelimt.skip = (scope.skip - 1) * scope.pagelimt.take;
            scope.$store
                .dispatch("GetNotification", scope.pagelimt).then((response) => {
                    scope.isProcessing = false
                    scope.page.loading = false;
                    scope.page.tableLoading = false
                    if (response.status == 204) {
                        scope.Tabledata = [];
                    } else {
                        scope.pagecount = response.data.count
                        scope.Tabledata = response.data.data;
                    }

                }).catch(function (error) {
                    scope.showSnackBar(error);
                });
        },
        AddNewRule() {
            let scope = this
            scope.page.icon = "fa-plus fa-2x"
            scope.page.options.back = true
            scope.page.options.filter = false
            scope.page.options.refresh = false
            scope.page.canadd = false
            scope.page.Notifiy_view = false
            scope.page.title = "Create Notification Rules"
        },
        refreshThisPage() {
            let scope = this
            scope.Backpage()
        }, FilterOpen() {
            console.log("filter")
        },
        Backpage() {
            let scope = this
            scope.page.icon = "fa fa-comments fa-2x"
            scope.page.options.back = false
            scope.page.options.filter = false
            scope.page.options.refresh = true
            scope.page.canadd = true
            scope.page.Notifiy_view = true
            scope.page.title = "Notification"
            scope.Addpayload = {
                name: "",
                rule_module: "",
                template: "",
                notify_type: "",
                notify_to: "",
                notify_to_value: "",
                message:"",
                status: false,
                params: [],
                api:{
                    method:"",
                    url:"",
                    payload:[],
                    auth_type:"",
                    auth_params:[]
                },
                wa_template:{}
            }
            scope.flag = 0
            scope.Getdatafromapi()
        },
        CreateData() {
            let scope = this
            scope.isProcessing = true
            scope.Addpayload.status = scope.Addpayload.status == true ? 0 : 1
            scope.Addpayload.api.auth_params = scope.Addpayload.api.auth_params.filter((filter)=>{
                return filter.name !=="" || filter.value !=""
            })
            scope.$store
                .dispatch("CreateNotification", scope.Addpayload).then(() => {
                    scope.Backpage()
                    scope.Getdatafromapi()
                    scope.isProcessing = false
                    scope.showSnackBar("Create Successfully");
                }).catch(function (error) {
                    scope.isProcessing = false
                    scope.showSnackBar(error);
                });
        },
        editItem(data) {
            let scope = this
            data.status = data.status == 0 ? true : false
            scope.Addpayload = data
            scope.page.icon = "fa-edit fa-2x"
            scope.page.options.back = true
            scope.page.options.filter = false
            scope.page.options.refresh = false
            scope.page.canadd = false
            scope.page.Notifiy_view = false
            scope.page.title = "Edit Notification Rule"
            scope.flag = 1
        },
        UpdateNotifiy() {
            let scope = this
            scope.isProcessing = true
            scope.Addpayload.status = scope.Addpayload.status == true ? 0 : 1
            scope.Addpayload.api.auth_params = scope.Addpayload.api.auth_params.filter((filter)=>{
                return filter.name !=="" || filter.value !=""
            })
            scope.$store
                .dispatch("UpdateNotification", scope.Addpayload).then((response) => {
                    if (response.status == 200) {
                        scope.Backpage()
                        scope.Getdatafromapi()
                        scope.isProcessing = false
                        scope.showSnackBar("Updated Successfully");
                    }

                }).catch(function (error) {
                    scope.isProcessing = false
                    scope.showSnackBar(error);
                });
        },
        deleteItem(data) {
            let scope = this
            scope.DeleteId = data
            scope.DeleteRowdata = true
        },
        closedelete() {
            let scope = this
            scope.DeleteId = ""
            scope.DeleteRowdata = false
        },
        savedelete() {
            let scope = this
            scope.isProcessing = true
            scope.$store
                .dispatch("DeleteNotification", scope.DeleteId).then((response) => {
                    if (response.status == 200) {
                        scope.isProcessing = false
                        scope.Backpage()
                        scope.Getdatafromapi()
                        scope.DeleteRowdata = false
                        scope.showSnackBar("Delete Successfully");
                    }

                }).catch(function (error) {
                    scope.isProcessing = false
                    scope.showSnackBar(error);
                });
        }

    },
    watch: {
        pagination: {
            handler() {
                let scope = this
                if (scope.pagination.page > 1 || scope.pagelimt.skip >= 10) {
                    scope.Getdatafromapi()
                }
            },
            deep: true,
        },
    }
}
</script>