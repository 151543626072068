import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Script;
// var getters = {
//     GetPauseCode: state => state.Script.getdata
// };
const mutations = {
    [mutation_type.Script]: (state, response) => {
        state.Script.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetScript: ({ commit },query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = query.skip;
                let take = query.take;
                let filter="";
                if(query.script_name){
                    filter =`&script_name=${query.script_name}`
                }
                api.get(`/aster/script?skip=${skip}&take=${take}`+filter).then(response => {
                    const script = response.data;
                    commit(mutation_type.Script, script);
                    const scriptdata = [];
                    script.data.forEach(item => {
                        scriptdata.push(item.script_name);
                    });
                    localStorage.setItem("script_name", JSON.stringify(scriptdata));
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterScript: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/script?script_name=${query}`).then(response => {
                    const script = response.data;
                    commit(mutation_type.Script, script);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertScript: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/script`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
              
                // resolve(response);
                // commit(mutation_type.CommitNothing, {});
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteScript: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                // const response = {
                //     "statusCode": 200,
                //     "message": "success"
                // }
                api
                    .delete(`/aster/script/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateScript: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/aster/script/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    // getters,
    mutations,
    actions
};