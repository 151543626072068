<template>
    <div class="DatePickermain">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" :disabled='currentField.disabled' :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`"  v-model="currentField.value.value" :label="`${currentField.label}`"  v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker  :readonly="currentField.clearable" :disabled='currentField.disabled' v-model="currentField.value.value" @input="menu = false"  :min="min"
            :max="max"></v-date-picker>
        </v-menu>
    
    </div>
    </template>
    

<script>
export default {
    name: "DatePicker",
    props: ["currentField"],
    data() {
        return {
            date: "",
            menu: false,
            required_class:"required",
            others:"others",
             read_only:"readonly",
             min:"",
             max:""
        };
    },
    destroyed() {
        this.currentField.value.value = '';
    },
    methods: {
        currenttime(data) {
            const today = new Date(data);
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1;
            let dd = today.getDate();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            const formattedToday =
                yyyy + "-" + mm + "-" + dd;
            return formattedToday;
        }
    },
    mounted(){
        if(this.currentField.datelimitdata !== null ){
            this.min=this.currenttime(this.currentField.datelimitdata[0])
            this.max=this.currenttime(this.currentField.datelimitdata[1])
        }
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr["id"] = this.currentField.model;
            attr.disabled = this.currentField.disabled;
            return attr;
        },
    },
};
</script>

<style>
.DatePickermain {
    height: 85px;
    padding: 10px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }

</style>
