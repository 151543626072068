<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :didFilter="filter.payload.number"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.extraOptions" @didfilterData="phone_number" 
      @openFilter="openDNCFilter" @refreshPage="refreshDNCPage" @addNew="NewDNC"
   @addDid="OpenDid"   :style="styleObject"></section-definition>

      
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="table.headers" :items="table.data" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">

          <div style="text-align: center">No Data Available</div>

        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.created_at }}</td>
          <td class="checkEmpty p0">{{ props.item.did_number }}</td>
          <td class="checkEmpty p0">{{ props.item.trunk }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td class="checkEmpty p0">{{ props.item.active }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon
            color="blue"
            small
            class="mr-4"
            v-if="page.canedit"
            @click="editItem(props.item)"
            >edit</v-icon
          >
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog presistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete DID info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this did.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>

    </v-navigation-drawer>
    <template>
      <v-dialog
        persistent
        :value="page.isaddDid"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create DID</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="DisportionMain" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="isaddDid.payload.did_number"
                      required
                      label="DID Number"
                      type="text"
                      :rules="[(v) => !!v || 'DID number is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="isaddDid.payload.trunk"
                      required
                      label="Trunk"
                      type="text"
                 
                    ></v-text-field>
                  </v-flex>
                 
                  <!-- <v-flex xs12>
                    <v-select v-model="addDisposition.payload.active" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex> -->
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn
              color="primary"
              :disabled="!valid"
              @click="saveAddPausecode()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        persistent
        :value="page.isEditDid"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Update DID</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editDid.did_number"
                      required
                      label="DID Number"
                      type="text"
                      :rules="[(v) => !!v || 'DID number is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editDid.trunk"
                      required
                      label="trunk"
                      type="text"
                      
                    ></v-text-field>
                  </v-flex>
              
                  <v-flex xs12>
                    <v-select
                      v-model="editDid.status"
                      required
                      label="Status"
                      :items="status"
                    
                    ></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select
                      v-model="editDid.active"
                      required
                      label="Active"
                      :items="active"
                    
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary"  @click="saveEditRules()"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog persistent :value="page.isUploadDNC" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Upload DID</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <p>
            <span style="font-weight: bold">Not sure what to upload? </span><a @click="downloadSample">Click here</a>
            and download the sample
            CSV file. Open the file and enter the phone numbers at the first
            column of each row.In Second Column <b>A-</b>Add New Data,<b>D-</b>Delete Existing Data. Save the file with
            .csv extension and upload the
            file.
          </p>
          <div class="file-dummy" @click="$refs.file.click()"
            :style="{ background: uploadDNC.color + '66', 'border-color': uploadDNC.color, color: uploadDNC.color, }">
            {{ uploadDNC.label }}
          </div>
          <input type="file" id="file" ref="file" accept=".csv" v-on:change="handleFileUpload()" />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseUploadDNC(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
// import moment from "moment";
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    loader,
  },
  computed: {
    ...mapGetters(["DoNot"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.uploadDNC = getDefaultData();

    setTimeout(
      function () {
        if (
      scope.$store.getters.UserPermissions.indexOf(
        'configurations:didnumber:create'
      ) !== -1
    ) {
      this.page.addDid = [{ label: "Add Did" }];
    }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            'configurations:didnumber:upload'
          ) !== -1
        ) {
          scope.page.addnew = [{ key: "upload", label: "Upload" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            'configurations:didnumber:edit'
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            'configurations:didnumber:delete'
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      
      }.bind(scope),
      1
    );
  },
  created() { },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "DID Number",
        icon: "fa-phone-volume fa-2x",
        loading: true,
        isaddDid: false,
        isEditDid: false,
        isUploadDNC: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: false, refresh: true,autoComplet:true ,addDid:true},
        addnew: [],
        temp: { key: "addpausecode", label: " Add PauseCode" },
        candelete: false,
      },
      pagecount: 0,
      table: {

        headers: [
          {
            text: "Date",
            align: "left",
            class: "p0",
            sortable: false,
            value: "entry_date",
          },
          {
            text: "DID Number",
            align: "left",
            class: "p0",
            sortable: false,
            value: "did_number",
          },
         
          {
            text: "Trunk",
            align: "left",
            class: "p0",
            sortable: false,
            value: "trunk",
          },
          {
            text: "Status",
            align: "left",
            class: "p0",
            sortable: false,
            value: "status",
          },
          {
            text: "Live",
            align: "left",
            class: "p0",
            sortable: false,
            value: "live",
          },
          {
            text: "Action",
            align: "center",
            class: "p0",
            sortable: false,
            value: "action",
          },

        ],
        data: [],
      },

      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      tableLoading: false,
      filter: {
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      isaddDid: {
        valid: false,
        payload: {
          // name: "",
          did_number: "",
          trunk:"",
          
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      editDid: {
        
        id:"",
       did_number: "",
        trunk: "",
        // active: "",
  status:"",
  active:""
      },
      status: ["Active","Inactive"],
      active:["Yes","No"],
      uploadDNC: {},
    };
  },
  methods: {
    refreshDNCPage: function () {
      var scope = this;
      scope.page.loading = true;
        scope.filter.payload.number = "";
         scope.page.isFilterOpen = false;
      scope.getDataFromApi();
    },
    phone_number(val){
      let scope=this
        scope.filter.payload.number =val;
          scope.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      this.tableLoading = true;
    
      scope.page.isFilterOpen = false;

      let take = this.pagination.rowsPerPage
      let skip
      if (this.pagination.page === 1) {
        skip = 0
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      }
      let query = {
        skip: skip,
        take: take,
        phone_number: scope.filter.payload.number
      }
      this.skip=skip
      this.$store
        .dispatch("DidNumber", query)
        .then((response) => {

          this.pagecount = response.data.count
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
         
          if (datas !== "" && datas !== undefined && datas !== null) {
          for (let i = 0; i < datas.length; i++) {
            if (datas[i].active == 1) {
              datas[i].active = "Yes";
            } else {
              datas[i].active = "No";
            }
            if (datas[i].status == 1) {
              datas[i].status = "Active";
            } else {
              datas[i].status = "Inactive";
            }

          }
        }
          this.table.data = datas
        

        })
        .catch(function (error) {
          scope.page.isUploadDNC = false;
          scope.showSnackBar("error" + error);

        });
    },
    closeEditRules: function () {
      this.page.isEditDid = false;
    },


    saveEditRules() {
      var scope = this;

      if (scope.editDid.status === "Active") {
        scope.editDid.status = 1;
      }
      if (scope.editDid.status ==="Inactive") {
        scope.editDid.status = 0;
      }
      if (scope.editDid.active === "Yes") {
        scope.editDid.active = 1;
      }
      if (scope.editDid.active ==="No") {
        scope.editDid.active = 0;
      }


      scope.$store
        .dispatch("updateDid", scope.editDid)
        .then(() => {
          scope.page.isaddDid = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditDid = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isaddDid = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDid = false;
        });
      this.getDataFromApi();
    },
    OpenDid:function(){
this.page.isaddDid = true
    },

    openDNCFilter: function () {
      this.page.isFilterOpen = true;
    },
    NewDNC: function (dnc) {
      if (dnc.key === "upload") {
        this.page.isUploadDNC = true;
      } else if (dnc.key === "addDid") {
        this.page.isaddDid = true;
      }
    },
    restvalues() {
      this.filter.payload.number = "";
      this.page.isFilterOpen = false;
      this.getDataFromApi()
    },
    closeAddPausecode: function () {
      this.page.isaddDid = false;
      this.$refs.DisportionMain.reset();
    },


    saveAddPausecode() {
      var scope = this;
      

      scope.$store
        .dispatch("insertDid", scope.isaddDid.payload)
        .then(() => {
      
          scope.showSnackBar("Created Successfully");
          this.$refs.DisportionMain.reset();
          this.closeAddPausecode()
        })
        .catch(function (error) {
          scope.page.isaddDid = false;
          scope.showSnackBar("error" + error);
          scope.addDID.payload.did_number = "";
          scope.addDID.payload.trunk = "";
          scope.addDID.payload.status = "";
          this.getDataFromApi()
       
        });
      this.getDataFromApi();
    },

    CloseUploadDNC: function () {
      this.page.isUploadDNC = false;
      this.getDataFromApi();
      this.uploadDNC = getDefaultData();
    },
    onFileRemove: function (args) {
      args.postRawFile = false;
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    editItem(item) {
      let scope = this;
      this.page.isEditDid = true;
      this.editDid.id = item.id;
      this.editDid.did_number = item.did_number;
      this.editDid.trunk = item.trunk;
      this.editDid.status = item.status;
      this.editDid.active = item.active;

      if (item.status ===" Active") {
        scope.editDid.status = 1;
      }
      if (item.status ==="Inactive") {
        scope.editDid.status = 0;
      }

      // if (item.active === "Yes") {
      //   scope.editDid.active = 1;
      // }
      // if (item.active ==="No") {
      //   scope.editDid.active = 0;
      // }

    },

    deleteItem(item) {
      this.DeleteRowdata = true;
      this.delete_id = item;

    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteDid", this.delete_id.id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "deleted") {
            scope.getDataFromApi();
            scope.showSnackBar("Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    downloadSample: function () {
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += "did_number,status,trunk\n\r1000000000,A-Add\n\r1000000000,D-Delete ";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sample_did.csv");
      document.body.appendChild(link);
      link.click();
    },
    handleFileUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadDNC.color = "#A64452";
      scope.uploadDNC.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/did/Upload", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadDNC.color = "#4BB543";
          scope.uploadDNC.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadDNC.color = "#F51B00";
          scope.uploadDNC.label = "Upload Error";
          scope.$refs.file.clearAll();
          return error;
        });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  }
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>