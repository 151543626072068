<template>
<div class="Buttonmain">
 <el-form-item :label-width="currentField.labelWidth-50 + 'px'">
      <el-button type="primary" class="form__button">{{currentField.buttonText}}</el-button>
    </el-form-item>
</div>
   
</template>

<script>
  export default {
    name: 'Button',
    props: ['currentField']
  }
</script>
