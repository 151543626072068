// import config from "@/config/index";
import Axios from "../../utils/axios-util";

const actions = {
    fetchCategoryData:(_,query) =>{
        return new Promise((resolve, reject) => {
          query.name=""
            try {
                Axios.get(`/category?skip=${query.skip}&take=${query.take}&is_active=${query.is_active}&name=${query.name}`,query)
                    .then((result) => {
                            resolve(result);
                       
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    fetchFilterCategory:(_,payload)=>{

        return new Promise((resolve, reject) => {
            try {
                if(payload.name===''){
                    delete payload.name
                }
                if(payload.is_active===''){
                    delete payload.is_active
                }
                Axios.post(`/meeting/category`,{name:payload.name,is_active:payload.is_active})
                    .then((result) => {
                        if (result.data.statusCode === 201) {
                            const columns = result.data.data;
                            resolve(columns);
                        } else {
                            // const columns = [];
                            // state.widgets = columns;
                            resolve([]);
                        }
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },

    removeCategory:(_, id)=> {
        return new Promise((resolve, reject) => {
              try {
                    Axios.delete(`/aster/category/${id}`)
                        .then((response) => {
                            // commit(types.REMOVE_WIDGET, payload.index);
                            resolve(response.data.data);
                        })
                        .catch((err) => {
                            reject(err.response.data);
                        });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },

    createCategory:(_, payload)=> {
        return new Promise((resolve, reject) => {
            try {
                Axios.post(`/create/category`, payload)

                    .then((response) => {
                        const res = response.data;
                        // commit(mutation_type.CommitNothing);                      
                        // commit(types.ENABLE_ACTIVE, 0);
                        // commit(types.DISABLE_ACTIVE, 0);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },


    
    editSavecategory:(_, payload)=> {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                Axios.put(`/update/category/${id}`, payload)
                    .then((result) => {
                        const response = result.data;
                        // commit(mutation_type.CommitNothing);
                        // commit(types.ENABLE_ACTIVE, 0);
                        // commit(types.DISABLE_ACTIVE, 0);
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
 
};
export default {
    actions,
  };