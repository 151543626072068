export default {
    updateJSON: (state, payload) => {
      state.jsonData = payload;
    },
    SET_WEBSOCKET_STATUS(state, status) {
      state.websocketStatus = status;
    },
    SET_SESSION(state, payload) {
      state.session = payload;
    },
    ON_CALL(state) {
      state.loading = false;
      state.callAction = true; 
    },
    CALL_RINGTONE(state, socket) {
      if (socket) {
        state.ringing = socket;
        state.audio.play();
      } else {
        state.ringing = socket;
        state.audio.pause();
        state.audio.currentTime = 0;
      }
      return {
        ...state,
        isplay: socket,
      };
    },
    INITIATE_EVENT(state, payload) {
      state.stopEvent = payload ? state.stopEvent + 1 : 0;
    },
   
  };
  
  
