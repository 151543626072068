import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.TimeCondition;
var getters = {
    getScheduler: state => state.TimeCondition,
}
const mutations = {
    [mutation_type.TimeCondition]: (state, response) => {
        state.TimeCondition = response;
    },
    [mutation_type.CommitNothing]: () => {

    }
};
const actions = {

    insertScheduler: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/scheduler/create`, data)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing,{});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    getScheduler: ({commit},query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = query.skip;
                let take = query.take;
                let filter=""
                let reports1 = []
                for (let i = 0; i < query.reports.length; i++) {
                   let reports = query.reports[i].replace(/ /g, '');
                    reports1.push(reports)
                }
                if(query.email){
                    filter +=`&email=${query.email}`
                }
                if(query.reports.length>0){
                    filter +=`&reports=${reports1}`
                }
                if(query.status){
                    filter +=`&status=${query.status}`
                }
                api
                    .get(`/scheduler?skip=${skip}&take=${take}`+filter)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing,{});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    filterScheduler: (  {commit}, data )=> {
        return new Promise((resolve, reject) => {
            try {
              
                let reports1 = []
                for (let i = 0; i < data.reports.length; i++) {
                   let reports = data.reports[i].replace(/ /g, '');
                    reports1.push(reports)
                }
                api
                    .get(`/scheduler?email=${data.email}&reports=${reports1}&status=${data.status}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing,{});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateScheduler:  (  {commit}, data)=> {
        return new Promise((resolve, reject) => {
            try {
              const id = data.id
              delete data.id;
                api
                    .put(`/scheduler/update/${id}`,data)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing,{});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteScheduler: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/scheduler/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing,{});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    
};
export default {
    state,
    getters,
    mutations,
    actions
}