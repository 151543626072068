<template>
    <div id="chart">
        <div v-if="loading" class="loader"></div>
        <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series"></VueApexCharts>
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        VueApexCharts,

    },
    props: {

        alldata: {
            type: Array
        },
        toolbarTrigger: {
            type: Boolean,
            default: true
        },

        timer: {
            type: Number,
            default: 10000,
        },
        id: {
            type: Number,
            default: 1,
        },
        name: {
            type: String,
            default: "",
            validator: function (val) {
                return typeof val === "string";
            },
        },
    },
    mounted() {

        if (localStorage.apply_pass) {
            let obj = JSON.parse(localStorage.apply_pass);
            if (obj.filter_type >= 0 && obj.filter_metrics.length>0) {
                this.watch_trigger = obj.filter_type;
            }

        }
    },
    watch: {
        watch_trigger() {

            let obj = JSON.parse(localStorage.apply_pass);
            if (obj.filter_metrics[0].widget_id === this.id) {
                this.filter_fun(obj)
            }
        }
    },
    created() {
        const scope = this;
        this.chartOptions.chart.toolbar.show = this.toolbarTrigger
        if (this.toolbarTrigger) {
            this.chartOptions.title.margin = 5
        }
        const payload = {
            id: scope.id,
            skip: 0,
            take: 20,
        };
        scope.loading = true;
        scope.$store
            .dispatch("rect/renderWidgetData", payload)
            .then((result) => {
                scope.dataPoints = JSON.parse(JSON.stringify(result));
                scope.dataPoints[0].forEach((val) => {
                    this.series.push(val)
                })

                scope.loading = false;
            })
            .catch((err) => {
                console.log("Error(Bar) ==>", err);
                scope.loading = false;
            });
        scope.fillData();
    },
    // updated() {
    //     const scope = this;
    //     if (scope.activePreview.update) {
    //         scope.dataPoints = [];
    //         const payload = {
    //             id: scope.id,
    //             skip: 0,
    //             take: 20,
    //         };
    //         scope.loading = true;
    //         scope.$store
    //             .dispatch("rect/renderWidgetData", payload)
    //             .then((result) => {
    //                 scope.dataPoints = JSON.parse(JSON.stringify(result));
    //                 scope.dataPoints[0].forEach((val) => {
    //                     this.series.push(val)
    //                 })

    //                 scope.loading = false;
    //             })
    //             .catch((err) => {
    //                 console.log("Error(Bar) ==>", err);
    //                 scope.loading = false;
    //             });
    //         scope.$store.dispatch("rect/updatePreview", scope.activePreview);
    //     }
    // },
    data() {
        return {
            dataPoints: null,
            polling: null,
            loading: false,
            watch_trigger: null,
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        export: {
                            csv: {
                                filename: this.name,
                            },
                            svg:{
                                filename: this.name,
                            },png:{
                                filename: this.name,
                            }
                        },
                        tools: {
                            customIcons: []
                        },
                        show: true
                    },
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        formatter: function (val) {

                            return val
                        }
                    },

                },
                title: {
                    text: this.name,
                    margin: 25
                },
                tooltip: {
                    x: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
            },
        }
    },
    methods: {
        triggers() {this.loading = true;
            this.$emit("popup");
        },
        reset_triggers() {
            let obj = {
                filter_metrics: this.alldata[0].filter_metrics,
                filter_type: 2,

            }
            this.filter_fun(obj)
            localStorage.setItem("apply_pass", JSON.stringify({}));
            this.$emit("reset");
        },
        filter_fun(obj) {
            this.$store.dispatch("rect/temp_filter_api", obj)
                .then(() => {
                    this.updatefun();

                })
        },
         preview() {
this.chartOptions.chart.toolbar.tools.customIcons.length = 0;
        },
        updatefun() {
            const scope = this;
            const payload = {
                id: scope.id,
                skip: 0,
                take: 20,
            };
            scope.$store
                .dispatch("rect/renderWidgetData", payload)
                .then((result) => {
                    this.chartOptions.chart.toolbar.show = this.toolbarTrigger
                    if (this.toolbarTrigger) {
                        this.chartOptions.title.margin = 5
                    }
                    this.series = []
                    scope.dataPoints = JSON.parse(JSON.stringify(result));
                    scope.dataPoints[0].forEach((val) => {
                        this.series.push(val)
                    })

                    scope.loading = false;
                })
                .catch((err) => {
                    console.log("Error(Bar) ==>", err);
                });
        },
        fillData() {
            const scope = this;

            scope.polling = setInterval(() => {

                const payload = {
                    id: scope.id,
                    skip: 0,
                    take: 20,
                };
                scope.$store
                    .dispatch("rect/renderWidgetData", payload)
                    .then((result) => {
                        this.chartOptions.chart.toolbar.show = this.toolbarTrigger
                        if (this.toolbarTrigger) {
                            this.chartOptions.title.margin = 5
                        }
                        this.series = []
                        scope.dataPoints = JSON.parse(JSON.stringify(result));
                        scope.dataPoints[0].forEach((val) => {
                            this.series.push(val)
                        })

                        scope.loading = false;
                    })
                    .catch((err) => {
                        console.log("Error(Bar) ==>", err);
                    });
            }, scope.timer);

        },
    },
    beforeDestroy() {

        clearInterval(this.polling);
    },

}
</script>
<style>
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 6;
}
.custom-icon {
    margin-bottom: 2px;
    margin-left: 5px
}
</style>