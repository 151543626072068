import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
import config from "@/config/index";
import _ from 'lodash'
const state = config.CustomForm;
var getters = {
    form: state => state.CustomForm.forms
    // activeField: state => state.CustomForm.activeField,
    // activeTabForFields: state => state.CustomForm.activeTabForFields,
    // themingVars: state => state.CustomForm.themingVars,
    // formType: state => state.CustomForm.formType,
    // PageMethode: state => state.CustomForm.page
}
const mutations = {
    [mutation_type.CommitNothing]: () => {},
    [mutation_type.CustomForm]: (state, response) => {

        try {
            const form = response.fields;
            const conversion = form.map((value) => {
                for (var key in value) {
                    console.log(value.fieldType,value.values,"value[key]")
                    if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType === 'SelectList' || value.fieldType === 'RadioButton' || value.fieldType === 'Checkbox') ) {
                        let options = JSON.parse(value.values);
                        value.values = options;
                    } else if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType !== 'SelectList' && value.fieldType !== 'RadioButton' && value.fieldType !== 'Checkbox') && Array.isArray(value.values)) {
                        value.values = value.values[0];
                    }
                    if (value.hasOwnProperty(key) && (value[key] === 'true' || value[key] === 'false')) {
                        value[key] = (value[key] === 'true');
                    }
                    if (value.hasOwnProperty(key) && key == "id") {
                        delete value[key];
                    }
                    if (value.link_property !== '' && value.link_property !== undefined && value.link_property && value.fieldType === 'TextInput' && key === 'link_property') {
                        value[key] = value.link_property.map;
                    }
                }
                return value;
            });
           
            const data = [{ "fields": conversion }];
            if (response.template_name) {
                data[0].template_name = response.template_name
            }
            if (response.type) {
                data[0].type = response.type
            }
            if (response.active) {
                data[0].active = response.active
            }
            localStorage.setItem("forms", JSON.stringify(data));
            localStorage.setItem("originalForm", JSON.stringify(data));
            
            state.CustomForm.forms = data;
        } catch (err) {
            return err;
        }
    },
};
const actions = {
    GetAsterSupportField: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/ticketsfields/fetch`).then(response => {
                    let fields =  _.cloneDeep(response.data.fields)
                    const obj={}
                    obj.active='y'
                    obj.fields=response.data.fields
                    obj.template_name='ticket'
                    obj.type='ticket'
                   commit(mutation_type.CustomForm, obj);
                   response.data.clonefields = fields
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetAsterSupportData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                if((query.payload == undefined && query.to_date == undefined && query.from_date == undefined
                    || query.payload == undefined && query.to_date == '' && query.from_date == '')){
                    query.payload = {}
                    query.to_date = ""
                    query.from_date = ""
                }  
                let apiurl
                if(query.status == "unassign" && query.download == undefined){
                    apiurl= `/tickets/fetch?skip=${query.skip}&take=${query.take}&assign=true&status=open&All=true`
                }else if(query.status == "unresolve" && query.download == undefined){
                    apiurl= `/tickets/fetch?ticketview=&skip=${query.skip}&take=${query.take}&user=&priority=&status=${query.status}&from=&to=&portal=true`
                }else if(query.download == true){
                     apiurl= `/tickets/fetch?ticketview=&user=&priority=&status=${query.status}${query.status !== "unassign" ? '&portal=true':''}&All=true${query.from_date !== "" && query.to_date !== "" ? "&from=" + query.from_date + "&to=" + query.to_date : ""}`;
                }else{
                    apiurl= `/tickets/fetch?ticketview=&skip=${query.skip}&take=${query.take}&user=&priority=&status=${query.status}&from=${query.from_date}&to=${query.to_date}&portal=true`
                }
                api.post(apiurl,query.payload).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    if (response.status === 204) {
                        resolve({ data: [{others:[]}],count: 0});
                    } else {
                        console.log("apiurlapiurlapiurlapiurl",query,response)
                        resolve(response.data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    AssignTicket:({commit},payload)=>{
        return new Promise((resolve, reject) => {
            try {
                if(payload.hasOwnProperty("assign")){
                    payload.fields=[{name:"user",value:payload.assign },
                    {name:"modified_by",value: payload.user}]
                    delete payload.assign
                    delete payload.user
                }
              
                api.put(`/tickets/update`,payload).then(response => {
                   console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    updateAsterTicket:({commit},payload)=>{
        return new Promise((resolve, reject) => {
            try {
                if(payload.hasOwnProperty("assign")){
                    payload.fields=[{name:"user",value:payload.assign}]
                    delete payload.assign
                }
              
                api.put(`/tickets/update`,payload).then(response => {
                   console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    CreateAsterTicket:({commit},payload)=>{
        return new Promise((resolve, reject) => {
            try {              
                api.post(`/tickets/create`,payload).then(response => {
                   console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    TicketHist:({commit},payload)=>{
        return new Promise((resolve, reject) => {
            try {              
                api.get(`/ticketshistory/fetch?skip=0&take=10&ticket_id=${payload.ticket_id}`,payload).then(response => {
                    // api.get(`/ticketshistory/fetch?skip=0&take=10&ticket_id=96`,payload).then(response => {
                   console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    TicketTags:({commit})=>{
        return new Promise((resolve, reject) => {
            try {              
                api.get(`/tags/fetch?status=active`).then(response => {
                    // api.get(`/ticketshistory/fetch?skip=0&take=10&ticket_id=96`,payload).then(response => {
                //    console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    TicketPhoneSearch:({commit},query)=>{
        return new Promise((resolve, reject) => {
            try {              
                api.get(`/contact/fetchsearch?number=${query.phone_number}`).then(response => {
                   console.log(response)
                   resolve(response.data)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    TicketSearch:({commit},query)=>{
        return new Promise((resolve, reject) => {
            try {              
                api.post(`/tickets/fetch?skip=0&take=10&search=${query.search}&merged_as=parent&selectbox=${query.current}&portal=true`).then(response => {
                   console.log(response)
                   resolve(response)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    FilterAsterSupport: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/tickets/fetch?ticket_id=${query.ticket_id}&portal=true`,{}).then(response => {
                    if (response.data.statusCode === 204) {
                        resolve({ data: [], totalCount: 0 ,downloadData:[]});
                    } else {
                        resolve(response.data.data[0].others[0]);
                    }
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    MergeTicket: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                console.log(payload)
                api.post(`/tickets/merge`,payload).then(response => {
                    if (response.data === 204) {
                        resolve(response);
                    } else {
                        resolve(response);
                    }
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    UnMergeTicket: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                console.log(payload)
                api.post(`/tickets/unmerge`,payload).then(response => {
                    if (response.data === 204) {
                        resolve(response);
                    } else {
                        resolve(response);
                    }
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    DeleteTicket: ({commit},query)=>{
        return new Promise((resolve, reject) => {
            try {
                api.delete(`/tickets/delete/${query.ticket_id}/${query.user}`).then(response => {
                console.log("responsedeleted",response)    
                resolve(response)
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    










  
    GetContactDetails:({commit})=>{
        return new Promise((resolve, reject) => {
            try {
                api.get(`/contact/fetch`).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    UpdateTableHeader:({commit},payload)=>{
        return new Promise((resolve, reject) => {
            try {
                api.put(`/tickets/tablefield`,payload).then(response => {
                   resolve(response)
                });
            } catch (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
            }
        })
    },
    // updateAsterTicket: ({ commit }, payload) => {
    //     return new Promise((resolve, reject) => {
            
    //         try {
    //             let id = payload.id;
    //             let name = payload.template_name;
    //             delete payload.id;
    //             delete payload.template_name;
    //             api
    //                 .put(`/aster/form/${name}/${id}`, payload)
    //                 .then(response => {
    //                     commit(mutation_type.CommitNothing, {});
    //                     resolve(response);
    //                 })
    //                 .catch(function(error) {
    //                     reject(error);
    //                 });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // }
    // GetAsterLead: ({ commit }) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/aster/form/template`).then(response => {
    //                 const Lead = response.data;
    //                 commit(mutation_type.AsterLead, Lead);
    //                 resolve(response.data);
    //             });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    // FilterAsterLeadData: ({ commit }, query) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/aster/form/template?template_name=${query}`).then(response => {
    //                 commit(mutation_type.CommitNothing, {});
    //                 resolve(response.data);
    //             });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    // insertAsterData: ({ commit }, payload) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api
    //                 .post(`/aster/form`, payload)
    //                 .then(response => {
    //                     commit(mutation_type.CommitNothing, {});
    //                     resolve(response);
    //                 })

    //             .catch(function(error) {
    //                 reject(error);
    //             });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    // FilterAsterLead: ({ commit }, query) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/aster/form?template_name=${query.template_name}&name=${query.name}&value=${query.value}`).then(response => {
    //                 resolve(response.data);
    //             });
    //         } catch (error) {
    //             commit(mutation_type.CommitNothing, {});
    //             reject(error);
    //         }
    //     });
    // },
    // FilterAsterLeads: ({ commit }, query) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/aster/form?template_name=${query.template_name}&list_id=${query.list_id}&phone_number=${query.phone_number}`).then(response => {
    //                 resolve(response.data);
    //             });
    //         } catch (error) {
    //             commit(mutation_type.CommitNothing, {});
    //             reject(error);
    //         }
    //     });
    // },
    
    // deleteAsterLead: ({ commit }, payload) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api
    //                 .delete(`/aster/form/${payload.template_name}/${payload.delete_id}`)
    //                 .then(response => {
    //                     commit(mutation_type.CommitNothing, {});
    //                     resolve(response.data);
    //                 })
    //                 .catch(function(error) {
    //                     reject(error);
    //                 });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    // Viewdata: ({ commit }, query) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             commit(mutation_type.AsterLead, query);
    //             localStorage.setItem("templetname", JSON.stringify({templetname:query}))
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    // // Viewdata: ({ commit }) => {
    // //     return new Promise((resolve, reject) => {
    // //         try {
    // //             api.get(`/aster/form/template`).then(response => {
    // //                 const Lead = response.data;
    // //                 commit(mutation_type.AsterLead, Lead);
    // //                 resolve(response.data);
    // //             });
    // //         } catch (error) {
    // //             reject(error);
    // //         }
    // //     });
    // // },
    // AssignAgent: ({ commit }, payload) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api
    //                 .put(`/aster/lead/assign`, payload)
    //                 .then(response => {
    //                     commit(mutation_type.CommitNothing, {});
    //                     resolve(response.data);
    //                 })
    //                 .catch(function(error) {
    //                     reject(error);
    //                 });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // }
};
export default {
    state,
    getters,
    mutations,
    actions
};
