import Vue from "vue";
import Router from "vue-router";
import { routes } from "./routes";
import { store } from "../store";

Vue.use(Router);

var customRouter = new Router({
  routes,
  base: "admin-portal",
  mode: "history",
  scrollBehviour(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});
customRouter.beforeEach(function(to, from, next){
  if(top.location != self.location){
    top.location = '/error-page'; 
  }
  if (to.meta.auth) {
    let filtered = store.getters.UserPermissions.filter( permission => {
      return (to.meta.permission.access.indexOf(permission)!==-1);
    });
    if(filtered.length>0){
      next();
    } else {
      if (store.state.auth0.auth0.isLoggedIn ){
        next('/blank-page');
      } else {
        localStorage.clear();
        next('/auth/login');
      }
    }
  } else {
    next();
  }
})

export default customRouter;
