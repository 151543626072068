import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Subdispostion;
var getters = {
    GetSubDisposition: state => state.SubDisposition
};
const mutations = {
    [mutation_type.SubDisposition]: (state, response) => {
        state.SubDisposition = response;
    },
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    insertInbound: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/create/inbound`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    Getcampaigns: ({ commit }) => {
        return new Promise((resolve, reject) => {
          try {
            let take = {
              take: ''
            }
            api.get(`/aster/campaigns?take=${take.take}`).then(response => {
              const pausecode = response.data;
              commit(mutation_type.PauseCode, pausecode);
              const pausecode_array = [];
              pausecode.data.forEach(item => {
                pausecode_array.push(item.pause_code);
              });
              // localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
              resolve(response.data);
            });
          } catch (error) {
            reject(error);
          }
        });
      },


      GetQueue: ({ commit }) => {
        return new Promise((resolve, reject) => {
          try {
            let take = {
              take: ''
            }
            api.get(`/queue?limit=${take.take}`).then(response => {
              const pausecode = response.data;
              commit(mutation_type.PauseCode, pausecode);
              const pausecode_array = [];
              pausecode.data.forEach(item => {
                pausecode_array.push(item.pause_code);
              });
              // localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
              resolve(response.data);
            });
          } catch (error) {
            reject(error);
          }
        });
      },

    InboundRoute: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                let skip ;
                let take;

                let filter=""
                if(payload.status){
                    filter +=`&status=${payload.status}`
                }
                if(payload.campaign){
                    filter +=`&campaign=${payload.campaign}`
                }
                if(payload.destination_name){
                    filter +=`&destination_name=${payload.destination_name}`
                }
                if(payload != undefined){

                    skip = payload.skip;
                    take = payload.take;
                }else{
                    skip = "";
                    take = "";
                }
             
                api.get(`/inboundroute?skip=${skip}&take=${take}`+filter).then((response) => {
                    var donotcall_response = response;
                    commit(mutation_type.didNumber, donotcall_response);
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
   
    deleteInbound: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/inbound/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateInbound: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/inboundroute/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};