<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="queuestatus"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <td class="checkEmpty p0">{{ props.item.queue }}</td>
        <td class="checkEmpty p0">{{ props.item.agents_total }}</td>
        <td class="checkEmpty p0">{{ props.item.pause }}</td>
        <td class="checkEmpty p0">{{ props.item.live }}</td>
        <td class="checkEmpty p0">{{ props.item.idle }}</td>
        <td class="checkEmpty p0">{{ props.item.call_total }}</td>
        <td class="checkEmpty p0">{{ props.item.answer_count}}</td>
        <td class="checkEmpty p0">{{ props.item.abandon_count}}</td>
        <td class="checkEmpty p0">{{ props.item.ringing_count}}</td>
        <td class="checkEmpty p0">{{ props.item.waiting_count}}</td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default{
		data(){
			return{
				headers: [
					{ text: "Queue Name", align: "left", sortable: false,class: "p0", value: "queue_name" },
					{ text: "Total Agents", align: "left", sortable: false,class: "p0", value: "total" },
					{ text: "Pause", align: "left", sortable: false,class: "p0", value: "pause" },
					{ text: "Oncall", align: "left", sortable: false,class: "p0", value: "oncall" },
					{ text: "Available", align: "left", sortable: false,class: "p0", value: "available" },
					{ text: "Total Calls", align: "left", sortable: false,class: "p0", value: "calls" },
					{ text: "Success", align: "left", sortable: false,class: "p0", value: "success" },
					{ text: "Failure", align: "left", sortable: false,class: "p0", value: "failure" },
					{ text: "Ringing", align: "left", sortable: false,class: "p0", value: "Ringing" },
					{ text: "Waiting", align: "left", sortable: false,class: "p0", value: "waiting" },
				],
			}
		},
		computed: {
        ...mapGetters([
            "queuestatus"
        ]),

        queueChunk() {

            return this.queuestatus.chunk(3, this.windowWidth, 275);
        }
    },
	}
</script>
<style scoped>
	table.v-table tbody td, table.v-table thead th {
    height: 25px;
	}
</style>