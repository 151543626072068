<template>
  <v-toolbar
    :fixed="fixedToolbar"
    v-if="toolbar"
    :class="navToolbarScheme"
    :clipped-left="toolbarClippedLeft"
    app
  >
    <v-toolbar-side-icon
      class="hidden-lg-and-up"
      @click.stop="$store.dispatch('toggleDrawer', !navDrawer)"
    ></v-toolbar-side-icon>
    <v-toolbar-title
      v-text="title"
      class="ml-0 hidden-lg-and-up"
    ></v-toolbar-title>
    <v-btn
      icon
      light
      class="hidden-md-and-down"
      @click.stop="toggleMiniVariantMode"
    >
   
      <!-- <v-tooltip bottom v-if="navMiniVarient" color="sidebar"> -->
        <div  v-if="navMiniVarient" color="sidebar">
        <v-icon title="Expand" slot="activator" >fas fa-arrow-circle-right</v-icon>
        <!-- <span>Expand</span> -->
      </div>
        <!-- </v-tooltip> -->
      <!-- <v-tooltip bottom v-else color="sidebar"> -->
        <div v-else color="sidebar">
        <v-icon title="Collapse" slot="activator">fas fa-arrow-circle-left</v-icon>
        <!-- <span>Collapse</span> -->
        </div>
    </v-btn>
   
    
     
     <v-spacer></v-spacer>
     <div class="header-view">
       <div v-show="showAvailMinutes == true"> Available Minutes: {{availableMins}} mins</div>   <v-spacer></v-spacer>
  <!-- <v-tooltip bottom > -->
      <!-- <template v-slot:activator="{ on, attrs }"> -->
        <v-icon title="Sign Out" color="#2196f1"  v-on="on" @click="auth0Logout">power_settings_new</v-icon>
      <!-- </template> -->
      <!-- <span>Sign Out</span> -->
    <!-- </v-tooltip> -->
    
     </div>
  
    <div class="text-center">
      <div class="row" justify="center">
        <v-flex xs6 md3>
          <v-dialog v-model="checkingtokens" :fullscreen="true">
            <v-card>
              <!-- <v-card-title>
                <img :src="doocti" />
              </v-card-title> -->
              <v-divider></v-divider>
              <v-card-text class="card-text">
                Your session has been expired
                <a class="click-text" @click="redirecttologin">Click Here </a>
                to login again
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-flex>
      </div>
    </div>
  </v-toolbar>
</template>
<script>
import { authUser } from "@/data/dummyData";
import { mapGetters,mapActions ,mapState } from "vuex";
import JsSIP from 'jssip'
export default {
  data() {
    return {
      title:  JSON.parse(localStorage.getItem("projectConfig")).projects[0].config[0].brand_name===null?'Doocti':JSON.parse(localStorage.getItem("projectConfig")).projects[0].config[0].brand_name,
      logo: "/static/doocti.png",
      doocti: JSON.parse(localStorage.getItem("projectConfig")).projects[0].config[0].avatar===null?'/static/doocti.png':JSON.parse(localStorage.getItem("projectConfig")).projects[0].config[0].avatar,
      checkingtokens: false,
      avatarName: "",
      availableMins:0,
      showAvailMinutes:false,
       AppPermission:undefined,
      tenant:undefined,
      config:undefined,
      view: true,
      station:undefined,
      dynamicWebsocketStatus: this.websocketStatus,
      sipOptions:{
        mediaConstraints:{
          audio:true,
          video:false
        }
      },
      transferresponse:[],
      checktransfer:true,
      muteconf:'',
      waitingresponse:[],
      apiinfo:undefined,
      allState:this.$store.state,
      agentEmails:undefined
    };
  },
  mounted() {
    this.background()
    if(this.stopEvent===undefined){
      setTimeout(()=>{
        this.$store.dispatch('initiateEvent',false)
      },1000)
    }
   
    const username = JSON.parse(localStorage.getItem("projectConfig"));
    const firstLetter = username.projects[0].userInfo[0].user_name;
    this.avatarName = firstLetter.charAt(0).toUpperCase();
   
    if (
      this.$store.getters.UserPermissions.indexOf(
        "dashboard:available-minutes:view"
      ) !== -1
    ) {
     
      this.showAvailMinutes=true
      this.checkAvailableMinutes();
    }
    this.userDatas()
  },
  computed: {
    ...mapState(),  
    ...mapGetters({
      navDrawer: "navDrawer",
      toolbarClippedLeft: "toolbarClippedLeft",
      fixedToolbar: "fixedToolbar",
      toolbar: "toolbarVisibility",
      navToolbarScheme: "navToolbarScheme",
      navMiniVarient: "navMiniVarient",
    }),
    authUser() {
      return authUser;
    },
    ...mapGetters(["checkingtoken"]),
  },
  methods: {
     ...mapActions(['setWebsocketStatus'
     , 'updateJSON','fetchCallAction',
     'setSession','onCall','initiateEvent',
     'callForward','callForwardKick']),

      userDatas(){
      let scope = this
      let payload = {
        skip:0,
        take:100
      }
      scope.$store
          .dispatch("userManagement", payload).then((response)=>{
            const agentRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Agent"));
            this.agentEmails = agentRoles.map(item => item.user_email);
            localStorage.setItem("agent_emails", JSON.stringify(this.agentEmails));
          })
     },
     sound(val){
      try {
      if(val){
        if(this.$store.state.ringing === false){
          this.$store.dispatch('callringtone',val)
        }
      }else{
          this.$store.dispatch('callringtone',val)
        }
      } catch (error) {
        console.log(error,'new is error')
      }
      
    },
    background(){
      try {
      const scope=this
       
      // localStorage.setItem('webrtcManual','false')
      if(localStorage.getItem('projectConfig')){
        scope.tenant = JSON.parse(localStorage.getItem('projectConfig')).tenant_code
        scope.AppPermission = JSON.parse(localStorage.getItem('projectConfig')).projects[0].userPermission
        scope.config = JSON.parse(localStorage.getItem('projectConfig'))
        scope.station = JSON.parse(localStorage.getItem('projectConfig')).projects[0].userInfo[0].user_phone_number
      }
      let  {webrtc_domain ,webrtc_ip} = scope.config.projects[0].config[0]
       let webrtcSocket = new JsSIP.WebSocketInterface(
      `wss://${webrtc_domain}/ws`,
      )
      
      var configuration = {
        sockets:[webrtcSocket], 
        display_name:this.station,
        authorization_user :this.station,
        uri: `sip:${this.station}@${webrtc_ip}`,
        password:this.station,
        realm:webrtc_ip,
        DtlsSrtpKeyAgreement:false,
        contact_uri:`sip:${this.station}@${webrtc_ip}`,
        connection_recovery_min_interval:5,
        connection_recovery_max_interval: 20,
        use_preloaded_route: true,
        session_timers: false,
        no_answer_timeout: 60000,
        register_expires: 30000000,
        ws_servers: `wss://${webrtc_ip}:5060`,
        trace_sip: true,
      }
      var coolphone = new JsSIP.UA(configuration)
      coolphone.start()
      coolphone.register()
      coolphone.on('registered',function (){
        scope.$store.dispatch('setWebsocketStatus', true);
      })
      coolphone.on('unregistered', function (){
        scope.$store.dispatch('setWebsocketStatus',false)
      })
      coolphone.on('registrationFailed',function (){
        scope.$store.dispatch('setWebsocketStatus',false)
      })
      coolphone.on('connected', function (){
        localStorage.setItem('webrtcManual','true')
        scope.$store.dispatch('setWebsocketStatus',true)
        let socketConnectCount = JSON.parse(
          localStorage.getItem('socketConnectCount')
        )
        localStorage.setItem('socketConnectCount', socketConnectCount + 1)
      })
      coolphone.on('disconnected',function (){
        scope.$store.dispatch('setWebsocketStatus',false)
      })
      coolphone.on('newRTCSession',function (data){
        let session_
        if(scope.$store.state.liveAgents.CallActionflag){
          scope.$store.dispatch('setWebsocketStatus', true)
          scope.$store.dispatch('setSession', data.session)
          session_ = data.session
        }
        if(session_.direction === 'incoming'){
          let webrtccallstatus=localStorage.getItem('webrtcManual')
          if (webrtccallstatus === 'true' && scope.$store.state.liveAgents.CallActionflag) {
            scope.$store.dispatch("CallActionflag", false);
            scope.$store.dispatch('onCall') 
            session_.on('peerconnection',function (data){
              data.peerconnection.addEventListener('addstream',function(e){
                let remoteAudio = document.createElement('audio')
                remoteAudio.srcObject = e.stream
                remoteAudio.play()
                scope.sound(false)
              })
            })
            setTimeout(function () {
              session_.answer(scope.sipOptions)
            }, 500)
          }
          // else{
          //   if(scope.AppPermission.webrtc_answer){
          //     session_.on('peerconnection',function (data){
          //     data.peerconnection.addEventListener('addstream',function(e){
          //       let remoteAudio = document.createElement('audio')
          //       remoteAudio.srcObject = e.stream
          //       remoteAudio.play()
          //       scope.sound(false)
          //     })
          //   })
          //   setTimeout(function () {
          //     session_.answer(scope.sipOptions)
          //   }, 500)
          //   }else{
          //      session_.on('peerconnection',function (data){
          //     data.peerconnection.addEventListener('addstream',function(e){
          //       let remoteAudio = document.createElement('audio')
          //       remoteAudio.srcObject = e.stream
          //       remoteAudio.play()
          //       scope.sound(false)
          //     })
          //   })
          //   }
          // }
          
        }
        session_.on('failed',function (){
          scope.sound(false)
        })
        session_.on('ended',function (){
          // localStorage.setItem('webrtcManual', 'false')
          scope.sound(false)
        })
        session_.on('started',function(){
        })
        session_.on('sending',function (){
        })
        session_.on('accepted', function () {
       })
        session_.on('confirmed',function (){
        })
        session_.on('connecting',function(){
        })
        session_.on('progress',function (){
        })
      })
      } catch (error) {
        console.log(error,'new is error')
      }
    },
    // callForward(){
    //   const scope=this
    //   let data = {
    //     action:'xfer-cancel',
    //     station:'646',
    //     agent:scope.apiinfo.user_email,
    //     tenant_id:scope.tenant,
    //     uniqueid:scope.session_state.background.socket_message.uniqueid,
    //   }
    //   scope.$store.dispatch('callForwardKick',data)
    // },

    //  edited risvan
    toggleMiniVariantMode() {
     
      this.$store.dispatch("toggleMiniVariantMode");
      this.$store.dispatch("toggleMiniVarient");
    },

    auth0Logout: function () {
      var scope = this;
      scope.$store.dispatch("auth0Logout").then(function () {
        scope.$router.push({
          name: "doocti/Auth/Login",
        });
      });
    },
    checkAvailableMinutes:function(){
        var scope = this;        
        let query={tenant_code:localStorage.getItem("TenantCode")}
         
       scope.$store.dispatch("call_credits",query).then(function (res) {
        if(res.status == 200){
          let data=res.data.data[0].balance_coins
          scope.availableMins=data;
          scope.checkMinutes();
        }
       
      
      });
    },
     checkMinutes:function(){
  
        this.timedate =  setTimeout(this.checkAvailableMinutes,60000)
      
     },
    redirecttologin: function () {
      this.auth0Logout();
    },
  },
  watch: {
    checkingtoken(newVal) {
      if (newVal) {
        this.checkingtokens = true;
      }
    },
  
  },
  destroyed() {
    clearTimeout(this.timedate);
  }
  
};
</script>
<style scoped>
.card-text {
  margin-top: 200px;
  margin-left: 450px;
  font-size: 20px;
  word-break: break-all;
  width: 310px;
}
.click-text {
  padding-left: 20px;
}
.header-view  {
  display:flex;
  flex-direction: right;
  justify-content: space-between;

}
.header-view div:first-child {
  font-size: 16px;
  color:#2196f3;
  font-weight: 1000;
  padding:30px 180px 30px 0;
}

</style>