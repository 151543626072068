<template>
  <v-img :src="backgroundImg" class="page-vimg">
    <v-container fill-height pa-0>
      <v-layout align-center>
        <v-flex xs12>
          <v-card class="text-xs-center margin-auto border-radius6 box-white-500-glow elevation-10 auth-box">
            <v-layout align-center justify-center fill-height wrap>
              <v-flex xs12 class="text-xs-center mt-3">
                <img style="width: 160px; height: 60px" v-if="domain_name()" src="/static/doocti.png" alt="Vuse"
                  class="text-xs-center" height="100" />

                <img style="width: 160px; height: 60px" v-if="domain_name() === false &&
                  domail_namevalidate('console.gtawk')
                  " src="/static/gtawk.png" alt="Vuse" class="text-xs-center" height="100" />
                   <img style="width: 160px; height: 60px" v-if="domain_name() === false &&
                  domail_namevalidate('localhost')
                  " src="/static/doocti.png" alt="Vuse" class="text-xs-center" height="100" />
                  <img style="width: 160px; height: 60px" v-if="domain_name() === false &&
                  domail_namevalidate('console-v2.doocti.com')"
                   src="/static/doocti.png" alt="Vuse" class="text-xs-center" height="100" />
                  <img style="width: 160px; height: 60px" v-if="domain_name() === false &&
                  domail_namevalidate('cc.callcentersolutions.ae')"
                   src="/static/ccsd.jpg" alt="Vuse" class="text-xs-center" height="100" />
                <h3>Admin Login</h3>
                <v-form @submit.prevent="$v.$invalid ? invalid() : submit(form)" ref="form">
                  <v-layout wrap pa-4>
                    <v-flex xs12 pa-0 v-if="true">


                      <v-text-field :readonly="false" :disabled="false" :placeholder="`${'User Name'}`" required
                        v-model="form.email" :error-messages="fieldErrors('form.email')"
                        :label="`${'UserName'}`"></v-text-field>

                      <v-text-field label="Password" v-model="form.password" :type="passwordFieldType" required
                        :error-messages="fieldErrors('form.password')" append-icon="mdi-eye"
                        @click:append="togglePasswordVisibility"></v-text-field>

                    </v-flex>

                    <v-flex xs12>
                      <v-layout wrap text-xs-center>
                        <v-flex xs12 class="no-mrpd">
                         <v-btn  color="act" type="submit" block :disabled="form.password.length === 0 || form.email.length === 0 ">Login</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.show" :right="true" :bottom="true" :color="'red'" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="black" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-img>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
const defaultForm = {
  email: "",
  password: "",
  remeberme: false,
};
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  validationMessages: {
    form: {
      email: {
        required: "Please enter email",
        email: "Email must be valid",
      },
      password: {
        required: "Please enter password",
        minLength: "Password must be of at least 8 characters",

      },
    },
  },
  data() {
    return {
      form: Object.assign({}, defaultForm),
      backgroundImg: "/static/doc-images/bgimage.jpg",
      passwordFieldType: 'password',
      snackbar: {
        show: false,
        timeout: 5000,
        text: "",
      },
    };
  },
  components: {},
  methods: {
    invalid: function () {
      this.$v.$touch();
      console.log("this is invalid");
    },
    showSnackBar(message) {
      this.snackbar.text = message;
      this.snackbar.show = true;
      setTimeout(() => {
        this.snackbar.text = "";
        this.snackbar.show = false;
      }, this.snackbar.timeout);
    },
 
    resetForm() {
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.reset();
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      console.log("inside togglePasswordVisibility", this.passwordFieldType);
    },

    submit: function (data) {
      var scope = this;
      delete data.remeberme;

      scope.$store
        .dispatch("auth0Login", data)
        .then(function (res) {
          console.log(res, "devtrial_agent002@doocti.com");
          scope.$store
            .dispatch("auth0Parse", res)

            .then(function () {
              scope.showSnackBar("Login succes!!");
              scope.resetForm();
              scope.$v.$reset();
              scope.$router.push({
                name: "realtime/LiveAgents",
              });
              localStorage.setItem('socketConnectCount', 0)
            })
            .catch(() => {
              console.log("inside first catch", res);
              // Handle any other logic if needed
              scope.showSnackBar("Password or Email is incorrect!");
            });
        })
        .catch((error) => {
          console.error("Login error:", error);
          scope.showSnackBar("Password or Email is incorrect!");
        });
    },

    domail_namevalidate(data) {
      if (window.location.hostname === data) {
        return true;
      } else {
        return false;
      }
    },
    domain_name() {
      const regex = /doocti/g;
      const matches = window.location.hostname.match(regex);
      if (matches !== null && matches[0] === "doocti") {
        return true;
      }
      return false;
    },
  },
};
</script>