<template>
    <div>
        <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
            :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
            @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewWebhook" :style="styleObject">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table :headers="headers" :items="schedulerData" class="elevation-1 fixed-layout"
                :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading"
                :rows-per-page-items="[10]">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty p0">{{ props.item.scheduler_name }}</td>
                    <td class="checkEmpty p0 " v-if="props.item.email.length > 10">
                        {{ props.item.email.split(",")[0] + "..." }}
                    </td>
                    <td class="checkEmpty p0 " v-if="props.item.email.length < 10">
                        {{ props.item.email }}
                    </td>
                    <td class="checkEmpty p0" v-if="props.item.reports.length > 10">
                        {{ props.item.reports.split(",")[0] + "..." }}
                    </td>
                    <td class="checkEmpty p0" v-if="props.item.reports.length < 10">
                        {{ props.item.reports }}
                    </td>
                    <td class="checkEmpty p0">
                        {{ props.item.status }}
                    </td>
                    <td class="checkEmpty p0">
                        <!-- /////// Quck edit option code by abdul //////////////// -->


                        <!-- <td class="text-xs-center checkEmpty p0"> -->

                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete"
                            @click="deleteItem(props.item.id)">delete
                        </v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Delete Scheduler Info</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete this Scheduler ?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template>
            <v-dialog persistent :value="page.isAddScheduler" scrollable max-width="500px">
                <v-card>

                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Create Scheduler</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="sche" v-model="valid">
                            <v-container style="max-height: 30em">
                                <v-layout column>
                                    <v-flex xs12>
                                        <v-text-field label="Scheduler name" required
                                            v-model="addScheduler.payload.name"
                                            :rules="[(v) => !!v || 'Scheduler name is required']"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field label="Description" required
                                            v-model="addScheduler.payload.Description"
                                            :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                                        <v-text-field required label="E-mail" type="text"
                                            v-model="addScheduler.payload.Email" :rules="emailRules"></v-text-field>
                                    </v-flex>


                                    <v-flex xs12>
                                        <v-autocomplete v-model="addScheduler.payload.months" :items="months"
                                            label="Months" persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="toggle">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            addScheduler.payload.months.length > 0 ? 'primary' : ''
                                                        ">{{ icon }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    addScheduler.payload.months.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>


                                    <v-flex xs12>
                                        <v-autocomplete :disabled="addScheduler.payload.dates.length > 0"
                                            v-model="addScheduler.payload.days" :items="days" label="Days"
                                            persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="toggle2">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            addScheduler.payload.days.length > 0 ? 'primary' : ''
                                                        ">{{ icon3 }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    addScheduler.payload.days.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>


                                    <v-flex xs12>
                                        <v-autocomplete :disabled="addScheduler.payload.days.length > 0"
                                            v-model="addScheduler.payload.dates" :items="dates" label="Dates"
                                            persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="toggle1">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            addScheduler.payload.dates.length > 0 ? 'primary' : ''
                                                        ">{{ icon2 }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    addScheduler.payload.dates.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-text-field label="Time" required v-model="addScheduler.payload.timeValue"
                                            @click="page.timeModal = true"></v-text-field>
                                    </v-flex>
                                    <v-dialog ref="dialog" v-model="page.timeModal"
                                        :return-value.sync="addScheduler.payload.timeValue" width="290px" height="50px">


                                        <v-time-picker v-if="page.timeModal" v-model="addScheduler.payload.timeValue"
                                            color="primary">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="page.timeModal = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary"
                                                @click="$refs.dialog.save(addScheduler.payload.timeValue)">
                                                OK
                                            </v-btn>
                                        </v-time-picker>
                                    </v-dialog>

                                    <v-flex xs12>
                                        <!-- <v-container fluid> -->
                                        <v-select v-model="addScheduler.payload.selectedReport" :items="reports"
                                            label="Reports" :rules="[(v) => !!v || 'Reports is required']" multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @mousedown.prevent @click="Reportstoggle">
                                                    <v-list-tile-action>
                                                        <v-icon
                                                            :color="addScheduler.payload.selectedReport.length > 0 ? 'primary' : ''">
                                                            {{ Reportsicon }}
                                                        </v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>
                                                            Select All
                                                        </v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <!-- <template v-slot:append-item>
 <v-divider class="mb-2"></v-divider>
 <v-list-tile disabled>
 <v-list-tile-avatar color="grey lighten-3">
 <v-icon>
 mdi-food-apple
 </v-icon>
 </v-list-tile-avatar>

 <v-list-tile-content v-if="ReportslikesAllFruit">
 <v-list-tile-title>
 Holy smokes, someone call the fruit police!
 </v-list-tile-title>
 </v-list-tile-content>

 <v-list-tile-content v-else-if="ReportslikesSomeFruit">
 <v-list-tile-title>
 Fruit Count
 </v-list-tile-title>
 <v-list-tile-subtitle>
 {{ addScheduler.payload.selectedReport.length }}
 </v-list-tile-subtitle>
 </v-list-tile-content>

 <v-list-tile-content v-else>
 <v-list-tile-title>
 How could you not like fruit?
 </v-list-tile-title>
 <v-list-tile-subtitle>
 Go ahead, make a selection above!
 </v-list-tile-subtitle>
 </v-list-tile-content>
 </v-list-tile>
 </template> -->
                                        </v-select>
                                        <!-- </v-container> -->
                                    </v-flex>

                                    <!-- <v-flex xs12>
 <v-autocomplete v-model="addScheduler.payload.selectedReport" :items="reports" label="Reports"
 persistent-hint multiple>
 <template v-slot:prepend-item>
 <v-list-tile ripple @click="toggle3">
 <v-list-tile-action>
 <v-icon :color="
 addScheduler.payload.selectedReport.length > 0 ? 'primary' : ''
 ">{{ icon7 }}</v-icon>
 </v-list-tile-action>
 <v-list-tile-content>
 <v-list-tile-title>Select All</v-list-tile-title>
 </v-list-tile-content>
 </v-list-tile>
 <v-divider class="mt-2"></v-divider>
 </template>
 <template v-slot:selection="{ item, index }">
 <v-chip v-if="index === 0">
 <span>{{ item }}</span>
 </v-chip>
 <span v-if="index === 1" class="grey--text caption">(+{{ addScheduler.payload.selectedReport.length
 - 1
 }}
 others)</span>
 </template>
 </v-autocomplete>
 </v-flex> -->




                                    <!-- <v-select required label="Status" :items="status1"
                                        v-model="addScheduler.payload.selectedStatus">
                                    </v-select> -->
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="modal-footer--sticky">
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="closeAddScheduler()">Close</v-btn>
                        <v-btn color="primary" :disabled=!valid @click="saveAddScheduler()">Create</v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </template>
        <template>
            <v-dialog persistent :value="page.isEditScheduler" scrollable max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Edit Scheduler</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="valid">
                            <v-container style="max-height: 30em">
                                <v-layout column>
                                    <v-flex xs12>
                                        <v-text-field label="Scheduler name" required v-model="editScheduler.name"
                                            :rules="[(v) => !!v || 'Rule name is required']"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field label="Description" required v-model="editScheduler.Description"
                                            :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                                        <v-text-field required label="E-mail" type="text" v-model="editScheduler.Email"
                                            :rules="emailRules"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-select v-model="editScheduler.selectedReport" :items="reports"
                                            label="Reports" multiple></v-select>
                                    </v-flex>
                                    <v-select required label="Status" :items="status1"
                                        v-model="editScheduler.selectedStatus">
                                    </v-select>
                                    <v-flex xs12>
                                        <v-autocomplete v-model="editScheduler.selectedMonths" :items="months"
                                            label="Month" persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="editToggle1">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            editScheduler.selectedMonths.length > 0 ? 'primary' : ''
                                                        ">{{ icon5 }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    editScheduler.selectedMonths.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-autocomplete :disabled="editScheduler.selectedDays.length > 0"
                                            v-model="editScheduler.selectedDates" :items="dates" label="Dates"
                                            persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="editToggle">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            editScheduler.selectedDates.length > 0 ? 'primary' : ''
                                                        ">{{ icon4 }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    editScheduler.selectedDates.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-autocomplete :disabled="editScheduler.selectedDates.length > 0"
                                            v-model="editScheduler.selectedDays" :items="days" label="Days"
                                            persistent-hint multiple>
                                            <template v-slot:prepend-item>
                                                <v-list-tile ripple @click="editToggle2">
                                                    <v-list-tile-action>
                                                        <v-icon :color="
                                                            editScheduler.selectedDays.length > 0 ? 'indigo darken-4' : ''
                                                        ">{{ icon6 }}</v-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>Select All</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-divider class="mt-2"></v-divider>
                                            </template>
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item }}</span>
                                                </v-chip>
                                                <span v-if="index === 1" class="grey--text caption">(+{{
                                                    editScheduler.selectedDays.length - 1
                                                }}
                                                    others)</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field label="Time" required v-model="editScheduler.timeValue"
                                            @click="page.timeModal1 = true">
                                        </v-text-field>
                                    </v-flex>
                                    <v-dialog ref="dialog1" v-model="page.timeModal1"
                                        :return-value.sync="editScheduler.timeValue" width="290px" height="50px">


                                        <v-time-picker v-if="page.timeModal1" v-model="editScheduler.timeValue"
                                            color="primary" header-color="primary">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="page.timeModal1 = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary"
                                                @click="$refs.dialog1.save(editScheduler.timeValue)">
                                                OK
                                            </v-btn>
                                        </v-time-picker>
                                    </v-dialog>

                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="modal-footer--sticky">
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="closeEditScheduler()">Close</v-btn>
                        <v-btn color="primary" :disabled=!valid @click="saveEditScheduler()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">
                    <div class="autocompleteDiv">
                        <!-- <v-select v-model="filter.payload.email" :items="filterEmailvalue" label="Email"
                            small></v-select> -->
                                        <v-autocomplete
        v-model="filter.payload.email"
        :items="filterEmailvalue"
        :loading="filter.isLoading"
        :search-input.sync="filter.search"        
     
        label="Email"      
         hide-no-data hide-selected 
      ></v-autocomplete>
                    </div>

                    <!-- /////////two more filter is added by abdul//////// -->
                    <div class="autocompleteDiv">
                        <v-select v-model="filter.payload.reports" :items="filter.options.type" label="Report"
                            item-text="name" item-value="value" return-string required persistent-hint
                            multiple></v-select>
                    </div>
                    <div class="autocompleteDiv">
                        <v-select v-model="filter.payload.status" :items="filter.options.status" label="Status"
                            item-text="name" item-value="value" return-string persistent-hint></v-select>
                    </div>
                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="reset()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import loader from "@/components/Widgets/loader";

//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
    components: {
        loader,

    },

    data() {
        return {
            valid: true,
            disabled: true,
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            pause_code: [],
            selected: { name: "ACTIVE", value: "Y" },
            page: {
                title: "Scheduler",
                icon: "fa-file fa-2x",
                loading: false,
                isAddScheduler: false,
                playingRecord: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
                addnew: [],
                candelete: false,
                canedit: false,
                isEditScheduler: false,
                disabledate: true,
                disabledays: true,
                timeModal: false,
                timeModal1: false,
                disabletime: false,
                selectallMonth: false,
                selectallDays: false,
                selectallDates: false,
                isFilterOpen: false
            },
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) =>
                    /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(v) ||
                    "E-mail must be valid",
            ],
            days: [
                "Mon",
                "Tue",
                "Wed",
                "Thu",
                "Fri",
                "Sat",
                "Sun",
            ],
            months: [

                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            dates: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],

            status1: ["Active", "Inactive"],
            reports: ["Call Recording", "Call Log", "Agent Performance", "Agent Activity", "Agent Dispo", "Agent Pause", "Queue Summary", "Missed Call", "Dropped Call"],

            pagecount: 0,
            headers: [
                {
                    text: "Scheduler Name",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "scheduler_name",
                },
                {
                    text: "Email",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "call_type",
                },
                {
                    text: "Report",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "active",
                },
                {
                    text: "Status",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "hooper_level",
                },
                {
                    text: "Action",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "btn",
                },
            ],
            schedulerData: [],
            pagination: {
                page: 0,
                rowsPerPage: 10,
            },
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            DeleteRowdata: false,
            showRowData: false,
            DataRow: false,
            tableLoading: false,
            filterdispo: [],
            filterEmailvalue: [],
            filter: {
                payload: {
                    email: "",
                    reports: "",
                    status: "",
                },
                options: {
                    type: [
                        { name: "Call Recording", value: "Call Recording" },
                        { name: "Call Log", value: "Call Log" },
                        { name: "Agent Performance", value: "Agent Performance" },
                        { name: "Agent Activity", value: "Agent Activity" },
                        { name: "Agent Dispo", value: "Agent Dispo" },
                        { name: "Agent Pause", value: "Agent Pause" },
                        { name: "Queue Summary", value: "Queue Summary" },
                        { name: "Missed call", value: "Missed Call" },
                        { name: "Dropped Call", value: "Dropped Call" }
                    ],
                    status: ["Active", "Inactive"],              
                },
                isLoading:false,
                search: "",
            },
            editScheduler: {
                id: "",
                name: "",
                Description: "",
                Email: "",
                selectedMonths: [],
                selectedDays: [],
                selectedDates: [],
                timeValue: "",
                selectedReport: "",
                selectedStatus: ""
            },
            addScheduler: {
                valid: false,
                payload: {
                    name: "",
                    Description: "",
                    Email: "",
                    months: [],
                    days: [],
                    dates: [],
                    timeValue: "",
                    selectedReport: "",
                    selectedStatus: "Active"
                },
            },
            isProcessing: false,
        };
    },
    methods: {

        Reportstoggle() {
            this.$nextTick(() => {
                if (this.ReportslikesAllFruit) {
                    this.addScheduler.payload.selectedReport = ""
                } else {
                    this.addScheduler.payload.selectedReport = this.reports.slice()
                }
            })
        },
        selectAllMon: function () {
            var scope = this;
            for (let i = 0; i < scope.months.length; i++) {
                if (scope.page.selectallMonth == true) {
                    scope.months[i].value = false;
                } else scope.months[i].value = true;
            }
        }, toggle() {
            this.$nextTick(() => {
                if (this.selectAllMonth) {
                    this.addScheduler.payload.months = [];
                    //this.passdata("xaxis_column");
                } else {
                    this.addScheduler.payload.months = this.months.slice();
                    //  this.passdata(
                    //  this.addScheduler.payload.months
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },


        editToggle() {
            this.$nextTick(() => {
                if (this.selected_AllDates) {
                    this.editScheduler.selectedDates = [];
                    //  this.passdata( "xaxis_column");
                } else {
                    this.editScheduler.selectedDates = this.dates.slice();
                    //  this.passdata(
                    //  this.editScheduler.selectedDates
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },
        editToggle1() {
            this.$nextTick(() => {
                if (this.selected_AllMonths) {
                    this.editScheduler.selectedMonths = [];
                    //  this.passdata("xaxis_column");
                } else {
                    this.editScheduler.selectedMonths = this.months.slice();
                    //  this.passdata(
                    //  this.editScheduler.selectedMonths
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },
        editToggle2() {
            this.$nextTick(() => {
                if (this.selected_AllDays) {
                    this.editScheduler.selectedDays = [];
                    //this.passdata( "xaxis_column");
                } else {
                    this.editScheduler.selectedDays = this.days.slice();
                    //this.passdata(
                    //  this.editScheduler.selectedDays
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },
        toggle1() {
            this.$nextTick(() => {
                if (this.all_dates) {
                    this.addScheduler.payload.dates = [];
                    //  this.passdata( "xaxis_column");
                } else {
                    this.addScheduler.payload.dates = this.dates.slice();
                    //  this.passdata(
                    //  this.addScheduler.payload.dates
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },


        toggle2() {
            this.$nextTick(() => {
                if (this.all_days) {
                    this.addScheduler.payload.days = [];
                    //  this.passdata( "xaxis_column");
                } else {
                    this.addScheduler.payload.days = this.days.slice();
                    //  this.passdata(
                    //  this.addScheduler.payload.days
                    //  .toString()
                    //  .toLowerCase()
                    //  .replaceAll(" ", "_"),
                    //  "xaxis_column"
                    //  );
                }
            });
        },
        selectAllDates: function () {
            var scope = this;
            for (let i = 0; i < scope.date1.length; i++) {
                if (scope.page.selectallDates == true) {
                    scope.date1[i].active = false;
                    scope.date2[i].active = false;
                    scope.date3[i].active = false;
                    scope.date4[i].active = false;
                    scope.date5[i].active = false;
                    scope.date6[0].active = false;
                    scope.page.disabledays = true;
                } if (scope.page.selectallDates == false) {
                    scope.date1[i].active = true;
                    scope.date2[i].active = true;
                    scope.date3[i].active = true;
                    scope.date4[i].active = true;
                    scope.date5[i].active = true;
                    scope.date6[0].active = true;
                    scope.page.disabledays = false;
                }

            }
        },
        disableDates: function () {
            var scope = this;
            for (let i = 0; i < scope.days.length; i++) {
                if (scope.days[i].value == true) {
                    scope.days[i].value == false
                    scope.page.disabledate = false;
                    break;
                }
                scope.page.disabledate = true;
            }
            for (let i = 0; i < this.days.length; i++) {
                if (this.days[i].value === false) {
                    this.page.selectallDays = false
                }
            }
            if (this.days[0].value === true && this.days[1].value === true && this.days[2].value === true && this.days[3].value === true && this.days[4].value === true && this.days[5].value === true && this.days[6].value === true) {
                this.page.selectallDays = true
            }
        },
        selectAllDays: function () {
            var scope = this;
            for (let i = 0; i < scope.days.length; i++) {
                if (scope.page.selectallDays == true) {
                    scope.days[i].value = false;
                    scope.page.disabledate = true;
                } if (scope.page.selectallDays == false) {
                    scope.page.disabledate = false;
                    scope.days[i].value = true;
                }

            }
        },
        disableDays: function () {
            var scope = this;

            for (let i = 0; i < scope.date1.length; i++) {
                if (
                    scope.date1[i].active == true ||
                    scope.date2[i].active == true ||
                    scope.date3[i].active == true ||
                    scope.date4[i].active == true ||
                    scope.date5[i].active == true ||
                    scope.date6[0].active == true
                ) {
                    scope.date1[i].active == false
                    scope.date2[i].active == false
                    scope.date3[i].active == false
                    scope.date4[i].active == false
                    scope.date5[i].active == false
                    scope.date6[0].active == false
                    scope.page.disabledays = false;
                    break;
                }
                scope.page.disabledays = true;
            }
            for (let i = 0; i < this.date1.length; i++) {
                if (this.date1[i].active === false || this.date2[i].active === false || this.date3[i].active === false || this.date4[i].active === false || this.date5[i].active === false || this.date6[0].active === false) {
                    this.page.selectallDates = false
                }
            }

            let seleceAllDates = [];
            for (let i = 0; i < this.date1.length; i++) {
                if (this.date1[i].active == true) {
                    seleceAllDates.push(this.date1[i].active)
                }
                if (this.date2[i].active == true) {
                    seleceAllDates.push(this.date2[i].active)
                }
                if (this.date3[i].active == true) {
                    seleceAllDates.push(this.date3[i].active)
                }
                if (this.date4[i].active == true) {
                    seleceAllDates.push(this.date4[i].active)
                }
                if (this.date5[i].active == true) {
                    seleceAllDates.push(this.date5[i].active)
                }

            }
            if (this.date6[0].active == true) {
                seleceAllDates.push(this.date6[0].active)
            }
            if (seleceAllDates.length == 31) {
                this.page.selectallDates = true
            }
        },
        saveAddScheduler() {
            var scope = this;
            let data = {
                "schedulerName": scope.addScheduler.payload.name,
                "description": scope.addScheduler.payload.Description,
                "email": scope.addScheduler.payload.Email,
                "months": scope.addScheduler.payload.months,
                "days": scope.addScheduler.payload.days,
                "dates": scope.addScheduler.payload.dates,
                "time": scope.addScheduler.payload.timeValue,
                "reports": scope.addScheduler.payload.selectedReport,
                "status": scope.addScheduler.payload.selectedStatus
            }

            scope.$store
                .dispatch("insertScheduler", data)

                .then((response) => {
                    scope.page.isAddScheduler = false;
                    scope.$refs.sche.reset();
                    if (response.data.message == "success") {
                        scope.getDataFromApi();
                        scope.showSnackBar("Created Successfully");
                    }
                })
                .catch(function (error) {
                    scope.page.isAddScheduler = false;
                    scope.showSnackBar("error" + error);
                    scope.addScheduler.payload.name = "";
                    scope.addScheduler.payload.Email = "";
                    scope.addScheduler.payload.selectedReport = "";
                    scope.addScheduler.payload.selectedStatus = "";
                    scope.addScheduler.payload.timeValue = "";
                    scope.addScheduler.payload.Description = "";
                });
            this.getDataFromApi();
        },
        closeAddScheduler: function () {
            //  let scope = this;
            this.page.isAddScheduler = false;
            this.addScheduler.payload.name = "";
            this.addScheduler.payload.Email = "";
            this.addScheduler.payload.months = "";
            this.addScheduler.payload.selectedReport = "";
            this.addScheduler.payload.selectedStatus = "";
            this.addScheduler.payload.timeValue = "";
            this.addScheduler.payload.Description = "";
            this.addScheduler.payload.dates = "";
            this.addScheduler.payload.days = "";


            this.getDataFromApi();

        },
        AddNewWebhook: function () {
            this.page.isAddScheduler = true;
        },
        saveEditScheduler() {
            var scope = this;

            let updateData = {
                "id": scope.editScheduler.id,
                "schedulerName": scope.editScheduler.name,
                "description": scope.editScheduler.Description,
                "email": scope.editScheduler.Email,
                "months": scope.editScheduler.selectedMonths,
                "days": scope.editScheduler.selectedDays,
                "dates": scope.editScheduler.selectedDates,
                "time": scope.editScheduler.timeValue,
                "reports": scope.editScheduler.selectedReport,
                "status": scope.editScheduler.selectedStatus
            }

            this.title = [];
            scope.$store
                .dispatch("updateScheduler", updateData)
                .then(() => {
                    scope.page.isAddScheduler = false;
                    scope.showSnackBar("Updated Successfully");
                    scope.page.isEditScheduler = false;
                    this.getDataFromApi();

                    scope.addScheduler.payload.name = "";
                    scope.addScheduler.payload.Email = "";
                    scope.addScheduler.payload.selectedReport = "";
                    scope.addScheduler.payload.selectedStatus = "";
                    scope.addScheduler.payload.timeValue = "";
                    scope.addScheduler.payload.Description = "";
                    // for (let i = 0; i < this.months.length; i++) {
                    // if(this.months[i].value == true ){
                    // this.months[i].value = false;
                    // this.page.selectallMonth = false;
                    // }
                    // }
                    // for (let i = 0; i < this.days.length; i++) {
                    // if(scope.days[i].value == true ){
                    // scope.days[i].value = false;
                    // }
                    // }
                    // if(scope.page.selectallDays == true){
                    // scope.page.selectallDays = false;
                    // }
                    // if(scope.page.selectallDates == true){
                    // scope.page.selectallDates = false;
                    // }
                    // if(scope.page.disabledate == false){
                    // this.page.disabledate = true;
                    // }
                    // if(scope.page.disabledays == false){
                    // this.page.disabledays = true;
                    // }

                    // for (let i = 0; i < scope.date1.length; i++) {
                    // if(scope.date1[i].active == true ){
                    // scope.date1[i].active = false;
                    // }
                    // }
                    // for (let i = 0; i < scope.date2.length; i++) {
                    // if(scope.date2[i].active == true ){
                    // scope.date2[i].active = false;
                    // }
                    // }
                    // for (let i = 0; i < scope.date3.length; i++) {
                    // if(scope.date3[i].active == true ){
                    // scope.date3[i].active = false;
                    // }
                    // }
                    // for (let i = 0; i < scope.date4.length; i++) {
                    // if(scope.date4[i].active == true ){
                    // scope.date4[i].active = false;
                    // }
                    // }
                    // for (let i = 0; i < scope.date5.length; i++) {
                    // if(scope.date5[i].active == true ){
                    // scope.date5[i].active = false;
                    // }
                    // if(scope.date6[0].active == true ){
                    // scope.date6[0].active = false;
                    // }
                    //}
                    // for (let i = 0; i < this.months.length; i++) {
                    // this.months[i].value = false;
                    // }
                    // for (let i = 0; i < this.days.length; i++) {
                    // this.days[i].value = false;
                    // }
                    // for (let i = 0; i < this.date1.length; i++) {
                    // this.date1[i].active = false;
                    // this.date2[i].active = false;
                    // this.date3[i].active = false;
                    // this.date4[i].active = false;
                    // this.date5[i].active = false;
                    // this.date6[0].active = false;
                    // }

                })
                .catch(function (error) {
                    scope.page.isAddScheduler = false;
                    scope.showSnackBar("error" + error);
                    scope.page.isEditScheduler = false;
                    scope.addScheduler.payload.name = "";
                    scope.addScheduler.payload.Email = "";
                    scope.addScheduler.payload.selectedReport = "";
                    scope.addScheduler.payload.selectedStatus = "";
                    scope.addScheduler.payload.timeValue = "";
                    scope.addScheduler.payload.Description = "";
                    // for (let i = 0; i < this.months.length; i++) {
                    // this.months[i].value = false;
                    // }
                    // for (let i = 0; i < this.days.length; i++) {
                    // this.days[i].value = false;
                    // }
                    // for (let i = 0; i < this.date1.length; i++) {
                    // this.date1[i].active = false;
                    // this.date2[i].active = false;
                    // this.date3[i].active = false;
                    // this.date4[i].active = false;
                    // this.date5[i].active = false;
                    // this.date6[0].active = false;
                    // }
                });
            this.getDataFromApi();
        },
        closeEditScheduler: function () {
            var scope = this;
            scope.page.isEditScheduler = false;


            for (let i = 0; i < this.months.length; i++) {
                if (this.months[i].value == true) {
                    this.months[i].value = false;
                    this.page.selectallMonth = false;
                }
            }
            for (let i = 0; i < this.days.length; i++) {
                if (scope.days[i].value == true) {
                    scope.days[i].value = false;
                }
            }
            if (scope.page.selectallDays == true) {
                scope.page.selectallDays = false;
            }
            if (scope.page.selectallDates == true) {
                scope.page.selectallDates = false;
            }
            if (scope.page.disabledate == false) {
                this.page.disabledate = true;
            }
            if (scope.page.disabledays == false) {
                this.page.disabledays = true;
            }

            //  for (let i = 0; i < scope.date1.length; i++) {
            //  if (scope.date1[i].active == true) {
            //  scope.date1[i].active = false;
            //  }
            //  }
            //  for (let i = 0; i < scope.date2.length; i++) {
            //  if (scope.date2[i].active == true) {
            //  scope.date2[i].active = false;
            //  }
            //  }
            //  for (let i = 0; i < scope.date3.length; i++) {
            //  if (scope.date3[i].active == true) {
            //  scope.date3[i].active = false;
            //  }
            //  }
            //  for (let i = 0; i < scope.date4.length; i++) {
            //  if (scope.date4[i].active == true) {
            //  scope.date4[i].active = false;
            //  }
            //  }
            //  for (let i = 0; i < scope.date5.length; i++) {
            //  if (scope.date5[i].active == true) {
            //  scope.date5[i].active = false;
            //  }
            //  if (scope.date6[0].active == true) {
            //  scope.date6[0].active = false;
            //  }
            //  }


            this.getDataFromApi();
        },
        refreshThisPage: function () {
            var scope = this;
            scope.page.loading = true;
            this.pagination.page=1        
            scope.getDataFromApi();
        },
        filterClick: function () {
       let scope = this
      scope.page.isFilterOpen = true;
        let data = {model:"scheduler",column:["email"]}
   
       scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
           scope.filterEmailvalue=datas[0].email        
   
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditPausecode = false;
        });
    },
        fetchFilter() {
            var scope = this;
            scope.tableLoading = true;


            var data = {
                email: scope.filter.payload.email,
                reports: scope.filter.payload.reports,
                status: scope.filter.payload.status
            };
            this.$store
                .dispatch("filterScheduler", data)
                .then((response) => {
                    scope.page.isFilterOpen = false
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data.data;
                    scope.schedulerData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddScheduler = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditScheduler = false;
                });
        },reset(){
        var scope = this;
        scope.filter.payload.email = ''
        scope.filter.payload.reports = ''
        scope.filter.payload.status = ''
        scope.getDataFromApi();
        scope.page.isFilterOpen = true
        },
        getDataFromApi() {
            var scope = this;
            scope.tableLoading = true;
         
            this.page.isFilterOpen = false;

            let take = this.pagination.rowsPerPage
            let skip
            if (this.pagination.page === 1) {
                skip = 0
            } else {
                skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
            }

            if (skip == -10) {
                skip = 0
            }
            let query = {
                skip: skip,
                take: take,
                email: scope.filter.payload.email,
                reports: scope.filter.payload.reports,
                status: scope.filter.payload.status
            }
            this.skip=skip
            this.$store
                .dispatch("getScheduler", query)
                .then((response) => {

                    this.pagecount = response.data.count
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data.data;
                    // if (datas !== "" && datas !== undefined && datas !== null) {
                    //     datas.forEach((element) => {
                    //         if (element["email"].includes(",")) {
                    //             let emailArr = element["email"].split(",")
                    //             for (let i = 0; i < emailArr.length; i++) {

                    //                 scope.filterEmailvalue.push(emailArr[i])
                    //             }
                    //         } if (!element["email"].includes(",")) {
                    //             scope.filterEmailvalue.push(element["email"])
                    //         }
                    //     });
                    // }

                    scope.schedulerData = datas;
                    scope.filter.payload.email = "";
                    scope.filter.payload.reports = "";
                    scope.filter.payload.status = "";
                })
                .catch(function () {
                    scope.page.isAddScheduler = false;
                    //  scope.showSnackBar("error" + error);
                    this.page.isEditScheduler = false;
                });
        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        deleteItem(id) {
            this.DeleteRowdata = true;
            this.delete_id = id;
        },
        editItem(item) {
            this.page.isEditScheduler = true;
            this.editScheduler.Description = item.description;
            this.editScheduler.name = item.scheduler_name;
            this.editScheduler.Email = item.email;
            let reportsItem = item.reports.split(',');
            this.editScheduler.selectedReport = reportsItem;
            this.editScheduler.timeValue = item.time;
            this.editScheduler.selectedStatus = item.status;
            this.editScheduler.selectedMonths = item.months.split(',');
            this.editScheduler.selectedDays = item.days.length > 0 ? item.days.split(',') : [];
            this.editScheduler.selectedDates = item.dates.length > 0 ? item.dates.split(',') : [];
            this.editScheduler.id = item.id;


            this.viewSelectedUpdate();
        },
        viewSelectedUpdate() {
            let arra = this.editScheduler.selectedMonths;
            let daysArr = this.editScheduler.selectedDays;
            let checkBoxAllMon = [];
            let ckeckBoxAllDay = [];
            let checkBoxDates1 = [];

            for (let i = 0; i < arra.length; i++) {
                for (let j = 0; j < this.months.length; j++) {
                    if (arra[i] === this.months[j].month) {
                        this.months[j].value = true;
                        checkBoxAllMon.push(this.months[j].value)
                    }
                }
            }
            for (let k = 0; k < daysArr.length; k++) {
                for (let j = 0; j < this.days.length; j++) {
                    if (daysArr[k] === this.days[j].day) {
                        this.days[j].value = true;
                        ckeckBoxAllDay.push(this.days[j].value)
                    }
                }
            }

            if (checkBoxDates1.length == 31) {
                this.page.selectallDates = true
                this.page.disabledays = false
            }
            if (ckeckBoxAllDay.length == 7) {
                this.page.selectallDays = true
                this.page.disabledate = false
            }
            if (checkBoxAllMon.length == 12) {
                this.page.selectallMonth = true
            }



        },

        savedelete() {
            let scope = this;
            scope.isProcessing = true;
            this.$store
                .dispatch("deleteScheduler", this.delete_id)
                .then((response) => {
                    scope.isProcessing = false;
                    if (response.data.message == "Delete") {
                        scope.getDataFromApi();
                        scope.showSnackBar("scheduler Deleted Successfully");
                    } else {
                        scope.showSnackBar("Error! Unable Delete list");
                    }
                })
                .catch(() => {
                    scope.isProcessing = false;
                    scope.showSnackBar("Error! Unable Delete list");
                });
            this.closedelete();
        },
        closedelete() {
            this.DeleteRowdata = false;
        },
    },
    computed: {
        ...mapGetters(["CallFlow", "filters", "status"]),

        selectAllMonth() {
            return this.addScheduler.payload.months.length === this.months.length
        }, selectAllreports() {
            return this.addScheduler.payload.selectedReport.length === this.selectedReport.length
        },

        selectSomeMonth() { return this.addScheduler.payload.months.length > 0 && !this.selectAllMonth },
        all_dates() {
            return this.addScheduler.payload.dates.length === this.dates.length
        },



        some_dates() { return this.addScheduler.payload.dates.length > 0 && !this.all_dates },
        selected_AllDates() {
            return this.editScheduler.selectedDates.length === this.dates.length
        },
        selected_SomeDates() { return this.editScheduler.selectedDates.length > 0 && !this.selected_AllDates },
        all_days() {
            return this.addScheduler.payload.days.length === this.days.length
        },
        some_days() { return this.addScheduler.payload.days.length > 0 && !this.all_days },

        selected_AllMonths() {
            return this.editScheduler.selectedMonths.length === this.months.length
        },
        selected_SomeMonths() { return this.editScheduler.selectedMonths.length > 0 && !this.selected_AllMonths },
        selected_AllDays() {
            return this.editScheduler.selectedDays.length === this.days.length
        },
        selected_SomeDays() { return this.editScheduler.selectedDays.length > 0 && !this.selected_AllDays },
        icon2() {
            if (this.all_dates) { return 'mdi-close-box' }
            if (this.some_dates) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        },


        icon3() {
            if (this.all_days) { return 'mdi-close-box' }
            if (this.some_days) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        },


        icon4() {
            if (this.selected_AllDates) { return 'mdi-close-box' }
            if (this.selected_SomeDates) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        },
        icon5() {
            if (this.selected_AllMonths) { return 'mdi-close-box' }
            if (this.selected_SomeMonths) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        },
        icon6() {
            if (this.selected_AllDays) { return 'mdi-close-box' }
            if (this.selected_SomeDays) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        }, icon8() {
            if (this.selectAllreports) { return 'mdi-close-box' }


            return 'mdi-checkbox-blank-outline'
        }, icon() {
            if (this.selectAllMonth) { return 'mdi-close-box' }
            if (this.selectSomeMonth) { return 'mdi-minus-box' }

            return 'mdi-checkbox-blank-outline'
        },

        ReportslikesAllFruit() {
            return this.addScheduler.payload.selectedReport.length === this.reports.length
        },
        ReportslikesSomeFruit() {
            return this.addScheduler.payload.selectedReport.length > 0 && !this.ReportslikesAllFruit
        },
        Reportsicon() {
            if (this.ReportslikesAllFruit) return 'mdi-close-box'
            if (this.ReportslikesSomeFruit) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

    },
    mounted() {
        let scope = this;
        scope.page.loading = false;

        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:create"
                    ) !== -1
                ) {
                    scope.page.addnew = [{ label: "Add Scheduler" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        // this.page.addnew = [{ label: "Add Campaign" }];
        // scope.page.candelete = true;
        // scope.page.canedit = true;
        // const project_config = JSON.parse(localStorage.projectConfig);
        // this.crmdomain = project_config.projects[0].config[0].crm_domain;
        this.getDataFromApi();
    },
    watch: {
        pagination: {
            handler() {
               
                if(this.pagination.page > 1 || this.skip >= 10){
                this.getDataFromApi();
                }
            },
            deep: true,
        }, 'filter.search'(val){
       if(this.page.isFilterOpen){
      let scope =this    
 
       let data = {model:"scheduler",column:["email"]}
     
        data["search"]=val
       
       this.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          this.filterEmailvalue=datas[0].email        
       
        })
        .catch(function (error) {
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
       }
    },
    }
};
</script>
<style>
.tbl {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

.file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
}

.edit-input.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
</style>