<template>
    <div class="TextInputmain">
        <v-text-field :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" :disabled='attributesBinding.disabled' :placeholder="`${currentField.isPlaceholderVisible ? attributesBinding.placeholder : '' }`" v-model="input"
             :label="`${currentField.label}`">
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: ['currentField','Allfields'],
    data() {
        return {
            input: '',
            required_class:"required",
            others:"others",
             read_only:"readonly"
             
        }
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr['id'] = this.currentField.model;
            if (this.currentField.showWordLimit) {
                attr.maxlength = this.currentField.maxlength;
                attr['show-word-limit'] = this.currentField.showWordLimit
            }

            if (this.currentField.clearable) {
                attr.clearable = this.currentField.clearable;
            }

            if (this.currentField.disabled) {
                attr.disabled = this.currentField.disabled;
            }

            if (this.currentField.showPassword) {
                attr['show-password'] = this.currentField.showPassword;
            }

            if (this.currentField.isPlaceholderVisible) {
                attr.placeholder = this.currentField.placeholder;
            }

            return attr;
        }
    }
}
</script>
<style>
.TextInput {
    margin: 20px 10px 10px 10px;
}

.TextInputmain {
    display: flex;
    padding: 10px;
    height: 85px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:"*";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"@@";
    font-weight: 600;
    padding-left:2px ;
  }
</style>