import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
import auth0Variables from '../../utils/auth0-variables';
import {encoding , decoding} from '../../utils/EncodeDecode'
const auth0Config = auth0Variables();
const common_api_url = auth0Config.common_api_url;
const mutations = {
    [mutation_type.CommitNothing]: () => {}
};
const actions = {

    UserShiftFetch: (_,query) => {
        
        return new Promise((resolve, reject) => {
            try {
                api.get(`/appointment/usershift?skip=${query.skip}&take=${query.take}&user=${query.user}&shift=${query.shift}&category=${query.category}&status=${query.status}`,query).then(response => {
                    const userShiftData = response.data;
                    resolve(userShiftData);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchCategory: (_,query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = 0;
                let take = 0;
                api.get(`/meeting/category?skip=${skip}&take=${take}&is_active=${query.is_active}`,query).then(response => {
                    const category = response.data;
                    resolve(category);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    fetchUsers: (_,query) => {
        return new Promise((resolve, reject) => {
            let user = query.user;
          try {
                    let params={
                    user_email:'',
                    user_phone_number: 0,
                    user_id:'',
                    role: user,
                    take:'',
                    skip:''
                }
                params = encoding(params)
                api.get(`${common_api_url}/tenantUser?user_email=${params.user_email}&user_phone_number=${params.user_phone_number}&user_id=${params.user_id}&limit=${params.take}&offset=${params.skip}&role=${params.role}`).then(response => {
                    if(response.status == 200){
                        response.data.data = decoding(response.data.data)
                    }
                    resolve(response);
                }).catch(function(error) {
                    reject(error);
                });
          } catch (error) {
            reject(error);
          }
        });
      },

    fetchShift: (_,query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = 0;
                let take = 0;
                api.get(`/shift?skip=${skip}&take=${take}`,query).then(response => {
                    const shift = response.data;
                    resolve(shift);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    UserShiftAssign: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/usershift/create`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteUserShift: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/usershift/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateUserShift: (_,payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id
                api
                    .put(`/usershift/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    mutations,
    actions
};