<template>
    <div class="longtextinputmain">
       
        <v-textarea :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" outlined  :disabled="currentField.disabled"  dense :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" name="input-7-4" :label="`${currentField.label}`" v-model="textarea">
        </v-textarea>
    </div>
    </template>

<script>
export default {
    name: "LongTextInput",
    props: ["currentField"],
    data() {
        return {
            textarea: "",required_class:"required",
            others:"others",
             read_only:"readonly"
        };
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr["id"] = this.currentField.model;
            attr.disabled = this.currentField.disabled;
            return attr;
        },
    },
};
</script>

<style>


.longtextinputmain {
   padding: 15px;
}
 
.required:after {
    content:"*";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:"*";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
