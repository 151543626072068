import { render, staticRenderFns } from "./CreatePage.vue?vue&type=template&id=742d4070&scoped=true&"
import script from "./CreatePage.vue?vue&type=script&lang=js&"
export * from "./CreatePage.vue?vue&type=script&lang=js&"
import style0 from "./CreatePage.vue?vue&type=style&index=0&id=742d4070&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742d4070",
  null
  
)

export default component.exports