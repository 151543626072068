<template>
  <div class="TimePickermain">
    <div class="TimePickerfield">
      <v-menu ref="menu" v-model="menu_time" :close-on-content-click="false" :nudge-right="40"
        :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable"  v-model="currentField.value.value" :disabled='currentField.disabled'
          :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`"
           :label="`${currentField.label}`"  v-bind="attrs" v-on="on" :rules="required_rule">
          </v-text-field>
        </template>
        <v-time-picker  :disabled='currentField.disabled' @click:minute="$refs.menu.save(time)" format="24hr" color=primary   v-if="menu_time"  v-model="currentField.value.value" full-width>
        </v-time-picker>
      </v-menu>
    </div>
  </div>
</template>


<script>
export default {
  name: "TimePicker",
  props: ["currentField"],
  data() {
    return {
      time: "",
      menu_time:false,

 required_class:"required",
            others:"others",
             read_only:"readonly",
             required_rule:[]
     
    };
  },
  destroyed() {
        this.currentField.value.value = '';
    },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  mounted() {
    if (this.currentField.required == true) {
      this.required_rule = [v => !!v || `${this.currentField.label} is required`]
    }

  }
};
</script>

<style>
.TimePickermain {
  display: flex;
  flex-direction: column;
  height: 85px;
  justify-content: center;
}

.TimePickerfield {
  width: 100%;
  padding: 10px;
}
.required:after {
    content:"*";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
