import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;

const mutations = {
   
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    GetAppointments: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                // let skip = payload.skip;
                // let take = payload.take;
                
                api.get(`/appointments?fromDate=${payload.fromDate}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetMeetings: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = payload.skip;
                let take = payload.take;
                
                api.get(`/asterdialer/meetings?userID=${payload.user}&module=${payload.module}&take=${take}&skip=${skip}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    Popup: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
               
                api.get(`/popup?phone_number=${payload.phone_number}&module=tickets`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
   
    insertAppointment: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/appointment/schedule`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertMeeting: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/asterdialer/meeting/schedule`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deletemeeting: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/meetings/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateMeeting: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                payload.sms_notify ="0"
                payload.wa_notify ="0"
                payload.email_notify ="0"
                payload.api_push ="0"
                api
                    .put(`/meetings/update/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    findlead: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                
                api
                    .get(`/records?phone_number=${payload.phone_number}&template_name=${payload.template}&id=`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    
    mutations,
    actions
};