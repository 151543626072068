<template>
  <div id="recordingReportFilter">
    <div class="datetimefilter">
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>From</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_fromdate"
            :value="filters.fromdate">
          </datetime>


        </div>
      </div>
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>To</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_todate">
          </datetime>
        </div>
      </div>
    </div>
    <div class="row" v-if="false">
    </div>
    <v-autocomplete v-model="appliedfilter.agent" :items="filteragents" label="Agent" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.type" :items="filters.type" label="Call Type" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.campaign" :items="filters.campaign" label="Campaign" persistent-hint small
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-text-field v-model="appliedfilter.uniqueid" :items="filters.uniqueid" label="Uniqueid" persistent-hint small
      multiple single-line :allow-overflow="false">
    </v-text-field>
    <v-text-field v-model="appliedfilter.phonenumber" :items="filters.phonenumber" label="Phone Number" persistent-hint
      small multiple single-line :allow-overflow="false">
    </v-text-field>
    <v-text-field v-model="appliedfilter.station" :items="filters.station" label="Station" persistent-hint small
      multiple single-line :allow-overflow="false">
    </v-text-field>
    <v-autocomplete v-model="appliedfilter.operator" :items="operator" label="Talk time" persistent-hint small single-line
      :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.value" :items="value" label="Values" persistent-hint small single-line
      :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.dispo" :items="filters.dispo_status" label="Disposition" persistent-hint small single-line
      :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.subdispo" :items="filters.subdispo_status" item-text="subdispo"
    item-value="subdispo" label="Sub Disposition" persistent-hint small single-line
    :allow-overflow="false">
  </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
export default {
  props: ["appliedfilter"],
  data() {
    return {
      operator: ['less than', 'equal to', 'greater than'],
 value: ['10 sec', '20 sec','30 sec','40 sec','50 sec','100 sec','150 sec','200 sec','250 sec', '300 sec'],
      page: {
        menu: {
          fromdate: false,
          todate: false
        }
      },
      filter_fromdate: '',
      filter_todate: '',
      filteragents: [],

    }
  },
  watch: {
    appliedfilter: {
      handler: function () {
        if (this.appliedfilter.fromdate == this.filters.fromdate && this.appliedfilter.todate == this.filters.todate) {
          this.filter_fromdate = moment(this.appliedfilter.fromdate).format()
          this.filter_todate = moment(this.appliedfilter.todate).format()
        }
      },
      deep: true
    },
    'filter_fromdate'(newVal) {
      var from_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      this.$store.commit('ACTION_FROMDATE', from_date);
      this.$store.commit('FILTERFROMDATE', from_date);
      this.appliedfilter.f_flag = this.appliedfilter.f_flag + 1
      // this.$emit("getDataFrom");
    },
    'filter_todate'(newVal) {
      var to_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      this.$store.commit('ACTION_TODATE', to_date);
      this.$store.commit('FILTER_TODATE', to_date);
      this.appliedfilter.t_flag = this.appliedfilter.t_flag + 1
      // this.$emit("getDataFrom");
    },
    'appliedfilter.agent'(newVal) {
      this.$store.commit('FILTER_AGENT', { agent: newVal });
      //  this.$emit("getDataFrom");
    },
    'appliedfilter.uniqueid'(newVal) {
      this.$store.commit('FILTER_UNIQUEID', { uniqueid: newVal });
      //  this.$emit("getDataFrom");

    },
    'appliedfilter.type'(newVal) {
      //  var scope = this;
      this.$store.dispatch("type", newVal);
      //  scope.$emit("getDataFrom");

    },
    'appliedfilter.phonenumber'(newVal) {
      this.$store.commit('FILTER_PHONE', { phone: newVal });
      //  this.$emit("getDataFrom");
    },
    "appliedfilter.campaign"(newVal) {
      this.$store.commit("FILTER_CAMPAIGN", { campaign: newVal });
    },
    'appliedfilter.station'(newVal) {
      this.$store.commit('FILTER_STATION', { station: newVal });
      //  this.$emit("getDataFrom");

    },
    'appliedfilter.queue'(newVal) {
      this.$store.commit('FILTER_QUEUE', { queue: newVal });
      //  this.$emit("getDataFrom");
    }
  },
  computed: {
    ...mapGetters([
      "filters",
      "filter"
    ]),
  },
  mounted() {
    this.filteragents = JSON.parse(localStorage.getItem("group_users"));
    this.filter_fromdate = moment(this.filters.fromdate).format();
    this.filter_todate = moment(this.filters.todate).format();
  }
}
</script>
<style>
.datetimefilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

label {
  /*font-weight: bold;*/
  color: #666;
}

.datediv {
  padding: 10px 2px !important;
}

.vdatetime {
  border-bottom: 1px solid #ccc;
}

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected>span>span,
.theme-orange .vdatetime-calendar__month__day--selected:hover>span>span {
  background: #2196f3;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #2196f3;
}
</style>