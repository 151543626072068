<template>
  <div>
  <section-definition
  :title="page.title"
  :extraOptions="page.options"
  :icon="page.icon"
  :breadcrumbs="page.breadcrumbs"
  :addNewData="page.addnew"
  :exportOptions="page.exportOptions"
  @openFilter="filterClick"
  @refreshPage="refreshThisPage"
  @addNew="AddNewRule"
  :style="styleObject"
  >
  </section-definition>
  <loader v-if="page.loading"></loader>
  <v-container v-else fluid grid-list-xl page>
  <v-data-table
  :headers="headers"
  :items="reminderData"
  class="elevation-1 fixed-layout"
  :pagination.sync="pagination"
  :total-items="pagecount"
  :loading="tableLoading"
  :rows-per-page-items="[10]"
  >
  <template v-slot:no-data style="text-align: center">
  <div style="text-align: center">No Data Available</div>
  </template>
  <template v-slot:items="props">
  <td class="checkEmpty p0">{{ props.item.name }}</td>
  <td class="checkEmpty p0">{{ props.item.campaign }}</td>
 
  <td class="checkEmpty p0">
  <template v-if="props.item.status === 'Active'">Active</template>
  <template v-else>Inactive</template>
  </td>
 
  <td class="text-xs-center checkEmpty p0">
  <v-icon
  color="blue"
  small
  class="mr-4"
  v-if="page.canedit"
  @click="editItem(props.item)"
  >edit</v-icon
  >
  <v-icon
  color="red"
  small
  class="mr-4"
  v-if="page.candelete"
  @click="deleteItem(props.item.id)"
  >delete
  </v-icon>
  </td>
  </template>
  </v-data-table>
  </v-container>
  <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
  <v-card>
  <v-toolbar dark color="primary">
  <v-card-title>
  <span class="headline">Delete Remainder Info</span>
  </v-card-title>
  </v-toolbar>
  <v-container grid-list-md>
  <v-layout wrap>
  <v-card-text>Are you Sure to delete this remainder?</v-card-text>
  </v-layout>
  </v-container>
  <v-card-actions>
  <v-spacer></v-spacer>
  <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
  <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
  </v-card-actions>
  </v-card>
  </v-dialog>
  <template>
  <v-dialog
  persistent
  :value="page.isAddPausecode"
  scrollable
  max-width="400px"
  >
  <v-card>
  <v-toolbar dark color="primary" class="modal-header--sticky">
  <v-card-title>
  <span class="headline">Create Reminder</span>
  </v-card-title>
  </v-toolbar>
  <v-card-text>
  <v-form ref="Remainderss" v-model="valid">
  <v-container grid-list-md>
  <v-layout wrap>
  <v-flex xs12>
  <v-text-field
  v-model="addPausecode.payload.name"
  label="Name"
  type="text"
  :rules="[(v) => !!v || 'Name is required']"
  ></v-text-field>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="addPausecode.payload.campaign"
  required
  label="Campaign"
  :items="addPausecode.options.campaign"
  item-text="name"
  @change="template(addPausecode.payload.campaign)"
  :rules="[(v) => !!v || 'Campaign is required']"
  >
  </v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="addPausecode.payload.template"
  required
  label="Template"
  :items="addPausecode.options.campaign"
  item-text="template"
  @change="template(addPausecode.payload.template)"
  :rules="[(v) => !!v || 'Template is required']"
  >
  </v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="addPausecode.payload.disposition"
  required
  label="Disposition"
  :items="addPausecode.options.campaign.filter((campaign) => campaign.name === this.addPausecode.payload.campaign)"
  item-text="disposition"
  @change="template(addPausecode.payload.disposition)"
  :rules="[(v) => !!v || 'Disposition is required']"
  >
  <!-- <template v-slot:prepend-item>
  <v-list-tile ripple @mousedown.prevent @click="toggle">
  <v-list-tile-action>
  <v-icon
  :color="
  addPausecode.payload.disposition.length > 0
  ? 'primary'
  : ''
  "
  >
  {{ icon }}
  </v-icon>
  </v-list-tile-action>
  <v-list-tile-content>
  <v-list-tile-title> All </v-list-tile-title>
  </v-list-tile-content>
  </v-list-tile>
  <v-divider class="mt-2"></v-divider>
  </template> -->
  </v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="addPausecode.payload.time_value"
  required
  label="Time Value"
  :items="addPausecode.options.templates"
  :rules="[(v) => !!v || 'Time Value is required']"
  ></v-select>
  </v-flex>
  <v-flex
  xs12
  v-if="
  addPausecode.payload.time_value !== 'immediate' ||
  addPausecode.payload.time_value == ''
  "
  >
  <v-select
  v-model="addPausecode.payload.reminder_time"
  required
  label="Reminder Time"
  :items="remindetime"
  :rules="[(v) => !!v || 'Reminder Time is required']"
  ></v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="addPausecode.payload.reminder_medium"
  required
  label="Reminder Medium"
  :items="['SMS', 'Email', 'Whatsapp', 'Call']"
  :rules="[(v) => !!v || 'Reminder Medium is required']"
  >
  <template v-slot:append-outer>
  <v-icon color="blue">{{
  ico(addPausecode.payload.reminder_medium)
  }}</v-icon>
  </template>
  </v-select>
  </v-flex>
  <v-flex
  xs12
  v-show="addPausecode.payload.reminder_medium != ''"
  >
  <v-select
  v-if="addPausecode.payload.reminder_medium == 'Call'"
  v-model="addPausecode.payload.medium_template"
  required
  label="Call"
  :items="AudioData.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Call is required']"
  >
  </v-select>
  <v-select
  v-else-if="addPausecode.payload.reminder_medium == 'SMS'"
  v-model="addPausecode.payload.medium_template"
  required
  label="SMS"
  :items="AsterSms.filter((res) => res != null)"
  :rules="[(v) => !!v || 'SMS is required']"
  >
  </v-select>
  <v-select
  v-else-if="
  addPausecode.payload.reminder_medium == 'Whatsapp'
  "
  v-model="addPausecode.payload.medium_template"
  required
  label="Whatsapp"
  :items="AsterWhatsapp.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Whatsapp is required']"
  >
  </v-select>
  <v-select
  v-else-if="
  addPausecode.payload.reminder_medium == 'Email'
  "
  v-model="addPausecode.payload.medium_template"
  required
  label="Email"
  :items="AsterEmail.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Email is required']"
  >
  </v-select>
  </v-flex>
  </v-layout>
  </v-container>
  </v-form>
  </v-card-text>
  <v-card-actions class="modal-footer--sticky">
  <v-spacer></v-spacer>
  <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
  <v-btn
  color="primary"
  :disabled="!valid"
  @click="saveAddPausecode()"
  >Create</v-btn
  >
  </v-card-actions>
  </v-card>
  </v-dialog>
  </template>
  <template>
  <v-dialog
  persistent
  :value="page.isEditPausecode"
  scrollable
  max-width="500px"
  >
  <v-card>
  <v-toolbar dark color="primary">
  <v-card-title>
  <span class="headline">update Reminder</span>
  </v-card-title>
  </v-toolbar>
  <v-card-text>
  <v-form v-model="valid">
  <v-container grid-list-md>
  <v-layout wrap>
  <v-flex xs12>
  <v-text-field
  v-model="editPausecode.payload.name"
  label="Name"
  type="text"
  ></v-text-field>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="editPausecode.payload.campaign"
  required
  label="Campaign"
  :items="addPausecode.options.campaign"
  item-text="name"
  @change="edittemplate(editPausecode.payload.campaign)"
  :rules="[(v) => !!v || 'Campaign is required']"
  >
  </v-select>
  </v-flex>
  {{selectedCampaign}}
  <v-flex xs12>
  <v-text-field
  v-model="editPausecode.payload.template"
  label="Template"
  type="text"
  readonly
  >
  </v-text-field>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="editPausecode.payload.disposition"
  required
  label="Disposition"
  :items="dispositionOptions"
  :rules="[(v) => !!v || 'Disposition is required']"
  multiple
  >
  <template v-slot:prepend-item>
  <v-list-tile
  ripple
  @mousedown.prevent
  @click="toggle_edit"
  >
  <v-list-tile-action>
  <v-icon
  :color="
  editPausecode.payload.disposition.length > 0
  ? 'primary'
  : ''
  "
  >
  {{ icon }}
  </v-icon>
  </v-list-tile-action>
  <v-list-tile-content>
  <v-list-tile-title> All </v-list-tile-title>
  </v-list-tile-content>
  </v-list-tile>
  <v-divider class="mt-2"></v-divider>
  </template>
  </v-select>
  </v-flex>
 
  <v-flex xs12>
  <v-select
  v-model="editPausecode.payload.time_value"
  required
  label="Time Value"
  :items="addPausecode.options.templates"
  :rules="[(v) => !!v || 'Time Value is required']"
  ></v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="editPausecode.payload.status"
  required
  label="Status"
  :items="status"
  :rules="[(v) => !!v || 'Status is required']"
  ></v-select>
  </v-flex>
  <v-flex
  xs12
  v-show="
  editPausecode.payload.time_value !== 'immediate' ||
  editPausecode.payload.time_value == ''
  "
  >
  <v-select
  v-model="editPausecode.payload.reminder_time"
  required
  label="Reminder Time"
  :items="remindetime"
  :rules="[(v) => !!v || 'Reminder Time is required']"
  ></v-select>
  </v-flex>
  <v-flex xs12>
  <v-select
  v-model="editPausecode.payload.reminder_medium"
  required
  label="Reminder Medium"
  :items="['SMS', 'Email', 'Whatsapp', 'Call']"
  :rules="[(v) => !!v || 'Reminder Medium is required']"
  >
  <template v-slot:append-outer>
  <v-icon color="blue">{{
  ico(editPausecode.payload.reminder_medium)
  }}</v-icon>
  </template>
  </v-select>
  </v-flex>
  <v-flex
  xs12
  v-show="editPausecode.payload.reminder_medium != ''"
  >
  <v-select
  v-if="editPausecode.payload.reminder_medium == 'Call'"
  v-model="editPausecode.payload.medium_template"
  required
  label="Call"
  :items="AudioData.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Call is required']"
  >
  </v-select>
  <v-select
  v-else-if="editPausecode.payload.reminder_medium == 'SMS'"
  v-model="editPausecode.payload.medium_template"
  required
  label="SMS"
  :items="AsterSms.filter((res) => res != null)"
  :rules="[(v) => !!v || 'SMS is required']"
  >
  </v-select>
  <v-select
  v-else-if="
  editPausecode.payload.reminder_medium == 'Whatsapp'
  "
  v-model="editPausecode.payload.medium_template"
  required
  label="Whatsapp"
  :items="AsterWhatsapp.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Whatsapp is required']"
  >
  </v-select>
  <v-select
  v-else-if="
  editPausecode.payload.reminder_medium == 'Email'
  "
  v-model="editPausecode.payload.medium_template"
  required
  label="Email"
  :items="AsterEmail.filter((res) => res != null)"
  :rules="[(v) => !!v || 'Email is required']"
  >
  </v-select>
  </v-flex>
  </v-layout>
  </v-container>
  </v-form>
  </v-card-text>
  <v-card-actions class="modal-footer--sticky">
  <v-spacer></v-spacer>
  <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
  <v-btn color="primary" :disabled="!valid" @click="saveEditRules()"
  >Update</v-btn
  >
  </v-card-actions>
  </v-card>
  </v-dialog>
  </template>
  <v-navigation-drawer
  fixed
  disable-resize-watcher
  right
  :value="page.isFilterOpen"
  >
  <v-toolbar small color="secondary" style="color: white">
  <v-toolbar-title>Filter</v-toolbar-title>
  <v-spacer></v-spacer>
  <v-btn icon flat color="white" @click="page.isFilterOpen = false">
  <i class="fas fa-close"></i>
  </v-btn>
  </v-toolbar>
  <div
  class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
  >
  <v-container class="sidenavContainer">
  <div class="autocompleteDiv">
  <!-- <v-select v-model="filter.payload.name" :items="namevalue" label="Name" small></v-select> -->
  <v-autocomplete
  v-model="filter.payload.name"
  :items="namevalue"
  :loading="filter.isLoading"
  :search-input.sync="filter.search"
  label="Name"
  hide-no-data
  hide-selected
  ></v-autocomplete>
  <v-select
  v-model="filter.payload.campaign"
  :items="campaignvalue"
  label="Campaign"
  small
  ></v-select>
  </div>
  </v-container>
  <v-container row class="sidenavFooter">
  <v-btn flat @click="reset()">Reset</v-btn>
  <v-spacer></v-spacer>
  <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
  </v-container>
  </div>
  </v-navigation-drawer>
  <v-snackbar
  v-model="snackbar.show"
  :bottom="true"
  :right="true"
  :timeout="snackbar.timeout"
  >
  {{ snackbar.text }}
  <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
  </v-snackbar>
  <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
  <v-card color="primary" dark>
  <v-card-text>
  Please stand by
  <v-progress-linear
  indeterminate
  color="white"
  class="mb-0"
  ></v-progress-linear>
  </v-card-text>
  </v-card>
  </v-dialog>
  </div>
 </template>
  <script>
 import loader from "@/components/Widgets/loader";
 //import api from "@/utils/axios-util";
 import { mapGetters } from "vuex";
 export default {
  components: {
  loader,
  },
 
  data() {
  return {
  valid: true,
  filtervalue: [],
  AudioData: [],
  AsterSms: [],
  AsterEmail: [],
  AsterWhatsapp: [],
  tenant_code: "",
  styleObject: {
  height: "80px",
  "margin-top": "-8px",
  },
  page: {
  title: "Reminder",
  icon: "fa-clipboard fa-2x",
  loading: false,
  isAddPausecode: false,
  isFilterOpen: false,
  playingRecord: false,
  breadcrumbs: [],
  exportOptions: [],
  options: { filter: true, refresh: true },
  addnew: [],
  isEditPausecode: false,
  temp: { key: "addpausecode", label: "Add PauseCode" },
  candelete: false,
  canedit: false,
  },
 
  pagecount: 0,
  headers: [
  {
  text: "Name",
  align: "left",
  class: "p0",
  sortable: false,
  value: "name",
  },
  {
  text: "Campaign",
  align: "left",
  class: "p0",
  sortable: false,
  value: "description",
  },
  {
  text: "Status",
  align: "left",
  class: "p0",
  sortable: false,
  value: "status",
  },
  {
  text: "Action",
  align: "center",
  sortable: false,
  value: "btn",
  width: "20%",
  },
  ],
  remindetime: [
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "30 minutes",
  "45 minutes",
  "60 minutes",
  "5 minutes",
  "3 hours",
  "6 hours",
  "12 hours",
  "24 hours",
  ],
  reminderData: [],
  //pagecount: 0,
  pagination: {
  rowsPerPage: 10,
  },
  snackbar: {
  show: false,
  timeout: 6000,
  text: "",
  },
  DeleteRowdata: false,
  showRowData: false,
  DataRow: false,
  tableLoading: false,
  crmdomain: "",
  namevalue: [],
  campaignvalue: [],
  selectedCampaign: "",
  dispositionOptions: [],
  filter: {
  payload: {
  name: "",
  campaign: "",
  isLoading: false,
  search: "",
  },
  options: {
  status: ["Active", "Inactive"],
  },
  },
  // pagination: {
  // page: 0,
  // rowsPerPage: 5
  // },
  isProcessing: false,
  call_status: [],
  editPausecode: {
  payload: {
  id: "",
  name: "",
  campaign: "",
  disposition: [],
  template: "",
  time_value: "",
  reminder_time: "",
  status: "",
  reminder_medium: "",
  medium_template: "",
  },
  options: {
  disposition: [],
  },
  },
  addPausecode: {
  valid: false,
  disable: false,
  payload: {
  name: "",
  campaign: "",
  disposition: [],
  template: "",
  time_value: "",
  reminder_time: "",
  reminder_medium: "",
  medium_template: "",
  },
  options: {
  campaign: [],
  disposition: [],
  templates: [],
  status: ["Active", "Inactive"],
  },
  },
  status: ["Active", "Inactive"],
  };
  },
 
  methods: {
  ico(key) {
  switch (key) {
  case "SMS":
  return "sms";
  case "Email":
  return "email";
  case "Whatsapp":
  return "mdi-whatsapp";
  case "Call":
  return "call";
  }
  },
  filterDispo(){
  console.log("5555");
  if (this.selectedCampaign) {
  console.log("1111");
  const selectedCampaign = this.addPausecode.options.campaign.find(
  (campaign) => campaign.name === this.selectedCampaign
  );
 
  if (selectedCampaign) {
  console.log("2222");
  this.dispositionOptions = selectedCampaign.disposition;
  } else {
  console.log("3333");
  this.dispositionOptions = [];
  }
  } else {
  console.log("4444");
  // If no campaign is selected, reset disposition options
  this.dispositionOptions = [];
  }
  },
  toggle() {
  this.$nextTick(() => {
  if (this.addDispostion) {
  this.addPausecode.payload.disposition = [];
  } else {
  this.addPausecode.payload.disposition =
  this.addPausecode.options.disposition.slice();
  }
  });
  },
  toggle_edit() {
  this.$nextTick(() => {
  if (this.editDisposition) {
  this.editPausecode.payload.disposition = [];
  } else {
  this.editPausecode.payload.disposition =
  this.editPausecode.options.disposition.slice();
  }
  });
  },
  refreshThisPage: function () {
  var scope = this;
  scope.page.loading = true;
  this.pagination.page = 1;
  scope.getDataFromApi();
  },
  filterClick: function () {
  this.page.isFilterOpen = true;
  },
 
  filterFieldOptions() {
  var scope = this;
  let data = { model: "reminder", column: ["name"] };
 
  scope.$store
  .dispatch("GetModuleNameList", data)
  .then((response) => {
  scope.tableLoading = false;
  scope.page.loading = false;
  var datas = response.data;
  scope.namevalue = datas[0].name;
  })
  .catch((err) => {
  console.log(err);
  });
  },
  edittemplate(name) {
  var scope = this;
  scope.addPausecode.options.campaign.forEach((res) => {
  if (res.name === name) {
  scope.editPausecode.payload.template = res.template;
  scope.editPausecode.options.disposition = res.disposition;
  }
  });
 
  scope.editPausecode.payload.disposition = "";
  },
  editprint(name) {
  var scope = this;
 
  scope.addPausecode.options.campaign.forEach((res) => {
  if (res.name === name) {
  scope.editPausecode.payload.template = res.template;
  scope.editPausecode.options.disposition = res.disposition;
  }
  });
  },
  reset() {
  var scope = this;
  scope.filter.payload.name = "";
  scope.filter.payload.campaign = "";
  this.getDataFromApi();
  },
  getDataFromApi() {
  var scope = this;
  scope.tableLoading = true;
 
  this.page.isFilterOpen = false;
 
  let take = this.pagination.rowsPerPage;
  let skip;
  if (this.pagination.page === 1) {
  skip = 0;
  } else {
  skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
  }
  //let query = {
  /* skip: skip,
  take: take
  }*/
  scope.filter.payload["skip"] = skip;
  scope.filter.payload["take"] = take;
  scope.skip = skip;
  this.$store
  .dispatch("GetReminder", scope.filter.payload)
 
  .then((response) => {
  this.pagecount = response.count;
  scope.tableLoading = false;
  scope.page.loading = false;
  var datas = response.data;
  if (datas !== "" && datas !== undefined && datas !== null) {
  datas.forEach((element) => {
  scope.namevalue.push(element["name"]);
  scope.campaignvalue.push(element["campaign"]);
  });
  }
  scope.reminderData = datas;
  })
  .catch(function (error) {
  scope.page.isAddPausecode = false;
  scope.showSnackBar("error" + error);
  this.page.isEditPausecode = false;
  });
  },
  AddNewRule: function () {
  //var scope = this;
  this.page.isAddPausecode = true;
  },
  closeEditRules: function () {
  this.page.isEditPausecode = false;
  this.editPausecode.payload.disposition = [];
  },
  closeAddPausecode: function () {
  this.$refs.Remainderss.resetValidation();
  this.$refs.Remainderss.reset();
  this.page.isAddPausecode = false;
  // this.$refs.Remainderss.reset();
  },
  saveEditRules() {
  var scope = this;
  if (scope.editPausecode.payload.time_value == "immediate") {
  scope.editPausecode.payload.reminder_time = "";
  }
 
  scope.$store
  .dispatch("updateRemindercode", scope.editPausecode.payload)
  .then(() => {
  scope.page.isAddPausecode = false;
  scope.showSnackBar("Updated Successfully");
 
  this.getDataFromApi();
  this.page.isEditPausecode = false;
  })
  .catch(function (error) {
  scope.page.isAddPausecode = false;
  scope.showSnackBar("error" + error);
  this.page.isEditPausecode = false;
  });
  this.getDataFromApi();
  },
  saveAddPausecode() {
  var scope = this;
  if (scope.addPausecode.payload.time_value == "immediate") {
  scope.addPausecode.payload.reminder_time = "";
  }
 
  scope.$store
  .dispatch("insertRemindercode", scope.addPausecode.payload)
  .then(() => {
  scope.page.isAddPausecode = false;
  scope.showSnackBar("Created Successfully");
  this.$refs.Remainderss.resetValidation();
  this.$refs.Remainderss.reset();
  // this.$refs.Remainderss.reset();
  scope.addPausecode.disable = false;
  this.getDataFromApi();
  })
  .catch(function (error) {
  scope.page.isAddPausecode = false;
  scope.showSnackBar("error" + error);
  });
  },
  showSnackBar(message) {
  let scope = this;
  scope.snackbar.text = message;
  scope.snackbar.show = true;
  setTimeout(() => {
  scope.snackbar.text = "";
  }, scope.snackbar.timeout);
  },
  deleteItem(id) {
  this.DeleteRowdata = true;
  this.delete_id = id;
  },
  editItem(item) {
  //var scope = this;
  this.page.isEditPausecode = true;
  this.editPausecode.payload.id = item.id;
  this.editPausecode.payload.name = item.name;
  this.editPausecode.payload.campaign = item.campaign;
  this.editPausecode.payload.reminder_time = item.reminder_time;
  this.editPausecode.payload.reminder_medium = item.reminder_medium;
  this.editPausecode.payload.medium_template = item.medium_template;
  this.editPausecode.payload.time_value = item.time_value;
  this.editPausecode.payload.template = item.template;
  this.editPausecode.payload.disposition = item.disposition;
 
  if (item.status === "Active") {
  this.editPausecode.payload.status = "Active";
  } else {
  this.editPausecode.payload.status = "Inactive";
  }
 
  this.editprint(item.campaign);
  },
  savedelete() {
  let scope = this;
  scope.isProcessing = true;
  scope.namevalue = [];
  scope.campaignvalue = [];
  this.$store
  .dispatch("deleteReminder", this.delete_id)
  .then((response) => {
  scope.isProcessing = false;
  if (response.data.message == "success") {
  this.getDataFromApi();
  scope.showSnackBar("Reminder Deleted");
  } else {
  scope.showSnackBar("Error! Unable Delete list");
  }
  })
  .catch(() => {
  scope.isProcessing = false;
  scope.showSnackBar("Error! Unable Delete list");
  });
  this.closedelete();
  },
  closedelete() {
  this.DeleteRowdata = false;
  },
  closeShow() {
  this.showRowData = false;
  },
  },
  computed: {
  ...mapGetters(["CallFlow"]),
  addDispostion() {
  return (
  this.addPausecode.payload.disposition.length ===
  this.addPausecode.options.disposition.length
  );
  },
  unselect() {
  return (
  this.addPausecode.payload.disposition.length > 0 && !this.addDispostion
  );
  },
  icon() {
  if (this.addDispostion) return "mdi-close-box";
  if (this.unselect) return "mdi-minus-box";
  return "mdi-checkbox-blank-outline";
  },
  editDisposition() {
  return (
  this.editPausecode.payload.disposition.length ===
  this.editPausecode.options.disposition.length
  );
  },
  unselect_edit() {
  return (
  this.editPausecode.payload.disposition.length > 0 &&
  !this.editDisposition
  );
  },
  },
  mounted() {
  let scope = this;
  scope.filtervalue = [];
  this.tenant_code = localStorage.getItem("TenantCode");
  this.page.loading = false;
  scope.$store.dispatch("GetCampaign").then((response) => {
  scope.addPausecode.options.campaign = response;
  });
  scope.$store.dispatch("GetTimevalue").then((response) => {
  response.unshift("immediate");
  scope.addPausecode.options.templates = response;
  });
  this.$store.dispatch("GetAudio").then((response) => {
  var datas = response.data.records;
  if (datas !== "" && datas !== undefined && datas !== null) {
  var len = this.tenant_code.length;
  datas.map((element) => {
  scope.filtervalue.push(element["file_name"].substring(len + 1));
  element["file_name"] = element["file_name"].substring(len + 1);
  return element;
  });
  }
 
  scope.AudioData = datas.map((res) => {
  if (res.status == "Active") {
  return res.file_name;
  }
  });
  });
  this.$store.dispatch("GetAsterSms").then((response) => {
  let datas = response.data;
  scope.AsterSms = datas.map((res) => res.name);
  });
  this.$store.dispatch("GetAsterWhatsapp").then((response) => {
  let datas = response.data;
  scope.AsterWhatsapp = datas.map((res) => {
  if (res.status == "Y") {
  return res.name;
  }
  });
  });
  this.$store.dispatch("GetAsterMail").then((response) => {
  var datas = response.data;
  scope.AsterEmail = datas.map((res) => res.name);
  });
  setTimeout(
  function () {
  if (
  scope.$store.getters.UserPermissions.indexOf(
  "configurations:reminder:create"
  ) !== -1
  ) {
  scope.page.addnew = [{ label: "Add Reminder" }];
  }
  if (
  scope.$store.getters.UserPermissions.indexOf(
  "configurations:reminder:edit"
  ) !== -1
  ) {
  scope.page.canedit = true;
  }
  if (
  scope.$store.getters.UserPermissions.indexOf(
  "configurations:reminder:delete"
  ) !== -1
  ) {
  scope.page.candelete = true;
  }
  }.bind(scope),
  1
  );
  const project_config = JSON.parse(localStorage.projectConfig);
  this.crmdomain = project_config.projects[0].config[0].crm_domain;
  this.getDataFromApi();
  this.filterFieldOptions();
  },
  watch: {
  pagination: {
  handler() {
  if (this.pagination.page > 1 || this.skip >= 10) {
  this.getDataFromApi();
  }
  },
  deep: true,
  },
  "filter.search"(val) {
  if (this.page.isFilterOpen) {
  let scope = this;
  let data = { model: "reminder", column: ["name"], search: val };
 
  scope.$store
  .dispatch("GetModuleNameList", data)
  .then((response) => {
  scope.tableLoading = false;
  scope.page.loading = false;
  var datas = response.data;
  scope.namevalue = datas[0].name;
  })
  .catch((err) => {
  console.log(err);
  });
  }
  },
  },
 };
 </script>
  <style>
 .file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
 }
 
 .modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
 }
 
 .modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
 }
 </style>