export default {
  auth0:{
    isLoggedIn : false,
    accessToken :'',
    api_url:'',
    portal_url:'',
    tenant_code:''
  },
  settings:{
    group_id:'',
    permissions:[],
    userList:[]
  }
}