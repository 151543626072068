import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.CallFlow;
var getters = {
  getJSON: state => state.jsonData,
  CallFlow: state => state.CallFlow.data,
  updateJSON: state => state.updateJSON
};
const mutations = {
  [mutation_type.updateJSON]: (state, response) => {
    state.updateJSON = response;
  },
  [mutation_type.CallFlow]: (state, response) => {
    state.CallFlow.data = response;
  },
  [mutation_type.CommitNothing]: () => { }
};
const actions = {
  CallFlow: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        api.get(`/callflow`).then(response => {
          const callflow_response = response.data;
          commit(mutation_type.CallFlow, callflow_response);
          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  postCallFlow: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        
        api
          .post(`/callflow`, payload)
          .then(response => {
            resolve(response);
          })
          .catch(function (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateCallFlow: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .put(`/callflow/${payload.flow_id}`, payload.data)
          .then(response => {
            resolve(response);
          })
          .catch(function (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  audioFileUpload: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .post("/audio/upload", payload, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            commit(mutation_type.CommitNothing, {});
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateJSON: ({ commit }, payload) => {
    commit(mutation_type.updateJSON, payload);
  },
  CheckName: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/callflow/check`, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(function (error) {
          commit(mutation_type.CommitNothing, {});
          reject(error);
        })
    })
  },
  DeleteCallflowRow: ({ commit }, payload) => {
    var deleted_id = {
      id: payload.flow_id
    };

    var deletes = { data: deleted_id };

    return new Promise(resolve => {
      api
        .delete(`/callflow/${payload}`, deletes)
        .then(response => {
          resolve(response);
        })
        .catch(function () {
          commit(mutation_type.CommitNothing, {});
          // reject(error);
        });
    });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
