import config from "@/config/index";
import auth0Variables from '../../utils/auth0-variables';
import axios from '../../utils/axios-util';

import VueJwtDecode from 'vue-jwt-decode'
import {
    AUTH0LOGINSUCCESS,
    AUTH0LOGOUTSUCCESS,
    RESETTOKEN
} from "../mutation-types";
const state = config.auth0;
var variables = auth0Variables();
var token = '';
var permissionInfo = '';

const getters = {
    UserPermissions: state => state.settings.permissions,
}
const mutations = {
    [AUTH0LOGINSUCCESS]: (state, payload) => {
        state.auth0.isLoggedIn = true;
        state.auth0.accessToken = payload.token;
        state.auth0.api_url = payload.api_url;
        state.auth0.portal_url = payload.portal_url;
        state.auth0.tenant_code = payload.tenant_code;
    },
    [AUTH0LOGOUTSUCCESS]: (state) => {
        state.auth0.isLoggedIn = false;
        state.auth0.accessToken = '';
    },
    [RESETTOKEN]: (state, payload) => {
        state.auth0.accessToken = payload;
    }
};
const actions = {
    auth0Login: (_, data) => {
        let payload = {
            password: data.password,
            username: data.email.toLowerCase()
        }
        return new Promise((resolve, reject) => {
            try {
                const regex = /doocti/g;
                const matches = window.location.hostname.match(regex);
                let apiurl = ''
                if (matches !== null && matches[0] === "doocti") {
                    apiurl = variables.common_api_url
                }
                else {
                    apiurl = variables.common_api_url
                    // apiurl='https://api-v2.'+window.location.hostname
                }
                payload.role=1
                axios
                    .post(`${apiurl}/v2/admin/login`, payload)
                    .then(response => {
                        console.log(response.data.access_token,'devtrial_agent002@doocti.com')
                        resolve(response.data.access_token)
                    })

            } catch (err) {
                reject(err)
            }
        })
    },
    auth0Parse: ({ commit, dispatch }, data) => {
        return new Promise((resolve, reject) => {

            var decoded_token = VueJwtDecode.decode(data);
            var tenant_code = decoded_token["http://api.doocti.com/claims/tenant_code"];
            localStorage.setItem("TenantCode", tenant_code);
            localStorage.setItem("editfields", false);
            // var api_url = decoded_token["http://api.doocti.com/claims/api_domain"];
            // var portal_url = decoded_token["http://api.doocti.com/claims/portal_domain"];
            var user_email = decoded_token["http://api.doocti.com/claims/email"];
            var config1 = {
                headers: {
                    Authorization: `Bearer ${data}`,
                },
            };
            const regex = /doocti/g;
            const matches = window.location.hostname.match(regex);
            let apiurl = ''
            if (matches !== null && matches[0] === "doocti") {
                apiurl = variables.common_api_url
            }
            else {
                apiurl = variables.common_api_url
                // apiurl='https://api-v2.'+window.location.hostname
            }
            axios
                .get(`${apiurl}/common/project/config?tenant_code=${tenant_code}&user_email=${user_email}`, config1)
                .then(response => {
                    var apiInfo = {
                        token: data,
                        portal_url: "https://" + response.data.projects[0].config[0].portal_domain,
                        api_url: "https://" + response.data.projects[0].config[0].api_domain,
                        tenant_code: tenant_code,
                        user_email: user_email
                    }
                    localStorage.apiInfo = JSON.stringify(apiInfo);
                    localStorage.projectConfig = JSON.stringify(response.data);
                    localStorage.projectId = response.data.projects[0].id;
                    axios.defaults.baseURL = apiInfo.portal_url;
                    axios.defaults.headers.Authorization = `Bearer ${apiInfo.token}`;
                    commit(AUTH0LOGINSUCCESS, apiInfo);
                    dispatch('getUserPermissions').then(() => {  
                        dispatch('FilterTeam',{user:user_email}).then((res)=>{
                            if(res.status ==  200){
                                let {id, name, type, type_data, campaign }=res.data.data[0]
                                let payload = {
                                    id,
                                    name,
                                    type,
                                    type_data,
                                    campaign:campaign.campaign
                                }
                                localStorage.setItem('Team',JSON.stringify(payload))
                            }else if(res.status ==  204){
                                localStorage.setItem('Team',JSON.stringify({}))
                            }
                            resolve();
                        }).catch((err)=>{
                            console.log(err)
                        })
                    });
                })
                .catch((err) => {
                    reject(err)
                });


        })
    },
    tokencheck: ({ commit }) => {
        token = localStorage.getItem('userToken');
        permissionInfo = JSON.parse(localStorage.getItem('permissionInfo'));
        if (state.auth0.accessToken == '') {
            commit(RESETTOKEN, token);
        }
        if (permissionInfo) {
            commit('ModifyUserPermissions', permissionInfo);
        }
    },
    auth0Logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            try {

                commit(AUTH0LOGOUTSUCCESS);
                localStorage.removeItem("apiInfo");
                localStorage.removeItem("projectConfig");
                localStorage.removeItem("projectId");
                localStorage.removeItem("group_template");
                localStorage.removeItem("group_users");
                localStorage.removeItem("group_queue");
                localStorage.removeItem("group_disposition");
                localStorage.removeItem("editfields");
                localStorage.removeItem("Team");
                resolve();
            } catch (error) {
                reject(error)
            }
        })
    },


};
export default {
    state,
    getters,
    mutations,
    actions
};