

<template>
  <div>
    <div class="grid-item3">
      <liveAgents @toggleSubDrawer="toggle_controll($event)" v-if="view"></liveAgents>
      <liveCalls @toggleSubDrawer="toggle_controll($event)" v-else></liveCalls>
    </div>
  </div>
</template>
<script>
import liveAgents from '../RealTime/Liveagent.vue';
import liveCalls from '../RealTime/LiveCalls.vue';
export default {
  components: {
    liveAgents,
    liveCalls
  },
  data() {
    return {

      view: true,

    }
  },
  methods: {
    toggle_controll(val) {
      if (val == 1) {
        this.view = true;
      } else {
        this.view = false;
      }
    },

  }
};
</script>

<style>
.grid-container {
  margin: 3px;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-rows: 35px;
  grid-gap: 2px;
  /* border-style: groove; */
  border-radius: 10px;
  height: 99%;
}

.grid-item1 {
  /* height: 42px; */
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: flex;
  padding-left: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  overflow: hidden;
}

.grid-item1 div {
  /* border-style: solid; */
  border: 1px solid #cacaca;
  border-radius: 15px;
  height: 35px;
  cursor: pointer;
  width: 50%;
}

h6 {
  width: 42.4vw;
  font-size: 20px;
  font-weight: bold;
  /* padding-bottom: 212px; */
  letter-spacing: 11px;
  color: #9093997d;
  text-align: center;
  margin: 0;
  padding: 3px;
}

.heading {
  display: flex;
  padding-top: 14px;
  padding-bottom: 2px;
}

.heading_icon {
  background-color: rgb(33, 150, 243);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
}

.grid-item3 {
  height: 99.9%;
  border-style: groove;
  border-color: #bdbdbd17;
}

.sideButton1 {
  background-color: rgb(33, 150, 243);
}

.sideButton1 h6 {
  color: aliceblue;
}

.sideButton2 {
  background-color: rgb(189, 189, 189);
}
</style>
  