<template>
    <div class="main-view-cls">
        <div v-if="walogreport.length > 0">
            <div class="main_container_grid">
                <div class="grid1">
                    <div class="header_cls">
                        <div class="profil_pic">
                            <div class="profil_txt">
                                <v-icon>fa fa-commenting fa-2x</v-icon>
                            </div>

                            <div
                                style="display:flex;flex-direction:column ;justify-content: space-between; margin-left: 10px;padding-bottom: 10px;">
                                <div style="display:flex;flex-direction:row">
                                    <div class="v-toolbar__title primary--text">#{{ walogreport[0].msg_from }}</div>

                                </div>
                            </div>
                        </div>
                        <div class="icon_flex">
                            <div>
                                <v-icon color="blue" style="font-size: 25px" @click="download_()"
                                    class="fas fa-download mr-4">
                                </v-icon>
                                <v-icon color="blue" style="font-size: 25px" @click="pushBack()"
                                    class="fas fa-arrow-right mr-4">
                                </v-icon>

                            </div>
                        </div>

                    </div>

                </div>

                <div class="grid2">
                    <div class="titlecls_timeline_div">
                        <div class="titlecls_timeline_history_bottom">
                            <div v-if="walogreport.length > 0">

                                <div style="padding:10px 10px 10px 0px" dense clipped align-top>
                                    <div class="detail-view">
                                        <h2>{{ "Info" }}</h2>
                                        <p>Session Id: {{ walogreport[0].session_id }}</p>
                                        <p>Did Number: {{ walogreport[0].msg_to }}</p>
                                        <p>Agent: {{ walogreport[0].agent }}</p>

                                    </div>

                                </div>
                            </div>
                            <div>
                                <!-- {{ "NO Data Availlable" }} -->
                            </div>

                        </div>
                    </div>
                </div>

                <div class="grid3">
                    <div>
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-card-title>
                                    <span class="headline">Chat Conversation</span>
                                </v-card-title>

                            </v-toolbar>
                            <v-container grid-list-md>

                                <div v-for="(data, i) in walogreport" :key="i" class="chatdiv">
                                    <div v-if="data.msg_direction === `in`">
                                        <div class="attachment">
                                            <div v-if="data.attachment === `image`">
                                                <img :src="data.msg_text" class="small-image" alt="Image">
                                            </div>
                                            <div v-else-if="data.attachment === `audio`">
                                                <audio controls>
                                                    <source :src="data.msg_text" type="audio/mp3">
                                                </audio>
                                            </div>
                                            <div v-else-if="data.attachment === `video`">
                                                <video controls>
                                                    <source src="attachment" type="video/mp4">
                                                </video>
                                            </div>
                                            <div v-else>
                                                {{ data.msg_text }}
                                            </div>

                                            <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">
                                                {{ data.date_time }}
                                            </div>
                                        </div>

                                    </div>
                                    <div v-else class="chat-request">
                                        <div v-if="data.attachment === `image`">
                                            <img :src="data.msg_text" class="small-image" alt="Image">
                                        </div>
                                        <div v-else-if="data.attachment === `audio`">
                                            <audio controls>
                                                <source :src="data.msg_text" type="audio/mp3">
                                            </audio>
                                        </div>
                                        <div v-else-if="data.attachment === `video`">
                                            <video controls>
                                                <source src="attachment" type="video/mp4">
                                            </video>
                                        </div>
                                        <div v-else>
                                            {{ data.msg_text }}
                                        </div>

                                        <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">
                                            {{ data.date_time }}
                                        </div>
                                    </div>
                                </div>
                            </v-container>

                        </v-card>
                    </div>
                </div>
                <v-dialog persistent v-model="DownloadFile" scrollable max-width="470px">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <div class="pop_title">
                                <div>
                                    <div style="font-size:large;font-weight: 600;">Download </div>
                                </div>
                                <div style="padding-top: 3px;">
                                    <v-icon color="light" flat style="font-size:20px"
                                        @click="closedownload">fa-times</v-icon>
                                </div>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-container grid-list-md>
                                <div>

                                    <div class="exportflex">
                                        <div class="export" @click="filedownload('csv')">

                                            <v-icon class="hovericon" size="60">fa fa-file-code-o
                                            </v-icon>
                                            <div style="font-size:20px;color:#2C3E50;font-weight:800;padding:5px">CSV </div>
                                        </div>
                                        <div class="export" @click="filedownload('txt')">
                                            <v-icon class="hovericon" size="60">fa-file-text
                                            </v-icon>
                                            <div style="font-size:20px;color:#2C3E50;font-weight:800;padding:5px">TXT</div>
                                        </div>
                                    </div>

                                </div>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="model-footer--sticky">
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>


        </div>
        <div v-else class="no-data-message">
            No Data Available
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data: () => ({
        walogreport: [],
        isProcessing: true,
        attachment: "",
        DownloadFile: false,
        pagecontrol: {
            export: false,
        }
    }),
    mounted() {
        this.getDataFromApi();
        let scope=this;
       
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "reports:whatsappdetail:export"
                    ) !== -1
                ) {
                    scope.pagecontrol.export = true;
                } 
                
            }.bind(scope),
            1
        );

    },

    methods: {
        download_() {
            this.DownloadFile = true;
        },
        closedownload() {
            this.DownloadFile = false;
        },
        filedownload(value) {
            let skipclumn = ['question','value','deleted','sender_type','channel','custom_value','attachment']
            if (value == 'csv') {
                let csv = ''
                
                let wachtlog = this.walogreport.map((elm)=>{
                    skipclumn.forEach((e)=>{
                       delete elm[e]
                    })

                    return elm
                })
                for (let row = 0; row < wachtlog.length; row++) {

                    let keysAmount = Object.keys(wachtlog[row]).length
                    let keysCounter = 0
                    if (row === 0) {
                        for (let key in wachtlog[row]) {
                            csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        } keysCounter = 0;
                        for (let key in wachtlog[row]) {
                            
                            csv += wachtlog[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        }
                    } else {

                        for (let key in wachtlog[row]) {
                            // if(!skipclumn.includes(key)){
                            csv += wachtlog[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                            // }
                        }
                    }

                    keysCounter = 0
                }


                let link = document.createElement('a')
                link.id = 'download-csv'
                link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
                link.setAttribute("download", this.walogreport[0].session_id + ".csv");

                document.body.appendChild(link)
                link.click();
                this.DownloadFile = false;

            } else if
                (value == "txt") {


                let txtContent = "";
                for (const key in this.walogreport[0]) {
                    if(!skipclumn.includes(key)){
                    let txt_tab = `${key} \t`;
                    txtContent += `${txt_tab}`;
                    }

                }
                this.walogreport.forEach(function (rows) {
                    skipclumn.forEach((elm)=>{
                        delete rows[elm]
                    })

                    let data = Object.values(rows);
                    let row = data.join("\t");
                    txtContent += `\n ${row}` + "\r";

                });
                var encodedUri1 =
                    "data:text/plain;charset=utf-8,\uFEFF" +
                    encodeURIComponent(txtContent);
                var link1 = document.createElement("a");
                link1.setAttribute("href", encodedUri1);
                link1.setAttribute("download", `s.txt`);
                link1.setAttribute("download", this.walogreport[0].session_id + ".txt");
                document.body.appendChild(link1);
                link1.click();
                this.showSnackBar("Download Successfully");
                this.DownloadFile = false;
            }

        },
        pushBack() {
            this.$router.push({
                name: "report/WaChatLogReport",
            });
        },

        getDataFromApi() {
            let obj = JSON.parse(localStorage.chatdetailview);
            let payload = {
                session_id: obj.session_id,

            }

            var scope = this;
            this.$store
                .dispatch("Getchatdetail", payload)
                .then((response) => {
                    var datas = response.data.data;
                    scope.walogreport = datas;
                    for (let j = 0; j < this.walogreport.length; j++) {
                        let element1 = this.walogreport[j];
                        let dateformat = element1.date_time
                        var from_date = moment(dateformat).format('YYYY-MM-DD HH:mm:ss');
                        this.walogreport[j].date_time = from_date
                        this.attachment = this.walogreport[j].msg_text
                    }
                    return this.walogreport
                })
                .catch(function (error) {
                    scope.page.isAddPausecode = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditPausecode = false;
                });
        },
    },


}

</script>

<style scoped >
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");


.no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 20px;
    color: #999;
}

/*********************************************** Download ************************************** */
.pop_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.exportflex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px;

}

.export {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    height: 130px;
    text-align: center;
    padding: 20px;
    background-color: #cacaca;
    color: #ffffff;
    border-radius: 10px;
}

/********************************************** chatconversation******************************* */
.chat-response {
    background-color: rgb(11, 153, 241);
    padding: 10px;
    padding: 10px;
    max-width: 300px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgba(213, 215, 222, 0.27) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    font-size: 12px;
    margin-right: 500px;

}

.attachment {
    background-color: #d7ebea;
    padding: 10px;
    padding: 10px;
    max-width: 315px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgba(213, 215, 222, 0.27) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    font-size: 12px;
    margin-right: 500px;

}

.chat-request {
    background-color: #d7ebea;
    padding: 10px;
    padding: 10px;
    max-width: 315px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    color: black;
    font-size: 12px;
    margin-left: 500px;


}

.chatdiv {
    display: flex;
    flex-direction: column;

}

.cqCDVm {
    text-align: right;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(17, 17, 17, 0.5);
    margin-right: -8px;
    margin-bottom: -4px;
}

.small-image {
    width: 200px;
    height: auto;
}

/**********************************************detaill views  ***********************************/
.detail-view {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
}


p {
    color: #999;
    font-size: 14px;
    margin-bottom: 5px;
}


/*********************************************************************************************** */






.grid1 {
    grid-area: header;
}

.grid2 {
    grid-area: menu;
    height: 500px;
}

.grid3 {
    grid-area: main;
    height: 500px;
    overflow: auto;

    /* width: 1049px;  */
}

.timeline {
    min-width: 240px;
    height: 500px;
    /* padding: 5px; */
    overflow: auto;
}

.header_cls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.profil_pic {
    display: flex;
    flex-direction: row;
}

.main_container_grid {
    display: grid;
    grid-template-areas:
        "header header header header header header"
        "menu main main main main main";
    gap: 10px;

    padding: 10px;
}

.profil_txt {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #ffffff !important;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%),
        0 13px 24px -11px rgb(33 150 243 / 60%);
}

.icon_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px 5px 5px;
}

/***************************************grid*************************************************************/
.titlecls_timeline_div {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}


.titlecls_timeline_history_bottom {
    height: calc(100% - 50px);
    overflow: auto;

}
</style>

