<template>
  <v-card>
    <v-toolbar dark color="primary" small>
      <v-toolbar-title>Export PDF</v-toolbar-title>
    </v-toolbar>
    <v-container class="table-container">
      <div id="pdf" >
        <table id="my-table">
          <thead>
            <tr>
              <th v-for="headers in header" :key="headers.text">{{headers.text}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in pdfdata" :key="'row-'+index">
              <td v-for="(column, i) in row" :key="'row-'+index+'-data-'+i">{{column}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-container>
    <v-container fluid>
      <v-layout>
        <v-spacer></v-spacer>
        <v-btn color="light" flat @click="close">Close</v-btn>
        <v-btn color="success" v-if="this.export" @click="pdfexport()">Export</v-btn>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  export default {
    props:{
      header: {
        required: true
      },
      pdfdata:{
        required: true
      },
      title:{
        required: false,
      },
      size:{
        required: false,
        default: "a4"
      }
    },
    data(){
      return{
       // data1:[data]
       export:'',
      }
    },
    methods:{
      pdfexport(){
        var pageSize= this.size;
        var doc = new jsPDF({
          orientation: 'landscape',
          unit: 'in',
          format: pageSize
        });
        doc.text("Overflow 'ellipsize' with one column with long content", 14, 20);
        doc.autoTable({
          html: '#my-table',
          didDrawPage: function () {
            doc.setFontSize(20);
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            // doc.text("Report", data.settings.margin.left + 15, 22);
          },
        });
        var today = new Date(); 
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        var dateTime = date+' '+time;
        var fileName = this.title+'_'+dateTime;
        doc.save(fileName+".pdf");
      }, 
      close: function(){
        this.$emit("closeModel");
      }
    },
    mounted(){
     
    },
    watch:{
      pdfdata(newval)
      {
         if(newval != '')
         {
           this.export = true;
         }
         else
         {
           this.export = false;
         }
      }
    }
  }
</script>
<style scoped>
  .table-container{
    height: 50vh;
    overflow: auto;
  }
  table { 
    width: 100%; 
    border-collapse: collapse;
    border-radius: 5px;
  }
  table *{
    font-size: 12px !important;
  }
  thead>tr, tbody>tr:last-child{
    border-radius: 5px;
  }
  thead>tr>th:first-child{
    border-radius: 5px 0px 0px 0px;
  }
  thead>tr>th:last-child{
    border-radius: 0px 5px 0px 0px;
  }
  tbody>tr:last-child>td:first-child{
    border-radius: 0px 0px 0px 5px;
  }
  tbody>tr:last-child>td:last-child{
    border-radius: 0px 0px 5px 0px;
  }
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #3498db; 
    color: white; 
    font-weight: bold; 
  }
  td, th { 
    padding: 10px; 
    text-align: left; 
    font-size: 18px;
  }
  @media only screen and (max-width: 760px),(min-device-width: 768px) and (max-device-width: 1024px)  {
    table { 
        width: 100%; 
    }
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr { border: 1px solid #ccc; }
    td {
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;
      padding-left: 50%; 
    }
    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
      content: attr(data-column);
      color: #000;
      font-weight: bold;
    }
  }
</style>