<template>
  <v-toolbar
    :fixed="fixedToolbar"
    v-if="toolbar"
    :class="navToolbarScheme"
    class="toolbar"
    :clipped="false"
    app
    id="main-toolbar"
  >
    <div class="toolbar-content">
      <span class="toolbar-image">
        <img src="@/assets/logowhite.png" />
      </span>
      <div class="toolbar-options">
        <div class="options">
          <v-btn
            color="white"
            class="user"
            flat
            small
            fab
            @click="generateJSON"
          >
            <v-icon>code</v-icon>
          </v-btn>
          <v-btn
            color="white"
            class="user"
            flat
            small
            fab
            @click="generateJSON"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </div>
    <v-menu offset-y>
      <v-avatar slot="activator" size="40" color="#2196f3">
        <span class="white--text text-h2">{{ avatarName }}</span>
      </v-avatar>
      <v-list dense>
        <v-list-tile avatar v-if="false">
          <v-list-tile-avatar>
            <img :src="authUser.avatar" :alt="authUser.name" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title v-text="authUser.name"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="() => {}">
          <v-list-tile-avatar>
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title @click="auth0Logout">Sign Out</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
      </div>
    </div>
    <!-- <div class="toolbar-column">
      <div class="company">
        <img src="@/assets/logowhite.png" />
        <div>
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn flat dark segmented v-on="on" class="user">
                Admin
                <v-icon right color="#fff" style="color: #fff !important;"
                  >keyboard_arrow_down</v-icon
                >
              </v-btn>
            </template>
            <v-list dense dark>
              <v-list-tile @click="logout">
                <v-list-tile-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="product">
        <div class="new">
          <v-subheader style="color: #4c6280;font-size: 23px;">
            Flows
          </v-subheader>
        </div>
        <div class="current">
          <ul id="breadcrumb">
            <li>
              <a href="#"><v-icon>home</v-icon></a>
            </li>
            <li><a href="#">Projects</a></li>
            <li><a href="#">Flows</a></li>
            <li><a href="#">Flow 1</a></li>
          </ul>
          <div class="options">
            <v-btn
              color="primary"
              class="user"
              flat
              small
              fab
              @click="generateJSON"
            >
              <v-icon>code</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="user"
              flat
              small
              fab
              @click="generateJSON"
            >
              <v-icon>save</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div> -->
  </v-toolbar>
</template>
<script>
import { authUser } from "@/data/dummyData";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      title: "Callflow",
      avatarName: "",
    };
  },
  mounted() {
    const username = JSON.parse(localStorage.getItem("projectConfig"));
    const firstLetter = username.projects[0].userInfo[0].user_name;
    this.avatarName = firstLetter.charAt(0).toUpperCase();
  },
  computed: {
    ...mapGetters({
      navDrawer: "navDrawer",
      toolbarClippedLeft: "toolbarClippedLeft",
      fixedToolbar: "fixedToolbar",
      toolbar: "toolbarVisibility",
      navToolbarScheme: "navToolbarScheme",
      navMiniVarient: "navMiniVarient",
    }),
    authUser() {
      return authUser;
    },
    getBlob() {
      const json = this.$store.state.jsonData;
      let contentType = "";
      let dData = "";
      let blob;
      let url;
      contentType = "application/json";
      dData = JSON.stringify(json, null, 2);
      blob = new Blob([dData], { type: contentType });
      url = window.URL.createObjectURL(blob);
      return url;
    },
  },
  methods: {
    toggleMiniVariantMode() {
      this.$store.dispatch("toggleMiniVariantMode");
      this.$store.dispatch("toggleMiniVarient");
    },
    generateJSON() {
      this.$emit("generateJSON");
    },
    auth0Logout: function () {
      var scope = this;
      scope.$store.dispatch("auth0Logout").then(function () {
        scope.$router.push({
          name: "doocti/Auth/Login",
        });
      });
    },
    logout() {},
  },
};
</script>
<style></style>
