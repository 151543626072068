<template>
  <div>
    <highcharts class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
  </div>
</template>
<script>
export default {
  props: {
    successCall: {
      required: false,
      default: 0
    },
    failureCall: {
      required: false,
      default: 0
    },
    waitingCall: {
      required: false,
      default: 0
    },
    RingingCall: {
      required: false,
      default: 0
    }
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }]
    };
  },
  computed: {
    chartOptions() {
      var options = {
        chart: {
          type: "bar",
          width: 250,
          height: 70
        },
        title: {
          text: ""
        },
        legend: {
          enabled: false,
          align: "right",
          verticalAlign: "middle",
          itemStyle: {
            fontSize: "9px",
            fontWeight: "normal"
          }
        },
        xAxis: {
          categories: ["Calls"],
          labels: {
            enabled: false
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        },
        series: [
          {
            name: "Waiting",
            data: [this.waitingCall],
            stack: "type"
          },
          {
            name: "Failure",
            data: [this.failureCall],
            stack: "status"
          },
          {
            name: "Success",
            data: [this.successCall],
            stack: "status"
          },
          {
            name: "Ringing",
            data: [this.RingingCall],
            stack: "type"
          }
        ]
      };
      return options;
    }
  }
};
</script>