<template>
  <div class="container1">
    <div class="header">
      <div class="header_txt">{{ data[0].name }}</div>
    </div>
    <div class="main_container">
      <div class="sub_container1">
        <div class="sub_container1_header">
          <div v-if="data[0].type === 'Chart'" class="containerflex">
            <div class="sub_container1_title">
              {{ chartTypes }}
            </div>

            <div class="sub_container1_icon">
              <i
                style="font-size: 20px; color: red"
                class="fa fa-pencil"
                aria-hidden="true"
                @click="dialog = true"
              ></i>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title> Chart Type </v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="chartTypes"
                      :items="chart_types"
                      label="A Select List"
                      item-text="name"
                      item-value="value"
                    >
                    </v-select>
                  </v-card-text>
                  <v-card-actions class="buttoncls">
                    <v-btn color="primary" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn color="primary" text @click="chartshift">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div class="chart">
            <charts
              v-if="data[0].type === 'Chart'"
              :applyfilter="checkbox"
              :chart="data"
              :toolbarTrigger="false"
            >
            </charts>
            <charts
              v-else
              class="chart_fix"
              :applyfilter="checkbox"
              :chart="data"
              :toolbarTrigger="false"
            >
            </charts>
          </div>
        </div>
      </div>
      <div class="sub_container2">
        <div class="sub_container2_header">
          <div>Filter</div>
        </div>
        <div class="filter_container">
          <div
            v-if="filter_data.length <= 0"
            class="filter_controll_main nodataclass"
          >
            <div style="text-align: center; z-index: 100">
              No Filter Applied!
            </div>
          </div>
          <div v-else class="filter_controll_main">
            <div v-for="(item, index) in filter_data" :key="'A' + index">
              <div class="filterDateContainer">
                <div v-if="item.option" class="filterData">
                  {{ item.option }}
                </div>
                <div class="filterData">{{ item.column_name }}</div>
                <div class="filterData">{{ item.temp_operation }}</div>
              </div>
              <div
                class="filter_controll"
                v-if="
                  item.temp_operation !== 'IS NULL' &&
                  item.temp_operation !== 'IS NOT NULL'
                "
              >
                <v-text-field
                  v-if="
                    (item.type === 'int' ||
                      item.type === 'tinyint' ||
                      item.type === 'smallint' ||
                      item.type === 'mediumint' ||
                      item.type === 'bigint' ||
                      item.type === 'enum') &&
                    item.temp_operation !== 'IS NULL' &&
                    item.temp_operation !== 'IS NOT NULL'
                  "
                  required
                  v-model="item.column_value"
                  label="Enter Number"
                  hint="enter numbers with comma"
                ></v-text-field>
                <v-text-field
                  required
                  v-model="item.column_value"
                  label=" Search"
                  v-else-if="
                    item.type === 'varchar' &&
                    item.temp_operation === 'LIKE %...%'
                  "
                ></v-text-field>
                <multicheck
                  v-else-if="
                    item.type === 'varchar' &&
                    (item.temp_operation === 'IN' ||
                      item.temp_operation === 'NOT IN')
                  "
                  @updatemultiselect="multiselect_function($event, index)"
                  :apidata="item.field_value"
                  :column_name="item.column_name"
                  :multiselectdata="item.column_value"
                >
                </multicheck>

                <v-select
                  v-else-if="
                    item.type === 'varchar' &&
                    item.temp_operation !== 'IS NULL' &&
                    item.temp_operation !== 'IS NOT NULL'
                  "
                  :items="item.field_value"
                  label="Select Value"
                  v-model="item.column_value"
                  @change="selectvalueFunction(item.column_value, index)"
                >
                </v-select>

                <datepicker
                  :columnValue="item.column_value"
                  v-if="
                    (item.type === 'datetime' || item.type === 'date') &&
                    (item.temp_operation === 'On' ||
                      item.temp_operation === 'After' ||
                      item.temp_operation === 'LIKE' ||
                      item.temp_operation === 'LIKE %...%' ||
                      item.temp_operation === 'NOT LIKE' ||
                      item.temp_operation === 'Equal' ||
                      item.temp_operation === 'Greater Than' ||
                      item.temp_operation === 'Lesser Than' ||
                      item.temp_operation === 'Greater Than And Equal' ||
                      item.temp_operation === 'Lesser Than Equal' ||
                      item.temp_operation === 'Not Equal')
                  "
                  @datetransfer="dategetter_edit($event, index)"
                ></datepicker>
                <range
                  :start="item.start"
                  :end="item.end"
                  v-else-if="
                    (item.type === 'datetime' || item.type === 'date') &&
                    item.temp_operation === 'BETWEEN'
                  "
                  @childdate="rangedate_function_edit($event, index)"
                ></range>
                <v-text-field
                  v-model="item.column_value"
                  label="Date"
                  v-else-if="item.type === 'datetime' || item.type === 'date'"
                ></v-text-field>
                <!-- </v-select> -->

                <v-text-field
                  v-if="item.type === 'time'"
                  label="Enter time"
                  v-model="item.column_value"
                  hint="Give Values in HH:MM:SS"
                ></v-text-field>
              </div>
            </div>
            <div class="checkbox">
              <div v-if="filter_data.length > 0">
                <v-checkbox
                  color="primary"
                  v-model="checkbox"
                  hide-details
                ></v-checkbox>
              </div>
              <div class="checkBoxText">*Save filter permanently</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_Button">
        <v-btn color="light" flat @click="close(false)">Cancel</v-btn>
        <v-btn color="primary" @click="apply">Apply</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import charts from "../apexcharts/apexcharts-config.vue";
import range from "@/components/range_filter";
import datepicker from "@/components/date-picker";
import multicheck from "@/components/multiselect_checkbox";
export default {
  components: {
    charts,
    multicheck,
    range,
    datepicker,
  },
  data() {
    return {
      index_value: null,
      tempdata: null,
      checkbox: false,
      dialog: false,
      chartTypes: this.data[0].chart_type,
      datetype: [
        "Today",
        "Yesterday",
        "This Week",
        "Last 7 days",
        "This Month",
        "This Year",
        "On",
        "After",
        "BETWEEN",
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IN",
        "NOT IN",
        "IS NULL",
        "IS NOT NULL",
        "BETWEEN", //varchar
        "NOT BETWEEN", //varachar
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      varechar_operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "IN",
        "NOT IN",
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      options: ["All", "Any"],
      apply_pass: 44,
    };
  },
  watch: {
    indexs() {
      this.index_value = this.indexs;
    },
  },

  props: {
    indexs: {
      type: Number,
    },
    chart_types: {
      type: Array,
    },
    data: {
      type: Array,
    },
    widget_types: {
      type: Array,
    },
    filter_data: {
      type: Array,
    },
    xaxis_columns: {
      type: Array,
    },
    filter_value: {
      type: Array,
    },
  },

  methods: {
    // mounted() {
    //

    // },
    apply() {
      localStorage.setItem("apply_pass", JSON.stringify({}));

      let filter_type;

      if (this.checkbox) {
        filter_type = 1;
      } else {
        filter_type = 0;
      }
      this.apply_pass = filter_type;

      let get = { filter_type: filter_type, filter_metrics: this.filter_data };
      localStorage.setItem("apply_pass", JSON.stringify(get));

      this.$emit("apply");
    },

    selectvalueFunction(val, index) {
      this.filter_data[index].column_value = val;
      this.data[0].filter_metrics[index].column_value = val;
    },
    dategetter_edit(val, index) {
      this.dategetter(val, index);
    },
    dategetter(val, index) {
      if (index === 0 || index > 0) {
        this.filter_data[index].column_value = val;
        this.data[0].filter_metrics[index].column_value = val;
      }
    },
    rangedate_function_edit(val, index) {
      this.rangedate_function(val, index);
    },
    rangedate_function(val, index) {
      // this.filter_data.column_value = val.dbdata;
      // this.filter_data.start = val.display_start_date;
      // this.filter_data.end = val.display_end_date;
      // this.rangedate = val;
      if (index === 0 || index > 0) {
        this.filter_data[index].column_value = val.dbdata;
        this.filter_data[index].start = val.display_start_date;
        this.filter_data[index].end = val.display_end_date;
        this.data[0].filter_metrics[index].column_value = val.dbdata;
        this.data[0].filter_metrics[index].start = val.display_start_date;
        this.data[0].filter_metrics[index].end = val.display_end_date;
      }
    },
    multiselect_function(multiselect, index) {
      this.filter_data[index].column_value = multiselect;
      this.data[0].filter_metrics[index].column_value = multiselect;
    },
    close(val) {
      localStorage.setItem("apply_pass", JSON.stringify({}));
      this.$emit("close", val);
    },
    chartshift() {
      this.dialog = false;
      this.$store
        .dispatch("rect/updateChart", {
          widget_id: this.data[0].widget_id,
          chart_type: this.chartTypes,
          component: this.chartTypes,
        })
        .then(() => {
          const payload = {
            id: this.data[0].widget_id,
            skip: 0,
            take: 20,
          };
          this.$store.dispatch("rect/renderWidgetData", payload).then(() => {
            this.data[0].chart_type = this.chartTypes;
          });
        });
    },
  },
};
</script>
<style>
.chart_fix {
  height: 350px;
  /* overflow: hidden; */
}
.filterDateContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.filterData {
  background-color: #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
  border-radius: 25px;
  margin: 4px;
  text-align: center;
  padding: 5px;
  width: 30%;
}

.nodataclass {
  position: absolute;
  top: 50%;
  left: 78%;
}

.checkBoxText {
  margin-top: 21px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.filter_container {
  margin: 10px;
  width: 100%;
  max-height: 380px;
  overflow: hidden;
  overflow-y: scroll;
}

.filter_controll_main {
  display: flex;
  flex-direction: column;
}

.buttoncls {
  display: flex;
  justify-content: space-between;
}

.chart {
  height: 400px;
}

.sub_container1_icon {
  font-size: larger;
}

.sub_container1_title {
  width: 90%;
  text-align: center;
  font-size: larger;
}

.sub_container2_header {
  background-color: #2196f3;
  height: 30px;
  text-align: center;
  color: #ffffff;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.containerflex {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.sub_container1_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: right;
  /* padding-top: 10px; */
}

.main_container {
  display: flex;
  margin-top: 5px;
  height: 420px;
}

.sub_container1 {
  width: 70%;
  margin-right: 5px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.sub_container2 {
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 30%;
}

.header {
  background-color: #2196f3;
  height: 50px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  /* box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
        rgb(33, 150, 243, 0.08) 0px 0px 0px 1px; */
}

.container1 {
  max-height: 800px;
  background-color: #ffffff;
  overflow-x: hidden;
  padding: 5px;
}

.header_txt {
  color: #ffffff;
  text-align: center;
  padding: 5px;

  font-size: x-large;
}

.footer_Button {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.button1 {
  background-color: #2196f3;
  height: 40px;
  border-radius: 5px;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
}

.button2 {
  background-color: #2196f3;
  border-radius: 5px;
  height: 40px;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>