<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"></section-definition>
    <v-card style="margin: 20px">
      <v-container fluid="true">
        <div class="toplayer">
          <div>
            <div class="titlecls title">REPORT BUILDER WIDGET EDIT</div>
          </div>
          <toplayer ref="childref" @layeroneDatePass="passdata($event)" @tablevalue="tabledata($event)"
            @layeroneDate="trigger($event)" :xaxis_columns_="xaxis_columns" :yaxis_columns_="yaxis_columns"
            :edit_arr="edit_arr"></toplayer>
        </div>
        <div class="layertwo" v-if="
          (create.data_table && create.widget_type == 'Chart') ||
          (create.data_table &&
            create.widget_type == 'Table' &&
            create.chart_type)
        ">
          <div>
            <div class="titlecls title">{{ title_str }} CONFIGURATION</div>
          </div>
          <div style="margin-top: 25px" class="layertwomaincontainer">
            <layertwo ref="childlayertwo" @filter_button_validator="filter_button_validators($event)"
              @summarize_edited_save="summarize_edited_save" @sort_edited_save="sort_edited_save"
              @edit_filter="edit_filter" @filter_edited_save="filter_edited_save" @edit_summarize="edit_summarize"
              @function_group="function_group" @function_filter="function_filter"
              @function_summarize="function_summarize" @edit_sort="edit_sort" @function_sort="function_sort"
              :sorted="sorted" :filters="filters" :metrics="metrics" :sort_editable_icon="sort_editable_icon"
              :edit_icon="edit_icon" :Summarize_edit_icon="Summarize_edit_icon" :filter_edit="filter_edit"
              :sort_edit="sort_edit" :summarize_edit="summarize_edit" :widget_type="create.widget_type"
              :table_type="create.chart_type"></layertwo>
            <viewLayer :sort_area="sort_area" :group_area="group_area" :filter_area="filter_area"
              :widgetType="create.widget_type" :function_area="function_area" :table_type="create.chart_type">
            </viewLayer>
          </div>
        </div>
        <div class="filters_class" v-if="
          summarize_function ||
          summarize_filter ||
          summarize_sort ||
          filter_edit ||
          sort_edit ||
          summarize_group ||
          summarize_edit
        ">
          <div>
            <div class="titlecls" v-if="summarize_function || summarize_edit">
              SUMMARIZE SETUP
            </div>
            <div class="titlecls" v-if="summarize_filter || filter_edit">
              FILTER SETUP
            </div>
            <div class="titlecls" v-if="summarize_sort">SORT SETUP</div>
            <div class="titlecls" v-if="summarize_group">GROUP SETUP</div>
          </div>
          <addSummarize @summarize_save="summarize_save" @function_cancel="function_cancel"
            @summarize_data_pass="summarize_assign_function($event)" @reseticon="reseticon"
            :xaxis_columns="xaxis_columns" :data_table="create.data_table" :function_types="function_types"
            :summarize_function="summarize_function">
          </addSummarize>
          <v-space></v-space>
          <addfilter  :filter_button_validator = "filter_button_validator" @multiselect_function="multiselect_function($event)" @dategetter="dategetter($event)"
            @rangedate_function="rangedate_function($event)" @filter_cancel="filter_cancel" @filter_save="filter_save"
            @filterValuePasser="filterValuePasser($event)" @operation_assign="operation_assign($event)"
            @datecreater="datecreater($event)" @type_assign="type_assign($event)" @reseticon="reseticon"
            :datetype="datetype" :type="filter_metrics.type" :date_shower="date_shower" :uniqueChars="uniqueChars"
            :operations="operations" :varechar_operations="varechar_operations" :data_table="create.data_table"
            :xaxis_columns="xaxis_columns" :filters="filters" :summarize_filter="summarize_filter" :options="options">
          </addfilter>
          <addsort @sort_save="sort_save" @sort_cancel="sort_cancel" @sortDataPasser="sortDataPasser($event)"
            @reseticon="reseticon" :orders="orders" :data_table="create.data_table" :summarize_sort="summarize_sort"
            :xaxis_columns="xaxis_columns"></addsort>
          <addGroup @group_save="group_save" @group_cancel="group_cancel" @groupDataPass="groupDataPass($event)"
            @reseticon="reseticon" :xaxis_columns="xaxis_columns" :data_table="create.data_table"
            :summarize_group="summarize_group">
          </addGroup>
          <addSummarize v-if="summarize_edit" :function_types="function_types"
            @summarize_delete="summarize_delete($event)" :summarize_edit="summarize_edit" :metrics="metrics"
            :xaxis_columns="xaxis_columns" :data_table="create.data_table"></addSummarize>
          <addfilter v-if="filter_edit" @filter_delete="filter_delete($event)"
            @type_assign_edit="type_assign_edit($event)" :filter_edit="filter_edit"
            @multiselect_function="multiselect_function($event)" @dategetter_edit="dategetter_edit($event)"
            @rangedate_function_edit="rangedate_function_edit($event)" @operation_assign="operation_assign($event)"
            @datecreater_edit="datecreater_edit($event)" @reseticon="reseticon" :datetype="datetype"
            :type="filter_metrics.type" :date_shower="date_shower" :uniqueChars="uniqueChars" :operations="operations"
            :varechar_operations="varechar_operations" :data_table="create.data_table" :xaxis_columns="xaxis_columns"
            :filters="filters" :edit_icon="edit_icon" :summarize_filter="summarize_filter" :options="options">
          </addfilter>
          <addsort v-if="sort_edit" :sorted="sorted" :sort_edit="sort_edit" @sort_delete="sort_delete($event)"
            @reseticon="reseticon" :orders="orders" :data_table="create.data_table" :summarize_sort="summarize_sort"
            :xaxis_columns="xaxis_columns"></addsort>
        </div>
      </v-container>

      <v-card-actions>
        <div style="display: flex">
          <v-checkbox style="margin-top: 20px" color="primary" v-model="create.isShowOnDashboard" persistent-hint>
          </v-checkbox>
          <div style="margin-top: 25px">Show On Dashboard</div>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="light" flat @click="goback">Cancel</v-btn>
        <v-btn color="primary" @click="validation">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import layertwo from "@/components/reportBuilders/layerTwo";
import toplayer from "@/components/reportBuilders/layerOne";
import viewLayer from "@/components/reportBuilders/view_layer";
import addSummarize from "@/components/reportBuilders/add-summarize.vue";
import addfilter from "@/components/reportBuilders/add-filter.vue";
import addsort from "@/components/reportBuilders/add-sort.vue";
import addGroup from "@/components/reportBuilders/add-group.vue";
export default {
  components: {
    viewLayer,
    toplayer,
    layertwo,
    addSummarize,
    addfilter,
    addsort,
    addGroup,
  },
  data() {
    return {
      filter_button_validator : false,
      title_str: "",
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      page: {
        title: "Edit Widget",
        icon: "fa-edit fa-2x",
      },
      edit_icon: true,
      Summarize_edit_icon: true,
      sort_editable_icon: true,
      propsdata: [],
      isActive: false,
      datatype: "",
      uniqueChars1: [],
      uniqueChars: [],
      typeArr : [],
      datetype: [
        "Today",
        "Yesterday",
        "This Week",
        "Last 7 days",
        "This Month",
        "This Year",
        "On",
        "BETWEEN",
        "NOT BETWEEN",
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      //table_names: [],
      seconds: [],
      widget_types: ["Chart", "Table"],
      chart_types: ["Zoom Time", "polar Area", "pie", "Lable line", "Basic Line", "Basic Bar", "Group Bar", "Stacked Bar", "Column", "Column Group", "donut", "Gradient Radial", "Multiple Radial"],
      xaxis_columns: [],
      yaxis_columns: [],
      create: {
        widget_name: "",
        data_table: "",
        widget_type: "",
        refresh_rate_sec: "",
        isShowOnDashboard: false,
        row_limit: "",
        xaxis_column: "",
        yaxis_column: "",
        chart_type: "",
        column: "",
        max_data_per_page: 0,
        isactive: false,
      },

      summarize_function: false,
      summarize_edit: false,
      function_types: [
        "select",
        "sum_of",
        "count_of",
        "average_of",
        "min_of",
        "max_of"
      ],
      function_metrics: {
        function_type: "",
        column_name: "",
        alias_name: "",
      },
      metrics: [],
      function_area: "",
      summarize_filter: false,
      operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IN",
        "NOT IN",
        "IS NULL",
        "IS NOT NULL",
        "BETWEEN", //varchar
        "NOT BETWEEN", //varachar
        "Equal",
        "Greater Than",
        "Lesser Than",
        "Greater Than And Equal",
        "Lesser Than Equal",
        "Not Equal",
      ],
      varechar_operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IS NULL",
        "IS NOT NULL",
        "IN",
        "NOT IN",
        "Equal",
        // "Greater Than",
        // "Lesser Than",
        // "Greater Than And Equal",
        // "Lesser Than Equal",
        "Not Equal",
      ],
      options: ["AND", "OR"],
      filter_metrics: {
        column_name: "",
        operation: "",
        option: "",
        column_value: "",
        type: "",
        date_picker: "",
        field_value: [],
        start: "",
        end: "",
        temp_operation: "",
      },
      edit_arr: [],
      filters: [],
      filter_area: "",
      filter_edit: false,
      summarize_sort: false,
      orders: ["ASC", "DESC"],
      sort: {
        column_name: "",
        order: "",
      },
      sorted: [],
      sort_area: "",
      sort_edit: false,
      summarize_group: false,
      group: "",
      group_area: "",
      rangedate: "",
      date_getters: "",
      date_shower: "",
      multiselect: "",
      operator_input: "",
      updatedWidget: {},
      str: "",
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  mounted() {
    var scope = this;
    var editWidget = JSON.parse(localStorage.getItem("editWidget"));
    var widgetTable = JSON.parse(localStorage.getItem("widgetTable"));

    scope.create.widget_id = editWidget.id;
    scope.widget_types = widgetTable.widget_types;
    scope.chart_types = widgetTable.chart_types;
    // scope.table_names = widgetTable.tables;
    scope.create.widget_name = editWidget.name;

    scope.tabledata(editWidget.data_table)

    scope.getColumns(editWidget.data_table);

    scope.create.widget_type = editWidget.type;
    scope.create.xaxis_column = editWidget.xaxis_column;
    scope.create.yaxis_column = editWidget.yaxis_column;
    scope.create.chart_type = editWidget.chart_type;
    scope.create.isShowOnDashboard = editWidget.isShowOnDashboard;
    scope.create.row_limit = editWidget.row_limit;
    scope.create.refresh_rate_sec = editWidget.timer / 1000;
    scope.updatedWidget = { ...editWidget };
    scope.group_area = editWidget.group_by;
    scope.group = editWidget.group_by.split(",");
    scope.sort_area = editWidget.order_by;
    let temp = editWidget.order_by.split(", ");
    // scope.sort_editable_icon = scope.group_area.length > 0 ? true : false;
    if (scope.sort_area.length > 0) {
      temp.forEach((element) => {
        let ex = element.split(" ");
        let sorting = {
          column_name: ex[0],
          order: ex[1],
        };
        scope.sorted.push(sorting);
      });
    }

    for (let i = 5; i <= 90; i++) {
      if (i % 5 == 0) {
        scope.seconds.push(i);
      }
    }
    for (let j = 0; j < editWidget.filter_metrics.length; j++) {

      let obj = {
        field_name: editWidget.filter_metrics[j].column_name,
        table_name: editWidget.data_table,
      };

      let arr = [];
      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        response.forEach((element) => {
          arr.push(element[`${editWidget.filter_metrics[j].column_name}`]);
        });
      });

      let filt = {
        column_name: editWidget.filter_metrics[j].column_name,
        operation: editWidget.filter_metrics[j].operation,
        column_value: editWidget.filter_metrics[j].column_value,
        option: editWidget.filter_metrics[j].option,
        type: editWidget.filter_metrics[j].type,
        date_picker: editWidget.filter_metrics[j].date_picker,
        // field_value: editWidget.filter_metrics[j].field_value_str.split(","),
        field_value: arr,
        start: editWidget.filter_metrics[j].start,
        end: editWidget.filter_metrics[j].end,
        temp_operation: editWidget.filter_metrics[j].temp_operation,
      };

      if (editWidget.filter_metrics[j].option == "") {
        scope.filter_area +=

          editWidget.filter_metrics[j].column_name +
          " " +
          editWidget.filter_metrics[j].operation +
          " " +
          editWidget.filter_metrics[j].column_value;
      } else {
        scope.filter_area += editWidget.filter_metrics[j].option + " " +
          editWidget.filter_metrics[j].column_name +
          " " +
          editWidget.filter_metrics[j].operation +
          " " +
          editWidget.filter_metrics[j].column_value;
      }

      if (j < editWidget.filter_metrics.length - 1) {
        scope.filter_area += ",";
      }
      scope.filters.push(filt);
    }
    for (let k = 0; k < editWidget.function_metrics.length; k++) {
      let func = {
        column_name: editWidget.function_metrics[k].column_name,
        function_type: editWidget.function_metrics[k].function_type,
        alias_name: editWidget.function_metrics[k].alias_name,
      };
      scope.function_area +=
        editWidget.function_metrics[k].function_type +
        " " +
        editWidget.function_metrics[k].column_name;
      if (k < editWidget.function_metrics.length - 1) {
        scope.function_area += ",";
      }
      scope.metrics.push(func);
    }
    scope.create.isactive = true;
    scope.edit_arr.push(this.create);
  },
  methods: {
    filter_button_validators(val) {
      this.filter_button_validator = val
    },
    groupDataPass(val) {
      this.group = val;
    },
    sortDataPasser(val) {
      let split_value = val.split(",");
      this.sort[`${split_value[0]}`] = split_value[1];
    },
    filterValuePasser(val) {
      let split_value = val.split(",");
      this.filter_metrics[`${split_value[0]}`] = split_value[1];
    },
    summarize_assign_function(val) {
      let split_val = val.split(",");
      this.function_metrics[`${split_val[0]}`] = split_val[1];
    },
    operation_assign(val) {
      if (val === "Equal") {
        this.filter_metrics.temp_operation = "Equal";
        this.filter_metrics.operation = "=";
      } else if (val === "Greater Than") {
        this.filter_metrics.temp_operation = "Greater Than";
        this.filter_metrics.operation = ">";
      } else if (val === "Lesser Than") {
        this.filter_metrics.temp_operation = "Lesser Than";
        this.filter_metrics.operation = "<";
      } else if (val === "Greater Than And Equal") {
        this.filter_metrics.temp_operation = "Greater Than And Equal";
        this.filter_metrics.operation = ">=";
      } else if (val === "Lesser Than Equal") {
        this.filter_metrics.temp_operation = "Lesser Than Equal";
        this.filter_metrics.operation = "<=";
      } else if (val === "Not Equal") {
        this.filter_metrics.temp_operation = "Not Equal";
        this.filter_metrics.operation = "!=";
      } else if (val === "IS NOT NULL") {
        this.filter_metrics.temp_operation = "IS NOT NULL";
        this.filter_metrics.operation = "IS NOT NULL";
      } else if (val === "IS NULL") {
        this.filter_metrics.temp_operation = "IS NULL";
        this.filter_metrics.operation = "IS NULL";
      } else if (val === "NOT LIKE") {
        this.filter_metrics.temp_operation = "NOT LIKE";
        this.filter_metrics.operation = "NOT LIKE";
      } else if (val === "LIKE %...%") {
        this.filter_metrics.temp_operation = "LIKE %...%";
        this.filter_metrics.operation = "LIKE %...%";
      } else if (val === "LIKE") {
        this.filter_metrics.temp_operation = "LIKE";
        this.filter_metrics.operation = "LIKE";
      } else if (val === "IN") {
        this.filter_metrics.temp_operation = "IN";
        this.filter_metrics.operation = "IN";
      } else if (val === "NOT IN") {
        this.filter_metrics.temp_operation = "NOT IN";
        this.filter_metrics.operation = "NOT IN";
      } else if (val === "Today") {
        this.filter_metrics.temp_operation = "Today";
        this.filter_metrics.operation = "=";
      } else if (val === "Yesterday") {
        this.filter_metrics.temp_operation = "Yesterday";
        this.filter_metrics.operation = "=";
      } else if (val === "This Month") {
        this.filter_metrics.temp_operation = "This Month";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "Last 7 days") {
        this.filter_metrics.temp_operation = "Last 7 days";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "This Week") {
        this.filter_metrics.temp_operation = "This Week";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "This Year") {
        this.filter_metrics.temp_operation = "This Year";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "On") {
        this.filter_metrics.temp_operation = "On";
        this.filter_metrics.operation = "=";
      } else if (val === "After") {
        this.filter_metrics.temp_operation = "After";
        this.filter_metrics.operation = ">";
      } else if (val === "BETWEEN") {
        this.filter_metrics.temp_operation = "BETWEEN";
        this.filter_metrics.operation = "BETWEEN";
      } else if (val === "NOT BETWEEN") {
        this.filter_metrics.temp_operation = "NOT BETWEEN";
        this.filter_metrics.operation = "NOT BETWEEN";
      }
    },
    multiselect_function(multiselect) {
      this.multiselect = multiselect;
      this.filter_metrics.column_value = multiselect;
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    formatDate(date) {
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      date = yyyy + "-" + mm + "-" + dd;
      return date;
    },
    datecreater_edit(val) {
      let splitdata = val.split(",");
      this.datecreater(splitdata[0], splitdata[1]);
    },
    datecreater(operater_val, index) {
      if (operater_val) {
        this.operation_assign(operater_val);
      }
      if (
        operater_val === "Today" ||
        operater_val === "Yesterday" ||
        operater_val === "This Week" ||
        operater_val === "This Month" ||
        operater_val === "Last 7 days" ||
        operater_val === "This Year"
      ) {
        if (operater_val === "Today") {
          let today = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = today;
          if (index >= 0) {
            this.filters[index].column_value = today;
          }

          this.filter_metrics.column_value = today;
        } else if (operater_val === "Yesterday") {
          let Yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
            .toISOString()
            .substr(0, 10);
          this.date_shower = Yesterday;
          if (index >= 0) {
            this.filters[index].column_value = Yesterday;
          }
          this.filter_metrics.column_value = Yesterday;
        } else if (operater_val === "This Week") {
          let monday = this.getMonday(new Date()).toISOString().substr(0, 10);
          let today = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${monday} - ${today}`;
          if (index >= 0) {
            this.filters[index].column_value = `${monday} , ${today}`;
          }
          this.filter_metrics.column_value = `${monday} , ${today}`;
        } else if (operater_val === "Last 7 days") {
          var today = new Date();
          let sixDaysAgo = new Date(today);
          sixDaysAgo.setDate(today.getDate() - 6);
          var Last_7_days = this.formatDate(sixDaysAgo);
          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);

          this.date_shower = `${Last_7_days} - ${today_}`;
          if (index >= 0) {
            this.filters[index].column_value = `${Last_7_days} , ${today_}`;
          }
          this.filter_metrics.column_value = `${Last_7_days} , ${today_}`;
        } else if (operater_val === "This Year") {
          var currentYear = new Date().getFullYear();

          let currentYear_start = `${currentYear}-01-01`;
          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${currentYear_start} - ${today_}`;
          if (index >= 0) {
            this.filters[
              index
            ].column_value = `${currentYear_start} , ${today_}`;
          }
          this.filter_metrics.column_value = `${currentYear_start} , ${today_}`;
        } else if (operater_val === "This Month") {
          var currentYear_ = new Date().getFullYear();
          var currentmonth = new Date().getMonth() + 1;
          let current_month_start;
          if (currentmonth < 10) {
            current_month_start = `${currentYear_}-0${currentmonth}-01`;
          } else {
            current_month_start = `${currentYear_}-${currentmonth}-01`;
          }

          let today_ = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          this.date_shower = `${current_month_start} - ${today_}`;
          if (index >= 0) {
            this.filters[
              index
            ].column_value = `${current_month_start} , ${today_}`;
          }
          this.filter_metrics.column_value = `${current_month_start} , ${today_}`;
        }
      }
    },
    dategetter_edit(val) {
      let splitdata = val.split(",");
      this.dategetter(splitdata[0], splitdata[1]);
    },
    dategetter(val, index) {
      this.filter_metrics.column_value = val;
      this.date_getters = val;
      if (index === 0 || index > 0) {
        this.filters[index].column_value = val;
      }
    },
    rangedate_function_edit(val) {
      let splitdata = val.split(",");
      this.rangedate_function(splitdata[0], splitdata[1]);
    },
    rangedate_function(val, index) {
      this.filter_metrics.column_value = val.dbdata;
      this.filter_metrics.start = val.display_start_date;
      this.filter_metrics.end = val.display_end_date;
      this.rangedate = val;
      if (index === 0 || index > 0) {
        this.filters[index].column_value = val.dbdata;
        this.filters[index].start = val.display_start_date;
        this.filters[index].end = val.display_end_date;
      }
    },
    reseticon() {
      this.$refs.childlayertwo.icon_changer("reset");
    },
    type_assign_edit(value) {
      let splitvalue = value.split(".");
      let val = splitvalue[0];
      let index = splitvalue[1];
      this.filters[index].column_value = "";
      let obj = {
        field_name: val,
        table_name: this.create.data_table,
      };
      let arr = [];
      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        response.forEach((element) => {
          arr.push(element[`${val}`]);
        });
      });
      this.filters[index].field_value = arr;
      for (let i = 0; i < this.xaxis_columns.length; i++) {
        if (this.xaxis_columns[i].value === val) {
          this.filters[index].type = this.xaxis_columns[i].type;
          if (this.filters[index].type === "varchar") {
            this.datatype = "varchar";
          } else {
            this.datatype = "";
          }
        }
        if (
          this.xaxis_columns[i].type !== "datetime" ||
          this.xaxis_columns[i].type !== "date"
        ) {
          this.filter_metrics.date_picker = "";
        }
      }
    },
    type_assign(val) {
      for (let i = 0; i < this.xaxis_columns.length; i++) {
        if (this.xaxis_columns[i].value === val) {
          this.filter_metrics.type = this.xaxis_columns[i].type;
          if (this.filter_metrics.type === "varchar") {
            this.datatype = "varchar";
          } else {
            this.datatype = "";
          }
        }
        if (
          this.xaxis_columns[i].type !== "datetime" ||
          this.xaxis_columns[i].type !== "date"
        ) {
          this.filter_metrics.date_picker = "";
        }
      }

      if (this.datatype === "varchar") {
        this.varcharedata(val);
      }
    },
    varcharedata(val) {
      let obj = {
        field_name: val,
        table_name: this.create.data_table,
      };
      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        let arr = [];
        response.forEach((element) => {
          arr.push(element[`${val}`]);
        });
        this.uniqueChars = [...new Set(arr)];
        this.filter_metrics.field_value = [...new Set(arr)];
      });
    },
    passdata(data) {
      let splitArr = data.split(".");
      this.create[`${splitArr[0]}`] = splitArr[1];
      if (splitArr[1] == "Consolidate_Report" ||
        splitArr[1] == "Detail_Report" ||
        (this.create.data_table !== "" && splitArr[1] == "Chart")
      ) {
        //  this.create.chart_type=""
        this.create.xaxis_column = "";
        this.create.yaxis_column = "";
        this.metrics = []
        this.function_area = ""
        this.$refs.childref.getempy(`${splitArr[1]}`);

      } else if ((this.create.data_table !== "" && splitArr[1] == "Table")) {
        this.create.chart_type = ""
        this.create.xaxis_column = "";
        this.create.yaxis_column = "";
        this.$refs.childref.getempy(`${splitArr[1]}`);

      }
    },
    getColumns(table) {
      const scope = this;

      scope.$store.dispatch("rect/fetchTableColumn", table).then((response) => {
        response.map((res)=>{
          this.typeArr.push(res)
        })
        
        scope.yaxis_columns = response;
        scope.xaxis_columns = response;
      });
    },
    trigger(val) {
      let scope = this;
      scope.yaxis_columns = [];
      scope.xaxis_columns = [];
      scope.filter_area = "";
      scope.function_area = "";
      scope.sort_area = "";
      scope.group_area = "";
      scope.metrics = [];
      scope.filters = [];
      scope.sorted = [];
      scope.summarize_edit = false;
      scope.filter_edit = false;
      scope.sort_edit = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.option = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.type = "";
      scope.filter_metrics.date_picker = "";
      scope.filter_metrics.field_value = [];
      (scope.filter_metrics.start = ""), (scope.filter_metrics.end = "");

      scope.yaxis_columns = val[0];
      scope.xaxis_columns = val[0];
    },
    goback() {
      const scope = this;
      scope.$router.push({
        name: "builder/Widgets",
      });
    },
    function_summarize() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_function = true;
    },
    function_filter() {
      var scope = this;
      scope.date_shower = "";
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_filter = true;
    },
    function_sort() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_group = false;
      scope.summarize_edit = false;
      scope.summarize_sort = true;
    },
    function_group() {
      var scope = this;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.summarize_filter = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.summarize_function = false;
      scope.summarize_sort = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_edit = false;
      scope.summarize_group = true;
    },
    sort_cancel() {
      var scope = this;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
    },
    sort_save() {
      var scope = this;
      scope.sorted.push(JSON.parse(JSON.stringify(scope.sort)));
      if (scope.sort_area !== "") scope.sort_area += ", ";
      scope.sort_area += scope.sort.column_name + " " + scope.sort.order;
      scope.summarize_sort = false;
      scope.sort_editable_icon = true;
      scope.sort.column_name = "";
      scope.sort.order = "";
    },
    group_cancel() {
      var scope = this;
      scope.group = "";
      scope.group_area = "";
      scope.summarize_group = false;
    },
    group_save() {
      var scope = this;
      scope.group_area = "";
      if (scope.group_area !== "") scope.group_area += ", ";
      scope.group_area += JSON.parse(JSON.stringify(scope.group));
      scope.summarize_group = false;
    },
    summarize_save() {
      var scope = this;

      scope.metrics.push(JSON.parse(JSON.stringify(scope.function_metrics)));
      if (scope.function_area !== "") scope.function_area += ", ";
      scope.function_area +=
        scope.metrics[scope.metrics.length - 1].function_type.replace(
          "_",
          " "
        ) +
        " " +
        scope.metrics[scope.metrics.length - 1].column_name;

      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_function = false;
    },
    function_cancel() {
      var scope = this;
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
    },
    filter_cancel() {
      var scope = this;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
    },
    filter_save() {
      this.propsdata = [];
      this.props_funstion();
      var scope = this;
      let filter_value = scope.filter_metrics;
      let data = {
        column_name: filter_value.column_name,
        operation: filter_value.operation,
        column_value: filter_value.column_value,
        option: filter_value.option,
        type: filter_value.type,
        date_picker: filter_value.date_picker,
        field_value: filter_value.field_value,
        start: filter_value.start,
        end: filter_value.end,
        temp_operation: filter_value.temp_operation,
      };
      scope.filters.push(JSON.parse(JSON.stringify(data)));
      if (scope.filter_area !== "") scope.filter_area += ", ";
      scope.filter_area +=
        scope.filters[scope.filters.length - 1].option +
        " " +
        scope.filters[scope.filters.length - 1].column_name +
        " " +
        scope.filters[scope.filters.length - 1].operation +
        " " +
        scope.filters[scope.filters.length - 1].column_value;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.filter_metrics.date_picker = "";
      scope.filter_metrics.type = "";
      scope.filter_metrics.field_value = [];

      scope.summarize_filter = false;
      this.operator_input = "";
    },
    edit_summarize() {
      ///2
      var scope = this;
      scope.Summarize_edit_icon = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.filter_edit = false;
      scope.sort_edit = false;
      if (scope.function_area !== "" && scope.function_area) {
        scope.summarize_edit = true;
      }
    },
    summarize_edited_save() {
      var scope = this;
      scope.function_area = "";
      scope.Summarize_edit_icon = true;
      for (let i = 0; i < scope.metrics.length; i++) {
        scope.function_area +=
          scope.metrics[i].function_type.replace("_", " ") +
          " " +
          scope.metrics[i].column_name;
        if (i < scope.metrics.length - 1) {
          scope.function_area += ",";
        }
      }
      scope.summarize_edit = false;
    },
    summarize_delete(values) {
      var scope = this;
      let remain_elm = [];
      scope.function_area = "";
      for (let i = 0; i < scope.metrics.length; i++) {
        ///111
        if (scope.metrics.length === 1) {
          scope.Summarize_edit_icon = true;
        }
        let remove_elm = [];
        if (
          scope.metrics[i].column_name === values.column_name &&
          scope.metrics[i].function_type === values.function_type &&
          scope.metrics[i].alias_name === values.alias_name
        ) {
          remove_elm.push(scope.metrics[i]);
        } else {
          remain_elm.push(scope.metrics[i]);
          if (scope.function_area !== "") scope.function_area += ", ";
          scope.function_area +=
            scope.metrics[i].function_type.replace("_", " ") + " " +
            scope.metrics[i].column_name;
        }
      }
      scope.metrics = [];
      scope.metrics = [...remain_elm];
      if (scope.metrics.length <= 0) {
        scope.summarize_edit = false;
        this.$refs.childlayertwo.icon_changer("reset");
      }
    },
    edit_filter() {
      ///2
      var scope = this;
      scope.edit_icon = false;
      scope.isActive = true;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.sort_edit = false;
      if (scope.filter_area !== "" && scope.filter_area) {
        scope.filter_edit = true;
      }
    },

    props_funstion() {
      for (let i = 0; i < this.filters.length; i++) {
        const element = this.filters[i];
        if (element.type === "datetime" || element.type === "date") {
          if (element.operation === "BETWEEN") {
            this.propsdata.push(element.column_value.split(","));
          }
           if (element.operation === "NOT BETWEEN") {
            this.propsdata.push(element.column_value.split(","));
          }
        }
      }
    },
    filter_edited_save() {
      var scope = this;
      scope.edit_icon = true;
      scope.filter_area = "";
      for (let i = 0; i < scope.filters.length; i++) {
        let val = scope.filters[i].temp_operation;
        if (val === "Equal") {
          scope.filters[i].operation = "=";
        } else if (val === "Greater Than") {
          scope.filters[i].operation = ">";
        } else if (val === "Lesser Than") {
          scope.filters[i].operation = "<";
        } else if (val === "Greater Than And Equal") {
          scope.filters[i].operation = ">=";
        } else if (val === "Lesser Than Equal") {
          scope.filters[i].operation = "<=";
        } else if (val === "Not Equal") {
          scope.filters[i].operation = "!=";
        } else if (val === "IS NOT NULL") {
          scope.filters[i].operation = "IS NOT NULL";
        } else if (val === "IS NULL") {
          scope.filters[i].operation = "IS NULL";
        } else if (val === "NOT LIKE") {
          scope.filters[i].operation = "NOT LIKE";
        } else if (val === "LIKE %...%") {
          scope.filters[i].operation = "LIKE %...%";
        } else if (val === "LIKE") {
          scope.filters[i].operation = "LIKE";
        } else if (val === "IN") {
          scope.filters[i].operation = "IN";
        } else if (val === "NOT IN") {
          scope.filters[i].operation = "NOT IN";
        } else if (val === "Today") {
          scope.filters[i].operation = "=";
        } else if (val === "Yesterday") {
          scope.filters[i].operation = "=";
        } else if (val === "This Month") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "Last 7 days") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "This Week") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "This Year") {
          scope.filters[i].operation = "BETWEEN";
        } else if (val === "On") {
          scope.filters[i].operation = "=";
        } else if (val === "After") {
          scope.filters[i].operation = ">";
        } else if (val === "BETWEEN") {
          scope.filters[i].operation = "BETWEEN";
        }
        else if (val === "NOT BETWEEN") {
          scope.filters[i].operation = "NOT BETWEEN";
        }
        if (
          scope.filters[i].operation === "IS NULL" ||
          scope.filters[i].operation === "IS NOT NULL"
        ) {
          scope.filters[i].column_value = "";
        }
        scope.filter_area +=
          scope.filters[i].option +
          " " +
          scope.filters[i].column_name +
          " " +
          scope.filters[i].operation +
          " " +
          scope.filters[i].column_value;
        if (i < scope.filters.length - 1) {
          scope.filter_area += ",";
        }
      }
      scope.filter_edit = false;
    },
    filter_delete(values) {
      ///111
      var scope = this;
      let remain_elm = [];
      let count = 0;
      scope.filter_area = "";
      for (let i = 0; i < scope.filters.length; i++) {
        let remove_elm = [];
        if (scope.filters.length === 1) {
          scope.edit_icon = true;
        }
        if (
          scope.filters[i].column_name === values.column_name &&
          scope.filters[i].operation === values.operation &&
          scope.filters[i].column_value === values.column_value
        ) {
          remove_elm.push(scope.filters[i]);
        } else {
          remain_elm.push(scope.filters[i]);
          if (count > 0) {
            if (scope.filter_area !== "") scope.filter_area += ", ";
            scope.filter_area +=
              scope.filters[i].option != ""
                ? scope.filters[i].column_name +
                " " +
                scope.filters[i].operation +
                " " +
                scope.filters[i].column_value
                : scope.filters[i].option +
                " " +
                scope.filters[i].column_name +
                " " +
                scope.filters[i].operation +
                " " +
                scope.filters[i].column_value;
          } else {
            if (scope.filter_area !== "") scope.filter_area += ", ";
            scope.filter_area +=
              scope.filters[i].option != ""
                ? scope.filters[i].column_name +
                " " +
                scope.filters[i].operation +
                " " +
                scope.filters[i].column_value
                : scope.filters[i].option +
                " " +
                scope.filters[i].column_name +
                " " +
                scope.filters[i].operation +
                " " +
                scope.filters[i].column_value;
            count++;
          }
        }
      }
      scope.filters = [];
      scope.filters = [...remain_elm];

      if (scope.filters.length <= 0) {
        scope.filter_edit = false;
        this.$refs.childlayertwo.icon_changer("reset");

      }
    },
    edit_sort() {
      var scope = this;
      scope.sort_editable_icon = false;
      scope.sort.column_name = "";
      scope.sort.order = "";
      scope.summarize_sort = false;
      scope.group = "";
      scope.summarize_group = false;
      scope.summarize_filter = false;
      scope.filter_metrics.column_name = "";
      scope.filter_metrics.operation = "";
      scope.filter_metrics.column_value = "";
      scope.filter_metrics.option = "";
      scope.summarize_function = false;
      scope.function_metrics.function_type = "";
      scope.function_metrics.column_name = "";
      scope.function_metrics.alias_name = "";
      scope.summarize_edit = false;
      scope.filter_edit = false;
      if (scope.sort_area !== "" && scope.sort_area) {
        scope.sort_edit = true;
      }
    },
    sort_edited_save() {
      var scope = this;
      scope.sort_area = "";
      scope.sort_editable_icon = true;
      for (let i = 0; i < scope.sorted.length; i++) {
        scope.sort_area +=
          scope.sorted[i].column_name + " " + scope.sorted[i].order;
        if (i < scope.sorted.length - 1) {
          scope.sort_area += ",";
        }
      }
      scope.sort_edit = false;
    },
    sort_delete(values) {
      var scope = this;
      let remain_elm = [];
      for (let i = 0; i < scope.sorted.length; i++) {
        let remove_elm = [];
        if (scope.sorted.length === 1) {
          scope.sort_editable_icon = true;
        }
        if (
          scope.sorted[i].column_name === values.column_name &&
          scope.sorted[i].order === values.order
        ) {
          remove_elm.push(scope.sorted[i]);
        } else {
          remain_elm.push(scope.sorted[i]);
        }
      }

      let del_val = `${values.column_name} ${values.order}`;
      let temp_str = scope.sort_area.split(", ");
      let removed_val = temp_str.filter((elm) => elm != del_val);

      scope.sort_area = "";
      for (let i = 0; i < removed_val.length; i++) {
        const elm = removed_val[i];
        if (i > 0) {
          scope.sort_area += `, ${elm}`;
        } else {
          scope.sort_area += `${elm}`;
        }
      }
      scope.sorted = [];
      scope.sorted = [...remain_elm];
      if (scope.sorted.length <= 0) {
        scope.sort_edit = false;
        this.$refs.childlayertwo.icon_changer("reset");
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },

    tabledata(val) {
      let scope = this
      scope.create.data_table = val;
      scope.title_str = val.replace(/_/g, " ").toUpperCase();
      if (scope.create.widget_type == "Chart" && scope.create.yaxis_column !== "" && scope.create.xaxis_column !== "") {
        scope.create.xaxis_column = ""
        scope.create.yaxis_column = ""
        this.$refs.childref.getempy("Chart");
      }
    },
    validation() {
      let Isvalid = this.$refs.childref.validate();
      if (Isvalid == 4 || Isvalid == 7) {
        if (
          this.create.widget_type == "Table" &&
          this.create.chart_type == "Consolidate_Report" &&
          this.metrics.length == 0
        ) {
          this.showSnackBar("Summarize required");
        } else {
          this.saveWidget();
        }
      }
    },
    saveWidget() {
      const scope = this;
      let filter_arr = [];
      if (this.filters.length > 0) {
        for (let i = 0; i < this.filters.length; i++) {
          for (let j = 0; j < this.filters[i].field_value.length; j++) {
            if (j === 0) {
              this.str += this.filters[i].field_value[j];
            } else {
              this.str += "," + this.filters[i].field_value[j];
            }
          }

          const element = this.filters[i];
          let obj = {
            column_name: element.column_name,
            operation: element.operation,
            option: element.option,
            column_value: element.column_value,
            type: element.type,
            date_picker: element.date_picker,
            field_value_str: this.str,
            start: element.start,
            end: element.end,
            temp_operation: element.temp_operation,
          };
          filter_arr.push(obj);
          this.str = "";
        }
      }
      if (scope.metrics.length > 0) {
        for (let i = 0; i < scope.metrics.length; i++) {
          scope.metrics[i].column_order = i + 1;
        }
      }
      const payload = {
        id: scope.updatedWidget.id,
        name: scope.create.widget_name,
        data_table:
          scope.create.data_table === ""
            ? "aster_agent_performance"
            : scope.create.data_table,
        type: scope.create.widget_type,
        xaxis_column: scope.create.xaxis_column,
        yaxis_column: scope.create.yaxis_column,
        is_shown_on_dashboard: scope.create.isShowOnDashboard,
        chart_type: scope.create.chart_type,
        row_limit: scope.create.row_limit,
        active: scope.updatedWidget.active,
        is_draggable: false,
        is_resizeable: false,
        width: scope.updatedWidget.width,
        height: scope.updatedWidget.height,
        min_width: scope.updatedWidget.minw,
        min_height: scope.updatedWidget.minh,
        position_top: scope.updatedWidget.top,
        position_left: scope.updatedWidget.left,
        zindex: scope.updatedWidget.zIndex,
        axis: scope.updatedWidget.axis,
        parent_limit: scope.updatedWidget.parentLim,
        snap_to_grid: scope.updatedWidget.snapToGrid,
        aspect_ratio: scope.updatedWidget.aspectRatio,
        color: scope.updatedWidget.color,
        component: scope.updatedWidget.component,
        class: scope.updatedWidget.class,
        refresh_rate: parseInt(scope.create.refresh_rate_sec) * 1000,
        backgroun_colors: scope.updatedWidget.backgroun_colors,
        labels: scope.updatedWidget.labels,
        function_metrics: scope.metrics,
        filter_metrics: filter_arr,
        group_by: scope.group_area,
        order_by: scope.sort_area,
      };

      if (scope.metrics.length <= 0) {
        delete payload.function_metrics;
      }


      if (this.create.isShowOnDashboard === true) {
        scope.$store.dispatch("rect/changeWidget", [payload.id])
      } else {
        this.$store.dispatch("rect/removeWidget", payload)
      }

      scope.$store
        .dispatch("rect/saveWidget", payload)
        .then(() => {
          scope.showSnackBar("Widget updated Succesfully");
          scope.goback();
        })
        .catch((err) => {
          scope.showSnackBar("error :" + err.message);
        });


    },
  },
};
</script>

<style>
.filters_class {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}

.Sub_containers:hover {
  box-shadow: rgba(33, 150, 243, 0.3) 0px 0px 0px 2px;
}


.layertwo {
  margin-top: 15px;
  padding: 15px;
  height: auto;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
  transition: 10s;
  transition-timing-function: ease;
}

.toplayer {
  padding-top: 15px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}

.mydiv {
  background-color: #edf2ef;
}

.titlecls {
  font-size: 20px;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
  padding: 10px;
  padding-top: 0px;
  text-align: center;
}

.titlecls {
  position: relative;
  color: #0087ca;
}

.titlecls:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
