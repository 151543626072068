<template>
  <div> 
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="meeting_data" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
    
          <td class="checkEmpty p0">{{ props.item.meeting_name }}</td>
          <td class="checkEmpty p0">{{ props.item.ownerID.split("@")[0] }}</td>
          <td class="checkEmpty p0">{{ props.item.leadNumber }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td class="checkEmpty p0">{{ props.item.scheduledTime }}</td>
          <td class="text-xs-center checkEmpty p0">
           
            <v-icon color="blue" small class="mr-4" v-if="page.canedit&&datetime(props.item.scheduledTime)" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.meetingID)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Meetings</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are your Sure to delete this meeting?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isaddmeetings" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create Meetings</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="DisportionMain" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                     <v-select v-model="addmeetings.payload.name " required label="Meeting title" :items="this.meeting_title" :rules="[(v) => !!v || 'Meeting title is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="addmeetings.payload.meeting_sub_title "  label="Meeting Sub title" :items="this.meeting_sub_title" ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-if="addmeetings.payload.name =='others'" v-model="addmeetings.payload.manual_title" required label="Title" type="text" :rules="[(v) => !!v || 'Title is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="addmeetings.payload.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field @change="phone_number" v-model="addmeetings.payload.phone_number" required
                      label="Phone Number" type="text" :rules="[(v) => !!v || 'Phone_number is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Agent is required']" :items="agents" required label="Agent"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.agent"
                      persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <label class="sheduleddate">Meeting Date</label>
                    <datetime   class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                      v-model="addmeetings.payload.sheduleddate" label="test">
                    </datetime>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Module is required']" :items="module" required label="Module"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.module"
                      persistent-hint></v-select>

                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.module === 'lead'">
                    <v-select :rules="[(v) => !!v || 'Template is required']" :items="template" required label="Template"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.template"
                      persistent-hint></v-select>

                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddMeetings()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveMeetings()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog persistent :value="page.isEditDisposition" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Update Meetings</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                   <v-flex xs12>
                     <v-select v-if="this.editmeetings.name" v-model="editmeetings.name" required label="Meeting title" :items="this.meeting_title" :rules="[(v) => !!v || 'Meeting title is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-if="editmeetings.name !== 'others'"  v-model="editmeetings.meeting_sub_title"  label="Meeting Sub title" :items="this.meeting_sub_title" ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-if="editmeetings.name === 'others'" v-model="editmeetings.manual_title" required label="Title" type="text" :rules="[(v) => !!v || 'Title is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="editmeetings.comments" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-text-field @change="phone_number" v-model="editmeetings.phoneNumber" required
                      label="Phone Number" type="text" :rules="[(v) => !!v || 'Phone_number is required']"></v-text-field>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Agent is required']" :items="agents" required label="Agent"
                      item-text="name" item-value="value" return-string v-model="editmeetings.user"
                      persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <label class="sheduleddate">Meeting Date</label>
                    <datetime  class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                      v-model="editmeetings.scheduleDate" label="test">
                    </datetime>
                  </v-flex>

                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select :items="agents" required label="Agent" item-text="name" item-value="value" return-string
              v-model="filter.payload.user" persistent-hint></v-select>
            <v-select v-model="filter.payload.module" label="Module" :items="filtermodule"></v-select>

          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
     
      req_id: [],
      valid: true,
      template: JSON.parse(localStorage.getItem("group_template")),
      module: ['lead'],
      agents: JSON.parse(localStorage.getItem("group_users")),
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Meetings",
        icon: "fa-clipboard fa-2x",
        loading: false,
        isaddmeetings: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditDisposition: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      titledata:[],
      pagecount: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "meeting_name",
        },
        {
          text: "Agent",
          align: "left",
          class: "p0",
          sortable: false,
          value: "ownerID",
        },
        {
          text: "Phone Number",
          align: "left",
          class: "p0",
          sortable: false,
          value: "leadNumber",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        }, {
          text: "scheduledTime",
          align: "left",
          class: "p0",
          sortable: false,
          value: "scheduledTime",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      meeting_data: [],
      meeting_title:[],
      meeting_sub_title:[],
      other_title_field:false,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          module: "", user: ""
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      editmeetings: {
        user: "",
        name: "",
        scheduleDate: "",
        manual_title:"",
        comments:"",
        id: "",
      meeting_sub_title : "" 
      },
      addmeetings: {
        valid: false,
        menu2: false,
        menu1: false,
        payload: {
          name: "",
          phone_number: "",
          description: "",
          module: "",
          agent: "", sheduleddate: "",meeting_sub_title:""
        },

        options: {
          status: ["Active", "Inactive"],
        },
      },
      filtermodule: ["lead", "contact", "ticket"],
    };
  },
  methods: {

    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.module = ""
      scope.filter.payload.user = ""
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
    },

    reset() {
      var scope = this;
      scope.filter.payload.module = ""
      scope.filter.payload.user = ""
      scope.addmeetings.payload.manual_title = ""
      scope.addmeetings.payload.meeting_sub_title = "" 
      scope.addmeetings.payload.name = ""
      scope.editmeetings.name = ""
      scope.editmeetings.manual_title = ""
      scope.editmeetings.meeting_sub_title = "" 
      this.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;
      scope.filtervalue = [];

      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      let query = {
        skip: skip,
        take: take,
        module: scope.filter.payload.module,
        user: scope.filter.payload.user
      };
      this.skip = skip;

      this.$store
        .dispatch("GetMeetings", query)
        .then((response) => {
          this.pagecount = response.data.totalCount;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          scope.meeting_data = datas;
        })
        .catch(function (error) {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
        let titleQuery = {
        skip: "",
        take: ""  
        }
        this.$store      
        .dispatch("FetchData",titleQuery)
        .then((response) => {
          var datas = response.data;
          scope.titledata=datas
           scope.meeting_title = datas.map((element) => element.title);
           
           
           scope.meeting_title.push("others")
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },
    AddNewRule: function () {
      this.page.isaddmeetings = true;
    },
    closeEditRules: function () {
      this.page.isEditDisposition = false;
      this.reset();
    },
    closeAddMeetings: function () {
      this.page.isaddmeetings = false;
      this.$refs.DisportionMain.reset();
      this.addmeetings.payload.manual_title ="";
      this.addmeetings.payload.name = "";
      this.addmeetings.payload.meeting_sub_title
      this.addmeetings.payload.sheduleddate = "";
    },
    saveEditRules() {
      var scope = this;
      if(scope.editmeetings.name === "others"){
        scope.editmeetings.name = scope.editmeetings.manual_title
        delete scope.editmeetings.manual_title

      }else{
        delete scope.editmeetings.manual_title
      }
      console.log(scope.editmeetings,"scope.editmeetings")
      scope.$store
        .dispatch("updateMeeting", scope.editmeetings)
        .then(() => {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditDisposition = false;
          this.refreshThisPage();
        })
        .catch(function (error) {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
      this.getDataFromApi();
    },
    currenttime(data) {
      const today = new Date(data);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();
      let hh = today.getHours();
      let MM = today.getMinutes();
      let ss = today.getSeconds();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      if (hh < 10) hh = "0" + hh;
      if (MM < 10) MM = "0" + MM;
      if (ss < 10) ss = "0" + ss;

      const formattedToday =
        yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
      return formattedToday;
      
    },
    saveMeetings() {
      var scope = this;
      if (scope.addmeetings.payload.module === "lead") {
        if (scope.addmeetings.payload.template !== "") {

          scope.$store
            .dispatch("findlead", { phone_number: scope.addmeetings.payload.phone_number, template: scope.addmeetings.payload.template })
            .then((res) => {
              if (res.status === 204) {
                scope.showSnackBar("please create leads");
              } else {

                this.req_id.push({ "lead": res.data.data[0].others.lead_id, module: "lead" })
                scope.create()
              }

            }).catch(function (error) {
              scope.showSnackBar(error);
            });
        } else {
          scope.showSnackBar("Please select template");
        }
      } else {
        scope.create()
      }

      // this.getDataFromApi();
    },
    create() {
      var scope = this;
      let id = this.req_id.find((item) => {
        if (item.module === scope.addmeetings.payload.module) {
          return item
        }
      })

      id = id[`${scope.addmeetings.payload.module}`]
      if(scope.addmeetings.payload.name === "others"){
          scope.addmeetings.payload.name = scope.addmeetings.payload.manual_title
      }
      let data = {"meeting_sub_title":scope.addmeetings.payload.meeting_sub_title, "meetingName": scope.addmeetings.payload.name , "meetingTilte": scope.addmeetings.payload.description, "meetingTime": scope.addmeetings.payload.sheduleddate, "user_email": scope.addmeetings.payload.agent, "leadID": id.toString(), "phoneNumber": scope.addmeetings.payload.phone_number, "campaign": "Admin Portal", "module": scope.addmeetings.payload.module, "callerName": scope.addmeetings.payload.phone_number }
      scope.$store
        .dispatch("insertMeeting", data)
        .then(() => {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("Created Successfully");
          this.$refs.DisportionMain.reset();
          scope.addmeetings.payload.sheduleddate = "";
          scope.addmeetings.payload.call_back = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("error" + error);
          scope.addmeetings.payload.name = "";
          scope.addmeetings.payload.phone_number = "";
          scope.addmeetings.payload.meeting_sub_title = "";
          scope.addmeetings.payload.description = "";
          scope.addmeetings.payload.module = "";
          scope.addmeetings.payload.agent = "";
          scope.addmeetings.payload.agent = "";
          scope.addmeetings.payload.sheduleddate = "";
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
    let  count = 0
      this.meeting_title.map((element)=>{
          if(element === item.meeting_name){
            count +=1
            this.editmeetings.name = element;
          }

      })
      if(count==0){
        this.editmeetings.name = "others"
        this.editmeetings.manual_title = item.meeting_name;
      }

      this.page.isEditDisposition = true;
      this.editmeetings.comments = item.subject;
      // this.editmeetings.phoneNumber = item.phoneNumber;
      this.editmeetings.id = item.meetingID;
      this.editmeetings.user = item.ownerID;
      this.editmeetings.meeting_sub_title=item.meeting_sub_title
      this.editmeetings.scheduleDate = new Date(item.scheduledTime).toISOString();

    },
    datetime(date){
      const currentDate = new Date(); // Gets the current date and time
const targetDate = new Date(date); // The target date and time

// Compare the dates using getTime()
const isDateLess = currentDate.getTime() < targetDate.getTime();

console.log(isDateLess); // Output: true or false
return isDateLess
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deletemeeting", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Meetings Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Meetings" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    let data = { model: "meeting", column: ["phone_number"] };

    scope.$store
      .dispatch("GetModuleNameList", data)
      .then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.filtervalue = datas[0].phone_number;
      })
      .catch((err) => {
        console.log(err);
      });

    this.getDataFromApi();
  },
  watch: {
    
    'addmeetings.payload.name': {
      handler() {
        let submeeting
        for (let i = 0; i < this.titledata.length; i++) {
          const element = this.titledata[i];
          if(element.title===this.addmeetings.payload.name){
            console.log(element,"eeeeeeeeeeeeeeeeeeeeeee33")
            submeeting=element.sub_title.split(',')
          }
          
        }
        this.meeting_sub_title=submeeting
      }
    },
    'editmeetings.name': {
      
      handler() {
        let submeeting
        for (let i = 0; i < this.titledata.length; i++) {
          
          const element = this.titledata[i];
          if(element.title===this.editmeetings.name){
            console.log(element,"eeeeeeeeeeeeeeeeeeeeeee33")
            submeeting=element.sub_title.split(',')
          }
          
        }
        this.meeting_sub_title=submeeting
      }
    },
    'addmeetings.payload.phone_number': {
      handler() {
        this.$store.dispatch("TicketPhoneSearch", { phone_number: Number(this.addmeetings.payload.phone_number) })
          .then((response) => {
            if (response.data.length > 0) {
              this.module.push("contact")
              this.req_id.push({ "contact": response.data[0].id, module: "contact" })
              } else {
              this.module = this.module.filter(item => item !== "contact")
              this.req_id = this.req_id.filter((item) => {
                if (item.module !== "contact") {
                  return item
                }
              })

            }
            this.$store.dispatch("Popup", { phone_number: this.addmeetings.payload.phone_number })
              .then((response) => {
                if (response.data.ids.length > 0) {
                  this.module.push("ticket")
                  this.req_id.push({ "ticket": response.data.ids[0].ticket_id, module: "ticket" })

                } else {
                  this.req_id = this.req_id.filter((item) => {

                    if (item.module !== "ticket") {
                      return item
                    }
                  })
                  this.module = this.module.filter(item => item !== "ticket")
                }

              })
          })
      },
      deep: true,
    },
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = { model: "meeting", column: ["phone_number"] };

        data["search"] = val;

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].phone_number;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.sheduleddate {
  font-size: 13px;
}
</style>