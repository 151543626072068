<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    >
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="announcementData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <!-- <td class="checkEmpty p0">{{ props.item.announcement }}</td> -->
          <td class="checkEmpty p0">{{ props.item.name }}</td>

          <td class="checkEmpty p0">
            <template v-if="props.item.status === 'Active'">Active</template>
            <template v-else>Inactive</template>
          </td>
          <td class="checkEmpty p0">{{ props.item.campaign.toString() }}</td>
          
          <td class="text-xs-center checkEmpty p0">
            <v-icon small class="mr-4"  @click="showAnnouncement(props.item.announcement )"
                                            >
                                            info</v-icon>
              <v-icon
              :color="(props.item.flag == '0' ? '#f57c00' :'green')"
              small
              class="mr-4 "
              :class="['fas', 'fa-bullhorn']"
              @click="publishItem(props.item)"
              ></v-icon>
        
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Announcement Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you Sure to delete this announcement?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <template>
      <v-dialog
        persistent
        :value="page.isAnnouncement"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Announcement</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
          <p class="wrap-announcement"> {{announcement}}</p> 
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="page.isAnnouncement=false"
              >Close</v-btn
            >
           
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        persistent
        :value="page.isAddAnnouncement"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Announcement</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="AddAnnouncement" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addAnnouncement.payload.name"
                      required
                      label="Name"
                      type="text"
                      :rules="[(v) => !!v || 'Name is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="addAnnouncement.payload.announcement"
                      required
                      label="Announcement"
                      type="text"
                      :rules="[(v) => !!v || 'Announcement is required']"
                    ></v-textarea>
                  </v-flex>
                  
                  <!-- <v-flex xs12>
                    <v-select v-model="addAnnouncement.payload.status" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select
                      v-model="addAnnouncement.payload.campaign"
                      :items="addAnnouncement.options.campaign"
                      label="Campaign"
                      item-text="name"
                      item-value="value"
                      return-string
                      required
                      :rules="[(v) => !!v || 'Campaign is required']"
                      persistent-hint
                      multiple
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddAnnouncement()"
              >Close</v-btn
            >
            <v-btn
              color="primary"
              :disabled="!valid"
              @click="saveAddAnnouncement()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-dialog
      persistent
      :value="page.isEditAnnouncement"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Update Announcement</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="asdfg" v-model="valid">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editAnnouncement.name"
                    required
                    label="Name"
                    type="text"
                    :rules="[(v) => !!v || 'name is required']"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="editAnnouncement.announcement"
                    required
                    label="Announcement"
                    type="text"
                    :rules="[(v) => !!v || 'announcement is required']"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12>
                  <v-select
                    v-model="editAnnouncement.status"
                    required
                    label="Status"
                    :items="status"
                    :rules="[(v) => !!v || 'Status is required']"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editAnnouncement.campaign"
                    :items="addAnnouncement.options.campaign"
                    label="Campaign"
                    item-text="name"
                    item-value="value"
                    return-string
                    required
                    persistent-hint
                    :rules="[(v) => !!v || 'Campaign is required']"
                    multiple
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal-footer--sticky">
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="saveEditRules()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      fixed
      disable-resize-watcher
      right
      :value="page.isFilterOpen"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.name"
              :items="filtervalue"
              :loading="filter.isLoading"
              :search-input.sync="filter.search"
              label="Name"
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-select
              v-model="filter.payload.status"
              :items="filter.options.status"
              label="Status"
              item-text="name"
              item-value="value"
              return-string
              persistent-hint
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Announcement",
        icon: "fa-bullhorn fa-2x",
        loading: false,
        isAddAnnouncement: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditAnnouncement: false,
        temp: { key: "addannouncement", label: "Add Announcement" },
        candelete: false,
        canedit: false,
        isAnnouncement:false,
        canpublish:false,
        
      },
      pagecount: 0,
      headers: [
        // {
        //   text: "Announcement",
        //   align: "left",
        //   class: "p0",
        //   sortable: false,
        //   value: "announcement",
        // },
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Campaign",
          align: "left",
          class: "p0",
          sortable: false,
          value: "Campaign",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      announcement:"",
      announcementData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          name: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editAnnouncement: {
        id: "",
        name: "",
        announcement: "",
        status: "",
        campaign: [],
      },
      addAnnouncement: {
        valid: false,
        payload: {
          name: "",
          announcement: "",
          status: "Active",
          campaign: "",
        },
        options: {
          campaign: [],
          status: ["Active", "Inactive"],
        },
      },
      status: ["Active", "Inactive"],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.name = "";
      scope.filter.payload.status = "";
      
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
    },
   
    reset() {
      var scope = this;
      scope.filter.payload.name = "";
      scope.filter.payload.status = "";
     
      scope.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;

      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      // let query = {
      //   skip: skip,
      //   take: take
      // }
      scope.filter.payload["skip"] = skip;
      scope.filter.payload["take"] = take;
      scope.skip = skip;

      this.$store
        .dispatch("GetAnnouncement", scope.filter.payload)
        .then((response) => {
          this.pagecount = response.count;
          scope.tableLoading = false;
          scope.page.loading = false;

          var datas = response.data;

          scope.announcementData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAnnouncement = false;
        });
    },
    AddNewRule: function () {
      this.page.isAddAnnouncement = true;
    },
    closeEditRules: function () {
      this.page.isEditAnnouncement = false;
    },
    closeAddAnnouncement: function () {
      this.page.isAddAnnouncement = false;
      this.$refs.AddAnnouncement.reset();
    },
    saveEditRules() {
      var scope = this;
      scope.editAnnouncement.campaign= scope.editAnnouncement.campaign.toString()
      scope.$store
        .dispatch("updateAnnouncement", scope.editAnnouncement)
        .then(() => {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("Updated Successfully");
          this.getDataFromApi();
          this.page.isEditAnnouncement = false;
        })
        .catch(function (error) {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAnnouncement = false;
        });
      this.getDataFromApi();
    },
    saveAddAnnouncement() {
      var scope = this;
      this.addAnnouncement.payload.campaign=this.addAnnouncement.payload.campaign.toString()
      scope.$store
        .dispatch("insertAnnouncement", scope.addAnnouncement.payload)
        .then(() => {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("Created Successfully");
          this.$refs.AddAnnouncement.reset();

          scope.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("error" + error);
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
      showAnnouncement(data) {
        this.announcement=data
     
      this.page.isAnnouncement = true;
     
    },
    editItem(item) {
      var scope = this;
      scope.$store;
      this.page.isEditAnnouncement = true;
      this.editAnnouncement.id = item.id;
      this.editAnnouncement.announcement = item.announcement;
      this.editAnnouncement.name = item.name;
      
      this.editAnnouncement.campaign = item.campaign.split(',');
      if (item.status === "Active") {
        this.editAnnouncement.status = "Active";
      } else {
        this.editAnnouncement.status = "Inactive";
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAnnouncement", this.delete_id)
        .then((response) => {
          scope.getDataFromApi();
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.showSnackBar("Announcement Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    publishItem(item){
        var scope = this;
        //item.flag=1
        let x;
        if(item.flag==0){
          x=1
        }else{
          x=0
        }
       let payload={flag:x,id:item.id}
      scope.$store
        .dispatch("updateAnnouncement", payload)
        .then(() => {
          scope.page.isAddAnnouncement = false;
         // scope.showSnackBar("Updated Successfully");
          this.getDataFromApi();
          this.page.isEditAnnouncement = false;
        })
        .catch(function (error) {
          scope.page.isAddAnnouncement = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAnnouncement = false;
        });
    },
    
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    scope.$store.dispatch("campaignList").then((response) => {
      let datas = response.data.data;
      datas.forEach((x) => {
        scope.addAnnouncement.options.campaign.push(x.campaign_name);
      });
    });
    let data = { model: "announcement", column: ["name"] };

    scope.$store
      .dispatch("GetModuleNameList", data)
      .then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.filtervalue = datas[0].name;
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:announcement:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Announcement" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:announcement:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:announcement:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },

  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;
        let data = { model: "announcement", column: ["name"], search: val };

        scope.$store
          .dispatch("GetModuleNameList", data)
          .then(() => {
            scope.tableLoading = false;
            scope.page.loading = false;
            // var datas = response.data;
            // scope.filtervalue = datas[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.mr-4 {
  text-align: start;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
.wrap-announcement{
   /* border: solid 1px #666; */
        width: 459px;
        word-wrap: break-word;
}
</style>