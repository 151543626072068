export default {
    calldetails: {
        report: []
    },
    effectiveabandon: {
        report: []
    },
    recordingreport: {
        report: []
    },
    missedcallreport: {
        report: []
    },
    ticketreport: {
        report: []
    },
    agent_activity_report: {
        report: []
    },
    agent_pause_report: {
        report: []
    },
    agent_dispo_report: {
        report: []
    },
    performance_report: {
        report: []
    },
    queueSummaryReport: {
        report: []
    },
    systemcallsummary:{ 
        report: []
    },
    dropedcallreport: {
        report: []
    },
     watsAppReport: {
        report: []
    },
    ticketReport: {
        report: []
    },
    filter: {
        fromdate: "",
        todate: "",
        agent: '',
        type: '',
        phone: '',
        group: '',
        station: '',
        did: '',
        queue: '',
        dispo: '',
        status: '',
        filter_process: false,
        last_app: '',
        campaign: '',
        event: '',
        uniqueid: '',
        template: '',
        list_id: '',
        extension: ''
    },

    filters: {
        fromdate: '',
        todate: '',
        agent: [],
        template: [],
        type: ["Inbound", "Manual", "Auto","VB","Power"],
        phonenumber: '',
        group: [],
        station: '',
        did: '',
        queue: [],
        subdispo_status:[],
        dispo_status: [],
        status: ['NOANSWER', 'COMPLETE', 'ABANDON'],
        last_app: '',
        event: ["Login", "Break"],
        campaign: [],
        list_id:''
    }
}