import { render, staticRenderFns } from "./SectionDef.vue?vue&type=template&id=0be6b27e&scoped=true&"
import script from "./SectionDef.vue?vue&type=script&lang=js&"
export * from "./SectionDef.vue?vue&type=script&lang=js&"
import style0 from "./SectionDef.vue?vue&type=style&index=0&id=0be6b27e&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be6b27e",
  null
  
)

export default component.exports