<template>
  <div >

    <div style="text-align:left;padding-left:10px">{{currentField.label}}</div>
    <div class="RadioButtonfield">

      <v-radio-group :class="[currentField.required?required_class:currentField.readonly?read_only:others]" :readonly="currentField.clearable" v-model="radios" @change="getChildProperties(true)" :rules="required_rule">
        <div class="radiorow">
          <v-radio v-for="(n, i) in currentField.values" :key="i" :value="n.value" color="primary" :label="`${n.value}`"
           :disabled="disable_val">
          </v-radio>
        </div>
      </v-radio-group>

    </div>

  </div>

</template>

<script>
import fetchData from "@/api/fetch-data";
export default {
  name: "RadioButton",
  props: ["currentField", "Allfields"],
  data() {
    return {
      radios: null,
      child_validator: 0,
      disable_val: false,
      required_class:"required",
            others:"others",
             read_only:"readonly",
             required_rule:[]
 
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  watch: {
    radios() {
      const scope = this;
      scope.currentField.value.value = this.radios;
      scope.getChildProperties(false)
    },
    "currentField.values"() {
      let count = 0
      for (let i = 0; i < this.Allfields.length; i++) {
        const element = this.Allfields[i];
        if (this.currentField.linked_to === element.model && element.text === 'Checkbox') {
          if (element.value.value) {
            this.child_validator += 1
            if (this.child_validator > 0) {
              if (this.currentField.disabled) {
                this.disable_val = true
              } else {
                this.disable_val = false
              }
            }
            // this.currentField.value.value = '';
            // this.radios=''
          } else {
            this.child_validator = 0
            this.disable_val = true
          }
          // this.currentField.value.value = '';
          // count += 1
        }

      }
      // console.log(this.currentField.value.value,"this.currentField.value.value1")    
      if (count === 0) {
        this.child_validator += 1
        if (this.child_validator > 0) {
          if (this.currentField.disabled) {
            this.disable_val = true
          } else {
            this.disable_val = false
          }
        }
        // this.currentField.value.value = '';
        // this.radios=''  
      }
    },
    "currentField.value.value"(){
      if(this.currentField.value.value == ""){
        this.radios= null
      }
    }
  },
  methods: {
    getChildProperties(data) {
      const scope = this;
      if (scope.currentField.is_link === true && scope.currentField.link_type === 'Parent') {

        scope.Allfields.find(elm => {
          if ((elm.is_link && elm.fieldType === 'SelectList') || (elm.is_link && elm.fieldType === 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to && scope.currentField.value.value !== '') {
              let arr = [];
              let val = elm.link_property[scope.currentField.value.value].split(',');

              val.forEach(res => {
                arr.push({ "name": res, "value": res })
              })
              
              elm.values = arr;
              if(data == true){
                elm.value.value = '';
              }
             
            }
          } else if ((scope.currentField.multiple === false || scope.currentField.multiple === '' || scope.currentField.multiple === undefined) && (elm.is_link && elm.fieldType != 'SelectList') || (elm.is_link && elm.fieldType != 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to) {
              let val = elm.link_property;if (val === scope.currentField.value.value) {
                elm.visible = true;
              } else {
                elm.visible = false;
                if(elm.fieldType == 'Checkbox'){
                  elm.value.value = false
                }else{
                  elm.value.value ="" 
                }  
              }
            }
          }
        })
      }
    },
  },
  mounted() {
    this.radios = this.currentField.value.value

    if (this.currentField.link_type === "Child") {
      this.disable_val = true
    } else if (this.currentField.disabled) {
      this.disable_val = true
    }

    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
    setTimeout(() => {
      const containers = document.querySelectorAll(
        `#${this.currentField.model}`
      );
      for (let container of containers) {
        if (container.classList.value === "el-radio is-checked") {
          this.radio = container.querySelector(
            "span.el-radio__label"
          ).outerText;
        }
      }
    }, 500);

    if (this.currentField.required == true) {
      this.required_rule = [v => !!v || `${this.currentField.label} is required`]
    }
    // console.log(this.currentField.value.value,"this.currentField.value.value")
  },
  created(){
    if(this.currentField.link_type == "Child"){
      let parent = this.Allfields.find((e)=>e.model == this.currentField.linked_to)
      if(parent.fieldType =="Checkbox"){
        let linked_value = this.currentField.link_property[this.currentField.linked_to]
        this.currentField.values = linked_value.split(",").map((elm)=>{
          return {name:elm,value:elm}
        })
      }else if(parent.fieldType =="RadioButton" || parent.fieldType =="SelectList"){
        let linked_value = this.currentField.link_property[parent.value.value]
        if(linked_value !== undefined){
          this.currentField.values = linked_value.split(",").map((elm)=>{
          return {name:elm,value:elm}
        })
        }
        // console.log(parent.fieldType,"parent.fieldType")
        
      }
      
      
    }
  }
};
</script>


<style>


.radiorow {
  display: flex;
  flex-direction: row;
  width: 100px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
