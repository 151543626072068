<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @refreshPage="refreshThisPage"
      @addNew="AddNewWebhook"
      @openFilter="filterClick"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="webhookData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">
            <div class="text-url">{{ props.item.url }}</div>
          </td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td class="checkEmpty p0">
            <div class="text-event">{{ props.item.event }}</div>
          </td>
          <td class="checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Webhook Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Webhook?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="Createform" v-model="valid">
      <v-dialog persistent :value="page.isAddWebhook" max-width="700px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Webhook</span>
            </v-card-title>
          </v-toolbar>
          <v-container
            id="scroll-target"
            style="max-height: 30em"
            class="scroll-y"
          >
            <v-layout column>
              <v-flex xs12>
                <v-text-field
                  label="Name"
                  required
                  v-model="addWebhook.payload.name"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="End Point URL"
                  required
                  v-model="addWebhook.payload.url"
                  :rules="urlRule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12> </v-flex>
              <div
                class="event-modules"
                v-for="(modules, moduleindex) in events"
                :key="moduleindex"
              >
                <div class="module-toolbar">
                  <!-- <input
 type="checkbox"
 :checked="ischeckall(moduleindex)"
 @click="checkall($event, moduleindex)"
 id="event.name"
 /> // Select All Checkbox-->
                  <div class="title">{{ modules.title }}</div>
                </div>
                <div class="event-checkboxes">
                  <div v-for="(events, index) in modules.list" :key="index">
                    <label :for="events.title"
                      ><input
                        type="checkbox"
                        v-model="events.active"
                        id="event.name"
                        style="cursor: pointer"
                        @click="params(events)"
                      />{{ events.title }}</label
                    >
                  </div>
                </div>
              </div>
              <v-dialog persistent :value="parameter.show" max-width="400px">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-card-title>
                      <span class="headline">Mapping</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-container fluid>
                    <label for="Select All">
                      <input
                        type="checkbox"
                        style="cursor: pointer"
                        v-model="addSelectAll"
                        @click="selectAll(!addSelectAll)"
                      />{{ "Select All" }}</label
                    >
                    <div
                      v-for="(value, val_index) in parameter.params"
                      :key="val_index"
                    >
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            style="cursor: pointer"
                            v-model="value.active"
                            @change="checked()"
                          />{{ value.system }}</label
                        >
                        :
                        <input
                          type="text"
                          name="name"
                          class="align"
                          v-model="value.custom"
                          :disabled="value.active === false"
                        />
                      </div>
                    </div>
                  </v-container>
                  <v-card>
                    <div
                      style="text-align: center"
                      v-for="(value, val_index) in events"
                      :key="val_index"
                    >
                      <div
                        v-for="(list, listindex) in value.list"
                        :key="listindex"
                      >
                        <div v-if="list.active">
                          <div
                            v-for="(modules, moduleindex) in parameter.params"
                            :key="moduleindex"
                          >
                            <span v-if="list.name === modules.events">
                              <div
                                v-for="(
                                  search, searchindex
                                ) in MappingItems.name"
                                :key="searchindex"
                              >
                                <div
                                  v-for="(check, checkindex) in modules.params"
                                  :key="checkindex"
                                >
                                  <div v-if="search === check.system">
                                    <label
                                      ><input
                                        class="align"
                                        type="text"
                                        v-model="check.custom"
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <v-card-actions>
                    <v-btn color="light" flat @click="closeParams()"
                      >Close</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="doneParams()"
                      >Done</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddWebhook()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddWebhook()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-form ref="Editform" v-model="valid">
      <v-dialog persistent :value="page.isEditWebhook" max-width="700px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Edit Webhook</span>
            </v-card-title>
          </v-toolbar>
          <v-container
            id="scroll-target"
            style="max-height: 30em"
            class="scroll-y"
          >
            <v-layout column>
              <v-flex xs12>
                <v-text-field
                  label="Name"
                  required
                  v-model="editWebhook.name"
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  label="End Point URL"
                  required
                  v-model="editWebhook.url"
                  :rules="urlRule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="editWebhook.status"
                  required
                  label="Status"
                  :items="status"
                  :rules="[(v) => !!v || 'Status is required']"
                ></v-select>
              </v-flex>
              <div
                class="event-modules"
                v-for="(modules, moduleindex) in events"
                :key="moduleindex"
              >
                <div class="module-toolbar">
                  <!-- <input
 type="checkbox"
 :checked="ischeckall(moduleindex)"
 @click="checkall($event, moduleindex)"
 id="event.name"
 /> -->
                  <div class="title">{{ modules.title }}</div>
                </div>
                <div class="event-checkboxes">
                  <div v-for="(events, index) in modules.list" :key="index">
                    <label :for="events.name"
                      ><input
                        type="checkbox"
                        v-model="events.active"
                        id="event.name"
                        @click="editparams(events, editWebhook.id)"
                      />{{ events.title }}</label
                    >
                  </div>
                </div>
              </div>
              <v-dialog persistent :value="parameter.shows" max-width="400px">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-card-title>
                      <span class="headline">Mapping</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-container fluid>
                    <div
                      v-for="(value, val_index) in parameter.edit_params"
                      :key="val_index"
                    >
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            style="cursor: pointer"
                            v-model="value.active"
                          />{{ value.system }}</label
                        >
                        :
                        <input
                          type="text"
                          name="name"
                          class="align"
                          v-model="value.custom"
                          :disabled="value.active === false"
                        />
                      </div>
                    </div>
                  </v-container>
                  <v-card>
                    <div
                      style="text-align: center"
                      v-for="(value, val_index) in events"
                      :key="val_index"
                    >
                      <div
                        v-for="(list, listindex) in value.list"
                        :key="listindex"
                      >
                        <div v-if="list.active">
                          <div
                            v-for="(modules, moduleindex) in parameter.params"
                            :key="moduleindex"
                          >
                            <span v-if="list.name === modules.events">
                              <div
                                v-for="(
                                  search, searchindex
                                ) in MappingItems.name"
                                :key="searchindex"
                              >
                                <div
                                  v-for="(check, checkindex) in modules.params"
                                  :key="checkindex"
                                >
                                  <div v-if="search === check.system">
                                    <label
                                      ><input
                                        class="align"
                                        type="text"
                                        v-model="check.custom"
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <v-card-actions>
                    <v-btn color="light" flat @click="closeEditParams()"
                      >Close</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="updateParams()"
                      >Update</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditWebhook()">Close</v-btn>
            <v-btn
              color="primary"
              :disabled="!valid"
              flat
              @click="saveEditWebhook()"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <!-- <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen"> -->
    <v-navigation-drawer
      fixed
      disable-resize-watcher
      right
      v-model="page.isFilterOpen"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <v-flex xs12>
            <v-text-field
              label="Name"
              required
              v-model="filter.payload.name"
            ></v-text-field>
              <v-autocomplete
              v-model="filter.payload.name"
              :items="filtervalue"
              :loading="filter.isLoading"
              :search-input.sync="filter.search"
              label="Name"
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              label="Event"
              required
              v-model="filter.payload.event"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="filter.payload.status"
              required
              label="Status"
              :items="status"
            ></v-select>
          </v-flex>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Webhook",
        icon: "fa-network-wired fa-2x",
        loading: false,
        isAddWebhook: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditWebhook: false,
        candelete: false,
        canedit: false,
        isFilterOpen: false,
      },
      filter: {
        payload: {
          event: "",
          name: "",
          status: "",
        },
        name: [],
        search:"",
        isLoading:false
      },
      filtervalue:[],
      pagecount: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name",
        },
        {
          text: "URL",
          align: "left",
          class: "p0",
          sortable: false,
          value: "url",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Event",
          align: "left",
          class: "p0",
          sortable: false,
          value: "event",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          class: "p0",
          width: "10%",
        },
      ],
      urlRule: [(v) => /^(http|https):/.test(v) || "URL must be valid"],
      webhookData: [],
      created_data: {
        tenant_id: "",
        params: [],
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      title: [],
      event: {
        url: "",
        title: "",
      },
      parameter: {
        show: false,
        shows: false,
        params: [],
        edit_params: [],
        custom_params: [],
        store: {},
      },
      custom_params: [],
      events: [
        {
          group: "call",
          title: "Call Events",
          list: [
            { title: "Channel Start", name: "call:chan_start", active: false },
            { title: "Queue Enter", name: "call:queue_enter", active: false },
            { title: "Ringing", name: "call:ringing", active: false },
            { title: "Drop", name: "call:drop", active: false },
            { title: "Answer", name: "call:answer", active: false },
            { title: "Hangup", name: "call:hangup", active: false },
            { title: "Transfer", name: "call:transfer", active: false },
            {
              title: "Start Conference",
              name: "call:start_conference",
              active: false,
            },
            {
              title: "Join Conference",
              name: "call:join_conference",
              active: false,
            },
            {
              title: "Leave Conference",
              name: "call:leave_conference",
              active: false,
            },
            {
              title: "Mute Conference",
              name: "call:mute_conference",
              active: false,
            },
            {
              title: "End Conference",
              name: "call:end_conference",
              active: false,
            },
          ],
        },
        {
          group: "user",
          title: "User Events",
          list: [
            { title: "Login", name: "user:login", active: false },
            { title: "Logout", name: "user:logout", active: false },
            { title: "Queue Login", name: "user:queue_login", active: false },
            { title: "Queue Logout", name: "user:queue_logout", active: false },
            { title: "Queue Pause", name: "user:queue_pause", active: false },
          ],
        },
      ],
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
      isProcessing: false,
      call_status: [],
      editWebhook: {
        id: "",
        name: "",
        url: "",
        status: "",
        event: "",
      },
      addWebhook: {
        valid: false,
        payload: {
          name: "",
          url: "",
          event: "",
          status: "Active",
        },
      },
      addSelectAll: false,
      editSelectAll: false,
      status: ["Active", "Inactive"],
      valid: true,
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.filter.payload.name = "";
      scope.filter.payload.event = "";
      scope.filter.payload.status = "";
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }

      if (skip == -10) {
        skip = 0;
      }
      let query = {
        skip: skip,
        take: take,
        name: this.filter.payload.name,
        event: this.filter.payload.event,
      };
      this.skip = skip;
      if (scope.filter.payload.status === "Active") {
        query["status"] = "1";
      }
      if (scope.filter.payload.status === "Inactive") {
        query["status"] = "0";
      }
      this.$store
        .dispatch("GetWebhook", query)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          this.pagecount = response.data.count;
          if (response.data.data) {
            for (var i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].status === 1) {
                response.data.data[i].status = "Active";
              } else {
                response.data.data[i].status = "Inactive";
              }
            }
            var datas = response.data.data;

            scope.webhookData = datas;
          } else {
            scope.webhookData = [];
          }
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditWebhook = false;
        });
    },
    fetchFilter() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      console.log(this.pagination.page, "page");
      if (skip == -10) {
        skip = 0;
      }
      let query = {
        skip: skip,
        take: take,
        name: this.filter.payload.name,
        event: this.filter.payload.event,
      };
      if (scope.filter.payload.status === "Active") {
        query["status"] = "1";
      }
      if (scope.filter.payload.status === "Inactive") {
        query["status"] = "0";
      }
      this.$store
        .dispatch("GetWebhook", query)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          this.pagecount = response.data.count;
          if (response.data.data) {
            for (var i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].status === 1) {
                response.data.data[i].status = "Active";
              } else {
                response.data.data[i].status = "Inactive";
              }
            }
            var datas = response.data.data;

            scope.webhookData = datas;
          } else {
            scope.webhookData = [];
          }
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditWebhook = false;
        });
    },
    AddNewWebhook: function () {
      for (let i = 0; i < this.events.length; i++) {
        for (let j = 0; j < this.events[i].list.length; j++) {
          if (this.events[i].list[j].active === true) {
            this.events[i].list[j].active = false;
          }
        }
      }
      this.page.isAddWebhook = true;
    },
    closeEditWebhook: function () {
      for (let i = 0; i < this.events.length; i++) {
        for (let j = 0; j < this.events[i].list.length; j++) {
          if (this.events[i].list[j].active === true) {
            this.events[i].list[j].active = false;
          }
        }
      }
      this.page.isEditWebhook = false;
    },
    closeAddWebhook: function () {
      let scope = this;
      scope.page.isAddWebhook = false;
      scope.$refs.Createform.reset();
    },
    reset() {
      let scope = this;
      scope.filter.payload.name = "";
      scope.filter.payload.event = "";
      scope.filter.payload.status = "";
      scope.getDataFromApi();
    },
    ischeckall: function (moduleindex) {
      return this.events[moduleindex].list.every((val) => {
        return val.active;
      });
    },
    checkall: function (events, moduleindex) {
      this.events[moduleindex].list.map((val) => {
        val.active = events.target.checked;
        return val;
      });
    },
    print(events) {
      if (events.active === true) {
        for (let i = 0; i < this.parameter.custom_params.length; i++) {
          for (let j = 0; j < this.parameter.edit_params.length; j++) {
            // this.parameter.edit_params[j].webhook_id = id;
            if (
              this.parameter.custom_params[i].system ===
                this.parameter.edit_params[j].system &&
              this.parameter.custom_params[i].event ===
                this.parameter.edit_params[j].event.split(":")[1]
            ) {
              this.parameter.edit_params[j].id =
                this.parameter.custom_params[i].id;
              this.parameter.edit_params[j].custom =
                this.parameter.custom_params[i].custom;
              this.parameter.edit_params[j].active = true;
            }
          }
        }
      }
    },
    params(event) {
      const scope = this;
      scope.addSelectAll = false;
      this.$store
        .dispatch("GetDefaultParams", event.name)
        .then((response) => {
          response.forEach((element) => {
            let eve = event.name;
            if (eve === element.events && event.active === true) {
              for (let j = 0; j < element.params.length; j++) {
                let obj = {
                  event: element.events,
                  system: element.params[j].system,
                  custom: element.params[j].system,
                  active: false,
                };
                if (event.action === "edit") {
                  obj.webhook_id = this.editWebhook.id;
                  scope.parameter.edit_params.push(obj);
                  scope.print(event);
                  scope.parameter.shows = true;
                } else {
                  scope.parameter.params.push(obj);
                  scope.parameter.show = true;
                }
              }
            }
          });
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditWebhook = false;
        });
    },
    editparams(event, id) {
      var scope = this;
      event.action = "edit";
      let payload = {
        id: id,
        event: event.name,
      };

      scope.$store
        .dispatch("EditParams", payload)
        .then((response) => {
          if (response.statusCode != 204) {
            response.data.forEach((element) => {
              let eve = event.name.split(":")[1];
              if (eve === element.events && event.active === false) {
                element.params.forEach((val) => {
                  let obj = {
                    id: val.Id,
                    event: element.events,
                    system: val.system_params,
                    custom: val.custom_params,
                    active: true,
                  };
                  scope.parameter.custom_params.push(obj);
                });
              }
            });
          }
          scope.params(event);
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditWebhook = false;
        });

      scope.parameter.store;
    },
    closeParams() {
      this.addSelectAll = false;
      this.parameter.params = [];
      this.parameter.show = false;
    },
    closeEditParams() {
      this.parameter.custom_params = [];
      this.parameter.edit_params = [];
      this.parameter.shows = false;
    },
    doneParams() {
      var scope = this;

      scope.parameter.params.forEach((data) => {
        if (data.active) {
          let arr = {
            events: data.event,
            system_params: data.system,
            custom_params: data.custom,
          };
          scope.created_data.params.push(arr);
        }
      });

      scope.parameter.params = [];
      scope.parameter.show = false;
      scope.addSelectAll = false;
    },
    selectAll(val) {
      var scope = this;
      if (val) {
        scope.parameter.params.forEach((data) => {
          data.active = true;
        });
      } else {
        scope.parameter.params.forEach((data) => {
          data.active = false;
        });
      }
    },
    checked() {
      this.addSelectAll = this.parameter.params.every((val) => val.active);

      console.log(this.addSelectAll, " this.addSelectAllssss");
    },
    updateParams() {
      var scope = this;
      let records = [];
      scope.parameter.edit_params.forEach((data) => {
        if (data.active) {
          if (data.id != undefined) {
            let obj = {
              events: data.event,
              system_params: data.system,
              custom_params: data.custom,
              webhook_id: data.webhook_id,
              id: data.id,
            };
            records.push(obj);
          } else {
            let obj = {
              events: data.event,
              system_params: data.system,
              custom_params: data.custom,
              webhook_id: data.webhook_id,
            };
            records.push(obj);
          }
        } else if (!data.active) {
          if (data.id != undefined) {
            scope.deleteeditparams(data.id);
          }
        }
      });

      let value = {
        data: records,
      };

      scope.$store
        .dispatch("updateParams", value)
        .then(() => {
          scope.parameter.shows = false;
          scope.showSnackBar("Updated Successfully");
          scope.parameter.edit_params = [];
          scope.parameter.custom_params = [];
          
        })
        .catch(function (error) {
          scope.parameter.shows = false;
          scope.showSnackBar("error" + error);
          scope.parameter.edit_params = [];
        });
    },
    deleteeditparams(value) {
      var scope = this;

      this.$store
        .dispatch("deleteeditparams", value)
        .then((response) => {
          if (response.data.message == "success") {
            scope.showSnackBar("Updated Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Remove params");
        });
      this.closedelete();
    },
    saveEditWebhook() {
      var scope = this;
      if (scope.editWebhook.status === "Active") {
        scope.editWebhook.status = "1";
      }
      if (scope.editWebhook.status === "Inactive") {
        scope.editWebhook.status = "0";
      }
      var temp = [];
      for (let i = 0; i < this.events.length; i++) {
        for (let j = 0; j < this.events[i].list.length; j++) {
          if (this.events[i].list[j].active === true) {
            this.title.push(this.events[i].list[j].name);
            this.title.join(",");
            temp = this.title;
            scope.editWebhook.event = "";
            scope.editWebhook.event = temp.join(",");
          }
        }
      }
      this.title = [];
      scope.$store
        .dispatch("updateWebhook", scope.editWebhook)
        .then(() => {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("Updated Successfully");
          scope.editWebhook.event = "";
          for (let i = 0; i < this.events.length; i++) {
            for (let j = 0; j < this.events[i].list.length; j++) {
              if (this.events[i].list[j].active === true) {
                this.events[i].list[j].active = false;
              }
            }
          }
          scope.page.isEditWebhook = false;
          this.refreshThisPage()
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditWebhook = false;
        });

      scope.page.loading = true;
      scope.filter.payload.name = "";
      scope.filter.payload.event = "";
      scope.filter.payload.status = "";
      this.getDataFromApi();
    },
    saveAddWebhook() {
      var scope = this;
      var temp = [];
      for (let i = 0; i < this.events.length; i++) {
        for (let j = 0; j < this.events[i].list.length; j++) {
          if (this.events[i].list[j].active === true) {
            this.title.push(this.events[i].list[j].name);
            this.title.join(",");
            temp = this.title;
            scope.addWebhook.payload.event = temp.join(",");
          }
        }
      }
      this.title = [];
      scope.$store
        .dispatch("insertWebhook", scope.addWebhook.payload)
        .then((res) => {
          console.log(res, "webbookerror");
          scope.$refs.Createform.reset();
          scope.created_data.id = res.data.data.id;
          scope.page.isAddWebhook = false;
          this.saveparams(scope.created_data);
          // scope.showSnackBar("Created Successfully");
          scope.addWebhook.payload.name = "";
          scope.addWebhook.payload.url = "";
          scope.addWebhook.payload.event = "";
          scope.addWebhook.payload.status = "Active";
          this.refreshThisPage()
        })
        .catch(function (error) {
          let check_status = error.toString();
          if (check_status.includes("409")) {
            scope.showSnackBar("Error: URL already exist");
          } else if (
            check_status.includes("400") &&
            scope.addWebhook.payload.event == ""
          ) {
            scope.showSnackBar("Error: Event is not allowed to be empty");
          }
          scope.page.isAddWebhook = false;

          scope.addWebhook.payload.status = "Active";

          delete scope.created_data.id;
          scope.created_data.tenant_id = "";
          scope.created_data.params = [];
        });
      scope.filter.payload.name = "";
      scope.filter.payload.event = "";
      scope.filter.payload.status = "";
      this.getDataFromApi();
    },
    saveparams(data) {
      var scope = this;
      scope.$store
        .dispatch("insertCustomParams", data)
        .then(() => {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("Created Successfully");
          delete scope.created_data.id;
          scope.created_data.tenant_id = "";
          scope.created_data.params = [];
        })
        .catch(function (error) {
          scope.page.isAddWebhook = false;
          scope.showSnackBar("error" + error);
          delete scope.created_data.id;
          scope.created_data.tenant_id = "";
          scope.created_data.params = [];
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;

      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      this.page.isEditWebhook = true;
      this.editWebhook.id = item.id;
      this.editWebhook.name = item.name;
      this.editWebhook.url = item.url;
      this.editWebhook.status = item.status;
      var che = item.event.split(",");
      for (let i = 0; i < this.events.length; i++) {
        for (let j = 0; j < this.events[i].list.length; j++) {
          for (let k = 0; k < che.length; k++) {
            if (che[k] === this.events[i].list[j].name) {
              this.events[i].list[j].active = true;
            }
          }
        }
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteWebhook", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.filter.payload.name = "";
            scope.filter.payload.event = "";
            scope.filter.payload.status = "";
            scope.getDataFromApi();
            scope.showSnackBar("Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable to Delete");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable to Delete");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },

  computed: {
    ...mapGetters(["webhookevents"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "connection:webhooks:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Webhook" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "connection:webhooks:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "connection:webhooks:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        this.demo += 1;
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
        "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;
        let data = { model: "webhooks", column: ["name"], search: val };

        scope.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            scope.filtervalue = datas[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.align {
  width: 200px;
  height: 20px;
  border: 1px solid #cacaca;
  text-align: center;
  position: relative;
  display: block;
  margin: auto;
  float: right;
  clear: both;
  cursor: pointer;
}

.dialogs {
  display: flex;
  justify-content: center;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.event-checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.event-checkboxes > div {
  width: 33%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.module-toolbar {
  display: flex;
  padding: 10px;
  background: #ccc;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  justify-content: flex-start;
}

.text-event {
  width: 220px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text-url {
  width: 220px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.event-modules {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
}

input[type="checkbox"] {
  margin-right: 10px;
}
</style>

