<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewTimezone" :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>     
      <v-data-table :headers="headers" :items="timezoneData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.zone_name }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">{{ props.item.start_time }}</td>
          <td class="checkEmpty p0">{{ props.item.end_time }}</td>

          <td class="checkEmpty p0">
            <template v-if="props.item.status === 'Y'">Active</template>
            <template v-else>Inactive</template>
          </td>

          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete"
              @click="deleteItem(props.item.id)">delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Timezone</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you Sure?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddTimezone" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Create Timezone</span>
          </v-card-title>
        </v-toolbar>
        <v-form ref="timezone" v-model="valid">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field v-model="addTimezone.payload.zone_name" required label="Zone Name" type="text"
                :rules="[(v) => !!v || 'Zone Name is required']"></v-text-field>
            </v-flex>
           
            <v-flex xs12>
              <v-text-field v-model="addTimezone.payload.description" required label="Description" type="text"
                :rules="[(v) => !!v || 'Description is required']"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-menu ref="menu1" v-model="addTimezone.menu2" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="addTimezone.payload.start_time" transition="scale-transition" offset-y
                max-width="250px" min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="addTimezone.payload.start_time" label="Start Time" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="addTimezone.menu2" v-model="addTimezone.payload.start_time" format="24hr" full-width
                  @click:minute="$refs.menu1.save(addTimezone.payload.start_time)" color="primary"></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-menu ref="menu" v-model="addTimezone.menu1" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="addTimezone.payload.end_time" transition="scale-transition" offset-y max-width="250px"
                min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="addTimezone.payload.end_time" label="End Time" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="addTimezone.menu1" v-model="addTimezone.payload.end_time" format="24hr" full-width
                  @click:minute="$refs.menu.save(addTimezone.payload.end_time)" color="primary"></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-select v-model="addTimezone.payload.status" required label="Status" :items="status" item-text="name"
                item-value="value" :rules="[(v) => !!v || 'Status is required']"></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddTimezone()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" flat @click="saveAddTimezone()">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditTimezone" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Update Timezone</span>
          </v-card-title>
        </v-toolbar>
        <v-form ref="edittimezone" v-model="valid1">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field v-model="editTimezone.zone_name" required label="Zone Name" type="text"
                :rules="[(v) => !!v || 'Zone Name is required']"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="editTimezone.description" required label="Description" type="text"
                :rules="[(v) => !!v || 'Description is required']"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-menu ref="menu4" v-model="addTimezone.payload.menu3" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="editTimezone.start_time" transition="scale-transition" offset-y max-width="250px"
                min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editTimezone.start_time" label="Start Time" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="addTimezone.payload.menu3" format="24hr" v-model="editTimezone.start_time" full-width
                  color="primary" @click:minute="$refs.menu4.save(editTimezone.start_time)"></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-menu ref="menu3" v-model="addTimezone.payload.menu4" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="editTimezone.end_time" transition="scale-transition" offset-y max-width="250px"
                min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="editTimezone.end_time" label="End Time" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="addTimezone.payload.menu4" color="primary"  format="24hr"  v-model="editTimezone.end_time" full-width
                  @click:minute="$refs.menu3.save(editTimezone.end_time)"></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-select v-model="editTimezone.status" required label="Status" :items="status" item-text='name'
                item-value='value' :rules="[(v) => !!v || 'Status is required']"></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditTimezone()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid1" flat @click="saveEditTimezone()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="filter.query.zone_name" :items="filtervalue" :loading="filter.isLoading" :search-input.sync="filter.search"     
        label="Timezone"      
         hide-no-data hide-selected 
      ></v-autocomplete>
          </div>
          <div>
            <v-select v-model="filter.query.status" required label="Status" :items="status" item-text='name'
              item-value='value'></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Timezone",
        icon: "fa-clock fa-2x",
        loading: false,
        isAddTimezone: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditTimezone: false,
        // temp: { key: "addTimezone", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },

      pagecount: 0,
      headers: [
        {
          text: "Timezone",
          align: "left",
          class: "p0",
          sortable: true,
          value: "timezone",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },

        {
          text: "Start time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "start_time",
        },
        {
          text: "End time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "end_time",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      timezoneData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,

      filtervalue: [],
      filter: {
        query: {
          zone_name: "",
          status: ""
        },
        isLoading:false,
        search: "",

      },
    
      // pagination: {
      //   page: 0,
      //   rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editTimezone: {
        id: "",
        zone_name: "",
        description: "",
        start_time: "",
        end_time: "",
        status: "",
      },
      valid: false,
      valid1: false,
      addTimezone: {
        valid: false,
        menu2: false,
        menu1: false,
        menu3: false,
        menu4: false,
        payload: {
          zone_name: "",
          description: "",
          start_time: "",
          end_time: "",
          status: "",
        },

      },
      status: [{ name: "Active", value: 'Y' }, { name: "Inactive", value: 'N' }],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
        scope.pagination.page = 1;
        scope.filter.query.zone_name = "";
        scope.filter.query.status = "";
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      var data = {
                timezone: scope.filter.query.zone_name,
              // reports: scope.filter.payload.reports,
                status: scope.query.payload.status
            };

      
      this.$store
        .dispatch("GetTimezone", data)
        .then((response) => {
          this.page.isFilterOpen = false;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.timezoneData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    getDataFromApi() {

      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
    
        scope.filter.query["skip"] = skip;
      scope.filter.query["take"] = take;
      scope.skip = skip;
     
      this.$store
        .dispatch("GetTimezone", this.filter.query)
        .then((response) => {
        
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas;
          if(response){
           datas = response.data;
           this.pagecount = response.count;
          }else{
            datas =[];
            this.pagecount = 0;
           }
        
          for (var i = 0; i < datas.length; i++) {
            this.filtervalue.push(datas[i].zone_name)
          }
          scope.timezoneData = datas;
        })
        .catch(function (error) {
          scope.page.isAddTimezone = false;
          scope.showSnackBar("error" + error);
          this.page.isEditTimezone = false;
        });
    },
    AddNewTimezone: function () {
      let scope = this
      scope.addTimezone.payload.zone_name = "";
          scope.addTimezone.payload.description = "";
          scope.addTimezone.payload.status = "";
          scope.addTimezone.payload.start_time = "";
          scope.addTimezone.payload.end_time = "";
      //var scope = this;
      this.page.isAddTimezone = true;
    },
    closeEditTimezone: function () {
      this.page.isEditTimezone = false;
    },
    closeAddTimezone: function () {
      this.page.isAddTimezone = false;
    },
    saveEditTimezone() {
      var scope = this;
      scope.$store
        .dispatch("updateTimezone", scope.editTimezone)
        .then(() => {
          scope.page.isAddTimezone = false;
          scope.showSnackBar("Updated Successfully");
          this.refreshThisPage()
          this.$refs.edittimezone.reset();
          this.page.isEditTimezone = false;
        })
        .catch(function (error) {
          scope.page.isAddTimezone = false;
          scope.showSnackBar("error" + error);
          this.page.isEditTimezone = false;
        });
      this.getDataFromApi();
    },
    saveAddTimezone() {
      var scope = this;

      scope.$store
        .dispatch("insertTimezone", scope.addTimezone.payload)
        .then(() => {
          scope.page.isAddTimezone = false;
          scope.showSnackBar("Created Successfully");

          this.refreshThisPage()
          this.$refs.timezone.reset();
          scope.addTimezone.payload.zone_name = "";
          scope.addTimezone.payload.description = "";
          scope.addTimezone.payload.status = "";
          scope.addTimezone.payload.start_time = "";
          scope.addTimezone.payload.end_time = "";
        })
        .catch(function (error) {
          scope.page.isAddTimezone = false;
          scope.showSnackBar("error" + error);
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditTimezone = true;
      this.editTimezone.id = item.id;
      this.editTimezone.zone_name = item.zone_name;
      this.editTimezone.description = item.description;
      this.editTimezone.start_time = item.start_time;
      this.editTimezone.end_time = item.end_time;
      this.editTimezone.status = item.status

    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      scope.filtervalue = [];
      this.$store
        .dispatch("deleteTimezone", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            this.refreshThisPage()
            scope.showSnackBar("Timezone Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    reset(){
        this.filter.query.zone_name = "";
        this.filter.query.status="";
        this.getDataFromApi();
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
   watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },

    'filter.search'(val){
       if(this.page.isFilterOpen){
      let scope =this    
 
       let data = {model:"timezone",column:["zone_name"]}
     
        data["search"]=val
       
       this.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          this.filtervalue=datas[0].zone_name        
       
        })
        .catch(function (error) {
        
          scope.page.isAddDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditDisposition = false;
        });
       }
    },







   },
  mounted() {
    
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:dialrule:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Timezone" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:dialrule:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:dialrule:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );

    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>