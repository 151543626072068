<template>
    <div>
        <section-definition :title="page.title" :icon="page.icon" :style="styleObject">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <div class="toplayer bmp">
                <div>
                    <div class="titlecls title">Serial Numbering Rule</div>
                </div>
                <v-form>
                    <div class="main__container">
                        <div class="subcontainer_" v-for="(data, index) in systemkeys" :key="index">
                            <!-- <v-text-field v-if="data !== 'id'" v-model="systemkeys[index]" class="field_input_text"
                                readonly></v-text-field> -->
                            <div v-if="data !== 'id'" class="field_input_text">
                                {{ humanize(systemkeys[index]) }} :
                            </div>
                            <v-select v-if="data !== 'id'" v-model="SystemSettingsData[data]" :items="option"
                                item-text="name" item-value="value" class="field_input" label="Configuration"
                                @change="systemSetup(data, SystemSettingsData[data])"></v-select>
                            <v-text-field v-if="SystemSettingsData[data] == 'perfix_serial' && data !== 'id'"
                                placeholder="Prefix Value" v-model="system_pre_suff_fixdata[data]"
                                class="field_input_text"></v-text-field>
                            <v-text-field v-if="SystemSettingsData[data] == 'suffix_serial' && data !== 'id'"
                                placeholder="Suffix Value" v-model="system_pre_suff_fixdata[data]"
                                class="field_input_text"></v-text-field>

                        </div>
                    </div>
                </v-form>
                <div style="display: flex;justify-content: flex-end;padding: 0px 15px 15px 20px;">
                    <div style="display: flex;margin-top: 15px;justify-content: flex-end">
                        <v-btn color="primary" @click="Updatesystem">Update</v-btn>
                    </div>
                </div>
            </div>

        </v-container>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
export default {
    components: {
        loader,
    },

    data() {
        return {
            valid: true,
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            page: {
                title: "System Settings",
                icon: "fa-bullhorn fa-2x",
                loading: false,
            },
            pagination: {
                rowsPerPage: 10,
            },
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            isProcessing: false,
            option: [{
                name: "Serial",
                value: "serial"
            },
            {
                name: "Serial DateTime",
                value: 'serialdatetime'
            },
            {
                name: "Serial Date",
                value: 'serialdate'
            },
            {
                name: "DateTime Serial",
                value: 'datetimeserial'
            },
            {
                name: "Date Serial",
                value: 'dateserial'
            },
            {
                name: "Prefix Serial",
                value: 'perfix_serial'
            },
            {
                name: "Suffix Serial",
                value: 'suffix_serial'
            }
            ],
            SystemSettingsData: {},
            systemkeys: [],
            system_pre_suff_fixdata: {}
        };
    },
    methods: {
        getDataFromApi() {
            var scope = this;
            scope.system_pre_suff_fixdata = {}
            this.$store
                .dispatch("GetSystemSettings")
                .then((response) => {
                    scope.page.loading = false;
                    scope.isProcessing = false
                    if (response.statusCode == 200) {
                        for (let item in response.data[0]) {
                            if (item != 'id') {
                                if (response.data[0][item].includes(':')) {
                                    let splited_value = response.data[0][item].split(':')
                                    response.data[0][item] = splited_value[0]
                                    scope.system_pre_suff_fixdata[item] = splited_value[1]
                                }
                            }
                        }
                        scope.SystemSettingsData = response.data[0]
                        scope.systemkeys = Object.keys(scope.SystemSettingsData)
                    }
                })
                .catch(function (error) {
                    scope.showSnackBar("error" + error);
                });
        },
        humanize(str) {
            var i, frags = str.split('_');
            for (i = 0; i < frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        systemSetup(data, value) {
            let scope = this
            if (value == "perfix_serial") {
                scope.system_pre_suff_fixdata[data] = ''
            } else if (value == 'suffix_serial') {
                scope.system_pre_suff_fixdata[data] = ''
            }
            else {
                if (scope.system_pre_suff_fixdata.hasOwnProperty(data)) {
                    delete scope.system_pre_suff_fixdata[data]
                }
            }
        },
        Updatesystem() {
            let scope = this
            console.log(scope.system_pre_suff_fixdata)
            let payload = { ...scope.SystemSettingsData }
            for (const key in scope.system_pre_suff_fixdata) {
                if (Object.hasOwnProperty.call(scope.system_pre_suff_fixdata, key)) {
                    const element = scope.system_pre_suff_fixdata[key];
                    if (element !== "") {
                        payload[key] = payload[key] + ":" + element
                    }
                }
            }
            scope.isProcessing = true
            console.log(payload)
            this.$store
                .dispatch("updateSystemSettings", payload)
                .then((response) => {
                    scope.page.loading = false;
                    if (response.statusCode == 200) {
                        scope.isProcessing = false
                        scope.getDataFromApi()
                        scope.showSnackBar("Successfully Updated");
                    }
                })
                .catch(function (error) {
                    scope.showSnackBar("error" + error);
                });

        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
    },
    mounted() {
        let scope = this;
        scope.page.loading = true;
        scope.getDataFromApi();
    },
};
</script>
<style>
.main__container {
    margin: 24px;

}

.subcontainer_ {
    display: flex;
    justify-content: space-between;
    width: 700px;
}

.toplayer {
    padding-top: 15px;
    border: 1px solid #cacaca;
    border-radius: 5px;
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
        rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}

.bmp {
    margin-bottom: 34px;
}

.filter_controll_edit {
    display: flex;
    justify-content: space-between;
}

.filtercontroller {
    max-width: 1120px;
    min-width: 500px;
    padding: 0 0 0 28px;
}

.filter_cls_container {
    max-width: 1120px;
    min-width: 500px;
}

.filter_controll {
    width: 250px;
    padding: 10px;
}

.filter_controll_txtclm {
    width: 150px;
    padding: 10px;
    display: flex;
}

.field-system {
    width: 224px;
    padding: 10px;
}

.field-custom {
    width: 200;
    padding: 10px;
}

.w-url {
    width: 500px;
    padding: 10px;
}

.filter_controll_cancel {
    width: 90px;
    padding: 10px;
}

.container_field {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.plusbtn {
    display: flex;
    justify-content: end;
}

.plusbtn-apibtn {
    display: flex;
    justify-content: start;
    padding: 10px;
}

.auth-field {
    display: flex;
    flex-direction: row;
}

.ptr-10 {
    padding-right: 20px;
}

.field_input {
    width: 250px !important;
    margin-right: 40px;
}

.field_input_text {
    width: 100px !important;
    margin-right: 40px;
    margin: auto;
    font-size: 15px;
}
</style>