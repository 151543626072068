import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.sla;
// var getters = {
//     GetPauseCode: state => state.Script.getdata
// };
const mutations = {
    [mutation_type.SLA]: (state, response) => {
        state.sla.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetSla: ({ commit },query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = query.skip;
                let take = query.take;
                let filter="";
                if(query.name){
                    filter =`&name=${query.name}`
                }
                // &priority=${query.priority}&status=${query.status}
                api.get(`/aster/sla?skip=${skip}&take=${take}&priority=${query.priority}&status=${query.status}`+filter).then(response => {
                  
                    commit(mutation_type.CommitNothing, {});
                    
                    
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
   
    insertSla: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/sla`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
              
                // resolve(response);
                // commit(mutation_type.CommitNothing, {});
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteSla: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                // const response = {
                //     "statusCode": 200,
                //     "message": "success"
                // }
                api
                    .delete(`/aster/sla/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateSla: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/aster/sla/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    // getters,
    mutations,
    actions
};