import config from "@/config/index";
import * as mutation_type from "../mutation-types";
const state = config.AsterLeadDelete;
var getters = {
    GetAsterLeadDelete: state => state.AsterLeadDelete.getdata
};
const mutations = {
    [mutation_type.AsterLeadDelete]: (state, response) => {
        state.AsterLeadDelete.getdata = response;
    }
};
const actions = {
    Deletedata: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.AsterLeadDelete, query);
            } catch (error) {
                reject(error);
            }
        });
    }

};
export default {
    state,
    getters,
    mutations,
    actions
};