import Vue from 'vue';

import draggable from 'vuedraggable'

import TextInput from '@/components/form_elements/FormElementTextInput'
import LongTextInput from '@/components/form_elements/FormElementLongTextInput'
import NumberInput from '@/components/form_elements/FormElementNumberInput'
import SelectList from '@/components/form_elements/FormElementSelectList'
import RadioButton from '@/components/form_elements/FormElementRadioButton'
import Checkbox from '@/components/form_elements/FormElementCheckbox'
import TimePicker from '@/components/form_elements/FormElementTimePicker'
import DatePicker from '@/components/form_elements/FormElementDatePicker'
import DatetimePicker from '@/components/form_elements/FormElementDatetimePicker'
import Rating from '@/components/form_elements/FormElementRating'
import Button from '@/components/form_elements/FormElementButton'
import Carousel from '@/components/form_elements/FormElementCarousel'
import Upload from '@/components/form_elements/FormElementUpload'
import ElSwitch from '@/components/form_elements/FormElementSwitch'
import TableComponent from '@/components/form_elements/FormElementTable'
import HtmlComponent from '@/components/form_elements/FormElementHtml'

import Elements from '@/components/form_elements/Elements'
import Properties from '@/components/form_elements/properties/Properties'

// import vm from '@/store/modules/CustomForm'
var _ = require('lodash');
import config from "@/config/index";

export const FormBuilder = new Vue({
    components: {
        Elements,
        Properties,
        draggable,
        TextInput,
        LongTextInput,
        NumberInput,
        SelectList,
        RadioButton,
        Checkbox,
        TimePicker,
        DatePicker,
        DatetimePicker,
        Rating,
        Button,
        Carousel,
        Upload,
        ElSwitch,
        TableComponent,
        HtmlComponent
    },
    data() {
        return {
            fields: [{
                fieldType: 'TextInput',
                type: '',
                label: 'Text',
                text: 'Text',
                filter:false,
                agent_show_on_filter: false,
                group: 'form', //form group
                required: false,
                isPlaceholderVisible: true,
                placeholder: '',
                unique: false,
                span: 8,
                labelWidth: 100,
                disabled: false,
                values: 'text',
                default: null,
                prepend: '',
                append: '',
                hasDefault: true,
                model: '',
                model_type: '',
                min: 5,
                input_type: 'text',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                clearable:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'LongTextInput',
                disabled: false,
                type: 'input',
                label: 'Long Text',
                text: 'Text Area',
                group: 'form',
                required: false,
                isPlaceholderVisible: true,
                placeholder: '',
                unique: false,
                span: 24,
                labelWidth: 100,
                default: null,
                hasDefault: true,
                model: '',
                model_type: '',
                min: 5,
                input_type: 'TextArea',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                clearable:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
           
            {
                fieldType: 'SelectList',
                clearable:false,
                type: 'input',
                label: 'Select',
                text: 'Select',
                group: 'form',
                required: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                span: 8,
                labelWidth: 100,
                values: [{
                    name: "No Data",
                    value: "no data"
                }],
                disabled: false,
                multiple: false,
                filter: false,
                agent_show_on_filter: false,
                default: null,
                hasDefault: true,
                model: '',
                model_type: '',
                min: 5,
                input_type: 'Select',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'RadioButton',
                clearable:false,
                type: 'input',
                label: 'Radio',
                text: 'Radio',
                filter:false,
                agent_show_on_filter: false,
                group: 'form',
                required: false,
                disabled: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                span: 8,
                labelWidth: 100,
                values: [{
                    name: "Option Label 1",
                    value: "Option 1"
                },{
                    name: "Option Label 2",
                    value: "Option 2"
                }],
                default: null,
                hasDefault: true,
                model: '',
                model_type: 'Text',
                min: 5,
                input_type: 'Radio',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'Checkbox',
                clearable:false,
                type: 'input',
                label: 'Checkbox',
                filter:false,
                agent_show_on_filter: false,
                text: 'Checkbox',
                group: 'form',
                required: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                disabled: false,
                span: 8,
                labelWidth: 100,
                values: [{
                    name: "No Data",
                    value: "No Data"
                }],
                default: null,
                hasDefault: true,
                model: '',
                model_type: 'Text',
                min: 5,
                input_type: 'Checkbox',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'TimePicker',
                clearable:false,
                type: 'input',
                label: 'Time',
                text: 'Time',
                group: 'form',
                required: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                disabled: false,
                span: 8,
                labelWidth: 100,
                default: null,
                hasDefault: true,
                model: '',
                model_type: 'Text',
                min: 5,
                input_type: 'Time',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'DatePicker',
                clearable:false,
                type: 'input',
                label: 'Date',
                text: 'Date',
                group: 'form',
                required: false,
                filter:false,
                agent_show_on_filter: false,
                disabled: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                span: 8,
                labelWidth: 100,
                default: null,
                hasDefault: true,
                model: '',
                model_type: 'Date',
                min: 5,
                input_type: 'Date',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
            {
                fieldType: 'DatetimePicker',
                clearable:false,
                type: 'input',
                label: 'Date time',
                filter:false,
                agent_show_on_filter: false,
                text: 'Date-Time',
                group: 'form',
                required: false,
                disabled: false,
                isPlaceholderVisible: false,
                placeholder: '',
                unique: false,
                span: 8,
                labelWidth: 100,
                default: null,
                hasDefault: true,
                model: '',
                model_type: 'DateTime',
                min: 5,
                input_type: 'DateTime',
                position: 0,
                isdefaultfield: false,
                show_on_view: false,
                show_on_popup: false,
                is_link: false,
                link_property: {},
                link_type: "",
                linked_to: "",
                catagory:false,
                catagory_value:'',
                editable:false,
                datelimit:false,
                datelimitdata:[],
            },
                // {
                //     fieldType: 'Rating',
                //     label: 'Rating',
                //     text: 'Rating',
                //     group: 'form',
                //     required: false,
                //     isPlaceholderVisible: false,
                //     unique: false,
                //     span: 8,
                //     labelWidth: 100,
                //     rateValue: 0,
                //     showText: true,
                //     disabled: false,
                //     showScore: false,
                //     scoreUnit: 'points',
                //     colors: ['#AAAAAA', '#F7BA2A', '#FF9900'],
                //     texts: ['oops', 'disappointed', 'normal', 'good', 'great'],
                //     default: null,
                //     hasDefault: true,
                //     model: '',
                //     model_type: '',
                //     min: 5,
                //     input_type: 'text'
                // },
                // {
                //     fieldType: 'ElSwitch',
                //     label: 'Switch',
                //     text: 'Switch',
                //     group: 'form',
                //     unique: false,
                //     span: 8,
                //     labelWidth: 100,
                //     activeText: '',
                //     inActiveText: ''
                // },
                // {
                //     fieldType: 'Button',
                //     label: "Text",
                //     type: 'input',
                //     text: 'Button',
                //     group: 'button',
                //     buttonText: 'Submit your form',
                //     required: false,
                //     isPlaceholderVisible: false,
                //     unique: true,
                //     span: 8,
                //     labelWidth: 100,
                //     default: null,
                //     hasDefault: true,
                //     model: '',
                //     model_type: '',
                //     min: 5,
                //     input_type: 'button',
                //     position: 0,
                //     isdefaultfield: false,
                //     show_on_view: false,
                //     show_on_popup: false,
                //     is_link: false,
                //     link_property: {},
                //     link_type: "",
                //     linked_to: "",
                //     catagory:false,
                //     catagory_value:''
                // },
                // {
                //     fieldType: 'Carousel',
                //     text: 'Carousel',
                //     group: 'static',
                //     unique: false,
                //     span: 24,
                //     labelWidth: 100,
                //     controlHeight: 150,
                //     itemsNumber: 4,
                //     items: [{
                //         url: ''
                //     }]
                // },
                // {
                //     fieldType: 'Upload',
                //     text: 'UploadFiles',
                //     group: 'form',
                //     unique: false,
                //     span: 24,
                //     uploadURL: 'https://jsonplaceholder.typicode.com/posts/'
                // },
                // {
                //     fieldType: 'TableComponent',
                //     text: 'Table',
                //     group: 'form',
                //     unique: false,
                //     span: 24,
                //     tableColumns: [{
                //         prop: 'date',
                //         label: 'Date',
                //         width: 180
                //     }, {
                //         prop: 'name',
                //         label: 'Name',
                //         width: 180
                //     }, {
                //         prop: 'address',
                //         label: 'Address'
                //     }],
                //     tableDatas: [{
                //         id: 1,
                //         edit: false,
                //         date: '2016-05-03',
                //         name: 'Tom',
                //         address: 'No. 189, Grove St, Los Angeles'
                //     }]
                // }
                // , {
                //     fieldType: 'HtmlComponent',
                //     text: 'Html',
                //     group: 'static',
                //     unique: false,
                //     span: 24,
                //     htmlContent: '<h3>Hello World</h3>',
                //     advancedOptions: ''
                // }
            ],

            sortElementOptions: {
                group: {
                    name: 'formbuilder',
                    pull: false,
                    put: true
                },
                sort: true
            },

            dropElementOptions: {
                group: {
                    name: 'formbuilder',
                    pull: 'clone',
                    put: false
                },
                sort: false,
                filter: ".is-disabled"
            }
        }
    },
    methods: {
        deleteElement(index, form) {
            const state = config.CustomForm;
            state.CustomForm.activeField = [];
            state.CustomForm.activeTabForFields = "elements";
            this.$delete(form, index);
        },

        cloneElement(index, field, form) {
            var cloned = _.cloneDeep(field) // clone deep lodash
            form.splice(index, 0, cloned)
        },

        editElementProperties(field) {
            const state = config.CustomForm;
            state.CustomForm.activeField = field;
            state.CustomForm.activeTabForFields = "properties";
        }
    }
});