<template>
  <div class="q"
    style="
      display: flex;
      justify-content: space-between;
     width:auto
    "
  >
    <v-flex class="flex_box"  v-if="widgetType === 'Table' && table_type=='Consolidate_Report'">
    
      <expandPanel
        v-if="function_area === ''"
        class="textarea"
        :info="'No Data'"
        :type="'Summarize View'"
      ></expandPanel>
      <expandPanel
        v-else
        class="textarea"
        :info="function_area"
        :type="'Summarize View'"
      ></expandPanel>
    

    </v-flex>
    <v-flex class="flex_box" >
      <expandPanel
        v-if="filter_area === ''"
        class="textarea"
        :info="'No Data'"
        :type="'Filter View'"
      ></expandPanel>
      <expandPanel
        v-else
        class="textarea"
        :info="filter_area"
        :type="'Filter View'"
      ></expandPanel>
    </v-flex>
    <v-flex class="flex_box" >
      <expandPanel
        v-if="group_area === ''"
        class="textarea"
        :info="'No Data'"
        :type="'Group View'"
      ></expandPanel>
      <expandPanel
        v-else
        class="textarea"
        :info="group_area"
        :type="'Group View'"
      ></expandPanel>
    </v-flex>
    <v-flex class="flex_box" >
      <expandPanel
        v-if="sort_area === ''"
        class="textarea"
        :info="'No Data'"
        :type="'Sort View'"
      ></expandPanel>
      <expandPanel
        v-else
        class="textarea"
        :info="sort_area"
        :type="'Sort View'"
      ></expandPanel>
    </v-flex>
  </div>
</template>
<script>
import expandPanel from "@/components/expand_bar";
export default {
  components: {
    expandPanel,
  },
  props: {
    widgetType: {
      type: String,
      default: "",
    },
    function_area: {
      type: String,
    },
    filter_area: {
      type: String,
    },
    group_area: {
      type: String,
    },
    sort_area:{
         type: String,
    },
        table_type:{
      type: String,
      default: "",
    }
  },
};
</script>
<style>
.textarea {
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
    rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}
.textarea:hover {
  box-shadow: rgba(33, 150, 243, 0.3) 0px 0px 0px 2px;
}
.flex_box{
    max-width: 280px;
    
}
</style>
