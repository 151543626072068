<template>
    <div v-if="!renderComponent" :class="!value_cls ? lds_ellipsis : edit_ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div v-else :class="value_cls ? preview_container : preview_container1">
        <v-form ref="form">
            <div v-for="(catagory, i) in catagory" :key="i" :class="value_cls ? sectionFlex1 : sectionFlex">
                <div class="catagory_title">
                    <div class="catagory_title_lfy">
                        <i style="padding: 3px 6px 3px 3px" class="fa fa-bandcamp" aria-hidden="true"></i>
                        <div style="font-weight: 500">{{ catagory }}</div>
                    </div>

                    <div v-if="uploadoption">
                        <input class="custom-file-input" ref="fileInput" type="file" @change="onFileSelected">
                        <input type="text" v-model="imagePath">
                    </div>


                </div>
                <div v-if="main_data[i] !== undefined">
                    <div class="component_flex">
                        <div v-if="value_cls" class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter((res) => {
                                if (
                                    res.model != 'lead_id' &&
                                    res.model != 'hopper_status' && res.model != 'id' &&
                                    res.model != 'user'
                                ) {
                                    if (res.model === 'list_id') {
                                        res.input_type = 'Select';
                                        res.values = add_list;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'tags') {
                                        res.input_type = 'Select';
                                        res.values = tags;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    res.disabled = false;
                                    // if (page_ == true) {
                                    // if (res.model === 'phone_number') {
                                    // res.input_type = 'Select';
                                    // // res.values = [{name:'1',value:'1'}];
                                    // res.fieldType = 'SelectList';
                                    // res.apifetch = true;
                                    // res.apidata = null;
                                    // }
                                    // }

                                }
                                return (
                                    ((res.is_link && res.link_type === 'Parent') ||
                                        (!res.is_link &&
                                            res.model != 'modified_date' &&
                                            res.model != 'lead_id' &&
                                            res.model != 'list_id' &&
                                            res.model != 'hopper_status' && res.model != 'id' &&
                                            res.model != 'subdisposition' &&
                                            res.model != 'deleted' &&
                                            res.model != 'disposition' &&
                                            res.model != 'user' &&
                                            res.model != 'ticket_id' &&
                                            res.model != 'contact_id' &&
                                            res.model != 'created_at' &&
                                            res.model != 'modified_at' &&
                                            res.model !== 'assign' &&
                                            res.model !== 'modified_by' &&
                                            res.model !== 'created_by' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'is_duplicate' &&
                                            res.model !== 'merged_to' &&
                                            res.model !== 'merged_as' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'req_id' &&
                                            res.model !== 'req_module')) && res.show_on_view == true
                                );
                            })" :key="'top' + eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter((res) => {
                                if (
                                    res.model != 'lead_id' &&
                                    res.model != 'hopper_status' && res.model != 'id' &&
                                    res.model != 'user'
                                ) {
                                    if (res.model === 'list_id') {
                                        res.input_type = 'Select';
                                        res.values = add_list;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'assign') {
                                        res.input_type = 'Select';
                                        res.values = user_data;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'tags') {
                                        res.input_type = 'Select';
                                        res.values = tags;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    res.disabled = false;
                                    // if (page_ == true) {
                                    // if (res.model === 'phone_number') {
                                    // res.input_type = 'Select';
                                    // // res.values = [{name:'1',value:'1'}];
                                    // res.fieldType = 'SelectList';
                                    // res.apifetch = true;
                                    // res.apidata = null;
                                    // }
                                    // }
                                    // res.disabled = false;
                                }
                                return (
                                    ((res.is_link && res.link_type === 'Parent') ||
                                        (!res.is_link &&
                                            res.model != 'modified_date' &&
                                            res.model != 'lead_id' &&
                                            res.model != 'hopper_status' && res.model != 'id' &&
                                            res.model != 'subdisposition' &&
                                            res.model != 'disposition' &&
                                            res.model != 'deleted' &&
                                            res.model != 'user' &&
                                            res.model != 'ticket_id' &&
                                            res.model != 'contact_id' &&
                                            res.model != 'created_at' &&
                                            res.model != 'modified_at' &&
                                            res.model !== 'modified_by' &&
                                            res.model !== 'created_by' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'is_duplicate' &&
                                            res.model !== 'merged_to' &&
                                            res.model !== 'merged_as' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'req_id' &&
                                            res.model !== 'req_module')) && res.show_on_view == true
                                );
                            })" :key="eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter(
                                (res) =>
                                    res.is_link &&
                                    res.link_type === 'Child' &&
                                    (res.fieldType === 'SelectList' ||
                                        res.fieldType === 'RadioButton')
                            )" :key="'down' + eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>

                            <div v-for="(field, eachFormIndex) in main_data[i].filter(
                                (res) =>
                                    res.is_link &&
                                    res.link_type === 'Child' &&
                                    res.fieldType != 'SelectList' &&
                                    res.fieldType != 'RadioButton' &&
                                    res.visible
                            )" :key="eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>
    
<script>
import { FormBuilder } from "@/components/form_elements/formbuilder";
// import UploadImage from 'vue-upload-image';
import { mapGetters } from "vuex";
var _ = require("lodash");

export default {
    name: "Publish",
    components: FormBuilder.$options.components,
    props: {
        insertsavepopup: Boolean,
        apidata: {
            type: Array,

        },
        requiredfield: Array,
        value_cls: {
            type: Boolean,
            default: false,
        },
        contactalldata: Array,
        contactnumid: Array,
        page_: { type: Boolean, default: false },
        support: {
            type: Boolean,
            default: false,
        }, model: {
            default: "LEAD"
        }
    },
    data() {
        return {
            uploadoption: "",
            lds_ellipsis: "lds-ellipsis",
            edit_ellipsis: "edit-ellipsis",
            renderComponent: false,
            main_data: [],
            user_data: [],
            add_list: [],
            preview_container: "preview_container",
            preview_container1: "preview_container1",
            sectionFlex: "sectionFlex",
            sectionFlex1: "sectionFlex1",
            editvalue_cls: false,
            count: 0,
            validationclone: [],
            tags: [],
            phone_numberdynamic: [],
            catagory: [],
            datetimevalidation_data: [],
            localpath: "",
            imageFile: null,
            imagePath: ''
        };
    },
    watch: {
        main_data: {

            deep: true,
            handler() {
                if (this.count == 3) {

                    this.editvalue_cls = true;
                }
                this.count = this.count + 1;
                this.$emit("update", this.main_data);
            },
        },
        insertsavepopup() {
           
            this.savepopupstrigger();
        },
        apidata() {
            if (!this.editvalue_cls) {
                for (let i = 0; i < this.main_data.length; i++) {
                    this.main_data[i].forEach((res) => {
                        this.apidata.forEach((val) => {
                            for (const key in val) {
                                if (res.fieldType === "Checkbox") {
                                    res.value.value = val[res.value.name];
                                } else {
                                    if (res.value.name === key) {
                                        res.value.value = val[res.value.name].toString();
                                    }
                                }
                            }
                        });
                    });
                }
            }
        },
    },
    methods: {
        onFileSelected(event) {


            const file = event.target.files[0];
            console.log(this.isValidFileSize(file), "xccxcxc")
            if (file && this.isValidFileSize(file)) {
                this.uploadImage(file);
            } else {
                console.error('Invalid file size');
            }
        },
        isValidFileSize(file) {
            const maxSize = 1024 * 1024; // 1MB

            return file.size <= maxSize;
        },
        uploadImage(file) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    if (img.width == 140 || img.height == 140) {
                        console.error('Image dimensions must be 140 x 140');
                        return;
                    }
                    this.imageFile = file;
                    this.imagePath = file.name; // or set it to the path returned by the server

                    const formData = new FormData();
                    formData.append('image', file);

                    formData.append("file", file);
                    formData.append("template_name", this.$route.query.module);
                    this.localpath = formData
                    this.$emit("imagedata", this.localpath);

                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        },

        savepopupstrigger() {
            if (this.insertsavepopup === true) {
                let scope = this;
                let field = [];
                this.datetimevalidation_data = []
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, "0");
                var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + "-" + mm + "-" + dd;

                this.main_data.forEach((res) => {
                    res.forEach((elm) => {
                        if (elm.model != "lead_id" && elm.model != "ticket_id") {
                            if (
                                elm.model == "modified_date" ||
                                elm.model == "modified_at" ||
                                elm.model == "created_at"
                            ) {
                                elm.value.value = today.toString();
                            }
                            if (elm.model == "hopper_status") {
                                elm.value.value = "0";
                            }
                            if (elm.model == "deleted") {
                                elm.value.value = "false";
                            }
                            if (elm.model == "created_by" || elm.model == "modified_by") {
                                elm.value.value = JSON.parse(localStorage.apiInfo).user_email;
                            }
                            if (elm.model == "disposition") {
                                elm.value.value = "new";
                            }
                            if (elm.required == true && elm.fieldType === "DatetimePicker" && elm.value.value == "") {
                                let formdata = ""
                                if (elm.label != "") {
                                    formdata = elm.label
                                } else if (elm.model != "") {
                                    formdata = elm.model
                                }
                                this.datetimevalidation_data.push(formdata)
                            }

                            if (elm.model_type == "Small Number" || elm.model_type == "BigNumber" || elm.model_type == "Number") {
                                if (elm.value.value == "") {
                                    elm.value.value = null
                                }

                            }


                            if(elm.model !== "duration"){
                                field.push(elm.value);
                            }
                        }
                        if (elm.fieldType === "Checkbox") {
                            elm.value.value = elm.value.value.toString();
                        }
                    });
                });

                let payload = {
                    template_name: scope.forms[0].template_name,
                    fields: field,
                };
                let formdata = {}
                let formvalidate_status = this.$refs.form.validate()
                if (formvalidate_status == true && this.datetimevalidation_data.length == 0) {
                    if (this.page_ == false && this.model === `LEAD`) {
                        scope.$store
                            .dispatch("insertCustomData", payload)
                            .then((res) => {
                                
                                console.log(res,"djkshjkdhjakhdjashdjashdjsahcjashdcjsach")
                                if (res.data.statusCode === 201) {
                                    if (this.localpath !== "") {
                                        this.localpath.append("id", res.data.id);
                                        this.localpath.append("tenent", JSON.parse(localStorage.apiInfo).tenant_code);
                                        scope.$store.dispatch('insertimage', this.localpath).then(() => {
                                            this.$emit("insertleads", true);
                                            this.insertsavepopup = false;
                                            this.localpath=""
                                        }).catch((error) => {
                                            this.insertsavepopup = false;
                                            console.log(error);
                                        });

                                    } else {
                                        this.$emit("insertleads", true);
                                        this.insertsavepopup = false;
                                    }

                                } else {
                                    this.insertsavepopup = false;
                                }
                            })
                            .catch((error) => {
                                this.insertsavepopup = false;
                                console.log(error);
                            });
                    }
                } else if (this.datetimevalidation_data.length > 0 && formvalidate_status == false) {
                    formdata.errorpopup_common = true
                    formdata.errorpopup_datetime = true
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }
                else if (this.datetimevalidation_data.length > 0) {
                    formdata.errorpopup_common = false
                    formdata.errorpopup_datetime = true
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }
                else if (this.$refs.form.validate() == false) {
                    formdata.errorpopup_common = true
                    formdata.errorpopup_datetime = false
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }

                if (this.page_ == true && this.model === `TICKET`) {
                    delete payload.template_name;

                    let phone_numberfetch = payload.fields.find(
                        (e) => e.name == "phone_number"
                    );
                    let query = {
                        phone_number: Number(phone_numberfetch.value),
                    };
                    console.log("phone_numberfetch", query);
                    this.$store
                        .dispatch("TicketPhoneSearch", query)
                        .then((res) => {
                            console.log(res);
                            if (res.statusCode === 200 && res.data.length == 0) {
                                this.$emit(
                                    "Supportmsg",
                                    `Do create Contact ${phone_numberfetch.value}`
                                );
                                this.insertsavepopup = false;
                            } else {
                                payload.fields = payload.fields.map((e) => {
                                    if (e.name == "tags") {
                                        e.value = e.value.toString();
                                    }
                                    return e;
                                });
                                scope.$store
                                    .dispatch("CreateAsterTicket", payload)
                                    .then((res) => {
                                        if (res.statusCode === 201) {
                                            this.$emit("insertleads", true);
                                            this.insertsavepopup = false;
                                        } else {
                                            this.insertsavepopup = false;
                                        }
                                    })
                                    .catch((error) => {
                                        this.insertsavepopup = false;
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.insertsavepopup = false;
                            console.log(error);
                        });
                } else if (this.page_ == true && this.model === `CONTACT`) {
                    payload.islead = 0
                    payload.modified_by = JSON.parse(localStorage.apiInfo).user_email
                    scope.$store
                        .dispatch("insertAsterContact", payload)
                        .then((res) => {
                            if (res.statusCode === 201) {
                                this.$emit("insertleads", true);
                                this.insertsavepopup = false;
                            } else {
                                this.insertsavepopup = false;
                            }
                        })
                        .catch((error) => {
                            this.insertsavepopup = false;
                            console.log(error);
                        });
                }


            }
        },
        validatecheck() {
            this.datetimevalidation_data = []
            this.main_data.forEach((res) => {
                res.forEach((elm) => {
                    if (elm.model != "lead_id" && elm.model != "ticket_id") {
                        if (elm.required == true && elm.fieldType === "DatetimePicker" && elm.value.value == "") {
                            let formdata = ""
                            if (elm.label != "") {
                                formdata = elm.label
                            } else if (elm.model != "") {
                                formdata = elm.model
                            }
                            this.datetimevalidation_data.push(formdata)
                        }

                    }
                });
            });
            let formdata = {}
            let formvalidate_status = this.$refs.form.validate()
            if (this.datetimevalidation_data.length > 0 && formvalidate_status == false) {
                formdata.errorpopup_common = true
                formdata.errorpopup_datetime = true
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
            else if (this.datetimevalidation_data.length > 0) {
                formdata.errorpopup_common = false
                formdata.errorpopup_datetime = true
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
            else if (formvalidate_status == false) {
                formdata.errorpopup_common = true
                formdata.errorpopup_datetime = false
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            } else {
                formdata.errorpopup_common = false
                formdata.errorpopup_datetime = false
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
        }
    },
    created() {
        this.catagory = [];
        this.uploadoption = this.forms[0].isfileupload
        for (let i = 0; i < this.forms[0].fields.length; i++) {
            const element = this.forms[0].fields[i].catagory_value;

            if (
                this.forms[0].fields[i].model === "lead_id"
            ) {
                this.catagory.splice(0, 0, element);
            } else if (!this.catagory.includes(element)) {
                this.catagory.push(element);
            }
        }

        let catagory_arrs = [...new Set(this.catagory)];
        this.catagory = [...new Set(this.catagory)]
        this.forms[0].fields[0].catagory_arr = catagory_arrs;

        this.editfields = JSON.parse(localStorage.getItem("editfields"));

        this.main_data = [];
        let main_data = [];
        if (this.catagory !== undefined) {
            for (let j = 0; j < this.catagory.length; j++) {
                let seperate_arr = [];
                for (let i = 0; i < this.forms[0].fields.length; i++) {
                    const element = this.forms[0].fields[i];
                    if (
                        this.catagory[j] ===
                        this.forms[0].fields[i].catagory_value
                    ) {
                        element.value = {
                            name: element.model,
                            value: "",
                        };
                        seperate_arr.push(element);
                    }
                }
                main_data.push(seperate_arr);
            }
        }
        localStorage.setItem("linkfields", JSON.stringify(main_data));
    },
    beforeDestroy() {
        localStorage.setItem("editfields", JSON.stringify(false));
        this.renderComponent = false;

    },
    mounted() {
        this.validationclone = [];
        this.main_data = JSON.parse(localStorage.getItem("linkfields"));
        this.user_data = JSON.parse(localStorage.getItem("group_users"));
        var scope = this;
        let stats = {
            list_name: "",
            list_campaign: "",
            list_status: "Y",
        };
        scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {
            let data = response.data;
            let list = [];
            if (data !== undefined) {
                for (let i = 0; i < data.length; i++) {
                    list.push(data[i].list_id.toString());
                }
            }
            scope.add_list = list;
            let validation_str = "";
            for (let i = 0; i < this.main_data.length; i++) {
                let validationclone = this.main_data[i].map((res) => {
                    if (res.is_link === true) {
                        if (res.link_type === "Child") {
                            if (res.linked_to != "") {
                                if (
                                    res.fieldType != "SelectList" &&
                                    res.fieldType != "RadioButton"
                                ) {
                                    if (res.link_property === "") {
                                        return `${res.label} field select one option in Linked to property.`;
                                    } else if (res.link_property != "") {
                                        return true;
                                    }
                                } else if (
                                    res.fieldType == "SelectList" ||
                                    res.fieldType == "RadioButton"
                                ) {
                                    if (_.size(res.link_property) == 0) {
                                        return `${res.label} field give some option values in Linked to property.`;
                                    } else if (_.size(res.link_property) > 0) {
                                        if (
                                            Object.values(res.link_property).every((res) => res != "")
                                        ) {
                                            return true;
                                        } else {
                                            return `${res.label} field give some option values in Linked to property.`;
                                        }
                                    }
                                }
                            } else if (res.linked_to === "") {
                                return `${res.label} field Linked to property is Missing.`;
                            }
                        } else if (res.link_type === "Parent") {
                            return true;
                        }
                    } else if (res.is_link === false) {
                        return true;
                    }
                });
                validation_str += `${validationclone.toString()},`;
            }

            let validation = validation_str
                .slice(0, validation_str.length - 1)
                .split(",");
            let validate = validation.filter((res) => res != "true");
            setTimeout(() => {
                this.renderComponent = validation.every((res) => res == "true");
                if (this.renderComponent == false) {
                    this.$emit("closedpreview", validate);
                }
            }, 1500);
        });
        scope.$store
            .dispatch("TicketTags")
            .then((res) => {
                if (res.statusCode === 200) {
                    scope.tags = res.data.map((e) => {
                        return { name: e.name, value: e.name };
                    });
                }
            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });
    },
    computed: {
        ...mapGetters(["forms", "themingVars"]),
        cssProps() {
            // Return an object that will generate css properties key
            // to match with the themingVars
            //
            // Example output: { '--theme-primary-color': this.themingVars.primaryColor }
            var result = {
                height: "100%",
                margin: "10px",
            },
                themingVars = this.themingVars;

            for (var v in themingVars) {
                if (themingVars.hasOwnProperty(v)) {
                    var newV = "--theme-" + _.kebabCase(v),
                        suffix = "";

                    // Add px to the value if the default value contain 'px'
                    if (_.includes(newV, "size")) suffix = "px";
                    else if (_.includes(newV, "margin")) suffix = "px";
                    else if (_.includes(newV, "radius")) suffix = "px";

                    result[newV] = themingVars[v] + suffix;
                }
            }

            return result;
        },
    },
};
</script>
    
<style>
.titlecls:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #cacaca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.preview_container {
    /* margin: 10px; */
    overflow: auto;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
}

.preview_container1 {
    border: 1px solid #cacaca;
    display: flex;
    flex-direction: column;
    margin: 0px;
    overflow: auto;
    max-height: 490px;
    margin: 5px 10px 5px 18px;
    border-radius: 5px;
}

.component_flex {
    margin: 10px;
}

.component_setup {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    padding-right: 30px;
}

.sectionFlex {
    display: flex;
    /* background-color: #e9edf1; */
    /* margin: 10px; */
    border-radius: 10px;
    flex-direction: column;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
}

.sectionFlex1 {
    display: flex;
    flex-direction: column;
}

.catagory_title {
    display: flex;
    flex-direction: row;
    background-color: #e9edf1;
    padding: 10px 10px 10px 20px;
    font-size: 14px;
    justify-content: space-between;
}

.edit-ellipsis {
    position: absolute;
    right: 42%;
    height: 80px;
    top: 55%;
}

.lds-ellipsis {
    position: fixed;
    left: 55%;
    bottom: 55%;
    right: 55%;
    height: 80px;
    top: 55%;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2196f3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.edit-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2196f3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.edit-ellipsis div:nth-child(1) {
    left: 8px;
    animation: edit-ellipsis1 0.6s infinite;
}

.edit-ellipsis div:nth-child(2) {
    left: 8px;
    animation: edit-ellipsis2 0.6s infinite;
}

.edit-ellipsis div:nth-child(3) {
    left: 32px;
    animation: edit-ellipsis2 0.6s infinite;
}

.edit-ellipsis div:nth-child(4) {
    left: 56px;
    animation: edit-ellipsis3 0.6s infinite;
}

@keyframes edit-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes edit-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes edit-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.catagory_title_lfy {
    display: flex;
    flex-direction: row;
}

.custom-file-input {
    color: transparent;
    display: block !important;
    width: 150px;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Select Image';
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.custom-file-input:hover::before {
    border-color: #e3e3e3;
}

.custom-file-input:active {
    outline: 0;
}

.custom-file-input:focus {
    border: none !important;
    outline: none !important;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    border: none;
}
</style>