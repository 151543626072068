<template>
    <div id="app">

        <div>


            <v-dialog v-model="showCustomizationPopup" scrollable max-width="500px">

                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Add Widgets</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text style="height: 520px;">
                        <v-container grid-list-md>
                            <v-layout wrap>
                            <div v-if="disabled_widgets.length>0">
                                <div  style="display: flex; width: 450px" v-for="(item, index) in disabled_widgets"
                                    :key="index">
                                    <label style="
                  padding-right: 370px;
                  padding-top: 25px;
                  padding-left: 25px;
                  width: 250px;
                ">{{ item.name }}</label>
                                    <v-switch v-model="item.switch" @change="addNewWidgets(item.id, item.switch)"
                                        color="primary" flat>
                                    </v-switch>
                                </div>
                                
                            </div>
                            <div v-else class="nodata"> No Data Available</div>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="showCustomizationPopup = false">Cancel</v-btn>
                        <v-btn color="primary" @click="addWidget">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="saveRowdata" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary" style="padding:0px">
                        <v-card-title style="padding:0px">
                            <span style="padding:5px ;font-size:20px">Save widget</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure to save widget</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="saveRowdata = false">Cancel</v-btn>
                        <v-btn color="primary" @click="updateMovable()">Yes, Save !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="DeleteRowdata" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary" style="padding:0px">
                        <v-card-title style="padding:0px">
                            <span style="padding:5px ;font-size:20px">Delete widget</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure to delete widget</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="DeleteRowdata = false">Cancel</v-btn>
                        <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="zindexpop" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary" style="padding:0px">
                        <v-card-title style="padding:0px">
                            <span style="padding:5px ;font-size:20px">Overlay widget</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure to Overlay widget</v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="zindexpop = false">Cancel</v-btn>
                        <v-btn color="primary" @click="savealine()">Yes, Overlay !</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <div v-if="page.options.edit" :class="class_com" id="list">
                
                <floatButton v-if="popups === false" @add="showCustomizationPopup = true" @editWidgets="editWidgets">
                </floatButton>
                <div v-if="popups === false">



                    <VueDragResize :isResizable="false" :isDraggable="false" style="background-color: #ffffff;" v-for="(rect, index) in rects" :key="index"
                        :w='rect.Movable[0].width' :h="'auto'" :minw='rect.Movable[0].width'
                        :minh="rect.Movable[0].height"  :isActive="false"
                        v-on:resizing="resize" v-on:dragging="resize" :x="rect.Movable[0].left" :y="rect.Movable[0].top"
                        :z="rect.Movable[0].zIndex">
                        <h4 style="text-align: center">{{ name }}</h4>

                        <apexcharts @reset="reset($event)" @popup="popup($event)" :chart="[rect]">
                        </apexcharts>

                    </VueDragResize>


                </div>






                <fullscreen_popup v-if="popups"  :xaxis_columns="xaxis" :filter_data="filter_data"
                    @apply="apply($event)" @close="close($event)" :widget_types="widget_types"
                    :chart_types="chart_types" :data="filterarr" :filter_value="arr">
                </fullscreen_popup>

            </div>

            <div v-else class="list1 grid-view" id="list">
                <floatButton  @save="updateWidgets"></floatButton>
                <!-- <div class="grid-view-column" v-for="index in rects.length * 29" :key="index"></div> -->
                <VueDragResize class="vdr" :preventActiveBehavior="false" :parentLimitation="true"
                    :parentW="windowHeight.toString().replace('px', '')" :parentH="rects.length * 750" style="background-color: #ffffff;" 
                     v-for="(rect, index) in rects" :key="index" 
                     :minw='360' :minh="rect.type == 'Chart'?360:480"
                    :z="rect.Movable[0].zIndex" 
                    :v-on:activated="activateEv(index)"
                    :v-on:deactivated="deactivateEv(index)" :isActive="true" :w='rect.Movable[0].width'
                    :h='rect.Movable[0].height' :x="rect.Movable[0].left" :y="rect.Movable[0].top"
                    v-on:dragging="resize($event, rect.id, index)" v-on:resizing="resize($event, rect.id, index)" >
                    <!-- {{rect.type}} {{ width }} {{ height }} {{ top }} {{ left }} -->
                    <div class="clearfix">

                        <div class="headers">
                            <div class="ico-hov ">
                                <i style=" color:rgb(75 75 75);padding:5px;padding-top:5px;font-size:25px;"
                                    @click="toTop(rect.id)" class="fa fa-angle-double-up  " aria-hidden="true"></i>
                            </div>

                            <div v-if="validation_arr[index].isactive" class="ico-hov ">
                                <i @click="saveMovable(rect.id)" 
                                    style=" color:rgb(75 75 75);padding:5px;padding-top:5px;font-size:25px;"
                                    class="fa fa-check " aria-hidden="true"></i>
                            </div>
                            <div v-else class="ico-hov hide_tick">
                                <i style=" color:rgb(75 75 75);padding:5px;padding-top:5px;font-size:25px;"
                                    class="fa fa-check " aria-hidden="true"></i>
                            </div>

                            <div class="ico-hov ">
                                <i @click="delete_widgeth(rect.id, index)"
                                    style=" left:50px ;color:rgb(75 75 75);padding:5px;padding-top:5px;font-size:25px;"
                                    class="fa fa-times-circle  " aria-hidden="true"></i>
                            </div>

                        </div>
                    </div>

                    <apexcharts :toolbarTrigger="toolbar" :chart="[rect]" :width="width" :height="height">

                    </apexcharts>
                </VueDragResize>

            </div>

        </div>

    </div>
</template>



<script>
import VueDragResize from 'vue-drag-resize';
import fullscreen_popup from "../../components/dashboard/components/full-screen-popup.vue"
import floatButton from '../../components/dashboard/components/float-button.vue'
import apexcharts from "../../components/dashboard/apexcharts/apexcharts-config.vue"


export default {
    // name: "app",

    components: {
        fullscreen_popup,
        VueDragResize,
        floatButton,
        apexcharts

    },
    props: {
        component: {
            type: String,
            default: "",
            validator: function (val) {
                return typeof val === 'string'
            }
        },
        componentIndex: {
            type: Number,
            default: 0,
            validator: function (val) {
                return typeof val === 'number'
            }
        },
        id: {
            type: Number,
            default: 1,
            validator: function (val) {
                return typeof val === 'number'
            }
        }
    },
    data() {

        return {
        
            windowHeight: `${window.innerWidth-85}px`,
            switch_Toggle: false,
            loading: false,
            Movable: null,
            toolbar: false,
            valid: false,
            class_com: 'list',
            popups: false,
            styleObject: {
                height: "80px",
                "margin-top": "-10px",
            },
            page: {

                title: "Dashboard",
                icon: "fa-building",
                options: {
                    add: true,
                    edit: true,
                    save: false
                },
            },
            main_arr: [],
            validation_arr: [],

            tick_validation: false,
            zindexpop: false,
            DeleteRowdata: false,
            saveRowdata: false,

            widget_name: "",
            data_table: "",
            widget_type: "",
            refresh_rate_sec: "",
            isShowOnDashboard: false,
            maxNoofRows: "",
            xaxis_column: "",
            yaxis_column: "",
            chart_type: "",
            column: "",
            maxNoofValues: "",
            listWidth: 0,
            listHeight: 0,
            selected_component: "",
            widget_types: ["Chart", "Table"],
            chart_types: ["Zoom Time", "polar Area", "pie", "Lable line", "Basic Line", "Basic Bar", "Group Bar", "Stacked Bar", "Column", "Column Group", "donut", "Gradient Radial", "Multiple Radial"],
            xaxis_columns: ["Disposition", "Time"],
            xaxis: [],
            yaxis: [],
            yaxis_columns: ["Inbound", "Outbound", "Drop", "Auto"],
            table_names: ["Agent Performance", "Call Summary"],
            showCustomizationPopup: false,
            seconds: [],
            max_data_per_page: 0,
            Axios: {},
            isEditing: true,
            disabled_widgets: [],
            disabled_widgets_id: [],
            enabled_widgets: [],
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            obj: {},
            filterarr: [],
            filter_data: [],
            name: "",
            arr: [],
            movable_obj: []


        };
        
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    created() {
        const scope = this;
        scope.getDependencies();
        scope.$store.dispatch("rect/clearWidgets");
        scope.loadWidgets();
        this.$store.dispatch("rect/temp_filter_api", {})
        // .then(() => {
        //     this.updatefun();

        // })

    },
    beforeDestroy() {
        const scope = this;
        scope.$store.dispatch("rect/clearWidgets");
        window.removeEventListener('resize', this.onResize);
    },

    computed: {
        rects() {

            return this.$store.state.rect.rects;
        },
        widgets() {
            return this.$store.state.rect.widgets;
        },
    },

    methods: {
        onResize() {
            this.windowHeight = `${window.innerWidth-85}px`
        },
        close(val) {
            this.filterarr = []
            this.popups = val
        },
        apply() {
            this.popups = false

        },
        popup(val) {

            this.loading = true;
            Promise.all([this.filter_datas(val),
            this.getColumns(val.data_table)], this.filterarr.push(val)).then(() => {

                setTimeout(() => {
                    this.class_com = 'list_change'
                    this.valid = true
                    this.popups = true
                    this.loading = false;
                }, 4000);
            })






        },
        reset(val) {

            this.filter_datas(val)
        },
        filter_datas(val) {





            this.$store.dispatch("rect/filter_fetch", val.widget_id).then((res) => {

                for (let i = 0; i < res.length; i++) {
                    const element = res[i];

                    let obj = {
                        field_name: element.column_name,
                        table_name: val.data_table,
                    };

                    this.$store.dispatch("rect/fetch_fields", obj).then((response) => {

                        //   this.filter_values(response);
                        element.field_value = this.filter_values(response, element.column_name);

                    });



                }

                this.filter_data = res
            })

        },
        filter_values(response, column) {
            let arr = [];
            response.forEach((res) => {

                arr.push(res[column]);

            });

            return arr;
        },
        toTop(id) {

            let pre_indexs;
            let pre_isactive;
            let indexs;
            let isactive;



            for (let i = 0; i < this.rects.length; i++) {
                const elm = this.rects[i];

                let main_obj = {};

                if (elm.id === id) {

                    indexs = elm.Movable[0].zIndex;
                    isactive = elm.Movable[0].isactive;

                    for (let j = 0; j < this.rects.length; j++) {
                        let main_obj = {};
                        const element = this.rects[j];
                        if (element.Movable[0].zIndex === 2) {

                            pre_indexs = element.Movable[0].zIndex;
                            pre_isactive = element.Movable[0].isactive;



                            main_obj.id = element.Movable[0].id;
                            main_obj.zIndex = indexs;
                            main_obj.isactive = isactive;

                            this.main_arr.push(main_obj)
                        }





                    }


                    main_obj.id = elm.id;
                    main_obj.zIndex = pre_indexs;
                    main_obj.isactive = pre_isactive;
                    this.main_arr.push(main_obj)
                }

            }

            this.zindexpop = true

        },
        savealine() {
            this.indexsave()
            this.zindexpop = false
        },
        indexsave() {

            for (let i = 0; i < this.main_arr.length; i++) {
                const element = this.main_arr[i];

                this.$store.dispatch("rect/saveMovable", element)

            }
            this.main_arr = []
            this.movable_widget()
        },
        resize(newRect, id, index) {
            this.rects.find((elm) => {

                if (elm.id === id) {


                    if (elm.Movable[0].width === newRect.width && elm.Movable[0].height === newRect.height &&
                        elm.Movable[0].top === newRect.top && elm.Movable[0].left === newRect.left) {
                        this.validation_arr[index].isactive = false
                    } else {
                        this.validation_arr[index].isactive = true
                    }

 let obj ={
                                    width: this.width,
                                    height: this.height,
                                    top: this.top,
                                    left: this.left,
                                    id: id
                                }

            elm.saveall = obj;


                }



            })

            this.width = newRect.width;
            this.height = newRect.height;
            this.top = newRect.top;
            this.left = newRect.left;


        },

        delete_widgeth(id, Index) {
            this.obj = {
                id: id,
                index: Index
            }
            this.DeleteRowdata = true;

        },
        savedelete() {
            this.$store.dispatch("rect/removeWidget", this.obj).then(() => {
                this.DeleteRowdata = false;

            })
        },
        editWidgets() {
            //this.$store.dispatch("rect/enableEdit");

            this.page.options.add = false;
            this.page.options.edit = false;
            this.page.options.save = true;
        },

        saveMovable(id) {

            this.ids = id
            this.saveRowdata = true

        },

        updateMovable() {

            this.$store.dispatch("rect/saveAllMovable", this.rects)
            this.saveRowdata = false
            this.validation_arr.find((elm) => {
                if (elm.id === this.ids) {
                    elm.isactive = false
                }
            })

            //this.validation_arr = []
            this.movable_widget()
        },

        getDependencies() {
            const scope = this;
            scope.$store.dispatch("rect/config").then((response) => {
                scope.widget_types = response.widget_types;
                scope.chart_types = response.chart_types;
                scope.table_names = response.tables;
            });
        },
        addNewWidgets(id, value) {
            const scope = this;
            if (value) {

                scope.enabled_widgets.push(id);

            } else {
                const index = scope.enabled_widgets.indexOf(id);
                if (index > -1) {
                    scope.enabled_widgets.splice(index, 1);
                }
            }

        },
        getColumns(data_table) {
            const scope = this;

            scope.$store
                .dispatch("rect/fetchTableColumn", data_table)
                .then((response) => {

                    scope.yaxis = response;
                    scope.xaxis = response;
                });
        },
        loadWidgets() {
            const scope = this;
            scope.disabled_widgets = [];
            scope.disabled_widgets_id = []

            scope.$store
                .dispatch("rect/fetchWidgets", {
                    name: "",
                    type: ""
                })
                .then((response) => {
                    response.forEach((element) => {

                        if (element.isShowOnDashboard) {
                            scope.validation_arr.push({
                                id: element.id,
                                isactive: false
                            })

                            scope.$store.dispatch("rect/fetchWidgetData", element.id);
                        } else {
                            if (element.archive === "true") {

                                let obj = {
                                    id: element.id,
                                    name: element.name,
                                    switch: false
                                }

                                scope.disabled_widgets.push(obj);
                                scope.disabled_widgets_id.push(element.id);
                            }

                        }
                    });
                });
        },

        addWidget() {
            const scope = this;
            
            scope.$store.dispatch("rect/changeWidget", scope.enabled_widgets)
                .then(() => {
                    scope.enabled_widgets = [];
                    scope.showCustomizationPopup = false;
                    scope.loadWidgets();
                    scope.$store.dispatch("rect/clearWidgets");
                    scope.widget_name = "";
                    scope.data_table = "";
                    scope.widget_type = "";
                    scope.xaxis_column = "";
                    scope.yaxis_column = "";
                    scope.isShowOnDashboard = "";
                    scope.chart_type = "";
                    scope.maxNoofValues = 0;
                    scope.maxNoofRows = 0;
                    scope.refresh_rate_sec = 5;
                });
        },

        updateWidgets() {

            const scope = this;
            scope.$store.dispatch("rect/updateWidget")

            scope.$store.dispatch("rect/disableEdit")

            this.page.options.add = true;
            this.page.options.save = false;
            this.page.options.edit = true;
            this.movable_widget()
            this.validation_arr = []

        },
        movable_widget() {
            const scope = this;
            scope.$store.dispatch("rect/clearWidgets");
            scope.loadWidgets();
        },

        openCustomization() {
            this.showCustomizationPopup = true;
        },

        activateEv(index) {

            this.$store.dispatch("rect/setActive", {
                id: index
            });
        },

        deactivateEv(index) {

            this.$store.dispatch("rect/unsetActive", {
                id: index
            });
        },

    },

};
</script>
<style>
.filter_class {
    font-size: 30px;
}

.txt {
    right: 0;
    width: 300px;
}

.txt2 {
    margin-right: 50px;
}

.headers {
    width: 100%;
    height: 50%;
}

.clearfix {
    width: auto;
    display: flex;
    justify-content: 'space-between';
    padding: 0px 0px 0px 10px;
    position: absolute;
    z-index: 40;
    right: 0;
    left: auto;
    /* border-radius: 5px;
    box-shadow: rgb(33 150 243 5%) 0px 6px 24px 0px, rgb(33 150 243 8%) 0px 0px 0px 1px;
    margin: 5px;
    border: #cacaca 1px solid; */
}
.nodata{    padding-left: 156px;
}

#close-icon {
    position: absolute;
    display: block;
    z-index: 2;
    right: 10px;
    top: 5px;

}

.vdr {
    border: solid 1px #2196f3;
    box-sizing: border-box;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: #ececec;
}

.loader_bg {
    background-color: burlywood;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 6;

}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

.moveable {
    font-family: "Roboto", sans-serif;
    margin: 0 auto;
    position: absolute;
    background-color: #ffffff;

}

#app {
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    font-family: "Lato", sans-serif;
}

.select-component {
    padding: 5px !important;
    margin: 0px !important;
    width: 150px;
    color: #2196f3 !important;
}

.list1 {
    /* max-width:windowHeight; */
    position: absolute;
    top: 5px;
    /* bottom: -48px; */
    left: 5px;
    right: 5px;
    height: 98.3%;
    overflow: hidden;
        overflow-x: auto;

    overflow-y: auto;
    box-shadow: 0 0 5px #aaa;
    /* background-color: red; */
    background-image: linear-gradient(0deg,
            transparent 24%,
            rgba(0, 0, 0, 0.1) 25%,
            rgba(0, 0, 0, 0.1) 26%,
            transparent 27%,
            transparent 74%,
            rgba(0, 0, 0, 0.1) 75%,
            rgba(0, 0, 0, 0.1) 76%,
            transparent 77%,
            transparent),
        linear-gradient(90deg,
            transparent 24%,
            rgba(0, 0, 0, 0.1) 25%,
            rgba(0, 0, 0, 0.1) 26%,
            transparent 27%,
            transparent 74%,
            rgba(0, 0, 0, 0.1) 75%,
            rgba(0, 0, 0, 0.1) 76%,
            transparent 77%,
            transparent);
    background-repeat: repeat;
    background-size: 10% 10%;

}
.list3{
    position: absolute;
    top: 5px;
    /* bottom: -48px; */
    left: 5px;
    right: 5px;
    height: 98.3%;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-box-shadow: 0 0 5px #aaa;
    box-shadow: 0 0 5px #aaa;
    background-color: #ffffff; 
}
.list {
    position: absolute;
    top: 5px;
    /* bottom: -48px; */
    left: 5px;
    right: 5px;
    height: 98.3%;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-box-shadow: 0 0 5px #aaa;
    box-shadow: 0 0 5px #aaa;
    background-color: #ffffff;
    /* margin-top: 50px; */
}

.list_change {
    position: absolute;
    top: 5px;
    /* bottom: -48px; */
    left: 5px;
    right: 5px;
    height: 98.3%;
    background-color: #ffffff;
    overflow: auto;
    box-shadow: 0 0 5px #aaa;

}

.grid-view {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto auto;
}

.grid-view-column {
    border: 1px solid #cacaca;
    min-width: 150px;
    height: 150px;
}

.box-shaddow {
    box-shadow: 10px 10px 15px 0px rgba(125, 125, 125, 1);
}

#add-widget {
    float: right;
    width: 160px;
    margin-top: 20px;
    margin-right: 0px;
    z-index: 4;
    background: #fff5f5;
}

.addWidget {
    z-index: 4;
}

.select-component {
    z-index: 4;
}

.hide_tick {
    cursor: not-allowed !important;
}

.ico-hov {
    width: 33px;
    height: 33px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 2px;
    border-radius: 50%;
    text-align: center;
    margin-top: 3px;
    cursor: pointer;
    user-select: none;
}

.ico-hov:hover {
    background-color: rgb(225, 227, 231);
}

.vdr.active:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    outline: 1px dashed #d6d6d6;
}

.change {
    position: absolute;
    top: 5px;
    /* bottom: -48px; */
    left: 5px;
    right: 5px;
    height: 98.3%;
}

.headline {
    padding-left: 0px !important;
}

/*  */
</style>
