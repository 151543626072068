import config from "@/config/index";
import api from '../../utils/axios-util';
import { CAMPAIGN, NULLVALUE } from "../mutation-types";

const state = config.campaign_status;

var getters = {
  categories: state => state.campaign.categories,
  failure: state => state.campaign.failure,
  success: state => state.campaign.success,
  available: state => state.campaign.available,
  table: state => state.campaign.table
}
const mutations = {
  [CAMPAIGN]: (state, payload) => {
    var name = [];
    var success = [];
    var failure = [];
    var available = [];
    var table = [];
    for (var i = 0; i < payload.data.data.length; i++) {
      name.push(payload.data.data[i].campaign);
      failure.push(parseInt(payload.data.data[i].unanswered));
      success.push(parseInt(payload.data.data[i].answered));
      available.push(parseInt(payload.data.data[i].available_leads));
    }
    table = payload.data.data;
    state.campaign.categories = name;
    state.campaign.failure = failure;
    state.campaign.success = success;
    state.campaign.available = available;
    state.campaign.table = table;
  },
  [NULLVALUE]: (state) => {
    state.campaign.categories = [];
    state.campaign.failure = [];
    state.campaign.success = [];
    state.campaign.available = [];
    state.campaign.table = [];
  }
};
const actions = {
  campaign: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        api.get("/realtime/campaignstatus").then(response => {
          var campaign_response = response;
          commit(CAMPAIGN, campaign_response);
          resolve(response);
        }).catch(function (error) {
          commit(CAMPAIGN, error);
          reject(error);
        });
      } catch (error) {
        commit(CAMPAIGN, error);
        reject(error);
      }
    })
  },
  emptycampaignstatu: ({ commit }) => {
    commit(NULLVALUE);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};