<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :sortOption="page.sortOption"
      :exportOptions="page.exportOptions"
      @openFilter="openTicketFilter"
      @refreshPage="refreshTicketPage"
      @exportData="exportTicketReport"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="ticketReport"
        class="elevation-1"
        :pagination.sync="pagination"
        :total-items="reporttotal"
        :rows-per-page-items="[5, 10, 25]"
      >
        <template v-slot:items="props">
          <td class="checkEmpty">{{ props.item.date }}</td>
          <td class="checkEmpty">{{ props.item.count.open ? props.item.count.open:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.pending ? props.item.count.pending:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.woc ? props.item.count.woc:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.in_progress ? props.item.count.in_progress:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.resolve ? props.item.count.resolve:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.hold ? props.item.count.hold:0 }}</td>
          <td class="checkEmpty">{{ props.item.count.closed ?props.item.count.closed:0 }}</td>


        </template>
      </v-data-table>
    </v-container>
    <!-- <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Missed Call Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div> -->
    <v-navigation-drawer
    fixed
    disable-resize-watcher
    right
    :value="page.isFilterOpen"
    class="filterNavigation"
  >
    <v-toolbar small color="secondary" style="color: white">
      <v-toolbar-title>Filter</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon flat color="white" @click="page.isFilterOpen = false">
        <i class="fas fa-close"></i>
      </v-btn>
    </v-toolbar>
    <div
      class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
    >
      <v-container class="sidenavContainer">
        <TicketbyDateFilter>
          v-if="page.isFilterOpen"
          @getDataFrom="getDataFrom"
          :appliedfilter="appliedfilter"
        ></TicketbydateFilter>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
      </v-container>
    </div>
  </v-navigation-drawer>
    <!-- <v-navigation-drawer
      fixed disable-resize-watcher
      right
      :value="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <TicketbyDateFilter
          v-if="page.isFilterOpen"
          @getDataFrom="getDataFrom"
          :appliedfilter="appliedfilter"
        ></TicketbyDateFilter>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
      </v-container>
      </div>
    </v-navigation-drawer> -->
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import TicketbyDateFilter from "./Components/TicketbyDateFilter";
import { mapGetters } from "vuex";
// import pdfexport from "./Components/pdfexport";
import moment from "moment";
export default {
  components: {
    loader,
    TicketbyDateFilter,
    // pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.page.loading = false;
    this.dateformate();
    // this.$store.dispatch("reportuserlist");
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:ticket:export"
          ) !== -1
        ) {
          scope.page.exportOptions =  [];
        } else {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        }
      }.bind(scope),
      1
    );
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Ticket By Date",
        icon: "fa fa-bullhorn fa-2x",
        loading: true,
        materialicon: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        sortOption:[],
        options: { filter: true, refresh: true },
      },
      dialog: false,
      pagination: {
        rowsPerPage: 10,
      },
      headers: [
        {
          text: "Date",
          align: "left",
          sortable: true,
          class: "p0",
          value: "calldate",
        },
         {
          text: "Open",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
             {
          text: "Pending",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
             {
          text: "woc",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
             {
          text: "Inprogress",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
             {
          text: "Resolved",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
             {
          text: "Hold",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
        },
        
        {
          text: "Closed",
          align: "left",
          sortable: false,
          class: "p0",
          value: "phone",
        },
       
      ],
      // pdf: {
      //   header: [
      //     {
      //       text: "Date Time",
      //       align: "left",
      //       sortable: true,
      //       value: "call_datetime",
      //     },
      //     {
      //       text: "Did Number",
      //       align: "left",
      //       sortable: true,
      //       value: "did_number",
      //     },
      //     {
      //       text: "Phone Number",
      //       align: "left",
      //       sortable: true,
      //       value: "phone",
      //     },
      //     {
      //       text: "Queue",
      //       align: "left",
      //       sortable: false,
      //       value: "queue",
      //     },
      //     {
      //       text: "Application",
      //       align: "left",
      //       sortable: true,
      //       value: "last_app",
      //     },
      //     {
      //       text: "Duration",
      //       align: "left",
      //       sortable: true,
      //       value: "duration",
      //     },
      //   ],
      // },
      agentactivityreport: [],
      reporttotal: 0,
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        detail: "calldetails",
        descending: "",
        export: "exportcalldetails",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      // pdf_data: [],
      from_date: "",
      to_date: "",
      appliedfilter: {
        phonenumber: "",
        did: "",
        queue: "",
        fromdate: "",
        todate: "",
        flag: "",
        last_app: "",
      },
      page_flag: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["ticketReport"]),
  },
  methods: {
    closeModel: function () {
      this.dialog = false;
    },
    openTicketFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshTicketPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.dateformate();
      scope.refreshvalue();
      scope.getDataFromApi();
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.type = "";
      scope.appliedfilter.station = "";
      scope.appliedfilter.agent = "";
      scope.appliedfilter.did = "";
      scope.appliedfilter.queue = "";
      scope.appliedfilter.dispo = "";
      scope.appliedfilter.status = "";
    },
    exportTicketReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        // this.pdf_flag = true;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    resetvalue() {
      var to_date = new Date();
      to_date = moment(to_date).format();
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format();
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.phonenumber = "";
      this.appliedfilter.type = "";
      this.appliedfilter.station = "";
      this.appliedfilter.agent = "";
      this.appliedfilter.did = "";
      this.appliedfilter.queue = "";
      this.appliedfilter.dispo = "";
      this.appliedfilter.status = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      this.appliedfilter.last_app = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.page.isFilterOpen = false;
      this.getDataFromApi();
    },
    getDataFromApi() {
      //reject
      var scope = this;
      this.payload.to_date = this.to_date;
      this.payload.from_date = this.from_date;
      var { page, rowsPerPage } = this.pagination;
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }
      if (page != "" || page != undefined || page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise((resolve) => {
        var { descending } = scope.pagination;
        this.payload.descending = descending;
        scope.$store
          .dispatch("ticketbyDate", this.payload)
          .then((response) => {
            this.page.isFilterOpen = false;
            if(response.status === 204) {
              scope.$store.commit('ticketReport',[]);
            }
            else {
              scope.page.loading = false;
              if (scope.page_flag) {
                this.pagination.page = 1;
                scope.page_flag = false;
              }
              scope.total = response.total;
              scope.reporttotal = scope.total;
              resolve(scope.total);
            }
            /* if (response.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            } */
          });
      });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },

    export(option) {
      this.excel_data = [];
      this.txt_data = [];
      // this.pdf_data = [];
      var add_details = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        ticketdetails: "ticketdetails",
      };
      this.$store.dispatch("exportdatafetch", add_details).then((response) => {
        this.exports = response;
        console.log(response)
        for (var i = 0; i < this.exports.length; i++) {
   /*        if (response[i].phone_number) {
            var mobile_number = response[i].phone_number;
            var reg = new RegExp("[&/\\#,+()$~%.'\":*?<>{}]", "gi");
            var phone_number = mobile_number.replace(reg, "");
            var phone = phone_number.replace(" ", "");
          }
          if (response[i].calldate) {
            var date = new Date(response[i].calldate);
            var options = {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };
            var timeString = date.toLocaleString("en-US", options);
            this.call_date = response[i].calldate.substr(0, 11) + timeString;
          } */
          
          this.excel_data.push({
            Date: response[i].date,
            open: response[i].count.open ?response[i].count.open:0,
            pending: response[i].count.pending ?response[i].count.pending:0,
            woc: response[i].count.woc ?response[i].count.woc:0,
            in_progress: response[i].count.in_progress ?response[i].count.in_progress:0,
            hold: response[i].count.hold ?response[i].count.hold:0,
            closed: response[i].count.closed ?response[i].count.closed:0

          });
          this.txt_data.push({
                Date: response[i].date,
            open: response[i].count.open ?response[i].count.open:0,
            pending: response[i].count.pending ?response[i].count.pending:0,
            woc: response[i].count.woc ?response[i].count.woc:0,
            in_progress: response[i].count.in_progress ?response[i].count.in_progress:0,
            hold: response[i].count.hold ?response[i].count.hold:0,
            closed: response[i].count.closed ?response[i].count.closed:0
          });
          // this.pdf_data.push([
          //   this.call_date,
          //   phone,
          //   response[i].queue,
          //   response[i].last_app,
          //   response[i].did_number,
          //   response[i].duration,
          // ]);
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = [
            "Date",
            "open",
            "pending",
            "woc",
            "in_progress",
            "hold",
            "closed"
          ];
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          // txtContent +=
          //   "Date Time,DID Number,Phone Number,Queue,Application,Duration\r\n";
          //  csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);
          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "ticketreport" + date2 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          let csvContent = "";
          csvContent +=
             "Date,open,pending,woc,in_progress,hold,closed\r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });

            for (let l = 0; l < data1.length; l++) {
              if (data1[l] != null && data1[l] != undefined && data1[l] != "" && typeof data1[l] == "string") {
                if (data1[l].includes(",")) {
                  data1[l] = data1[l].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date11 = new Date();
          date11 = moment(date11).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "ticketreport_" + date11 + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      });
    },

    refreshvalue() {
      this.$store.commit("FILTER_PHONE", { phone: "" });
      this.$store.commit("FILTER_DID", { did: "" });
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.$store.commit("FILTER_LAST_APP", { last_app: "" });
    },
  },

  beforeDestroy() {
    this.$store.commit("FILTER_PHONE", { phone: "" });
    this.$store.commit("FILTER_DID", { did: "" });
    this.$store.commit("FILTER_QUEUE", { queue: "" });
    this.$store.commit("FILTER_LAST_APP", { last_app: "" });
  },
};
</script>