<template>
  <div class="a">
    <v-form fluid="true" class="maincontainer">
      <div class="subcontainer">
        <v-text-field
          v-model="create.widget_name"
          :error-messages="nameErrors"
          @change="passdata(create.widget_name, 'widget_name')"
          :rules="[(v) => !!v || 'Name is required']"
          label="Name"
          required
        ></v-text-field>
      </div>
      <div class="subcontainer">
        <v-select
          :items="widget_types"
          label="Widget Type"
          v-model="create.widget_type"
          :error-messages="WidgetTypeErrors"
          @change="passdata(create.widget_type, 'widget_type')"
          :rules="[(v) => !!v || 'Widget Type is required']"
          required
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer">
        <v-select
          :items="table_names"
          label="Data Table"
          item-text="name"
          item-value="value"
          return-string
          v-model="create.data_table"
          :error-messages="DataTableErrors"
          @change="getColumns(create.data_table)"
          required
          :rules="[(v) => !!v || 'Data Table is required']"
          :disabled="create.widget_type == ''"
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer" v-if="create.widget_type == 'Table'">
        <v-select
          :items="table_type"
          label="Table Type"
          :error-messages="ChartTypeErrors"
          v-model="create.table_type"
          @change="passdata(create.table_type, 'chart_type')"
          required
          :rules="[(v) => !!v || 'Table Type is required']"
          persistent-hint
        ></v-select>
      </div>
      <div
        class="subcontainer"
        v-if="
          create.table_type == 'Detail_Report' && create.widget_type == 'Table'
        "
      >
        <v-autocomplete
          required
          label="Detail_Report"
          v-model="create.table_columns"
          :items="table_columns"
          :error-messages="ChartTypeErrors"
          @change="pass_function(create.table_columns)"
          :rules="[(v) => !!v || 'Detail_Report required']"
          persistent-hint
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-tile ripple @click="toggle">
              <v-list-tile-action>
                <v-icon
                  :color="
                    create.table_columns.length > 0 ? 'indigo darken-4' : ''
                  "
                  >{{ icon }}</v-icon
                >
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Select All</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ create.table_columns.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </div>
      <div class="subcontainer">
        <v-select
          :items="seconds"
          label="Refresh rate"
          v-model="create.refresh_rate_sec"
          :error-messages="RefreshrateErrors"
          @change="passdata(create.refresh_rate_sec, 'refresh_rate_sec')"
          :rules="[(v) => !!v || 'Refresh rate is required']"
          required
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer" v-if="create.widget_type === 'Chart'">
        <v-select
          required
          :items="chart_types"
          item-text="name"
          item-value="value"
          return-string
          label="Chart Type"
          v-model="create.chart_type"
          :error-messages="ChartTypeErrors"
          :rules="[(v) => !!v || 'Chart Type is required']"
          @change="passdata(create.chart_type, 'chart_type')"
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer" v-if="create.widget_type === 'Chart'">
        <v-select
          required
          :items="xaxis_columns"
          label="Params"
          item-text="name"
          item-value="value"
          return-string
          v-model="create.xaxis_column"
          :error-messages="ParamsErrors"
          @change="passdata(create.xaxis_column, 'xaxis_column')"
          :rules="[(v) => !!v || 'Params is required']"
          :disabled="create.data_table == ''"
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer" v-if="create.widget_type === 'Chart'">
        <v-select
          required
          :items="yaxis_columns"
          label="Value"
          item-text="name"
          item-value="value"
          return-string
          v-model="create.yaxis_column"
          :error-messages="ValueErrors"
          @change="passdata(create.yaxis_column, 'yaxis_column')"
          :rules="[(v) => !!v || 'Value is required']"
          :disabled="create.data_table == ''"
          persistent-hint
        ></v-select>
      </div>
      <div class="subcontainer" v-if="create.widget_type === 'Table'">
        <v-text-field
          required
          type="number"
          v-model="create.row_limit"
          :error-messages="TableTypeErrors"
          @change="passdata(create.row_limit, 'row_limit')"
          :rules="[(v) => !!v || 'Row Limit is required']"
          label="Row Limit"
        ></v-text-field>
      </div>
      <div class="subcontainer" v-if="create.widget_type === 'Chart'">
        <v-text-field
          required
          type="number"
          v-model="create.row_limit"
          :error-messages="TableTypeErrors"
          @change="passdata(create.row_limit, 'row_limit')"
          :rules="[(v) => !!v || 'Row Limit is required']"
          label="Row Limit"
        ></v-text-field>
      </div>
    </v-form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

export default {
  mixins: [validationMixin],
  validations: {
    create: {
      widget_name: { required },
      data_table: { required },
      widget_type: { required },
      table_type: { required },
      refresh_rate_sec: { required },
      chart_type: { required },
      xaxis_column: { required },
      yaxis_column: { required },
      row_limit: { required },
    },
  },
  mounted() {
    const scope = this;
    var widgetTable = JSON.parse(localStorage.getItem("widgetTable"));
   
    scope.widget_types = widgetTable.widget_types;
    scope.chart_types = widgetTable.chart_types;
   
    widgetTable.tables.forEach((eml) => {
    
        scope.table_names.push(eml);
     
    });

    for (let i = 5; i <= 90; i++) {
      if (i % 5 == 0) {
        scope.seconds.push(i);
      }
    }
  },
  watch: {
    yaxis_columns_() {
      this.yaxis_columns = this.yaxis_columns_;
      this.table_columns = [
        ...new Set(this.yaxis_columns_.map((elm) => elm.name)),
      ];
    },
    xaxis_columns_() {
      this.xaxis_columns = this.xaxis_columns_;
    },
    edit_arr() {
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      for (let i = 0; i < this.edit_arr.length; i++) {
        const element = this.edit_arr[i];
        this.create.isactive = element.isactive;
        this.create.widget_name = element.widget_name;
        this.create.data_table = element.data_table;
        this.create.widget_type = element.widget_type;
        this.create.refresh_rate_sec = element.refresh_rate_sec;
        this.create.row_limit = element.row_limit;
        this.create.xaxis_column = element.xaxis_column;
        this.create.yaxis_column = element.yaxis_column;
        this.create.chart_type = element.chart_type;
        this.create.column = element.column;
        this.create.isShowOnDashboard = element.isShowOnDashboard;
        this.create.max_data_per_page = element.max_data_per_page;
        this.create.table_type = element.chart_type;
        let editclmdata = element.xaxis_column.split(",");
        let resdata = editclmdata.map((elm) =>
          titleCase(elm.replaceAll("_", " "))
        );
        this.create.table_columns = resdata;
      }
    },
  },
  props: {
    edit_arr: {
      type: Array,
    },
    yaxis_columns_: {
      type: Array,
    },
    xaxis_columns_: {
      type: Array,
    },
  },
  data() {
    return {
      create: {
        widget_name: "",
        data_table: "",
        widget_type: "",
        refresh_rate_sec: "",
        row_limit: "",
        xaxis_column: "",
        yaxis_column: "",
        chart_type: "",
        column: "",
        isShowOnDashboard: false,
        max_data_per_page: 0,
        isactive: false,
        table_type: "",
        table_columns: [],
      },
      xaxis_columns: [],
      yaxis_columns: [],
      seconds: [],
      chart_types: [
        "Zoom Time",
        "polar Area",
        "pie",
        "Lable line",
        "Basic Line",
        "Basic Bar",
        "Group Bar",
        "Stacked Bar",
        "Column",
        "Column Group",
        "donut",
        "Gradient Radial",
        "Multiple Radial",
      ],
      widget_types: ["Chart", "Table"],
      table_names: [],
      response_data: [],
      table_type: ["Detail_Report", "Consolidate_Report"],
      table_columns: [],
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.create.widget_name.$dirty) return errors;
      !this.$v.create.widget_name.required && errors.push("Name is required");
      return errors;
    },
    WidgetTypeErrors() {
      const errors = [];
      if (!this.$v.create.widget_type.$dirty) return errors;
      !this.$v.create.widget_type.required &&
        errors.push("Widget Type is required");
      return errors;
    },
    DataTableErrors() {
      const errors = [];
      if (!this.$v.create.data_table.$dirty) return errors;
      !this.$v.create.data_table.required &&
        errors.push("Data Table is required");
      return errors;
    },
    TableTypeErrors() {
      const errors = [];
      if (!this.$v.create.table_type.$dirty) return errors;
      !this.$v.create.table_type.required &&
        errors.push("Table Type is required");
      return errors;
    },
    RefreshrateErrors() {
      const errors = [];
      if (!this.$v.create.refresh_rate_sec.$dirty) return errors;
      !this.$v.create.refresh_rate_sec.required &&
        errors.push("Refresh rate is required");
      return errors;
    },
    ChartTypeErrors() {
      const errors = [];
      if (!this.$v.create.chart_type.$dirty) return errors;
      !this.$v.create.chart_type.required &&
        errors.push("Chart Type is required");
      return errors;
    },
    ParamsErrors() {
      const errors = [];
      if (!this.$v.create.xaxis_column.$dirty) return errors;
      !this.$v.create.xaxis_column.required &&
        errors.push("Params is required");
      return errors;
    },
    ValueErrors() {
      const errors = [];
      if (!this.$v.create.yaxis_column.$dirty) return errors;
      !this.$v.create.yaxis_column.required && errors.push("Value is required");
      return errors;
    },
    RowLimitErrors() {
      const errors = [];
      if (!this.$v.create.row_limit.$dirty) return errors;
      !this.$v.create.row_limit.required &&
        errors.push("Row Limit is required");
      return errors;
    },
    Allcolumnselect() {
      return this.create.table_columns.length === this.table_columns.length;
    },
    selecticon() {
      return this.create.table_columns.length > 0 && !this.Allcolumnselect;
    },
    icon() {
      if (this.Allcolumnselect) return "mdi-close-box";
      if (this.selecticon) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    getText(item) {
      return `${item.name} - ${item.text}`;
    },
    validate() {
    
      let count;
      if (this.$v.create.widget_type.$invalid == true) {
        count = 0;
        this.$v.create.widget_name.$invalid == false
          ? count++
          : this.$v.create.widget_name.$touch();
        this.$v.create.data_table.$invalid == false
          ? count++
          : this.$v.create.data_table.$touch();
        this.$v.create.refresh_rate_sec.$invalid == false
          ? count++
          : this.$v.create.refresh_rate_sec.$touch();
        this.$v.create.widget_type.$invalid == false
          ? count++
          : this.$v.create.widget_type.$touch();
        return count;
      } else if (
        this.$v.create.widget_type.$invalid == false &&
        this.create.widget_type == "Table"
      ) {
        count = 0;
        this.$v.create.widget_name.$invalid == false
          ? count++
          : this.$v.create.widget_name.$touch();
        this.$v.create.data_table.$invalid == false
          ? count++
          : this.$v.create.data_table.$touch();
        this.$v.create.refresh_rate_sec.$invalid == false
          ? count++
          : this.$v.create.refresh_rate_sec.$touch();
        this.$v.create.row_limit.$invalid == false
          ? count++
          : this.$v.create.row_limit.$touch();
        return count;
      } else if (
        this.$v.create.widget_type.$invalid == false &&
        this.create.widget_type == "Chart"
      ) {
        count = 0;
        this.$v.create.widget_name.$invalid == false
          ? count++
          : this.$v.create.widget_name.$touch();
        this.$v.create.data_table.$invalid == false
          ? count++
          : this.$v.create.data_table.$touch();
        this.$v.create.chart_type.$invalid == false
          ? count++
          : this.$v.create.chart_type.$touch();
        this.$v.create.xaxis_column.$invalid == false
          ? count++
          : this.$v.create.xaxis_column.$touch();
        this.$v.create.yaxis_column.$invalid == false
          ? count++
          : this.$v.create.yaxis_column.$touch();
        this.$v.create.refresh_rate_sec.$invalid == false
          ? count++
          : this.$v.create.refresh_rate_sec.$touch();
        this.$v.create.row_limit.$invalid == false
          ? count++
          : this.$v.create.row_limit.$touch();
        return count < 5 ? 0 : count;
      }
    },

    // delete
    getempy(val) { //same function

      if(val=="Table"){
          this.create.xaxis_column  =""
          this.create.yaxis_column  =""
          this.create.chart_type=""
      }else if(val =="Chart"){
          this.create.xaxis_column  =""
          this.create.yaxis_column  =""
          this.create.chart_type=""
          this.create.table_type=""
          this.create.table_columns = [];
      }else if(val=="Consolidate_Report" || val=="Detail_Report"){
          this.create.table_columns = [];
          this.create.xaxis_column  =""
          this.create.yaxis_column  =""
      }

    },
    passdata(data, field) {



      this.$emit("layeroneDatePass", `${field}.${data}`);
      this.$emit("layerone", `${field}.${data}`); // unused emit
    },
    getColumns(table) {
      const scope = this;
      scope.$store.dispatch("rect/fetchTableColumn", table).then((response) => {
        let arr = [];
        arr.push(response);
        arr.push(table);
        this.$emit("tablevalue", table);
        this.$emit("layeroneDate", arr);
        this.$emit("layeroneDates", arr); // unused emit
        scope.response_data = response;
        scope.yaxis_columns = response;
        scope.xaxis_columns = response;
        scope.table_columns = [...new Set(response.map((elm) => elm.name))];
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.Allcolumnselect) {
          this.create.table_columns = [];
          this.passdata("", "xaxis_column");
        } else {
          this.create.table_columns = this.table_columns.slice();
          this.passdata(
            this.create.table_columns
              .toString()
              .toLowerCase()
              .replaceAll(" ", "_"),
            "xaxis_column"
          );
        }
      });
    },
    pass_function(val) {
      this.passdata(
        val.toString().toLowerCase().replaceAll(" ", "_"),
        "xaxis_column"
      );
    },
  },
};
</script>
<style>
.maincontainer {
  margin: 24px;
  margin-top: 0px;
  width:auto;
  min-width: 500px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.subcontainer {
  width: 300px;
}
</style>
