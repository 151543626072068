<template>
  <div class="SelectListlabelmain">
    
    <v-autocomplete :class="[currentField.required?required_class :currentField.readonly?read_only:others]" :readonly="currentField.clearable" v-if="currentField.multiple" :disabled="disable_val" 
      @change="getChildProperties(true)"
      :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" multiple item-text="name"
      item-value="value" :items="currentField.values" v-model="multivalue"
      :menu-props="{ top: true, offsetY: true }" :label="`${currentField.label}`" :rules="required_rule">
    </v-autocomplete>
    <v-autocomplete :class="[currentField.readonly?read_only :currentField.required?required_class:others]" :readonly="currentField.clearable" v-else :disabled="disable_val"  @change="getChildProperties(true)"
      :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" item-text="name"
      item-value="value" :items="currentField.values" v-model="currentField.value.value"
      :menu-props="{ top: true, offsetY: true }" :label="`${currentField.label}`" :rules="required_rule">
    </v-autocomplete>
  </div>


</template>

<script>
import fetchData from "@/api/fetch-data";
import qs from "qs";

export default {
  name: "SelectList",
  props: ["currentField", "Allfields"],
  data() {
    return {
      update: true,
      value: "",
      loading: false,
      child_validator: 0,
      disable_val: false,
      required_class:"required",
            others:"others",
             read_only:"readonly",
      multivalue:[],
      required_rule:[]
           
    };
  },
  mounted() {

    if (this.currentField.link_type === "Child") {
      this.disable_val = true
    } else if (this.currentField.disabled) {
      this.disable_val = true
    }

    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }
    if(this.currentField.multiple){
    if(this.currentField.value.value =="" && this.currentField.value.value ==" "){
    this.multivalue =[] }else{
    this.currentField.value.value.split(',')
    }
    }
    
    if(this.currentField.value.value !==""){
      this.getChildProperties(false)
    }
    if (this.currentField.required == true) {
      this.required_rule = [v => !!v || `${this.currentField.label} is required`]
    }
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      attr.clearable = this.currentField.clearable;
      attr.multiple = this.currentField.multiple;
      attr.filterable = this.currentField.filterable;
      attr.remote = this.currentField.remote;
      if (attr.remote) {
        attr.remoteMethod = this.remoteMethod;
      }
      return attr;
    },
  },
  methods: {
    reload() {
      this.update = false;
      this.$nextTick(() => {
        this.update = true;
      });
    },
    getChildProperties(data) {
      const scope = this;
      if (scope.currentField.is_link === true && scope.currentField.link_type === 'Parent') {

        scope.Allfields.find(elm => {
          if ((elm.is_link && elm.fieldType === 'SelectList') || (elm.is_link && elm.fieldType === 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to) {

              let arr = [];
              let val = elm.link_property[scope.currentField.value.value].split(',');

              val.forEach(res => {
                arr.push({ "name": res, "value": res })
              })

              elm.values = arr;

              if (data == true) {
                elm.value.value = '';
              }
              
              
            }
          } else if ((scope.currentField.multiple === false || scope.currentField.multiple === '' || scope.currentField.multiple === undefined) && (elm.is_link && elm.fieldType != 'SelectList') || (elm.is_link && elm.fieldType != 'RadioButton')) {
            if (scope.currentField.model === elm.linked_to) {

              let val = elm.link_property;
              if (val === scope.currentField.value.value) {
                elm.visible = true;
              } else {
                elm.visible = false;
                if(elm.fieldType == 'Checkbox'){
                  elm.value.value = false
                }else{
                  elm.value.value ="" 
                }
              }
            }
          }
        })
      }

      if (this.currentField.multiple == true) {
        if (this.multivalue.length > 0) {
          this.currentField.value.value = this.multivalue.toString()
        } else {
          this.currentField.value.value = ""
        }
      }

    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        let dataUrl = this.currentField.dataUrl;
        let valueField = this.currentField.valueField;
        let labelField = this.currentField.labelField;
        var param = { queryStr: query };
        let promise = fetchData.fetchOptionsData(
          dataUrl,
          labelField,
          valueField,
          qs.stringify(param)
        );
        promise.then((data) => {
          this.currentField.options = data;
          this.loading = false;
        });
      } else {
        this.currentField.options = [];
      }
    },
  },
  watch: {
    // "currentField.value.value"(){

    //   if(this.currentField.multiple ==  true){
    //     if(this.currentField.value.value =="" && this.currentField.value.value ==" "){
    //       this.multivalue =[]
    //     }else{
    //       this.multivalue =this.currentField.value.value.split(",")
    //     }
        
    //   }
      
    // },
    // "multivalue"(){
    //   if(this.multivalue.length >0){
    //     this.currentField.value.value = this.multivalue.toString()
    //   }else{
    //     this.currentField.value.value =""
    //   }
      
    // },
    "currentField.multiple"() {
      this.reload();
    },
    "currentField.values"() {
      let count = 0
      for (let i = 0; i < this.Allfields.length; i++) {
        const element = this.Allfields[i];
        if (this.currentField.linked_to === element.model && element.text === 'Checkbox') {
          if (element.value.value) {
            this.child_validator += 1
            if (this.child_validator > 0) {
              if (this.currentField.disabled) {
                this.disable_val = true
              } else {
                this.disable_val = false
              }
            }
            // this.currentField.value.value = '';
          } else {
            this.child_validator = 0
            this.disable_val = true
          }
          // this.currentField.value.value = '';
          count += 1
        }


      }
      if (count === 0) {
        this.child_validator += 1
        if (this.child_validator > 0) {
          if (this.currentField.disabled) {
            this.disable_val = true
          } else {
            this.disable_val = false
          }
        }
        // this.currentField.value.value = '';
      }

    }
  },
  created(){
    if(this.currentField.link_type == "Child"){
      let parent = this.Allfields.find((e)=>e.model == this.currentField.linked_to)
      if(parent.fieldType =="Checkbox"){
        let linked_value = this.currentField.link_property[this.currentField.linked_to]
        this.currentField.values = linked_value.split(",").map((elm)=>{
          return {name:elm,value:elm}
        })
      }else if(parent.fieldType =="RadioButton" || parent.fieldType =="SelectList"){
        let linked_value = this.currentField.link_property[parent.value.value]
        if(linked_value !== undefined){
          this.currentField.values = linked_value.split(",").map((elm)=>{
          return {name:elm,value:elm}
        })
        }
        
        
      }
      
      
    }


    if(this.currentField.multiple ==  true){
        if(this.currentField.value.value =="" && this.currentField.value.value ==" "){
          this.multivalue =[]
        }else{
          this.multivalue =this.currentField.value.value.split(",").filter(Boolean)
        }
        
      }
  },
};
</script>

<style>
.SelectListlabelmain {
  height: 85px;
  padding: 10px;
}

.SelectlistInput {
  margin: 20px 10px 10px 10px;
}

.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"@@@@";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
