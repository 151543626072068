<template>
  <view-layer>
    <div>
      <div
        v-if="summarize_edit === false"
        style="display: flex; justify-content: 'space-between'"
      >
        <v-flex v-if="summarize_function" class="summarize_function">
          <v-select
            :items="function_types"
            item-text="name"
            item-value="value"
            return-string
            label="Function Types"
            v-model="summarize_function_type"
            @change="
              summarize_function_(summarize_function_type, 'function_type')
            "
            :disabled="data_table == ''"
            persistent-hint
          ></v-select>


        </v-flex>
        <v-flex
          xs4
          v-if="summarize_function_type == 'distinct_of' && summarize_function"
          class="summarize_function"
        >
          <v-select
            :items="xaxis_columns"
            label="Select Column To Summarize"
            item-text="name"
            v-model="summarize_function_column_name_arr"
            multiple
            @change="
              summarize_function_(
                summarize_function_column_name_arr,
                'column_name'
              )
            "
            :disabled="data_table == ''"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex
          xs4
          v-if="summarize_function_type != 'distinct_of' && summarize_function"
          class="summarize_function"
        >
          <v-select
            :items="xaxis_columns"
            label="Select Column To Summarize"
            item-text="name"
            item-value="value"
            return-string
            v-model="summarize_function_column_name"
            @change="
              summarize_function_(summarize_function_column_name, 'column_name')
            "
            :disabled="data_table == ''"
            persistent-hint
          ></v-select>
        </v-flex>
        <v-flex
          xs3
          v-if="
            summarize_function_column_name &&
            summarize_function_type != 'distinct_of'
          "
          class="summarize_function"
        >
          <v-text-field
            v-model="summarize_function_alias_name"
            @change="
              summarize_function_(summarize_function_alias_name, 'alias_name')
            "
            label="Alias Name"
          ></v-text-field>
        </v-flex>
        <i
          v-if="summarize_function_column_name"
          @click="summarize_save"
          style="
            color: rgb(33, 150, 243);
            padding: 5px;
            padding-top: 40px;
            font-size: 25px;
          "
          class="fa fa-check green--text"
          aria-hidden="true"
          ></i
        >
        <i
          v-if="summarize_function"
          @click="function_cancel"
          style="
            color: rgb(33, 150, 243);
            padding: 5px;
            padding-top: 40px;
            font-size: 25px;
          "
          class="fa fa-times-circle red--text"
          aria-hidden="true"
          ></i
        >
      </div>
      <div v-if="summarize_edit">
        <div
          style="display: flex"
          v-for="(item, index) in metrics"
          :key="index"
        >
          <v-flex class="summarize_function" v-if="summarize_edit">
            <v-select
              :items="function_types"
              item-text="name"
              item-value="value"
              return-string
              label="Function Type"
              v-model="item.function_type"
              :disabled="data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex class="summarize_function" v-if="summarize_edit">
            <v-select
              :items="xaxis_columns"
              label="Select Column To Summarize"
              item-text="name"
              item-value="value"
              return-string
              v-model="item.column_name"
              :disabled="data_table == ''"
              persistent-hint
            ></v-select>
          </v-flex>
          <v-flex class="summarize_function" v-if="summarize_edit">
            <v-text-field
              v-model="item.alias_name"
              label="Alias Name"
            ></v-text-field>
          </v-flex>
          <i
            v-if="summarize_edit"
            @click="summarize_delete(item)"
            style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 40px;
              font-size: 25px;
            "
            class="fa fa-times-circle red--text"
            aria-hidden="true"
            ></i
          >
        </div>
      </div>
    </div>
  </view-layer>
</template>
<script>
export default {
  props: {
    metrics: {
      type: Array,
    },
    summarize_edit: {
      type: Boolean,
      default: false,
    },
    summarize_function: {
      type: Boolean,
      default: false,
    },
    function_types: {
      type: Array,
    },
    data_table: {
      type: String,
    },
    xaxis_columns: {
      type: Array,
    },
  },
  data() {
    return {
      summarize_function_type: "",
      summarize_function_column_name: "",
      summarize_function_column_name_arr: [],
      summarize_function_alias_name: "",
      obj: {
        function_type: "",
        alias_name: "",
        column_name: "",
      },
    };
  },
  methods: {
    summarize_function_(val, field) {

      if (
        this.summarize_function_column_name_arr.length &&
        this.summarize_function_type == "distinct_of"
      ) {
        val = this.summarize_function_column_name_arr.toString();
      }

      this.$emit("summarize_data_pass", `${field},${val}`);
    },
    summarize_save() {
      this.summarize_function_type = "";
      this.summarize_function_column_name = "";
      this.summarize_function_alias_name = "";
      this.$emit("reseticon");
      this.$emit("summarize_save");
    },
    function_cancel() {
      this.summarize_function_type = "";
      this.summarize_function_column_name = "";
      this.summarize_function_alias_name = "";
      this.$emit("reseticon");
      this.$emit("function_cancel");
    },
    summarize_delete(val) {
      this.$emit("summarize_delete", val);
    },
  },
};
</script>
<style>
.summarize_function {
  width: 200px;
  padding: 20px;
}
</style>
