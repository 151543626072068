<template>
    <div  class="longtextinputmain ">
        <v-textarea :class= "[currentField.required?required_class:currentField.readonly?read_only:others]" :readonly="currentField.clearable" :no-resize="false" :rows="1" outlined  :disabled="currentField.disabled"  dense :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" name="input-7-4" :label="`${currentField.label}`" v-model="currentField.value.value" :rules="required_rule">
        </v-textarea>
    </div>
    </template>

<script>
export default {
    name: "LongTextInput",
    props: ["currentField"],
    data() {
        return {
            textarea: "",
            required_class:"required",
            others:"others",
            read_only:"readonly",
            required_rule:[]
        };
    },
    destroyed() {
        this.currentField.value.value = '';
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr["id"] = this.currentField.model;
            attr.disabled = this.currentField.disabled;
            return attr;
        },
    },
    mounted() {
        if(this.currentField.required == true){
            this.required_rule = [ v => !!v || `${this.currentField.label} is required`]
        }
        
        if(this.currentField.hasDefault){

            if(this.currentField.value.value == ""){
            this.currentField.value.value = this.currentField.default
                
            }
        }
    }
};
</script>

<style>
.longtextinputmain {
   padding: 15px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }

</style>
