import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
var getters = {
    GetAnnouncement: state => state.Announcement.getdata
};
const mutations = {
    [mutation_type.Announcement]: (state, response) => {
        state.PauseCode.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetAnnouncement: ({commit},query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = query.skip;
                let take = query.take;
                let filter=""
                if(query.name){
                    filter +=`&name=${query.name}`
                }
                if(query.status){
                    filter +=`&status=${query.status}`
                }
                api.get(`/get/announcement?skip=${skip}&take=${take}`+filter).then(response => {
                    const announcement = response.data;
                    commit(mutation_type.Announcement, announcement);
                    const pausecode_array = [];
                    announcement.data.forEach(item => {
                        pausecode_array.push(item.pause_code);
                    });
                    localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },

    /*FilterAnnouncement: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/get/announcement?name=${query.name}&status=${query.status}`).then(response => {
                    const pausecode = response.data;
                    commit(mutation_type.Announcement, pausecode);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },*/
    insertAnnouncement:({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/announcement`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAnnouncement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
              
                api
                    .delete(`/remove/announcement/${payload}`)
                    
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAnnouncement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
               
                const id = payload.id;
                delete payload.id;
                console.log(payload,id,"hjkhkjshdjkhsjkdh")
                api
            
                    .put(`/edit/announcement/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};