import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const mutations = {
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    CreateSource: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/source/create`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetSource: ({ commit },query) => {
        return new Promise((resolve, reject) => {
            try {
                let skiptake =''
                if(query !== undefined){
                    skiptake = `?skip=${query.skip}&take=${query.take}`
                }
                api.get(`/source/fetch${skiptake}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetSourceFilter: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/source/fetch?active=${query.active}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteSource: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/source/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    UpdataSource: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/source/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    mutations,
    actions
};