/* eslint-disable prettier/prettier */
<template>
  <div class="canvas" :id="flow.name" :ref="flow.name" @scroll="positionLine">
    <json-comp :json="flowJSON" v-show="showJSONComponent"></json-comp>
    <div class="nav">
      <div class="actions-title">
        <v-subheader style="font-weight: bold">Actions</v-subheader>
      </div>
      <div class="list">
        <div
          id="audio"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.audio }}</v-icon
          >Audio
        </div>
        <div
          id="menu"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.menu }}</v-icon
          >Menu
        </div>
        <div
          id="sms"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.sms }}</v-icon
          >SMS
        </div>
        <div
          id="voicemail"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.voicemail }}</v-icon
          >Voice Mail
        </div>
        <div
          id="validation"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.validation }}</v-icon
          >Validation
        </div>
        <div
          id="connection"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.connection }}</v-icon
          >Connection
        </div>
        <div
          id="conference"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.conference }}</v-icon
          >Conference
        </div>
        <div
          id="dial"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.dial }}</v-icon
          >Dial
        </div>
        <div
          id="hangup"
          @click="isNameChanged"
          draggable="true"
          @dragstart="dragFlow($event)"
        >
          <v-icon>{{ icon.hangup }}</v-icon
          >Hangup
          <!-- <v-col cols="12" class="mt-4">
            <v-tooltip v-model="show" left>
              <template visibility="hidden"></template>
              <span>Please Change the flow name</span>
            </v-tooltip>
          </v-col> -->
        </div>
      </div>
      <div class="upl_btn">
        <v-btn @click="uploadJSON()" style="background: #2194f3; color: white">
          Save
        </v-btn>
      </div>
    </div>
    <v-snackbar
      v-model="Snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="Snackbar.timeout"
    >
      {{ Snackbar.text }}
      <v-btn color="pink" flat @click="Snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="alert" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Alert! </span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text text-bold>{{ alertmessage }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closealert()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" right :timeout="time" top color="#fff">
      <span style="color: #000">Zoom: {{ zoomalert }}</span>
    </v-snackbar>
    <div class="scrolling-canvas" @scroll="positionLine()">
      <div
        id="div1"
        @drop="drop($event)"
        @dragover="allowDrop($event)"
        @click.stop="hideFooter($event)"
        class="zoom"
      >
        <slot name="flow"></slot>
        <vue-draggable-resizable
          v-for="(item, index) in shapes"
          :key="item.id"
          :min-width="150"
          :min-height="100"
          :w="item.w"
          :h="item.h"
          :x="item.x"
          :y="item.y"
          :class="{ check: true, highlight: item.showFooter }"
          class-name="flow"
          class-name-handle="custom-handle"
          :id="item.id"
          :ref="item.id"
          :draggable="item.draggable"
          :parent="true"
          @dragging="positionLine(index)"
          @resizing="positionLine(index)"
          @activated="activated(index, item)"
          @deactivated="item.showCircle = false"
          :active="item.showFooter"
        >
          <div
            draggable="true"
            class="connect-arrow"
            @dragstart.stop="drag($event, index)"
            @mouseenter="disableDrag"
            @mouseleave="enableDrag"
            id="drag"
            v-show="item.showCircle"
          ></div>
          <div
            draggable="true"
            class="connect-arrow-f"
            @dragstart.stop="drag($event, index)"
            @mouseenter="disableDrag"
            @mouseleave="enableDrag"
            id="drag-f"
            v-show="
              item.showCircle &&
              (item.name == 'menu' ||
                item.name == 'dial' ||
                item.name == 'validation')
            "
          ></div>
          <div
            class="content"
            :id="item.id"
            @drop="dropArrow(item.id, $event, index, item)"
            @dragover="allowDrop($event)"
          >
            <div :id="item.id" class="content__header">
              <div>{{ item.title }}</div>
              <div class="header-options">
                <v-icon
                  color="#fff"
                  style="font-size: 15px"
                  @click.stop="showFlowFooter(index)"
                  >edit</v-icon
                >
                <v-icon
                  color="#fff"
                  style="font-size: 15px"
                  v-if="item.data != 'trigger'"
                  @click.stop="removeFlow(index, item)"
                  >close</v-icon
                >
              </div>
            </div>
            <div :id="item.id" class="content__description">
              <div>
                <v-icon>{{ icon[`${item.data}`] }}</v-icon>
                <span class="name">{{ item.name }}</span>
              </div>
              <div style="font-size: 10px">{{ item.id }}</div>
              <div class="description">{{ item.description }}</div>
            </div>
          </div>
        </vue-draggable-resizable>
      </div>
    </div>
    <v-btn fab samll fixed color="#2194f3" class="zoom-out" @click="zoomOut()">
      <v-icon color="#fff" light>mdi-minus</v-icon>
    </v-btn>
    <v-btn fab samll fixed color="#2194f3" class="zoom-in" @click="zoomIn()">
      <v-icon color="#fff" light>add</v-icon>
    </v-btn>
    <div
      v-for="(footer, index) in shapes"
      :key="`${index}_${footer.id}`"
      class="foot"
      v-show="footer.showFooter"
    >
      <v-tabs class="gradient-bg" dark v-model="props_tab_index">
        <v-tab href="#general">General</v-tab>
        <v-tab href="#settings" v-show="footer.data != 'hangup'"
          >Settings</v-tab
        >
        <v-tab href="#control" v-show="footer.data == 'menu'">Control</v-tab>
      </v-tabs>
      <div v-if="props_tab_index == 'general'" class="fix-height">
        <div>
          <div class="input">
            <label for="title">Title</label>
            <input
              v-model="footer.name"
              type="text"
              name="title"
              id="title"
              placeholder="Enter title text"
            />
          </div>
          <div class="input">
            <label for="desc">Description</label>
            <textarea
              placeholder="Enter description text"
              v-model="footer.description"
              name="desc"
              id="desc"
              cols="44"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
      <div v-if="props_tab_index == 'control'" class="fix-height">
        <div
          class="menu-settings"
          v-show="footer.data == 'menu' && footer.showFooter"
        >
          <div class="table-view-parallel">
            <table class="table">
              <thead>
                <tr>
                  <th>Next</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(key, i) in menuItems" :key="i">
                  <td>
                    <div class="input">
                      <input
                        width="50%"
                        display="inline"
                        v-model="menuValues[i]"
                        @input="menuControl(footer.id, i, menuValues[i])"
                        type="number"
                        id="key"
                      />
                    </div>
                  </td>
                  <td>
                    <v-select
                      :items="menuItems"
                      label="Components"
                      v-model="menuChoices[i]"
                      @input="checkValueExist(i, menuChoices[i], footer.id)"
                      persistent-hint
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="props_tab_index == 'settings'" class="fix-height">
        <div v-show="footer.data == 'dial'">
          <div class="input">
            <label for="dial-type">Dial Type</label>
            <multi-select
              id="dial-type"
              :options="dialTypes"
              v-model="footer.settings.dialType"
              placeholder="Select Dial Type"
            ></multi-select>
          </div>
          <div class="input" v-if="footer.settings.dialType === 'Queue'">
            <label for="queue">Queue</label>
            <multi-select
              id="queue"
              :options="queueTypes"
              v-model="footer.settings.queue"
              placeholder="Select Queue"
            ></multi-select>
          </div>
          <div class="input" v-if="footer.settings.dialType === 'Number'">
            <label for="caller">Number</label>
            <v-select
              :items="dialVariables"
              v-model="footer.settings.DID"
              placeholder="Select Number"
            ></v-select>
          </div>
          <div class="input" v-if="footer.settings.DID === 'Others'">
            <label for="caller">Number</label>
            <input
              v-model="footer.settings.DIDNumber"
              type="text"
              name="caller"
              id="caller"
              placeholder="Enter Phone Number"
            />
          </div>

          <div class="input" v-if="footer.settings.dialType === 'Queue'">
            <v-autocomplete
              v-model="footer.settings.users"
              :items="userData"
              label="Choose Agent"
              persistent-hint
              item-text="user_name"
              key="user_name"
              return-object
              small
              multiple
              single-line
              :allow-overflow="false"
            >
            </v-autocomplete>
          </div>
        </div>
        <div v-show="footer.data == 'hangup'"></div>
        <div class="trigger-settings" v-show="footer.data == 'trigger'">
          <div class="input">
            <label for="desc">DID</label>
            <input
              v-model="footer.settings.did"
              type="text"
              name="desc"
              id="desc"
              placeholder="Enter DID"
            />
          </div>
          <div class="input">
            <v-checkbox
              v-model="footer.settings.check_balance"
              label="Check Balance"
            ></v-checkbox>
          </div>
          <div class="input" v-if="footer.settings.check_balance">
            <label for="balance">Balance</label>
            <input
              v-model="footer.settings.balance"
              type="number"
              name="balance"
              id="balance"
              placeholder="Enter balance"
            />
          </div>
        </div>

        <div class="audio-settings" v-show="footer.data == 'audio'">
          <div class="input-view-parallel">
            <label style="font-size: 17px; padding: 10px">Type</label>
            <input
              type="radio"
              name="audioChooser"
              v-on:change="audioOptions"
              v-model="audioOption"
              value="upload_file"
            />
            <label style="font-size: 12px; padding: 5px">Upload File</label>
            <input
              type="radio"
              name="audioChooser"
              v-on:change="audioOptions"
              v-model="audioOption"
              value="choose_file"
            />
            <label style="font-size: 12px; padding: 5px">Choose File</label>
            <div class="input" v-if="audioUpload">
              <label for="file">Audio File</label>
              <input
                accept=".mp3,audio/*"
                @change="addFile"
                type="file"
                name="audio-audio_file"
                :id="footer.id"
              />
            </div>
            <div class="input" v-if="audioChoose">
              <label for="file">Audio File</label>
              <v-select
                :items="audioFiles"
                v-model="audioFile"
                @input="audioFilesUpload(audioFile, footer.id)"
                placeholder="Select Audio File"
              ></v-select>
            </div>
            <div class="input">
              <v-checkbox
                v-model="footer.settings.expect"
                @change="checkDTMF(footer.settings.expect, footer.id)"
                label="Expect DTMF"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="vmail-settings" v-show="footer.data == 'voicemail'">
          <div class="input">
            <label for="vmail_num">Number</label>
            <input
              v-model="footer.settings.vmail_num"
              type="text"
              name="vmail_num"
              id="vmail_num"
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
        <div class="sms-settings" v-show="footer.data == 'sms'">
          <div class="input">
            <label for="sms_opt">SMS</label>
            <multi-select
              id="sms_opt"
              :options="smsTypes"
              v-model="footer.settings.sms_opt"
              placeholder="Select Sms Option"
            ></multi-select>
          </div>
          <div class="input" v-if="footer.settings.sms_opt === 'Others'">
            <label for="sms_num">Number</label>
            <input
              v-model="footer.settings.sms_num"
              type="text"
              name="sms_num"
              id="sms_num"
              placeholder="Enter Phone Number"
            />
          </div>
          <div class="input">
            <label for="sms_txt">Message</label>
            <textarea
              v-model="footer.settings.sms_txt"
              name="sms_txt"
              id="sms_txt"
              cols="44"
              rows="5"
              placeholder="Type Your Message"
            ></textarea>
          </div>
          <div class="input">
            <label for="sms_api">SMS API</label>
            <input
              v-model="footer.settings.sms_api"
              type="text"
              name="sms_api"
              id="sms_api"
              placeholder="Enter API URL"
            />
          </div>
        </div>
        <div class="conference-settings" v-show="footer.data == 'conference'">
          <div class="input">
            <label for="conf_num">Bridge Number</label>
            <input
              v-model="footer.settings.conf_num"
              type="text"
              name="conf_num"
              id="conf_num"
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
        <div class="validation-settings" v-show="footer.data == 'validation'">
          <div class="input">
            <label for="time_cond">Time Condition</label>
            <input
              v-model="footer.settings.time_from"
              type="time"
              name="time_from"
              id="time_from"
              placeholder="From"
            />
            <input
              v-model="footer.settings.time_to"
              type="time"
              name="time_to"
              id="time_to"
              placeholder="To"
            />
          </div>          
          <div class="input_table">
            <label for="validate_param">Validate</label>
            <table>
              <tr v-for="(key, i) in no_of_validate" :key="i">
                <td>
                  <v-select
                    v-model="validateParam[i]"
                    :items="variables"
                    style="width:90px;padding:5px;overflow:hidden;text-overflow:clip;"
                    name="validate_param"
                    @change="
                      add_validate('param', footer.id, i, validateParam[i])
                    "
                    id="validate_param"
                    placeholder="Param"
                  />
                </td>
                <td>
                  <v-select
                    v-model="validateOperation[i]"
                    :items="operations"
                    style="width:90px;padding:5px;overflow:hidden;text-overflow:clip;"
                    name="operation"
                    @change="
                      add_validate('opera', footer.id, i, validateOperation[i])
                    "
                    id="operation"
                    placeholder="Operation"
                  />
                </td>
                <td>
                  <input
                    v-model="validateValue[i]"
                    type="text"
                    name="validate_value"
                    @change="
                      add_validate('value', footer.id, i, validateValue[i])
                    "
                    id="validate_value"
                    placeholder="Value"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="connection-settings" v-show="footer.data == 'connection'">
          <div class="input">
            <label for="conn_method">Connection</label>
            <multi-select
              id="conn_method"
              :options="connMethods"
              v-model="footer.settings.conn_method"
              placeholder="Select Connection Method"
            ></multi-select>
          </div>
          <div class="input">
            <label for="conn_type">Type</label>
            <multi-select
              id="conn_type"
              :options="connTypes"
              v-model="footer.settings.conn_type"
              placeholder="Select Connection Type"
            ></multi-select>
          </div>
          <div class="input">
            <label for="conn_api">API</label>
            <input
              v-model="footer.settings.conn_api"
              type="text"
              name="conn_api"
              id="conn_api"
              placeholder="Enter API URL"
            />
          </div>
          <div class="input">
            <label for="conn_res">Response</label>
            <textarea
              @change="add_response(footer.id, footer.settings.conn_res)"
              v-model="footer.settings.conn_res"
              name="conn_res"
              id="conn_res"
              cols="44"
              rows="3"
              placeholder="Enter Response"
            ></textarea>
          </div>
          <div class="input_table">
            <label for="api_param">Parameters</label>
            <table>
              <tr v-for="(key, i) in no_of_params" :key="i">
                <td>
                  <input
                    v-model="paramkeys[i]"
                    type="text"
                    name="api_param"
                    @change="add_params('key', footer.id, i, paramkeys[i])"
                    id="api_param"
                    placeholder="Key"
                  />
                </td>
                <td>
                  <v-select
                    v-if="valueDrop[i]"
                    v-model="paramvalues[i]"
                    :items="variables"
                    style="width:100px;padding:8px;overflow:hidden;text-overflow:clip;"
                    name="api_value"
                    @change="add_params('value', footer.id, i, paramvalues[i])"
                    id="api_value"
                    placeholder="Value"
                  />
                  <input
                    v-if="valueText[i]"
                    v-model="paramvalues[i]"
                    type="text"
                    name="api_value"
                    @change="add_params('value', footer.id, i, paramvalues[i])"
                    id="api_value"
                    placeholder="Value"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="menu-settings" v-show="footer.data == 'menu'">
          <div class="input-view-parallel">
            <label style="font-size: 17px; padding: 10px">Type</label>
            <input
              type="radio"
              name="menuChooser"
              v-on:change="menuOptions"
              v-model="radioOption"
              value="upload_file"
            />
            <label style="font-size: 12px; padding: 5px">Upload File</label>
            <input
              type="radio"
              name="menuChooser"
              v-on:change="menuOptions"
              v-model="radioOption"
              value="choose_file"
            />
            <label style="font-size: 12px; padding: 5px">Choose File</label>
            <div class="check" v-if="menuUpload">
              <div class="input">
                <label for="file">Menu Audio File</label>
                <input
                  accept=".mp3,audio/*"
                  @change="addFile"
                  type="file"
                  name="menu-audio_file"
                  :id="footer.id"
                />
              </div>
              <div class="input">
                <label for="file">Invalid Audio File</label>
                <input
                  accept=".mp3,audio/*"
                  @change="addFile"
                  type="file"
                  name="menu-invalid_file"
                  :id="footer.id"
                />
              </div>
            </div>
            <div class="check" v-if="menuChoose">
              <div class="input">
                <label for="file">Menu Audio File</label>
                <v-select
                  :items="audioFiles"
                  v-model="menuValid"
                  @input="menuFiles('valid', menuValid, footer.id)"
                  placeholder="Select Audio File"
                ></v-select>
              </div>
              <div class="input">
                <label for="file">Invalid Audio File</label>
                <v-select
                  :items="audioFiles"
                  v-model="menuInvalid"
                  placeholder="Select Audio File"
                  @input="menuFiles('invalid', menuInvalid, footer.id)"
                ></v-select>
              </div>
            </div>
            <!-- <div class="input">
              <label for="file">Timeout Audio File</label>
              <input
                accept=".mp3,audio/*"
                @change="addFile"
                type="file"
                name="menu-timeout_file"
                :id="footer.id"
              />
            </div> -->
            <div class="input">
              <label for="file">Timeout (seconds)</label>
              <input
                v-model.number="footer.settings.timeout"
                type="number"
                name="repeat"
                id="repeat"
              />
            </div>
            <div class="input">
              <label for="file">Repeat (times)</label>
              <input
                v-model.number="footer.settings.repeat"
                type="number"
                name="repeat"
                id="repeat"
              />
            </div>
            <!-- <div class="input">
              <v-checkbox
                v-model="footer.settings.skip"
                label="Skip on DTMF"
              ></v-checkbox>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeaderLine from "leader-line-vue";
import VueDraggableResizable from "vue-draggable-resizable";
import { mapGetters } from "vuex";
import { isNumber } from "util";
import JsonComp from "./json";
import api from "@/utils/axios-util";
export default {
  props: ["flow", "lines", "showJSON" ],
  components: {
    JsonComp: JsonComp,
    "vue-draggable-resizable": VueDraggableResizable,
  },
  data() {
    return {
      time: 4000,
      presetLine: [
        { type: "l", site: 100 },
        { type: "v", site: 200 },
      ],
      width: 60,
      height: 60,
      x: 0,
      y: 0,
      tenant_code: "",
      props_tab_index: "general",
      icon: {
        dial: "call",
        audio: "fa-volume-up",
        trigger: "bolt",
        menu: "fa-th",
        sms: "fas fa-sms",
        voicemail: "fab fa-teamspeak",
        validation: "fas fa-check-circle",
        connection: "fa fa-random",
        conference: "fa fa-cc",
        hangup: "fas fa-phone-slash",
      },
      show: false,
      radioOption: "",
      audioOption: "",
      audioChoose: false,
      audioUpload: false,
      menuUpload: false,
      menuChoose: false,
      menuValid: "",
      audioFile: "",
      menuInvalid: "",
      variables: ["DID Number", "Caller", "Agent", "Others"],
      valueText: [false],
      valueDrop: [true],
      options: {
        dial: {},
        hangup: {},
        audio: {
          audio: "",
          timeout: "",
          repeat: "",
        },
        voicemail: {
          vmail_num: "",
        },
        sms: {
          sms_opt: "",
          sms_num: "",
          sms_txt: "",
          sms_api: "",
        },
        validation: {
          rules: [
            {
              parameter: "",
              operation: "",
              value: "",
            },
          ],
          time_from: "",
          time_to: "",
        },
        connection: {
          conn_method: "",
          conn_type: "",
          conn_api: "",
          api_param: [],
          api_value: [],
        },
        conference: {
          conf_num: "",
        },
        trigger: {
          did: "",
          check_balance: false,
        },
        menu: {
          choices: [],
          keys: [],
          files: {
            menu_valid: "",
            menu_invalid: "",
          },
        },
      },
      no_of_params: [1],
      paramkeys: [],
      paramvalues: [],
      menuChoices: [],
      menuItems: [],
      menuValues: [],
      connections: [
        { name: "Option 1", value: "option1" },
        { name: "Option 2", value: "option2" },
        { name: "Option 3", value: "option3" },
      ],
      type: [
        { name: "Inbound", value: "inbound" },
        { name: "Outbound", value: "outbound" },
      ],
      elements: ["trigger"],
      dialTypes: ["Queue", "Number"],
      queueTypes: ["Queue1", "Queue2"],
      smsTypes: ["Caller", "Agent", "Others"],
      connMethods: ["GET", "POST", "PUT", "DELETE"],
      connTypes: ["TEXT", "JSON", "XML", "Form-urlencoded"],
      audioFiles: [],
      shapeCount: 1,
      operations: [
        "LIKE",
        "LIKE %...%",
        "NOT LIKE",
        "IN",
        "NOT IN",
        "IS NULL",
        "IS NOT NULL",
        "BETWEEN",
        "NOT BETWEEN",
        "=",
        ">",
        "<",
        ">=",
        "<=",
        "!=",
      ],
      validateParam: [],
      validateValue: [],
      validateOperation: [],
      no_of_validate: [1],
      dataValue: [],
      flow_id_name: "",
      shapes: [
        {
          id: `CTI-${this.flow.name}-trigger0`,
          data: "trigger",
          name: "trigger",
          x: 45,
          y: 18,
          w: 150,
          h: 100,
          title: "TRIGGER",
          description: "Description",
          draggable: true,
          showFooter: false,
          showCircle: false,
          settings: {
            did: "",
            check_balance: false,
          },
          next: {
            success: "",
            failure: "",
          },
        },
      ],
      line: [],
      start: "",
      end: "",
      showCircle: false,
      showFooter: false,
      zoom: "",
      connectors: [],
      Snackbar: {
        show: false,
        timeout: 1000,
        text: "",
      },
      flowJSON: {},
      showJSONComponent: false,
      alert: false,
      alertmessage: "",
      lineColor: "#000",
      lineType: "success",
      currentFlow: 0,
      offsetX: 0,
      offsetY: 0,
      selectedFlow: 0,
      highlightedFlowIndex: -1,
      zoomProperties: {
        defaults: {
          chrome: {
            browser: "chrome",
            zoom: 100,
            default: 100,
            step: 10,
            suffix: "%",
            prefix: "",
            property: "zoom",
            min: 30,
            max: 200,
            unit: 1,
          },
          firefox: {
            browser: "firefox",
            zoom: 1,
            default: 1,
            step: 0.1,
            suffix: ")",
            prefix: "scale(",
            property: "-moz-transform",
            min: 0.5,
            max: 2,
            unit: 100,
          },
        },
        current: {},
      },
      snackbar: false,
      activatedIndex: null,
      userData: [],
    };
  },
  mounted() {
    const scope = this;
    this.flow.name = localStorage.getItem("flowName");
    scope.tenant_code = localStorage.getItem("TenantCode");
    scope.generateJSON();
    scope.findAudio();
    scope.isNameChanged();
    window.addEventListener("keyup", (e) => {
      if (e.key == "Delete") {
        if (scope.highlightedFlowIndex >= 0) {
          scope.removeLine(scope.highlightedFlowIndex);
        }
      } else if (e.key === "Escape") {
        if (scope.highlightedFlowIndex >= 0) {
          scope.unHighlightLine(scope.highlightedFlowIndex);
        }
      }
    });
    var isFirefox = typeof InstallTrigger !== "undefined";
    var isChrome = navigator.userAgent.includes("Chrome");
    let browser = "";
    if (isChrome) {
      browser = "chrome";
    } else if (isFirefox) {
      browser = "firefox";
    }
    if (browser !== "") {
      scope.zoomProperties.current = scope.zoomProperties.defaults[browser];
    }

    scope.$store
      .dispatch("queueManagement", { name: "", take: 100, skip: 0 })
      .then((response) => {
        if (response.data.statusCode === 200) {
          const qType = response.data.data;
          var qTypes = [];
          qType.forEach((q) => {
            qTypes.push(q.name);
          });
          scope.queueTypes = qTypes;
        }
      });
    scope.$store
      .dispatch("userManagement", { skip: 0, take: 100 })
      .then((response) => {
        if (response.data.statusCode === 200) {
          scope.userData = response.data.data.map((user) => {
            return {
              phone_number: user.user_phone_number,
              user_name: user.user_email,
            };
          });
        }
      });
  },
  methods: {
    closealert() {
      this.alert = false;
    },
    findAudio() {
      var scope = this;
      let data = "Active";
      scope.$store.dispatch("FindAudio", data).then((response) => {
        var len = this.tenant_code.length;
        var datas = response.data.records;
        if (datas !== "" && datas !== undefined && datas !== null) {
          datas.forEach((element) => {
            scope.audioFiles.push(element["file_name"].substring(len + 1));
          });
        }
      });
    },
    add_response(id, value) {
      let var_len = this.variables.length;
      let temp = [];
      for (let j = 0; j < var_len; j++) {
        if (this.variables[j].indexOf(id) > -1) {
          temp.push(this.variables[j]);
        }
      }
      this.variables = this.variables.filter((name) => {
        return !temp.includes(name);
      });
      let values = value.split(",");
      for (let i = 0; i < values.length; i++) {
        let v = id + "." + values[i];
        this.variables.push(v);
      }
    },
    add_validate(typ, id, index, val) {
      var scope = this;
      if (typ == "param") {
        scope.shapes.map(function (shape) {
          if (shape.id == id) {
            if (shape.settings.rules[index] === undefined) {
              shape.settings.rules[index] = {
                parameter: "",
                operation: "",
                value: "",
              };
            }
            shape.settings.rules[index].parameter = val;
            // shape.settings.rules[index].parameter[index + 1] = "";
            var len = scope.no_of_validate.length;
            scope.no_of_validate.push(scope.no_of_validate[len - 1] + 1);
          }
          return shape;
        });
      }
      if (typ == "opera") {
        scope.shapes.map(function (shape) {
          if (shape.id == id) {
            shape.settings.rules[index].operation = val;
          }
          return shape;
        });
      }
      if (typ == "value") {
        scope.shapes.map(function (shape) {
          if (shape.id == id) {
            shape.settings.rules[index].value = val;
          }
          return shape;
        });
      }
    },
    add_params(typ, id, index, val) {
      var scope = this;
      if (val === "Others") {
        scope.valueDrop[index] = false;
        scope.valueText[index] = true;
      }
      if (val !== "" && val !== undefined && typ == "key") {
        var len = scope.no_of_params.length;
        scope.no_of_params.push(scope.no_of_params[len - 1] + 1);
        scope.valueText.push(false);
        scope.valueDrop.push(true);
      }
      if (typ == "key") {
        scope.shapes.map(function (shape) {
          if (shape.id == id) {
            shape.settings.api_param[index] = val;
            shape.settings.api_param[index + 1] = "";
          }
          return shape;
        });
      }
      if (typ == "value") {
        scope.shapes.map(function (shape) {
          if (shape.id == id) {
            shape.settings.api_value[index] = val;
          }
          return shape;
        });
      }
    },
    isNameChanged() {
      //this function is used for to check flow or file name is changed or not
      if (this.flow.name.substr(0, this.flow.name.length - 1) == "Untitled") {
        this.show = !this.show;
      }
    },
    menuControl(id, key, data) {
      //this function is used to prevent duplicate value in menu key in sitting tab
      var scope = this;
      // var static_keys = [];
      scope.shapes.map(function (shape) {
        if (shape.id == id) {
          shape.settings.keys[key] = data;
          // shape.settings.keys.forEach((key) => {
          // if (!static_keys.includes(key)) {
          //   static_keys.push(key);
          //   const index = static_keys.indexOf(key);
          //   shape.settings.choices[index].key = key;
          // } else {
          //   for (let j = 0; j < 10; j++) {
          //     if (!static_keys.includes(j)) {
          //       static_keys.push(j);
          //       const index = static_keys.indexOf(j);
          //       shape.settings.choices[index].key = j;
          //       break;
          //     }
          // }
          // }
          // });
          // shape.settings.keys = static_keys;
        }
        return shape;
      });
    },
    checkValueExist(key, choices, id) {
      // const static_shapes = [];
      this.shapes.map(function (shape) {
        if (shape.id == id) {
          shape.settings.choices[key] = choices;
          // alert(JSON.stringify(shape.settings.choices));
          // const static_choices = shape.settings.choices;
          // shape.settings.choices = [];
          // const value_array = shape.next.success.split(",");
          // static_choices.forEach(choice => {
          //   alert(JSON.stringify(value_array) + ":" + JSON.stringify(choice));
          //   if (!value_array.includes(choice.value)) {
          //     choice.value = "";
          //   }
          //   static_choices.push(choice);
          // });
        }
        // static_shapes.push(shape);
        return shape;
      });
      // this.dispatch("updateJSON", this.shape);
    },
    // checkValueExist(selectData, id, index) {
    // eslint-disable-next-line prettier/prettier
    //this function is used to prevent duplicate value in dropdown menu item in sitting tab
    // var scope = this;
    // const static_shapes = [];
    // const static_shapes = scope.shapes.map(function (shape) {
    //   if (shape.id === id && shape.id.split("-")[2].substring(0, 4) === "menu") {
    //     for (let i = 0; i < shape.settings.choices.length; i++) {
    //       if (i != index && shape.settings.choices[i].value == selectData) {
    //         shape.settings.choices[i].value = "";
    //       }
    //     }
    //   }
    //   return shape;
    // });
    //   scope.shapes.forEach(shape => {
    //     if (
    //       shape.id === id &&
    //       shape.id.split("-")[2].substring(0, 4) === "menu"
    //     ) {
    //       for (let i = 0; i < shape.settings.choices.length; i++) {
    //         if (i != index && shape.settings.choices[i].value == selectData) {
    //           shape.settings.choices[i].value = "";
    //         }
    //       }
    //     }
    //     static_shapes.push(shape);
    //   });
    //   // alert(JSON.stringify(static_shapes));
    // },
    showFlowFooter(index) {
      //this function is used to show right side menu
      this.shapes.forEach((shape, shapeIndex) => {
        if (index != shapeIndex) {
          shape.showFooter = false;
        }
      });
      this.shapes[index].showFooter = !this.shapes[index].showFooter;
    },
    positionLine(selectedFlow) {
      //this function is used for when we move element and that flow line should also align properly with new element position
      if (isNumber(selectedFlow)) {
        this.scrollPage(selectedFlow);
      }
      if (this.line) {
        this.line.forEach((line) => {
          line.position();
        });
      }
    },
    scrollPage(index) {
      //this function is used for when we scroll page the element should not to move
      const flowId = this.shapes[index].id,
        flow = this.$refs[`${flowId}`],
        // div = this.$refs[`${this.flow}`],
        flowBottom = flow[0].$el.getBoundingClientRect().bottom,
        flowRight = flow[0].$el.getBoundingClientRect().right,
        screenBottom = window.innerHeight,
        screenRight = window.innerWidth;
      if (screenBottom - flowBottom < 200) {
        scrollBy(0, 50);
        this.shapes[index].showFooter = false;
      } else if (screenBottom - flowBottom > 400) {
        scrollBy(0, -50);
      }
      if (screenRight - flowRight < 200) {
        scrollBy(50, 0);
      } else if (screenRight - flowRight > 500) {
        scrollBy(-50, 0);
      }
    },
    showSnackBar(message) {
      // this function is used to show alert msg
      var scope = this;
      scope.Snackbar.show = true;
      scope.Snackbar.text = message;
      setTimeout(() => {}, scope.Snackbar.timeout);
    },
    audioOptions() {
      if (this.audioOption == "choose_file") {
        this.findAudio();
        this.audioUpload = false;
        this.audioChoose = true;
      }
      if (this.audioOption == "upload_file") {
        this.audioChoose = false;
        this.audioUpload = true;
      }
    },
    menuOptions() {
      if (this.radioOption == "choose_file") {
        this.findAudio();
        this.menuUpload = false;
        this.menuChoose = true;
      }
      if (this.radioOption == "upload_file") {
        this.menuChoose = false;
        this.menuUpload = true;
      }
    },
    addFile(event) {
      // this function is used to upload audio files in settings
      // var scope = this;
      let selectedFile = event.target.files[0];
      if (selectedFile.size <= 5000000) {
        let attribute = event.target.attributes;
        let name = attribute.name.nodeValue;
        let id = attribute.id.nodeValue;
        let formData = new FormData();
        formData.append("file_name", selectedFile);
        formData.append("status", "Active");
        formData.append("description", name);
        api.defaults.headers["Content-Type"] = "multipart/form-data";
        api
          .post("/audio", formData)
          .then((response) => {
            api.defaults.headers["Content-Type"] = "application/json";
            const file_name = response.data.insert.file_name;
            this.shapes.map(function (shape) {
              if (shape.id == id) {
                if (name.split("-")[0] == "menu") {
                  if (name == "menu-audio_file") {
                    shape.settings.files.menu_valid = file_name;
                  }
                  if (name == "menu-invalid_file") {
                    shape.settings.files.menu_invalid = file_name;
                  }
                }
                if (name.split("-")[0] == "audio") {
                  shape.settings.audio = file_name;
                }
              }
            });
            var leng = this.tenant_code.length;
            if (name.split("-")[0] == "audio") {
              this.audioFile = file_name.substring(leng + 1);
            }
            if (name == "menu-audio_file") {
              this.menuValid = file_name.substring(leng + 1);
            }
            if (name == "menu-invalid_file") {
              this.menuInvalid = file_name.substring(leng + 1);
            }
            return response;
          })
          .catch((error) => {
            api.defaults.headers["Content-Type"] = "application/json";
            return error;
          });
        this.findAudio();
      } else {
        this.showSnackBar("File Size Must be Below 5MB");
      }
    },
    checkDTMF(bool, id) {
      let dtmf = id + ".dtmf";
      if (bool == true) {
        this.variables.push(dtmf);
      } else if (bool == false) {
        let index = this.variables.indexOf(dtmf);
        this.variables.splice(index, 1);
      }
    },
    audioFilesUpload(value, id) {
      this.shapes.forEach((shape) => {
        if (shape.id == id) {
          shape.settings.audio = this.tenant_code + "_" + value;
        }
      });
    },
    menuFiles(val, value, id) {
      if (val == "valid") {
        this.shapes.forEach((shape) => {
          if (shape.id == id) {
            shape.settings.files.menu_valid = this.tenant_code + "_" + value;
          }
        });
      }
      if (val == "invalid") {
        this.shapes.forEach((shape) => {
          if (shape.id == id) {
            shape.settings.files.menu_invalid = this.tenant_code + "_" + value;
          }
        });
      }
    },
    dragFlow(ev) {
      //this function is used starts when we drag elements
      this.isNameChanged();
      ev.dataTransfer.setData("text/html", ev.target.id);
      var crt = ev.target.cloneNode(true);
      crt.style.border = "1px dotted black";
      crt.style.width = "150px";
      crt.style.height = "100px";
      crt.style.borderRadius = "8px";
      document.body.appendChild(crt);
      ev.dataTransfer.setDragImage(crt, 0, 0);
    },
    disableDrag() {
      // this function is used to disableDrag
      this.shapes.forEach((shape) => {
        shape.draggable = false;
      });
    },
    enableDrag() {
      // this function is used to enableDrag
      this.shapes.forEach((shape) => {
        shape.draggable = true;
      });
    },
    drag(ev, index) {
      // this function is used to get which lines are draging (failure or success)
      ev.stopPropagation();
      ev.dataTransfer.setData("text", "foo");
      this.start = ev.target.parentNode.id;
      let el = ev.target.id;
      if (el == "drag-f") {
        this.lineColor = "#f00";
        this.lineType = "failure";
      } else {
        this.lineColor = "#000";
        this.lineType = "success";
      }
      this.currentFlow = index;
    },
    allowDrop(ev) {
      // this function is used to drop only in grid area or (#div1)
      ev.preventDefault();
    },
    dropArrow(id, ev, index) {
      // this function is used draw arrow between two elements
      try {
        let isDroppable = true,
          scope = this;
        const flow = this.shapes[this.currentFlow];
        this.shapes.forEach((shape) => {
          shape.showFooter = false;
          shape.draggable = true;
        });
        ev.preventDefault();
        this.end = ev.target.parentNode.id;
        this.line.forEach((line) => {
          let end = line.end.id,
            start = line.start.id;
          if (
            (start == this.end && end == this.start) ||
            (start == this.start && end == this.end)
          ) {
            isDroppable = false;
          }
          if (
            this.lineType == "success" &&
            flow.name != "menu" &&
            flow.next.success != ""
          ) {
            scope.alertmessage =
              "Only menu will create a multiple success event.";
            scope.alert = true;
            isDroppable = false;
          }
          if (this.lineType == "failure" && flow.next.failure != "") {
            scope.alertmessage = "Only allow one failure event each elements.";
            scope.alert = true;
            isDroppable = false;
          }
        });
        if (isDroppable) {
          let line = new LeaderLine.setLine(
            document.getElementById(this.start),
            document.getElementById(this.end),
            {
              path: "fluid",
              color: this.lineColor,
              endPlus: "arrow3",
              size: 1.5,
              endLabel: this.end,
            }
          );
          this.line.splice(this.line.length, 1, line);
          let div = document.querySelector("#div1");
          let lines = document.querySelectorAll(".leader-line");
          lines.forEach((line, index) => {
            if (this.zoomProperties.current.browser == "chrome") {
              line.style.zoom = div.style.zoom;
            }
            if (this.zoomProperties.current.browser == "firefox") {
              line.style.zoom = div.style.zoom;
              line.style[this.zoomProperties.current.property] =
                this.returnZoom1(this.zoomProperties.current.zoom, 0);
            }
            line.addEventListener("click", () => {
              this.highlightLine(index);
            });
          });
          this.$emit("addLine", this.line);
          if (this.lineType == "success") {
            if (flow.next.success == "") {
              flow.next.success = this.shapes[index].id;
            } else {
              flow.next.success =
                flow.next.success + "," + this.shapes[index].id;
            }
          }
          if (this.lineType == "failure") {
            flow.next.failure = this.shapes[index].id;
          }
        }
      } catch (e) {
        console.log("Err =>", e);
      }
    },
    drop(ev) {
      // this function is used to drop only in grid area or (#div1)

      // EDITED BY ABDUL SUBHAN TO REMOVE UN WANTED ELEMENT
      // code-review: Please use proper variable name (Ex : tempArray) also fix lint errors
      var data = ev.dataTransfer.getData("text/html");
      var drp = [
        "audio",
        "menu",
        "dial",
        "hangup",
        "sms",
        "validation",
        "voicemail",
        "connection",
        "conference",
      ];
      for (var i = 0; i < drp.length; i++) {
        if (drp[i] == data) {
          const zoom = document.getElementById("div1").style.zoom || 100;
          const zoomInt = parseInt(zoom) / 100;
          ev.preventDefault();
          if (ev.target.id != "drag") {
            data = ev.dataTransfer.getData("text/html");
            if (data) {
              if (data !== "hangup" && !this.elements.includes(data)) {
                this.elements.push(data);
              }
              var flowName = this.flow.name;
              var tempArray = flowName.split(" ");
              flowName = tempArray.join("");
              tempArray = flowName.split("-");
              flowName = tempArray.join("");
              this.shapes.push(
                JSON.parse(
                  JSON.stringify({
                    id: `CTI-${flowName}-${data}${this.shapeCount}`,
                    data: data,
                    name: data,
                    x: ev.offsetX / zoomInt,
                    y: ev.offsetY / zoomInt,
                    w: 150,
                    h: 100,
                    title: `${data.toUpperCase()}`,
                    draggable: true,
                    description: "Description",
                    showFooter: false,
                    showCircle: false,
                    next: {
                      success: "",
                      failure: "",
                    },
                    settings: this.options[data],
                  })
                )
              );
              this.shapeCount += 1;
            }
          }
        }
      }
    }, //////////////////END EDIT/////////////////////////////////
    activated(index, item) {
      // this function is used to get or chech the selected element is active or not

      // EDITED BY ABDUL SUBHAN IN MENU ITEM SELECTION
      const scope = this;
      scope.activatedIndex = index;
      scope.shapes[index].showCircle = true;
      scope.selectedFlow = index;
      scope.no_of_params = [];
      scope.no_of_validate = [];
      scope.validateOperation = [];
      scope.validateParam = [];
      scope.validateValue = [];
      scope.shapes.forEach((shape) => {
        if (shape.name === "validation" && shape.id === item.id) {
          if (shape.settings.rules.length > 0) {
            for (let j = 0; j < shape.settings.rules.length; j++) {
              scope.no_of_validate.push(j);
              scope.validateParam[j] = shape.settings.rules[j].parameter;
              scope.validateOperation[j] = shape.settings.rules[j].operation;
              scope.validateValue[j] = shape.settings.rules[j].value;
            }
          } else {
            scope.no_of_params = [1];
            scope.validateValue = [];
            scope.validateParam = [];
            scope.validateOperation = [];
          }
        }
        if (shape.name === "connection" && shape.id === item.id) {
          if (shape.settings.api_param.length > 0) {
            for (let j = 0; j < shape.settings.api_param.length; j++) {
              scope.no_of_params.push(j);
            }
           
            scope.paramkeys = shape.settings.api_param;
            scope.paramvalues = shape.settings.api_value;
          } else {
            scope.no_of_params = [1];
            scope.paramkeys = [];
            scope.paramvalues = [];
          }
        }
        if (shape.name === "menu" && shape.id === item.id) {
         
          if (shape.settings.keys.length > 0) {
            scope.menuValues = shape.settings.keys;
            scope.menuChoices = shape.settings.choices;
          }
          scope.menuItems = [];
          var menu = shape.next.success.split(",");
         
          for (let i = 0; i < menu.length; i++) {
            if (menu[i] !== "") scope.menuItems.push(menu[i]);
            // shape.settings.choices[i].value = menu[i];
          }
        }
        return shape;
      });
      // scope.menuChoices = [];
    },
    // ########################END#################
    deActivated(id) {
    
      // this function is used to deActivated a element
      this.shapes[id].showFooter = false;
      this.shapes[id].showCircle = false;
    },
    hideFooter(e) {
     
      // this function is used to hideFooter
      if (e.target.id == "div1") {
        this.shapes.forEach((shape) => {
          shape.showFooter = false;
        });
        this.line.forEach((line) => {
          line.outline = false;
        });
      }
    },
    removeFlow(flowIndex, item) {
     

      // this function is used to removeFlow or file name
      const scope = this;
      scope.shapes.map(function (shape) {
        if (shape.data == "menu") {
          const x = shape.settings.choices.indexOf(item.id);
          shape.settings.choices.splice(x, 1);
          shape.settings.keys.splice(x, 1);
          shape.showFooter = false;
        }
        return shape;
      });
      // scope.menuValues.filter(e => e !== item);
      let deletedIndex;
      if (scope.line.length > 0) {
        scope.line.forEach((obj, index) => {
          const endId = obj.end.id;
          const startId = obj.start.id;
          if (
            startId == scope.shapes[flowIndex].id ||
            endId == scope.shapes[flowIndex].id
          ) {
            deletedIndex = index;
            scope.shapes.map((shape) => {
              if (shape.id === startId) {
                if (obj.color === "#000") {
                  let connections = shape.next.success.split(",");
                  connections.splice(connections.indexOf(endId), 1);
                  shape.next.success = connections.join(",");
                } else {
                  let connections = shape.next.failure.split(",");
                  connections.splice(connections.indexOf(endId), 1);
                  shape.next.failure = connections.join(",");
                }
              }
            });
            obj.remove();
            scope.$emit("removeLine", index);
          }
        });
        scope.line.splice(deletedIndex, 1);
      }
      scope.menuItems.splice(item.id, 1);
      scope.shapes.splice(flowIndex, 1);
    },
    highlightFlow(id) {
      // this function is used to highlightFlow  in which flow name we are in
      document.getElementById(id).classList.add("hover");
    },
    unHighlightFlow(id) {
      // this function is used to unHighlightFlow
      document.getElementById(id).classList.remove("hover");
    },
    highlightLine(index) {
      // this function is used to highlightLine the dash-dash animation
      this.line.forEach((line) => {
        line.setOptions({
          dash: false,
          size: 1.5,
        });
      });
      this.highlightedFlowIndex = index;
      this.line[index].setOptions({
        dash: {
          animation: true,
        },
        size: 2,
      });
    },
    unHighlightLine(index) {
      // this function is used to unHighlightLine the dash-dash animation
      this.line[index].setOptions({
        dash: false,
        size: 1.5,
      });
    },
    removeLine(index) {
     
      // this function is used to removeLine
      const scope = this;
      scope.shapes.map((shape) => {
        if (shape.id === scope.line[index].start.id) {
          if (scope.line[index].color === "#000") {
            let connections = shape.next.success.split(",");
            connections.splice(
              connections.indexOf(scope.line[index].end.id),
              1
            );
            shape.next.success = connections.join(",");
          } else {
            let connections = shape.next.failure.split(",");
            connections.splice(
              connections.indexOf(scope.line[index].end.id),
              1
            );
            shape.next.failure = connections.join(",");
          }
        }
      });
      scope.line[index].remove();
      scope.$emit("removeLine", index);
      scope.line.splice(index, 1);
      let lines = document.querySelectorAll(".leader-line");
      lines.forEach((line, index) => {
        line.addEventListener("click", () => {
          scope.highlightLine(index);
        });
      });
      // this.highlightedFlowIndex = -1;
    },
    returnZoom(val, step) {
      // this function is used for returnZoom value this function only for chrome

      // EDIT BY ABDUL SUBHAN IN CHROME ZOOM OPTION
      let returnval;
      if (this.zoomProperties.current.browser == "chrome") {
        returnval = `${this.zoomProperties.current.prefix}${val + step}${
          this.zoomProperties.current.suffix
        }`;
      }
      if (this.zoomProperties.current.browser == "firefox") {
        returnval = `${this.zoomProperties.current.prefix}${val + step}${
          this.zoomProperties.current.suffix
        }`;
      }

      return returnval;
    },
    //////////////////END EDIT/////////////////////////////////

    returnZoom1(val, step) {
      // this function is used for returnZoom value this function only for firefox

      // EDIT BY ABDUL SUBHAN IN firefox ZOOM OPTION
      let returnval;
      if (this.zoomProperties.current.browser == "chrome") {
        returnval = `${this.zoomProperties.current.prefix}${val + step}${
          this.zoomProperties.current.suffix
        }`;
      }
      if (this.zoomProperties.current.browser == "firefox") {
        returnval = `${this.zoomProperties.current.prefix}1${this.zoomProperties.current.suffix}`;
      }
      return returnval;
    }, //////////////////END EDIT/////////////////////////////////

    zoomIn() {
      // this function is used zoom in
      let div = document.querySelector("#div1");
      let zoom =
        document.querySelector("#div1").style[
          this.zoomProperties.current.property
        ];
      let lines = document.querySelectorAll(".leader-line");
      if (!zoom || zoom === "") {
        div.style["transform-origin"] = "left top";
        div.style[this.zoomProperties.current.property] = this.returnZoom(
          this.zoomProperties.current.zoom,
          0
        );
        if (lines) {
          lines.forEach((line) => {
            line.style[this.zoomProperties.current.property] = this.returnZoom(
              this.zoomProperties.current.zoom,
              0
            );
          });
        }
      }
      if (this.zoomProperties.current.zoom <= this.zoomProperties.current.max) {
        div.style["transform-origin"] = "left top";
        let zoomVal = this.returnZoom(
          this.zoomProperties.current.zoom,
          this.zoomProperties.current.step
        );
        let newZoom =
          this.zoomProperties.current.zoom + this.zoomProperties.current.step;
        this.handleZoom(div, lines, zoomVal, newZoom);
      }
    },
    zoomOut() {
      // this function is used zoom out
      let div = document.querySelector("#div1");
      let zoom =
        document.querySelector("#div1").style[
          this.zoomProperties.current.property
        ];
      let lines = document.querySelectorAll(".leader-line");
      if (!zoom || zoom === "") {
        div.style["transform-origin"] = "left top";
        div.style[this.zoomProperties.current.property] = this.returnZoom(
          this.zoomProperties.current.zoom,
          0
        );
        if (lines) {
          lines.forEach((line) => {
            line.style[this.zoomProperties.current.property] = this.returnZoom(
              this.zoomProperties.current.zoom,
              0
            );
          });
        }
      }
      if (this.zoomProperties.current.zoom >= this.zoomProperties.current.min) {
        div.style["transform-origin"] = "left top";
        var zoomVal = this.returnZoom(
          this.zoomProperties.current.zoom,
          this.zoomProperties.current.step * -1
        );
        let newZoom =
          this.zoomProperties.current.zoom - this.zoomProperties.current.step;
        this.handleZoom(div, lines, zoomVal, newZoom);
      }
    },
    handleZoom(div, lines, zoomVal, newZoom) {
      // this function is used handleZoom event this is main function in zoom
      div.style[this.zoomProperties.current.property] = zoomVal;
      if (lines) {
        lines.forEach((line) => {
          if (this.zoomProperties.current.browser == "chrome") {
            line.style[this.zoomProperties.current.property] = zoomVal;
          }
          if (this.zoomProperties.current.browser == "firefox") {
            line.style[this.zoomProperties.current.property] = this.returnZoom1(
              this.zoomProperties.current.zoom,
              0
            );
          }
        });
      }
      this.line.forEach((line) => {
        line.position();
      });
      this.zoomProperties.current.zoom = newZoom;
      this.snackbar = true;
    },
    uploadJSON() {
      // this function is used to upload uploadJSON data
      var scope = this;
      scope.generateJSON();
      scope.$store
        .dispatch("postCallFlow", scope.flowJSON)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode === 200) {
            this.flow.name = "Untitled1";
            scope.showSnackBar("Uploaded Successfully");
            scope.$router.push({ name: "CallFlow" });
          } else {
            scope.showSnackBar("Create error-" + response.message);
          }
        })
        .catch(function () {
          scope.showSnackBar("Upload Failed..!");
        });
    },
    generateJSON() {
    
      // this function is used to generateJSON
      let values = {};
      var FlowName = this.flow.name;
      var NameSplit = FlowName.split(" ");
      FlowName = NameSplit.join("");
      NameSplit = FlowName.split("-");
      FlowName = NameSplit.join("");
      this.shapes.map(function (shape) {
        var tempArray = shape.id.split("-");
        var id =
          tempArray[0] + "-" + FlowName + "-" + tempArray[tempArray.length - 1];
        shape.id = id;
        return shape;
      });
      const flows = this.shapes.slice(1);
      for (var i = 0; i < flows.length; i++) {
        var id = flows[i].id;
        values[id] = flows[i];
      }
      const connections = this.line.map((line) => {
        return {
          end: line.end.id,
          start: line.start.id,
        };
      });
      const variable = this.variables;
      const json = {
        name: this.flow.name,
        data: {
          start: {
            ...this.shapes[0],
            flowType: "start",
          },
          values,
          connections,
          variable,
        },
      };
    
      this.flowJSON = json;
      this.$store.dispatch("updateJSON", json);
     
    },
    // dragEnd(x, y) {
    // this.shapes[this.activatedIndex].x = x;
    // this.shapes[this.activatedIndex].y = y;
    // },
  },
  updated() {
    const scope = this;
    if (scope.lines.length > 0) {
      scope.lines.forEach((line) => {
        const id = line.end.id.split("-")[1];
        var FlowName = scope.flow.name;
        var NameSplit = FlowName.split(" ");
        FlowName = NameSplit.join("");
        NameSplit = FlowName.split("-");
        FlowName = NameSplit.join("");
        if (id != FlowName) {
          line.hide();
        } else {
          line.show();
        }
      });
    }
  },
  watch: {
    showJSON(val) {
      this.showJSONComponent = val;
      if (val) {
        this.generateJSON();
      }
    },
  },
  computed: {
    ...mapGetters(["getJSON"]),
    zoomalert() {
      return (
        this.zoomProperties.current.zoom * this.zoomProperties.current.unit +
        "%"
      );
    },
    dialVariables() {
      let temp = ["DID Number", "Caller", "Agent"];
      return this.variables.filter((e) => {
        return !temp.includes(e);
      });
    },
  },
  beforeDestroy() {
    this.line.forEach((line) => {
      line.remove();
    });
  },
};
</script>
<style>
body {
  overflow: hidden;
}

body .leader-line {
  pointer-events: auto !important;
}

.header {
  border-width: 1px 0 1px;
  border-style: solid;
}

.hover {
  border: 1.5px solid red;
}

.controls {
  position: fixed;
  display: flex;
  bottom: 10px;
  right: 0px;
}

.controls i {
  display: block;
  color: #fff !important;
  background: #424242;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px 0;
  margin: 10px 0;
}

.zoom-in,
.zoom-out {
  top: 90%;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px;
}

.zoom-in {
  right: 30px;
}

.zoom-out {
  right: 80px;
}

.flow {
  box-shadow: 1px 1px 13px #999;
  position: absolute;
  border-radius: 5px;
}

.draggable.resizable.flow.active,
.check.highlight {
  box-shadow: 1px 1px 14px #666;
}
/* .v-tooltip__content {
  background: #fcbe03;
  color: red;
} */
.check.highlight > .custom-handle {
  display: block !important;
}
.v-tooltip__content--fixed {
  top: 260px !important;
}
</style>
