export const CREATE_WIDGET = 'CREATE_WIDGET';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';

export const ENABLE_ACTIVE = 'ENABLE_ACTIVE';
export const DISABLE_ACTIVE = 'DISABLE_ACTIVE';

export const ENABLE_DRAGGABLE = 'ENABLE_DRAGGABLE';
export const DISABLE_DRAGGABLE = 'DISABLE_DRAGGABLE';

export const ENABLE_RESIZABLE = 'ENABLE_RESIZABLE';
export const DISABLE_RESIZABLE = 'DISABLE_RESIZABLE';

export const ENABLE_PARENT_LIMITATION = 'ENABLE_PARENT_LIMITATION';
export const DISABLE_PARENT_LIMITATION = 'DISABLE_PARENT_LIMITATION';

export const ENABLE_SNAP_TO_GRID = 'ENABLE_SNAP_TO_GRID';
export const DISABLE_SNAP_TO_GRID = 'DISABLE_SNAP_TO_GRID';

export const ENABLE_ASPECT = 'ENABLE_ASPECT';
export const DISABLE_ASPECT = 'DISABLE_ASPECT';

export const ENABLE_X_AXIS = 'ENABLE_X_AXIS';
export const ENABLE_Y_AXIS = 'ENABLE_Y_AXIS';
export const ENABLE_BOTH_AXIS = 'ENABLE_BOTH_AXIS';
export const ENABLE_NONE_AXIS = 'ENABLE_NONE_AXIS';

export const CHANGE_ZINDEX = 'CHANGE_ZINDEX';

export const CHANGE_MINW = 'CHANGE_MINW';
export const CHANGE_MINH = 'CHANGE_MINH';

export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const CHANGE_HEIGHT = 'CHANGE_HEIGHT';
export const CHANGE_TOP = 'CHANGE_TOP';
export const CHANGE_LEFT = 'CHANGE_LEFT';
export const CLEAR_WIDGETS = '';

export const COMMIT_NOTHING = 'COMMIT_NOTHING';
export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';

export default {
    ENABLE_ACTIVE,
    DISABLE_ACTIVE,
    ENABLE_DRAGGABLE,
    DISABLE_DRAGGABLE,
    ENABLE_RESIZABLE,
    DISABLE_RESIZABLE,
    ENABLE_PARENT_LIMITATION,
    DISABLE_PARENT_LIMITATION,
    ENABLE_SNAP_TO_GRID,
    DISABLE_SNAP_TO_GRID,
    ENABLE_ASPECT,
    DISABLE_ASPECT,
    ENABLE_X_AXIS,
    ENABLE_Y_AXIS,
    ENABLE_NONE_AXIS,
    ENABLE_BOTH_AXIS,
    CHANGE_ZINDEX,
    CHANGE_MINW,
    CHANGE_MINH,
    CHANGE_WIDTH,
    CHANGE_HEIGHT,
    CHANGE_TOP,
    CHANGE_LEFT,
    CREATE_WIDGET,
    REMOVE_WIDGET,
    UPDATE_WIDGET,
    CLEAR_WIDGETS,
    COMMIT_NOTHING,
    UPDATE_PREVIEW
}