import config from "@/config/index";
// import { delete } from "vue/types/umd";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.CustomForm;
var getters = {
    forms: (state) => {
        state.CustomForm.forms[0].fields.forEach(res => {
            res.visible = false;
        })

        return state.CustomForm.forms;
    },
    activeField: state => state.CustomForm.activeField,
    activeTabForFields: state => state.CustomForm.activeTabForFields,
    themingVars: state => state.CustomForm.themingVars,
    formType: state => state.CustomForm.formType,
    PageMethode: state => state.CustomForm.page,
    linkChanges(state) {

        let main_data = [];
        for (let j = 0; j < state.CustomForm.forms[0].fields[0].catagory_arr.length; j++) {
            let seperate_arr = [];
            for (let i = 0; i < state.CustomForm.forms[0].fields.length; i++) {
                const element = state.CustomForm.forms[0].fields[i];
                if (state.CustomForm.forms[0].fields[0].catagory_arr[j] === state.CustomForm.forms[0].fields[i].catagory_value) {
                    element.value = { name: element.model, value: '' };
                    seperate_arr.push(element)
                }

            }
            main_data.push(seperate_arr)
        }
        return main_data;

    },
}
const mutations = {

    [mutation_type.CommitNothing]: () => { },
    [mutation_type.LINK_PROPERTY]: (state, payload) => {
        try {
            let temp = state.CustomForm.forms[0].fields;
            temp.map((value) => {
                if (value.model === payload.linked_to && value.linked_to === payload.model) {
                    if (value.fieldType === 'SelectList') {
                        let link = value.link_property;
                        let options = link[payload.option];
                        let options_arr = options.split(",");
                        let sub_category_values = {};
                        value.values = [];
                        for (let i = 0; i < options_arr.length; ++i) {
                            sub_category_values = {
                                name: options_arr[i],
                                value: options_arr[i]
                            }
                            value.values.push(sub_category_values);
                        }
                    }
                    if (value.fieldType === 'TextInput' && value.link_property.includes(payload.option)) {
                        value.show_on_view = true;
                    } else {
                        value.show_on_view = false;
                    }
                }
            })
        } catch (err) {
            return err;
        }
    },
    [mutation_type.CustomForm]: (state, response) => {

        try {
            const form = response[0].fields;
            const conversion = form.map((value) => {
                for (var key in value) {
                    if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType === 'SelectList' || value.fieldType === 'RadioButton' || value.fieldType === 'Checkbox') && Array.isArray(value.values)) {
                        let options = JSON.parse(value.values[0]);
                        value.values = options;
                    } else if (value.hasOwnProperty(key) && key === 'values' && (value.fieldType !== 'SelectList' && value.fieldType !== 'RadioButton' && value.fieldType !== 'Checkbox') && Array.isArray(value.values)) {
                        value.values = value.values[0];
                    }
                    if (value.hasOwnProperty(key) && (value[key] === 'true' || value[key] === 'false')) {
                        value[key] = (value[key] === 'true');
                    }
                    if (value.hasOwnProperty(key) && key == "id") {
                        delete value[key];
                    }
                    if (value.link_property !== '' && value.link_property !== undefined && value.link_property && (value.fieldType != "SelectList" && value.fieldType != "RadioButton") && key === 'link_property') {
                        value[key] = value.link_property.map;
                    }
                }
                return value;
            });
            const data = [{ "fields": conversion }];
            console.log(response,"sjxbszjcbhzbchzxbchhzbhzxcbhzx")
            if (response[0].template_name) {
                data[0].template_name = response[0].template_name
            }
            if (response[0].type) {
                data[0].type = response[0].type
            }
            if (response[0].active) {
                data[0].active = response[0].active
            }
            if (response[0].industry) {
                data[0].industry = response.industry;
            }
            if (response[0].isfileupload) {
                data[0].isfileupload = response[0].isfileupload;
            }
            if(response[0].authdetails){
                data[0].authdetails = response[0].authdetails;
            }
            console.log(response,"sjxbszjcbhzbchzxbchhzbhzxcbhzx",data)
            localStorage.setItem("forms", JSON.stringify(data));
            localStorage.setItem("originalForm", JSON.stringify(data));
            state.CustomForm.forms = data;
        } catch (err) {
            console.log("Err:",err)
            return err;
        }
    },
    [mutation_type.RefreshCustomForm]: (state, response) => {
        state.CustomForm.forms = response;
        state.CustomForm.activeField = {};
        state.CustomForm.activeTabForFields = "elements";
    },
    [mutation_type.UpdateFormType]: (state, response) => {
        state.CustomForm.formType = response;
    },
    [mutation_type.PageMethode]: (state, response) => {
        state.CustomForm.page = response;
        localStorage.setItem("PageMethode", response);
    },
    [mutation_type.CommitNothing]: () => {

    }
};
const actions = {
    linkChanges: ({ commit }, payload) => {
        commit("linkChanges", payload)
    },
    GetCustomForm: () => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/aster/form/template`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetFormBuilder: () => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/formbuilder/aster/form/template`)
                    .then((response) => {
                        if (response.status === 200) {
                            resolve(response.data);
                        } else {
                            resolve({
                                data: []
                            });
                        }

                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    getIndustrydetail: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/industry/fetch`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit(mutation_type.CommitNothing, []);
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    getDomaindetail: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/workflow/fetch`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit(mutation_type.CommitNothing, []);
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterCustomForm: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/aster/form/template?template_name=${query.template_name}`)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit(mutation_type.CommitNothing, []);
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterFormBuilder: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/formbuilder/aster/form/template?template_name=${query.template_name}`)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        commit(mutation_type.CommitNothing, []);
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetFormFields: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/aster/form/template/fields?template_name=${query}`)
                    .then(async response => {
                        commit(mutation_type.RefreshCustomForm, []);
                        localStorage.removeItem("forms");
                        localStorage.removeItem("originalForm");
                        const formFields = await JSON.parse(JSON.stringify(response.data.data));
                        commit(mutation_type.CustomForm, formFields);
                        commit(mutation_type.PageMethode, 'Update');
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetFormbuilderFields: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/formbuilder/aster/form/template/fields?template_name=${query}`)
                    .then(async response => {
                        commit(mutation_type.RefreshCustomForm, []);
                        localStorage.removeItem("forms");
                        localStorage.removeItem("originalForm");
                        const formFields = await JSON.parse(JSON.stringify(response.data.data));
                        console.log(formFields, "/formbuilder/aster/form/template/fields")
                        commit(mutation_type.CustomForm, formFields);
                        commit(mutation_type.PageMethode, 'Update');
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    clearForm: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.RefreshCustomForm, []);
                // commit(mutation_type.UpdateFormType, payload.type);
            } catch (error) {
                reject(error);
            }
        });
    },
    restoreForm: ({ commit }, payload) => {
        localStorage.setItem("forms", JSON.stringify(payload));
        commit(mutation_type.RefreshCustomForm, payload);
    },
    deleteCustomformField: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/form/template/fields/${query.template_name}/${query.model}`)
                    .then(response => {
                        const value = localStorage.getItem("PageMethode");
                        commit(mutation_type.PageMethode, value);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteformBuilderField: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/formbuilder/aster/form/template/fields/${query.template_name}/${query.model}`)
                    .then(response => {
                        const value = localStorage.getItem("PageMethode");
                        commit(mutation_type.PageMethode, value);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    bulkdeleteCustomformField: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/customform/${query.template_name}/${query.catagory_value}`)
                    .then(response => {
                        const value = localStorage.getItem("PageMethode");
                        commit(mutation_type.PageMethode, value);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteform: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/formbuilder/aster/form/template/${query.template_name}`)
                    .then(response => {
                        commit(mutation_type.RefreshCustomForm, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteCustomform: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/form/template/${query.template_name}`)
                    .then(response => {
                        commit(mutation_type.RefreshCustomForm, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetDefaultFields: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/aster/form/default/fields?type=${query.formDomain}&industry=${query.formindusties}`)
                    .then(response => {
                        response.data.data.map(elm => {
                            if (elm.fieldType !== "SelectList") {
                                delete elm.multiple
                            }
                        });
                        var data = [{
                            template_name: "",
                            fields: response.data.data,
                            type: query,
                            industry: response.data.industryvalue,
                        }];
                        commit(mutation_type.CustomForm, data);
                        commit(mutation_type.PageMethode, 'Create');
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetformbuilderDefaultFields: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .get(`/formbuilder/aster/form/default/fields`)
                    .then(response => {
                        response.data.data.map(elm => {
                            if (elm.fieldType !== "SelectList") {
                                delete elm.multiple
                            }
                        });
                        var data = [{
                            template_name: "",
                            fields: response.data.data,
                            type: query,
                            industry: response.data.industryvalue,
                        }];
                        commit(mutation_type.CustomForm, data);
                        commit(mutation_type.PageMethode, 'Create');
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    CreateCustomForm: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                api
                    .post(`/aster/form/template`, payload[0])
                    .then(response => {
                        if (response.data.statuscode === 201) {
                            commit(mutation_type.CommitNothing, []);
                            commit(mutation_type.RefreshCustomForm, []);
                        }
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    CreateformbuilderCustomForm: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                console.log()
                api
                    .post(`/formbuilder/aster/form/template`, payload[0])
                    .then(response => {
                        if (response.data.statuscode === 201) {
                            commit(mutation_type.CommitNothing, []);
                            commit(mutation_type.RefreshCustomForm, []);
                        }
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    createworkflow: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                console.log()
                api
                    .post(`/custom-module-workflow`, payload)
                    .then(response => {
                        if (response.data.statuscode === 201) {
                            commit(mutation_type.CommitNothing, []);
                            commit(mutation_type.RefreshCustomForm, []);
                        }
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    CreateNewField: ({ commit }, payload) => {
        if (payload[0].hasOwnProperty('deleted')) {
            delete payload[0].deleted;
        }
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/aster/form/template/fields`, payload[0])
                    .then(response => {
                        commit(mutation_type.CommitNothing, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (err) {
                reject(err);
            }
        });
    },
    formbuilderCreateNewField: ({ commit }, payload) => {
        if (payload[0].hasOwnProperty('deleted')) {
            delete payload[0].deleted;
        }
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/formbuilder/aster/form/template/fields`, payload[0])
                    .then(response => {
                        commit(mutation_type.CommitNothing, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (err) {
                reject(err);
            }
        });
    },
    UpdateFields: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .put(`/aster/form/template/fields/${payload.template_name}`, { fields: payload.fields })
                    .then(response => {
                        commit(mutation_type.CommitNothing, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (err) {
                reject(err);
            }
        });

    },
    UpdatereportbuilderFields: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .put(`/formbuilder/aster/form/template/fields/${payload.template_name}`, { fields: payload.fields , authdetails:payload.authdetails})
                    .then(response => {
                        commit(mutation_type.CommitNothing, []);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err.response);
                    });
            } catch (err) {
                reject(err);
            }
        });

    },
    getChildProperties: ({ commit }, payload) => {
        commit(mutation_type.LINK_PROPERTY, payload);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}