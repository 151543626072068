<template>
 
 
    <div class="main-view-cls">
    <div class="main_container_grid">
    <div class="grid1">
    <div class="header_cls">
    <div class="profil_pic">
    <div>
    <div class="profil_txt" v-if="
    apidata.email !== undefined &&
    apidata.email !== '' &&
    apidata.email
    ">
    {{ apidata.email.split("")[0].toUpperCase() }}
    </div>
    <div class="profil_txt" v-else>
    {{ T }}
    </div>
   
    <!-- <div class="profil_txt" v-else>
    {{currentsupport[0]}}
    </div> -->
    </div>
    <div
    style="display:flex;flex-direction:column ;justify-content: space-between; margin-left: 10px;padding-bottom: 10px;">
    <div style="display:flex;flex-direction:row">
    <div class="v-toolbar__title primary--text" v-if="
    apidata.subject !== undefined &&
    apidata.subject !== '' &&
    apidata.subject
    ">
    {{ apidata.subject }}
    </div>
    <div class="v-toolbar_portal_title primary--text" v-else>
    No Subject
    </div>
    <div class="v-toolbar__title primary--text" v-if="apidata.is_duplicate && apidata.merged_as == 'child' ">
    #{{ apidata.ticket_id + `${apidata.nonduplicate_merge_to !=='' ? ' Ticket Merged To '+apidata.nonduplicate_merge_to : ' Duplicate Ticket Merged To '+apidata.duplicate_merge_to }`}}
    </div>
    <div class="v-toolbar__title primary--text" v-else>
    #{{ apidata.ticket_id }}
    </div>
    </div>
    <div style="color: #cacaca;font-style: oblique;">
    Last Modified : {{`${edit_datas[0]} ${edit_datas[1]}`}}
    </div>
    </div>
    </div>
    <div class="icon_flex">
    <div>
    <!-- <v-icon color="blue" style="font-size: 25px" @click="pushBack()" class="fas fa-unlink mr-4"></v-icon> -->
    <v-icon v-if="apidata.user !=='' && apidata.merged_as == 'child'" color="blue" style="font-size: 25px" aria-hidden="true" @click="unmergepopup = true" class="fas fa-chain-broken mr-4"></v-icon>
    <v-icon v-if="apidata.user !=='' && apidata.merged_as == 'parent'" color="blue" style="font-size: 25px" @click="mergepopup = true" class="fas fa-link mr-4"></v-icon>
    <v-icon v-show="page.userassign" :color="assgin_color" @click="assignAgent()" style="font-size: 25px"
    class="mr-4">queue
    </v-icon>
    </div>
    <div>
    <v-icon color="blue" style="font-size: 25px" @click="pushBack()" class="fas fa-arrow-left mr-4">
    </v-icon>
    </div>
    </div>
    </div>
    </div>
    <div class="grid2">
    
    <div class="timeline">
    <timeline :module="`ticket`" :id="this.ticket_id_" :ticket_info="ticket_info"></timeline>
   
    </div>
    </div>
    <div class="grid3">
    <div class="v-toolbar__title titlecls "
    style="font-size: medium;color:#ffffff;padding:10px 10px 10px 10px;text-align:center;background-color: rgb(33, 150, 243);">
    TICKET
    INFORMATIONS</div>
    <div class="form_data">
    <preview :value_cls="true" 
    :apidata="[apidata]" 
    :contactalldata="contactalldata" 
    @update="update_fun($event)" 
    :support="true">
    </preview>
    </div>
    </div>
    </div>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
    {{ snackbar.text }}
    <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog persistent v-model="editticketinfo" max-width="500px">
    <v-card>
    <v-toolbar dark color="primary">
    <!-- <v-card-title style="width: 100% ;padding:0px!important"> -->
    <div style=" display: flex;justify-content: space-between; width: 100%!important;padding: 5px;">
    <div class="headline" style="width:90%;font-size:15px;margin-left: 10px;">Save changes</div>
    
    <div style="margin: auto;" @click="closeticket()">
    <!-- <v-btn icon flat color="white" "> -->
    <i class="fas fa-close" style="font-size:20px"></i>
    <!-- </v-btn> -->
    </div>
    </div>
    
    <!-- </v-card-title> -->
    
    </v-toolbar>
    <v-container grid-list-md>
    <v-layout wrap>
    <v-card-text>Save changes to this ticket before closing?</v-card-text>
    </v-layout>
    </v-container>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="light" flat @click="closewithoutticket()">Close without Saving</v-btn>
    <v-btn color="primary" @click="saveticket()">Save</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog v-model="isShowAgentAssign" max-width="400px">
    <v-form ref="refassign">
    <v-card>
    <v-toolbar color="primary" dark>
    <v-toolbar-title>Assign Ticket</v-toolbar-title>
    </v-toolbar>
    <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
    <template>
    <v-autocomplete v-model="assignedDetails.user" :items="agents" label="Agent" required persistent-hint
    :allow-overflow="false"></v-autocomplete>
    </template>
    </v-container>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="light" flat @click="closeAssign()">Cancel</v-btn>
    <v-btn color="primary" @click="submitAssign()">Save</v-btn>
    </v-card-actions>
    </v-card>
    </v-form>
    </v-dialog>
    <v-dialog v-model="unmergepopup" max-width="400px">
    <v-form ref="refassign">
    <v-card>
    <v-toolbar color="primary" dark>
    <v-toolbar-title>Unmerge Ticket</v-toolbar-title>
    </v-toolbar>
    <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
    Do you unmerge this Ticket?
    </v-container>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="light" flat @click="unmergepopup = false">Cancel</v-btn>
    <v-btn color="primary" @click="UnmergeTicket()">Ok</v-btn>
    </v-card-actions>
    </v-card>
    </v-form>
    </v-dialog>
   
    <v-dialog v-model="mergepopup" scrollable max-width="500px">
    <v-card>
    <v-toolbar dark color="primary">
    <v-card-title style="width: 100% ;padding:0px!important">
   
    <div style=" display: flex;justify-content: space-between; width: 100%!important; ">
    <div>
    <v-icon v-if="mergerlist == false" color="white" style="font-size: 25px" @click="mergerlist = true" class="fas fa-arrow-left mr-4">
    </v-icon>
    <span class="headline">Merge Ticket</span>
    </div>
    <div>
    <v-icon v-if="mergerlist == true" color="white" style="font-size: 25px" @click="mergerlist = false" class="fas fa-solid fa-plus mr-4"></v-icon>
   
    </div>
    </div>
    </v-card-title>
    </v-toolbar>
    <v-card-text style="height: 520px">
    <v-container grid-list-md>
    <v-layout wrap>
    
    <div v-if="mergerlist == true">
    <div v-if="mergedtickets.length > 0 ">
    <div
    style="display: flex; width: 450px"
    v-for="(item, index) in mergedtickets"
    :key="index" >
    <label style="padding-right: 370px;padding-top: 25px; font-size: 20px;" >{{item.data+" " + item.status }}</label>
    <v-icon color="blue" style="font-size: 25px" @click="(()=>{ticket_redirect(item.data)})" class="fas fa-eye mr-4"></v-icon>
    <!-- <v-switch
    v-model="item.switch"
    @change="addNewWidgets(item.id, item.switch)"
    color="primary"
    flat
    >
    </v-switch> -->
    </div>
    </div>
    <div v-else class="nodata">No Data Available</div>
    </div>
    <div v-else>
    <div>
    <div style="display: flex; justify-content: center;">
    <v-autocomplete multiple label='Ticket' chips
    small-chips
    outlined dense v-model="mergedata" :items="unmergedtickets" @input.native="ticketsearch" ></v-autocomplete>
    
    </div>
    <v-autocomplete v-if="ticketduplicate == false" label='Status' v-model="mergestatus" :items="ticketstatus" item-text="name"
    item-value="value"></v-autocomplete>
    <v-switch v-model="ticketduplicate" color="secondary" label="duplicate"></v-switch> 
    </div>
    </div>
    </v-layout>
    </v-container>
    </v-card-text>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="light" flat @click="mergepopup = false"
    >Cancel</v-btn
    >
    <v-btn v-if="mergerlist == false" color="primary" @click="mergeticket">Merge Ticket</v-btn>
    <!-- <v-btn color="primary" @click="addWidget">Save</v-btn> -->
    </v-card-actions>
    </v-card>
    </v-dialog>
   
   
    </div>
   </template>
   <script>
   import timeline from "../../components/crm/timeLine.vue"
   import { mapGetters } from "vuex";
   import preview from "./custom-form/Preview.vue";
   export default {
    components: {
    timeline,
    preview,
    },
    data() {
    return {
    page: {
    userassign: false,
    },
    beforeDestroyedit: false,
    editticketinfo: false,
    history: [],
    apidata: {},
    contactdata: {},
    contactalldata: [],
    contact_date: [],
    ticket_id_:"",
    agents: [],
    snackbar: {
    show: false,
    timeout: 6000,
    text: "",
    },
    isShowAgentAssign: false,
    assignedDetails: {},
    currentsupport: "",
    final_data: [],
    required: [],
    count: 0,
    err_arr: [],
    edit_datas: [],
    user_email:JSON.parse(localStorage.apiInfo).user_email,
    updatestatus:false,
    mergepopup:false,
    mergedtickets:[],
    unmergedtickets:[],
    ticketstatus:[],
    mergedata:[],
    mergestatus:"",
    ticketduplicate:false,
    assgin_color:"red",
    mergerlist:true,
    unmergepopup:false,
    ticket_info:JSON.parse(localStorage.ticket_templet)
    // DeleteRowdata: false,
    // icon: "",
    // title: "",
    // lead_id: "",
    // isShowAgentAssign: false,
    // valid: true,
    // assignedDetails: {},
    // user: "",
    // count: 0
    };
    },
    computed: {
    ...mapGetters(["form"]),
    },
    mounted() {
    let scope = this
    scope.getApidata()
   
   
    let ticketstatusdatas= scope.form[0].fields.find(e=>e.model == "status")
    console.log("values",ticketstatusdatas.values)
    scope.ticketstatus = ticketstatusdatas.values
    setTimeout(
    function () {
    if (
    scope.$store.getters.UserPermissions.indexOf(
    "leadmanagement:tickets:userassign"
    ) !== -1
    ) {
    scope.page.userassign = true;
    }
    }.bind(scope),
    1
    );
    },
    methods: {
    ticketsearch(e){
    let query
    if(e == undefined){
    query={
    search:"",
    current:this.apidata.ticket_id
    }
    }else{
    query={
    search:e.target._value,
    current:this.apidata.ticket_id
    }
    }
    
    if(e == undefined){
    query.search =""
    }
    this.$store.dispatch("TicketSearch", query).then((res) => {
    if (res.status == 200){
    this.unmergedtickets = res.data.data[0].others.map(elm=>{return elm.ticket_id})
    this.unmergedtickets = [...this.mergedata,...this.unmergedtickets]
    }else if(res.status == 200){
    this.unmergedtickets = []
    }
    
    });
   
    },
    closepopup(){
    let scope = this
    scope.mergerlist = true
    scope.mergepopup = false
    
    },
           DateFormate(data) {
               let datetime = new Date(data)
               let yy = datetime.getFullYear()
               let dd = datetime.getDate()
               let mm = datetime.getMonth() + 1
               dd = String(dd).padStart(2, "0");
               mm = String(mm).padStart(2, "0");
               return `${yy}-${mm}-${dd}`
           },
    getApidata(){
    let scope = this;
    scope.agents = JSON.parse(localStorage.getItem("group_users"));
    let obj = JSON.parse(localStorage.ticket_templet);
    
   
    let payload={
    phone_number:obj.phone_number,
    ticket_id:obj.ticket_id
    }
    this.$store.dispatch("FilterAsterSupport", payload).then((res) => {
   
    this.apidata = res;
    this.apidata.due_date = this.apidata.due_date !== "" || this.apidata.due_date !== null  ? this.DateFormate(this.apidata.due_date) :null
    this.GetTicketHist(payload);
    this.edit_datas = res.modified_at.split(' ')
    this.assgin_color = this.apidata.user =="" ? "red" : "green" 
    let merge_data =[]
    let duplicate_data =[]
    if(res.nonduplicate_merge_to != "" && res.duplicate_merge_to != ""){
    merge_data= res.nonduplicate_merge_to.split(",").map((e)=>{ return {status:"merge",data:e}})
    duplicate_data= res.duplicate_merge_to.split(",").map((e)=>{
    return {status:"duplicate",data:e}
    })
    }else if(res.nonduplicate_merge_to != "" && res.duplicate_merge_to == ""){
    merge_data= res.nonduplicate_merge_to.split(",").map((e)=>{ return {status:"merge",data:e}})
    }else if(res.nonduplicate_merge_to == "" && res.duplicate_merge_to != ""){
    duplicate_data= res.duplicate_merge_to.split(",").map((e)=>{ return {status:"duplicate",data:e}
    })
    }
    
   
    this.mergedtickets = [...merge_data,...duplicate_data]
   
    });
   
    
    },
    closeticket() {
    this.editticketinfo = false;
    },
    closewithoutticket() {
    this.editticketinfo = false;
    this.$router.push({
    name: "crm/SupportsList",
    });
    },
    saveticket() {
    this.beforeDestroyedit = true;
    this.editticketinfo = false;
    if(this.updatestatus == false){
    this.editFunction(this.final_data);
   
    }
    
    },
    GetTicketHist(payload) {
   this.ticket_id_ = payload.ticket_id
    },
    assignAgent() {
    const scope = this;
    scope.assignedDetails = { ...scope.apidata };
    scope.isShowAgentAssign = true;
    },
    closeAssign() {
    const scope = this;
    scope.assignedDetails = {};
    scope.isShowAgentAssign = false;
    // scope.$refs.refassign.resetValidation();
    },
    submitAssign() {
    const scope = this;
    const assignDetails = {
    ticket_id: scope.assignedDetails["ticket_id"],
    assign: scope.assignedDetails["user"],
    user:scope.user_email
    };
    console.log(scope.assignedDetails)
    scope.$store
    .dispatch("AssignTicket", assignDetails)
    .then((response) => {
    if (response.statusCode === 200) {
    scope.showSnackBar("Ticket assigned to agent successfully!");
    scope.assignedDetails = {};
    scope.isShowAgentAssign = false;
    scope.getApidata()
    // scope.$refs.refassign.resetValidation();
    // scope.supportdata(scope.currentsupport);
    } else {
    scope.showSnackBar("Ticket assigned to agent failed!");
    }
    })
    .catch((err) => {
    console.log("ERR :", err)
    scope.showSnackBar("Ticket assigned to agent failed!");
    });
    },
    update_fun(val) {
    let scope = this
    console.log("val",val)
    let final_data1 = [];
    // let obj = {};
    let date = new Date();
    let yyyy=date.getFullYear()
    let mm=(date.getMonth() + 1) 
    let dd=date.getDate() 
    let hr= date.getHours()
    let min=date.getMinutes()
    let sec=date.getSeconds()
    if(hr < 10) hr ='0'+hr
    if(min < 10) min ='0'+min
    if(sec < 10) sec ='0'+sec
    if(mm < 10) mm ='0'+mm
    if(dd < 10) dd ='0'+dd
    let today =`${yyyy}-${mm}-${dd} ${hr}:${min}:${sec}`
    for (let i = 0; i < val[0].length; i++) {
    const element = val[0][i];
    // for (let y = 0; y < apidataelement.length; y++) {
    // const element1 = element[y];
   
    // obj[`${element1.model}`] = element1.value.value;
    // }
    if(element.value.name !=="created_by" && element.value.name !=="created_at" && element.value.name !=="user" && element.value.name !=="ticket_id" && element.value.name !=="is_merge" && element.value.name !=="is_duplicate" && element.value.name !=="merged_to" && element.value.name !=="merged_as" ){
    
    if(element.value.name ==="modified_at") {
    element.value.value = today
    }else if((element.value.name ==="modified_by") ){
    element.value.value = scope.user_email
    }else if(element.value.name ==="tags"){
    console.log("val",element.value.value )
    // element.value.value = element.value.value.toString()
    }
    if(element.value.name !=="duration"){
        final_data1.push(element.value);
    }
    
    }
    }
    scope.final_data =final_data1
    console.log("final_data",scope.final_data.find(e=>e.name == "subject" ))
    
    },
    showSnackBar(message) {
    let scope = this;
    scope.snackbar.text = message;
    scope.snackbar.show = true;
    setTimeout(() => {
    scope.snackbar.text = "";
    }, scope.snackbar.timeout);
    },
    editFunction(val) {
    // let keys = Object.keys(val[0]);
    // let values = Object.values(val[0]);
    // let main_arr = [];
    // for (let i = 0; i < keys.length; i++) {
    // if (
    // keys[i] !== "lead_id" &&
    // keys[i] !== "user" &&
    // keys[i] !== "ticket_id" &&
    // keys[i] !== "order_id" &&
    // keys[i] !== "list_id" &&
    // keys[i] !== "hopper_status" &&
    // keys[i] !== "created_at"
    // ) {
   
    // let obj = {};
   
    // obj.name = keys[i];
    // obj.value = values[i];
    // main_arr.push(obj);
    // }
   
   
    // }
   
    // let fields = main_arr;
    // for (let i = 0; i < fields.length; i++) {
    // const element = fields[i];
    // for (let j = 0; j < this.required.length; j++) {
    // const element1 = this.required[j];
    // if (element.name === element1 && element.value === "") {
    // this.count += 1;
   
    // this.err_arr.push(element.name.replace("_", " ").toUpperCase());
    // }
    // }
    // }
    // if (this.count === 0) {
   
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();
   
    // today = yyyy + '-' + mm + '-' + dd;
   
    // main_arr.forEach(res => {
    // if (res.name == 'modified_date') {
    // res.value = today
    // }
    // if (res.name == 'modified_at') {
    // res.value = today
    // }
    // if (res.name == 'assign' && res.value == "") {
    // res.value = "unassigned"
    // }
    // if (res.name == 'contact_id') {
    // let getcontact = main_arr.find(elm => elm.name == "contact_number").value
    // res.value = this.contact_date.find(elm => elm.contactNumber == getcontact).id
    // }
    // })
    // this.payload_edit(main_arr)
    // this.err_arr = [];
    // } else {
    // this.payload_edit(main_arr)
    // this.count = 0;
    // this.err_arr = [];
    // }
    // val = val.map((e)=>{
    // if(Array.isArray(e.value)){
    // e.value = e.value.toString()
    // }
    // })
   
    let phone_numberfetch=val.find(e=>e.name == "phone_number")
    let query ={
    phone_number:phone_numberfetch.value
    }
    console.log("phone_numberfetch",phone_numberfetch)
    this.$store.dispatch("TicketPhoneSearch", query)
    .then((res) => {
    if (res.statusCode === 200&& res.data.length == 0 ) {
    this.showSnackBar(`Do create Contact ${phone_numberfetch.value}`);
    }else{
    this.payload_edit(val)
    }
    })
    .catch((error) => {
    this.insertsavepopup = false;
    console.log(error);
    });
   
   
    
    },
    payload_edit(val) {
    let scope=this
    scope.updatestatus=true
    if (val.check) {
    this.showSnackBar(`400 Bad Request: ${val.val.toString()} Is Required Field`);
    } else {
    this.count += 1
    let payload = {
    ticket_id: this.apidata.ticket_id,
    fields: val
    }
    payload.fields.push({name:"sms_notify",value:"0"})
    payload.fields.push({name:"wa_notify",value:"0"})
    payload.fields.push({name:"email_notify",value:"0"})
    payload.fields.push({name:"api_push",value:"0"})
    this.$store.dispatch("updateAsterTicket", payload).then((response) => {
    if (response.statusCode === 200) {
    
    this.showSnackBar("Ticket Updated successfully!");
    this.$router.push({
    name: "crm/SupportsList",
    });
    }
    })
    }
    },
    pushBack() {
    console.log("Ssssssss")
    this.editticketinfo = true;
    },
    mergeticket(){
    let scope = this
    scope.mergepopup = false
    let payload={
    parent_id:scope.apidata.ticket_id.toString(),
    child_id:scope.mergedata.toString(),
    duplicatemerge:scope.ticketduplicate == true ? "true":"false",
    status:scope.ticketduplicate == true ? "closed" :scope.mergestatus,
    portal:scope.user_email
    }
   
    this.$store.dispatch("MergeTicket", payload).then((res) => {
    if(res.status == 201){
    scope.mergedata=[]
    scope.ticketduplicate= false
    scope.mergestatus =""
    scope.mergerlist = true
    scope.getApidata()
    scope.showSnackBar("Merge Ticket Successfully");
    }else{
    scope.mergedata=[]
    scope.ticketduplicate= false
    scope.mergestatus =""
    scope.mergerlist = true
    scope.showSnackBar("Unable To Merge Ticket Successfully");
    }
    });
    },
    ticket_redirect(data){
    let payload ={
    ticket_id:data
    }
    this.$store.dispatch("FilterAsterSupport", payload).then((res) => { 
    this.apidata = res;
    this.apidata.due_date = this.DateFormate(this.apidata.due_date)
    payload.phone_number = res.phone_number
    this.GetTicketHist(payload);
    this.edit_datas = res.modified_at.split(' ')
    this.assgin_color = this.apidata.user =="" ? "red" : "green" 
    let merge_data= res.nonduplicate_merge_to.split(",").map((e)=>{ return {status:"merge",data:e}})
    let duplicate_data= res.duplicate_merge_to.split(",").map((e)=>{
    return {status:"duplicate",data:e}
    })
    this.mergedtickets = [...merge_data,...duplicate_data]
    this.mergepopup =false
    let obj = {};
    obj.ticket_id = res.ticket_id;
    obj.phone_number = res.phone_number;
    obj.user = res.user;
    console.log(obj)
    localStorage.setItem("ticket_templet", JSON.stringify(obj));
   
    });
    },
    UnmergeTicket(){
    
    let payload={
    parent_id: this.apidata.ticket_id.toString() ,
    child_id: this.apidata.is_duplicate == "true" ? this.apidata.duplicate_merge_to : this.apidata.nonduplicate_merge_to,
    duplicatemerge:this.apidata.is_duplicate,
    portal:this.user_email
    }
    this.$store.dispatch("UnMergeTicket", payload).then((res) => {
    if(res.status == 201){
    this.getApidata()
    this.unmergepopup = false
    }
    })
    }
    },
    watch:{
    mergerlist(val){
    console.log("newnew")
    if(val==false){
    this.ticketsearch()
    }
    }
    }
    // beforeDestroy() {
    // if (this.beforeDestroyedit) {
    // this.editFunction(this.final_data);
    // }
    // },
   };
   </script>
    
    
   <style>
   @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");
   /* .v-toolbar__content{
    padding: 0px !important;
   } */
   .grid1 {
    grid-area: header;
   }
   
   .grid2 {
    grid-area: menu;
   }
   
   .grid3 {
    grid-area: main;
   }
   
   .main_container_grid {
    display: grid;
    grid-template-areas:
    "header header header header header header"
    "menu main main main main main";
    gap: 10px;
   
    padding: 10px;
   }
   
   .grid1 {
    padding: 5px;
    border-radius: 5px;
   }
   
   .grid2 {
    /* height: 500px; */
    border: 1px solid #cacaca;
    border-radius: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100px;
   }
   
   .timeline {
    min-width: 240px;
    height: 500px;
    /* padding: 5px; */
    overflow: auto;
   }
   
   .grid3 {
    /* height: 500px; */
   
    overflow: auto;
    border: 1px solid #cacaca;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
   
    flex-direction: column;
   }
   
   .form_data {
    min-width: 800px;
    overflow: auto;
   }
   
   .profil_pic {
    display: flex;
    flex-direction: row;
   }
   
   .profil_txt {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(33 150 243 / 60%);
   }
   
   .header_cls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
   }
   
   .icon_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px 5px 5px;
   }
   
   .titlecls:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
   }
   
   .titlecls:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
   }
   
   
   
   .content_cls {
    padding: 20px 20px 20px 0px;
   }
   
   .timelinetrigger {
    cursor: pointer;
   }
   </style>