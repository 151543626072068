import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.DoNotCall;
var getters = {
    DoNot: state => state.DoNotCall.data,
}
const mutations = {
    [mutation_type.DoNotCall]: (state, response) => {
        state.DoNotCall.data = response.data.data;

    }
};
const actions = {
    DoNotCall: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                let skip ;
                let take;
                if(payload != undefined){

                    skip = payload.skip;
                    take = payload.take;
                }else{
                    skip = "";
                    take = "";
                }
                let filter=""
                if(payload.phone_number){
                     filter=`&phone_number=${payload.phone_number}`
                }
                api.get(`/dnc/fetch?skip=${skip}&take=${take}`+filter).then((response) => {
                    var donotcall_response = response;
                    commit(mutation_type.DoNotCall, donotcall_response);
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    DeleteRowdata: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/dnc/del/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterDnc: ({ commit }, query) => {
               return new Promise((resolve, reject) => {
                   try {
                       api.get(`/dnc?phone=${query}`).then(response => {
                           const pausecode = response.data;
                           commit(mutation_type.PauseCode, pausecode);
                           resolve(response.data);
                       });
                   } catch (error) {
                       reject(error);
                   }
               });
           },
};
export default {
    state,
    getters,
    mutations,
    actions
}