<template>
  <div class="SelectListlabelmain">
    <v-select v-if="currentField.multiple" :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" :disabled="currentField.disabled"  @change="getChildProperties()" :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" multiple item-text="name" item-value="value" :items="currentField.values" v-model="value"  :menu-props="{ top: true, offsetY: true }" :label="`${currentField.label}`">
        </v-select>
        <v-select v-else :disabled="currentField.disabled" :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" @change="getChildProperties()" :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`"  item-text="name" item-value="value" :items="currentField.values" v-model="value"  :menu-props="{ top: true, offsetY: true }" :label="`${currentField.label}`">
        </v-select>
  </div>
  
  
  </template>

<script>
import fetchData from "@/api/fetch-data";
import qs from "qs";

export default {
  name: "SelectList",
  props: ["currentField","Allfields"],
  data() {
    return {
      update: true,
      value: "",
      loading: false,

 required_class:"required",
            others:"others",
             read_only:"readonly"
             
    };
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }

  
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      attr.clearable = this.currentField.clearable;
      attr.multiple = this.currentField.multiple;
      attr.filterable = this.currentField.filterable;
      attr.remote = this.currentField.remote;
      if (attr.remote) {
        attr.remoteMethod = this.remoteMethod;
      }
      return attr;
    },
  },
  methods: {
    reload() {
      this.update = false;
      this.$nextTick(() => {
        this.update = true;
      });
    },
    getChildProperties() {
      const scope = this;
      if (scope.currentField.is_link === true && scope.currentField.link_type === 'Parent') {
       
        scope.Allfields.find(elm => {
         if((elm.is_link && elm.fieldType === 'SelectList') || (elm.is_link && elm.fieldType === 'RadioButton')){
          if (scope.currentField.model === elm.linked_to) {
            let arr = [];
            let val = elm.link_property[scope.value].split(',');

            val.forEach(res => {
              arr.push({ "name": res, "value": res })
            })

            elm.values = arr;
          }
         } else if ((scope.currentField.multiple === false || scope.currentField.multiple === '' || scope.currentField.multiple === undefined) && (elm.is_link && elm.fieldType != 'SelectList') || (elm.is_link && elm.fieldType != 'RadioButton')) {
           if (scope.currentField.model === elm.linked_to) {
             let val = elm.link_property;
             if (val === scope.value) {
               elm.visible = true;
             } else {
               elm.visible = false;
             }
           }
         }
        })
      }
      },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        let dataUrl = this.currentField.dataUrl;
        let valueField = this.currentField.valueField;
        let labelField = this.currentField.labelField;
        var param = { queryStr: query };
        let promise = fetchData.fetchOptionsData(
          dataUrl,
          labelField,
          valueField,
          qs.stringify(param)
        );
        promise.then((data) => {
          this.currentField.options = data;
          this.loading = false;
        });
      } else {
        this.currentField.options = [];
      }
    },
  },
  watch: {
    "currentField.multiple"() {
      this.reload();
    },
  },
};
</script>

<style>
.SelectListlabelmain{
  height: 85px;
  padding: 10px;
}

.SelectlistInput{
  margin: 20px 10px 10px 10px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
