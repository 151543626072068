import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Abandonedsummary;
var getters = {
    GetAbandonedsummary: state => state.Abandonedsummary
};
const mutations = {
    [mutation_type.Abandonedsummary]: (state, response) => {
        state.Abandonedsummary = response;
    },
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    GetAbandonedsummary: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/abdnsummary`).then(response => {
                    const Abandonedsummary = response.data.data;
                    commit(mutation_type.Abandonedsummary, Abandonedsummary);
                    resolve(response);
                });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    },
    FilterAbandonedsummary: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/abdnsummary?fromdate=${query.from_date}&todate=${query.to_date}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};