export default {
	userManagement : {
		usersdata:[],
		filter:{
			UserId: '',
			MobileNumber: Number(),
			EmailId: '',
			Role: ''
		},
		UserType:[],
		QueueList:[],
		Createqueue:[],
		AssignQueueList:[]
	},
	
}