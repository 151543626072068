<template>
  <div class="filtercontroller">
    <div v-if="filter_edit" class="filter_cls_container">
     
      <div class="filter_controll_edit" v-for="(item, index) in filters" :key="'A' + index">
        <!-- <div 
        v-for="(xaxisitem, index) in xaxis_columns"
        :key="'A' + index"> -->
        <v-flex class="filter_controll">
          <div>
            <v-select :items="xaxis_columns" label="Select Columnn To Filter" item-text="name" item-value="value"
              return-string v-model="item.column_name" :disabled="data_table == ''" persistent-hint
              @change="type_assign_edit(item.column_name, index)"></v-select>
          </div>
        </v-flex>
        <v-flex class="filter_controll" v-if="filter_edit">
          <v-select v-if="
            item.column_name &&
            (item.type === 'datetime' || item.type === 'date')
          " :items="datetype" label="Select Condition" return-string @change="datecreater_edit(item.operation, index)"
            v-model="item.temp_operation"></v-select>

          <v-select v-else-if="item.column_name && item.type === 'varchar'" :items="varechar_operations"
            label="Select Condition" item-text="name" item-value="value" return-string
            v-model="item.temp_operation"></v-select>

          <v-select v-else :items="operations" label="Select Condition" item-text="name" item-value="value"
            return-string v-model="item.temp_operation"></v-select>
        </v-flex>
        <v-flex class="filter_controll" v-if="
          filter_edit &&
          item.temp_operation !== 'IS NULL' &&
          item.temp_operation !== 'IS NOT NULL'
        ">
          <v-text-field v-if="
            (item.type === 'int' ||
              item.type === 'tinyint' ||
              item.type === 'smallint' ||
              item.type === 'mediumint' ||
              item.type === 'bigint' ||
              item.type === 'enum') &&
            item.temp_operation !== 'IS NULL' &&
            item.temp_operation !== 'IS NOT NULL'
          " required v-model="item.column_value" label="Enter Number" hint="Enter Numbers With Comma"></v-text-field>
          <v-text-field required v-model="item.column_value" label="Search" v-else-if="
            item.type === 'varchar' && item.temp_operation === 'LIKE %...%'
          "></v-text-field>
          <multicheck v-else-if="
            item.type === 'varchar' &&
            (item.temp_operation === 'IN' || item.temp_operation === 'NOT IN')
          " @updatemultiselect="multiselect_function($event)" :apidata="item.field_value"
            :column_name="item.column_name" :multiselectdata="item.column_value">
          </multicheck>

          <v-combobox v-else-if="
            item.type === 'varchar' &&
            item.temp_operation !== 'IS NULL' &&
            item.temp_operation !== 'IS NOT NULL'
          " v-model="item.column_value" :items="item.field_value" label="Select Value">
          
          </v-combobox>
        
          <datepicker :columnValue="item.column_value" v-if="
            (item.type === 'datetime' || item.type === 'date') &&
            (item.temp_operation === 'On' ||
              item.temp_operation === 'After' ||
              item.temp_operation === 'LIKE' ||
              item.temp_operation === 'LIKE %...%' ||
              item.temp_operation === 'NOT LIKE' ||
              item.temp_operation === 'Equal' ||
              item.temp_operation === 'Greater Than' ||
              item.temp_operation === 'Lesser Than' ||
              item.temp_operation === 'Greater Than And Equall' ||
              item.temp_operation === 'Lesser Than Equal' ||
              item.temp_operation === 'Not Equal')
          " @datetransfer="dategetter_edit($event, index,item.column_name)"></datepicker>

          <range :start="item.start" :end="item.end" v-else-if="
            (item.type === 'datetime' || item.type === 'date') && item.temp_operation === 'BETWEEN' 
            
          " @childdate="rangedate_function_edit($event, index,item.column_name)"></range>
          <v-text-field v-model="item.column_value" label="Date"
            v-else-if="item.type === 'datetime' || item.type === 'date'"></v-text-field>
          <!-- </v-select> -->

          <v-text-field v-if="item.type === 'time'" label="Enter time" v-model="item.column_value"
            hint="Give Values in HH:MM:SS"></v-text-field>
        </v-flex>
        <v-flex class="filter_controll" v-if="filter_edit && item.option !== ''">
          <v-select :items="options" label="Select Method" item-text="name" item-value="value" return-string
            v-model="item.option"></v-select>
        </v-flex>

        <div v-if="filter_edit && index === 0">
          <i v-if="filter_edit && index === 0 && filters.length > 1"></i>
          <i v-else-if="filter_edit && index === 0 && filters.length === 1" @click="filter_delete(item)" style="
                color: rgb(33, 150, 243);
                 padding: 5px;
              padding-top: 35px;
              font-size: 25px;
            " class="fa fa-times-circle red--text" aria-hidden="true"></i>
        </div>
        <i v-if="filter_edit && index > 0" @click="filter_delete(item)" style="
            color: rgb(33, 150, 243);
            padding: 5px;
            padding-top: 35px;
            font-size: 25px;
          " class="fa fa-times-circle red--text" aria-hidden="true"></i>
      </div>
      <!-- </div> -->
    </div>
    <div class="filter_cls_container">
      <div v-if="filter_edit === false" class="flexboxFilterCreate">
        <div style="display: flex; justify-content: space-around; flex-wrap: wrap">
          <v-flex v-if="filters.length > 0 && summarize_filter" class="filter_controll">
            <v-select :items="options" label="Select Method" item-text="name" item-value="value" return-string
              v-model="option" @change="filterValuePasser(option, 'option')"></v-select>
          </v-flex>
          <v-flex v-if="option && summarize_filter" class="filter_controll">
            <v-select :items="xaxis_columns" label="Select Column To Filter" item-text="name" item-value="value"
              return-string v-model="column_name" :disabled="option == ''" persistent-hint
              @change="type_assign(column_name, 'column_name')"></v-select>
          </v-flex>
          <v-flex v-else-if="summarize_filter && filters.length === 0" class="filter_controll">
            <v-select :items="xaxis_columns" label="Select Column To Filter" item-text="name" item-value="value"
              return-string v-model="column_name" :disabled="data_table == ''" persistent-hint
              @change="type_assign(column_name, 'column_name')"></v-select>
          </v-flex>
          <v-flex v-if="column_name" class="filter_controll">
            <v-select v-if="column_name && (type === 'datetime' || type === 'date')" :items="datetype"
              label="Select Condition" return-string v-model="operator_input"
              @change="datecreater(operator_input)"></v-select>

            <v-select v-else-if="column_name && type === 'varchar'" :items="varechar_operations"
              label="Select Condition" return-string v-model="operator_input"
              @change="operation_assign(operator_input)"></v-select>

            <v-select v-else :items="operations" label="Select Condition" return-string v-model="operator_input"
              @change="operation_assign(operator_input)"></v-select>
          </v-flex>
          <v-flex class="filter_controll" v-if="
            column_name &&
            operator_input !== 'IS NULL' &&
            operator_input !== 'IS NOT NULL'
          ">
            <v-text-field v-if="
              type === 'int' ||
              type === 'tinyint' ||
              type === 'smallint' ||
              type === 'mediumint' ||
              type === 'bigint' ||
              type === 'enum'
            " required v-model="column_value" @change="filterValuePasser(column_value, 'column_value')"
              label="Enter Number" hint="enter numbers with comma"></v-text-field>
            <v-text-field required v-model="column_value" @change="filterValuePasser(column_value, 'column_value')"
              label="Search" v-else-if="type === 'varchar' && operator_input === 'LIKE %...%'">
            </v-text-field>
            <multicheck v-else-if="
              type === 'varchar' &&
              (operator_input === 'IN' || operator_input === 'NOT IN')
            " @updatemultiselect="multiselect_function($event)" :apidata="uniqueChars" :column_name="column_name">
            </multicheck>
            <v-combobox v-else-if="type === 'varchar'" v-model="column_value" :items="uniqueChars"
              label="Select Value" persistent-hint @change="filterValuePasser(column_value, 'column_value')">
            </v-combobox>
            <datepicker v-if="
              (type === 'datetime' || type === 'date') &&
              (operator_input === 'On' ||
                operator_input === 'After' ||
                operator_input === 'LIKE' ||
                operator_input === 'LIKE %...%' ||
                operator_input === 'NOT LIKE' ||
                operator_input === 'Equal' ||
                operator_input === 'Greater Than' ||
                operator_input === 'Lesser Than' ||
                operator_input === 'Greater Than And Equal' ||
                operator_input === 'Lesser Than Equal' ||
                operator_input === 'Not Equal')
            " @datetransfer="dategetter($event)"></datepicker>
            <range v-else-if="
              (type === 'datetime' || type === 'date') && operator_input === 'BETWEEN' 
            " @childdate="rangedate_function($event)"></range>
            <v-text-field label="Date" v-model="date_shower"
              v-else-if="type === 'datetime' || type === 'date'"></v-text-field>
            <v-text-field v-if="type === 'time'" label="Enter time" v-model="column_value"
              @change="filterValuePasser(column_value, 'column_value')" hint="Give Values in HH:MM:SS"></v-text-field>
          </v-flex>
        </div>
        <div style="display: flex; justify-content: space-around">
          <i v-if="column_name || filters.length === 0 && filter_button_validator === true" @click="filter_cancel"
             style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 35px;
              font-size: 25px;
            " class="fa fa-times-circle red--text" aria-hidden="true"></i>

          <i v-if="operator_input" @click="filter_save" style="
              color: rgb(33, 150, 243);
              padding: 5px;
              padding-top: 35px;
              font-size: 25px;
            " class="fa fa-check green--text" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import range from "@/components/range_filter";
import datepicker from "@/components/date-picker";
import multicheck from "@/components/multiselect_checkbox";
export default {
  components: {
    range,
    datepicker,
    multicheck,
  },
  props: {
    filter_button_validator:{
 type: Boolean,
      default: false,
    },
    filter_edit: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },

    date_shower: {
      type: String,
      default: "",
    },
    uniqueChars: {
      type: Array,
    },
    datetype: {
      type: Array,
    },
    operations: {
      type: Array,
    },
    varechar_operations: {
      type: Array,
    },
    data_table: {
      type: String,
    },
    filters: {
      type: Array,
    },
    summarize_filter: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
    },
    xaxis_columns: {
      type: Array,
    },
  },
  data() {
    return {
      operator_input: "",
      column_name: "",
      operation: "",
      option: "",
      column_value: "",

      date_picker: "",
      field_value: [],
      start: "",
      end: "",
      temp_operation: "",
      value: '',
      arr: [],
      config: false
  };
  },
  created(){
this.config = true;
  },
  methods: {
    filter_delete(val) {
      this.$emit("filter_delete", val);
    },
    multiselect_function(val) {
      this.$emit("multiselect_function", val);
    },
    rangedate_function_edit(val, index,type) {
      
        this.xaxis_columns.map(res => {
          
        const split_obj = Object.values(val);
        const [a]=split_obj
       
        if (type == res.value) {
          this.filters[0].column_value = a
          this.filters[0].type = res.type;
        }
      })
      this.$emit("rangedate_function_edit", `${val},${index}`);
    },
    rangedate_function(val) {
      this.$emit("rangedate_function", val);
    },
    dategetter_edit(val, index,type) {
      
      this.xaxis_columns.map(res => {
       
        if (type == res.value) {
         
          this.filters[0].column_value = val
          this.filters[0].type = res.type;
        }
      })
      this.$emit("dategetter_edit", `${val},${index}`);
    },
    dategetter(val) {
      this.$emit("dategetter", val);
    },
    filter_cancel() {
      this.$emit("reseticon");
      this.$emit("filter_cancel");
      let scope = this;
      scope.summarize_filter = false;
      scope.column_name = "";
      scope.operation = "";
      scope.column_value = "";
      scope.option = "";
      scope.date_picker = "";
      scope.type = "";
      scope.field_value = [];
      scope.operator_input = "";
    },
    filter_save() {
      this.$emit("reseticon");
      this.$emit("filter_save");
      let scope = this;
      scope.column_name = "";
      scope.operation = "";
      scope.column_value = "";
      scope.option = "";
      scope.date_picker = "";
      scope.type = "";
      scope.field_value = [];
      scope.operator_input = "";
    },
    filterValuePasser(val, field) {
      this.$emit("filterValuePasser", `${field},${val}`);
    },
    type_assign_edit(val, index) {
      this.xaxis_columns.map(res => {
        if(val == res.value){
          this.filters[0].column_value = ""
          this.filters[0].type = res.type;
        }
      })
      this.value = val;
      this.$emit("type_assign_edit", `${val},${index}`);
    },
    type_assign(val, field) {
      this.filterValuePasser(val, field);
      this.$emit("type_assign", val);
    },
    datecreater_edit(val, index) {
      this.$emit("datecreater_edit", `${this.filters[0].temp_operation},${index}`);
    },
    datecreater(val) {
      this.$emit("datecreater", val);
    },
    operation_assign(val) {
      this.$emit("operation_assign", val);
    },
  },
  watch : {
   value (newvalue) {
    this.arr=[];
    var editWidget = JSON.parse(localStorage.getItem("editWidget"));

      let obj = {
        field_name:  newvalue,
        table_name: editWidget.data_table,
      };

      this.$store.dispatch("rect/fetch_fields", obj).then((response) => {
        response.forEach((element) => {
          this.arr.push(element[`${newvalue}`]);
        });
      });
      this.filters[0].field_value = this.arr;
   }
  }
};
</script>
<style>
.flexboxFilterCreate {
  display: flex;
  justify-content: "space-between";
}
.filter_controll {
  width: 250px;
  padding: 10px;
}
.filter_cls_container {
  max-width: 1120px;
  min-width: 500px;
}
.filtercontroller {
  max-width: 1120px;
  min-width: 500px;
}
.filter_controll_edit {
  display: flex;
  justify-content: space-between;
}
</style>