<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="openCallflowFilter"
      @refreshPage="refreshThisPage"
      @addNew="NewCallflow"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="callflowdata"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <!-- <td class="checkEmpty p0">{{ props.item.flow_id }}</td> -->
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.created_by }}</td>
          <td class="checkEmpty p0">{{ props.item.created_at }}</td>
          <td class="text-xs-center checkEmpty p0">
            <!-- <v-icon color="green" small class="mr-4" @click="editItem(props.item)">edit</v-icon> -->
            <v-icon
              color="grey"
              small
              class="mr-4"
              v-if="page.canrender"
              @click="showData(props.item)"
              >info</v-icon
            >
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canrender"
              @click="moveToRender(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete list info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this list.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRowData" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">JSON Data</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>{{ DataRow }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closeShow()">Close</v-btn>
          <!-- <v-btn color="primary" flat @click="moveToRender()">Render</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field
              v-model="filter.payload.number"
              label="Phone Number"
              small
            ></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.status"
              :items="filter.options.status"
              label="Status"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="livaagents()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog persistent max-width="500px" v-model="showPopup">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Enter New Flow Name</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                style="text-decoration: none"
                v-model="newtry"
                required
                label="Flow Name"
                type="text"
                :rules="[(v) => !!v || 'Flow Name is required']"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAdd()">Close</v-btn>
          <v-btn color="primary" flat @click="saveAdd(newtry)">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
// import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
import moment from "moment";

function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Call Flow",
        icon: "fa-project-diagram fa-2x",
        loading: false,
        isAddCallflow: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: false, refresh: true },
        addnew: [],
        temp: { key: "addcallflow", label: "Add Callflow" },
        candelete: false,
        canrender: false,
      },
      newtry: "",
      addPage: false,
      showPopup: false,
      pagecount: 0,
      headers: [
        {
          text: "name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "name",
        },
        {
          text: "Created_by",
          align: "left",
          class: "p0",
          sortable: true,
          value: "created_by",
        },
        {
          text: "Created_at",
          align: "left",
          class: "p0",
          sortable: true,
          value: "created_at",
        },
        // { text: "Status", align: "left", class: "p0", sortable: false, value: "status" },
        {
          text: "Action",
          align: "center",
          class: "p0",
          sortable: false,
          value: "action",
        },
      ],
      callflowdata: [],
      // pagecount: 0,
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      filter: {
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      addCallflow: {
        valid: false,
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
    };
  },
  methods: {
    closeAdd() {
      this.showPopup = false;
    },
    saveAdd(data) {
      try {
        var scope = this;
        localStorage.setItem("flowName", data);
        const name = {
          name: data,
        };
        scope.$store
          .dispatch("CheckName", name)
          .then((response) => {
            if (response.message == "success") {
              scope.$router.push({ name: "CallFlow/Create" });
            } else {
              scope.showSnackBar("Error! Name Already Exist");
            }
          })
          .catch(() => {
            scope.showSnackBar("Error! Unable To Add");
          });
      } catch (err) {
        console.log("ERR", err);
      }
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    NewCallflow: function () {
      this.showPopup = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store.dispatch("CallFlow").then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data.data;
        datas.forEach(element => {
          element.created_at = moment(element.created_at).format("YYYY-MM-DD HH:mm:ss");
        });
        scope.callflowdata = datas;
      });
    },
    openCallflowFilter: function () {
      this.page.isFilterOpen = true;
    },
    CloseAddCallflow: function () {
      this.page.isAddCallflow = false;
    },
    onFileRemove: function (args) {
      args.postRawFile = false;
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    showData(item) {
      let scope = this;
      scope.$store.dispatch("updateJSON", item);
      scope.showRowData = true;
      scope.DataRow = item;
    },
    moveToRender(item) {
      let scope = this;
      scope.$store.dispatch("updateJSON", item);
      scope.$router.push({ name: "CallFlow/Render" });
    },
    deleteItem(item) {
      this.DeleteRowdata = true;
      this.delete_id = item.flow_id;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteCallflowRow", scope.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("List Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    downloadSample: function () {
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += "phone_number\r\n";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sample_blocklist.csv");
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf("call:flow:create") !== -1
    ) {
      this.page.addnew = [{ label: "Add New" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf("call:flow:delete") !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf("call:flow:render") !== -1
    ) {
      scope.page.canrender = true;
    }
    this.uploadBlocklist = getDefaultData();
    this.getDataFromApi();
  },
  created() {},
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
