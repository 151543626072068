<template>
    <div v-if="preview" >

        <barBasic ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'BasicBar'"></barBasic>
        <barGroup ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'GroupBar'"></barGroup>
        <barStacked ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger"
            @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name" :id="chart[0].id"
            v-if="chart[0].chart_type === 'StackedBar'"></barStacked>
        <ColumnChart ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'Column'"></ColumnChart>
        <ColumnGroup ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'ColumnGroup'"></ColumnGroup>
        <donut ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name"
            :id="chart[0].id" v-if="chart[0].chart_type === 'donut'">
        </donut>
        <GradientRadial ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'GradientRadial'"></GradientRadial>
        <MultipleRadial ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :width="width" :height="height"
            :timer="chart[0].timer" :name="chart[0].name" :id="chart[0].id"
            v-if="chart[0].chart_type === 'MultipleRadial'">111</MultipleRadial>
        <LineBasic ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'BasicLine'"></LineBasic>
        <LineWithLable ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'LableLine'"></LineWithLable>
        <pie ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name"
            :id="chart[0].id" v-if="chart[0].chart_type === 'Pie'"></pie>
        <polarArea ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'PolarArea'"></polarArea>
        <zoomTime ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'ZoomTime'"></zoomTime>
        <tables ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" @popup="popup(chart[0])" :toolbarTrigger="toolbarTrigger" v-if="chart[0].type === 'Table'"
            :id="chart[0].id" :name="chart[0].name" :timer="chart[0].timer"></tables>
    </div>
     <div v-else >

        <barBasic_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'BasicBar'"></barBasic_Preview>
        <barGroup_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'GroupBar'"></barGroup_Preview>
        <barStacked_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger"
            @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name" :id="chart[0].id"
            v-if="chart[0].chart_type === 'StackedBar'"></barStacked_Preview>
        <ColumnChart_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'Column'"></ColumnChart_Preview>
        <ColumnGroup_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'ColumnGroup'"></ColumnGroup_Preview>
        <donut_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name"
            :id="chart[0].id" v-if="chart[0].chart_type === 'donut'">
        </donut_Preview>
        <GradientRadial_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'GradientRadial'"></GradientRadial_Preview>
        <MultipleRadial_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :width="width" :height="height"
            :timer="chart[0].timer" :name="chart[0].name" :id="chart[0].id"
            v-if="chart[0].chart_type === 'MultipleRadial'">111</MultipleRadial_Preview>
        <LineBasic_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'BasicLine'"></LineBasic_Preview>
        <LineWithLable_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'LableLine'"></LineWithLable_Preview>
        <pie_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer" :name="chart[0].name"
            :id="chart[0].id" v-if="chart[0].chart_type === 'Pie'"></pie_Preview>
        <polarArea_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'PolarArea'"></polarArea_Preview>
        <zoomTime_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" :toolbarTrigger="toolbarTrigger" @popup="popup(chart[0])" :timer="chart[0].timer"
            :name="chart[0].name" :id="chart[0].id" v-if="chart[0].chart_type === 'ZoomTime'"></zoomTime_Preview>
        <tables_Preview ref="name" @reset="reset(chart[0])" :apply_pass="apply_pass" :alldata="chart" @popup="popup(chart[0])" :toolbarTrigger="toolbarTrigger" v-if="chart[0].type === 'Table'"
            :id="chart[0].id" :name="chart[0].name" :timer="chart[0].timer"></tables_Preview>
    </div>

</template>
<script>
import tables from './tables/table.vue'
import barBasic from './bar-basic.vue'
import barGroup from './bar-Grouped.vue'
import barStacked from './bar-Stacked.vue'
import ColumnChart from './column-charts.vue'
import ColumnGroup from './column-with-group.vue'
import donut from './donut-basic.vue'
import GradientRadial from './gradient-radialbar.vue'
import MultipleRadial from './multiple-radialbars.vue'
import LineBasic from './line-basic.vue'
import LineWithLable from './line-charts-data-labels.vue'
import pie from './pie-basic.vue'
import polarArea from './polar-area.vue'
import zoomTime from './zoom-timeseries.vue'
import barStacked_Preview from './preview/bar-Stacked.vue'
import tables_Preview from './preview/tables/table.vue'
import barBasic_Preview from './preview/bar-basic.vue'
import barGroup_Preview from './preview/bar-Grouped.vue'
import ColumnChart_Preview from './preview/column-charts.vue'
import ColumnGroup_Preview from './preview/column-with-group.vue'
import donut_Preview from './preview/donut-basic.vue'
import GradientRadial_Preview from './preview/gradient-radialbar.vue'
import MultipleRadial_Preview from './preview/multiple-radialbars.vue'
import LineBasic_Preview from './preview/line-basic.vue'
import LineWithLable_Preview from './preview/line-charts-data-labels.vue'
import pie_Preview from './preview/pie-basic.vue'
import polarArea_Preview from './preview/polar-area.vue'
import zoomTime_Preview from './preview/zoom-timeseries.vue'

export default {
    components: {

        barBasic,
        barGroup,
        barStacked,
        ColumnChart,
        ColumnGroup,
        donut,
        GradientRadial,
        MultipleRadial,
        LineBasic,
        LineWithLable,
        pie,
        polarArea,
        zoomTime,
        tables,
        barStacked_Preview,
        barBasic_Preview,
        barGroup_Preview,
        ColumnChart_Preview,
        ColumnGroup_Preview,
        donut_Preview,
        GradientRadial_Preview,
        MultipleRadial_Preview,
        LineBasic_Preview,
        LineWithLable_Preview,
        pie_Preview,
        polarArea_Preview,
        zoomTime_Preview,
        tables_Preview,
    },
    props: {
        apply_pass: {
            type: Number,
            //default: 0
        },
        chart: {
            type: Array
        },
        width: {
            type: Number,
            default: 0
        },
        height: {
            type: Number,
            default: 0
        },
        toolbarTrigger: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
preview : true
        }
    },
    methods: {
        popup(val) {
            this.$emit("popup", val);

        },
        reset(val) {
            this.$emit("reset", val);
        },
         preview_customIcons() {
this.preview = false;
        },
    }

}
</script>
<style>
</style>
