<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewData" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="whatsappData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align:center">
          <div style="text-align:center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.id }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.message }}</td>
          <td class="checkEmpty p0">{{ props.item.status == "Y" ? "Active" : "Inactive" }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Template</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isAddWhatsapp" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Template</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="wattsapp" v-model="valid" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addWhatsapp.payload.name" required label="Name" type="text"
                      :rules="[v => !!v || 'Name is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addWhatsapp.payload.message" required label="Message" type="text"
                      :rules="[v => !!v || 'Message is required']"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddWhatsapp()">Close</v-btn>
            <v-btn color="primary" @click="saveAddWhatsapp()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-dialog persistent :value="page.isEditWhatsapp" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Edit Template</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field v-model="editWhatsapp.payload.name" required label="Name" type="text"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="editWhatsapp.payload.message" required label="Message" type="text"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select v-model="editWhatsapp.payload.status" :items="editWhatsapp.options.status" label="Status"
                item-text="name" item-value="value" return-string persistent-hint></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditWhatsapp()">Close</v-btn>
          <v-btn color="primary" @click="saveEditWhatsapp()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color:white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select v-model="filter.payload.name" :items="filter.payload.options" label="Name" small></v-select>
            <v-select v-model="filter.payload.status" :items="filter.options.status" label="Status" item-text="name"
              item-value="value" return-string persistent-hint></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi('reset')">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px"
      },
      page: {
        title: "Whatsapp",
        icon: "fa fa-whatsapp fa-3x",
        loading: false,
        isAddWhatsapp: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditWhatsapp: false,
        temp: { key: "addasterwhatsapp", label: "Add AsterWhatsapp" },
        candelete: false,
        canedit: false
      },
      pagecount: 0,
      headers: [
        {
          text: "Id",
          align: "left",
          class: "p0",
          sortable: false,
          value: "id"
        },
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name"
        },
        {
          text: "Message",
          align: "left",
          class: "p0",
          sortable: false,
          value: "message"
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status"
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%"
        }
      ],
      whatsappData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: ""
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filter: {
        payload: {
          name: "",
          options: [],
          status: ""
        },
        options: {
          status: ["Active", "Inactive"]
        }
      },
      //   pagination: {
      //     page: 0,
      //     rowsPerPage: 5
      //   },
      isProcessing: false,
      call_status: [],
      editWhatsapp: {
        payload: {
          id: "",
          name: "",
          message: "",
          status: ""
        },
        options: {
          status: ["Active", "Inactive"]
        }
      },
      addWhatsapp: {
        valid: false,
        payload: {
          name: "",
          message: ""
        }
      }
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.filter.payload.status = ""
      scope.filter.payload.name = ""
      this.pagination.page=1
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      if (scope.filter.payload.status == 'Active') {
        scope.filter.payload.status = 'Y';
      }
      else if (scope.filter.payload.status == 'Inactive') {
        scope.filter.payload.status = 'N';
      }
      this.page.isFilterOpen = false;
      var data = {
        name: scope.filter.payload.name,
        status: scope.filter.payload.status,
        skip: "",
        take: ""
      };
      this.$store.dispatch("FilterAsterWhatsapp", data).then(response => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.whatsappData = datas;
        scope.pagecount = datas.length

      })
        .catch(function (error) {
          scope.page.isAddWhatsapp = false;
          scope.showSnackBar("error" + error);
          this.page.isEditWhatsapp = false;
        });
    },
    getDataFromApi(val) {
      var scope = this;
      if ('reset' === val) {
        scope.filter.payload.name = "";
        scope.filter.payload.status = "";

      }
      scope.tableLoading = true;
      scope.filter.payload.name = ""
      scope.filter.payload.status = ""
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage
      let skip
      if (this.pagination.page === 1) {
        skip = 0
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      }
      let query = {
        name: scope.filter.payload.name,
        status: scope.filter.payload.status,
        skip: skip,
        take: take
      }
      this.skip=skip
      this.$store.dispatch("GetAsterWhatsapp", query).then(response => {
        scope.tableLoading = false;
        scope.page.loading = false;
        scope.pagecount = response.count;
        response.filterwhatsapp.forEach(element =>
          scope.filter.payload.options.push(element.name)
        );
        var datas = response.data;
        scope.whatsappData = datas;
      })
        .catch(function (error) {
          if (error.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
          scope.page.isAddWhatsapp = false;
          scope.showSnackBar("error" + error);
          this.page.isEditWhatsapp = false;
        });
    },
    AddNewData: function () {
      //var scope = this;
      this.page.isAddWhatsapp = true;
    },
    closeEditWhatsapp: function () {
      this.page.isEditWhatsapp = false;
    },
    closeAddWhatsapp: function () {
      this.page.isAddWhatsapp = false;
      this.$refs.wattsapp.reset();
    },
    saveEditWhatsapp() {
      var scope = this;
      if (scope.editWhatsapp.payload.status == 'Active') {
        scope.editWhatsapp.payload.status = 'Y';
      }
      else if (scope.editWhatsapp.payload.status == 'Inactive') {
        scope.editWhatsapp.payload.status = 'N';
      }
      scope.$store
        .dispatch("updateAsterWhatsapp", scope.editWhatsapp.payload)
        .then(() => {
          scope.page.isAddWhatsapp = false;
          this.getDataFromApi();
          scope.showSnackBar("Updated Successfully");
          this.page.isEditWhatsapp = false;
        })
        .catch(function (error) {
          scope.page.isAddWhatsapp = false;
          scope.showSnackBar("error" + error);
          this.page.isEditWhatsapp = false;
        });
      this.getDataFromApi();
    },
    saveAddWhatsapp() {
      var scope = this;
      this.$refs.wattsapp.validate();

      scope.page.isAddWhatsapp = !this.$refs.wattsapp.validate();
      if (this.$refs.wattsapp.validate()) {
        scope.$store
          .dispatch("insertAsterWhatsapp", scope.addWhatsapp.payload)
          .then(() => {
            scope.page.isAddWhatsapp = false;
            scope.showSnackBar("Created Successfully");
            scope.addWhatsapp.payload.name = "";
            scope.addWhatsapp.payload.message = "";
            scope.$refs.wattsapp.reset();
            scope.getDataFromApi();
          })
          .catch(function (error) {
            scope.page.isAddWhatsapp = false;
            scope.showSnackBar("error" + error);

            scope.getDataFromApi();
          });
      }

    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditWhatsapp = true;
      this.editWhatsapp.payload.id = item.id;
      this.editWhatsapp.payload.name = item.name;
      this.editWhatsapp.payload.message = item.message;
      if (item.status == 'Y') {
        this.editWhatsapp.payload.status = 'Active';
      }
      else if (item.status == 'N') {
        this.editWhatsapp.payload.status = 'Inactive';
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAsterWhatsapp", this.delete_id)
        .then(response => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Template Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete Template");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete Template");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    }
  },
  computed: {
    ...mapGetters(["CallFlow"])
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (scope.$store.getters.UserPermissions.indexOf('template:whatsapp:create') !== -1) {
      this.page.addnew = [{ label: "Add Template" }];
    }
    if (scope.$store.getters.UserPermissions.indexOf('template:whatsapp:delete') !== -1) {
      scope.page.candelete = true;
    }
    if (scope.$store.getters.UserPermissions.indexOf('template:whatsapp:edit') !== -1) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
  }
  , watch: {
    pagination: {
      handler() {
        this.demo += 1
          if(this.pagination.page>1 || this.payload.skip>=10){
         this.getDataFromApi();
        }
      },
      deep: true,
    },
  }
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>