import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Contact;
var getters = {
    GetContact: state => state.Contact
};
const mutations = {
    [mutation_type.Contact]: (state, response) => {
        state.Contact = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetContact: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/asterdialer/contact/fetch`).then(response => {
                    const contact = response.data.data;
                    commit(mutation_type.Contact, contact);
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterContact: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/asterdialer/contact/fetch?name=${query.name}&number=${query.number}&email=${query.email}`).then(response => {
                    const contact = response.data;
                    commit(mutation_type.Contact, contact);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    uploadContact: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/asterdialer/contact/upload`).then((response) => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    insertContact: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/asterdialer/contact/create`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response.data.message);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteContact: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/asterdialer/contact/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateContact: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/asterdialer/contact/update/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error.response.data.message);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};