<template>
  <v-dialog persistent :value="headerEdit" scrollable max-width="600px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Header Config</v-toolbar-title>
      </v-toolbar>
      <v-container id="scroll-target" style="max-height: 800px" class="scroll-y">
        <div class="headcontainer">
          <div class="drag-container">
            <h3>Fields</h3>
            <draggable class="list-group" v-model="sourcefield" :list="sourcefield" v-bind="dragOptions" group="people"
              @change="sourcelog" @start="soruceDragStart" @end="sourceDragStart" :move="sourcecheckMove"
              :disabled="disableDraggablefield">
              <v-card class="mx-auto list-group-item" max-width="344" color="#cacaca" hover
                v-for="(element, index) in sourcefield" :key="index">
                <v-card-subtitle>
                  <div class="drag-main-container">
                    <i class="fa fa-bars fa-2x drag-icon" aria-hidden="true"></i>
                    <span class="drag-text-container">
                      {{ element.lable }}
                    </span>
                  </div>
                </v-card-subtitle>
              </v-card>
            </draggable>
          </div>

          <div class="drag-container">
            <h3>Table Fields</h3>
            <draggable class="list-group" v-model="destinationfield" :list="destinationfield" v-bind="dragOptions" group="people"
              @change="destinationlog" @start="destinationDragStartchange" @end="destinationDragEndchange"
              :move="destinationcheckMove" :disabled="disableDraggableTable" >
              <v-card class="mx-auto list-group-item" max-width="344" color="#2196f3" hover
                v-for="(element, index) in destinationfield" :key="index">
                <v-card-subtitle>
                  <div class="drag-main-container">
                    <i class="fa fa-thumb-tack fa-2x drag-icon" aria-hidden="true"></i>
                    <span class="drag-text-container">
                      {{ element.lable }}
                    </span>
                  </div>
                </v-card-subtitle>
              </v-card>
            </draggable>
          </div>
        </div>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light" flat @click="closePopupHeader">Cancel</v-btn>
        <v-btn color="primary" @click="savePopupHeader">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "table-field",
  components: {
    draggable
  },
  props: {
    headersfield: {
      type: Array
    },
    headerEdit: Boolean
  },
  data() {
    return {
      sourcefield:[],
      destinationfield: [],
      soruceDrag: true,
      destinationDrag: true,
      disableDraggableTable: false,
      disableDraggablefield: false,
      start: "",
    };
  },
  methods: {
    sourcelog(evt) {
      let scope = this
      window.console.log("Soruce:", evt);
      console.log(scope.sourcefield)

      scope.sourcefield = scope.sourcefield.map((e,i)=>{
        e.table_position =i + 1
        e.table_show = 'false'
        return e
      })
    },
    destinationlog(evt) {
      let scope = this
      scope.destinationfield = scope.destinationfield.map((e, i) => {
        e.table_show = 'true'
        e.table_position = i + 1
        return e
      })
      // if (this.items.length > this.maxItems) {
      //   this.items = this.items.slice(0, this.maxItems); // Truncate the list if it exceeds the maximum allowed items
      // }

      window.console.log("destination:", evt);
      // scope.disableDraggablefield = false
      // console.log(evt)
    },
    sourcecheckMove(e) {
      window.console.log("source index: " + e.draggedContext.futureIndex);
    },
    destinationcheckMove(e) {
      window.console.log("destination index: " + e.draggedContext.futureIndex);
    },
    closePopupHeader() {
      let scope = this
      scope.$emit("closePopupHeader");
    },
    savePopupHeader(){
      let scope = this
      scope.$emit("savePopupHeader",[...scope.destinationfield,...scope.sourcefield]);
    },
    destinationDragStartchange(e) {
      // console.log(e)
      this.start = e
    },
    destinationDragEndchange(e) {
      // console.log(e)
      this.start = e
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

  },
  watch: {
    headersfield: {
      handler: function () {
        this.destinationfield = []
        this.sourcefield = []
        this.headersfield.forEach(element => {
        if(element.table_show == "true"){
          console.log(element,"santhoshlog")
          this.destinationfield.push(element)
        }else{
          // element.table_position = 0
          // element.table_show = "false"
          this.sourcefield.push(element)
        }
      });
      this.sourcefield.sort(function(a, b){return a.table_position - b.table_position})
      this.destinationfield.sort(function(a, b){return a.table_position - b.table_position})
      }
    },
  },
};
</script>
<style>
.disable-source{
  pointer-events:none;
}
.headcontainer {
  display: flex;
  flex-direction: row;
}

.drag-container {
  width: 280px;
  padding: 5px;
  overflow: scroll;
}

.mx-auto {
  margin: 5px;
  box-shadow: 9px 9px 9px rgba(128, 128, 128, 0.6);
}

.mx-auto:hover {
  box-shadow: 9px 9px 9px rgba(20, 20, 20, 0.6);
}

.drag-text-container {
  font-size: 15px;
  color: #ffff;
  font-weight: 700;
}

.drag-icon {
  color: #ffff;
  margin-right: 5px;
}

.drag-main-container {
  display: flex;
  flex-direction: row;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 50px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>