<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <div class="selectboxcontriller">
        <div class="txtarea">
          <div>
            <v-text-field
              v-if="multiselectdata !== 'empty'"
              @click="combaine_data"
              v-bind="attrs"
              v-on="on"
              readonly
              label="Select field value"
              v-model="multiselectdata"
            >
            </v-text-field>
            <v-text-field
              v-else
              @click="combaine_data"
              v-bind="attrs"
              v-on="on"
              readonly
              label="Select field value"
              v-model="view_data"
            >
            </v-text-field>
          </div>
        </div>
        <!-- <div class="icon_div">
          <i
            @click="combaine_data"
            v-bind="attrs"
            v-on="on"
            class="fa fa-plus-circle green--text icon_cls"
            aria-hidden="true"
            style="color: rgb(33, 150, 243); font-size: 25px"
          ></i>
        </div> -->
      </div>
    </template>
    <v-card>
      <div class="headerselectall">
        <div>
          <v-checkbox
            v-model="sall"
            color="primary"
            style="padding: 0px 0px 0px 16px; "
            @change="selectallfun"
          ></v-checkbox>
        </div>
        <div class="selectlable">Select All</div>
      </div>
      <v-card-text
        style="
        margin-bottom: 3px;
          height: 200px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.42);
          padding-bottom: 0px;
        "
      >
        <div>
          <div v-for="(products, index) in validate" :key="index">
            <div style="display: flex; height: 40px">
              <v-checkbox
              color="primary"
                style="width: 20px; margin: 0px"
                v-model="products.isactive"
                @change="checksall"
              ></v-checkbox>
              <div
                style="width: 250px; margin-left: 5px; padding: 5px"
                >{{ products.value }}</div
              >
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary darken-1" text @click="canclecheckbox">
          Close
        </v-btn>
        <v-btn
          style="margin-left: 100px"
          color="primary darken-1"
          text
          @click="savecheckbox"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
export default {
  watch: {
    column_name() {
      this.selected_data = [];
      this.view_data = "";
    },
  },
  props: {
    multiselectdata: { type: String, default: "empty" },
    apidata: Array,
    column_name: String,
  },

  data() {
    return {
      dialogm1: "",
      dialog: false,
      validate: [],
      selected_data: [],
      view_data: "",
      temp_column_name: "",
      view_data1: "",
      props_val: "",
      sall:false
    };
  },
  mounted() {
    this.temp_column_name = this.column_name;
  },
  methods: {
    selectallfun() {
      for (let i = 0; i < this.validate.length; i++) {
        if (this.validate[i].isactive === false && this.sall == true) {
          this.validate[i].isactive = true;
        }
        if (this.sall == false) {
          this.validate[i].isactive = false;
        }
      }
    },
    checksall() {
      if (
        this.validate.filter((elm) => elm.isactive == true).length ===
        this.validate.length
      ) {
        this.sall = true;
      } else {
        this.sall = false;
      }
    },
    combaine_data() {
let valided = [];
if (this.selected_data.length == 0) {
  for (let i = 0; i < this.apidata.length; i++) {
        let obj = {
          value: this.apidata[i],
          isactive: false,
        };
        valided.push(obj);
        this.selected_data = [];
        this.view_data = "";
      }

      this.validate = [...new Set(valided)];
}

      
    },
    canclecheckbox() {
      (this.dialog = false);
    },
    savecheckbox() {
      this.multiselectdata = '';
      let select_arr = [];
      for (let i = 0; i < this.validate.length; i++) {
        if (this.validate[i].isactive === true) {
          select_arr.push(this.validate[i].value);
        }
      }
      this.selected_data = [...new Set(select_arr)];
      this.view_data = this.selected_data.toString();
      this.multiselectdata =  this.view_data;
      (this.dialog = false);
      this.$emit("updatemultiselect", this.view_data);
    },
  },
};
</script>
<style >
.txtarea {
  width: 260px;
}
.selectboxcontriller {
  display: flex;
  width: 350px;
}
.icon_cls {
  margin-top: 25px;
  margin-left: 3px;
}
.headerselectall {
  display: flex;
  flex-direction: row;
  border-bottom:1px solid #cacaca;
  height: 46px;
}
.selectlable{
  margin-top: 18px;
}
</style>
