<template>
  <div class="permission-container">
    <div class="main-heading ">   <div class="titlecls title" style="padding:0px 0px 5px 0px;margin-bottom:15px">{{ (gname== 'realtime') ? "Real Time" : (gname == 'leadmanagement') ? "Leads" : (gname == 'formbuilder') ? "Form Builder" : (gname == 'custommodule') ? "Custom Module" : gname.charAt(0).toUpperCase() + gname.slice(1) }}</div></div>
    <div class="maincontainer_flexcls1">
   
      <div
        v-for="(submodule, submoduleKey, submoduleIndex) in list"
        :key="`submodule-${submoduleIndex}`"
      > 
       
        <div class="subcontainer1" >
       
          <v-expansion-panel >
           
            <v-expansion-panel-content>
              <template v-slot:header >
                <div class="group-title">
                  <div class="gore">
                    <input                 
                      type="checkbox"
                      :checked="ischeckall(submodule)"
                      @change="subselect(submodule, gname)"
                      id="event.name"
                    />
                  </div>
                  <div class="sub-menu">
                    <!-- {{submoduleKey.charAt(0).toUpperCase() + submoduleKey.slice(1)}}  -->
                    {{ (submoduleKey=="audiostore")?"Audio Store":(submoduleKey=="blocklist")?"Block List":(submoduleKey=="dialrule")?"Dial Rule":(submoduleKey=="didnumber")?"DID Number":(submoduleKey=="dnc")?"DNC":(submoduleKey=="inboundroute")?"Inbound Route":(submoduleKey=="leadstatus")?"Lead Status":(submoduleKey=="pausecode")?"Pause Code":(submoduleKey=="sla")?"SLA":(submoduleKey=="subdisposition")?"Sub-Disposition":(submoduleKey=="usergroup")?"User Group":(submoduleKey=="webhooks")?"Web Hooks":(submoduleKey=="module")?"Template":(submoduleKey=="reportbuilder")?"Report Builder":(submoduleKey.includes("-"))?submoduleKey.split("-")[0].charAt(0).toUpperCase() + submoduleKey.split("-")[0].slice(1)+" "+submoduleKey.split("-")[1].charAt(0).toUpperCase() + submoduleKey.split("-")[1].slice(1):(submoduleKey=="liveagents")?"Live Agents":(submoduleKey=="livecalls")?"Live Calls":(submoduleKey=="livestatus")?"Live Status":(submoduleKey=="queueperformance")?"Queue Performance":(submoduleKey=="abandonedsummary")?"Abandoned Summary":(submoduleKey=="agentactivity")?"Agent Activity":(submoduleKey=="agentdispo")?"Agent Dispo":(submoduleKey=="agentpause")?"Agent Pause":(submoduleKey=="agentperformance")?"Agent Performance":(submoduleKey=="calldetail")?"Call Detail":(submoduleKey=="callsummary")?"Call Summary":(submoduleKey=="dropedcall")?"Droped Call":(submoduleKey=="effectiveabandon")?"Effective Abandon":(submoduleKey=="missedcall")?"Missed Call":(submoduleKey=="queueperformance")?"Queue Performance":
                    (submoduleKey=="ticketbyagent")?"Ticket By Agent":(submoduleKey=="whatsappbyagent")?"Whatsapp By Agent":(submoduleKey=="sms")?"SMS":submoduleKey.charAt(0).toUpperCase() + submoduleKey.slice(1) }}
                  </div>
                </div>
              </template>
              <div class="titles_list">
                
                <div v-for="(event, index) in submodule.value" :key="index">
                  <input
                    type="checkbox"
                    name=""
                    :id="event.permission"
                    :checked="ischecked(event.permission)"
                    @click="
                      updatePermission(
                        event.permission,
                        event.title,
                        submodule,
                        gname
                      )
                    "
                  />
                  <label
                    :class="{ active: ischecked(event.permission) }"
                    :for="event.permission"
                    >{{ event.title }}</label
                  >
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      
      </div>
      
    </div>
   </div>
  
  </template>
  <script>
  export default {
    data() {
      return { 
        active: null,
        userinfo:null
      };
    },
     mounted(){
      this.userinfo = JSON.parse(localStorage.apiInfo).user_email;
      console.log(this.userinfo,'this is userinfo')
    },
  
    props: {
      Gvalue: {
        type: Array,
      },
      list: {
        type: Object,
      },
      gname: {
        type: String,
      },
      ischeckall: {
        type: Function,
      },
      subselect: {
        type: Function,
      },
      ischecked: {
        type: Function,
      },
      updatePermission: {
        type: Function,
      },
    },
   
  };
  </script>
  <style scoped>
  .maincontainer_flexcls1 {
   
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    
  }
  .permission-container{
   margin-left:20px;
   padding: 10px;
  }
  .subcontainer1 {
    width: 200px;
   
  }
  .sub-menu {
    padding: 3px;
  }
  .group-title {
    display: flex;
    flex-direction: row;
  }
  .titles_list {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
  }
  .main-heading{
    color:#2196f3 !important
  }
  
  </style>
  