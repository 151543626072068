<template>
    <div>
      <section-definition
        :title="page.title"
        :extraOptions="page.options"
        :icon="page.icon"
        :breadcrumbs="page.breadcrumbs"
        :addNewData="page.addnew"
        :exportOptions="page.exportOptions"
        @openFilter="filterClick"
        @refreshPage="refreshThisPage"
        @addNew="AddNewSla"
        :style="styleObject"
      >
      </section-definition>
      <loader v-if="page.loading"></loader>
      <v-container v-else fluid grid-list-xl page>
        <v-data-table
          :headers="headers"
          :items="sladata"
          class="elevation-1 fixed-layout"
          :pagination.sync="pagination"
          :total-items="pagecount"
          :loading="tableLoading"
          :rows-per-page-items="[10]"
        >
          <template v-slot:no-data style="text-align: center">
            <div style="text-align: center">No Data Available</div>
          </template>
          <template v-slot:items="props">
            <td class="checkEmpty p0">{{ props.item.name }}</td>
            <td
              class="checkEmpty p0"
              v-if="props.item.description != 'NULL'"
            >
              {{ props.item.description }}
            </td>
            <td
              class="checkEmpty p0"
              v-if="props.item.description == 'NULL'"
            ></td>
  
            <td class="checkEmpty p0">{{ props.item.priority }}</td>
   <td class="checkEmpty p0">{{ props.item.duration }}</td>
    <td class="checkEmpty p0">{{ props.item.status }}</td>
            <!-- <td class="checkEmpty p0">
    <template v-if="props.item.status === 'Ac'">Active</template>
    <template v-else>Inactive</template>
    </td> -->
  
            <td class="text-xs-center checkEmpty p0">
              <v-icon
                color="blue"
                small
                class="mr-4"
                v-if="page.canedit"
                @click="editItem(props.item)"
                >edit</v-icon
              >
              <v-icon
                color="red"
                small
                class="mr-4"
                v-if="page.candelete"
                @click="deleteItem(props.item.id)"
                >delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you Sure to delete this?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>
        <v-dialog
          persistent
          :value="page.isAddSla"
          scrollable
          max-width="500px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Create</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="Sla" v-model="valid">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="addSla.payload.name"
                        required
                        label="Name"
                        type="text"
                        :rules="[(v) => !!v || 'Name is required']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="addSla.payload.description"
                        required
                        label="Description"
                        type="text"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-select v-model="addSla.payload.priority" required label="Priority" :items="priority"
                      item-text="text"
                      item-value="value"
                      
                      :rules="[(v) => !!v || 'Priority is required']"></v-select>
                      </v-flex>
                      <v-flex xs12>
                      <v-select v-model="addSla.payload.status" required label="Status" :items="status"
                      item-text="text"
                      item-value="value"
                        
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                      </v-flex>
                     <v-flex xs12>
        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          lazy
  
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="addSla.payload.duration"
              label="Time"
              
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="addSla.payload.duration"
            full-width format="24 hr"
            @click:minute="$refs.menu.save(addSla.payload.duration)"
          ></v-time-picker>
        </v-menu>
      </v-flex>
     
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeaddSla()">Close</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="saveaddSla()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog
          persistent
          :value="page.isEditSla"
          scrollable
          max-width="500px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Update</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="editSla" v-model="valid">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editSla.name"
                        required
                        label="Name"
                        type="text"
                        :rules="[(v) => !!v || 'Name is required']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editSla.description"
                        required
                        label="Description"
                        type="text"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-select v-model="editSla.priority" required label="Priority" :items="priority"
                      item-text="text"
                      item-value="value"
                      
                      :rules="[(v) => !!v || 'Priority is required']"></v-select>
                      </v-flex>
                      <v-flex xs12>
                      <v-select v-model="editSla.status" required label="Status" :items="status"
                      item-text="text"
                      item-value="value"   
                      :rules="[(v) => !!v || 'Priority is required']"></v-select>
                      </v-flex>
                     <v-flex xs12>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="editSla.duration"
              label="Time"            
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="menu"
            v-model="editSla.duration"
            full-width
            @click:minute="$refs.menu.save(editSla.duration)"
          ></v-time-picker>
        </v-menu>
      </v-flex>
     
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeEditSla()">Close</v-btn>
              <v-btn color="primary" @click="saveEditSla()">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-navigation-drawer
        fixed
        disable-resize-watcher
        right
        v-model="page.isFilterOpen"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat color="white" @click="page.isFilterOpen = false">
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div
          class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
        >
          <v-container class="sidenavContainer">
            <div class="autocompleteDiv">
              <!-- <v-select
    :items="newArray"
    v-model="filter.payload.name"
    label="name"
    small
    ></v-select> -->
  
              <v-autocomplete
                v-model="filter.payload.name"
                :items="filtervalue"
                :loading="filter.isLoading"
                :search-input.sync="filter.search"
                label="Name"
                hide-no-data
                hide-selected
              ></v-autocomplete
              >
              <v-flex xs12>
                      <v-select v-model="filter.payload.priority" required label="Priority" :items="priority"
                      item-text="text"
                      item-value="value"
                      
                ></v-select>
                      </v-flex>
                      <v-flex xs12>
                      <v-select v-model="filter.payload.status" required label="Status" :items="status"
                      item-text="text"
                      item-value="value"></v-select>
                        
                    
                      </v-flex>
            </div>
          </v-container>
          <v-container row class="sidenavFooter">
            <v-btn flat @click="Reset()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
   <script>
  import loader from "@/components/Widgets/loader";
  //import api from "@/utils/axios-util";
  import { mapGetters } from "vuex";
  export default {
    components: {
      loader,
    },
  
    data() {
      return {
        valid: true,
        styleObject: {
          height: "80px",
          "margin-top": "-8px",
        },
          menu2: false,
          modal2: false,
           menu: false,
          modal: false,
        page: {
          title: "SLA",
          icon: "fa-file-alt fa-2x",
          loading: false,
          isAddSla: false,
          isFilterOpen: false,
          playingRecord: false,
          breadcrumbs: [],
          exportOptions: [],
          options: { filter: true, refresh: true },
          addnew: [],
          isEditSla: false,
          temp: { key: "addsla", label: "Add" },
          candelete: false,
          canedit: false,
          demo: false,
          sample: false,
          checkurl: false,
          checktext: false,
          deletesla_name: "",
          timeModal:false
        },
  
        pagecount: 0,
        headers: [
          {
            text: "Name",
            align: "left",
            class: "p0",
            sortable: false,
            value: "name",
          },
          {
            text: "Description",
            align: "left",
            class: "p0",
            sortable: false,
            value: "description",
          },
          {
            text: "Priority",
            align: "left",
            class: "p0",
            sortable: false,
            value: "priority",
          },
           {
            text: "Duration",
            align: "left",
            class: "p0",
            sortable: false,
            value: "duration",
          },
           {
            text: "Status",
            align: "left",
            class: "p0",
            sortable: false,
            value: "status",
          },
          {
            text: "Action",
            align: "center",
            sortable: false,
            value: "btn",
            width: "20%",
          },
        ],
        URLrules: [(v) => /^(http|https):/.test(v) || "URL must be valid"],
  
        sladata: [],
        //pagecount: 0,
        pagination: {
          rowsPerPage: 10,
        },
        snackbar: {
          show: false,
          timeout: 6000,
          text: "",
        },
        DeleteRowdata: false,
        showRowData: false,
        DataRow: false,
        tableLoading: false,
        crmdomain: "",
        filtervalue: [],
        newArray: [],
        filter: {
          payload: {
            name: "",
  priority:"",
  status:""
  
          },
          isLoading: false,
          search: "",
        },
  
        isProcessing: false,
        call_status: [],
        editSla: {
          id: "",
          name: "",
            description: "",
            priority: "",
            duration: "",
            status: "",
        },
        addSla: {
          payload: {
            name: "",
            description: "",
            priority: "",
            duration: "",
            status: "",
          },
        },
  
        priority: [{text:"Low",value:"low"},{text:"Medium",value:"medium"},{text:"High",value:"high"}],
        status: [{text:"Create",value:"create"},{text:"Update",value:"update"}],
      };
    },
    methods: {
      refreshThisPage: function () {
        var scope = this;
        scope.page.loading = true;
        this.pagination.page = 1;
  
        scope.getDataFromApi();
      },
      checkbox: function (id) {
        if (id == "URL") {
          let scope = this;
          scope.addSla.payload.type = id;
          scope.page.demo = true;
          scope.page.sample = false;
        } else {
          let scope = this;
          scope.addSla.payload.type = id;
          scope.page.sample = true;
          scope.page.demo = false;
        }
      },
      filterClick: function () {
        let scope = this;
        scope.filter.payload.name = "";
            scope.filter.payload.priority = "";
            scope.filter.payload.status = "";
        scope.page.isFilterOpen = true;
      },
      filterFieldOptions() {
        let scope = this;
  
        let data = { model: "sla", column: ["name"] };
  
        scope.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            scope.filtervalue = datas[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      Reset() {
        var scope = this;
        scope.filter.payload.name = "";
        this.getDataFromApi();
      },
      getDataFromApi() {
        var scope = this;
        scope.tableLoading = true;
  
        this.page.isFilterOpen = false;
  
        let take = this.pagination.rowsPerPage;
        let skip;
        if (this.pagination.page === 1) {
          skip = 0;
        } else {
          skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
        }
        /*let query = {
    skip: skip,
    take: take,
    };*/
        scope.filter.payload["skip"] = skip;
        scope.filter.payload["take"] = take;
        this.skip = skip;
        this.$store
          .dispatch("GetSla", scope.filter.payload)
          .then((response) => {
            this.pagecount = response.count;
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;         
            scope.sladata = datas;
            scope.filter.payload.name = "";
            scope.filter.payload.priority = "";
            scope.filter.payload.status = "";
          })
          .catch(function (error) {
            scope.page.isaddSla = false;
            scope.showSnackBar("error" + error);
            this.page.iseditSla = false;
          });
      },
      AddNewSla: function () {
       
        //var scope = this;
        this.page.isAddSla = true;
      },
      closeEditSla: function () {
        this.page.isEditSla = false;
      },
      closeaddSla: function () {
        this.page.isAddSla = false;
        this.$refs.Sla.reset();
      },
      saveEditSla() {
        if (this.$refs.editSla.validate()) {
          var scope = this;
          scope.$store
            .dispatch("updateSla", scope.editSla)
            .then(() => {
              scope.page.isAddSla = false;
              scope.showSnackBar("Updated Successfully");
              this.getDataFromApi();
              this.page.isEditSla = false;
            })
            .catch(function (error) {
              scope.page.isAddSla = false;
              scope.showSnackBar("error" + error);
              this.page.isEditSla = false;
            });
        }
      },
      saveaddSla() {
        if (this.$refs.Sla.validate()) {
          var scope = this;
          scope.$store
            .dispatch("insertSla", scope.addSla.payload)
            .then(() => {
              scope.page.isAddSla = false;
              this.$refs.Sla.reset();
              this.refreshThisPage();
              scope.showSnackBar("Created Successfully");
            })
            .catch(function (error) {
              let check_status = error.toString();
              if (check_status.includes("409")) {
                scope.showSnackBar("Error:name already exist");
              }
              scope.page.isAddSla = false;
              //scope.showSnackBar("error" + error);
            });
        }
      },
      showSnackBar(message) {
        let scope = this;
        scope.snackbar.text = message;
        scope.snackbar.show = true;
        setTimeout(() => {
          scope.snackbar.text = "";
        }, scope.snackbar.timeout);
      },
      deleteItem(id) {
        this.DeleteRowdata = true;
        this.delete_id = id;
      },
      editItem(item) {
        //var scope = this;
        this.page.isEditSla = true;
        this.editSla.id = item.id;
        this.editSla.name = item.name;
        this.editSla.status = item.status;
        this.editSla.description = item.description;
         this.editSla.priority = item.priority;
         this.editSla.duration = item.duration
      },
      savedelete() {
        let scope = this;
        scope.isProcessing = true;
        this.$store
          .dispatch("deleteSla", this.delete_id)
          .then((response) => {
            scope.isProcessing = false;
            if (response.data.message == "success") {
              scope.page.loading = true;
              scope.refreshThisPage();
              scope.showSnackBar("Deleted Successfully");

            } else {
              scope.showSnackBar("Error! Unable Delete list");
            }
          })
          .catch(() => {
            scope.isProcessing = false;
            scope.showSnackBar("Error! Unable Delete list");
          });
        this.closedelete();
      },
      closedelete() {
        this.DeleteRowdata = false;
      },
      closeShow() {
        this.showRowData = false;
      },
    },
    computed: {
      ...mapGetters(["CallFlow"]),
    },
    mounted() {
      let scope = this;
      this.page.loading = false;
      setTimeout(
        function () {
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "configurations:sla:create"
            ) !== -1
          ) {
            scope.page.addnew = [{ label: "Add" }];
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "configurations:sla:edit"
            ) !== -1
          ) {
            scope.page.canedit = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "configurations:sla:delete"
            ) !== -1
          ) {
            scope.page.candelete = true;
          }
        }.bind(scope),
        1
      );
      const project_config = JSON.parse(localStorage.projectConfig);
      this.crmdomain = project_config.projects[0].config[0].crm_domain;
      this.getDataFromApi();
      this.filterFieldOptions();
    },
    watch: {
      pagination: {
        handler() {
          if (this.pagination.page > 1 || this.skip >= 10) {
            this.getDataFromApi();
          }
        },
        deep: true,
      },
      "filter.search"(val) {
        if (this.page.isFilterOpen) {
          let scope = this;
  
          let data = { model: "sla", column: ["name"], search: val };
  
          scope.$store
            .dispatch("GetModuleNameList", data)
            .then((response) => {
              scope.tableLoading = false;
              scope.page.loading = false;
              var datas = response.data;
              scope.filtervalue = datas[0].name;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  };
  </script>
   <style>
  .file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
  }
  </style>