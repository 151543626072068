<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="openPerformanceReportFilter"
      @refreshPage="refreshPerformanceReportPage" :isMaterialIconType="true" @exportData="exportPerformanceReportReport"
      @toggleClick="toggleClick" :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="getheaders" :items="performance_report" class="elevation-1" :pagination.sync="pagination"
        :total-items="agentperformancetotal" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:items="props" v-if="page.viewing == '1'">
          <td class="checkEmpty p0">{{ props.item.calldate }}</td>
          <td class="checkEmpty p0">{{ props.item.agent }}</td>
          <td class="checkEmpty p0">{{ props.item.team }}</td>

          <td class="checkEmpty p0">
            {{
                props.item.answer +
                props.item.unanswer +
                props.item.inbound_unanswer
            }}
          </td>
          <td class="checkEmpty p0">{{ props.item.inbound }}</td>
          <td class="checkEmpty p0">{{ props.item.inbound_unanswer }}</td>
          <td class="checkEmpty p0">{{ props.item.outbound }}</td>
          <td class="checkEmpty p0">{{ props.item.unanswer }}</td>
          <!-- <td class="checkEmpty">{{ props.item.inbound }}</td>
          <td class="checkEmpty">{{ props.item.outbound }}</td>
          <td class="checkEmpty">{{ props.item.preview }}</td>
          <td class="checkEmpty">{{ props.item.dialer }}</td>-->
        </template>
        <template v-slot:items="props" v-else>
          <td class="checkEmpty p0">{{ props.item.calldate }}</td>
          <td class="checkEmpty p0">{{ props.item.agent }}</td>
          <td class="checkEmpty p0">{{ props.item.team }}</td>
          <td class="checkEmpty p0">{{ props.item.login }}</td>
          <td class="checkEmpty p0">{{ props.item.break }}</td>
          <!--  <td class="checkEmpty">{{ props.item.acw }}</td> -->
          <td class="checkEmpty p0">{{ props.item.talktime }}</td>
          <td class="checkEmpty p0">{{ props.item.idle }}</td>
          <td class="checkEmpty p0">{{ props.item.avg_talk }}</td>
          <td class="checkEmpty p0">{{ props.item.ring }}</td>
        </template>
      </v-data-table>
    </v-container>
    <!-- <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Performance Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div> -->
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen" class="filterNavigation">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <div class="row">
          <v-container class="pa-1">
            <v-menu v-model="page.menu.fromdate" :close-on-content-click="false" full-width max-width="290" small>
              <template v-slot:activator="{ on }">
                <v-text-field :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="appliedfilter.fromdate" @change="page.menu.fromdate = false">
              </v-date-picker>
            </v-menu>
          </v-container>
          
          <v-container class="pa-1">
            <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width max-width="290" small>
              <template v-slot:activator="{ on }">
                <v-text-field :value="appliedfilter.todate" label="To Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="appliedfilter.todate" @change="page.menu.todate = false">
              </v-date-picker>
            </v-menu>
          </v-container>
        </div>
        
        <v-autocomplete v-model="appliedfilter.agent" :items="filteragents" label="Agent" persistent-hint small multiple
          single-line :allow-overflow="false"></v-autocomplete>
      </v-container>
    
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFrom()">Filter</v-btn>
      </v-container>
  </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
// import pdfexport from "./Components/pdfexport";
import moment from "moment";
export default {
  components: {
    loader,
    // pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.page.loading = false;
    this.filteragents = JSON.parse(localStorage.getItem("group_users"));
    // this.$store.dispatch("reportuserlist").then(() => {
    //   // this.getDataFromApi();
    //   this.dateformate();
    // });
    this.dateformate();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:agentperformance:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
    this.getDataFromApi();
  },
  computed: {
    getheaders: function () {
      if (this.page.viewing == "1") {
        return this.headers1;
      } else {
        return this.headers2;
      }
    },
    ...mapGetters(["performance_report", "filters"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Agent Performance Report",
        icon: "network_check",
        loading: true,
        materialicon: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        options: { filter: true, refresh: true, toggleButton: true , tab:['Calls', 'Time']},
        exportOptions: [],

        viewing: "1",
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        detail: "performance",
        descending: "",
        export: "Performancereport",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      pdf_data: [],
      txt_data: [],
      filteragents: [],
      from_date: "",
      to_date: "",
      dialog: false,
      appliedfilter: {
        fromdate: "",
        todate: "",
        agent: "",
      },
      export_calls: "1",
      pagination: {
        rowsPerPage: 10,
      },
      headers1: [
        {
          text: "Date",
          align: "left",
          sortable: true,
          class: "p0",
          value: "calldate",
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "team",
        },
        {
          text: "Total Calls",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total",
        },
        {
          text: "Inbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "inboundanswer",
        },
        {
          text: "Inbound Unanswer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "inboundunanswer",
        },
        {
          text: "Outbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "outboundanswer",
        },
        {
          text: "Outbound Unanswer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "outboundunanswer",
        },
        // {
        //   text: "Answered",
        //   align: "left",
        //   sortable: false,
        //   class: "p0",
        //   value: "answered",
        // },
        // {
        //   text: "Unanswered",
        //   align: "left",
        //   sortable: false,
        //   class: "p0",
        //   value: "unanswered",
        // },
        // { text: "Inbound", align: "left", sortable: false, value: "inbound" },
        // { text: "Outbound", align: "left", sortable: false, value: "outbound" },
        // { text: "Preview", align: "left", sortable: false, value: "preview" },
        // { text: "Auto", align: "left", sortable: false, value: "dialer" }
      ],
      headers2: [
        {
          text: "Date",
          align: "left",
          sortable: true,
          class: "p0",
          value: "calldate",
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "team",
        },
        {
          text: "Staff Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "login",
        },
        {
          text: "Break Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "break",
        },
        // { text: "Handled Time", align: "left", sortable: false, value: "acw" },
        {
          text: "Talk Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "talktime",
        },
        // { text: "Hold Time", align: "left", sortable: false, value: "hold" },
        {
          text: "Idle Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "idle",
        },
        {
          text: "Avg Talk time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "avg",
        },
        {
          text: "Ring time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "ring",
        },
        // { text: "Avg Handle time", align: "left", sortable: false, value: "avg" }
      ],
      agentperformancereport: [],
      agentperformancetotal: 0,
      pdf: {
        header: [
          { text: "Date", align: "left", sortable: true, value: "calldate" },
          { text: "Agent", align: "left", sortable: false, value: "agent" },
          {
            text: "Total Calls",
            align: "left",
            sortable: false,
            value: "total",
          },
          {
            text: "Inbound Answer",
            align: "left",
            sortable: false,
            value: "inboundanswer",
          },
          {
            text: "Inbound Unanswer",
            align: "left",
            sortable: false,
            value: "inboundunanswer",
          },
          {
            text: "Outbound Answer",
            align: "left",
            sortable: false,
            value: "outboundanswer",
          },
          {
            text: "Outbound Unanswer",
            align: "left",
            sortable: false,
            value: "outboundunanswer",
          },
          // {
          //   text: "Answered",
          //   align: "left",
          //   sortable: false,
          //   value: "answered",
          // },
          // {
          //   text: "Unanswered",
          //   align: "left",
          //   sortable: false,
          //   value: "unanswered",
          // },
          {
            text: "Staff Time",
            align: "left",
            sortable: false,
            value: "login",
          },
          {
            text: "Break Time",
            align: "left",
            sortable: false,
            value: "break",
          },
          {
            text: "Talk Time",
            align: "left",
            sortable: false,
            value: "talktime",
          },
          { text: "Idle Time", align: "left", sortable: false, value: "idle" },
          {
            text: "Avg Talk time",
            align: "left",
            sortable: false,
            value: "avg",
          },
          { text: "Acw Time", align: "left", sortable: false, value: "idle" },
          {
            text: "Avg Acw Time",
            align: "left",
            sortable: false,
            value: "idle",
          },
          {
            text: "Handle Time",
            align: "left",
            sortable: false,
            value: "idle",
          },
          {
            text: "Avg Handle Time",
            align: "left",
            sortable: false,
            value: "idle",
          },

          {
            text: "Ring Time",
            align: "left",
            sortable: false,
            value: "ring",
          },
          {
            text: "First Login Time",
            align: "left",
            sortable: false,
            value: "idle",
          },
          {
            text: "Last Logout Time",
            align: "left",
            sortable: false,
            value: "idle",
          },
        ],
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if(this.pagination.page>1 || this.payload.skip>=10){
        this.getDataFromApi();
         }
      },
      deep: true,
    },
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      this.skip = 0;
      // this.getDataFromApi();
    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.skip = 0;
      // this.getDataFromApi();
    },
    "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
      this.skip = 0;
      // this.getDataFromApi();
    },
  },
  methods: {
    openPerformanceReportFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshPerformanceReportPage: function () {
      var scope = this;
      scope.dateformate();
      scope.pagination.page=1
      scope.appliedfilter.agent = "";
      scope.getDataFromApi();
      scope.refreshvalue();
      
    },
    exportPerformanceReportReport: function (option) {
      if (option == "PDF" || option == "Txt(Tab-Delimited)") {
        this.dialog = true;
      }
      ``;
      //var export_option = {export_formate : option , viewing : this.page.viewing};
      var export_option = { export_formate: option };
      this.export(export_option);
    },
    toggleClick: function (option) {
      this.page.viewing = option;
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
      this.appliedfilter.fromdate = this.from_date;
      this.appliedfilter.todate = this.to_date;
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFromApi() {
      var scope = this;
      var { descending } = scope.pagination;
      scope.payload.to_date = scope.to_date;
      scope.payload.from_date = scope.from_date;
      var { page, rowsPerPage } = scope.pagination;
      //scope.$store
      //  .dispatch("reportuserlist")
      //  .then((response) => {
      //    let dataa = response.toString(",");
      scope.payload.agent = JSON.parse(localStorage.getItem("group_users"));
      if (rowsPerPage == -1) {
        scope.payload.take = scope.total;
      } else if (
        rowsPerPage != "" &&
        rowsPerPage != undefined &&
        rowsPerPage != null
      ) {
        scope.payload.take = rowsPerPage;
      } else {
        scope.payload.take = 5;
      }
      if (page != "" && page != undefined && page != null) {
        scope.skip = page;
      } else {
        scope.skip = 1;
      }
      scope.payload.skip = (scope.skip - 1) * scope.payload.take;
      scope.payload.descending = descending;
      scope.$store
        .dispatch("Performancereport", scope.payload)
        .then((response) => {
          scope.page.loading = false;
          scope.total = response.count;
          if (scope.page_flag) {
            scope.pagination.page = 1;
            scope.page_flag = false;
          }
          scope.agentperformancetotal = scope.total;
          if (response.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      // });
      //})
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
      this.page.isFilterOpen=false
    },
    resetvalue() {
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format("YYYY-MM-DD");
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format("YYYY-MM-DD");
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.agent = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.page.isFilterOpen = false;
      this.getDataFromApi();
    },
    closeModel: function () {
      this.dialog = false;
    },

    export(option) {
      this.excel_data = [];
      this.txt_data = [];
      // this.pdf_data = [];
      var from_date = moment(this.payload.from_date).format("YYYY-MM-DD");
      var to_date = moment(this.payload.to_date).format("YYYY-MM-DD");
      var export_date = {
        from_date: from_date,
        to_date: to_date,
        misseddetail: "Performancereport",
      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          this.excel_data.push({
            Date: response[i].calldate,
            Agent: response[i].agent,
            agentName:response[i].agent_name,
            team:response[i].team,
            Total:
              response[i].answer +
              response[i].unanswer +
              response[i].inbound_unanswer,
            InboundAnswer: response[i].inbound,
            InboundUnanswer: response[i].inbound_unanswer,
            OutboundAnswer: response[i].outbound,
            OutboundUnanswer: response[i].unanswer,
            // Answered: response[i].answer,
            // Unanswered: response[i].unanswer,
            Staff_time: response[i].login,
            Break_time: response[i].break,
            Talk_Time: response[i].talktime,
            Idle_Time: response[i].idle,
            Avg_Talk: response[i].avg_talk,
            Ring_Time: response[i].ring,
            Acw_Time: response[i].acw,
            Avg_Acw_Time: response[i].avg_acw,
            Handle_Time: response[i].handle_time,
            Avg_Handle_Time: response[i].avg_handle_time,
            First_Login_Time: response[i].first_login_time,
            Last_Logout_Time: response[i].last_logout_time,
          });
          this.txt_data.push({
            Date: response[i].calldate,
            Agent: response[i].agent,
            agentName:response[i].agent_name,
            team:response[i].team,
            Total:
              response[i].answer +
              response[i].unanswer +
              response[i].inbound_unanswer,
            InboundAnswer: response[i].inbound,
            InboundUnanswer: response[i].inbound_unanswer,
            OutboundAnswer: response[i].outbound,
            OutboundUnanswer: response[i].unanswer,
            // Answered: response[i].answer,
            // Unanswered: response[i].unanswer,
            Staff_time: response[i].login,
            Break_time: response[i].break,
            Talk_Time: response[i].talktime,
            Idle_Time: response[i].idle,
            Avg_Talk: response[i].avg_talk,
            Ring_Time: response[i].ring,
            Acw_Time: response[i].acw,
            Avg_Acw_Time: response[i].avg_acw,
            Handle_Time: response[i].handle_time,
            Avg_Handle_Time: response[i].avg_handle_time,
            First_Login_Time: response[i].first_login_time,
            Last_Logout_Time: response[i].last_logout_time,
          });
          // this.pdf_data.push([
          //   response[i].calldate,
          //   response[i].agent,
          //   response[i].answer +
          //     response[i].unanswer +
          //     response[i].inbound_unanswer,
          //   // response[i].answer,
          //   // response[i].unanswer,
          //   response[i].inbound,
          //   response[i].inbound_unanswer,
          //   response[i].outbound,
          //   response[i].unanswer,
          //   response[i].login,
          //   response[i].break,
          //   response[i].talktime,
          //   response[i].idle,
          //   response[i].avg_talk,
          //   response[i].ring,
          //   response[i].acw,
          //   response[i].avg_acw,
          //   response[i].handle_time,
          //   response[i].avg_handle_time,
          //   response[i].first_login_time,
          //   response[i].last_logout_time
          // ]);
          // this.pdf.header = [];
          //this.pdf.header = this.pdf.headers;
          // else{
          //   this.excel_data.push({
          //     Date : response[i].calldate  ,
          //     Agent : response[i].agent,

          //     // Handled_Time : response[i].acw,

          //     // Avg_TT : response[i].avg,
          //   });
          //   this.pdf_data.push([
          //     response[i].calldate ,
          //     response[i].agent,
          //     response[i].login,
          //     response[i].break ,
          //     // response[i].acw,
          //     response[i].talktime,
          //     response[i].hold,
          //     response[i].idle,
          //     // response[i].avg,
          //   ]);
          //   this.pdf.header = [];
          //   this.pdf.header= this.headers2
          //   }
        }
        if (option.export_formate == "CSV") {
          let csvContent = '';

          //csvContent += "Date,Agent,Total,Answered,Unanswered,Inbound,Outbound,Preview,Auto\r\n";
          csvContent +=
            "Date,Agent,Agent Name,Team,Total Calls,Inbound Answer,Inbound Unanswer,Outbound Answer,Outbound Unanswer,Staff Time,Break Time,Talk Time,Idle Time,Avg Talk Time,Ring Time,Acw Time, Avg Acw Time,Handle Time,Avg Handle Time,First Login Time,Last Logout Time\r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
            var date11 = new Date();
          date11 = moment(date11).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "AgentPerformanceReport" + date11 + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
        if (option.export_formate == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = [
            "Date",
            "Agent","Agent Name","Team",
            "Total Calls",
            "Inbound Answer",
            "Inbound Unanswer",
            "Outbound Answer",
            "Outbound Unanswer",
            "Staff Time",
            "Break Time",
            "Talk Time",
            "Idle Time",
            "Avg Talk Time",
            "Ring Time",
            "Acw Time",
            "Avg Acw Time",
            "Handle Time",
            "Avg Handle Time",
            "First Login Time",
            "Last Logout Time",
          ];
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute(
            "download",
            "AgentPerformanceReport" + date2 + ".txt"
          );
          document.body.appendChild(link1);
          link1.click();
        }
      });
    },
    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.page_flag = true;
    },
  },

  beforeDestroy() {
    this.$store.commit("FILTER_AGENT", { agent: "" });
  },
};
</script>
