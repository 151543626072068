export default {
  livecalls: {
    response: [],
    userFilter:{number :'',agent:'',Queue:'',Campaign:'',Extension:'',Type:'',Status:''},
    page:{loading : false}
    
  },  
  filter:{
        agent:[],
        queue:[],
        extension:[],
        status:["DIALING","DISPO", "ANSWER", "CONNECT", "WAITING"],
        type:["INBOUND","MANUAL","AUTO"],
        user:[],
        campaign:["INBOUND","PREVIEW","AUTO","POWER"]

    },
};
