import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.TagManagement;
var getters = {
    // tagsdata: state => {
    //     return state.TagManagement.tagsdata.map((res,i)=>{
    //         res.id = i + 1;
    //          return res;
    //     })
    // },
    tagsdata: state => state.TagManagement.tagsdata,
    TagManagementFilter: state => state.TagManagement.filter,

}
const mutations = {
    [mutation_type.TagManagement]: (state, response) => {
        state.TagManagement.tagsdata = response.data.data
    },
    filter_name: (state, response) => {
        state.TagManagement.filter.name = response.name;
    },
    [mutation_type.CommitNothing]: () => {}
}
const actions = {
    TagManagement: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            let name = "";
            let apie ;
            if (state.TagManagement.filter.name) {
                name = `name=${state.TagManagement.filter.name}`
                 apie = `/tags/fetch?${name}`
            } else {
                apie =  `/tags/fetch?skip=${query.skip}&take=${query.take}`
            }
            api.get(apie).then(response => {
                var TagManagement_response = response;
                commit(mutation_type.TagManagement, TagManagement_response)
                resolve(response);
            }).catch(function(error) {
                reject(error);
            })

        })
    },
    Tagmanagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let name = "";
            let apie ;
            if (payload) {
                name = `name=${payload}`
                apie = `/tags/fetch?${name}`
            } else {
                name = ''
                apie = `/tags/fetch?skip=${payload.skip}&take=${payload.take}`
            }
            api.get(apie).then(response => {
                var TagManagement_response = response;
                commit(mutation_type.TagManagement, TagManagement_response)
                resolve(response);
            }).catch(function(error) {
                reject(error);
            })

        })
    },
    Addtags: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let data = {
                name: payload.name,
                description: payload.description,
                status: payload.status
            }
            api.post('/tags/create', data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    },
    editeditem: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            var data = {
                name:payload.name,
                description: payload.description,
                // status: payload.status.value
            }
            if(payload.status.value){
                data["status"]=payload.status.value
            }
            if(payload.status){
                data["status"]=payload.status
            }
            api.put(`/tags/update/${payload.id}`, data).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    },
    DeleteRows: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.delete(`/tags/delete/${payload.id}`).then(response => {
                commit(mutation_type.CommitNothing, {});
                resolve(response)
            }).catch(function(error) {
                reject(error)
            })
        })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}