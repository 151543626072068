<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="UserShiftData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.user }}</td>
          <td class="checkEmpty p0">{{ props.item.shift }}</td>
          <td class="checkEmpty p0">{{ props.item.category }}</td>
          <td class="checkEmpty p0">
            <template v-if="props.item.status === '0'">Active</template>
            <template v-else>Inactive</template>
          </td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete</v-icon></td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete User Shift</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>{{ deletemsg }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isAddUsershift" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Assign New Shift</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="addUserShift" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                  <v-select v-model="addUserShift.payload.user" required label="User" :items="users" item-text="name" item-value="id" multiple chips small-chips      
                    :menu-props="{ offsetY: true, closeOnContentClick: false }" :rules="[(v) => !!v || 'User selection is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="addUserShift.payload.shift " required label="Shift" :items="shift" :rules="[(v) => !!v || 'Shift selection is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="addUserShift.payload.category " required label="Category" :items="category" :rules="[(v) => !!v || 'Category selection is required']"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddUserShift()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveUserShift()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog persistent :value="page.isEditUsershift" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update User Shift</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="update" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                     <v-select v-model="editUserAssign.user " required label="User" :items="users" :rules="[(v) => !!v || 'User is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="editUserAssign.shift " required label="Shift" :items="shift" :rules="[(v) => !!v || 'Shift is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                     <v-select v-model="editUserAssign.category " required label="Category" :items="category" :rules="[(v) => !!v || 'Category is required']"></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="editUserAssign.status" required label="Status" :items="is_active"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
           <v-select
                v-model="filter.payload.user"
                label="User"
                :items="this.users"
              ></v-select>
             <v-select
                v-model="filter.payload.shift"
                label="Shift"
                :items="shift"
              ></v-select>
               <v-select
                v-model="filter.payload.category"
                label="Category"
                :items="category"
              ></v-select>
             <v-select
                v-model="filter.payload.status"
                label="Status"
                :items="status"
              ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "User Shift Assign",
        icon: "fas fa-tasks fa-2x",
        loading: false,
        isAddUsershift: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, autoCompletePC: true },
        addnew: [],
        isEditUsershift: false,
        temp: { key: "shiftassign", label: "Shift Assign" },
        candelete: false,
        timeModal: false,
        timeModal1: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "User",
          align: "left",
          class: "p0",
          sortable: false,
          value: "title",
        },
        {
          text: "Shift",
          align: "left",
          class: "p0",
          sortable: false,
          value: "sub_title",
        },
        {
          text: "Category",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
      
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          // width: "20%",
        },
      ],
      UserShiftData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
        page:1
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      newArray: [],
      users:[],
      category:[],
      shift:[],

      filter: {
        payload: {
          status:"",
          user:"",
          shift:"",
          category:""
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
      },
          status: ["Active", "Inactive"],

      isProcessing: false,
      call_status: [],
      editUserAssign: {
        id: "",
        user:"",
        shift: "",
        category: "",
        status: "",
      },
      addUserShift: {
        valid: false,
        payload: {
          user: [],
          shift: "",
          category:"",
          },
      },
      is_active: ["Active", "Inactive"],
      deletemsg:''
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    reset() {
      this.filter.payload.user="";
      this.filter.payload.shift="";
      this.filter.payload.category="";
      this.filter.payload.status="";
      },
   
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      scope.filter.payload["skip"] = skip;
      scope.filter.payload["take"] = take;
      scope.skip = skip;
      this.$store
        .dispatch("UserShiftFetch", scope.filter.payload)
        .then((response) => {
          this.pagecount = response.count;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          //  scope.newArray = datas.map((element) => element.title);
          scope.UserShiftData = datas;
          this.reset();
        })
        .catch(function (error) {
          scope.page.isAddUsershift = false;
          scope.showSnackBar("error" + error);
          this.page.isEditUsershift = false;
        });
    },
    fetchCategoryOptions(){
      var scope = this;
      let query={
        skip:0,
        take:0,
        is_active:''
      }
      scope.$store
        .dispatch("fetchCategoryData",query)
        .then((response) => {
          var data = response.data.data;
          this.category = data.map((element) => element.name);
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    fetchShiftOptions(){
      var scope = this;
      let query={
        skip:0,
        take:0
      }
      scope.$store
        .dispatch("fetchShift",query)
        .then((response) => {
          var data = response.data;
          scope.shift = data.map((element) => element.name);
        })
        .catch(function (error) {
          console.log(error);
        });

    },
     fetchUserOptions() {
      var scope = this;
      let query={
          user:['Lite-Agent']
      }
      scope.$store
        .dispatch("fetchUsers",query)
        .then((response) => {
          var data = response.data.data;
          for ( let i=0;i< data.length; i++){
            scope.users.push(data[i].user_email)
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    AddNewRule: function () {
      //var scope = this;
      this.page.isAddUsershift = true;
    },
    closeEditRules: function () {
      this.page.isEditUsershift = false;
    },
    closeAddUserShift: function () {
      this.page.isAddUsershift = false;
      this.$refs.addUserShift.reset();
    },
    saveEditRules() {
      var scope = this;
      if(this.editUserAssign.status == "Active"){
          this.editUserAssign.status = "0"
      }else{
          this.editUserAssign.status = "1"

      }
      scope.$store
        .dispatch("updateUserShift", scope.editUserAssign)
        .then(() => {
          scope.page.isAddUsershift = false;
          scope.showSnackBar("Updated Successfully");
          this.getDataFromApi();
          this.page.isEditUsershift = false;
        })
        .catch(function (error) {
          scope.page.isAddUsershift = false;
          scope.showSnackBar("error" + error);
          this.page.isEditUsershift = false;
        });
      this.getDataFromApi();
    },
    saveUserShift() {
      var scope = this;
      scope.$store
        .dispatch("UserShiftAssign", scope.addUserShift.payload)
        .then(() => {
          scope.page.isAddUsershift = false;

          scope.showSnackBar("Created Successfully");
          this.getDataFromApi();
          this.$refs.addUserShift.reset();
        })
        .catch(function (error) {
          scope.page.isAddUsershift = false;
          // if (error.response && error.response.status === 409) { 
          //   scope.showSnackBar("The meeting title already exists");
          // } 
          console.log(error);
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(data) {   
       let scope = this
      scope.delete_id = data.id;
      scope.deletemsg = `Are you sure to delete this user shift ?`
      this.DeleteRowdata = true;
    },
    editItem(item) {
      this.page.isEditUsershift = true;
      this.editUserAssign.id = item.id;
      this.editUserAssign.user = item.user;
      this.editUserAssign.shift = item.shift;
      this.editUserAssign.category = item.category;
      this.editUserAssign.status = item.status;
      

      if (item.status === "0") {
        this.editUserAssign.status = "Active";
      } else {
        this.editUserAssign.status = "Inactive";
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      scope.filtervalue = [];
      this.$store
        .dispatch("deleteUserShift", scope.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Deleted successfully ");
          } else {
            scope.showSnackBar("Error! Unable to delete user shift");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete ");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    // ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Assign Shift" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
    this.fetchCategoryOptions();
    this.fetchShiftOptions();
    this.fetchUserOptions();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.mr-4 {
  text-align: start;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>