import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.DidNumber;
var getters = {
    DidNumber: state => state.DidNumber.data,
}
const mutations = {
    [mutation_type.didNumber]: (state, response) => {
        state.didNumber.data = response.data.data;

    }
};
const actions = {
    DidNumber: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                console.log('this is working ')
                let skip ;
                let take;
                if(payload != undefined){

                    skip = payload.skip;
                    take = payload.take;
                }else{
                    skip = "";
                    take = "";
                }
                let filter=""
                if(payload!=undefined){
                if(payload.phone_number){
                     filter=`&did_number=${payload.phone_number}`
                }
            }
                api.get(`/didnumber/fetch?skip=${skip}&take=${take}`+filter).then((response) => {
                    var donotcall_response = response;
                    commit(mutation_type.didNumber, donotcall_response);
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    DeleteDid: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/did/delete/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateDid: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/didNumber/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertDid: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/did/create`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
};
export default {
    state,
    getters,
    mutations,
    actions
}