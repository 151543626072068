let Crypto = require("crypto");
import auth0Variables from './auth0-variables';
const auth0Config = auth0Variables();
let security_key = auth0Config.cryptosecuritykey;
let secret_iv = "smlt";
let method = 'AES-256-CBC'
let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)

function encrypt(text) {
    let encrypt = Crypto.createCipheriv(method, key, iv)
    let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
    return Buffer.from(convert).toString("base64")
}


function decrypt(code, method, key, iv) {
    let buff = Buffer.from(code, 'base64');
    code = buff.toString('utf-8');
    let decryptor = Crypto.createDecipheriv(method, key, iv)
    return decryptor.update(code, 'base64', 'utf8') + decryptor.final('utf8')

}


export function encoding(payload){ 
    
    if(typeof(payload) == "object"){
        if(Object.keys(payload).length > 0){
            for (const keys in payload) {
                    let element = payload[keys];
                    payload[keys] =  encrypt(JSON.stringify(element), method, key, iv)
                    // if(typeof(element) == "string"){
                    //     payload[keys]=  encrypt(JSON.stringify(element), method, key, iv)
                    // }else if(typeof(element) == 'number'){
                    //     payload[keys]=  encrypt(JSON.stringify(element), method, key, iv)
                    // }else if(typeof(element) == 'object'){
                    //     if(!Array.isArray(payload[keys]) && Object.keys(payload[keys]).length>0){
                    //         payload[keys]=  encrypt(JSON.stringify(element), method, key, iv)
                    //     }else if(Array.isArray(payload[keys])){
                    //         payload[keys]=  encrypt(JSON.stringify(element), method, key, iv)
                    //     }else{
                    //         payload[keys]=  encrypt(JSON.stringify(element), method, key, iv)
                    //     }
                    // }
            }
        } else if (Array.isArray(payload)) {
            payload= encrypt(JSON.stringify(payload), method, key, iv)
        }
    }else if (typeof(payload) == "string") {
        payload= encrypt(JSON.stringify(payload), method, key, iv)
    }else if (typeof(payload) == "number") {
        payload= encrypt(JSON.stringify(payload), method, key, iv)
    }  


    return payload
}


export function decoding(payload) {
    if (typeof (payload) == "object") {
        if (Array.isArray(payload) == false) {
            for (const keys in payload) {
                let element = payload[keys];
                let decrypt_data = decrypt(element, method, key, iv)
                payload[keys] = JSON.parse(decrypt_data)
                // console.log(payload)
                // if (typeof (element) == "string") {
                //     payload[keys] = decrypt(, method, key, iv)
                // } else if (typeof (element) == 'number') {
                //     payload[keys] = decrypt(, method, key, iv)
                // } else if (Object.keys(payload[keys]).length > 0) {
                //     payload[keys] = decrypt(, method, key, iv)
                // }
                // console.log(decrypt(element, method, key, iv))
            }
        } else if (Array.isArray(payload)) {
            let decrypt_data = decrypt(payload, method, key, iv)
            payload = JSON.parse(decrypt_data)
        }
    } else if (typeof (payload) == "string") {
        let decrypt_data = decrypt(payload, method, key, iv)
        payload = JSON.parse(decrypt_data)
    } 

    return payload
}
