
export default {
    updateJSON: ({ commit }, payload) => {
      commit("updateJSON", payload);
    },
    setWebsocketStatus({ commit }, status) {
      commit('SET_WEBSOCKET_STATUS', status);
    },
    setSession({ commit }, data) {
      commit('SET_SESSION', data);
    },
    onCall({ commit }) {
      console.log('new is on_call is wokring')
       commit('ON_CALL');
    },
    callringtone({ commit }, socket) {
      console.log(socket,'new is socket')
      commit('CALL_RINGTONE', socket);
    },
    initiateEvent({ commit }, data) {
      return new Promise((resolve) => {
        commit('INITIATE_EVENT', data);
        resolve(data);
      });
    },
  };
  