<template>
  <div id="app">
    <!-- <div v-if="loading" class="loader"></div> -->
    <div v-if="loading" :class="lds_ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="title_head">
      <div class="font_head">{{ name }}</div>
      <button class="filtericon" v-if="toolbarTrigger">

        <i class="fa fa-bars" @click="dialog = !dialog" aria-hidden="true"
          style="font-size:17px;height: 17px; margin-top: 10px;margin-left: 0px;color:rgb(110	129	146);"></i>
      </button>

    </div>
    <TableView :headers="columns" :rows="items" :sort="sort1">
      <!-- <template v-slot:items="{ row}">
      
        <td v-for="(column, index) in columns" :key="index">{{ row.column }}</td>
        <td>{{ row.last_name }}</td>
        <td>{{ row.email }}</td>
        <td>{{ row.age }}</td>
        <td>{{ row.country }}</td>
        <td>{{ row.category }}</td>
        <td>{{ row.last_update }}</td>
      </template>

      <template v-slot:no-data>
        <span>No data</span>
      </template> -->
    </TableView>
    <div>
      <div v-on:click="csvexport" class="dialog" v-if="dialog">
        <div class="dialog_txt"><span class="txt_area">Download CSV</span></div>
      </div>
    </div>
  </div>
</template>
<script>
//import TableView from '../tables/TableView.vue'   // existed code

//import TableView from './TableView.vue'
import TableView from '../../preview/tables/TableView.vue'


export default {
  name: 'App',
  components: {
    TableView,

  },
  props: {
    alldata: {
      type: Array
    },
    timer: {
      type: Number,
      default: 10000,
    },
    id: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      default: "",
      validator: function (val) {
        return typeof val === "string";
      },
    },
    toolbarTrigger: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lds_ellipsis: "lds-ellipsis",
      dialog: false,
      dataPoints: null,
      polling: null,
      loading: false,
      watch_trigger: null,
      columns: null,
      items: null,
      sort1: {
        field: 'first_name',
        order: 'asc'
      },
      sort2: {
        field: 'email',
        order: 'asc'
      },
      pagination: {
        itemsPerPage: 7,
        align: 'center',
        visualStyle: 'select'
      }
    }
  },
  methods: {
    preview() {
      this.table = false;
    },
    reset_triggers() {
      let obj = {
        filter_metrics: this.alldata[0].filter_metrics,
        filter_type: 2,

      }
      this.filter_fun(obj)
      localStorage.setItem("apply_pass", JSON.stringify({}));
      this.$emit("reset");
    },
    filter_fun(obj) {
      this.$store.dispatch("rect/temp_filter_api", obj)
        .then(() => {
          this.updatefun();

        })
    },
    fillData() {
      const scope = this;

      scope.polling = setInterval(() => {
          console.log("inside set timeout inside table vue")
        const payload = {
          id: scope.id,
          skip: 0,
          take: 20,
        };

        scope.loading = true;

        scope.$store
          .dispatch("rect/renderWidgetData", payload).then((result) => {
            console.log("iinside settimeout success")
            this.dataPoints = JSON.parse(JSON.stringify(result));
            const keys = Object.keys(this.dataPoints[0]);
            const columns = keys.map((element) => ({
              label: element.replace("_", " ").toUpperCase(),
              field: element,
              sortable: true,
            }));

            this.columns = columns;
            this.items = this.dataPoints;
            this.loading = false;
          })
          .catch((err) => {
            console.log("inside set timeout error")
            console.error("Error fetching data:", err);
            this.loading = false;
          });

      }, scope.timer);
    },
    updatefun() {
      const scope = this;
      const payload = {
        id: scope.id,
        skip: 0,
        take: 20,
      };
      scope.$store
        .dispatch("rect/renderWidgetData", payload)
        .then((result) => {
          console.log("inside table.vue file update fun")
          scope.dataPoints = JSON.parse(JSON.stringify(result));
          const keys = Object.keys(scope.dataPoints[0]);
          // const value = Object.values(scope.dataPoints[0]);

          let columns = []
          for (let i = 0; i < keys.length; i++) {
            const element = keys[i];
            let obj = {
              label: element.replace("_", " ").toUpperCase(),
              field: element,
              sortable: true,
              //  type: "String"
            }
            columns.push(obj)
          }
          scope.columns = columns
          scope.items = scope.dataPoints
          scope.loading = false;
        })
        .catch((err) => {
          console.log("Error(Bar) ==>", err);
        });
    },
    csvexport() {
      let scope = this
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += Object.keys(scope.dataPoints[0]).join(",") + "\r\n";
      scope.dataPoints.forEach(function (rows) {
        let data = Object.values(rows);
        let data1 = []
        data.forEach((elm) => {

          if (typeof elm === 'string') {

            data1.push(elm.replace(/'|"|#|\n/g, " "))
          }
          else {
            data1.push(elm)
          }
        })
        let row = data1.join(",");

        csvContent += row + "\r\n";

      });


      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.name + ".csv");
      document.body.appendChild(link);
      link.click();
      this.dialog = false
    },
    triggers() {
      this.loading = true;
      this.$emit("popup");
    },
  },
  mounted() {

    if (localStorage.apply_pass) {
      let obj = JSON.parse(localStorage.apply_pass);
      if (obj.filter_type >= 0 && obj.filter_metrics.length > 0) {
        this.watch_trigger = obj.filter_type;
      }

    }
  },
  watch: {
    watch_trigger() {

      let obj = JSON.parse(localStorage.apply_pass);
      if (obj.filter_metrics[0].widget_id === this.id) {
        this.filter_fun(obj)
      }
    }
  },

  created() {
    const scope = this;
    this.table = true;
    const payload = {
      id: scope.id,
      skip: 0,
      take: 5,
    };
    scope.loading = true;

    console.log("this is payload and id value from table vue", payload)

    scope.$store
      .dispatch("rect/renderWidgetData", payload)
      .then((result) => {
        console.log(" preview table vue inside create function")
        scope.dataPoints = JSON.parse(JSON.stringify(result));
        const keys = Object.keys(scope.dataPoints[0]);

        console.log(`this is keys,${keys}, and and data point is ${scope.dataPoints}`)

        // const value = Object.values(scope.dataPoints[0]);

        let columns = []
        for (let i = 0; i < keys.length; i++) {
          const element = keys[i];
          let obj = {
            label: element.replace("_", " ").toUpperCase(),
            field: element,
            sortable: true,
            //  type: "String"
          }
          columns.push(obj)
        }
        scope.columns = columns
        scope.items = scope.dataPoints
        scope.loading = false;
      })
      .catch((err) => {
        console.log("inside create function table vue error ")
        console.log("Error(Bar) ==>", err);
        scope.loading = false;
      });
     scope.fillData();
  },


}
</script>
<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);

body,
html {
  height: 100%;
}

.filtericon {
  display: flex;
  justify-content: space-around;
  width: 50px;

}

.font_head {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 900;
}

.title_head {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: 0px;
  margin-top: 0px;

}

#app {
  font-family: 'Roboto Mono', Helvetica, Arial, sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 90%;
  margin: 0 auto;
  min-height: 350px;
}

.dialog {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  top: 30px;
  right: 10px;


}

.dialog_txt {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 6;
}

.dialog_txt:hover {

  background-color: #eee;

}

.lds-ellipsis {
  position: fixed;
  left: 55%;
  bottom: 55%;
  right: 55%;
  height: 80px;
  top: 55%;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2196f3;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}</style>
