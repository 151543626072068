<template>
  <v-app :dark="colorScheme.dark" :class="colorScheme.scheme">
    <!-- <router-view name="header"/>
    <router-view name="sidebar"/>    
    <v-content>
      <router-view/>
    </v-content> -->
    <router-view @generateJSON="generateJSON" name="header" />
    <router-view @changeFlow="changeFlow" name="sidebar" />
    <v-content  style="display: flex; width: 100%; height: 100%;">
      <router-view :flows="flows" :flow="flow" :showJSON="showJSON" />
    </v-content>
    <!-- <router-view name="footer"/> -->
    <app-settings
      :drawer="subDrawer"
      @toggleSubDrawer="handleSubdrawer"
    ></app-settings>
    <!-- Toggle settings button -->
    <!--  <v-btn fixed class="settings-btn" @click.stop="subDrawer = !subDrawer">
      <v-icon light>settings</v-icon>
    </v-btn> -->
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import AppSettings from "@/components/AppSettings";
export default {
  data() {
    return {
      flows: [{ name: "Untitled 1", edit: false, id: 0 }],
      flow: "Untitled1",
      subDrawer: false,
      showJSON: false
    };
  },
  computed: {
    ...mapGetters({
      colorScheme: "colorScheme"
    })
  },
  components: {
    AppSettings
  },
  methods: {
    changeFlow([flows, flow]) {
      this.flows = flows;
      this.flow = flow;
    },
    handleSubdrawer(value) {
      this.subDrawer = value;
    },
    generateJSON() {
      this.showJSON = !this.showJSON;
    }
  },
  mounted() {}
};
</script>
<style>

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
