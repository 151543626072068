<template>
  <div class="Checkboxmain">
    
    <div class="RadioButtonfield">
      <div v-for="item in currentField.values" :key="JSON.stringify(item)">
      <v-checkbox :class="[currentField.required?required_class:currentField.clearable?read_only:others]" :readonly="currentField.clearable" color="primary" :disabled="currentField.disabled" v-model="currentField.value.value"  :label="`${currentField.label}`" :rules="required_rule"></v-checkbox>
    </div>
              
    </div>
  </div>
</template>

<script>
import fetchData from "@/api/fetch-data";
export default {
  name: "Checkbox",
  props: ["currentField"],
  data() {
    return {
      checkList: false,
      required_class:"required",
            others:"others",
             read_only:"readonly",
                required_rule:[]
    };
  },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
  methods : {
  
  },
  watch:{
    "currentField.link_type":{
      handler: function () {
        if(this.currentField.link_type == "Parent" && this.currentField.model !==""){
          console.log("currentField")
          this.currentField.values.forEach(res => {
            res.name = this.currentField.model;
            res.value = this.currentField.model;
          })
        }
      }
    }
  },
   destroyed() {
        this.currentField.value.value = this.currentField.value.value.toString();
    },
  mounted() {

 if(this.currentField.required == true){
            this.required_rule = [ v => !!v || `${this.currentField.label} is required`]
        }

    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl;
      let valueField = this.currentField.valueField;
      let labelField = this.currentField.labelField;
      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField);
      promise.then((data) => {
        this.currentField.options = data;
      });
    }

    this.currentField.values.forEach(res=>{
      res.name = this.currentField.model;
      res.value = this.currentField.model;
    })
    
    setTimeout(() => {
      if (this.currentField.model !== "") {
        const containers = document.querySelectorAll(
          `#${this.currentField.model}`
        );
        for (let container of containers) {
          if (container.classList.value === "el-checkbox is-checked") {
            this.checkList.push(
              container.querySelector("span.el-checkbox__label").outerText
            );
          }
        }
      }
    }, 500);
  },
};
</script>

<style>
.el-checkbox-group {
  font-size: 14px;
}

.el-form-checkbox {
  padding: 1px !important;
  margin-bottom: 0;
}

.Checkboxmain {
  display: flex;
  flex-direction: column;
  min-height: 85px;
  padding-left: 30px;

}

.RadioButtonfield {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
