<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewTimezone" :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="ShiftData" class="elevation-1 fixed-layout" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.split_count }}</td>
          <td class="checkEmpty p0">{{ props.item.from_time }}</td>
          <td class="checkEmpty p0">{{ props.item.to_time }}</td>
          <td class="checkEmpty p0">
            <template v-if="props.item.is_default == 0">Active</template>
            <template v-else>Inactive</template>
          </td>

          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete"
              @click="deleteItem(props.item.id)">delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Shift </span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this shift ?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isAddShiftModule" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Create Shift</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
        <v-form ref="ShiftModule" v-model="valid">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="addShiftModule.payload.name" required label="Shift name" type="text"
                  :rules="[(v) => !!v || ' Shift name is required']"></v-text-field>
              </v-flex>
              <!-- changed -->
              <v-flex xs12>
                <v-text-field v-model="addShiftModule.payload.split_count" required label="Split Count" type="number"
                  :rules="[(v) => !!v || 'Split count is required']"></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-menu ref="menu1" v-model="addShiftModule.menu2" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="addShiftModule.payload.from_time" transition="scale-transition" offset-y
                  max-width="250px" min-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="addShiftModule.payload.from_time" label="From Time" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="addShiftModule.menu2" v-model="addShiftModule.payload.from_time" format="24hr"
                    full-width @click:minute="$refs.menu1.save(addShiftModule.payload.from_time)"
                    color="primary"></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-menu ref="menu" v-model="addShiftModule.menu1" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="addShiftModule.payload.to_time" transition="scale-transition" offset-y
                  max-width="250px" min-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="addShiftModule.payload.to_time" label="To Time" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="addShiftModule.menu1" v-model="addShiftModule.payload.to_time" format="24hr"
                    full-width @click:minute="
                      $refs.menu.save(addShiftModule.payload.to_time)
                      " color="primary"></v-time-picker>
                </v-menu>
              </v-flex>
              <!-- <v-flex xs12>
                <v-select v-model="addShiftModule.payload.status" required label="Status" :items="status" item-text="name"
                  item-value="value"></v-select>
              </v-flex> -->
            </v-layout> 
          </v-container>
        </v-form>
     </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeAddShiftModule()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" flat @click="saveAddShiftModule()">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog persistent :value="page.isEditShiftModule" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Shift</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="update" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                <v-text-field v-model="editShiftModule.name" required label="Name" type="text"
                  :rules="[(v) => !!v || 'Name is required']"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="editShiftModule.split_count" required label="Split Count" type="number"
                  :rules="[(v) => !!v || 'split value is required']"></v-text-field>
              </v-flex>
              <!-- <v-flex xs12>
                <label class="sheduleddate">From Time</label>
                <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                  v-model="editShiftModule.from_time" label="From Time">
                </datetime>
              </v-flex>

              <v-flex xs12>
                <label class="sheduleddate">To Time</label>
                <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                  v-model="editShiftModule.to_time" label="To Time">
                </datetime>
              </v-flex> -->

              <v-flex xs12>
                <v-menu ref="menu4" v-model="addShiftModule.payload.menu3" :close-on-content-click="false"
                  :nudge-right="40" :return-value.sync="editShiftModule.from_time" transition="scale-transition" offset-y
                  max-width="250px" min-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editShiftModule.from_time" label="From Time" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="addShiftModule.payload.menu3" format="24h" v-model="editShiftModule.from_time"
                    full-width color="primary"
                    @click:minute="$refs.menu4.save(editShiftModule.from_time)"></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-menu ref="menu3" v-model="addShiftModule.payload.menu4" :close-on-content-click="false"
                  :nudge-right="40" :return-value.sync="editShiftModule.to_time" transition="scale-transition" offset-y
                  max-width="250px" min-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editShiftModule.to_time" label="To Time" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="addShiftModule.payload.menu4" color="primary" format="24h"
                    v-model="editShiftModule.to_time" full-width
                    @click:minute="$refs.menu3.save(editShiftModule.to_time)"></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>{{editShiftModule.status}}
                <v-select v-model="editShiftModule.status" required label="Status" :items="status" 
                :rules="[(v) => !!v || 'Name is required']"></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditShiftModule()">Close</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="saveEditShiftModule()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="filter.query.name" :items="filtervalue" :loading="filter.isLoading"
              :search-input.sync="filter.search" label="Name" hide-no-data hide-selected></v-autocomplete>
          </div>
          <div>
            <v-select v-model="filter.query.status" required label="Status" :items="status" item-text="name"
              item-value="value"></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
//import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Shift",
        icon: "fa-clock fa-2x",
        loading: false,
        isAddShiftModule: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditShiftModule: false,
        // temp: { key: "addShiftModule", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },

      pagecount: 0,
      headers: [
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: true,
          value: "Name",
        },
        {
          text: "Split count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "default value",
        },

        {
          text: "From time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "from_time",
        },
        {
          text: "To time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "to_time",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      ShiftData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,

      filtervalue: [],
      filter: {
        query: {
          name: "",
          status: "",
        },
        isLoading: false,
        search: "",
      },

      // pagination: {
      //   page: 0,
      //   rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editShiftModule: {
        id: "",
        name: "",
        split_count: "",
        from_time: "",
        to_time: "",
        is_default: "",
        status: "",
      },
      valid: false,
      // valid1: false,
      addShiftModule: {
        valid: false,
        menu2: false,
        menu1: false,
        menu3: false,
        menu4: false,
        payload: {
          name: "",
          split_count: "",
          from_time: "",
          to_time: "",
          is_default: 0
        },
      },
      status: ["Active","Inactive"],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.query.name = "";
      scope.filter.query.status = "";
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }

      scope.filter.query["skip"] = skip;
      scope.filter.query["take"] = take;
      scope.skip = skip;

      this.$store
        .dispatch("GetShift", this.filter.query)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas;
          if (response) {
            datas = response.data;
            this.pagecount = response.count;
          } else {
            datas = [];
            this.pagecount = 0;
          }

          for (var i = 0; i < datas.length; i++) {
            this.filtervalue.push(datas[i].name);
          }
          scope.ShiftData = datas;
        })
        .catch(function (error) {
          scope.page.isAddShiftModule = false;
          scope.showSnackBar("error" + error);
          this.page.isEditShiftModule = false;
        });
    },
    AddNewTimezone: function () {
      let scope = this;
      scope.addShiftModule.payload.is_default = 0;
      scope.addShiftModule.payload.name = "";
      scope.addShiftModule.payload.split_count = "";
      scope.addShiftModule.payload.from_time = "";
      scope.addShiftModule.payload.to_time = "";
      this.page.isAddShiftModule = true;
    },
    closeEditShiftModule: function () {
      this.page.isEditShiftModule = false;
    },
    closeAddShiftModule: function () {
            let scope = this;

      this.page.isAddShiftModule = false;
      scope.addShiftModule.payload.is_default = "";
      scope.addShiftModule.payload.name = "";
      scope.addShiftModule.payload.split_count = "";
      scope.addShiftModule.payload.from_time = "";
      scope.addShiftModule.payload.to_time = "";

    },
    saveEditShiftModule() {
       if(this.editShiftModule.status == "Active"){
          this.editShiftModule.status = "0"
      }else{
          this.editShiftModule.status = "1"

      }
      const updateData = {
        id: this.editShiftModule.id,
        name: this.editShiftModule.name,
        split_count: parseInt(this.editShiftModule.split_count),
        from_time: this.editShiftModule.from_time,
        to_time: this.editShiftModule.to_time,
        is_default: parseInt(this.editShiftModule.status)
      };

      var scope = this;
      scope.$store
        .dispatch("updateShift", updateData)
        .then(() => {
          scope.page.isAddShiftModule = false;
          scope.showSnackBar("Shift Updated Successfully");
          this.refreshThisPage();
          this.$refs.editShiftModule.reset();
          this.editShiftModule.
          this.page.isEditShiftModule = false;
        })
        .catch(function (error) {
          scope.page.isAddShiftModule = false;
          scope.showSnackBar("error" + error);
          this.page.isEditShiftModule = false;
        });
      this.getDataFromApi();
    },
    saveAddShiftModule() {
      var scope = this;
      const insertDatas = {
        name: scope.addShiftModule.payload.name,
        split_count: parseInt(scope.addShiftModule.payload.split_count),
        from_time: scope.addShiftModule.payload.from_time,
        to_time: scope.addShiftModule.payload.to_time,
        is_default: scope.addShiftModule.payload.is_default
      };

      scope.$store
        .dispatch("insertShift", insertDatas)
        .then(() => {
          scope.page.isAddShiftModule = false;
          scope.showSnackBar("Created Successfully");

          this.refreshThisPage();
          this.$refs.ShiftModule.reset();
          scope.addShiftModule.payload.name = "";
          scope.addShiftModule.payload.split_count = "";
          scope.addShiftModule.payload.from_time = "";
          scope.addShiftModule.payload.to_time = "";
          scope.addShiftModule.payload.status = "";
          
        })
        .catch(function (error) {
          scope.page.isAddShiftModule = false;
          scope.showSnackBar("error" + error);
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditShiftModule = true;
      this.editShiftModule.id = item.id;
      this.editShiftModule.name = item.name;
      this.editShiftModule.split_count = item.split_count;
      this.editShiftModule.from_time = item.from_time;
      this.editShiftModule.to_time = item.to_time;
      this.editShiftModule.status = item.is_default;
      if (item.is_default == 0){
      this.editShiftModule.status = "Active";
      }
      else{
      this.editShiftModule.status = "Inactive";
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      scope.filtervalue = [];
      this.$store
        .dispatch("deleteShift", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == "200") {
            this.refreshThisPage();
            scope.showSnackBar("shift Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    reset() {
      this.filter.query.name = "";
      this.filter.query.status = "";
      this.getDataFromApi();
    },
  },
  // computed: {
  //   ...mapGetters(["CallFlow"]),
  // },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },

    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;
        let data = { model: "ShiftModel", column: ["name"] };
        data["search"] = val;

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].name;
          })
          .catch(function (error) {
            scope.page.isAddDisposition = false;
            scope.showSnackBar("error" + error);
            this.page.isEditDisposition = false;
          });
      }
    },
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Shift" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );

    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
