<template>
    <div>
        <div v-if="change_screen === false" class="button_flex">
            <div class="float">
                <i @click="isactiveFunction" class="fa fa-bars my-float" aria-hidden="true"></i>

            </div>
            <div @click="add" v-if="isactive" class="float float2">
                <i class="fa fa-plus my-float"></i>
            </div>
            <div @click="edit" v-if="isactive" class="float float1">
                <i class="fa fa-pencil my-float" aria-hidden="true"></i>
            </div>
        </div>
        <div v-if="change_screen" class="button_flex">
            <div @click="save" class="float">
                <i class="fa fa-floppy-o my-float" aria-hidden="true"></i>

            </div>
        </div>
    </div>
</template>
<script>


export default {
    data() {
        return {
            isactive: false,
            change_screen: false
        }
    },
    methods: {
        save() {
            this.$emit("save");  
            this.change_screen = false
            this.isactive= false
        },
        edit() {
            this.$emit("editWidgets");
            this.change_screen = true
        },
        add() {
            this.$emit("add");
        },
        isactiveFunction() {
            if (this.isactive) {
                this.isactive = false
            } else {
                this.isactive = true
            }
        }
    }
}
</script>

<style>
.float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 40px;
    z-index: 50;
    background-color: #2196f3;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
}

.float1 {
    bottom: 100px;
}

.float2 {
    bottom: 160px;
}

.my-float {
    padding: 12px;
    font-size: x-large;
}
</style>