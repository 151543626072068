import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Reminder;
var getters = {
    GetReminder: state => state.Reminder.getdata
};
const mutations = {
    [mutation_type.Reminder]: (state, response) => {
        state.Reminder.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {}
};
const actions = {
    GetReminder: ({ commit },query) => {
        
        return new Promise((resolve, reject) => {
            try {
               
                let skip ;
                let take;
                if(query == undefined){
                    skip = 0;
                    take = 10;
                }else{
                    skip = query.skip;
                    take = query.take;
                }
                let filter=""
                if(query.name){
                    filter+=`&name=${query.name}`
                }
                if(query.campaign){
                    filter+=`&campaign=${query.campaign}`
                }
                api.get(`/reminder?skip=${skip}&take=${take}`+filter).then(response => {
                    const reminder = response.data;
                    commit(mutation_type.Reminder, reminder);
                    // const reminder_array = [];
                    // reminder.data.forEach(item => {
                    //     reminder_array.push(item.pause_code);
                    // });
                    // localStorage.setItem("pause_code", JSON.stringify(reminder_array));

                    resolve(reminder);

                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterRemindercode: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/reminder?name=${query.name}&campaign=${query.campaign}`).then(response => {
                    const reminder = response.data;
                    commit(mutation_type.Reminder, reminder);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertRemindercode: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/reminder`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteReminder: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                api
                    .delete(`/reminder/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateRemindercode: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/reminder/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetCampaign: () => {
        return new Promise((resolve, reject) => {
            try {
                
                let take = {
                    take : ''
                  }
                api.get(`/aster/campaigns?take=${take.take}`).then(response => {
                    let value = response.data.data;
                    let data = value.filter(res=>res.active=='Y')
                    let campaigns = [];
                    for (let i = 0; i < data.length; i++) {
                        var obj ={
                            name : data[i].campaign_name,
                            template : data[i].template_name,
                            disposition : data[i].dispo_statuses
                        }
                        campaigns.push(obj);
                    }
                    resolve(campaigns);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetTimevalue: () => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/reminder/template`).then(response => {
                    
                    resolve(response.data.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};