<template>
    <div>
        <div class="toplayer bmp">
            <div>
                <div class="titlecls title">Notification Configurations</div>
            </div>
            <v-form fluid="true" class="maincontainer">
                <div class="subcontainer">
                    <v-text-field v-model="notify.name" label="Name" :rules="[(v) => !!v || 'Name is required']"
                        required></v-text-field>
                </div>
                <div class="subcontainer">
                    <v-select v-model="notify.rule_module" label="Rule Module" :items="ModuleData"
                        :rules="[(v) => !!v || 'Rule Module is required']" item-text="name" item-value="value"
                        @change="Ruletype(notify.rule_module)" required></v-select>
                </div>
                <div class="subcontainer" v-if="notify.rule_module == 'leads' && notify.rule_module !== ''">
                    <v-select v-model="notify.template" label="Template" :items="TemplateData" item-text="name"
                        item-value="value" :rules="[(v) => !!v || 'Template is required']"
                        @change="GetTemplate('custom_' + notify.template)" required></v-select>
                </div>
                <div class="subcontainer">
                    <v-select v-model="notify.notify_type" label="Notify Type" :items="NotifyData"
                        :rules="[(v) => !!v || 'Notify Type is required']" item-text="name" item-value="value"
                        required @change="WAtemplate(notify.notify_type)"></v-select>   
                </div>
                <div class="subcontainer"  v-if="notify.notify_type == 'wa'">
                    <v-select
                    :items="whatstemplate"
                    item-text="name" item-value="id"
                    label="Template Message"
                    v-model="whatsapptemplate"
                    @change="WAtemplatedata(whatsapptemplate)"
                  ></v-select>
                </div>
                <div class="subcontainer"  v-if="notify.notify_type == 'email' || notify.notify_type == 'sms'">
                    <v-textarea
                    solo
                    name="input-7-4"
                    label="Template Message"
                    hint="Dynamaic value can enclose in ${field_name} in Between the Text. Eg: iam ${first_name}"
                    v-model="notify.message"
                  ></v-textarea>
                </div>
                <div class="subcontainer"  v-if="notify.notify_type !== 'api'">
                    <v-select v-model="notify.notify_to" label="Notify To" :items="NotifyTo"
                        :rules="[(v) => !!v || 'Notify To is required']" item-text="name" item-value="value"
                        required></v-select>
                    <v-text-field v-if="notify.notify_to == 'other'" v-model="notify.notify_to_value" label="Data"
                        required></v-text-field>
                </div>
            </v-form>
        </div>
        <div class="toplayer bmp" 
        v-if="notify.notify_type == 'api'"
        >
            <div>
                <div class="titlecls title">API Details</div>
            </div>
            <div class="filtercontroller">
                <v-flex class="filter_cls_container">
                    <div class="container_field">
                        <div class="filter_controll" >
                            <v-select  v-model="notify.api.method" label="METHOD" :items="Method" ></v-select>
                        </div>
                        <div class="w-url">
                            <v-text-field v-model="notify.api.url"  label="End Point URl" ></v-text-field>
                        </div>
                    </div>
                    <div class="container_field">
                        <div class="filter_controll" >
                            <v-select  v-model="notify.api.auth_type" label="Auth Type" :items="AuthType" item-text="name"
                            item-value="value" @change="GetAuthType(notify.api.auth_type)"></v-select>
                        </div>
                        <!-- <div class="w-url" v-if="notify.api.auth !== 'na'" >
                            <v-select v-for="(item,index) in notify.api.auth_params" v-model="notify.api.auth_params"  placeholder="{'Authorization':'asdfdsffsda.....' , 'Grant Type':'sadsa-adfsdasf' }" label="Auth key/Token" ></v-select>
                        </div> -->
                        <div class="w-url" v-if="notify.api.auth_type !== 'na'" >
                            <div v-for="(item,index) in notify.api.auth_params" :key="index" class="auth-field">
                                <div class="ptr-10">
                                    <v-text-field v-model="item.name" label="Name" placeholder="Header" @change="Headerdata"></v-text-field>
                                </div>
                                <div >
                                    <v-text-field v-model="item.value" label="Value" placeholder="value" @change="Headerdata"></v-text-field>
                                </div>
                                <v-icon v-if="notify.api.auth_params.length-1 !== index"
                                class="icon"
                                @click="AuthRemoveField(index)"
                                color="red"
                                >close
                                </v-icon>
                                
                            </div>
                        </div>
                    </div>
                    <div class="container_field" v-for="(item,key) in notify.api.payload" :key="key">
                        <div class="field-system" v-if="item.system_params !=='customvalue'">
                            <v-select v-model="item.system_params" label="Field"   item-disabled="active" :items="TableFieldsClone" item-text="name"
                            item-value="value" solo @change="Tablevalue(item.system_params,key)"></v-select>
                        </div>                        
                        <div class="field-custom" v-if="item.system_params =='customvalue'"> 
                            <v-text-field v-model="item.custom_params.key" label="Custom Field name"></v-text-field>
                        </div>
                        <div class="field-custom" v-if="item.system_params =='customvalue'"> 
                            <v-text-field v-model="item.custom_params.value" label="Custom Field value"></v-text-field>
                        </div>
                        <div class="field-custom" v-if="item.system_params !=='customvalue'">
                            <v-text-field v-model="item.custom_params" label="Field Map"></v-text-field>
                        </div>
                        <div class="field-custom">
                            <v-icon
                        color="red"
                        class="mt-4 ml-0 remove-icon"
                        @click="ApiRemoveprams(key)"
                        >close</v-icon
                        >
                        </div>
                    </div>
                    <div class="plusbtn-apibtn" v-if="TableFields.length !== notify.api.payload.length || true">
                        <v-icon
                        class="icon"
                        @click="ApiAddprams()"
                        color="primary"
                        >mdi-plus
                        </v-icon>
                        <label >Add Payload</label>
                       
                        </div>
                </v-flex>
            </div>
        </div>
        <div class="toplayer">
            <div>
                <div class="titlecls title">Rules Data</div>
            </div>
            <div class="plusbtn">
                <v-btn icon color="primary" flat @click="plusClick">
                    <i :class="['fas', 'fa-plus', 'fa-2x']"></i>
                </v-btn>
            </div>
            <div class="filtercontroller">
                <v-flex class="filter_cls_container">
                    <div class="container_field" v-for="(item, index) in notify.params" :key="index">
                        <div class="filter_controll" v-if="item.option !== ''">
                            <v-select v-model="item.option" label="Option" :items="options"></v-select>
                        </div>
                        <div class="filter_controll">
                            <v-select v-model="item.params_name" label="Select Column" :items="TableFields" item-text="name"
                                item-value="value" @change="FieldData(item.params_name, index)"></v-select>
                        </div>
                        <div class="filter_controll"
                            v-if="item.params_name && (item.type === 'datetime' || item.type === 'date')">
                            <v-select v-model="item.operation" label="Operation" :items="datetype" item-text="name"
                                item-value="value" @change="Datefieldchange()"></v-select>
                        </div>
                        <div class="filter_controll" v-else-if="item.params_name && item.type === 'varchar'">
                            <v-select v-model="item.operation" label="Operation" :items="varechar_operations"
                                item-text="name" item-value="value"></v-select>
                        </div>
                        <div class="filter_controll" v-else>
                            <v-select v-model="item.operation" label="Operation" :items="operations" item-text="name"
                                item-value="value" @change="OperationChange(index)"></v-select>
                        </div>

                        <div class="filter_controll" v-if="(item.type === 'int' ||
                            item.type === 'tinyint' ||
                            item.type === 'smallint' ||
                            item.type === 'mediumint' ||
                            item.type === 'bigint' ||
                            item.type === 'enum') &&
                            item.operation !== 'IS NULL' &&
                            item.operation !== 'IS NOT NULL'
                            ">
                            <v-text-field required v-model="item.params_values" label="Enter Value"></v-text-field>
                        </div>
                        <div class="filter_controll_txtclm" v-if="item.operation === 'mtd'">
                           <div> <v-text-field required v-model="item.params_values" label="Enter Value"></v-text-field> </div><div style="margin:auto;">Days</div>
                        </div>
                        <div class="filter_controll_txtclm" v-if="item.operation === 'mtt'">
                            <div>      
                                <v-menu
                                  ref="menu"
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  :return-value.sync="time"
                                  lazy
                          
                                  transition="scale-transition"
                                  offset-y
                                  full-width
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="item.params_values"
                                      label="Time"
                                      
                                      readonly
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menu2"
                                    v-model="item.params_values"
                                    full-width format="24 hr" use-seconds
                                    @click:minute="$refs.menu.save(item.params_values)"
                                  ></v-time-picker>
                                </v-menu>

                                
                             </div><div style="margin:auto;">Hours</div>
                         </div>
                        <div class="filter_controll" v-if="(item.type === 'varchar') &&
                            item.operation !== 'IS NULL' &&
                            item.operation !== 'IS NOT NULL'">
                            <v-text-field required v-model="item.params_values" label="Enter Value"
                                ></v-text-field>     
                        </div> 
                        <div class="filter_controll" v-if="(item.type === 'datetime' || item.type === 'date' || item.type == 'timestamp') &&
                            item.operation !== 'IS NULL' && item.operation !== 'BETWEEN' && item.operation !== 'mtd' && item.operation !== 'mtt'">
                            <!-- <v-text-field required v-model=" item.params_values" label="Date"></v-text-field> -->
                            <datepicker :columnValue="item.params_values"
                                @datetransfer="Datefieldchange($event, index, item.params_values)"></datepicker>
                        </div>
                        <div class="filter_controll"
                            v-if="(item.type === 'datetime' || item.type === 'date' || item.type == 'timestamp') && item.operation == 'BETWEEN'">
                            <!-- <v-text-field required v-model="item.params_values" label="Date"></v-text-field> -->
                            <datepicker :columnValue="Getbtwn(item.params_values, index, 0)"
                                @datetransfer="Datefieldbtwnchange($event, index, 0)"></datepicker>
                            <datepicker :columnValue="Getbtwn(item.params_values, index, 1)"
                                @datetransfer="Datefieldbtwnchange($event, index, 1)"></datepicker>
                        </div>
                        <div class="filter_controll_cancel">
                            <i @click="filter_cancel(index)" style="
                             color: rgb(33, 150, 243);
                             padding: 5px;
                             padding-top: 35px;
                             font-size: 25px;
                           " class="fa fa-times-circle red--text" aria-hidden="true"></i>
                        </div>

                    </div>

                </v-flex>
            </div>
        </div>
        <div style="display: flex;justify-content: space-between;padding: 0px 15px 15px 20px;">
            <div style="display: flex">
                <v-checkbox v-model="notify.status" style="margin-top: 20px" color="primary" persistent-hint></v-checkbox>
                <div style="margin-top: 28px">Active</div>
            </div>
            <div style="display: flex;margin-top: 15px;justify-content: space-between">
                <v-btn color="light" flat @click="backpage">Cancel</v-btn>
                <v-btn v-if="Flag == 0" color="primary" @click="CreateNotify">Create</v-btn>
                <v-btn v-if="Flag == 1" color="primary" @click="UpdateNotify">Update</v-btn>
            </div>
        </div>

    </div>
</template>
<script>
import datepicker from "@/components/date-picker";
export default {
    components: {
        datepicker
    },
    props: {
        notify: Object,
        Flag: Number
    },
    data() {
        return {
            ModuleData: [{ name: 'Lead', value: 'leads' },
            { name: 'Ticket', value: 'tickets' },
            { name: 'Meeting', value: 'meetings' },
            { name: 'Agent Calls', value: 'agent_calls' },
            ],
            NotifyData: [{ name: "Email", value: "email" },
            { name: "Whats App", value: "wa" },
            { name: "SMS", value: "sms" },
            { name: "API", value: "api" },
            ],
            NotifyTo: [{ name: "Caller", value: "caller" },
            { name: "Agent", value: "agent" },
            { name: "Other", value: "other" }],
            TemplateData: [],
            TableFields: [],
            TableFieldsClone:[],
            Addpayloaddata: [{
                params_name: "",
                params_values: "2022-01-02 , 2022-01-02",
                type: "",
                operation: "BETWEEN",
                option: ""
            }, {
                params_name: "",
                params_values: "",
                type: "",
                operation: "=",
                option: "AND"
            }],
            datafield:[],
            options: ["AND", "OR"],
            AuthType:[{name:"N/A",value:"na"},{name:"TOKEN / API KEY",value:"auth/key"}],
            Method:["POST","PUT","GET"],
            datetype: [
                { name: "On", value: "=" },
                { name: "After", value: ">" },
                { name: "BETWEEN", value: "BETWEEN" },
                { name: "LIKE", value: "LIKE" },
                { name: "LIKE %...%", value: "LIKE %...%" },
                { name: "NOT LIKE", value: "NOT LIKE" },
                { name: "IS NULL", value: "IS NULL" },
                { name: "IS NOT NULL", value: "IS NOT NULL" },
                { name: "Equal", value: "=" },
                { name: "Greater Than", value: ">" },
                { name: "Lesser Than", value: "<" },
                { name: "Greater Than And Equal", value: ">=" },
                { name: "Lesser Than Equal", value: "<=" },
                { name: "Not Equal", value: "!=" },
            ],
            operations: [
                { name: "LIKE", value: "LIKE" },
                { name: "LIKE %...%", value: "LIKE %...%" },
                { name: "NOT LIKE", value: "NOT LIKE" },
                { name: "IN", value: "IN" },
                { name: "NOT IN", value: "NOT IN" },
                { name: "IS NULL", value: "IS NULL" },
                { name: "IS NOT NULL", value: "IS NOT NULL" },
                { name: "BETWEEN", value: "BETWEEN" },
                { name: "NOT BETWEEN", value: "NOT BETWEEN" },
                { name: "Equal", value: "=" },
                { name: "Greater Than", value: ">" },
                { name: "Lesser Than", value: "<" },
                { name: "Greater Than And Equal", value: ">=" },
                { name: "Lesser Than Equal", value: "<=" },
                { name: "Not Equal", value: "!=" },
                {name:"More then Time",value:"mtt"},
                {name:"More then Day",value:"mtd"},
            ],
            varechar_operations: [
                { name: "LIKE", value: "LIKE" },
                { name: "LIKE %...%", value: "LIKE %...%" },
                { name: "NOT LIKE", value: "NOT LIKE" },
                { name: "IS NULL", value: "IS NULL" },
                { name: "IS NOT NULL", value: "IS NOT NULL" },
                { name: "IN", value: "IN" },
                { name: "NOT IN", value: "NOT IN" },
                { name: "Equal", value: "=" },
                { name: "Not Equal", value: "!=" },
            ],
            whatstemplate:[],
            whatsapptemplate:"",
            menu2:false
        }
    },
    methods: {
        Ruletype(data) {
            let scope = this
            let table = ''
            if (data == 'leads') {
                scope.$store.dispatch("GetTemplate").then((response) => {
                    scope.TemplateData = response[0].map((e) => {
                        return {
                            name: e.template_name,
                            value: e.template_name
                        }
                    })

                })
            } else if (data == 'tickets') {
                table = 'aster_tickets'
            } else if (data == 'meetings') {
                table = 'aster_followup'
            }else if (data == 'agent_calls') {
                table = 'aster_call_status'
            }
            if (table != '') {
                scope.GetTemplate(table)
            }

        },
        GetTemplate(table) {
            let scope = this
            scope.$store.dispatch("rect/fetchTableColumn", table).then((response) => {
                scope.TableFields = response
                scope.TableFieldsClone = response.map((data)=>{
                    data.active=false
                    return data
                })
                scope.TableFieldsClone.push({name:"Custom Field",value:"customvalue",active:false})
            })
        },
        WAtemplate(data) {
            let self = this
            if(data == "wa"){
                this.$store.dispatch("GetWaTemplate").then((response) => {
                if (response.status == 200) {
                    let arr = []
                    response.data.forEach((elm) => {
                        if (elm.data.components.length > 0) {
                            if (elm.data.components[0].hasOwnProperty("type") && elm.data.components[0].hasOwnProperty("format")) {
                                if (elm.data.components[0].type == "HEADER" && elm.data.components[0].format == "TEXT") {
                                    arr.push({ name: elm.data.name, id: elm.data.id, lang: elm.data.language, format: elm.data.components[0].format, link: "" })
                                } else {
                                    if (elm.data.components[0].hasOwnProperty("example")) {
                                        arr.push({ name: elm.data.name, id: elm.data.id, lang: elm.data.language, format: elm.data.components[0].format, link: elm.data.components[0].example.header_handle[0] })
                                    }
                                }
                            }
                        }
                    })
                    console.log(arr)
                    self.whatstemplate = arr
                }
            })
            }
        },
        WAtemplatedata(data){
            let self = this
            self.notify.wa_template = self.whatstemplate.find((t)=>{
              return  t.id == data
            })
        },
        operation_assign(val) {
            if (val === "Equal") {
                return "=";
            } else if (val === "Greater Than") {
                return ">";
            } else if (val === "Lesser Than") {
                return "<";
            } else if (val === "Greater Than And Equal") {
                return ">=";
            } else if (val === "Lesser Than Equal") {
                return "<=";
            } else if (val === "Not Equal") {
                return "!=";
            } else if (val === "IS NOT NULL") {
                return "IS NOT NULL";
            } else if (val === "IS NULL") {
                return "IS NULL";
            } else if (val === "NOT LIKE") {
                return "NOT LIKE";
            } else if (val === "LIKE %...%") {
                return "LIKE %...%";
            } else if (val === "LIKE") {
                return "LIKE";
            } else if (val === "IN") {
                return "IN";
            } else if (val === "NOT IN") {
                return "NOT IN";
            } else if (val === "Today") {
                return "=";
            } else if (val === "Yesterday") {
                return "=";
            } else if (val === "This Month") {
                return "BETWEEN";
            } else if (val === "Last 7 days") {
                return "BETWEEN";
            } else if (val === "This Week") {
                return "BETWEEN";
            } else if (val === "This Year") {
                return "BETWEEN";
            } else if (val === "On") {
                return "=";
            } else if (val === "After") {
                return ">";
            } else if (val === "BETWEEN") {
                return "BETWEEN";
            } else if (val === "NOT BETWEEN") {
                return "NOT BETWEEN";
            }
        },
        FieldData(item, index) {
            let scope = this

            let field_data = scope.TableFields.find(e => e.value == item)
            scope.notify.params[index].type = field_data.type
        },
        Datefieldchange(event, index) {
            let scope = this
            scope.notify.params[index].params_values = event
            if (scope.notify.params[index].params_values == "") {
                console.log(" need to work empty value in change operation")
            }
        },
        Getbtwn(data, index, num) {
            let scope = this
            if (scope.notify.params[index].params_values != "" && scope.notify.params[index].params_values.includes(',')) {
                return scope.notify.params[index].params_values.split(',')[num]
            } else {
                return ""
            }
        },
        Datefieldbtwnchange(event, index, num) {
            let scope = this
            if (scope.notify.params[index].params_values == "") {
                scope.notify.params[index].params_values = event + ', ' + event
            } else if (scope.notify.params[index].params_values != "" && scope.notify.params[index].params_values.includes(',')) {
                let paramsdata = scope.notify.params[index].params_values.split(',')
                paramsdata[num] = event
                scope.notify.params[index].params_values = paramsdata.join()
            }
        },
        plusClick() {
            let scope = this
            let optiontype = ""
            if (scope.notify.params.length > 0) optiontype = "AND"
            scope.notify.params.push({
                params_name: "",
                params_values: "",
                type: "",
                operation: "",
                option: optiontype
            })
        },
        filter_cancel(index) {
            let scope = this
            if (index == 0) {
                if (scope.notify.params.length > 1) {
                    scope.notify.params[index + 1].option = ""
                }
            }
            scope.notify.params.splice(index, 1)

        },
        backpage() {
            let scope = this
            scope.$emit("backpage");
        },
        CreateNotify() {
            let scope = this
            scope.$emit("CreateNotifiy");
        },
        UpdateNotify(){
            let scope = this
            scope.$emit("UpdateNotifiy");
        },
        ApiAddprams(){
            let scope = this
                scope.notify.api.payload.push({
                system_params:"",
                custom_params:""
            }) 

        },
        ApiRemoveprams(i){
            let scope = this
            scope.notify.api.payload.splice(i, 1)
            scope.Tablevalue()
        },
        Tablevalue(data,i){
            let scope = this
            let select_Data = scope.notify.api.payload.filter((elm)=>{
                if(elm.system_params !==""){
                    return elm.system_params
                }
            })
            select_Data = scope.notify.api.payload.map((elm)=>{
                if(elm.system_params !==""){
                    return elm.system_params
                }
            })
            scope.notify.api.payload.forEach((elm)=>{
                scope.TableFieldsClone.find((elem)=>{
                    if((elem.value == elm.system_params || select_Data.includes(elem.value)) &&  elem.value !== "customvalue"){
                        elem.active = true
                    }else{
                        elem.active = false
                    }
                })
            })

            if(data != undefined){
                if(data == "customvalue"){
                    scope.notify.api.payload[i].custom_params={}
                    scope.notify.api.payload[i].custom_params.key=""
                    scope.notify.api.payload[i].custom_params.value=""
                }else{
                    scope.notify.api.payload[i].custom_params =""
                }
            }
        },
        GetAuthType(Data){
            let scope = this
            if(scope.notify.api.auth_params.length == 0 && Data ==  'auth/key'){
                scope.notify.api.auth_params.push({name:"",value:""})
            }
        },
        Headerdata(){
            let scope = this
            let addstatus= scope.notify.api.auth_params.every((e)=>{
                return e.name !="" || e.value
            })
            if(addstatus){
                scope.notify.api.auth_params.push({name:"",value:""})
            }
        },
        AuthRemoveField(i){
            let scope = this
            scope.notify.api.auth_params.splice(i, 1)
        },
        OperationChange(i){
            let scope = this
            scope.notify.params[i].params_values =""
        }
    },
    mounted() {
        let scope = this
        if (scope.Flag == 1) {
            scope.Ruletype(scope.notify.rule_module)
            if(scope.notify.template !==""){
                scope.GetTemplate('custom_' + scope.notify.template)
            }
            if(scope.notify.params.length > 0){
                scope.notify.params=  scope.notify.params.map((elm)=>{
                    if(elm.hasOwnProperty('id') == true){
                        delete elm.id
                    }
                    if(elm.hasOwnProperty('notification_id') == true){
                        delete elm.notification_id
                    }
                    return elm
                })
            }
            if(scope.notify.notify_type == "api"){
                scope.Headerdata()
            }
            console.log(scope.notify.wa_template)
            if(Object.keys(scope.notify.wa_template).length !== 0){
                scope.whatsapptemplate = scope.notify.wa_template.id
                scope.WAtemplate("wa") 
            }
            
        }

    }
}
</script>
<style>
.maincontainer {
    margin: 24px;
    margin-top: 0px;
    width: auto;
    min-width: 500px;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.subcontainer {
    width: 300px;
}

.toplayer {
    padding-top: 15px;
    border: 1px solid #cacaca;
    border-radius: 5px;
    box-shadow: rgb(33, 150, 243, 0.05) 0px 6px 24px 0px,
        rgb(33, 150, 243, 0.08) 0px 0px 0px 1px;
}

.bmp {
    margin-bottom: 34px;
}

.filter_controll_edit {
    display: flex;
    justify-content: space-between;
}

.filtercontroller {
    max-width: 1120px;
    min-width: 500px;
    padding: 0 0 0 28px;
}

.filter_cls_container {
    max-width: 1120px;
    min-width: 500px;
}

.filter_controll {
    width: 250px;
    padding: 10px;
}

.filter_controll_txtclm{
    width: 150px;
    padding: 10px;
    display: flex;
}

.field-system{
    width: 224px;
    padding: 10px;
}
.field-custom{
    width: 200;
    padding: 10px;
}

.w-url{
    width: 500px;
    padding: 10px;
}

.filter_controll_cancel {
    width: 90px;
    padding: 10px;
}

.container_field {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.plusbtn {
    display: flex;
    justify-content: end;
}

.plusbtn-apibtn {
    display: flex;
    justify-content: start;
    padding: 10px;
}
.auth-field{
    display: flex;
    flex-direction: row;
}
.ptr-10{
    padding-right: 20px;
}
</style>