import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const state = config.Blocklist;
var getters = {
    Blocklist: state => state.Blocklist.data,
}
const mutations = {
    [mutation_type.Blocklist]: (state, response) => {
        state.Blocklist.data = response.data.data;
    }
};
const actions = {
    Blocklist: ({ commit },payload) => {
        return new Promise((resolve, reject) => {
            try {
                let skip ;
                let take;
                if(payload == undefined){
                    skip = 0;
                    take = 10;
                }else{
                    skip = payload.skip;
                    take = payload.take;
                }
                let query=""
                if(payload.phone_number){
                    query =`&phone_number=${payload.phone_number}`
                }
               
                api.get(`/blocklist/fetch?skip=${skip}&take=${take}`+query).then((response) => {
                    var Blocklist_response = response;
                    resolve(response)
                    commit(mutation_type.Blocklist, Blocklist_response);
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
    FilterBlocklist: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/blocklist/check?phone_number=${query}`).then(response => {
                    const pausecode = response.data;
                    commit(mutation_type.PauseCode, pausecode);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    // DeleteBlocklistdata: (payload) => {
    //     var deleted_id = {
    //         "id": payload.id
    //     }

    //     var deletes = { data: deleted_id }

    //     return new Promise((resolve) => {
    //         api.delete(`/blocklist/del/${payload.id}`, deletes).then(response => {
    //             resolve(response);
    //         })
    //     })
    // },

    DeleteBlocklistdata: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/blocklist/del/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },


};
export default {
    state,
    getters,
    mutations,
    actions
}