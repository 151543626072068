<template>
  <div style="width: 250px">
   <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-if="columnValue != ''" style="width: 230px" v-model="columnValue" label="Pick one date"
          prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
        <v-text-field v-else v-model="date_saver" label="Pick one date" prepend-icon="mdi-calendar" v-bind="attrs"
          v-on="on"></v-text-field>
      </template>
      <v-date-picker color="primary" v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="save()"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    columnValue: {
      type: String,
      default: "",
    },
  },
  watch: {
    columnValue() {
      this.columnValue = ""
    },
  },

  data: () => ({
    date_saver: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
  }),
  methods: {
    save() {
      this.modal = false;
      this.date_saver = this.date;
      this.$emit("datetransfer", `${this.date_saver}`);
    },
  },
};
</script>