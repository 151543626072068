import config from "@/config/index";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
import jwt_decode from "jwt-decode";
const state = config.WebHookManagement;
var getters = {
    GetWebhook: state => state.webhookmanagement,
}
const mutations = {
    [mutation_type.WebHookManagement]: (state, response) => {
        state.webhookmanagement = response;
    },
    [mutation_type.CommitNothing]: () => {

    }
};
const actions = {
    GetWebhook: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            let fetch_query = ""

            if (query.name) {

                fetch_query += `&name=${query.name}`

            }
            if (query.event) {

                fetch_query += `&event=${query.event}`

            }
            if (query.status) {

                fetch_query += `&status=${query.status}`

            }

            try {
                api.get(`/webhook?skip=${query.skip}&take=${query.take}` + fetch_query).then(response => {
                    const webhook = response.data.data;
                    commit(mutation_type.WebHookManagement, webhook);
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertWebhook: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/webhook`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteWebhook: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/webhook/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateWebhook: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/webhook/${id}`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    EditParams: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                const decoded = jwt_decode(localStorage.getItem('apiInfo'));
                const tenantcode = decoded["http://api.doocti.com/claims/tenant_code"];
                api.get(`/api/params?tenant_id=${tenantcode}&webhook_id=${payload.id}&event=${payload.event}`).then(response => {
                    const webhook_params = response.data;

                    commit(mutation_type.CommitNothing, webhook_params);
                    resolve(webhook_params);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetDefaultParams: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/api/system/params?event=${payload}`).then(response => {
                    const webhook_params = response.data.data;
                    commit(mutation_type.CommitNothing, webhook_params);
                    resolve(webhook_params);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateParams: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                const decoded = jwt_decode(localStorage.getItem('apiInfo'));
                const tenantcode = decoded["http://api.doocti.com/claims/tenant_code"];
                payload.data.forEach(element => {
                    element.tenant_id = tenantcode;
                });
                api
                    .put(`/api/update`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertCustomParams: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                const decoded = jwt_decode(localStorage.getItem('apiInfo'));
                const tenantcode = decoded["http://api.doocti.com/claims/tenant_code"];
                payload.tenant_id = tenantcode;
                api
                    .post(`/api/system/params`, payload)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteeditparams: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/api/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}