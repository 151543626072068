<template>
    <div>
        <div v-show="activeField.hasOwnProperty('')" style="padding: 75px">
            Please Select One Element
        </div>
        <div class="no-access" style="padding: 25%" v-if="activeField.isdefaultfield == true">
            No Access for Properties
        </div>
        <el-form v-show="activeField.hasOwnProperty('fieldType')" :model="fieldProperties" :rules="rules"
            :label-position="labelPosition" ref="fieldProperties" v-else>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="Label Name" v-show="activeField.hasOwnProperty('label')" required
                        :error="getErrorForField('label', validation)">
                        <el-input style="width: 100%" v-model="activeField.label" @change="fieldChanged('label')">{{
                            activeField.label
                        }}</el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Input Type"
                        v-show="activeField.hasOwnProperty('type') && activeField.fieldType === 'TextInput'" required>
                        <el-row>
                            <el-col :span="24">
                                <el-select v-model="activeField.type" placeholder="Select"
                                    :disabled="(activeField.hasOwnProperty('editable') && activeField.editable == true)">
                                    <el-option v-for="item in inputtype" @change="fieldChanged('input_type')"
                                        :key="item.name" :value="item.value" :label="item.name">
                                    </el-option>
                                </el-select>
                                <!-- <el-input v-model="activeField.model_type" controls-position="right"></el-input> -->
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>



            <el-row>
                <el-col :span="12">
                    <el-form-item label="Model" v-show="activeField.hasOwnProperty('model')" required>
                        <el-row>
                            <el-col :span="24">
                                <el-input v-model="activeField.model" @change="fieldChanged('model')"
                                    :disabled="activeField.hasOwnProperty('editable') && activeField.editable == true"
                                    controls-position="right">
                                </el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Model Type" v-show="activeField.hasOwnProperty('model_type')" required>
                        <el-row>
                            <el-col :span="24">
                                <el-select v-model="activeField.model_type" placeholder="Select"
                                    :disabled="activeField.fieldType == 'Checkbox' || (activeField.hasOwnProperty('editable') && activeField.editable == true)">
                                    <el-option v-for="item in modelType" @change="fieldChanged('model_type')"
                                        :key="item.name" :value="item.value" :label="item.name">
                                    </el-option>
                                </el-select>
                                <!-- <el-input v-model="activeField.model_type" controls-position="right"></el-input> -->
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="Height - px" v-show="activeField.hasOwnProperty('fieldType') &&
                activeField['fieldType'] == 'Carousel'
                ">
                <el-input-number v-model="activeField.controlHeight" controls-position="right"></el-input-number>
            </el-form-item>

            <!-- Show only when 'isPlacehodlerVisible' key exist :disabled="activeField.model_type" -->
            <el-row>
                <el-col :span="12">
                    <el-form-item v-if="activeField.fieldType !== 'RadioButton' &&
                        activeField.fieldType !== 'Checkbox'
                        " label="Placeholder" v-show="activeField.hasOwnProperty('isPlaceholderVisible')">
                        <el-switch v-model="activeField.isPlaceholderVisible"
                            @change="fieldChanged('isPlaceholderVisible')">
                        </el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Placeholder" v-show="activeField.isPlaceholderVisible">
                        <el-input v-show="activeField.isPlaceholderVisible" v-model="activeField.placeholder"
                            @change="fieldChanged('placeholder')">
                            {{ activeField.placeholder }}
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="5">
                    <el-form-item label="Date limit" v-show="activeField.fieldType == 'DatePicker'">
                        <el-row>
                            <el-col :span="29">
                                <el-switch v-model="activeField.datelimit" @change="fieldChanged('datelimit')">
                                </el-switch>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :span="25" v-show="activeField.hasOwnProperty('datelimit') && activeField.datelimit">
                    
                    <el-form-item label="Start to End" v-show="true">
                        <!-- <el-date-picker v-model="activeField.datelimitdata" type="daterange" range-separator="To"
                            start-placeholder="Start date" end-placeholder="End date">
                        </el-date-picker> -->
                        <el-row>
                            <el-col :span="8"> <el-date-picker prefix-icon="sicon" type="date" placeholder="Start Date" v-model="activeField.datelimitdata[0]" style="width: 100%;"  :picker-options="pickerOptions"></el-date-picker></el-col>
                            <el-col class="line" :span="5">to</el-col>
                            <el-col :span="8"> <el-date-picker  prefix-icon="sicon" type="date" placeholder="End Date" v-model="activeField.datelimitdata[1]" style="width: 100%;"  :picker-options="pickerOptions"></el-date-picker></el-col>
                           
                          </el-row>
                        
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- <el-row>
    <el-col :span="12">
    <el-form-item label="Default field?" v-show="activeField.hasOwnProperty('hasDefault')">
    <el-switch v-model="activeField.hasDefault" @change="fieldChanged('hasDefault')"></el-switch>
    </el-form-item>
    </el-col>
    <el-col :span="12" v-if="activeField.hasDefault">
    <el-form-item label="Default Value" v-show="activeField.hasOwnProperty('default')">
    <el-input :disabled="activeField.model_type != '' ? false : true" :type="typeIsChanges"
    v-model="activeField.default" controls-position="right" @change="fieldChanged('default')"></el-input>
    </el-form-item>
    </el-col>
    </el-row> -->

            <el-row>
                <el-col :span="12">
                    <el-form-item label="Show-on-Form" v-show="activeField.hasOwnProperty('show_on_view')">
                        <el-switch v-model="activeField.show_on_view" @change="fieldChanged('show_on_view')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Show-on-Popup" v-show="activeField.hasOwnProperty('show_on_popup')">
                        <el-switch v-model="activeField.show_on_popup" @change="fieldChanged('show_on_popup')"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="Required Field"
                        v-show="activeField.hasOwnProperty('required') && activeField.linked_to == ''">
                        <el-switch v-model="activeField.required" @change="fieldChanged('required')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col v-if="activeField.required === false && activeField.disabled === false" :span="12">
                    <el-form-item label="Read-only" v-show="activeField.hasOwnProperty('clearable')">
                        <el-switch v-model="activeField.clearable"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-show="activeField.hasOwnProperty('lableWidth')">
                    <el-form-item label="Layout - Max value is 24" v-show="activeField.hasOwnProperty('span')">
                        <el-input-number @change="fieldChanged('span')" v-model="activeField.span" :min="1" :max="24"
                            controls-position="right"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <!-- <el-col :span="12">
    <el-form-item label="Form Category" v-show="activeField.hasOwnProperty('catagory')">
    <el-switch v-model="activeField.catagory" @change="fieldChanged('catagory')"></el-switch>
    </el-form-item>
    </el-col> -->
                <el-col :span="12">
                    <el-form-item label="Choose Category" v-show="activeField.hasOwnProperty('catagory_value') &&
                        activeField.catagory
                        ">
                        <el-input v-model="activeField.catagory_value" controls-position="right"
                            @change="fieldChanged('catagory_value')" v-show="activeField.catagory"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-if="(activeField.fieldType === 'TextInput' &&
                    activeField.model_type === 'Text') ||
                activeField.model_type === 'BigText' ||
                activeField.model_type === 'LongText' ||
                activeField.model_type === 'SmallNumber' ||
                activeField.model_type === 'Number' ||
                activeField.model_type === 'BigNumber' ||
                (activeField.fieldType === 'LongTextInput' &&
                    activeField.model_type === 'Text') ||
                activeField.model_type === 'BigText' ||
                activeField.model_type === 'LongText' ||
                activeField.model_type === 'SmallNumber' ||
                activeField.model_type === 'Number' ||
                activeField.model_type === 'BigNumber'
                ">
                <el-col :span="12" v-show="activeField.unique == false || activeField.hasOwnProperty('default')">
                    <el-form-item label="Default field?" v-show="activeField.hasOwnProperty('hasDefault')">
                        <el-switch v-model="activeField.hasDefault" @change="fieldChanged('hasDefault')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="activeField.hasDefault">
                    <el-form-item label="Default Value" v-show="activeField.hasOwnProperty('default')">
                        <el-input :disabled="activeField.model_type != '' ? false : true" :type="typeIsChanges"
                            v-model="activeField.default" controls-position="right"
                            @change="fieldChanged('default')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="Button text" v-if="activeField.fieldType === 'Button'"
                v-show="activeField.hasOwnProperty('buttonText')">
                <el-input v-model="activeField.buttonText" @change="fieldChanged('buttonText')">
                    {{ activeField.buttonText }}
                </el-input>
            </el-form-item>

            <el-form-item label="Code view" v-show="activeField.hasOwnProperty('fieldText')">
                <el-input v-model="activeField.fieldText" type="textarea" :rows="10">
                    {{ activeField.fieldText }}
                </el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="Active Text" v-show="activeField.hasOwnProperty('activeText')">
                        <el-input v-model="activeField.activeText">{{
                            activeField.activeText
                        }}</el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Inactive Text" v-show="activeField.hasOwnProperty('inActiveText')">
                        <el-input v-model="activeField.inActiveText">{{
                            activeField.inActiveText
                        }}</el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="uploadURL" v-show="activeField.hasOwnProperty('uploadURL')">
                <el-input v-model="activeField.uploadURL">{{
                    activeField.uploadURL
                }}</el-input>
            </el-form-item>

            <el-form-item label="Items" v-show="activeField.hasOwnProperty('items')">
                <li v-for="(item, index) in activeField.items" :key="index" class="properties__optionslist">
                    <el-row :gutter="5">
                        <el-col :span="20">
                            <el-input v-model="item.url">{{ item.url }}</el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click="deleteOption(activeField.items, index)"
                                v-show="activeField.items.length > 1">
                                <i class="el-icon-error"></i>
                            </el-button>
                        </el-col>
                    </el-row>
                </li>
                <el-button type="text" @click="addItem(activeField.items)">
                    <i class="el-icon-plus"></i>
                    Add more
                </el-button>
            </el-form-item>

            <el-form-item label="Options" v-if="(activeField.fieldType === 'SelectList' ||
                    activeField.fieldType === 'RadioButton') &&
                activeField.link_type !== 'Child'
                ">
                <ul class="properties__optionsul">
                    <li class="properties__optionslist">
                        <el-row :gutter="5">
                            <el-col :span="10"> Label </el-col>
                            <el-col :span="10"> Value </el-col>
                            <el-col :span="4"> </el-col>
                        </el-row>
                    </li>
                    <li v-for="(item, index) in activeField.values" :key="index" class="properties__optionslist">
                        <el-row :gutter="5">
                            <el-col :span="10">
                                <el-input v-model="item.name" @change="fieldChanged('values')">{{
                                    item.name
                                }}</el-input>
                            </el-col>
                            <el-col :span="10">
                                <el-input v-model="item.value" @change="fieldChanged('values')">{{
                                    item.value
                                }}</el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-button @click="deleteOption(activeField.values, index)"
                                    v-show="activeField.values.length > 2">
                                    <i class="el-icon-error"></i>
                                </el-button>
                            </el-col>
                        </el-row>
                    </li>
                </ul>
                <el-button type="text" @click="addOption(activeField.values)">
                    <i class="el-icon-plus"></i>
                    Add more
                </el-button>
            </el-form-item>

            <el-form-item label="Table Columns" v-show="activeField.fieldType === 'TableComponent'">
                <ul class="properties__optionsul">
                    <li class="properties__optionslist">
                        <el-row :gutter="5">
                            <el-col :span="7"> Prop </el-col>
                            <el-col :span="7"> Label </el-col>
                            <el-col :span="7"> Width </el-col>
                            <el-col :span="3"> </el-col>
                        </el-row>
                    </li>
                    <li v-for="(column, index) in activeField.tableColumns" :key="index" class="properties__optionslist">
                        <el-row :gutter="5">
                            <el-col :span="7">
                                <el-input v-model="column.prop">{{ column.prop }}</el-input>
                            </el-col>
                            <el-col :span="7">
                                <el-input v-model="column.label">{{ column.label }}</el-input>
                            </el-col>
                            <el-col :span="7">
                                <el-input @change="columnWidth" v-model="column.width">{{
                                    column.width
                                }}</el-input>
                            </el-col>
                            <el-col :span="3">
                                <el-button @click="
                                    deleteColumn(activeField.tableColumns, index, column.prop)
                                    " v-show="activeField.tableColumns.length > 1">
                                    <i class="el-icon-error"></i>
                                </el-button>
                            </el-col>
                        </el-row>
                    </li>
                </ul>
                <el-button type="text" @click="addColumn(activeField.tableColumns)">
                    <i class="el-icon-plus"></i>
                    Add more
                </el-button>
            </el-form-item>
            <!-- //Ashik -->
            <el-row>

                <el-col :span="12" v-if="activeField.fieldType != 'TextInput' &&
                    activeField.fieldType != 'TextInput' &&
                    activeField.fieldType != 'TextInput' &&
                    activeField.fieldType != 'TextInput'
                    ">
                    <el-form-item label="Multiple Select" v-show="activeField.hasOwnProperty('multiple')">
                        <el-switch v-model="activeField.multiple" @change="fieldChanged('multiple')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Filter" v-show="activeField.hasOwnProperty('filter') &&(activeField.fieldType !== 'RadioButton' &&
                    activeField.fieldType !== 'Checkbox')">
                        <el-switch v-model="activeField.filter" @change="fieldChanged('filter')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Agent Filter" v-show="activeField.hasOwnProperty('agent_show_on_filter') &&(activeField.fieldType !== 'RadioButton' &&
                    activeField.fieldType !== 'Checkbox')">
                        <el-switch v-model="activeField.agent_show_on_filter"
                            @change="fieldChanged('agent_show_on_filter')"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="Disabled" v-show="activeField.hasOwnProperty('disabled')">
                        <el-switch v-model="activeField.disabled" @change="fieldChanged('disabled')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12"
                    v-show="activeField.fieldType !== 'Checkbox' && activeField.fieldType !== 'SelectList' && activeField.fieldType !== 'RadioButton' && activeField.fieldType !== 'TimePicker' && activeField.fieldType !== 'DatePicker'">
                    <el-form-item label="Unique" v-show="activeField.hasOwnProperty('unique')">
                        <el-switch v-model="activeField.unique" @change="fieldChanged('unique')"></el-switch>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12">
    <el-form-item label="Read-only" v-show="activeField.hasOwnProperty('clearable')">
    <el-switch v-model="activeField.clearable"></el-switch>
    </el-form-item>
    </el-col> -->
            </el-row>
            <el-row v-if="activeField.multiple === false || activeField.multiple === undefined
                ">
                <el-col v-show="activeField.hasOwnProperty('is_link')" :span="12">
                    <el-form-item label="is Linked?">
                        <el-switch v-model="activeField.is_link" @change="fieldChanged('is_link')"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Link Type" v-show="activeField.hasOwnProperty('link_type') &&
                        activeField.is_link &&
                        (activeField.fieldType === 'SelectList' ||
                            activeField.fieldType === 'RadioButton' ||
                            activeField.fieldType === 'Checkbox')
                        ">
                        <el-select placeholder="Select" v-model="activeField.link_type">
                            <el-option v-for="item in linkType" :key="item.name" @change="fieldChanged('link_type')"
                                :value="item.value" :label="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Link Type" v-show="activeField.hasOwnProperty('link_type') &&
                        activeField.is_link &&
                        activeField.fieldType != 'SelectList' &&
                        activeField.fieldType != 'RadioButton' &&
                        activeField.fieldType != 'Checkbox'
                        ">
                        <el-select v-model="activeField.link_type" :selected="true">
                            <el-option value="Child">Child </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="activeField.link_type != 'Parent'">
                <el-col :span="24">
                    <el-form-item label="Linked to" v-if="activeField.hasOwnProperty('linked_to') && activeField.is_link && activeField.link_type
                        ">
                        <el-select placeholder="Select" v-model="activeField.linked_to" @change="fieldChanged('linked_to')">
                            <el-option v-for="item in linkItems" :key="item.name" :value="item.value" :label="item.name">
                            </el-option>
                        </el-select>
                        <p v-if="activeField.is_link && activeField.link_type == 'Child' && activeField.linked_to == '' && validation_arr.length > 0"
                            class="validation">Linked to is required.</p>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-if="activeField.hasOwnProperty('link_property') &&
                activeField.is_link &&
                activeField.link_type === 'Child' &&
                (activeField.fieldType === 'SelectList' ||
                    activeField.fieldType === 'RadioButton')
                ">
                <el-col :span="24" v-for="(item, index) in parentLists" :key="index">
                    <el-form-item :label="item[Object.keys(parentLists[0])[0]]">
                        <el-input v-model="activeField.link_property[item[Object.keys(parentLists[0])[1]]]
                            " controls-position="right"></el-input>
                    </el-form-item>
                </el-col>
                <p style="
    padding: 10px;
   " v-if="(activeField.is_link && activeField.link_type == 'Child' && activeField.linked_to != '' && Object.keys(activeField.link_property).length == 0 && validation_arr.length > 0) || (activeField.is_link && activeField.link_type == 'Child' && activeField.linked_to != '' && Object.keys(activeField.link_property).length > 0 && Object.values(activeField.link_property).some(res => res == '') && validation_arr.length > 0)"
                    class="validation">Linked to options is required.</p>
            </el-row>

            <el-row v-else-if="activeField.hasOwnProperty('link_property') &&
                activeField.is_link &&
                activeField.link_type === 'Child' &&
                (activeField.fieldType != 'SelectList' ||
                    activeField.fieldType != 'RadioButton')
                ">
                <el-col :span="24" v-for="(item, index) in parentLists" :key="index">
                    <el-form-item>
                        <el-radio v-model="activeField.link_property" :label="item.value">
                        </el-radio>
                    </el-form-item>
                </el-col>
                <p style="
    padding: 10px;
   " v-if="(activeField.is_link && activeField.link_type == 'Child' && activeField.linked_to != '' && activeField.link_property === '' && validation_arr.length > 0)"
                    class="validation">Linked to options is required.</p>
            </el-row>

            <el-form-item label="Html Content" v-show="activeField.hasOwnProperty('htmlContent')">
                <el-input :rows="10" type="textarea" v-model="activeField.htmlContent">{{
                    activeField.htmlContent
                }}</el-input>
            </el-form-item>

            <!-- <el-button v-show="activeField.hasOwnProperty('advancedOptions')" @click="advancedPropsVisible = true" style="width: 100%;" type="success">
    Advanced Options
    </el-button> -->
            <el-dialog :close-on-click-modal="false" title="Advanced Options" :visible.sync="advancedPropsVisible">
                <el-form ref="OptionsForm" :rules="dialogRules">
                    <rating-advanced-props v-if="activeField.fieldType === 'Rating'"></rating-advanced-props>
                    <text-input-advanced-props v-if="activeField.fieldType === 'TextInput'"></text-input-advanced-props>
                    <html-advanced-props v-if="activeField.fieldType === 'HtmlComponent'"></html-advanced-props>
                    <number-input-advanced-props
                        v-if="activeField.fieldType === 'NumberInput'"></number-input-advanced-props>
                    <select-list-advanced-props v-if="activeField.fieldType === 'SelectList'"></select-list-advanced-props>
                    <options-advanced-props v-if="activeField.fieldType === 'RadioButton' ||
                        activeField.fieldType === 'Checkbox'
                        "></options-advanced-props>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="confirmForm">Confirm</el-button>
                </div>
            </el-dialog>
        </el-form>
    </div>
</template>
   
<script>
import RatingAdvancedProps from "./RatingAdvancedProps";
import TextInputAdvancedProps from "./TextInputAdvancedProps.vue";
import HtmlAdvancedProps from "./HtmlAdvancedProps.vue";
import NumberInputAdvancedProps from "./NumberInputAdvancedProps.vue";
import OptionsAdvancedProps from "./OptionsAdvancedProps.vue";
import SelectListAdvancedProps from "./SelectListAdvancedProps.vue";
import { mapGetters } from "vuex";

export default {
    name: "Properties",
    components: {
        RatingAdvancedProps,
        TextInputAdvancedProps,
        HtmlAdvancedProps,
        NumberInputAdvancedProps,
        OptionsAdvancedProps,
        SelectListAdvancedProps,
    },
    props: {
        validation_arr: {
            type: Array
        }
    },
    data() {
        return {
            radio: "1",
            labelPosition: "top",
            fieldProperties: {},
            rules: {},
            validation: [],
            linkType: [
                { name: "Parent", value: "Parent" },
                { name: "Child", value: "Child" },
            ],
            parentLists: [],
            linkItems: [],
            linkedSelect: [],
            linkedOptions: [],
            linkedValue: [],
            modelType: [],
            inputtype: [
                { name: "Phone Number", value: "phone_number" },
                { name: "Email", value: "email" },
                { name: "Currency", value: "currency" },
                { name: "Others", value: "others" },
            ],
            lableSize: [
                { name: "Small", value: 40 },
                { name: "Medium", value: 60 },
                { name: "Large", value: 75 },
                { name: "Extra Large", value: 100 },
            ],
            layoutSize: [
                { name: "Small", value: 6 },
                { name: "Medium", value: 12 },
                { name: "Large", value: 18 },
                { name: "Extra Large", value: 24 },
            ],
            advancedPropsVisible: false,
            dialogRules: {
                dataUrl: [
                    { required: true, message: "Please input url", trigger: "change" },
                ],
            },
            totalFields: [],
            changefields: {},
            pickerOptions: {
                disabledDate: () => {
                    
                }
            }
        };
    },
    computed: {
        ...mapGetters(["activeField", "forms", "formType", "PageMethode"]),
        typeIsChanges() {
            return this.activeField.model_type == "Text" ||
                this.activeField.model_type == "BigText" ||
                this.activeField.model_type == "LongText"
                ? "text"
                : this.activeField.model_type == "SmallNumber" ||
                    this.activeField.model_type == "Number" ||
                    this.activeField.model_type == "BigNumber"
                    ? "number"
                    : "";
        },
    },
    mounted() {

        const scope = this;
        scope.linkItems = [];
        scope.parentLists = [];
        if (scope.activeField.is_link) {
            scope.forms[0].fields.forEach((field) => {
                if (
                    field.link_type !== scope.activeField.link_type &&
                    field.model !== scope.activeField.model &&
                    field.link_type &&
                    field.link_type !== "" &&
                    field.is_link
                ) {
                    scope.linkItems.push({ name: field.model, value: field.model });
                }
                if (
                    scope.activeField.link_type === "Child" &&
                    scope.activeField.linked_to !== "" &&
                    scope.activeField.linked_to &&
                    field.model === scope.activeField.linked_to
                ) {
                    scope.parentLists = field.values;
                }
            });
        }

        if (scope.activeField.fieldType !== undefined) {
            if (
                scope.activeField.fieldType == "RadioButton" ||
                scope.activeField.fieldType == "TimePicker"
            ) {
                scope.modelType = [{ name: "Text", value: "Text" }];
            }

            if (
                scope.activeField.fieldType == "TextInput" ||
                scope.activeField.fieldType == "LongTextInput"
            ) {
                scope.modelType = [
                    { name: "Text", value: "Text" },
                    { name: "Big Text", value: "BigText" },
                    { name: "Long Text", value: "LongText" },
                    { name: "Small Number", value: "SmallNumber" },
                    { name: "Number", value: "Number" },
                    { name: "Big Number", value: "BigNumber" },
                ];


            }

            if (scope.activeField.fieldType == "DatePicker") {
                scope.modelType = [{ name: "Date", value: "Date" }];
            }

            if (scope.activeField.fieldType == "DatetimePicker") {
                scope.modelType = [{ name: "Date Time", value: "DateTime" }];
            }

            if (
                scope.activeField.fieldType == "SelectList" ||
                scope.activeField.fieldType == "Checkbox"
            ) {
                scope.modelType = [
                    { name: "Text", value: "Text" },
                    { name: "Big Text", value: "BigText" },
                    { name: "Long Text", value: "LongText" },
                    { name: "Small Number", value: "SmallNumber" },
                    { name: "Number", value: "Number" },
                    { name: "Big Number", value: "BigNumber" },
                ];
            }
        }
    },
    watch: {
        activeField: {
            handler: function () {
                console.log("activeField")
                const scope = this;
                scope.linkItems = [];
                scope.parentLists = [];
                var Formvalue = JSON.parse(localStorage.getItem("catagory_value"));
                if (scope.activeField.is_link) {
                    if (Formvalue.catagory_value === scope.activeField.catagory_value) {
                        scope.forms[0].fields.forEach((field) => {
                            if (
                                field.link_type !== scope.activeField.link_type &&
                                field.model !== scope.activeField.model &&
                                field.link_type === "Parent" &&
                                field.link_type !== "" &&
                                field.is_link &&
                                Formvalue.catagory_value == field.catagory_value &&
                                Formvalue.catagory_value == scope.activeField.catagory_value &&
                                field.catagory_value == scope.activeField.catagory_value
                            ) {
                                console.log("linked called", scope.linkItems, 1)
                                scope.linkItems.push({ name: field.model, value: field.model });
                                console.log("linked called", scope.linkItems, 2)
                            }
                            if (
                                scope.activeField.link_type === "Child" &&
                                scope.activeField.linked_to !== "" &&
                                scope.activeField.linked_to &&
                                field.model === scope.activeField.linked_to
                            ) {
                                scope.parentLists = field.values;
                            }
                        });
                    }
                }

                if (
                    scope.activeField.fieldType == "RadioButton" ||
                    scope.activeField.fieldType == "TimePicker"
                ) {
                    scope.modelType = [{ name: "Text", value: "Text" }];
                }

                if (
                    scope.activeField.fieldType == "TextInput" ||
                    scope.activeField.fieldType == "LongTextInput"
                ) {
                    scope.modelType = [
                        { name: "Text", value: "Text" },
                        { name: "Big Text", value: "BigText" },
                        { name: "Long Text", value: "LongText" },
                        { name: "Small Number", value: "SmallNumber" },
                        { name: "Number", value: "Number" },
                        { name: "Big Number", value: "BigNumber" },
                    ];
                }

                if (scope.activeField.fieldType == "DatePicker") {
                    scope.modelType = [{ name: "Date", value: "Date" }];
                }

                if (scope.activeField.fieldType == "DatetimePicker") {
                    scope.modelType = [{ name: "Date Time", value: "DateTime" }];
                }

                if (
                    scope.activeField.fieldType == "SelectList" ||
                    scope.activeField.fieldType == "Checkbox"
                ) {
                    scope.modelType = [
                        { name: "Text", value: "Text" },
                        { name: "Big Text", value: "BigText" },
                        { name: "Long Text", value: "LongText" },
                        { name: "Small Number", value: "SmallNumber" },
                        { name: "Number", value: "Number" },
                        { name: "Big Number", value: "BigNumber" },
                    ];
                }


            },
        },
        "activeField.is_link": {
            handler: function () {
                console.log("activeField.is_link")
                const scope = this;
                scope.linkItems = [];
                scope.parentLists = [];
                var Formvalue = JSON.parse(localStorage.getItem("catagory_value"));
                if (scope.activeField.is_link) {
                    if (Formvalue.catagory_value === scope.activeField.catagory_value) {
                        scope.forms[0].fields.forEach((field) => {
                            if (
                                field.link_type !== scope.activeField.link_type &&
                                field.model !== scope.activeField.model &&
                                field.link_type === "Parent" &&
                                field.link_type !== "" &&
                                field.is_link &&
                                Formvalue.catagory_value == field.catagory_value &&
                                Formvalue.catagory_value == scope.activeField.catagory_value &&
                                field.catagory_value == scope.activeField.catagory_value
                            ) {
                                console.log("linked called", scope.linkItems, 3)
                                scope.linkItems.push({ name: field.model, value: field.model });
                                console.log("linked called", scope.linkItems, 4)
                            }
                            if (
                                scope.activeField.link_type === "Child" &&
                                scope.activeField.linked_to !== "" &&
                                scope.activeField.linked_to &&
                                field.model === scope.activeField.linked_to
                            ) {
                                scope.parentLists = field.values;
                            }


                            if (
                                !scope.activeField.is_link
                            ) {
                                field.link_type = ''
                                field.linked_to = ''
                            }
                        });
                    }
                }
            },
        },
        "activeField.linked_to": {
            handler: function () {
                console.log("activeField.linked_to");
                const scope = this;
                scope.linkItems = [];
                scope.parentLists = [];
                var Formvalue = JSON.parse(localStorage.getItem("catagory_value"));
                if (scope.activeField.is_link) {
                    if (Formvalue.catagory_value === scope.activeField.catagory_value) {
                        scope.forms[0].fields.forEach((field) => {
                            if (
                                field.link_type !== scope.activeField.link_type &&
                                field.model !== scope.activeField.model &&
                                field.link_type === "Parent" &&
                                field.link_type !== "" &&
                                field.is_link &&
                                Formvalue.catagory_value == field.catagory_value &&
                                Formvalue.catagory_value == scope.activeField.catagory_value &&
                                field.catagory_value == scope.activeField.catagory_value
                            ) {
                                console.log("linked called", scope.linkItems, 5)
                                scope.linkItems.push({ name: field.model, value: field.model });
                                console.log("linked called", scope.linkItems, 6)
                            }
                            if (
                                scope.activeField.link_type === "Child" &&
                                scope.activeField.linked_to !== "" &&
                                scope.activeField.linked_to &&
                                field.model === scope.activeField.linked_to
                            ) {
                                scope.parentLists = field.values;
                            }
                        });
                    }
                }
            },
        },
        deep: true,
        radio: function () {
            this.activeField.link_property = this.radio;
        },
    },
    methods: {
        updateOption(link_property) {
            if (this.activeField.fieldType != "SelectList") {
                let arr = [];
                let val = link_property["Option 1"].split(",");

                val.forEach((res) => {
                    arr.push({ name: res, value: res });
                });

                this.activeField.values = arr;
            }
        },
        deleteOption(option, index) {
            this.$delete(option, index);
        },
        addOption(option) {
            let needPush = false;
            if (!Array.isArray(option)) {
                option = [];
                needPush = true;
            }
            let count = option.length + 1;
            option.push({
                name: "Option Label " + count,
                value: "Option " + count,
            });
            if (needPush) {
                this.activeField.values = option;
            }
        },
        addItem(item) {
            item.push({
                url: "",
            });
        },
        fieldChanged(field) {
            console.log(field, "linkedOptions")
            var scope = this;
            var Form = JSON.parse(localStorage.getItem("originalForm"));


            if (field == "datelimit") {
                if (!scope.activeField.datelimit) {
                    scope.activeField.datelimitdata = []
                }
            }
            if (field === "show_on_view") {
                if (!scope.activeField.show_on_view) {
                    scope.activeField.is_link = false;
                }
            }



            if (
                scope.activeField.fieldType === "TextInput" &&
                scope.activeField.is_link &&
                field === "is_link"
            ) {
                var find_select = scope.forms[0].fields;
                scope.linkedSelect = [];
                scope.linkedOptions = [];
                for (let i = 0; i < find_select.length; i++) {
                    if (
                        find_select[i].fieldType === "SelectList" &&
                        find_select[i].model !== "hooper_status" &&
                        find_select[i].model !== "disposition"
                    ) {
                        scope.linkedSelect.push({
                            name: find_select[i].model,
                            value: find_select[i].model,
                        });
                        var temp = find_select[i].values;
                        for (let j = 0; j < temp.length; j++) {
                            scope.linkedOptions.push({
                                [find_select[i].model]: temp[j].name,
                            });
                        }
                    }
                }
                scope.linkedValue = [];
                scope.activeField.link_property = "";
                for (let k = 0; k < scope.linkedOptions.length; k++) {
                    if (
                        scope.linkedOptions[k][scope.activeField.linked_to] !== "" &&
                        scope.linkedOptions[k][scope.activeField.linked_to] !== undefined &&
                        scope.linkedOptions[k][scope.activeField.linked_to] !== null
                    ) {
                        scope.linkedValue.push({
                            name: scope.linkedOptions[k][scope.activeField.linked_to],
                            value: scope.linkedOptions[k][scope.activeField.linked_to],
                        });
                    }
                }
                scope.activeField.link_type = "Child";
            }

            if ((scope.activeField.fieldType === "TextInput" || scope.activeField.fieldType === "LongTextInput" || scope.activeField.fieldType === "TimePicker" || scope.activeField.fieldType === "DatePicker" || scope.activeField.fieldType === "DatetimePicker") && scope.activeField.is_link && field === "is_link") {
                scope.activeField.link_type = "Child";
            }



            if (field === "is_link" && scope.activeField.is_link == false) {
                if (scope.activeField.link_type == "Parent") {
                    if (scope.activeField[field] == false) {
                        scope.forms[0].fields.forEach((field) => {
                            if (scope.activeField.model == field.linked_to) {
                                field.is_link = false
                                field.link_property = {}
                                field.linked_to = ""
                                field.link_type = ""
                            }
                        });
                    }

                }
                scope.activeField.link_property = {}
                scope.activeField.linked_to = ""
                scope.activeField.link_type = ""


            }

            if (
                scope.activeField.fieldType === "TextInput" &&
                scope.activeField.is_link &&
                field === "linked_to"
            ) {
                scope.linkedValue = [];
                scope.activeField.link_property = "";
                for (let k = 0; k < scope.linkedOptions.length; k++) {
                    if (
                        scope.linkedOptions[k][scope.activeField.linked_to] !== "" &&
                        scope.linkedOptions[k][scope.activeField.linked_to] !== undefined &&
                        scope.linkedOptions[k][scope.activeField.linked_to] !== null
                    ) {
                        scope.linkedValue.push({
                            name: scope.linkedOptions[k][scope.activeField.linked_to],
                            value: scope.linkedOptions[k][scope.activeField.linked_to],
                        });
                    }
                }
            }

            if (scope.activeField.is_link &&
                field === "linked_to") {
                if (scope.activeField.link_type == "Child") {
                    let parent_Data = scope.forms[0].fields.find((field) => {
                        if (scope.activeField.linked_to == field.model) {
                            return field
                        }
                    });
                    if (parent_Data.required == false && scope.activeField.required == true) {
                        scope.activeField["required"] = false;
                    }
                }
            }


            const defaultFields = Form[0].fields.map((value) => {
                return { model: value.model, label: value.label };
            });


            const data = defaultFields.filter(
                (x) =>
                    x.model === scope.activeField.model.toLowerCase() ||
                    x.label === scope.activeField.label.toUpperCase()
            );
            if (data.length === 0 && (field === "label" || field === "model")) {
                scope.validation = scope.validation.filter(
                    (validate_field) => validate_field.path[0] !== field
                );

                scope.activeField[field] = scope.activeField[field].trim();
                scope.activeField[field] = scope.activeField[field].replace(
                    /\.{2,}|_{2,}| {2,}| _|_ |\. | \.|_\.|\._|_+$|\.+$/g,
                    ""
                );

                if (
                    /^.+([a-zA-Z]|[0-9])([\w -]*[a-zA-Z\\._\s].*[^._\s])?$/.test(
                        scope.activeField[field].toLowerCase()
                    )
                ) {
                    if ((field === "label" || field === "model") && scope.activeField.editable == false) {
                        const model = scope.activeField[field].split(" ").join("_");
                        scope.activeField["model"] = model.toLowerCase();
                    }
                }
            } else if (
                data.length === 1 &&
                (scope.activeField.model.toLowerCase() === data[0].model ||
                    scope.activeField.label.toUpperCase() === data[0].label)
            ) {
                if ((field === "label" || field === "model") && scope.activeField.editable == false) {
                    scope.activeField["model"] =
                        scope.activeField["model"].toLowerCase() + 1;
                    scope.activeField[field] = scope.activeField["label"] + 1;
                    scope.validation.push({
                        message: "Duplicate Entry",
                        path: [`${field}`],
                    });
                }
            }
            if (field === "required") {
                scope.activeField["required"] = scope.activeField[field];
                scope.activeField["disabled"] = false;
                scope.activeField["clearable"] = false;


                if (scope.activeField.link_type == "Parent") {
                    if (scope.activeField[field] == false) {
                        scope.forms[0].fields.forEach((field) => {
                            if (scope.activeField.model == field.linked_to) {
                                field.required = false
                            }
                        });
                    }

                }


            }

            if (field === "filter") {
                scope.activeField["filter"] = scope.activeField[field];
                scope.activeField["disabled"] = false;
                scope.activeField["clearable"] = false;
            }
            if (field === "disabled" || field === "clearable") {

                scope.activeField["filter"] = false;
                scope.activeField["required"] = false;
                scope.activeField["clearable"] = false;
            }

            if (field == "unique") {
                scope.activeField["hasDefault"] = false;
            }

            if (scope.formType === "Update") {
                scope.forms.modifiedFields[scope.activeField["SelectList"]] =
                    scope.activeField["model"];
            }
            scope.linkItems = [];
            if (
                scope.activeField.fieldType === "SelectList" &&
                scope.activeField.is_link
            ) {
                scope.forms[0].fields.forEach((field) => {
                    if (
                        field.link_type !== scope.activeField.link_type &&
                        field.model !== scope.activeField.model &&
                        field.link_type &&
                        field.link_type !== "" &&
                        field.is_link
                    ) {
                        scope.linkItems.push({ name: field.model, value: field.model });
                    }
                });
            }
        },
        deleteColumn(column, index, prop) {
            this.$delete(column, index);
            this.activeField.tableDatas.forEach(function (ele) {
                delete ele[prop];
            });
        },
        addColumn(tableColumns) {
            tableColumns.push({
                prop: "",
                label: "",
                width: 180,
            });
        },
        columnWidth(value) {
            if (value && isNaN(value)) {
                this.$message.error("Column width should be a number!");
            }
        },
        confirmForm() {
            this.$refs["OptionsForm"].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    return false;
                }
            });
        },
        getErrorForField(field, errors) {
            if (!errors && !errors.length) {
                return false;
            }
            let filtered = errors.filter((error) => {
                return error.path[0] === field;
            });
            if (filtered.length) {
                return filtered[0].message;
            }
        },
    },
    // beforeDestroy() {
    // this.activeField = [];
    // },
};
</script>
   
<style lang="scss" scoped>
.properties__optionslist {
    margin-bottom: 5px;
    list-style: none;
    list-style-type: none;
}

.properties__optionsul {
    padding: 0;
}

.validation {
    font-size: 11px;
    word-spacing: 3px;
    color: red;
    margin: 0;
}
</style>