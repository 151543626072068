<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    >
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="scriptdata"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.script_name }}</td>
          <td
            class="checkEmpty p0"
            v-if="props.item.script_description != 'NULL'"
          >
            {{ props.item.script_description }}
          </td>
          <td
            class="checkEmpty p0"
            v-if="props.item.script_description == 'NULL'"
          ></td>

          <td class="checkEmpty p0">{{ props.item.type }}</td>

          <!-- <td class="checkEmpty p0">
  <template v-if="props.item.status === 'Ac'">Active</template>
  <template v-else>Inactive</template>
  </td> -->

          <td class="text-xs-center checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Script Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you Sure to delete this script?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog
        persistent
        :value="page.isAddScript"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Script</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="Script" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addScript.payload.script_name"
                      required
                      label="Script_name"
                      type="text"
                      :rules="[(v) => !!v || 'Script_name is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addScript.payload.script_description"
                      required
                      label="Description"
                      type="text"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-radio-group row label="Type :" required>
                      <span style="color: red">*&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <v-radio
                        label="URL"
                        type="radio"
                        color="secondary"
                        value="url"
                        v-on:change="checkbox('URL')"
                      >
                      </v-radio>
                      <v-radio
                        label="Text"
                        type="radio"
                        color="secondary"
                        value="text"
                        v-on:change="checkbox('TEXT')"
                      >
                      </v-radio>
                    </v-radio-group>
                    <div v-if="page.demo">
                      <v-flex xs12>
                        <v-text-field
                          v-model="addScript.payload.value"
                          :rules="URLrules"
                          required
                          color="secondary"
                          label="URL"
                          type="url"
                        >
                        </v-text-field>
                      </v-flex>
                    </div>
                    <div v-if="page.sample">
                      <v-flex xs12>
                        <v-textarea
                          v-model="addScript.payload.value"
                          color="secondary"
                          placeholder="Text here..."
                        >
                        </v-textarea>
                      </v-flex>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddScript()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddScript()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        persistent
        :value="page.isEditScript"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">update Script</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="Scripts">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editScript.script_description"
                      label="Description"
                      type="text"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-if="page.checkurl"
                      v-model="editScript.value"
                      :rules="URLrules"
                      label="URL"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-if="page.checktext"
                      v-model="editScript.value"
                      label="Text"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="editScript.status"
                      required
                      label="Status"
                      :items="status"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer
      fixed
      disable-resize-watcher
      right
      v-model="page.isFilterOpen"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>asda
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <!-- <v-select
  :items="newArray"
  v-model="filter.payload.script_name"
  label="Script_name"
  small
  ></v-select> -->

            <v-autocomplete
              v-model="filter.payload.script_name"
              :items="filtervalue"
              :loading="filter.isLoading"
              :search-input.sync="filter.search"
              label="Name"
              hide-no-data
              hide-selected
            ></v-autocomplete
            >asasd {{ filter.payload.script_name }}{{ filter.search }}
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="Reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
 <script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },

      page: {
        title: "script",
        icon: "fa-file-alt fa-2x",
        loading: false,
        isAddScript: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditScript: false,
        temp: { key: "addscript", label: "Add Script" },
        candelete: false,
        canedit: false,
        demo: false,
        sample: false,
        checkurl: false,
        checktext: false,
        deletescript_name: "",
      },

      pagecount: 0,
      headers: [
        {
          text: "Script Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "script_name",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Type",
          align: "left",
          class: "p0",
          sortable: false,
          value: "type",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      URLrules: [(v) => /^(http|https):/.test(v) || "URL must be valid"],

      scriptdata: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      newArray: [],
      filter: {
        payload: {
          script_name: "",
        },
        isLoading: false,
        search: "",
      },

      isProcessing: false,
      call_status: [],
      editScript: {
        id: "",
        script_description: "",
        value: "",
        status: "",
      },
      addScript: {
        payload: {
          script_name: "",
          script_description: "",
          type: "",
          value: "",
          status: "ACTIVE",
        },
      },

      status: ["ACTIVE", "INACTIVE"],
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      this.pagination.page = 1;

      scope.getDataFromApi();
    },
    checkbox: function (id) {
      if (id == "URL") {
        let scope = this;
        scope.addScript.payload.type = id;
        scope.page.demo = true;
        scope.page.sample = false;
      } else {
        let scope = this;
        scope.addScript.payload.type = id;
        scope.page.sample = true;
        scope.page.demo = false;
      }
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
    },
    filterFieldOptions() {
      let scope = this;

      let data = { model: "scripts", column: ["script_name"] };

      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.filtervalue = datas[0].script_name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    Reset() {
      var scope = this;
      scope.filter.payload.script_name = "";
      this.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;

      this.page.isFilterOpen = false;

      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      /*let query = {
  skip: skip,
  take: take,
  };*/
      scope.filter.payload["skip"] = skip;
      scope.filter.payload["take"] = take;
      this.skip = skip;
      this.$store
        .dispatch("GetScript", scope.filter.payload)
        .then((response) => {
          this.pagecount = response.count;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          // if (datas !== "" && datas !== undefined && datas !== null) {
          // datas.forEach((element) => {
          // scope.filtervalue.push(element["script_name"]);
          // });
          // }
          // var absent = a1. filter(e=>! a2. includes(e));
          let a = JSON.parse(localStorage.getItem("script_name"));
          scope.newArray = scope.filtervalue.filter((e) => a.includes(e));
          scope.scriptdata = datas;
          scope.filter.payload.script_name = "";
        })
        .catch(function (error) {
          scope.page.isAddScript = false;
          scope.showSnackBar("error" + error);
          this.page.isEditScript = false;
        });
    },
    AddNewRule: function () {
      //var scope = this;
      this.page.isAddScript = true;
    },
    closeEditRules: function () {
      this.page.isEditScript = false;
    },
    closeAddScript: function () {
      this.page.isAddScript = false;
      this.$refs.Script.reset();
    },
    saveEditRules() {
      if (this.$refs.Scripts.validate()) {
        var scope = this;
        scope.$store
          .dispatch("updateScript", scope.editScript)
          .then(() => {
            scope.page.isAddScript = false;
            scope.showSnackBar("Updated Successfully");
            this.getDataFromApi();
            this.page.isEditScript = false;
          })
          .catch(function (error) {
            scope.page.isAddScript = false;
            scope.showSnackBar("error" + error);
            this.page.isEditScript = false;
          });
      }
    },
    saveAddScript() {
      if (this.$refs.Script.validate()) {
        var scope = this;
        scope.$store
          .dispatch("insertScript", scope.addScript.payload)
          .then(() => {
            scope.page.isAddScript = false;
            scope.showSnackBar("Created Successfully");
            this.$refs.Script.reset();
          })
          .catch(function (error) {
            let check_status = error.toString();
            if (check_status.includes("409")) {
              scope.showSnackBar("Error: Script name already exist");
            }
            scope.page.isAddScript = false;
            //scope.showSnackBar("error" + error);
          });
        this.getDataFromApi();
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //var scope = this;
      this.page.isEditScript = true;
      this.editScript.id = item.id;
      this.editScript.script_description = item.script_description;
      this.editScript.status = item.status;
      // this.editScript.value = item.description;

      if (item.type == "URL") {
        this.page.checkurl = true;
        this.page.checktext = false;
        this.editScript.value = item.value;
      } else if (item.type == "TEXT") {
        this.page.checkurl = false;
        this.page.checktext = true;
        this.editScript.value = item.value;
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteScript", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.page.loading = true;
            scope.getDataFromApi();
            scope.showSnackBar("Script Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:script:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Script" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:script:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:script:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
    this.filterFieldOptions();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = { model: "scripts", column: ["script_name"], search: val };

        scope.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            scope.filtervalue = datas[0].script_name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
 <style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>