<template>
  <div>
    <v-container fluid="true">
      <WorkflowDetails  @backpage="Backpage" @CreateNotifiy="CreateData"
        @UpdateNotifiy="UpdateNotifiy" :Flag="flag">
      </WorkflowDetails>
    </v-container>
    </div>
</template>
<script>
import WorkflowDetails from "@/views/Configurations/notification/WorkflowDetails.vue";
export default {
  components: {
   
    WorkflowDetails
  },
  data() {
    return {
    }
  },
  mounted() {
    var scope = this;
    scope.page.loading = true
    scope.page.Notifiy_view = true
    scope.Getdatafromapi()
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:notification:create"
          ) !== -1
        ) {
          scope.page.canadd = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:notification:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:notification:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
  },
  methods: {


  },
  watch: {
    pagination: {
      handler() {
        let scope = this
        if (scope.pagination.page > 1 || scope.pagelimt.skip >= 10) {
          scope.Getdatafromapi()
        }
      },
      deep: true,
    },
  }
}
</script>