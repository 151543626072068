var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.renderComponent)?_c('div',{class:!_vm.value_cls ? _vm.lds_ellipsis : _vm.edit_ellipsis},[_c('div'),_c('div'),_c('div'),_c('div')]):_c('div',{class:_vm.value_cls ? _vm.preview_container : _vm.preview_container1},[_c('v-form',{ref:"form"},_vm._l((_vm.catagory),function(catagory,i){return _c('div',{key:i,class:_vm.value_cls ? _vm.sectionFlex1 : _vm.sectionFlex},[_c('div',{staticClass:"catagory_title"},[_c('div',{staticClass:"catagory_title_lfy"},[_c('i',{staticClass:"fa fa-bandcamp",staticStyle:{"padding":"3px 6px 3px 3px"},attrs:{"aria-hidden":"true"}}),_c('div',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(catagory))])]),(_vm.uploadoption)?_c('div',[_c('input',{ref:"fileInput",refInFor:true,staticClass:"custom-file-input",attrs:{"type":"file"},on:{"change":_vm.onFileSelected}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.imagePath),expression:"imagePath"}],attrs:{"type":"text"},domProps:{"value":(_vm.imagePath)},on:{"input":function($event){if($event.target.composing)return;_vm.imagePath=$event.target.value}}})]):_vm._e()]),(_vm.main_data[i] !== undefined)?_c('div',[_c('div',{staticClass:"component_flex"},[(_vm.value_cls)?_c('div',{staticClass:"component_setup"},_vm._l((_vm.main_data[i].filter((res) => {
                            if (
                                res.model != 'lead_id' &&
                                res.model != 'hopper_status' && res.model != 'id' &&
                                res.model != 'user'
                            ) {
                                if (res.model === 'list_id') {
                                    res.input_type = 'Select';
                                    res.values = _vm.add_list;
                                    res.fieldType = 'SelectList';
                                    res.disabled = false;
                                }
                                if (res.model === 'tags') {
                                    res.input_type = 'Select';
                                    res.values = _vm.tags;
                                    res.fieldType = 'SelectList';
                                    res.disabled = false;
                                }
                                res.disabled = false;
                                // if (page_ == true) {
                                // if (res.model === 'phone_number') {
                                // res.input_type = 'Select';
                                // // res.values = [{name:'1',value:'1'}];
                                // res.fieldType = 'SelectList';
                                // res.apifetch = true;
                                // res.apidata = null;
                                // }
                                // }

                            }
                            return (
                                ((res.is_link && res.link_type === 'Parent') ||
                                    (!res.is_link &&
                                        res.model != 'modified_date' &&
                                        res.model != 'lead_id' &&
                                        res.model != 'list_id' &&
                                        res.model != 'hopper_status' && res.model != 'id' &&
                                        res.model != 'subdisposition' &&
                                        res.model != 'deleted' &&
                                        res.model != 'disposition' &&
                                        res.model != 'user' &&
                                        res.model != 'ticket_id' &&
                                        res.model != 'contact_id' &&
                                        res.model != 'created_at' &&
                                        res.model != 'modified_at' &&
                                        res.model !== 'assign' &&
                                        res.model !== 'modified_by' &&
                                        res.model !== 'created_by' &&
                                        res.model !== 'is_merge' &&
                                        res.model !== 'is_duplicate' &&
                                        res.model !== 'merged_to' &&
                                        res.model !== 'merged_as' &&
                                        res.model !== 'is_merge' &&
                                        res.model !== 'req_id' &&
                                        res.model !== 'req_module')) && res.show_on_view == true
                            );
                        })),function(field,eachFormIndex){return _c('div',{key:'top' + eachFormIndex},[_c('div',{key:`row-${eachFormIndex}`},[_c('div',[_c(field.fieldType,{tag:"component",attrs:{"currentField":field,"Allfields":_vm.main_data[i]}})],1)])])}),0):_c('div',{staticClass:"component_setup"},_vm._l((_vm.main_data[i].filter((res) => {
                            if (
                                res.model != 'lead_id' &&
                                res.model != 'hopper_status' && res.model != 'id' &&
                                res.model != 'user'
                            ) {
                                if (res.model === 'list_id') {
                                    res.input_type = 'Select';
                                    res.values = _vm.add_list;
                                    res.fieldType = 'SelectList';
                                    res.disabled = false;
                                }
                                if (res.model === 'assign') {
                                    res.input_type = 'Select';
                                    res.values = _vm.user_data;
                                    res.fieldType = 'SelectList';
                                    res.disabled = false;
                                }
                                if (res.model === 'tags') {
                                    res.input_type = 'Select';
                                    res.values = _vm.tags;
                                    res.fieldType = 'SelectList';
                                    res.disabled = false;
                                }
                                res.disabled = false;
                                // if (page_ == true) {
                                // if (res.model === 'phone_number') {
                                // res.input_type = 'Select';
                                // // res.values = [{name:'1',value:'1'}];
                                // res.fieldType = 'SelectList';
                                // res.apifetch = true;
                                // res.apidata = null;
                                // }
                                // }
                                // res.disabled = false;
                            }
                            return (
                                ((res.is_link && res.link_type === 'Parent') ||
                                    (!res.is_link &&
                                        res.model != 'modified_date' &&
                                        res.model != 'lead_id' &&
                                        res.model != 'hopper_status' && res.model != 'id' &&
                                        res.model != 'subdisposition' &&
                                        res.model != 'disposition' &&
                                        res.model != 'deleted' &&
                                        res.model != 'user' &&
                                        res.model != 'ticket_id' &&
                                        res.model != 'contact_id' &&
                                        res.model != 'created_at' &&
                                        res.model != 'modified_at' &&
                                        res.model !== 'modified_by' &&
                                        res.model !== 'created_by' &&
                                        res.model !== 'is_merge' &&
                                        res.model !== 'is_duplicate' &&
                                        res.model !== 'merged_to' &&
                                        res.model !== 'merged_as' &&
                                        res.model !== 'is_merge' &&
                                        res.model !== 'req_id' &&
                                        res.model !== 'req_module')) && res.show_on_view == true
                            );
                        })),function(field,eachFormIndex){return _c('div',{key:eachFormIndex},[_c('div',{key:`row-${eachFormIndex}`},[_c('div',[_c(field.fieldType,{tag:"component",attrs:{"currentField":field,"Allfields":_vm.main_data[i]}})],1)])])}),0),_c('div',{staticClass:"component_setup"},[_vm._l((_vm.main_data[i].filter(
                            (res) =>
                                res.is_link &&
                                res.link_type === 'Child' &&
                                (res.fieldType === 'SelectList' ||
                                    res.fieldType === 'RadioButton')
                        )),function(field,eachFormIndex){return _c('div',{key:'down' + eachFormIndex},[_c('div',{key:`row-${eachFormIndex}`},[_c('div',[_c(field.fieldType,{tag:"component",attrs:{"currentField":field,"Allfields":_vm.main_data[i]}})],1)])])}),_vm._l((_vm.main_data[i].filter(
                            (res) =>
                                res.is_link &&
                                res.link_type === 'Child' &&
                                res.fieldType != 'SelectList' &&
                                res.fieldType != 'RadioButton' &&
                                res.visible
                        )),function(field,eachFormIndex){return _c('div',{key:eachFormIndex},[_c('div',{key:`row-${eachFormIndex}`},[_c('div',[_c(field.fieldType,{tag:"component",attrs:{"currentField":field,"Allfields":_vm.main_data[i]}})],1)])])})],2)])]):_vm._e()])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }