import config from "@/config/index";
import api from '../../utils/axios-util';
import {QUEUESTATUS,NULLVALUES} from "../mutation-types";
const state = config.queuestate;
var getters = {
  queuestatus: state => state.queuestatus
}
const mutations = {
  [QUEUESTATUS]: (state, payload) => {
    state.queuestatus = payload.data.data;
  },
  [NULLVALUES]: (state) => {
    state.queuestatus = [];
  }
};
const actions = {
  queuestatusaction: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        api.get("/realtime/queueStatus").then(response => {
          var queue_response = response;
          commit(QUEUESTATUS, queue_response);
          resolve(response);
        }).catch(function (error) {
          commit(QUEUESTATUS, error);
          reject(error);
        });
      } catch (error) {
        commit(QUEUESTATUS, error);
        reject(error);
      }
    })
  },
  emptycampaignstatus: ({ commit }) => {
    commit(NULLVALUES);
    clearTimeout(setTimeout);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};