/* eslint-disable */
import config from "@/config/index";
import { reject } from "lodash";
import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
let Crypto = require("crypto");
const state = config.userManagement;
import auth0Variables from '../../utils/auth0-variables';
import {encoding , decoding} from '../../utils/EncodeDecode'
const auth0Config = auth0Variables();
const common_api_url = auth0Config.common_api_url;
let security_key = auth0Config.cryptosecuritykey;
let secret_iv = "smlt";
let method = 'AES-256-CBC'
let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)

// function encrypt(text, method, key, iv) {
//     let encrypt = Crypto.createCipheriv(method, key, iv)
//     let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
//     return Buffer.from(convert).toString("base64")
// }
var getters = {
    usersdata: state => state.userManagement.usersdata,
    UserType: state => state.userManagement.UserType,
    userManagementFilter: state => state.userManagement.filter,
    QueueList: state => state.userManagement.QueueList,
    Createqueue: state => state.userManagement.Createqueue,
    AssignQueueList: state => state.userManagement.AssignQueueList
}
const mutations = {
    [mutation_type.userManagement]: (state, response) => {
        let usersdata = response.data.data;
        if (usersdata !== undefined) {
            usersdata = usersdata.map(userdata => {
                userdata.selectedroles = userdata.roles.map(role => {
                    return role.id;
                })
                return userdata;
            });
            state.userManagement.usersdata = usersdata;
        } else {
            state.userManagement.usersdata = [];
        }
    },
    [mutation_type.Createqueue]: (state, response) => {
        state.userManagement.Createqueue = response;
    },
    [mutation_type.UserType]: (state, response) => {
        state.userManagement.UserType = response;
    },
    [mutation_type.QueueList]: (state, response) => {
        state.userManagement.QueueList = response.data.data;
    },
    filter_userId: (state, response) => {
        state.userManagement.filter.UserId = response.user_id;
    },
    filter_Role: (state, response) => {
        state.userManagement.filter.Role = response.role;
    },
    filter_EmailId: (state, response) => {
        state.userManagement.filter.EmailId = response.user_email;
    },
    filter_MobileNumber: (state, response) => {
        state.userManagement.filter.MobileNumber = response.user_phone_number;
    }
};
const actions = {
    userManagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                // let security_key = "raghavan";
                // let secret_iv = "smlt";
                // let method = 'AES-256-CBC'
                // let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
                // let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)
                

                // function encrypt(text, method, key, iv) {
                //     let encrypt = Crypto.createCipheriv(method, key, iv)
                //     let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
                //     return Buffer.from(convert).toString("base64")
                // }
                
                // let filterRoleArr = [];
                // let filtermailArr = [];
                // let encrypt_filterUserId;
                let phno = state.userManagement.filter.MobileNumber != "" ? state.userManagement.filter.MobileNumber : 0;

                let params={
                    user_email:  state.userManagement.filter.EmailId,
                    user_phone_number: phno,
                    user_id:state.userManagement.filter.UserId,
                    role: state.userManagement.filter.Role,
                    take:payload.take,
                    skip:payload.skip
                }
                params = encoding(params)
               

                api.get(`${common_api_url}/tenantUser?user_email=${params.user_email}&user_phone_number=${params.user_phone_number}&user_id=${params.user_id}&limit=${params.take}&offset=${params.skip}&role=${params.role}`).then(response => {
                    if(response.status == 200){
                        response.data.data = decoding(response.data.data)
                        console.log(response.data.data)
                    }
                    commit(mutation_type.userManagement, response);
                    resolve(response);
                }).catch(function(error) {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    },

    // unused service
    filteruserManagement: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let phno = state.userManagement.filter.MobileNumber != "" ? state.userManagement.filter.MobileNumber : 0;
                let params={
                    user_email:  state.userManagement.filter.EmailId,
                    user_phone_number: phno,
                    user_id:state.userManagement.filter.UserId,
                    role: state.userManagement.filter.Role
                }
                params = encoding(params)
                api.get(`${common_api_url}/tenantUser?user_email=${params.user_email}&user_phone_number=${params.user_phone_number}&user_id=${params.user_id}&role=${params.role}`).then(response => {
                    var userManagement_response = response;
                    commit(mutation_type.userManagement, userManagement_response);
                    resolve(response);
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        })
    },
    UserType: ({ commit }) => {
        try {
            api.get(`${common_api_url}/roles`).then(response => {
                var UserType_response = response.data.data;
                var UserType_response = UserType_response.map(res => {
                    return { id: res.id, name: res.name };
                })
                commit(mutation_type.UserType, UserType_response);
            }).catch(function (error) {

            })
        } catch (error) {

        }
    },
    QueueList: ({ commit }) => {
        return new Promise((resolve,) => {
            try {
                api.get(`${common_api_url}/queue`)
                    .then(response => {
                        if(response.status == 200){
                            var QueueList_response = response;
                            commit(mutation_type.QueueList, QueueList_response);
                        }
                        resolve(response);
                    }).catch(function (error) {
                        reject(error);
                    });
            } catch (error) {

            }
        })
    },
    getUserQueue: ({ }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                // let payloadata = encrypt(payload, method, key, iv)
                let payloadata =  encoding(payload)
                api.get(`${common_api_url}/queue/users?name=${payloadata}`).then(response => {
                    if (response.status == 200) {
                        let res = decoding(response.data.data);
                        resolve(res);
                    } else if (response.status == 204) {
                        var d = {
                            id: '',
                            name: payload,
                            interface: "",
                            user_rank: 0,
                            queue: []
                        };
                        resolve(d);
                    } else {
                        let error = "Invalid Response";
                        reject(error);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {

            }
        })
    },
    getUserQueueTenant: ({ }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                // let payloadata = encrypt(payload, method, key, iv)
                let payloadata =  encoding(payload)
                api.get(`${common_api_url}/queue/users?queue=${payloadata}`).then(response => {
                    if (response.status == 200) {
                        response.data.data = decoding(response.data.data);
                        resolve(response);
                    } else if (response.status == 204) {
                        resolve(response);
                    } else {
                        let error = "Invalid Response";
                        reject(error);
                    }
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {

            }
        })
    },
    createQueueUser: ({}, payload) => {
        return new Promise((resolve, reject) => {
            var Queue_assign = {
                data: [{
                    "name": payload.name, 
                    "interface":payload.interface,
                    "queue": payload.queue
                }]
            }
            Queue_assign=  encoding(Queue_assign)
           
            // let security_key = "raghavan";
            // let secret_iv = "smlt";
            // let method = 'AES-256-CBC' //algorithm
            // let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
            // let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)
            // let text = "dinesh"
    
            // function encrypt(text, method, key, iv) {
            //     let encrypt = Crypto.createCipheriv(method, key, iv)
            //     let convert = encrypt.update(text, 'utf8', 'baseencrypt64') + encrypt.final('base64')
            //     return Buffer.from(convert).toString("base64")
            // }

            // let encrypt_name = encrypt(payload.name,method,key,iv)
            // let encrypt_interface = encrypt(payload.interface,method,key,iv)
            // let str_user_rank = payload.interface;
            // let encrypt_user_rank = encrypt(str_user_rank.toString(),method,key,iv)
            // let encrypt_queue = encrypt(payload.queue,method,key,iv)

            // let encrypt_Queue_assign = {
            //     "name": encrypt_name,
            //     "interface": encrypt_interface,
            //     "user_rank": encrypt_user_rank,
            //     "queue": encrypt_queue
            // }

            // api.post(`${common_api_url}/queue/user/assign`, Queue_assign).then(response => {
            api.post(`${common_api_url}/queue/user/assign`, Queue_assign).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        })
    },
    DeleteQueueUser: ({ }, payload) => {
        return new Promise((resolve, reject) => {
            api.delete(`${common_api_url}/queue/user/${payload}`).then(response => {
                if (response.status == 200) {
                    resolve(response);
                } else {
                    reject('Error');
                }
            }).catch(error => {
                reject(error);
            })
        })
    },
    UpdateQueueUser: ({}, payload) => {
      
        var updateItem = {
            "name": payload.name,
            "interface": payload.interface,
            "user_rank": payload.user_rank,
            "queue": payload.queue
        }
        return new Promise((resolve, reject) => {
            api.put(`${common_api_url}/queue/user/${payload.id}`, updateItem).then(response => {
                resolve(response);
            }).catch(function update(error) {
                reject(error.response);
            })
        })
    },
    editedItemuser: ({}, payload) => {
        console.log(payload)
        var payloads = {
            "user_id": payload.user_id,
            "name": payload.name,
            "user_email": payload.user_email,
            "user_phone_number": payload.user_phone_number,
            "roles": [payload.roles],
            "project_id": payload.project_id.toString(),
            "status": payload.status
        }
        payloads.groups = payload.groups;
        // payloads =encoding(payloads)

        // let security_key = "raghavan";
        // let secret_iv = "smlt";
        // let method = 'AES-256-CBC' //algorithm
        // let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
        // let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)
        // let text = "dinesh"

        // function encrypt(text, method, key, iv) {
        //     let encrypt = Crypto.createCipheriv(method, key, iv)
        //     let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
        //     return Buffer.from(convert).toString("base64")
        // }

        // let group_arr = [];
        // let role_arr = [];
        // let encrypt_user_id = encrypt(payloads.user_id,method,key,iv);
        // let encrypt_name = encrypt(payloads.name,method,key,iv);
        // let encrypt_user_email = encrypt(payloads.user_email,method,key,iv);
        // let encrypt_user_phone_number = encrypt(payloads.user_phone_number,method,key,iv);
        // let str_project_id =  payloads.project_id;
        // let encrypt_project_id = encrypt(str_project_id.toString(),method,key,iv);
        
        // let encrypt_status ;
        // if(payloads.status === undefined ){
        //     encrypt_status = undefined ;     
        // }else{
        //     encrypt_status = encrypt(payloads.status,method,key,iv)
        // }

        // for(let i=0; i<payloads.groups.length; i++){

        //     let str_group_id = payloads.groups[i].group_id;
        //     let group_id = encrypt(str_group_id.toString(),method,key,iv);
        //     let group_name = encrypt(payloads.groups[i].group_name,method,key,iv);
        //     let group_obj = {};
        //     group_obj["group_id"] = group_id;
        //     group_obj["group_name"] = group_name;
        //     group_arr.push(group_obj);
        // }
        // for(let i=0; i<payloads.roles.length; i++){
        //     let role_id = encrypt(payloads.roles[i].id,method,key,iv);
        //     let role_name = encrypt(payloads.roles[i].name,method,key,iv);
        //     let rolesobj = {};
        //     rolesobj["id"] = role_id;
        //     rolesobj["name"] = role_name;
        //     role_arr.push(rolesobj);
        // }

      
        // let encrypt_payload = {
            
        //     "user_id": encrypt_user_id,
        //     "name": encrypt_name,
        //     "user_email": encrypt_user_email,
        //     "user_phone_number": encrypt_user_phone_number,
        //     "roles": role_arr,
        //     "project_id": encrypt_project_id,
        //     "status": encrypt_status,
            
        // }
        // encrypt_payload.groups = group_arr;

        return new Promise((resolve, reject) => {
            console.log(payloads)
            var dat = { data: payloads }
            dat = encoding(dat)
            let params={
                id:payload.id,
                user_email:payload.user_email
            }
            params = encoding(params)
            api.put(`${common_api_url}/projectUsers?id=${params.id}&user_email=${params.user_email}`, dat).then(response => {
                resolve(response);
            })
                .catch(function edit(error) {
                    reject(error.response);
                })
        })
    },
    newlyUser: ({dispatch}, payload) => {
        var userdata = {
            "project": {
                "id": localStorage.getItem("projectId")
            },
            "user": [{
                "user_email": payload.user_email,
                "user_phone_number": payload.user_phone_number,
                "user_id": payload.user_id,
                "name": payload.name,
                "did_number":payload.didNumber,
                "auth": {
                    "password": payload.password,
                    "roles": [payload.roles]
                }
            }]
        }
        userdata.user[0].groups = payload.groups;
        userdata =encoding(userdata)
//////
        // let security_key = "raghavan";
        // let secret_iv = "smlt";
        // let method = 'AES-256-CBC' //algorithm
        // let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
        // let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)
        // // let text = "dinesh"

        // function encrypt(text, method, key, iv) {
        //     let encrypt = Crypto.createCipheriv(method, key, iv)
        //     let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
        //     return Buffer.from(convert).toString("base64")
        // }
        // let authObj = {};
        // let role_arr = [];
        // let user_obj = [];
        // let group_arr = [];
        // let project_id = encrypt(userdata.project.id, method, key, iv);
        // let user_array = userdata.user[0]
        // let auth = user_array.auth.password;
        // let auth_pass = encrypt(auth, method, key, iv);
        // for (let i = 0; i < user_array.auth.roles.length; i++) {
        //     let role_id = encrypt(user_array.auth.roles[i].id, method, key, iv);
        //     let role_name = encrypt(user_array.auth.roles[i].name, method, key, iv);
        //     let obj = {};
        //     obj["id"] = role_id
        //     obj["name"] = role_name;
        //     role_arr.push(obj)
        // }
        // for (let i = 0; i < user_array.groups.length; i++) {

        //     let group_id = encrypt(toString(user_array.groups[i].group_id), method, key, iv);
        //     let group_name = encrypt(user_array.groups[i].group_name, method, key, iv);
        //     let obj = {};
        //     obj["group_id"] = group_id
        //     obj["group_name"] = group_name;
        //     group_arr.push(obj)
        // }

        // let user_name = encrypt(user_array.name, method, key, iv);
        // let user_email = encrypt(user_array.user_email, method, key, iv);
        // let user_id = encrypt(user_array.user_id, method, key, iv);
        // let user_phone_number = encrypt(user_array.user_phone_number, method, key, iv);
        // authObj["password"] = auth_pass;
        // authObj["roles"] = role_arr;

        // user_obj["auth"] = authObj;

        // let user_group = {};
        // user_group["groups"] = group_arr;
        // user_obj["name"] = user_name;
        // user_obj["user_email"] = user_email;
        // user_obj["user_id"] = user_id;
        // user_obj["user_phone_number"] = user_phone_number;

        // var userdata1 = {
        //     "project": {
        //         "id": project_id
        //     },
        //     "user": [{
        //         "user_email": user_email,
        //         "user_phone_number": user_phone_number,
        //         "user_id": user_id,
        //         "name": user_name,
        //         "auth": authObj
        //     }]
        // }
        // userdata1.user[0].groups = group_arr;
      
        // console.log(encoding(userdata))
        
        /////
        return new Promise((resolve, reject) => {
            api.post(`${common_api_url}/projectUsers`, userdata).then(response => {
                let permissionInfo = JSON.parse(localStorage.getItem('permissionInfo'));
                    dispatch("getGroupUserLists", permissionInfo.group_id);
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error.response);
                })
        })
    },
    did_user:({},payload)=>{
        api.post(`/user/did`,payload).then((resopnse)=>{
            return resopnse
        })
    },
    userDidFetch: async ({}, payload) => {
        try {
            const response = await api.get(`/user/did/${payload}`);
            console.log(response, 'this is first data'); 
            return response;
        } catch (error) {
            throw error; 
        }
    },
    userDidEdit:({},payload)=>{
        const didData = {
            station:payload.user_phone_number,
            agent:payload.user_email,
            did_number:payload.didNumber
        }
        api.put(`/user/did/${payload.user_email}`,didData)
        .then((response)=>{
            console.log(response,'this is response')
        })
    },
    DeleteRow: ({ }, payload) => {
        var deleted_id = {
            "id": payload.id
        }
        var deletes = { data: encoding(deleted_id) }
        return new Promise((resolve) => {
            api.delete(`${common_api_url}/deleteUser`, deletes).then(response => {
                resolve(response);
            })
        })
    },
    EditPassword: ({ }, payload) => {
        try {
            return new Promise((resolve, reject) => {

                // let security_key = "raghavan";
                // let secret_iv = "smlt";
                // let method = 'AES-256-CBC'
                // let key = Crypto.createHash('sha512').update(security_key, 'utf-8').digest('hex').substring(0, 32)
                // let iv = Crypto.createHash('sha512').update(secret_iv, 'utf-8').digest('hex').substring(0, 16)
                

                // function encrypt(text, method, key, iv) {
                //     let encrypt = Crypto.createCipheriv(method, key, iv)
                //     let convert = encrypt.update(text, 'utf8', 'base64') + encrypt.final('base64')
                //     return Buffer.from(convert).toString("base64")
                // }
                let params ={
                    id:encoding(payload.id),
                    user_email: encoding(payload.user_email),
                    ConfirmPassword:encoding(payload.ConfirmPassword)
                }
                api.put(`${common_api_url}/userPassword?id=${params.id}&user_email=${params.user_email}`, { "password": params.ConfirmPassword })
                    .then(response => {
                      
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error.response.data);
                    })
            })
        } catch (err) {
            reject(err);
        }
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}