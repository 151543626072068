<template>
    <div>
        <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :addNewData="page.addnew"
            @addNew="AddNewRule" @refreshPage="refreshThisPage" :style="styleObject"></section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table :headers="headers" :items="Channeldata" class="elevation-1 fixed-layout"
                :pagination.sync="pagination" :loading="page.tableLoading" :total-items="pagecount">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty p0">{{ props.item.name }}</td>
                    <td class="checkEmpty p0">{{ props.item.description }}</td>
                    <td class="checkEmpty p0">
                        <template v-if="props.item.active === 0">Active</template>
                        <template v-else>Inactive</template>
                    </td>
                    <td class="text-xs-center checkEmpty p0">
                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete"
                            @click="deleteItem(props.item.id)">delete</v-icon>
                    </td>
                </template>
            </v-data-table>
        </v-container>


        <v-dialog persistent :value="isAddPop" max-width="500px">
            <v-form ref="isAddCreate" lazy-validation>
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Create Channel</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field v-model="AddData.name" required label="Name" type="text"
                                    :rules="Namerule"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="AddData.description" required label="Description"
                                    type="text"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="isAddPop = false">Close</v-btn>
                        <v-btn color="primary" @click="CreateData">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>


        <v-dialog persistent :value="isEditPop" max-width="500px">
            <v-form ref="isEditUpdate" lazy-validation>
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Update Channel</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field v-model="EditData.name" required label="Name" type="text"
                                    :rules="Namerule"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="EditData.description" required label="Description"
                                    type="text"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-select v-model="EditData.active" required label="Status" :items="status" item-text="name"
                                    item-value="value"></v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="isEditPop = false">Close</v-btn>
                        <v-btn color="primary" @click="UpdateData">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>


        <v-dialog persistent v-model="isDeletePop" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Channel</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you Sure?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="isDeletePop = false">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import loader from "@/components/Widgets/loader";
export default {
    components: {
        loader,
    },

    data() {
        return {
            Namerule: [(v) => !!v || 'Name is required'],
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            page: {
                title: "Channel",
                icon: "fa-clipboard fa-2x",
                loading: false,
                tableLoading: false,
                options: { filter: false, refresh: true },
                exportOptions: [],
                addnew: [],
                candelete: false,
                canedit: false,
            },
            headers: [
                {
                    text: "Name",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "name",
                },
                {
                    text: "Description",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "description",

                },
                {
                    text: "Status",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Action",
                    align: "left",
                    sortable: false,
                    value: "btn",
                    width: "20%",
                }
            ],
            pagination: {
                page: 1,
                rowsPerPage: 10,
            },
            isAddPop: false,
            AddData: {
                name: "",
                description: "",
                active: 0
            },
            Channeldata: [],
            isEditPop: false,
            EditData: {
                id: "",
                name: "",
                description: "",
                active: 0
            },
            status: [{ name: "Active", value: 0 }, { name: "Inactive", value: 1 }],
            isDeletePop: false,
            isProcessing: false,
            delete_id: '',
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            pagecount: 0,
            pagelimt: {
                skip: 0,
                take: 0
            }
        }
    },
    methods: {
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        getDataFromApi() {
            var scope = this;
            scope.page.tableLoading = true
            var { page, rowsPerPage } = scope.pagination;
            if (rowsPerPage == -1) {
                scope.pagelimt.take = scope.pagecount;
            } else {
                scope.pagelimt.take = rowsPerPage;
            }
            if (page != "" && page != undefined && page != null) {
                scope.skip = page;
            } else {
                scope.skip = 1;
            }
            scope.pagelimt.skip = (scope.skip - 1) * scope.pagelimt.take;
            scope.$store
                .dispatch("GetChannel",scope.pagelimt)
                .then((response) => {
                    console.log(response)
                    scope.page.loading = false;
                    scope.page.tableLoading = false
                    if (response.status == 204) {
                        scope.Channeldata = [];
                    } else {
                        scope.pagecount = response.data.count
                        scope.Channeldata = response.data.data;
                        scope.AddData.name = ""
                        scope.AddData.description = ""
                        scope.AddData.active = 0
                    }

                })
                .catch(function (error) {
                    scope.showSnackBar("error" + error);
                });
        },
        AddNewRule() {
            let scope = this
            scope.isAddPop = true
        },
        CreateData() {
            let scope = this
            let isvalid = scope.$refs.isAddCreate.validate()
            if (isvalid) {
                scope.isProcessing = true
                scope.$store
                    .dispatch("CreateChannel", scope.AddData)
                    .then(() => {
                        scope.isAddPop = false;
                        scope.showSnackBar("Created Successfully");
                        scope.AddData.name = "";
                        scope.AddData.description = "";
                        scope.isProcessing = false
                        scope.$refs.isAddCreate.reset()
                        scope.getDataFromApi();
                    })
                    .catch(function (error) {
                        scope.isAddPop = false;
                        scope.isProcessing = false
                        if (error.statusCode == 409) {
                            scope.showSnackBar(error.message);
                        } else {
                            scope.showSnackBar("error" + error);
                        }

                    });
                
            }

        },
        editItem(data) {
            let scope = this
            scope.EditData.id = data.id
            scope.EditData.name = data.name
            scope.EditData.description = data.description
            scope.EditData.active = data.active
            scope.isEditPop = true
        },
        UpdateData() {
            let scope = this
            let isvalid = scope.$refs.isEditUpdate.validate()
            if (isvalid) {
                scope.isProcessing = true
                scope.$store
                    .dispatch("UpdataChannel", scope.EditData)
                    .then((res) => {
                        if (res.status == 200) {
                            scope.isEditPop = false;
                            scope.getDataFromApi();
                            scope.showSnackBar("Updated Successfully");
                            scope.EditData.id = ""
                            scope.EditData.name = ""
                            scope.EditData.description = ""
                            scope.EditData.active = ""
                            scope.isProcessing = false
                            scope.$refs.isEditUpdate.reset()
                        } else {
                            scope.showSnackBar("Failed to Updated");
                        }
                    })
                    .catch(function (error) {
                        scope.isEditPop = false;
                        scope.isProcessing = false
                        scope.showSnackBar(error.message);
                    });
                
            }
        },
        deleteItem(data) {
            let scope = this
            scope.isDeletePop = true
            scope.delete_id = data
        },
        savedelete() {
            let scope = this
            scope.isProcessing = true;
            scope.$store
                .dispatch("deleteChannel", scope.delete_id)
                .then((response) => {
                    scope.isProcessing = false;
                    scope.isDeletePop = false
                    if (response.status == 200) {
                        scope.showSnackBar("Deleted Successfully");
                        scope.getDataFromApi();
                    } else {
                        scope.showSnackBar("Error! Unable Delete list");
                    }
                })
                .catch(() => {
                    scope.isProcessing = false;
                    scope.showSnackBar("Error! Unable Delete list");
                });
            
        },
        refreshThisPage() {
            let scope = this
            scope.page.loading = true;
            scope.getDataFromApi();
        }
    },
    mounted() {
        let scope = this
        scope.page.loading = true;
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "management:channel:create"
                    ) !== -1
                ) {
                    scope.page.addnew = [{ label: "Add Channel" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "management:channel:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "management:channel:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        scope.getDataFromApi()
    },
    watch: {
        pagination: {
            handler() {
                if (this.pagination.page > 1 || this.pagelimt.skip >= 10) {
                this.getDataFromApi()
                }
            },
            deep: true,
        },
    }
}
</script>