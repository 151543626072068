<template>
  <v-expansion-panel>
    <v-expansion-panel-content>
      <template v-slot:header>
        <div>{{ type }}</div>
      </template>
      <div class="cardheaders">
        <v-card>
          <v-card-text class="infoalign" v-if="info === 'No Data'"
            ><div class=" text_wrapper">
              {{ info }}
            </div></v-card-text
          >
          <v-card-text v-else><div class=" text_wrapper">
              {{ info }}
            </div></v-card-text>
        </v-card>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  props: {
    info: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.cardheaders {
  border-top: 1px solid #cacaca;
}
.infoalign {
  margin-left: 65px;
  width: 105px;
  min-height: 105px;
}
.text_wrapper{
  overflow-wrap: break-word;
}
</style>