<template>
  <div class="DatetimePickermain">
    <label style="font-size:9px" :class="[currentField.required?required_class:currentField.readonly?read_only:others]" :readonly="currentField.clearable">{{ currentField.label }}</label>
    <div>
      <datetime   :disabled="currentField.disabled||currentField.clearable"  :placeholder="`${currentField.isPlaceholderVisible ? currentField.placeholder : ''}`" class="theme-orange " style="border-bottom:1px solid rgb(145 145 145);" type="datetime"
        format="yyyy-MM-dd HH:mm:ss" v-model="currentField.value.value" :rules="required_rule">
      </datetime>
      <p v-show="false">{{`${currentField.label} is required`}} </p>
      <!-- <v-datetime-picker label="Select Datetime"  v-model="currentField.value.value"> </v-datetime-picker> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DatetimePicker",
  props: ["currentField"],
  data() {
    return {
      date: "",
      required_class:"required",
            others:"others",
             read_only:"readonly",
             required_rule:[]
    };
  },
  mounted() {
        if(this.currentField.required == true){
            this.required_rule = [ v => !!v || `${this.currentField.label} is required`]
        }
        
  },
  destroyed() {
        this.currentField.value.value = '';
    },
  computed: {
    attributesBinding() {
      var attr = {};
      attr["id"] = this.currentField.model;
      attr.disabled = this.currentField.disabled;
      return attr;
    },
  },
};
</script>

<style>
.DatetimePickermain {
 height: 85px;
 padding: 13px 10px 10px 10px;
}

.DatetimePickerfield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
}

.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
    font-size: medium;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>
