<template>
  <div>
    <section-definition
      :title="pagecontrol.title"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @openFilter="filterClick"
      @refreshPage="refreshClick"
      @addNew="NewTag"
      :style="styleObject"
    ></section-definition>
    <loader v-if="pagecontrol.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <template>
        <v-dialog presistent :value="pagecontrol.isAddTag" scrollable max-width="500px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">New Tag</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="Tags">
                <v-container grid-list-md>
                  <div id="scrollset">
                    <div id="scrollbar">
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="Addtags.name"
                            required
                            label="Name"
                            type="text"
                            :rules="[(v) => !!v || 'Name is required']"
                          ></v-text-field>
                        </v-flex>
                        <div>
                          <h5 v-if="error_edit === 409" class="alert-danger">
                            Tags name already exists
                          </h5>
                        </div>
                        <v-flex xs12>
                          <v-text-field
                            v-model="Addtags.description"
                            required
                            label="Description"
                            :rules="[(v) => !!v || 'Description is required']"
                          ></v-text-field>
                        </v-flex>
                        <!-- <v-flex xs12>
                      <v-select v-model="Addtags.status" :items="tagstatus" label="Status" item-text="name"
                        item-value="value" return-object persistent-hint></v-select>
                    </v-flex> -->
                      </v-layout>
                    </div>
                  </div>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="CloseNewTag()">Cancel</v-btn>
              <v-btn :disabled="!valid" color="primary" @click="SaveNewTag()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog presistent v-model="dialog" scrollable max-width="500px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Edit Tag</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-form ref="edittag" v-model="valid">
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.name"
                        required
                        label="Name"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editedItem.description"
                        required
                        label="Description"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="editedItem.status"
                        :items="tagstatus"
                        label="Status"
                        item-text="name"
                        item-value="value"
                        return-object
                        persistent-hint
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="CloseEdittag()">Cancel</v-btn>
              <v-btn color="primary" @click="SaveEdittag()">Update</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog presistent v-model="DeleteRows" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete Tag </span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this Tag.</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        fixed
        disable-resize-watcher
        right
        :value="pagecontrol.isFilterOpen"
        class="filterNavigation"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            color="white"
            @click="pagecontrol.isFilterOpen = false"
          >
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div class="sidenavContainer">
          <v-container>
            <!-- <v-text-field v-model="appliedfilter.name" label="Tag Name"></v-text-field> -->
            <v-autocomplete
              v-model="appliedfilter.name"
              :items="appliedfilter.filtervalue"
              :loading="appliedfilter.isLoading"
              :search-input.sync="appliedfilter.search"
              label="Name"
              hide-no-data
              hide-selected
            ></v-autocomplete>
          </v-container>
        </div>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFrom()">Filter</v-btn>
        </v-container>
      </v-navigation-drawer>
      <v-data-table
        :headers="headers"
        :items="tagsdata"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:items="props">
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.description }}</td>
          <td class="text-xs-left">{{ userstring(props.item.status) }}</td>
          <td class="justify-center layout px-0">
            <v-icon
              color="green"
              small
              class="mr-4"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              @click="deleteItem(props.item)"
              >delete</v-icon
            >
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";

export default {
  components: {
    loader,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
          width: "20%",
        },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "description",
          width: "20%",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status",
          width: "20%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      tagstatus: [
         "active" ,
       "inactive" 
      ],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      delete_tag: "",
      Addtags: {
        name: "",
        id: "",
        status: "active",
        description: "",
      },
      pagination: {
        rowsPerPage: 10,
      },
      appliedfilter: {
        name: "",
        isLoading: false,
        search: "",
        filtervalue: [],
      },
      error_edit: "",
      editedItem: {
        name: "",
        id: "",
        Description: "",
        status: "",
      },
      isProcessing: false,
      DeleteRows: false,
      dialog: false,
      valid: true,
      tableLoading: false,
      pagecount: 0,
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      pagecontrol: {
        title: "Tag Management",
        icon: "fa-tag fa-2x",
        loading: true,
        isFilterOpen: false,
        isAddTag: false,
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
      },
    };
  },
  computed: { ...mapGetters(["tagsdata"]) },
  methods: {
    filterClick: function () {
      let scope = this;
      this.pagecontrol.isFilterOpen = true;
      let data = { model: "tags", column: ["name"] };

      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.pagecontrol.loading = false;
          var datas = response.data;

          scope.appliedfilter.filtervalue = datas[0].name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshClick: function () {
      this.pagecontrol.loading = true;
       this.pagination.page = 1;
       this.appliedfilter.name = "";
       this.$store.commit("filter_name", { name: "" });
      this.getDataFromApi();
    },
    resetvalue() {
      this.appliedfilter.name = "";
      this.pagecontrol.isFilterOpen = false;
      this.pagecontrol.loading = true;
      this.$store.commit("filter_name", { name: "" });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    NewTag: function () {
      this.pagecontrol.isAddTag = true;
    },
    CloseNewTag: function () {
      this.pagecontrol.isAddTag = false;
      this.$refs.Tags.reset();
    },
    getDataFrom() {
      this.$store.commit("filter_name", { name: this.appliedfilter.name });
      this.getDataFromApi();
      this.pagecontrol.isFilterOpen = false;
    },
    SaveNewTag: function () {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("Addtags", this.Addtags)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            this.getDataFromApi();
            this.CloseNewTag();
            scope.showSnackBar("User Created Successfully");
          } else {
            scope.showSnackBar("Create error-" + response.message);
          }
        })
        .catch(function () {
          scope.isProcessing = false;
          // scope.error_edit = error.response.data.statusCode;
          scope.showSnackBar("Error Creating Tag");
        });
    },
    SaveEdittag() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("editeditem", this.editedItem)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            this.getDataFromApi();
            this.CloseEdittag();
            scope.showSnackBar("Tag Updated Successfully");
          } else {
            scope.showSnackBar("Update error-" + response.data.message);
          }
        })
        .catch(function () {
          scope.isProcessing = false;
          // scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Updating Tag");
        });
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.tagsdata.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    CloseEdittag() {
      this.dialog = false;
      this.$refs.edittag.reset();
    },
    userstring(val) {
      var k = this.tagstatus.filter((statusvalue) => {
        return statusvalue == val;
      });
      if (k.length > 0) {
        return k[0];
      } else {
        return "";
      }
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      return new Promise(() => {
        let take = this.pagination.rowsPerPage;
        let skip;
        if (this.pagination.page === 1) {
          skip = 0;
        } else {
          skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
        }
        if (skip == -10) {
          skip = 0;
        }

        if (isNaN(skip)) {
          skip = 0;
        }

        let query = {
          skip: skip,
          take: take,
        };
        this.skip = skip;

        scope.$store
          .dispatch("TagManagement", query)
          .then((response) => {
            this.pagecount = response.data.count;
            scope.pagecontrol.loading = false;
            scope.tableLoading = false;
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
            scope.tableLoading = false;
          });
      });
    },
    deleteItem(item) {
      this.DeleteRows = true;
      this.delete_tag = item;
    },
    closedelete() {
      this.DeleteRows = false;
    },

    savedelete() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteRows", this.delete_tag)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            this.getDataFromApi();
            this.closedelete();
            scope.showSnackBar("Deleted  Successfully");
          } else {
            scope.showSnackBar("Delete error-" + response.data.message);
          }
        })
        .catch(function () {
          scope.isProcessing = false;
          // scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Deleting Tag");
        });
    },
  },
  watch: {
   
    pagination: {
      handler() {
        this.demo += 1;
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "appliedfilter.search"(val) {
      if (this.pagecontrol.isFilterOpen) {
        let scope = this;

        let data = { model: "tags", column: ["name"], search: val };

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.pagecontrol.loading = false;
            var datas = response.data;
            this.appliedfilter.filtervalue = datas[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.pagecontrol.loading = false;
    this.pagecontrol.addnew = [{ label: "Add Tag" }];
    this.getDataFromApi();
  },
  refreshvalue() {
    this.$store.commit("filter_name", { name: "" });
  },
  beforeDestroy() {
    this.$store.commit("filter_name", { name: "" });
  },
};
</script>
<style>
.alert-danger {
  color: red;
}

.sidenavContainer {
  height: 508px;
}
</style>