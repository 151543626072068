<template>
 <div>
    <v-card>
      <v-container>
        <highcharts class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import config from "@/config/index";
export default {
  data () {
    return {
      updateArgs: [true, true, {duration: 1000}],
    }
  },
  created () {
    let i = document.createElement('input')
    i.setAttribute('type', 'color');
    (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
  },
  watch: {
    
  },
  computed:{
    ...mapGetters([
       "categories",
       "failure",
       "success",
       "available"
      ]),  
      
       chartOptions() 
       {
        var options= {
          chart: {
            type: 'column',
            width:1000,
            height:240
          },
          title: {
            text: ''
          },
          colors:["#7cb5ec", "#434348", "#89A54E", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
          xAxis: {
            categories:this.categories
          },
          yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
              text: 'Count'
            }
          },
          tooltip: {
            formatter: function () {
              var total = '111';
              const state = config.campaign_status;
              state.campaign.table.forEach((data) => {             

                data.campaign === this.key ? total= data.total_leads : "1112";
            })
              return '<b>' + this.x + '</b><br/>' +this.series.name+ ': ' + this.y + '<br/>' +'Total: ' + total;
            }
          },
          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },
          series: [
            {
              name: 'failure',
              data: this.failure,
              stack : "dialer"
            },
            {
              name: 'success',
              data: this.success,
              stack : "dialer"
            },
            {
              name: 'Available',
              data: this.available,
              stack : "Available"
            },
          ],
          legend:{
            verticalAlign: 'top'
          },
      }
      return options;
       }
      }

    }
  
</script>
<style scoped>
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
#colorPicker {
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
}
.numberInput {
  width: 30px;
}
</style>

