import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import navigation from "./modules/navigation";
import swatch from "./modules/swatch";
import report from "./modules/report";
// import axios from 'axios';
import liveAgents from "./modules/liveAgents";
import campaign from "./modules/campaign_status";
import queuestatus from "./modules/queuestatus";
import auth0 from "./modules/auth0";
import overallreport from "./modules/overallreport";
import userManagement from "./modules/userManagement";
import queueManagement from "./modules/queueManagement";
import AudioStore from "./modules/AudioStore";
import groupManagement from "./modules/groupManagement";
import WebHookManagement from "./modules/WebHookManagement";
import TimeCondition from "./modules/scheduler";
import DoNotCall from "./modules/DoNotCall";
import Blocklist from "./modules/Blocklist";
import TagManagement from "./modules/TagManagement";
import PauseCode from "./modules/pauseCode";
import MeetingTitle from "./modules/MeetingTitle";
import ShiftUser from "./modules/ShiftUser";
import Shift from "./modules/Shift";
import Category from "./modules/category";
// import Appointment from "./modules/Appointment";
import Reminder from "./modules/reminder";
import Announcement from "./modules/Announcement";
import Disposition from "./modules/Disposition";
import Meetings from "./modules/Meetings";
import SubDisposition from "./modules/SubDisposition";
import AsterCampaigns from "./modules/Astercampaigns";
import AsterContacts from "./modules/Astercontacts";
import AsterList from "./modules/AsterList";
import AsterSms from "./modules/AsterSms";
import AsterMail from "./modules/AsterMail";
import AsterWhatsapp from "./modules/AsterWhatsapp";
import AsterLead from "./modules/AsterLead";
import AsterLeadInsert from "./modules/AsterLeadInsert";
import AsterLeadDelete from "./modules/AsterLeadDelete";
import Contact from "./modules/Contacts";
import Rules from "./modules/Rules";
import CustomForm from "./modules/CustomForm";
import rect from "./modules/rect";
import CallFlow from "./modules/CallFlow";
import Script from "./modules/Script";
import Abandonedsummary from "./modules/Abandonedsummary";
import Astersupport from "./modules/AsterSupport";
import leadStatus from "./modules/leadStatus";
import ticketStatus from "./modules/ticketStatus";
import didNumber from "./modules/didNumber";
import InboundRoute from "./modules/InboundRoute";
import Timezone from "./modules/timezone"
import SLA from "./modules/sla"
import TeamManagement from "./modules/TeamManagement";
import Channel from "./modules/Channel";
import Source from './modules/Source';
import Notification from "./modules/Notification"
import SystemSettings from "./modules/SystemSetting";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    jsonData: null,
    websocketStatus: {},
    session: {},
    loading: true,
    callAction: false,
    oncall_phonenumber: '',
    ringing: false,
    audio: new Audio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'), // Assuming you have an Audio object for handling the ringtone
    isplay: false,
    websocketconnection: {},
    socket_message: {
      userChannel: '',
      agentChannel: '',
      subject: '',
      calltype: '',
      ringing_time: '',
      station: '',
      phonenumber: '',
      uniqueid: '',
      evt: '',
      oncalltime: '',
      endtime: '',
      stage1: '',
      stage2: '',
      stage3: '',
      duration: '',
    },
    conference: [],
    activeconference: null,
    stopEvent: 0,
  },
  getters,
  mutations,
  actions,
  modules: {
    navigation,
    swatch,
    liveAgents,
    campaign,
    queuestatus,
    report,
    auth0,
    overallreport,
    userManagement,
    queueManagement,
    AudioStore,
    groupManagement,
    WebHookManagement,
    TimeCondition,
    DoNotCall,
    Blocklist,
    didNumber,
    TagManagement,
    PauseCode,
    MeetingTitle,
    ShiftUser,
    Shift,
    Category,
    // Appointment,
    Reminder,
    Announcement,
    Disposition,
    SubDisposition,
    Contact,
    Rules,
    AsterCampaigns,
    AsterContacts,
    AsterList,
    AsterSms,
    AsterMail,
    AsterWhatsapp,
    AsterLead,
    AsterLeadInsert,
    AsterLeadDelete,
    CustomForm,
    CallFlow,
    Script,
    Abandonedsummary,
    leadStatus,
    ticketStatus,
    rect: rect,
    Astersupport,
    InboundRoute,
    SLA,
    Timezone,
    TeamManagement,
    Channel,
    Source,
    Meetings,
    Notification,
    SystemSettings
  },
});
