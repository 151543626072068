<template>
    <div class="TextInputmain">
        <v-text-field :class="[currentField.required?required_class:currentField.readonly?read_only:others]" :readonly="currentField.clearable" :disabled='attributesBinding.disabled' :placeholder="`${currentField.isPlaceholderVisible ? attributesBinding.placeholder : '' }`" v-model="currentField.value.value"
             :label="`${currentField.label}`" 
             :rules="required_rule" 
             :type="input">
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: ['currentField','Allfields'],
    data() {
        return {
            input: '',
             required_class:"required",
             others:"others",
             read_only:"readonly",
             required_rule:[],
        }
    },
    destroyed() {
        this.currentField.value.value = '';
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr['id'] = this.currentField.model;
            if (this.currentField.showWordLimit) {
                attr.maxlength = this.currentField.maxlength;
                attr['show-word-limit'] = this.currentField.showWordLimit
            }

            if (this.currentField.clearable) {
                attr.clearable = this.currentField.clearable;
            }

            if (this.currentField.disabled) {
                attr.disabled = this.currentField.disabled;
            }

            if (this.currentField.showPassword) {
                attr['show-password'] = this.currentField.showPassword;
            }

            if (this.currentField.isPlaceholderVisible) {
                attr.placeholder = this.currentField.placeholder;
            }

            return attr;
        },
    },
    mounted() {
        if(this.currentField.required == true){
            this.required_rule = [ v => !!v || `${this.currentField.label} is required`]
            if(this.currentField.model_type == "SmallNumber" ){
                this.required_rule.concat([v => String(v).length > 32767 || "The value must be an less then 32767"])
            }
            // this.required_rule.push(v => !!v || `${this.currentField.label} is required`)
        } 
        if(this.currentField.model_type == "BigNumber" ||  this.currentField.model_type == "SmallNumber" ||   this.currentField.model_type == "Number"){
            this.input="number"
            if(this.currentField.model_type == "SmallNumber" ){
                this.required_rule=[v => Number(v) <= 32767 || "The value type is SmallNumber must be an less then 32767"]
            }
        }else{
            this.input="text" 
        }
    },
}
</script>
<style>
.TextInput {
    margin: 20px 10px 10px 10px;
}

.TextInputmain {
    display: flex;
    padding: 10px;
    height: 85px;
}
.required:after {
    content:" *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left:2px ;
  }

  .readonly:after {
    content:" *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left:2px ;
  }

  .others:after{
    content:"";
    font-weight: 600;
    padding-left:2px ;
  }
</style>