<template>
  <div class="spinner">
    <img src="/static/img/loading.svg" alt="loading" />
  </div>
</template>
<script>
export default {
  name: "callback",
  data() {
    return {};
  },
  mounted() {
    var scope = this;
    scope.$store
      .dispatch("auth0Parse")
      .then(function () {
        scope.$router.push({
          name: "realtime/LiveAgents",
        });
      })
      .catch(() => {});
  },
};
</script>
<style>
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 40vh;
  width: 40vw;
  left: 33%;
  margin-left: -4em;
  top: 30%;
  /* bottom: 0;
  left: 0;
  right: 0; */
}
</style>