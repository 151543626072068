import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const mutations = {
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    CreateNotification: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/notification/create`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetNotification: ({ commit },query) => {
        return new Promise((resolve, reject) => {
            try {
                let skiptake =''
                if(query !== undefined){
                    skiptake = `?skip=${query.skip}&take=${query.take}`
                }
                api.get(`/notification/fetch${skiptake}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    UpdateNotification: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                let id = query.id
                delete query.id
                api.put(`/notification/update/`+id,query).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    DeleteNotification: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/notification/delete/${query}`)
                    .then(response => {
                        resolve(response);
                        commit(mutation_type.CommitNothing, {});
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
};
export default {
    mutations,
    actions
};