import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.Subdispostion;
var getters = {
    GetSubDisposition: state => state.SubDisposition
};
const mutations = {
    [mutation_type.SubDisposition]: (state, response) => {
        state.SubDisposition = response;
    },
    [mutation_type.CommitNothing]: () => { }
};
const actions = {
    insertsubDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/subdisposition`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetSubDisposition: ({ commit },query) => {
        return new Promise((resolve, reject) => {
            try {
                let skip = query.skip;
                let take = query.take;
                let filter=""
                if(query.subdispo_status){
                    filter +=`&active=${query.subdispo_status}`
                }
                if(query.subdispo_id){
                    filter +=`&subdispo_id=${query.subdispo_id}`
                }
                api.get(`/subdisposition?skip=${skip}&take=${take}`+filter).then(response => {
                    if (response.status == 204) {
                        delete response.data
                        response.data = {
                            data: []
                        }
                    }
                    const subdisposition = response.data.data;
                    commit(mutation_type.SubDisposition, subdisposition);
                    const subdispo_array = [];
                    if (subdisposition.length > 0) {
                        subdisposition.forEach(dispo => {
                            subdispo_array.push(dispo.subdispo_id);
                        });
                        localStorage.setItem("group_subdisposition", JSON.stringify(subdispo_array));
                        resolve(response);
                    } else {
                        resolve(response);
                    }
                });
            } catch (error) {
              
                reject(error);
            }
        });
    },
    FiltersubDisposition: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/subdisposition?active=${query.subdispo_status}&subdispo_id=${query.subdispo_id}`).then(response => {
                    const subdisposition = response.data;
                    commit(mutation_type.SubDisposition, subdisposition);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },

    deletesubDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/subdisposition/${payload}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updatesubDisposition: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/subdisposition/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};